/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-60.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina60 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-60', className].join(' ');
  return (
    <svg
      id="disciplina_tennis-tavolo"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M18.5 14c1.4 0 2.5 1.1 2.5 2.5S19.9 19 18.5 19 16 17.9 16 16.5s1.1-2.5 2.5-2.5ZM7 15s1 1 1 2v3.5c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5V17c0-1 1-2 1-2H7Zm1-1h3s5 0 5-5-4-7-6.5-7S3 4 3 9s5 5 5 5Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina60;
