/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-196.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco196 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-196', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="32.526"
      viewBox="0 0 38.439 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <g transform="translate(0 5.914)">
          <path
            className="a"
            d="M328.743,391.478h-34a.739.739,0,0,1,0-1.478h34a.739.739,0,1,1,0,1.478Z"
            transform="translate(-294 -390)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(2.957 2.966)">
          <path
            className="a"
            d="M298.739,387.482a.736.736,0,0,1-.281-.059.667.667,0,0,1-.4-.4.736.736,0,0,1-.059-.281.76.76,0,0,1,.214-.525.739.739,0,0,1,1.264.525.735.735,0,0,1-.059.281.666.666,0,0,1-.4.4A.736.736,0,0,1,298.739,387.482Z"
            transform="translate(-298 -386.013)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(5.914 2.958)">
          <path
            className="a"
            d="M302.739,387.479a.736.736,0,0,1-.281-.059.852.852,0,0,1-.244-.155,1.124,1.124,0,0,1-.163-.244.726.726,0,0,1-.051-.281.774.774,0,0,1,.214-.525.763.763,0,0,1,.244-.155.741.741,0,0,1,.806.155.763.763,0,0,1,.214.525.737.737,0,0,1-.059.281.666.666,0,0,1-.4.4A.736.736,0,0,1,302.739,387.479Z"
            transform="translate(-302 -386.001)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(8.871 2.956)">
          <path
            className="a"
            d="M306.739,387.478a.736.736,0,0,1-.281-.059.854.854,0,0,1-.244-.155,1.121,1.121,0,0,1-.163-.244.727.727,0,0,1-.051-.281.774.774,0,0,1,.214-.525.737.737,0,0,1,.665-.2.451.451,0,0,1,.141.044.42.42,0,0,1,.126.067.74.74,0,0,1,.118.089.777.777,0,0,1,.214.525.735.735,0,0,1-.214.525.865.865,0,0,1-.244.155A.737.737,0,0,1,306.739,387.478Z"
            transform="translate(-306 -385.999)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M328.743,414.526H297.7a3.7,3.7,0,0,1-3.7-3.7V385.7a3.7,3.7,0,0,1,3.7-3.7h31.047a3.7,3.7,0,0,1,3.7,3.7v25.133A3.7,3.7,0,0,1,328.743,414.526ZM297.7,383.479a2.22,2.22,0,0,0-2.218,2.218v25.133a2.221,2.221,0,0,0,2.218,2.218h31.047a2.22,2.22,0,0,0,2.218-2.218V385.7a2.22,2.22,0,0,0-2.218-2.218Z"
            transform="translate(-294 -382)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M301.7,405.62a.739.739,0,0,0-.739-.739h-2.218a.739.739,0,0,0,0,1.478h2.218A.739.739,0,0,0,301.7,405.62Zm.217,3.173-1.478,1.478a.739.739,0,1,0,1.045,1.045l1.478-1.478a.739.739,0,1,0-1.045-1.045Zm-.433-8.871a.739.739,0,0,0-1.045,1.045l1.478,1.478a.739.739,0,0,0,1.045-1.045Zm28.307,4.958h-2.218a.739.739,0,0,0,0,1.478h2.218a.739.739,0,0,0,0-1.478Zm-3.173,3.913a.739.739,0,1,0-1.045,1.045l1.478,1.478a.739.739,0,1,0,1.045-1.045Zm-.523-6.13a.737.737,0,0,0,.523-.217l1.478-1.478a.739.739,0,1,0-1.045-1.045l-1.478,1.478a.739.739,0,0,0,.523,1.262Zm-3.383-3.626a.744.744,0,0,0-.787.1l-3.749,3.125-3.263-5.872a.769.769,0,0,0-1.292,0l-3.263,5.872-3.749-3.125a.74.74,0,0,0-1.213.568v11.828a.739.739,0,0,0,.739.739h16.263a.739.739,0,0,0,.739-.739V399.706A.739.739,0,0,0,322.708,399.037Zm-1.053,11.757H306.871v-9.51l3.222,2.686a.752.752,0,0,0,.61.158.736.736,0,0,0,.51-.368l3.05-5.489,3.05,5.489a.735.735,0,0,0,.51.368.753.753,0,0,0,.61-.158l3.222-2.686Z"
          transform="translate(-295.043 -385.661)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco196;
