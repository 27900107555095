/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\play-stroke.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const PlayStroke = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-play-stroke', className].join(' ');
  return (
    <svg
      id="playStroke"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path d="M8 5.13989V19.1399L19 12.1399L8 5.13989Z" fill="currentColor" stroke="#1B2022" strokeWidth="0.5" />
    </svg>
  );
};
export default PlayStroke;
