/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-374.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco374 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-374', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.569"
      height="38.439"
      viewBox="0 0 29.569 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M388.435,223.306v11.827a1.48,1.48,0,0,0,1.478,1.478h17.741a1.48,1.48,0,0,0,1.478-1.478V223.306a1.48,1.48,0,0,0-1.478-1.478H389.914A1.48,1.48,0,0,0,388.435,223.306Zm13.306,11.827H389.914v-2.957h11.827Zm0-4.435H389.914v-2.957h11.827Zm5.914,4.435H403.22v-2.957h4.436Zm0-4.435H403.22v-2.957h4.435Zm0-4.435H389.914v-2.957h17.741Zm.739,13.306h-4.435a.739.739,0,1,0,0,1.478h4.435a.739.739,0,1,0,0-1.478Zm-8.871,0H389.174a.739.739,0,0,0,0,1.478h10.349a.739.739,0,1,0,0-1.478Zm-10.349-20.7h10.349a.739.739,0,0,0,0-1.478H389.174a.739.739,0,0,0,0,1.478Zm0-2.957h7.392a.739.739,0,1,0,0-1.478h-7.392a.739.739,0,0,0,0,1.478Zm24.337,3.411a.736.736,0,0,0-.16-.239l-8.869-8.869a.741.741,0,0,0-.239-.16.73.73,0,0,0-.285-.058H386.218A2.22,2.22,0,0,0,384,212.218v34a2.22,2.22,0,0,0,2.218,2.218h25.133a2.22,2.22,0,0,0,2.218-2.218V219.61A.733.733,0,0,0,413.511,219.325Zm-8.813-6.8,6.347,6.347h-5.608a.74.74,0,0,1-.739-.739Zm7.392,33.7a.74.74,0,0,1-.739.739H386.218a.74.74,0,0,1-.739-.739v-34a.74.74,0,0,1,.739-.739h17v6.653a2.22,2.22,0,0,0,2.218,2.218h6.653Z"
        transform="translate(-384 -210)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco374;
