/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\round-question.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const RoundQuestion = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-round-question', className].join(' ');
  return (
    <svg
      id="round-question"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#clip0_2019_10091)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22C6.4875 22 2 17.5125 2 12C2 6.4875 6.4875 2 12 2C17.5125 2 22 6.4875 22 12C22 17.5125 17.5125 22 12 22ZM13 16V18H11V16H13ZM8 10C8 7.79 9.79 6 12 6C14.21 6 16 7.79 16 10C16 11.2829 15.21 11.9733 14.4408 12.6455C13.711 13.2833 13 13.9046 13 15H11C11 13.1787 11.9421 12.4566 12.7704 11.8217C13.4202 11.3236 14 10.8792 14 10C14 8.9 13.1 8 12 8C10.9 8 10 8.9 10 10H8Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2019_10091">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default RoundQuestion;
