/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\star.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Star = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-star', className].join(' ');
  return (
    <svg
      id="star"
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M17.2403 11.3307L14.0003 0.664062L10.7603 11.3307H0.666992L8.90699 17.2107L5.77366 27.3307L14.0003 21.0774L22.2403 27.3307L19.107 17.2107L27.3337 11.3307H17.2403Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Star;
