/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-106.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina106 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-106', className].join(' ');
  return (
    <svg
      id="disciplina_esports-league-of-legends"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M21.923 15.352s.118-.082 0 0c-5.669 3.936-6.861 3.264-7.877 3.323-1.016.06-2.763-.353-2.763-.353S11.247 4.5 11.27 3.946C11.294 3.392 13.916 1 13.916 1H2.898s1.145.872 1.736 1.26c.59.39 1.275 1.332 1.275 1.332s-.035 12.821 0 14.918c.035 2.098-.957 2.51-.957 2.51L2 23s17.219-.035 17.219-.047c3.495-7.6 2.704-7.6 2.704-7.6Zm-.992 1.945c-.035.306-1.984 4.018-2.492 4.206-.508.177-10.25-.235-10.25-.235s-4.276 1.32-4.122 1.213c1.334-.942 3.4-2.392 3.4-2.392L7.67 4.618 5.177 1.672 8.425 3.31l3.684-1.768-3.212 3.17.153 14.906s.237.495 1.075.542c.827.047 4.169.389 6.165.13 1.996-.26 4.594-3.335 4.594-3.335s.07.047.047.342Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina106;
