/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-362.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco362 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-362', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M483.437,462a.738.738,0,0,0-.739.739V479a.739.739,0,0,0,.739.739H499.7a.739.739,0,0,0,.739-.739A17.02,17.02,0,0,0,483.437,462Zm.739,1.5a15.382,15.382,0,0,1,5.121,1.148l-5.121,5.121Zm0,8.359,6.544-6.544a15.556,15.556,0,0,1,3.142,2.221l-9.687,9.687Zm1.045,6.408,9.687-9.687a15.55,15.55,0,0,1,2.221,3.142l-6.544,6.544Zm7.454,0,5.12-5.121a15.4,15.4,0,0,1,1.148,5.121Zm1.111,5.174a.739.739,0,0,0-.739.739,14.784,14.784,0,1,1-14.784-14.784.739.739,0,1,0,0-1.478,16.263,16.263,0,1,0,16.263,16.263A.739.739,0,0,0,493.786,483.437ZM479,479a.739.739,0,1,0-1.478,0v1.478a2.218,2.218,0,1,0,0,4.435H479a.739.739,0,1,1,0,1.478h-2.957a.739.739,0,0,0,0,1.479h1.478v1.478a.739.739,0,1,0,1.478,0v-1.478a2.218,2.218,0,0,0,0-4.435h-1.478a.739.739,0,0,1,0-1.479h2.957a.739.739,0,1,0,0-1.478H479Z"
        transform="translate(-462 -462)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco362;
