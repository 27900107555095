import { AnyAction, Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { selectCurrentPath, selectTemplateSlug } from '../../sport/selectors';
import { selectIsLoading, selectIsOnline } from 'features/location/selectors';
import { setSignalRConnection, signalRs } from 'features/signalR/signalRSlice';

import { ApiEndpointQuery } from '@reduxjs/toolkit/dist/query/core/module';
import { CustomHttpClient } from '../CustomHttpClient';
import { LocationEventHandler } from './types';
import { RootState } from 'lib/centralStore';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsight } from 'components/appInsight/AppInsight';
import { decodePathname } from '../../sport/utils/utils';
import { feedLingUI } from 'hooks/useLingUI';
import { isTruthy } from 'utility/functions';
import { selectSignalRConnection } from 'features/signalR/selectors';
import { sportSignalRListeners } from '../../sport/sportSlice';
import { SportsSignalRPacketDto } from 'types/swagger';

import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { selectSportPacketMessage } from 'features/configuration/selectors';

export const MAX_CONNECTION_ATTEMPTS = 6;
const REATTEMPT_DELAY = 200;

export interface SportEventHandler extends LocationEventHandler {
  onTemplateEventHandler: (_isLoading: boolean, _slug?: string | undefined) => Promise<void>;
}

export const nextDelay = (attemptNo = 0): number => {
  const nextAttempt = attemptNo + 1;
  return (REATTEMPT_DELAY * nextAttempt * nextAttempt) / 2;
};

export interface FetchProps {
  query: ApiEndpointQuery<any, any>;
  slug: string;
}

export const sportConnectionHubManager = (store: MiddlewareAPI<Dispatch<AnyAction>, any>): SportEventHandler => {
  const key = signalRs.sport;

  const registerListeners = (connection: HubConnection) => {
    const isPacketMessageOn = selectSportPacketMessage(store.getState() as RootState);

    const dispatchMessage = (event, action?: AnyAction | Function, type?: string) => {
      const { traduzioneMap, ...payload } = event ?? {};
      feedLingUI(traduzioneMap);

      if (typeof action === 'function') {
        store.dispatch(action(payload));
      } else if (type) {
        store.dispatch({ type, payload });
      }
    };

    if (isPacketMessageOn) {
      connection.on('PacketMessage', (_a: boolean, _b: string, { data }: SportsSignalRPacketDto) => {
        if (isTruthy(data?.length)) {
          data?.forEach(({ message, event }) => {
            const { action, actionType } = sportSignalRListeners.find((x) => x.eventName === message) ?? {};

            if (action || actionType) {
              dispatchMessage(event, action, actionType);
            } else {
              const msg = `[sportConnectionHubManager] - PacketMessage: no action defined for "${message}" event`;
              appInsight.trackTrace({
                message: msg,
                severityLevel: SeverityLevel.Information,
              });
              console.log(msg);
            }
          });
        } else {
          const msg = `[sportConnectionHubManager] - PacketMessage: empty payload data`;
          appInsight.trackTrace({
            message: msg,
            severityLevel: SeverityLevel.Information,
          });
          console.log(msg);
        }
      });
    } else {
      sportSignalRListeners.forEach(({ eventName, action, actionType }) => {
        connection.on(eventName, (_a: boolean, _b: string, data: any) => {
          if (action || actionType) {
            dispatchMessage(data, action, actionType);
          } else {
            const msg = `[sportConnectionHubManager]: no action defined for "${eventName}" event`;
            appInsight.trackTrace({
              message: msg,
              severityLevel: SeverityLevel.Information,
            });
            console.log(msg);
          }
        });
      });
    }
  };

  const buildConnection = (): HubConnection => {
    const isPacketMessageOn = selectSportPacketMessage(store.getState() as RootState);

    const connectionBuilder = new HubConnectionBuilder().withUrl(
      `${process.env.NEXT_PUBLIC_API_BASE_URL}/signalr-sports-${process.env.NEXT_PUBLIC_COUNTRY}/sigr-hubs-${process.env.NEXT_PUBLIC_COUNTRY}/eventi-sport`,
      { httpClient: new CustomHttpClient() }
    );

    if (isPacketMessageOn) {
      connectionBuilder.withHubProtocol(new MessagePackHubProtocol());
    }

    const connection = connectionBuilder.build();

    connection.onclose((error) => {
      appInsight.trackTrace({
        message: `[sportConnectionHubManager] signalR closed`,
        severityLevel: SeverityLevel.Information,
        properties: { error },
      });

      store.dispatch(setSignalRConnection({ key }));
      const state = store.getState() as RootState;
      const slug = decodePathname(selectCurrentPath(state));
      const templateSlug = selectTemplateSlug(state);

      console.log('onclose', slug, templateSlug);
      ensureConnected(undefined, [slug, templateSlug]);
    });

    connection.onreconnected(async () => {
      // just in case of restoring withAutomaticReconnect option
      appInsight.trackTrace({
        message: `[sportConnectionHubManager] signalR reconnected`,
        severityLevel: SeverityLevel.Information,
      });

      const state = store.getState();
      const slug = decodePathname(selectCurrentPath(state));
      const templateSlug = selectTemplateSlug(state);

      console.log('onreconnected', slug, templateSlug);
      doSubscribe(connection, [slug, templateSlug]);
    });

    registerListeners(connection);

    store.dispatch(setSignalRConnection({ key, connection }));

    return connection;
  };

  const doSubscribe = async (hub: HubConnection, slugs?: Array<string | undefined>): Promise<void> => {
    for (let slug of slugs ?? []) {
      if (!isTruthy(slug?.length)) continue;

      let success = false;

      for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && !success; x++) {
        try {
          await hub.invoke('SubscribeToGroup', slug);
          success = true;
          appInsight.trackTrace({
            message: `[sportConnectionHubManager] Successfully Subscribed To ${slug}`,
            severityLevel: SeverityLevel.Information,
          });
        } catch (exception) {
          appInsight.trackTrace({
            message: `[sportConnectionHubManager] Failure Subscribing To ${slug} - attempt n. ${x + 1}`,
            severityLevel: SeverityLevel.Error,
          });
        }

        await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
      }

      if (!success) {
        appInsight.trackTrace({
          message: `[sportConnectionHubManager] unable to Subscribe to ${slug}`,
          severityLevel: SeverityLevel.Critical,
        });
      }
    }

    return Promise.resolve();
  };

  const ensureConnected = async (hub?: HubConnection, slugs?: Array<string | undefined>): Promise<HubConnection> => {
    const connection = hub ?? buildConnection();

    let success = true;

    for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && connection.state !== HubConnectionState.Connected; x++) {
      success = false;
      if (connection.state === HubConnectionState.Disconnected) {
        try {
          await connection.start();
          doSubscribe(connection, slugs);
          success = true;
        } catch (e) {
          appInsight.trackTrace({
            message: `[sportConnectionHubManager] unable to Start signalR, state is ${connection.state} - attempt n. ${
              x + 1
            }`,
            severityLevel: SeverityLevel.Error,
          });
        }
      }
      await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
    }

    if (!success) {
      appInsight.trackTrace({
        message: `[sportConnectionHubManager] unable to Start signalR`,
        severityLevel: SeverityLevel.Critical,
      });
    }

    return Promise.resolve(connection);
  };

  const doUnsubscribe = async (hub: HubConnection, slug?: string): Promise<void> => {
    if (!slug) return Promise.resolve();
    if ((hub?.state || HubConnectionState.Disconnected) !== HubConnectionState.Connected) {
      appInsight.trackTrace({
        message: `[sportConnectionHubManager] unable to Unsubscribe From ${slug} since state is ${hub?.state}`,
        severityLevel: SeverityLevel.Information,
      });

      return Promise.resolve();
    }

    let success = false;

    for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && !success; x++) {
      try {
        await hub.invoke('UnsubscribeFromGroup', slug);
        success = true;
        appInsight.trackTrace({
          message: `[sportConnectionHubManager] Successfully Unsubscribed From ${slug}`,
          severityLevel: SeverityLevel.Information,
        });
      } catch (exception) {
        appInsight.trackTrace({
          message: `[sportConnectionHubManager] Failure Unsubscribing from ${slug} - attempt n. ${x + 1}`,
          severityLevel: SeverityLevel.Error,
        });
      }

      await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
    }

    if (!success) {
      appInsight.trackTrace({
        message: `[sportConnectionHubManager] unable to Unsubscribing from ${slug}`,
        severityLevel: SeverityLevel.Critical,
      });
    }

    return Promise.resolve();
  };

  const onTemplateEventHandler = async (isLoading: boolean, slug?: string): Promise<void> => {
    const state = store.getState() as RootState;

    const connectionSignalR = selectSignalRConnection(state)(key);
    const prevPathname = decodePathname(selectCurrentPath(state)) ?? '';

    const signalR = await ensureConnected(connectionSignalR, [prevPathname]);

    if (isLoading) {
      const path = selectCurrentPath(state);
      doUnsubscribe(signalR, decodePathname(path));

      const templateSlug = selectTemplateSlug(state);
      doUnsubscribe(signalR, templateSlug);
      return Promise.resolve();
    } else if (isTruthy(slug?.length)) {
      appInsight.trackTrace({
        message: `[sportConnectionHubManager] template loaded ${slug}`,
        severityLevel: SeverityLevel.Information,
      });

      doSubscribe(signalR, [decodePathname(slug)]);
    }
  };

  const onLocationEventHandler = async (nextPath?: string): Promise<void> => {
    const state = store.getState() as RootState;

    const nextPathname = nextPath ? decodePathname(nextPath) : '';
    const prevPath = selectCurrentPath(state);
    const prevPathname = decodePathname(prevPath) ?? '';

    const connectionSignalR = selectSignalRConnection(state)(key);
    if (prevPathname === nextPathname) {
      await ensureConnected(connectionSignalR, [nextPathname]);
      return Promise.resolve();
    }

    appInsight.trackTrace({
      message: `[sportConnectionHubManager] movig from ${prevPathname} to ${nextPathname}`,
      severityLevel: SeverityLevel.Information,
    });

    const signalR = await ensureConnected(connectionSignalR);

    doSubscribe(signalR, [nextPathname]);
    doUnsubscribe(signalR, prevPathname);

    return Promise.resolve();
  };

  const onIsOnlineEventHandler = async (isOnline: boolean): Promise<void> => {
    const state = store.getState() as RootState;
    const prevOnline = selectIsOnline(state);
    if (isOnline === true && prevOnline === false) {
      const path = selectCurrentPath(state);
      const templateSlug = selectTemplateSlug(state);

      const pathname = decodePathname(path) ?? '';
      const connectionSignalR = selectSignalRConnection(state)(key);
      await ensureConnected(connectionSignalR, [pathname, templateSlug]);
    }
    return Promise.resolve();
  };

  return {
    onTemplateEventHandler,
    onLocationEventHandler,
    onIsOnlineEventHandler,
  };
};
