/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-78.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina78 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-78', className].join(' ');
  return (
    <svg
      id="disciplina_rugby-a-7"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="m23.567 1.006-.103-.464L23 .439a18.951 18.951 0 0 0-17.452 5.11A18.94 18.94 0 0 0 .44 22.997l.103.464.464.103c1.332.29 2.691.437 4.054.436a18.917 18.917 0 0 0 7.13-1.387 18.819 18.819 0 0 0 6.267-4.161 18.984 18.984 0 0 0 4.833-8.215 19.11 19.11 0 0 0 .276-9.231Zm-1.354.787A17.4 17.4 0 0 1 22.4 7.14l-5.53-5.53a17.735 17.735 0 0 1 5.344.183ZM5.057 22.515c-1.096-.001-2.19-.103-3.267-.305a17.399 17.399 0 0 1-.188-5.347l5.531 5.53c-.689.08-1.382.121-2.076.122Zm12.351-5.113a17.33 17.33 0 0 1-8.475 4.686l-7.021-7.02a17.411 17.411 0 0 1 4.687-8.472 17.33 17.33 0 0 1 8.475-4.686l7.017 7.015a17.397 17.397 0 0 1-4.683 8.477Zm-9.436.02 4.009-8.215h-3.01l.567-2.624h6.497l-5.297 10.838H7.972Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina78;
