/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\download-virtual.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const DownloadVirtual = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-download-virtual', className].join(' ');
  return (
    <svg
      id="download-virtual"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M2 16H4V21H20V16H22V21C22 22.11 21.11 23 20 23H4C2.9 23 2 22.11 2 21V16ZM12 17L17.55 11.54L16.13 10.13L13 13.25V4H11V13.25L7.88 10.13L6.46 11.55L12 17Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default DownloadVirtual;
