/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\stats.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Stats = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-stats', className].join(' ');
  return (
    <svg
      id="stats"
      viewBox="0 0 24 24"
      height="100%"
      width="100%"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 5H9V19H11V5ZM7 12H5V19H7V12ZM13 13H15V19H13V13ZM19 9H17V19H19V9Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Stats;
