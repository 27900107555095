import { CANALE, GOLDEN_LEAGUE } from '../constants';
import { GiocataCorsa, QuotaList } from 'lib/api/virtual/virtualTemplateAvvenimentoApi';

import { ProssimePartenzeType } from '../types';
import { VirtualTicketInfoEsito } from '../components/virtualTicket/types';

export const isGoldenLeague = (disciplina?: string) => {
  return disciplina === GOLDEN_LEAGUE;
};

export const isKeyExcluded = <T extends ProssimePartenzeType>(
  key: T,
  exclude: T
): key is Exclude<T, typeof exclude> => {
  return key !== exclude;
};

export const retrieveCurrentIframeVideoStreaming = (listVideoStreaming: Array<string>, canale: string) => {
  return listVideoStreaming.find((url) => {
    return url.includes('canale_' + canale);
  });
};

export const formatCanaleUrl = (canaleFromApi: string | undefined): string => {
  if (canaleFromApi) {
    return `${CANALE}_${canaleFromApi}`;
  } else {
    return '';
  }
};

export const conditionOnSelectedScommessa = (condition: boolean, length: number) => {
  if (condition) {
    return { firstCondition: length === 1, secondCondition: length === 0 || length >= 2 };
  } else {
    return { firstCondition: length % 3 === 1 || length % 3 === 2, secondCondition: length % 3 === 0 };
  }
};

export const formatKey = (keys: Array<string>): string => {
  return keys.join('_');
};

// from array[ {listaQuota:[{codTipoScommessa}]},{}],
//we need to find which object of listaQuote has codTipoScommessa,
//soo we can find scommessa by esitoId
export const findScommessaFromFaiLaTuaGiocataByKeys = (
  array: Array<GiocataCorsa>,
  tipoScommessaId: string,
  esitoid: string
) => {
  return array.find(
    ({ listaQuote }) => listaQuote.find((quota) => quota.codTipoScommessa === +tipoScommessaId)?.idEsito! === esitoid
  )!;
};

export const findScommessaFromQuoteCombinateListByKey = (
  list: Array<{ title: string; codiceTipoScommessa: number; quotaList: QuotaList }>,
  key: string
) => {
  return list.find((element) => element.codiceTipoScommessa === +key)!;
};

export const retriveDescrizioneEsitoByScommessa = (obj: any) => {
  return obj.nome;
};

export const retriveTicketInfoEsitoFromVirtualScommesse = (
  faiLaTuaGiocata: Array<GiocataCorsa>,
  quoteCombinateList: Array<{
    title: string;
    quotaList: QuotaList;
    codiceTipoScommessa: number;
  }>,
  { tipoScommessaId, esitoId, esitoKey }: { tipoScommessaId: string; esitoId: string; esitoKey: string }
): VirtualTicketInfoEsito => {
  // check if tipoScommessa 1 || 2 || 3
  if (+tipoScommessaId === 1 || +tipoScommessaId === 2 || +tipoScommessaId === 3) {
    // findScommessa from "faiLaTuaGiocata"
    const scommessaCorsa = findScommessaFromFaiLaTuaGiocataByKeys(faiLaTuaGiocata, tipoScommessaId, esitoId);
    // create descrizioneTipoScommessa - quota
    const { label: descrizioneTipoScommessa, quota } = scommessaCorsa.listaQuote.find(
      (elm) => elm.codTipoScommessa === +tipoScommessaId
    )!;
    return {
      id: esitoKey,
      idEsito: +esitoId,
      idTipoScommessa: +tipoScommessaId,
      quota,
      descrizione: retriveDescrizioneEsitoByScommessa(scommessaCorsa),
      descrizioneTipoScommessa: descrizioneTipoScommessa,
      descrizioneEsito: retriveDescrizioneEsitoByScommessa(scommessaCorsa),
    };
  } else {
    // scommessa
    const scommessaCorsa = findScommessaFromQuoteCombinateListByKey(quoteCombinateList, tipoScommessaId);
    // descrizioneTipoScommessa
    const descrizioneTipoScommessa = scommessaCorsa.title;
    // quotaList
    const quotaList = scommessaCorsa?.quotaList?.map((quota) => {
      return {
        ...quota,
        idEsito: quota.label,
        label: quota.label,
        codTipoScommessa: scommessaCorsa.codiceTipoScommessa,
      };
    })!;
    // quoteCombinate to find quota and descrizioneEsito have to compare idEsito, because codTipoScommessa is always the SAME.
    // create descrizioneEsito - quota
    const { label: descrizioneEsito, quota } = quotaList.find((elm) => elm.idEsito === esitoId)!;
    return {
      id: esitoKey,
      idEsito: +esitoId,
      idTipoScommessa: +tipoScommessaId,
      quota,
      descrizione: descrizioneEsito,
      descrizioneTipoScommessa: descrizioneTipoScommessa,
      descrizioneEsito: descrizioneEsito,
    };
  }
};
