/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-235.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco235 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-235', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="35.482"
      viewBox="0 0 41.396 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(7.392 0)">
        <path
          className="a"
          d="M327.873,243.047a.74.74,0,0,1-.523-.217l-5.7-5.7H307.174A5.181,5.181,0,0,1,302,231.959V217.175A5.181,5.181,0,0,1,307.174,212h23.655A5.181,5.181,0,0,1,336,217.175v14.784a5.181,5.181,0,0,1-5.174,5.175h-2.218v5.174a.739.739,0,0,1-.739.739Zm-20.7-29.569a3.7,3.7,0,0,0-3.7,3.7v14.784a3.7,3.7,0,0,0,3.7,3.7h14.784a.741.741,0,0,1,.523.217l4.652,4.652v-4.129a.739.739,0,0,1,.739-.739h2.957a3.7,3.7,0,0,0,3.7-3.7V217.175a3.7,3.7,0,0,0-3.7-3.7Z"
          transform="translate(-302 -212)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(0 11.827)">
        <path
          className="a"
          d="M298.653,251.655a.738.738,0,0,1-.739-.739v-3.7H295.7a3.7,3.7,0,0,1-3.7-3.7V231.7a3.7,3.7,0,0,1,3.7-3.7h1.478a.739.739,0,0,1,0,1.478H295.7a2.22,2.22,0,0,0-2.218,2.218v11.827a2.22,2.22,0,0,0,2.218,2.218h2.957a.739.739,0,0,1,.739.739v2.651l3.174-3.173a.739.739,0,0,1,.523-.217h8.871a2.22,2.22,0,0,0,2.218-2.218.739.739,0,0,1,1.478,0,3.7,3.7,0,0,1-3.7,3.7h-8.565l-4.219,4.219A.739.739,0,0,1,298.653,251.655Z"
          transform="translate(-292 -228)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(17.002 10.349)">
        <path
          className="a"
          d="M326.827,236.349h-8.871A2.96,2.96,0,0,1,315,233.392v-4.435A2.96,2.96,0,0,1,317.957,226h8.871a2.96,2.96,0,0,1,2.957,2.957v4.435A2.96,2.96,0,0,1,326.827,236.349Zm-8.871-8.871a1.48,1.48,0,0,0-1.478,1.478v4.435a1.48,1.48,0,0,0,1.478,1.478h8.871a1.48,1.48,0,0,0,1.478-1.478v-4.435a1.48,1.48,0,0,0-1.478-1.478Z"
          transform="translate(-315 -226)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(19.959 2.957)">
        <path
          className="a"
          d="M327.131,224.871a.739.739,0,0,1-.739-.739v-3.7a2.957,2.957,0,0,0-5.914,0v3.7a.739.739,0,1,1-1.478,0v-3.7a4.435,4.435,0,1,1,8.871,0v3.7A.739.739,0,0,1,327.131,224.871Z"
          transform="translate(-319 -216)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M325.957,231.478a1.478,1.478,0,1,0-2.218,1.273v.945a.739.739,0,1,0,1.478,0v-.945A1.473,1.473,0,0,0,325.957,231.478Z"
        transform="translate(-300.084 -216.694)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco235;
