/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-392.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco392 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-392', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="38.439"
      viewBox="0 0 35.482 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M330,313.959a3.585,3.585,0,0,0,.518-.472,3.713,3.713,0,0,0,.944-2.847,3.8,3.8,0,0,0-3.806-3.335H316.7v-8.131A5.181,5.181,0,0,0,311.524,294h-1.478a.739.739,0,0,0-.739.739v3.515a10.348,10.348,0,0,1-1.535,5.424l-3.715,6.036a2.941,2.941,0,0,0-2.142-.93h-2.957A2.96,2.96,0,0,0,296,311.741v17.741a2.96,2.96,0,0,0,2.957,2.957h2.957a2.951,2.951,0,0,0,2.416-1.261,3.675,3.675,0,0,0,2.759,1.261h16.136a3.8,3.8,0,0,0,3.806-3.335,3.717,3.717,0,0,0-.724-2.581,3.791,3.791,0,0,0,3.681-3.333,3.717,3.717,0,0,0-.934-2.836,3.661,3.661,0,0,0,1.469-5.923A3.634,3.634,0,0,0,330,313.959Zm-26.611,15.524a1.48,1.48,0,0,1-1.478,1.478h-2.957a1.48,1.48,0,0,1-1.478-1.478V311.741a1.48,1.48,0,0,1,1.478-1.478h2.957a1.481,1.481,0,0,1,1.478,1.478ZM324.829,314.7h2.957a2.217,2.217,0,0,1,2.207,2.438,2.3,2.3,0,0,1-2.334,2H324.09a.739.739,0,1,0,0,1.478h2.218a2.217,2.217,0,0,1,2.207,2.438,2.3,2.3,0,0,1-2.334,2h-3.569a.739.739,0,1,0,0,1.478h.739a2.217,2.217,0,0,1,2.207,2.438,2.3,2.3,0,0,1-2.334,2H307.088a2.22,2.22,0,0,1-2.218-2.218V311.211l4.159-6.758a11.823,11.823,0,0,0,1.755-6.2v-2.776h.739a3.7,3.7,0,0,1,3.7,3.7v8.131a1.48,1.48,0,0,0,1.478,1.478h10.961a2.305,2.305,0,0,1,2.334,2,2.217,2.217,0,0,1-2.207,2.439h-2.957a.739.739,0,0,0,0,1.478Z"
        transform="translate(-296 -294)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco392;
