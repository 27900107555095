import {
  BANNER_INSIDE_LIST_CMS,
  BANNER_INSIDE_LIST_PROMO,
  BANNER_ON_PAGE_CMS,
  BANNER_ON_PAGE_PROMO,
  BANNER_UNIQUE_MODEL_API_KEY,
  HERO_BANNER_CMS,
  HERO_BANNER_PROMO,
  PROMO_MODEL_API_KEY,
} from 'lib/datoCms/constants';
import {
  DatoCmsBanner,
  DatoCmsMenuItemBlock,
  DatoCmsSportManifestazionePage,
  DatoCmsVisualComposer,
} from '../lib/datoCms/types';
import { DatoCmsGamesPage, DatoCmsSportPage } from 'lib/types/page';
import { IppicaCurrency, IppicaLocale } from 'features/ippica/types';
import Persist, { StorageKind } from 'lib/persist';
import { SESSION_UID, isHappybetSite, isSnaiSite } from 'utility/constant';
import { Time, stringDictionary } from './types';
import { convertInBannerType, updateVisualComposer } from '../lib/datoCms/utils';
import { PHASE_PRODUCTION_BUILD } from 'next/constants';

import unidecode from 'unidecode';

import { AppFragment } from 'enums/path-fragment';
import { MessageCategoryResponseApi } from 'lib/api/messageCategoryResponseApi';
import { PrivacyResponseApi } from 'lib/api/privacyResponseApi';
import { ProfileContractEditRequest } from 'types/dashboard/profileContractEditRequest';
import { PromoEngineResponseApi } from 'lib/promoEngine/types';
import { StaticPageCms } from 'lib/datoCms/queries/getStaticPage';
import { UsersContractResDto } from 'types/swagger';
import { VisualComposer } from 'components/visualComposer';
import { getTypeFromListOfKeys } from '../components/page-content/slot-top/utils/utils';
import raise from './raise';
import policy from 'lib/policy/types';

const { DIGITAL_ASSISTANT_URL } = policy;

export const isClientSide = (): boolean => {
  if (typeof window !== 'undefined' && window instanceof Window) {
    document.documentElement.style.setProperty(
      '--scrollbar-width',
      `${window.innerWidth - document.documentElement.offsetWidth}px`
    );
    return true;
  }
  return false;
};

export const hasValue = <T>(input: T): boolean => {
  return !['null', 'empty', 'undefined'].includes(`${input}`);
};

export const purgeNulls = <T>(input: T): Partial<T> | undefined => {
  let propCounter = 0;

  let result: any = Array.isArray(input) ? [] : {};
  for (let fieldName of Object.keys(input ?? {})) {
    let newValue: any | null | undefined = null;
    if (typeof (input[fieldName] ?? '') === 'object') {
      newValue = purgeNulls(input[fieldName]);
    } else {
      newValue = input[fieldName];
    }

    if (hasValue(`${newValue}`)) {
      result[fieldName] = newValue;
      propCounter++;
    }
  }

  if (propCounter === 0) return undefined;

  return result as Partial<T>;
};

export const isTruthy = (input?: boolean | string | number | null): boolean => {
  switch (typeof input) {
    case 'boolean':
      return input === true;
    case 'number':
      return input > 0;
    case 'string':
      return (input.match(/^(true|y|1|s|on)$/gim) ?? []).length > 0;
    default:
      return false;
  }
};

export const isProductionBuildPhase = () => process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD;

export const skipStaticPageGeneration = () => {
  if (isProductionBuildPhase()) {
    return isTruthy(process.env.SKIP_BUILD_STATIC_GENERATION);
  }
  return true;
};

export const isMatch = (input: string | undefined, pattern: string | undefined, opt = 'gim'): boolean => {
  if (!isTruthy(input?.length)) return false;
  if (!isTruthy(pattern?.length)) return false;

  const rgx = new RegExp(pattern!, opt);

  return isTruthy((input!.match(rgx) ?? []).length);
};

export const capitalize = (input?: string | null | undefined): string | null | undefined => {
  if (!isTruthy(`${input ?? ''}`.length)) return input;

  const result = input!
    .toLowerCase()
    .split(' ')
    .map((word: string) => {
      const [w, ...ord] = word.split('');
      return [w?.toUpperCase(), ...ord].join('');
    })
    .join(' ');

  // console.log(`capitalize(${input}) => ${result}`);

  return result;
};

export const toSportUrlCase = (input?: string) => {
  if (!input) return input;

  let rewrite = isMatch(input, `^(/?)(${AppFragment.Sport}|${AppFragment.Live})/`);

  if (rewrite && isMatch(input, `^(/?)(${AppFragment.Sport}.info)(/?)`)) {
    rewrite = false;
  }

  if (rewrite) {
    const parts = input
      .trim()
      .split('/')
      .filter((x) => !!x);
    const [root, ...oth] = parts;
    return ['', root?.toLowerCase(), ...oth.map((x) => x.toUpperCase())].filter((x) => hasValue(x)).join('/');
  }
  return `${input?.[0] === '/' ? '' : '/'}${input}`.toLowerCase();
};

export const getSessionId = (): string => {
  const myUID = Persist(StorageKind.session);
  let sessionId = myUID.getItem(SESSION_UID);
  if (!isTruthy(sessionId?.length)) {
    sessionId = crypto.randomUUID();
    myUID.setItem(SESSION_UID, sessionId);
  }
  return `${sessionId}`;
};

function dynamicDataLoader<T extends object, R>(_root: T, _path: Array<string>, _allowUndefined?: false): R;
function dynamicDataLoader<T extends object, R>(_root: T, _path: Array<string>, _allowUndefined: true): R | undefined;
function dynamicDataLoader<T extends object, R>(root: T, path: Array<string>, allowUndefined?: boolean): R {
  let result = root;

  for (const step of path) {
    if (!Object.keys(result).includes(step) && !allowUndefined) {
      if (!allowUndefined) raise(`dynamicDataLoader: ${step} is invalid resolving ${path.join('.')}`);
      return undefined as unknown as R;
    }
    result = result[step] as T;
  }

  return result as unknown as R;
}

export { dynamicDataLoader };

export const isInternalLink = (href?: string) => {
  const my = [`${process.env.NEXT_PUBLIC_BASE_URL}`, '/(([A-z0-9-%]+/)*[A-z0-9-%]+$)?'];
  if (isClientSide()) {
    my.splice(0, 0, `${document?.location?.origin}`);
  } else {
    my.splice(0, 0, `${process.env.BASE_URL}`);
  }

  if (my.some((x) => isMatch(href, `^${x}`))) {
    return true;
  }
  return false;
};

export const concatString = (tipo: string, modelApiKey: string, delimiter: string = '_'): string => {
  return `${tipo}${delimiter}${modelApiKey}`;
};

export const convertInCurrencyValue = (amount: number, currency: 'EUR' | string = 'EUR', minDecimalDigits?: number) => {
  let euro = Intl.NumberFormat('it-IT', {
    useGrouping: true,
    style: 'currency',
    currency: currency,
    minimumFractionDigits: minDecimalDigits ?? 2,
  });
  return euro.format(amount);
};

export const isItalianAvvenimento = (nation?: string) => {
  if (!nation) return false;

  return nation === 'ITALIA';
};

export const convertAmountWithoutCurrencyValue = (amount: number, locales: IppicaLocale) => {
  let valueFormat = Intl.NumberFormat(locales, {
    useGrouping: true,
    minimumFractionDigits: 2,
  });
  return valueFormat.format(amount);
};
export const convertAmountInCurrenyValue = (amount: number, locales: IppicaLocale, currency: IppicaCurrency) => {
  let valueFormat = Intl.NumberFormat(locales, {
    useGrouping: true,
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
  });
  return valueFormat.format(amount);
};

export interface CurrencyInfoType {
  currencyPrize: string | '';
  nation?: string;
}

export const convertInCurrencyValueByPrizeInfo = (amount: number, currencyInfo: CurrencyInfoType) => {
  let locales: IppicaLocale | undefined;
  const { nation, currencyPrize } = currencyInfo ?? {};

  switch (currencyPrize) {
    case IppicaCurrency.EUR:
      locales = IppicaLocale.it;
      return convertAmountInCurrenyValue(amount, locales, currencyPrize);
    case IppicaCurrency.ZAR:
      locales = IppicaLocale.za;
      return convertAmountInCurrenyValue(amount, locales, currencyPrize);
    case IppicaCurrency.YEN:
      locales = IppicaLocale.jp;
      return convertAmountInCurrenyValue(amount, locales, currencyPrize);
    case IppicaCurrency.USD:
      locales = IppicaLocale.us;
      return convertAmountInCurrenyValue(amount, locales, currencyPrize);
    case IppicaCurrency.GBP:
      locales = IppicaLocale.gb;
      return convertAmountInCurrenyValue(amount, locales, currencyPrize);
    case '':
      locales = IppicaLocale.it;
      return isItalianAvvenimento(nation)
        ? convertAmountInCurrenyValue(amount, locales, IppicaCurrency.EUR)
        : convertAmountWithoutCurrencyValue(amount, locales);
    default:
      return amount;
  }
};

// export const getLabelByKey = (labels: Label[], key: string) => {
//   const label = labels.find((label: Label) => label.key === key);
//   return label ? label.value : '';
// };

export const handleBannersInVisualComposer = (
  banners: DatoCmsBanner[] | undefined,
  visualComposer: DatoCmsVisualComposer
) => {
  if (banners) {
    return {
      ...updateVisualComposer(visualComposer, convertInBannerType(banners[0])),
    };
  }
  return;
};

export const handleConsoleGameInVisualComposer = <T extends Object, K extends Array<any>>(
  visualComposer: DatoCmsVisualComposer,
  catalogResponseData: T,
  games: K | undefined,
  urlParam: string
): VisualComposer<T> | undefined => {
  const middlewareListOfKeys = Object.keys(catalogResponseData);
  if (visualComposer.typeOfSlot === getTypeFromListOfKeys(middlewareListOfKeys, visualComposer.typeOfSlot)) {
    return {
      ...updateVisualComposer(visualComposer, games, urlParam),
    };
  }
  return;
};

export const tranformSlugInListName = (dictionary: stringDictionary, slug: string) => {
  return dictionary[slug] ?? dictionary['slot-tutte'];
};

export const checkCurrentDateInARange = (startIntervalDate: string, endIntervalDate: string) => {
  const today = new Date();
  const openTime = new Date(startIntervalDate);
  const closeTime = new Date(endIntervalDate);

  return today >= openTime && today < closeTime ? true : false;
};

export const formateDate = (date: string) => {
  let objectDate = new Date(date);
  let day = objectDate.getDate();
  let month = objectDate.getMonth() + 1;
  let year = objectDate.getFullYear();
  return day + '/' + month + '/' + year;
};

export const calculateInitialTime = ({ days, hours, minutes, seconds }: Time): number => {
  const initialDays = (days ?? 0) * 24 * 60 * 60 * 1000;
  const initialHours = (hours ?? 0) * 60 * 60 * 1000;
  const initialMinutes = minutes * 60 * 1000;
  const initialSeconds = seconds * 1000;
  const initialTime = initialDays + initialHours + initialMinutes + initialSeconds;
  return initialTime;
};
export const calculateRemainingDays = (remainingTime: number): number =>
  Math.floor(remainingTime / (24 * 60 * 60 * 1000));

export const calculateRemainingHours = (remainingTime: number, canExceedDay: boolean = false): number => {
  if (canExceedDay) {
    return Math.floor(remainingTime / (1000 * 60 * 60));
  }
  return Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
};

export const calculateRemainingMinutes = (remainingTime: number): number =>
  Math.floor((remainingTime / 1000 / 60) % 60);
export const calculateRemainingSeconds = (remainingTime: number): number => Math.floor((remainingTime / 1000) % 60);

export const convertMillisecondsInTime = (difference: number) => {
  // calculate time left in days, hours, minutes,seconds
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export const conditionsToVisualizeBanner = (
  page: DatoCmsGamesPage | DatoCmsSportPage | DatoCmsSportManifestazionePage,
  bannerData: {
    type: string;
    data?: PromoEngineResponseApi;
    hasError?: boolean;
  }
): boolean | undefined => {
  try {
    let condition: boolean | undefined;
    switch (bannerData?.type) {
      // PROMO ENGINE
      case HERO_BANNER_PROMO:
        condition =
          isTruthy(page?.heroBanner?.length) && // if banner field exist in cms
          page?.heroBanner?.[0]._modelApiKey === PROMO_MODEL_API_KEY && // if modelApikey is 'promo'
          !bannerData.hasError && // if redux query has no error
          bannerData.data && // if data from query exist
          !!bannerData.data.heroBannerList?.length; // if data in heroBannerList exist
        break;
      case BANNER_ON_PAGE_PROMO:
        condition = bannerData.data && isTruthy(bannerData?.data?.pageBannerList?.length) && !bannerData.hasError;
        break;
      case BANNER_INSIDE_LIST_PROMO:
        condition =
          isTruthy(page?.bannerInsideList?.length) &&
          page?.bannerInsideList?.[0]._modelApiKey === PROMO_MODEL_API_KEY &&
          bannerData.data &&
          !!bannerData.data.portraitBanner &&
          !bannerData.hasError;
      // CMS
      case HERO_BANNER_CMS:
        condition =
          isTruthy(page?.heroBanner?.length) && page?.heroBanner?.[0]?._modelApiKey === BANNER_UNIQUE_MODEL_API_KEY;
        break;
      case BANNER_INSIDE_LIST_CMS:
        condition = page?.bannerInsideList?.[0]?._modelApiKey === BANNER_UNIQUE_MODEL_API_KEY;
        break;
      case BANNER_ON_PAGE_CMS:
        condition =
          isTruthy(page?.bannerOnPage?.length) &&
          isTruthy(page?.bannerOnPage?.[0]?.banners?.length) &&
          page?.bannerOnPage?.[0]?._modelApiKey === BANNER_UNIQUE_MODEL_API_KEY;
        break;
      default:
        false;
        break;
    }
    return condition!;
  } catch (e) {
    console.error('conditionsToVisualizeBanner:', e);
    return false;
  }
};

// eslint-disable-next-line no-unused-vars
export function debounce<Args extends unknown[]>(fn: (...args: Args) => void, delay: number = 1000) {
  let timeoutID: NodeJS.Timeout | undefined;

  const debounced = (...args: Args) => {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => fn(...args), delay);
  };

  return debounced;
}

export function trasformMessagesCategoryToDatoMenuArrayBlock(
  categories: MessageCategoryResponseApi[]
): DatoCmsMenuItemBlock[] {
  if (categories.length === 0) return [];
  return categories.map((category) => {
    return trasformMessageCatItemToDatoMenuItemBlock(category);
  });
}

export function trasformMessageCatItemToDatoMenuItemBlock(item: MessageCategoryResponseApi): DatoCmsMenuItemBlock {
  return {
    id: item.id_tipologia,
    label: item.descr_tipologia,
    linkBlock: [
      {
        id: item.id_tipologia,
        href: `/dashboard/messaggi-da-snai/${item.id_tipologia}`,
      },
    ],
  };
}

export function populateProfileContractEditRequest(
  profile: UsersContractResDto & {
    risposta?: string | null;
    cod_domanda?: string | number | null;
  },
  csmfCod?: string,
  privacyResponse?: PrivacyResponseApi
): ProfileContractEditRequest {
  const result = {
    csmf_cod: csmfCod, //*
    idSessione: '',
    nome: profile.nome,
    cognome: profile.cognome,
    dataNascita: profile.dataNascita,
    comuneNascita: profile.luogoNascita,
    provinciaNascita: profile.provinciaNascita,
    indirizzoResidenza: profile.indirizzoResidenza, //*
    provinciaResidenza: profile.provinciaResidenza, //*
    comuneResidenza: profile.comuneResidenza, //*
    capResidenza: profile.capResidenza, //*
    indirizzoDomicilio: profile.indirizzoInfo, //*
    provinciaDomicilio: profile.provinciaInfo, //*
    comuneDomicilio: profile.comuneInfo, //*
    capDomicilio: profile.capInfo, //*
    cf: profile.cf,
    codiceTipoDoc: Number(profile.codiceTipoDoc),
    codiceTipoAutorita: profile.autoritaRilascioDoc,
    numeroDoc: profile.numeroDoc,
    dataRilascioDoc: profile.dataRilascioDoc,
    dataScadenzaDoc: profile.dataScadenzaDoc,
    luogoRilascioDoc: profile.luogoRilascioDoc,
    sesso: Number(profile.sesso),
    email: profile.email, //*
    telefonoMobile: profile.telefonoMobile, //*
    flagMarketing: privacyResponse?.flag_marketing ? Number(privacyResponse?.flag_marketing) : 0, //*
    flagProfilazione: privacyResponse?.flag_profilazione ? Number(privacyResponse?.flag_profilazione) : 0, //*
    codicePromo: '',
    username: '', //*
    password: '',
    limiteVersamento: 0,
    provenienza: '',
    spidAccess: true,
    codiceIobb: '',
    firstReferral: '',
    lastReferral: '',
    firstPage: '',
    idOtpEmail: '',
    idOtpTelefono: '',
    tipoinvio: 'mail', //* valori ammessi: mail/sms
  };

  if (profile.risposta) {
    Reflect.set(result, 'rispostaDomandaSegreta', profile.risposta);
  }

  const codiceTipoDomandaSegreta = Number(`${profile.cod_domanda}`);
  if (!isNaN(codiceTipoDomandaSegreta)) {
    Reflect.set(result, 'codiceTipoDomandaSegreta', codiceTipoDomandaSegreta);
  }

  return result;
}

export const getAmount = (amount?: string | number | null) => {
  if (!amount) return 0;
  if (amount === '0' || amount === 0) return 0;
  return Number(amount) / 100;
};

export const handleWheelScroll = (containerRef: React.RefObject<HTMLElement>, e: React.WheelEvent<HTMLElement>) => {
  const container = containerRef.current;
  const delta = Math.sign(e.deltaY);

  if (container) {
    container.scrollLeft += delta * 100;
  }
};

export const detectMob = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const isString = (value: string | number): boolean => {
  return /[a-zA-Z]/.test(value as any); // FORCE CHECK
};

export const isPreProd = (): boolean => {
  return (
    (process.env.NEXT_API_BASE_URL?.includes('nsapipreprod') ||
      process.env.NEXT_API_BASE_URL?.includes('apipreview')) ??
    false
  );
};

export const detectCountryInitials = (nazione: string) => {
  const arrayNazione = nazione.split(' ');
  if (arrayNazione.length > 1) {
    return arrayNazione.map((parola) => parola[0].toUpperCase()).join('');
  } else {
    return arrayNazione[0].substring(0, 2).toUpperCase();
  }
};

export const calculateArraySingleHeaderWidth = (
  tipoVisualizzazione: number,
  sizeInfoAggiuntive: string[],
  numeroEsiti: number,
  headers: string[],
  isMarcatore: boolean = false
): Array<string | number> => {
  if (isMarcatore) {
    switch (tipoVisualizzazione) {
      case 6:
        const result = Array.from({ length: numeroEsiti }, () => 75);
        return result.map((item, index) => (index !== 0 ? item + 8 : item));
      case 7:
        const sizes: number[] = [];
        // headers?.some((header) => isString(header)) ? sizes.push(120) : sizes.push(64);
        sizeInfoAggiuntive[0] === 'small' || sizeInfoAggiuntive[0] === undefined ? sizes.push(64) : sizes.push(120);
        sizes.push(75);
        return sizes;
    }
  }

  switch (tipoVisualizzazione) {
    case 1:
      let sizes1: number[] = Array.from({ length: numeroEsiti }, () => 54);
      return sizes1;
    case 2:
      let sizes2: number[] = [];
      headers?.some((header) => isString(header)) ? sizes2.push(120) : sizes2.push(64);
      sizes2.push(54);
      return sizes2;
    case 3:
      return ['100%'];
    case 4:
      let sizes4: number[] = [];
      sizeInfoAggiuntive[0] === 'small' || sizeInfoAggiuntive[0] === undefined ? sizes4.push(64) : sizes4.push(120);
      sizes4 = [...sizes4, ...Array.from({ length: numeroEsiti }, () => 54)];
      return sizes4;
    case 5:
      let sizes5: number[] = [];
      sizeInfoAggiuntive[0] === 'small' || sizeInfoAggiuntive[0] === undefined ? sizes5.push(64) : sizes5.push(120);
      sizes5.push(headers?.some((header) => isString(header)) ? 120 : 64);
      sizes5.push(54);
      return sizes5;
    case 6:
      return [
        ...sizeInfoAggiuntive.map((item) => (item === 'small' || item === undefined ? 64 : 120)),
        ...Array.from({ length: numeroEsiti }, () => 54),
      ];
    case 7:
      let sizes7: number[] = [];
      headers?.some((header) => isString(header)) ? sizes7.push(97) : sizes7.push(64);
      sizes7.push(54);
      return [...sizeInfoAggiuntive.map((item) => (item === 'small' || item === undefined ? 64 : 120)), ...sizes7];

    default:
      return ['auto']; // auto is dangerous for computation
  }
};

export const calculateHeaderWidth = (
  tipoVisualizzazione: number,
  sizeInfoAggiuntive: string[],
  numeroEsiti: number,
  headers: string[],
  isMarcatore: boolean = false
): string => {
  const results =
    calculateArraySingleHeaderWidth(tipoVisualizzazione, sizeInfoAggiuntive, numeroEsiti, headers, isMarcatore) ?? [];

  if (results.some((x) => typeof x !== 'number')) return 'auto';

  const numbers = results?.map((x) => parseFloat(`${x}`));

  const computed = numbers?.reduce((acc, value) => parseFloat(`${acc}`) + parseFloat(`${value}`), 0);
  return `${computed}px`;
};

export const toQueryString = (rawQuery?: Record<string | number, any>): string => {
  const encodeQueryParam = (key: string, value: any): string => {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  };

  const addArrayQueryParam = (query: Record<string | number, any>, key: string): string => {
    const value = query[key];
    return value.map((v: any) => encodeQueryParam(key, v)).join('&');
  };

  const addQueryParam = (query: Record<string | number, any>, key: string): string => {
    const value = query[key];
    if (typeof value === 'object') {
      return toQueryString(value);
    } else {
      return encodeQueryParam(key, value);
    }
  };

  const query = rawQuery || {};
  const result = Object.keys(query)
    .filter((key) => typeof query[key] !== 'undefined')
    .map((key) => (Array.isArray(query[key]) ? addArrayQueryParam(query, key) : addQueryParam(query, key)))
    .filter((x) => !!x)
    .join('&');

  return result;
};

type GtagEvent = {
  section: string;
  detail: string;
  event: string;
  eventCategory?: string;
};

export const gtag = (event: GtagEvent, onlySnai?: boolean, onlyHappybet?: boolean) => {
  const doApply = () => {
    if (!isClientSide()) return;
    window.dataLayer = window.dataLayer ?? [];
    window.dataLayer.push({ ...event, event_category: event.eventCategory });
  };

  if (!onlyHappybet && onlySnai && isSnaiSite) {
    doApply();
    return;
  }

  if (!onlySnai && onlyHappybet && isHappybetSite) {
    doApply();
    return;
  }

  doApply();
};

export const createPaths = (elements: StaticPageCms[]): string[] => {
  const paths: string[] = [];

  // Per ogni elemento, genera il percorso e aggiungilo alla lista dei percorsi
  elements?.forEach((element) => {
    let current = element;
    let path = '/' + current.slug;
    // Costruisci il percorso risalendo attraverso i genitori fino alla radice
    while (current && current.parent) {
      path = `/${current.parent.slug}${path}`;
      current = current.parent as StaticPageCms;
    }
    // Aggiungi il percorso all'array dei percorsi
    if (path !== '') paths.push(path);
  });

  return paths;
};

export const popupCenter = ({ url, title }: { url: string; title: string }) => {
  const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const widthNewWindow = width - width / 3;
  const heightNewWindow = height - height / 3;

  var left = width / 2 - widthNewWindow / 2 + dualScreenLeft;
  var top = height / 2 - heightNewWindow / 2 + dualScreenTop;
  var newWindow = window.open(
    url,
    title,
    'scrollbars=yes, width=' + widthNewWindow + ', height=' + heightNewWindow + ', top=' + top + ', left=' + left
  );

  if (newWindow) {
    newWindow.focus();
  }
};

export const domChildrenCount = <T>(container: T | null, selector: string) => {
  if (container instanceof HTMLElement) {
    return container.querySelectorAll(selector).length;
  }
  return 0;
};
export const domContains = <T>(container: T, selectors: Array<string>) => {
  for (const selector of selectors) {
    if (domChildrenCount(container, selector) > 0) return true;
  }
  return false;
};

export const formatAmount = (amount?: string | number, currency: string = 'EUR') => {
  const formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: currency,

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });
  const toNumber = Number(amount);
  if (!isNaN(toNumber)) return formatter.format(toNumber);
  else return formatter.format(0);
};

export const myUnidecode = (input: string) => {
  // transform accent to apex for last char
  // unidecode the string

  const progress = input
    .replace(/(à|á)+(\s+|$)/gim, "a'")
    .replace(/(è|é)+(\s+|$)/gim, "e'")
    .replace(/(ì|í)+(\s+|$)/gim, "i'")
    .replace(/(ò|ó)+(\s+|$)/gim, "o'")
    .replace(/(ù|ú)+(\s+|$)/gim, "u'");

  return unidecode(progress);
};

export const isMobileBrowser = () => {
  var userAgent = navigator.userAgent || navigator.vendor;
  var mobileRegex = /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini|opera mobi|webos|fennec/i;
  return mobileRegex.test(userAgent);
};

export function killDigitalAssistant() {
  const { DA } = window as any;
  DA?.hide();
  DA?.disconnect();
  delete window.DA;

  var scripts = document.getElementsByTagName('script');
  for (var i = 0; i < scripts.length; i++) {
    var script = scripts[i];

    if (script.getAttribute('src')?.includes(DIGITAL_ASSISTANT_URL.replace('?view.mobileMode=detect', ''))) {
      script.parentNode?.removeChild(script);
    }
  }
  var script = document.createElement('script');
  script.src = DIGITAL_ASSISTANT_URL;
  document.head.appendChild(script);
}
