/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-bh.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneBh = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-bh', className].join(' ');
  return (
    <svg
      id="nazione-bh"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <path d="M0 0h16v16H0" fill="#fff" />
        <path
          d="M16 0H3.2l2.606 1.6L3.2 3.2l2.606 1.6L3.2 6.4 5.806 8 3.2 9.6l2.606 1.6L3.2 12.8l2.606 1.6L3.2 16H16"
          fill="#CE1126"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="16" height="16" rx="8" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NazioneBh;
