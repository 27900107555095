import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';
import { GamesSkillGameDto } from 'types/swagger';
import { SlotToggleFavourite, SlotToggleFavouriteType } from './actions';
import { Filters } from './types';
import { calculateAllSelectedFiltersTogether, removeValueFromSelectedFilters } from './utils/utils';

interface SlotArchiveSliceState {
  allItems: Array<GamesSkillGameDto>;
  pageNumber: number;
  updatingItems: Record<string, boolean>;
  selectedFilters: Filters;
  selectedFiltersLength: number;
}

const emptyFilterState = {
  lines: [],
  producer: [],
  category: [],
  volatility: [],
  functionality: [],
};

const initialState: SlotArchiveSliceState = {
  allItems: [],
  pageNumber: 1,
  updatingItems: {},
  selectedFilters: emptyFilterState,
  selectedFiltersLength: 0,
};

const slotArchiveSlice = createSlice({
  name: 'archiveSlot',
  initialState,
  reducers: {
    setFunctionalityValue: (state, action: PayloadAction<string>) => {
      state.selectedFilters.functionality.push(action.payload);
    },
    unsetFunctionalityValue: (state, action: PayloadAction<string>) => {
      state.selectedFilters.functionality = state.selectedFilters.functionality.filter(
        (item: any) => item !== action.payload
      );
    },
    setVolatilityValue: (state, action: PayloadAction<string>) => {
      state.selectedFilters.volatility.push(action.payload);
    },
    unsetVolatilityValue: (state, action: PayloadAction<string>) => {
      state.selectedFilters.volatility = state.selectedFilters.volatility.filter(
        (item: any) => item !== action.payload
      );
    },
    setLinesValue: (state, action: PayloadAction<string>) => {
      state.selectedFilters.lines.push(action.payload);
    },
    unsetLinesValue: (state, action: PayloadAction<string>) => {
      state.selectedFilters.lines = state.selectedFilters.lines.filter((item: any) => item !== action.payload);
    },
    setProducerValue: (state, action: PayloadAction<string>) => {
      state.selectedFilters.producer.push(action.payload);
    },
    unsetProducerValue: (state, action: PayloadAction<string>) => {
      state.selectedFilters.producer = state.selectedFilters.producer.filter((item: any) => item !== action.payload);
    },
    setCategoryValue: (state, action: PayloadAction<string>) => {
      state.selectedFilters.category.push(action.payload);
    },
    unsetCategoryValue: (state, action: PayloadAction<string>) => {
      state.selectedFilters.category = state.selectedFilters.category.filter((item: any) => item !== action.payload);
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    incrementPageNumber: (state) => {
      state.pageNumber = state.pageNumber + 1;
    },
    resetFilters: (state) => {
      state.selectedFilters = emptyFilterState;
    },
    removeFilter: (state, { payload }: PayloadAction<string>) => {
      const filtersOri = state.selectedFilters ?? emptyFilterState;
      const filtersNext = removeValueFromSelectedFilters(filtersOri, payload);

      if (!shallowEqual(filtersOri, filtersNext)) {
        state.pageNumber = 1;
        state.selectedFilters = filtersNext;
        state.selectedFiltersLength = calculateAllSelectedFiltersTogether(filtersNext).length;
      }
    },
    setSelectedFiltersLenght: (state, action: PayloadAction<number>) => {
      state.selectedFiltersLength = action.payload;
    },
    setAllItems: (state, action: PayloadAction<Array<GamesSkillGameDto>>) => {
      state.allItems.splice(0, state.allItems.length, ...action.payload);
    },
    setIncrementalItems: (state, action: PayloadAction<Array<GamesSkillGameDto>>) => {
      state.allItems.push(...action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SlotToggleFavourite.pending.type, (state, action: any) => {
        const { id } = action.meta.arg ?? {};
        state.updatingItems[id] = true;
      })
      .addCase(SlotToggleFavourite.rejected.type, (state, action: any) => {
        const { id } = action.meta.arg ?? {};
        delete state.updatingItems[id];
      })
      .addCase(SlotToggleFavourite.fulfilled.type, (state, action: PayloadAction<SlotToggleFavouriteType>) => {
        const { id, isFavorite } = action.payload;
        const idx = state.allItems.findIndex((item) => item.id === id);
        if (idx > -1) {
          state.allItems[idx].isFavorite = isFavorite;
        }
        delete state.updatingItems[id];
      });
  },
});

export const {
  setFunctionalityValue,
  unsetFunctionalityValue,
  setVolatilityValue,
  unsetVolatilityValue,
  resetFilters,
  removeFilter,
  setLinesValue,
  unsetLinesValue,
  setProducerValue,
  unsetProducerValue,
  setCategoryValue,
  unsetCategoryValue,
  setPageNumber,
  incrementPageNumber,
  setSelectedFiltersLenght,
  setAllItems,
  setIncrementalItems,
} = slotArchiveSlice.actions;

export default slotArchiveSlice.reducer;
