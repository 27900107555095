import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IconsEnum } from 'components/Icons';
import { ButtonMsgCenter } from 'features/signup/types';
import { MyAvatar } from 'lib/api/avatarsResponseApi';
import { RootState } from 'lib/centralStore';
// Define a type for the slice state
export interface DashBoardState {
  stringAutoesclusion?: string | undefined;
  captainUpError?: boolean | undefined;
  alertMessage?:
    | {
        icon: IconsEnum;
        message: string;
        color: string;
        btn?: ButtonMsgCenter[];
        animation?: boolean;
      }
    | undefined;
  injectDigitalAssistantCompleted?: boolean;
  isSaldoVisible: boolean;
  avatar?: MyAvatar;
  saldo: number;
  bonusGold: number;
  updateWalletTimestamp?: number;
  saldoPrelevabile?: number;
}

// Define the initial state using that type
export const initialState: DashBoardState = {
  isSaldoVisible: true,
  saldo: 0,
  bonusGold: 0,
};

export const dashBoardSlice = createSlice({
  name: 'dashboardSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAutoEsclusionString: (state, action: PayloadAction<string | undefined>) => {
      state.stringAutoesclusion = action.payload;
    },
    setCaptainUpError: (state, action: PayloadAction<boolean | undefined>) => {
      state.captainUpError = action.payload;
    },
    setAlertMessage: (
      state,
      action: PayloadAction<
        | {
            icon: IconsEnum;
            message: string;
            btn?: ButtonMsgCenter[];
            color: string;
            animation?: boolean;
          }
        | undefined
      >
    ) => {
      state.alertMessage = action.payload;
    },
    setInjectDigitalAssistantCompleted: (state, action: PayloadAction<boolean | undefined>) => {
      state.injectDigitalAssistantCompleted = action.payload;
    },
    resetStateDashboard: () => initialState,
    setSaldoVisibility: (state, action: PayloadAction<boolean>) => {
      state.isSaldoVisible = action.payload;
    },
    setAvatar: (state, action: PayloadAction<MyAvatar | undefined>) => {
      state.avatar = action.payload;
    },
    setWalletData: (
      state,
      action: PayloadAction<{
        saldo: number;
        bonusGold: number;
        saldoPrelevabile?: number;
        updateWalletTimestamp?: number;
      }>
    ) => {
      state.saldo = action.payload.saldo;
      state.bonusGold = action.payload.bonusGold;
      state.saldoPrelevabile = action.payload.saldoPrelevabile;
      state.updateWalletTimestamp = action.payload.updateWalletTimestamp;
    },
    setUpdateWalletTimestamp: (state, action: PayloadAction<number | undefined>) => {
      state.updateWalletTimestamp = action.payload;
    },
  },
});

export const {
  setAutoEsclusionString,
  setCaptainUpError,
  setAlertMessage,
  setInjectDigitalAssistantCompleted,
  resetStateDashboard,
  setSaldoVisibility,
  setAvatar,
  setWalletData,
  setUpdateWalletTimestamp,
} = dashBoardSlice.actions;

export default dashBoardSlice.reducer;

export const selectAlertMessage = (store: RootState) => store.dashboard.alertMessage;
