/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-de.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneDe = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-de', className].join(' ');
  return (
    <svg
      id="nazione-de"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path fill="#fff" fillRule="evenodd" d="M0 0h16v16H0V0Z" clipRule="evenodd" />
          <path fill="#002654" fillRule="evenodd" d="M0 0h5.334v16H0V0Z" clipRule="evenodd" />
          <path fill="#CE1126" fillRule="evenodd" d="M10.666 0H16v16h-5.334V0Z" clipRule="evenodd" />
          <g clipPath="url(#c)">
            <path fill="#FFCE00" d="M0 10.666h16V16H0v-5.334Z" />
            <path fill="#000" d="M0 0h16v5.334H0V0Z" />
            <path fill="#D00" d="M0 5.334h16v5.332H0V5.334Z" />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="16" height="16" fill="#fff" rx="8" />
        </clipPath>
        <clipPath id="c">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NazioneDe;
