/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-100.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina100 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-100', className].join(' ');
  return (
    <svg
      id="disciplina_sambo"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M12.992 15.67h4.136l.837 4.558-8.229 1.653c-.046-.817-.096-1.614-.143-2.41-.006-.1-.004-.2-.008-.3-.015-.366-.149-.783-.017-1.084.216-.497.605-.918.949-1.411l2.376 3.609 2.217-1.384-2.118-3.23Zm1.32 3.058-2.39-3.656h5.273v-1.46H6.479v1.484h2.635l-1.09 1.573 1.243.773 1.266-1.775 2.534 3.833 1.245-.772Zm-5.446 2.447c.011-.138.023-.235.027-.333.031-.867.053-1.734.094-2.6.01-.216-.05-.341-.24-.453-.516-.302-1.015-.627-1.54-.955l.814-1.176c-.311 0-.566.013-.818-.003-.22-.014-.311.061-.35.285-.256 1.453-.526 2.906-.801 4.406l2.814.829Zm2.641-13.883c-.446-1.36-.902-2.719-1.354-4.078a683.57 683.57 0 0 0-.369-1.111c-.447.14-.863.264-1.268.412-.083.03-.18.16-.18.245a.46.46 0 0 1-.015.11l-3.289-.193.797 1.37c-.235.19-.445.41-.626.653-.392.527-.671 1.14-.996 1.718a6890.14 6890.14 0 0 0-3.295 5.867C.62 12.805.31 13.314 0 13.842l3.082 1.854L6.344 9.23l.077.02.461 3.761h3.237c.486-1.788.966-3.544 1.433-5.303.034-.127-.002-.286-.045-.417v.001Zm12.387 6.228c-.81-1.392-1.641-2.774-2.429-4.177-.637-1.133-1.262-2.275-1.824-3.445-.36-.75-.815-1.39-1.438-1.933-.016-.014-.034-.025-.05-.04l.726-1.248-3.14.184c-.053-.051-.08-.125-.065-.242.008-.063-.084-.178-.153-.202-.418-.147-.844-.274-1.29-.417l-3.647 11.024h6.519l.5-3.9.074-.007c1.078 2.148 2.156 4.296 3.244 6.47L24 13.73c-.047-.093-.073-.154-.106-.21Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina100;
