/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-368.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco368 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-368', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.526"
      height="38.439"
      viewBox="0 0 32.526 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M497.047,294H479.306a1.48,1.48,0,0,0-1.478,1.478v10.349H475.3a3.688,3.688,0,0,0-3.589,2.811l-2.428,9.84a.732.732,0,0,0-.845.133l-2.218,2.218a.739.739,0,1,0,1.045,1.045l1.7-1.7,9.3,9.3-1.7,1.7a.739.739,0,1,0,1.045,1.045l2.218-2.218a.738.738,0,0,0,0-1.045l-1.182-1.182c3.4-.992,5.717-5.058,6.447-7.749a2.2,2.2,0,0,1,.567-.977l5.168-5.168a3.33,3.33,0,0,0-2.356-5.682h0a3.3,3.3,0,0,0-2.351.976l-5.27,5.276a.759.759,0,0,1-.092.078.539.539,0,0,1-.5.064.755.755,0,0,1-.482-.475l-.316-.949a.74.74,0,0,0-1.4.467l.316.95A2.24,2.24,0,0,0,479.807,316a2.019,2.019,0,0,0,1.814-.271,2.2,2.2,0,0,0,.274-.234l5.271-5.277a1.835,1.835,0,0,1,1.305-.542h0a1.851,1.851,0,0,1,1.311,3.158l-5.168,5.169a3.68,3.68,0,0,0-.948,1.636c-.753,2.773-3.183,6.635-6.294,6.862l-6.859-6.859,2.629-10.653a2.215,2.215,0,0,1,2.156-1.687h2.53v2.957a.739.739,0,0,0,1.478,0v-8.914a6.662,6.662,0,0,0,5.87-5.87h6a6.661,6.661,0,0,0,5.87,5.87v19.307a6.661,6.661,0,0,0-5.87,5.87h-7.805a.739.739,0,0,0,0,1.478h13.676a1.48,1.48,0,0,0,1.478-1.478V295.478A1.481,1.481,0,0,0,497.047,294Zm-17.741,5.855v-4.376h4.376A5.178,5.178,0,0,1,479.306,299.855Zm17.741,26.671h-4.376a5.178,5.178,0,0,1,4.376-4.376Zm0-26.671a5.178,5.178,0,0,1-4.376-4.376h4.376Zm-6.639,16.622a.739.739,0,1,0,.558,1.369,7.39,7.39,0,1,0-10.161-7.267.739.739,0,0,0,1.475.084,5.91,5.91,0,1,1,8.128,5.814Z"
        transform="translate(-466 -294)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco368;
