import { LocationState, navigate } from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { setPathName } from './utils';

const initialState: LocationState = {
  pathname: '',
  isOnline: true,
  isLoading: true,
};

export const locationSlice = createSlice({
  name: 'locationSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setIsOnline: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // set pathname in the same methos managed in locationMiddleware
      .addCase(navigate.pending, (state, _action) => {
        // state.pathname = action.meta.arg;
        state.isLoading = true;
      })
      .addCase(navigate.rejected, (state, _action) => {
        state.isLoading = false;
      })
      .addCase(navigate.fulfilled, (state, action) => {
        setPathName(action.payload);
        state.pathname = action.payload;
        state.isLoading = false;
      });
  },
});

export default locationSlice.reducer;

export const { setIsOnline } = locationSlice.actions;
