/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-421.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco421 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-421', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M322.09,146.7h.739a.739.739,0,0,0,0-1.478h-.739v-5.174a.74.74,0,0,0-1.3-.487l-5.174,5.914a.74.74,0,0,0,.557,1.226h4.435v.739a.739.739,0,0,0,1.479,0Zm-1.479-1.478h-2.806l2.806-3.207Zm-.912,12.168-5.784-3.647a1.956,1.956,0,0,0-2.719.649l-1.282,2.138a.483.483,0,0,1-.318.226.455.455,0,0,1-.361-.073,36.869,36.869,0,0,1-7.477-7.477.46.46,0,0,1-.073-.361.486.486,0,0,1,.227-.317l2.137-1.282a1.953,1.953,0,0,0,.648-2.718l-3.646-5.785a1.953,1.953,0,0,0-2.782-.553,23.737,23.737,0,0,0-2.7,2.153c-3.4,3.4-1.115,11.011,5.2,17.327,4.337,4.337,9.284,6.773,13.06,6.773a5.838,5.838,0,0,0,4.267-1.573,23.632,23.632,0,0,0,2.153-2.7,1.952,1.952,0,0,0-.553-2.782Zm-.653,1.927a22.3,22.3,0,0,1-1.991,2.507c-2.72,2.718-9.7.339-15.238-5.2s-7.921-12.517-5.2-15.238a22.484,22.484,0,0,1,2.507-1.992.472.472,0,0,1,.273-.087.512.512,0,0,1,.094.009.476.476,0,0,1,.31.214l3.646,5.785a.478.478,0,0,1-.159.663l-2.136,1.281a1.969,1.969,0,0,0-.913,1.28,1.948,1.948,0,0,0,.312,1.52,38.3,38.3,0,0,0,7.831,7.831,1.935,1.935,0,0,0,1.518.312,1.97,1.97,0,0,0,1.28-.912l1.283-2.138a.476.476,0,0,1,.663-.158l5.784,3.646a.473.473,0,0,1,.214.31A.468.468,0,0,1,319.046,159.314ZM314.7,126a17.734,17.734,0,0,0-15.579,9.28.74.74,0,0,0,1.3.707,16.253,16.253,0,1,1,22.039,22.032.739.739,0,0,0,.707,1.3A17.732,17.732,0,0,0,314.7,126Zm-2.49,17.417-2.662.665a2.952,2.952,0,0,0-2.24,2.868v.486a.739.739,0,0,0,.739.739h5.914a.739.739,0,0,0,0-1.478h-5.153a1.476,1.476,0,0,1,1.1-1.181l2.661-.665a2.815,2.815,0,0,0-.682-5.546h-1.62a2.96,2.96,0,0,0-2.957,2.957.739.739,0,0,0,1.478,0,1.48,1.48,0,0,1,1.478-1.478h1.62a1.337,1.337,0,0,1,.325,2.633Z"
        transform="translate(-294 -126)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco421;
