import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { removeAvvenimento, RemoveAvvenimentoProps } from 'features/sport';
// import { LiveMenuAvvenimentiResponseApi } from 'lib/api/live/liveMenuAvvenimentiResponseApi';

export interface LiveFavouriteItem {
  keyAvvenimento: string;
  slugAvvenimento: string;
  infoTipoScommessaKey: number[];
}

// Define a type for the slice state
export interface LiveFavouriteAvvenimentoSliceState {
  liveAvvenimentoList: Array<LiveFavouriteItem>;
}

// Define the initial state using that type
const initialState: LiveFavouriteAvvenimentoSliceState = {
  liveAvvenimentoList: [],
};

const doRemoveFromAvvenimentoList = (state: LiveFavouriteAvvenimentoSliceState, avvenimentoToRemove: string): void => {
  const idx = (state.liveAvvenimentoList ?? []).findIndex(
    (avvenimento) => avvenimento.keyAvvenimento === avvenimentoToRemove
  );
  if (idx > -1) {
    state.liveAvvenimentoList.splice(idx, 1);
  }
};

const liveFavouriteAvvenimentoSlice = createSlice({
  name: 'liveFavouriteAvvenimento',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addAvvenimentoToFavouriteList: (
      state,
      action: PayloadAction<{
        keyAvvenimento: string;
        slugAvvenimento: string;
        infoTipoScommessaKey: number[];
      }>
    ) => {
      const { keyAvvenimento, slugAvvenimento, infoTipoScommessaKey } = action.payload;
      if (state.liveAvvenimentoList.some((x) => x.keyAvvenimento === keyAvvenimento)) return;
      state.liveAvvenimentoList.push({
        keyAvvenimento,
        slugAvvenimento,
        infoTipoScommessaKey,
      });
    },
    // updateLiveAvvenimentoList: (state, action: PayloadAction<LiveMenuAvvenimentiResponseApi>) => {
    //   state.liveAvvenimentoList = state.liveAvvenimentoList.filter((liveAvvenimenti) => {
    //     return action.payload.sportList.flatMap((sport) => {
    //       return sport.manifestazioneList.flatMap((manifestazione) => {
    //         return manifestazione.avvenimentoList.some((avvenimento) => {
    //           return avvenimento.key !== liveAvvenimenti.keyAvvenimento;
    //         });
    //       });
    //     });
    //   });
    // },
    removeAvvenimentoFromFavouriteList: (state, action: PayloadAction<{ keyAvvenimento: string }>) => {
      const { keyAvvenimento } = action.payload;
      doRemoveFromAvvenimentoList(state, keyAvvenimento);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(removeAvvenimento.fulfilled.type, (state, action: PayloadAction<RemoveAvvenimentoProps>) => {
      // when the removeAvvenimento action is raised
      // all involved avvenimentiToRemove should be removed from liveAvvenimentoList
      const { avvenimentoKey } = action.payload;
      doRemoveFromAvvenimentoList(state, avvenimentoKey);
    });
  },
});

export const { addAvvenimentoToFavouriteList, removeAvvenimentoFromFavouriteList } =
  liveFavouriteAvvenimentoSlice.actions;

export default liveFavouriteAvvenimentoSlice.reducer;
