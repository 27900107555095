/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-158.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco158 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-158', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.655"
      height="38.439"
      viewBox="0 0 23.655 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <g transform="translate(7.392 13.306)">
          <path
            className="a"
            d="M230.738,239.827a.739.739,0,0,1-.6-1.169l7.392-10.349a.739.739,0,1,1,1.2.859l-7.392,10.349A.737.737,0,0,1,230.738,239.827Z"
            transform="translate(-230 -228)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(5.175 13.306)">
          <path
            className="a"
            d="M229.957,233.914a2.957,2.957,0,1,1,2.957-2.957A2.96,2.96,0,0,1,229.957,233.914Zm0-4.435a1.478,1.478,0,1,0,1.478,1.478A1.48,1.48,0,0,0,229.957,229.478Z"
            transform="translate(-227 -228)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(12.567 19.22)">
          <path
            className="a"
            d="M239.957,241.914a2.957,2.957,0,1,1,2.957-2.957A2.96,2.96,0,0,1,239.957,241.914Zm0-4.435a1.478,1.478,0,1,0,1.478,1.478A1.48,1.48,0,0,0,239.957,237.478Z"
            transform="translate(-237 -236)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M240.7,210H222.957A2.96,2.96,0,0,0,220,212.957v32.526a2.96,2.96,0,0,0,2.957,2.957H240.7a2.96,2.96,0,0,0,2.957-2.957V212.957A2.96,2.96,0,0,0,240.7,210Zm-19.22,5.914h20.7v25.133h-20.7Zm1.478-4.435H240.7a1.48,1.48,0,0,1,1.478,1.478v1.478h-20.7v-1.478A1.48,1.48,0,0,1,222.957,211.478ZM240.7,246.961H222.957a1.48,1.48,0,0,1-1.478-1.478v-2.957h20.7v2.957A1.48,1.48,0,0,1,240.7,246.961Z"
          transform="translate(-220 -210)"
          fill="currentColor"
        />
        <g transform="translate(9.61 34.004)">
          <path
            className="a"
            d="M236.7,257.478h-2.957a.739.739,0,0,1,0-1.478H236.7a.739.739,0,1,1,0,1.478Z"
            transform="translate(-233 -256)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(11.088 2.227)">
          <path
            className="a"
            d="M235.739,214.482a.732.732,0,1,1,.525-.214,1.156,1.156,0,0,1-.111.089.561.561,0,0,1-.133.067.468.468,0,0,1-.133.044A1,1,0,0,1,235.739,214.482Z"
            transform="translate(-235 -213.013)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco158;
