/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-47.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco47 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-47', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0)">
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M61.22,416.439H42.739a.739.739,0,0,1-.523-1.262l4.9-4.9a19.219,19.219,0,1,1,14.1,6.163Zm-16.7-1.479h16.7a17.741,17.741,0,1,0-12.545-5.2.738.738,0,0,1,0,1.045Z"
            transform="translate(-42 -378)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(8.871 8.871)">
          <path
            className="a"
            d="M69.358,410.7a9.219,9.219,0,0,1-2.784-.478,19.064,19.064,0,0,1-7.349-4.749h0c-4.9-4.9-6.647-10.831-3.977-13.5a18.1,18.1,0,0,1,2.086-1.666,1.667,1.667,0,0,1,2.375.473L62.5,395.2a1.67,1.67,0,0,1-.554,2.322l-1.635.98a.2.2,0,0,0-.092.13.17.17,0,0,0,.027.139,28.006,28.006,0,0,0,5.678,5.679.185.185,0,0,0,.14.028.2.2,0,0,0,.129-.092l.981-1.636a1.669,1.669,0,0,1,2.321-.554l4.425,2.789a1.665,1.665,0,0,1,.472,2.375,18.158,18.158,0,0,1-1.665,2.087A4.6,4.6,0,0,1,69.358,410.7ZM58.3,391.479a.183.183,0,0,0-.108.035,16.186,16.186,0,0,0-1.9,1.5c-.914.913-1.063,2.61-.41,4.656a17.587,17.587,0,0,0,4.387,6.753h0a17.582,17.582,0,0,0,6.753,4.387c2.047.653,3.743.5,4.656-.41a16.248,16.248,0,0,0,1.5-1.9.183.183,0,0,0,.032-.146.186.186,0,0,0-.085-.123l-4.426-2.791a.186.186,0,0,0-.146-.024.192.192,0,0,0-.12.088l-.98,1.635a1.682,1.682,0,0,1-1.093.778,1.662,1.662,0,0,1-1.3-.267,29.435,29.435,0,0,1-6.032-6.033,1.658,1.658,0,0,1-.267-1.3,1.68,1.68,0,0,1,.778-1.093l1.635-.98a.192.192,0,0,0,.088-.12.19.19,0,0,0-.024-.146l-2.789-4.424a.191.191,0,0,0-.162-.089Z"
            transform="translate(-54 -390.001)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco47;
