/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-11.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina11 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-11', className].join(' ');
  return (
    <svg
      id="disciplina_ciclismo"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.162 4.08a1.406 1.406 0 1 1-.931 2.654 1.406 1.406 0 0 1 .93-2.654Zm-5.896 8.733s.19 2.428.274 3.137c.03.221.153.778.8.778.668-.042.717-.557.74-.808.034-.456.06-1.687.079-2.573l.012-.572.001-.012c.011-.304.016-.446-.153-.735l-1.204-1.982L13.23 8.62l.79 1.792a.609.609 0 0 0 .422.312l3.328-.446s.538-.072.484-.617c-.041-.393-.312-.485-.495-.507l-2.478.03-.709-2.203c-.465-1.132-1.86-1.132-2.668-.82-1.327.515-2.874 1.677-3.683 2.642-.244.293-.324.83-.16 1.174.496 1.113 2.204 2.836 2.204 2.836Zm7.574-1.609a.575.575 0 0 0-.221-.366l.259-.034c.164-.023.335-.08.477-.183.091.141.152.294.183.465.083.488-.142 1.102-.98 1.376a.597.597 0 0 0-.031.008.319.319 0 0 1-.08.011.354.354 0 0 1-.114-.69c.366-.122.549-.324.507-.587Zm-.808 2.284-.557-1.921-.16-.568-.85.11.061.202h-3.309l.423.839h2.497l-2.405 2.695-.072 1.334 3.224-3.602.336 1.132a3.422 3.422 0 0 0-2.074 3.145 3.43 3.43 0 0 0 3.427 3.427 3.423 3.423 0 1 0 0-6.847 3.821 3.821 0 0 0-.541.054Zm3.126 3.381a2.593 2.593 0 0 1-2.589 2.588 2.593 2.593 0 0 1-2.588-2.588c0-1.022.606-1.91 1.467-2.325l.527 1.799a.796.796 0 0 0 .599 1.327c.445 0 .8-.355.8-.801a.804.804 0 0 0-.587-.77l-.526-1.8a2.592 2.592 0 0 1 2.897 2.57ZM6.423 20.296a3.426 3.426 0 0 0 3.37-2.82l.465.018c.172.366.537.63.972.63.587 0 1.094-.485 1.063-1.114-.282.274-.716.385-1.021.374-.587 0-.991-.313-1.205-.74l-.232-.011a3.462 3.462 0 0 0-.98-2.185l.991-1.284-.568-.648-1.082 1.414a3.41 3.41 0 0 0-1.77-.496A3.43 3.43 0 0 0 3 16.861a3.433 3.433 0 0 0 3.423 3.435Zm1.91-5.158c.366.393.606.911.656 1.468l-1.731-.073 1.075-1.395Zm-1.91-.85c.454 0 .88.122 1.254.324l-1.132 1.475c-.042-.01-.08-.01-.122-.01-.446 0-.8.354-.8.8 0 .446.354.8.8.8a.782.782 0 0 0 .618-.305l1.902.08a2.594 2.594 0 0 1-2.52 2.013 2.593 2.593 0 0 1-2.588-2.588 2.59 2.59 0 0 1 2.588-2.589Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default Disciplina11;
