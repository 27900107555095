import { decodePathname, selectTemplateSlug } from 'features/sport';
import {
  initTemplateAvvenimento,
  initTemplateDisciplina,
  initTemplateLive,
  initTemplateManifestazione,
  setSlug,
  sportSignalRListeners,
} from 'features/sport/sportSlice';

import { Middleware } from '@reduxjs/toolkit';
import { shallowEqual } from 'fast-equals';
import { liveNavSignalRListeners } from 'features/live/liveNav/liveNavSlice';
import { sportConnectionHubManager } from 'features/signalR/hubManager/sportConnectionHubManager';
import { RootState } from 'lib/centralStore';
import { isClientSide } from 'utility/functions';
import { setIsOnline } from './locationSlice';
import { navigate } from './types';

const dataFromApiQuery = (state: RootState): any => state?.sport?.scommessa;

export const getDifference = (ob1: {}, ob2: {}, parent = ''): string[] => {
  let result: string[] = [];

  [...Object.keys(ob1 ?? {}), ...Object.keys(ob2 ?? {})]
    .filter((x, idx, arr) => arr.indexOf(x) === idx)
    .forEach((key) => {
      const prev = ob1?.[key] ?? 'undefined';
      const next = ob2?.[key] ?? 'undefined';

      if (!shallowEqual(prev, next)) {
        const id = parent ? `${parent}.${key}` : key;

        if (typeof prev === 'object' || typeof next === 'object') {
          // const x = Array.isArray(ob1) || Array.isArray(ob2);
          getDifference(prev, next, id).forEach((str) => {
            result.push(str);
          });
        } else {
          result.push(`${id}: ${prev} -> ${next}`);
        }
      }
    });

  return result;
};

export const locationMiddleware: Middleware = (api) => (next) => async (action) => {
  let trace = false;
  let objPrev: RootState = {} as RootState;

  if (isClientSide()) {
    const { configuration }: RootState = api.getState();
    const { isEnabledTrace } = configuration ?? {};

    if ([setSlug.type, navigate.fulfilled.type].includes(action.type)) {
      // list action used in locationSlice to set pathname
      // navigate.pending.type, navigate.rejected.type,
      // const { meta, payload } = action ?? {};
      const nextPath = action.payload;

      sportConnectionHubManager(api).onLocationEventHandler(nextPath);
    } else if ([setIsOnline.type].includes(action.type)) {
      sportConnectionHubManager(api).onIsOnlineEventHandler(action.payload);
    } else if ([navigate.pending.type].includes(action.type)) {
      const nextPath = decodePathname(action?.meta?.arg);
      const prevPathname = decodePathname(selectTemplateSlug(api.getState())) ?? '';

      if (!prevPathname.startsWith(nextPath)) {
        sportConnectionHubManager(api).onTemplateEventHandler(true);
      }
    } else if (
      [
        initTemplateManifestazione.type,
        initTemplateAvvenimento.type,
        initTemplateDisciplina.type,
        initTemplateLive.type,
      ].includes(action.type)
    ) {
      const { isLoading, ...template } = action.payload ?? {};
      const slug = template?.slug;

      sportConnectionHubManager(api).onTemplateEventHandler(isLoading, slug);
    } else if (
      isEnabledTrace &&
      (sportSignalRListeners.map((x) => x.actionType).includes(action.type) ||
        liveNavSignalRListeners.map((x) => x.actionType).includes(action.type))
    ) {
      objPrev = api.getState();
      trace = true;
    }
  }
  const result = next(action);

  if (trace) {
    const onjNext = api.getState();
    const prevSt = dataFromApiQuery(objPrev);
    const nextSt = dataFromApiQuery(onjNext);

    const diff = getDifference(prevSt, nextSt);

    let keys: string[] = [];
    if (diff.length > 0) {
      keys = (action?.payload?.patches || [])
        .map(({ path }) => {
          const [objName, id] = path;
          if (objName === 'esitoMap') return id.split('-').slice(0, 2).join('-');
          if (objName === 'avvenimentoList') return nextSt?.avvenimentoList?.[id]?.key;
        })
        .filter((k, idx, lst) => lst.indexOf(k) === idx);
    }
    if (diff.length < 1) {
      console.log('location', action, 'nothing');
    } else {
      console.log('location', action, new Date(), keys, diff);
    }
  }

  return result;
};
