import { z } from 'zod';
import { ErrorLabelsJson } from './types';

const swapArgumentsInTemplate = (template: string | undefined, context: object) => {
  return template?.replace(/{{(.*?)}}/g, (_, match) => {
    // Le stringhe da swappare possono essere della forma {{- minimum, datetime}}
    const [stringToSwap, formatter]: [string, string | undefined] = match.split(',');
    // Ingnoriamo l'eventuale -, React sanitizza di default
    const finalStringToSwap = stringToSwap.split(' ').at(-1)!;
    const swappedString = context[finalStringToSwap];

    let formatFunction: (_s: string) => string = (s) => s;

    if (formatter) {
      // Aggiungere qui eventuali altri formatter
      if (formatter === 'datetime') {
        formatFunction = (s) => new Date(s).toLocaleString();
      }
    }

    return formatFunction(
      typeof swappedString === 'string'
        ? swappedString
        : // TODO: Possono esserci altri casi?
        Array.isArray(swappedString)
        ? swappedString.join(', ')
        : JSON.stringify(swappedString)
    );
  });
};

// https://github.com/aiji42/zod-i18n/blob/main/packages/core/src/index.ts
// https://zod.dev/ERROR_HANDLING?id=customizing-errors-with-zoderrormap
// https://github.com/JacobWeisenburger/zod_utilz/blob/main/src/makeErrorMap.ts
export function makeErrorMap(errors: ErrorLabelsJson['errors']): z.ZodErrorMap {
  return (issue, ctx) => {
    let rawMessage = '';
    const context = { ...ctx, ...issue };

    switch (issue.code) {
      case z.ZodIssueCode.invalid_type:
        if (issue.received === z.ZodParsedType.undefined) {
          rawMessage = errors['invalid_type_received_undefined'];
        } else {
          rawMessage = errors['invalid_type'];
        }
        break;
      case z.ZodIssueCode.invalid_string:
        if (typeof issue.validation === 'object') {
          if ('startsWith' in issue.validation) {
            rawMessage = errors['invalid_string']['startsWith'];
          } else if ('endsWith' in issue.validation) {
            rawMessage = errors['invalid_string']['endsWith'];
          }
        } else {
          rawMessage = errors['invalid_string'][issue.validation];
        }
        break;
      case z.ZodIssueCode.too_small:
      case z.ZodIssueCode.too_big:
        rawMessage =
          errors[issue.code][issue.type][issue.exact ? 'exact' : issue.inclusive ? 'inclusive' : 'not_inclusive'];
        break;
      default:
        rawMessage = errors[issue.code] ?? '';
        break;
    }

    const message = rawMessage ? swapArgumentsInTemplate(rawMessage, context) : undefined;

    return message ? { message } : { message: ctx.defaultError };
  };
}
