import { defaultLang } from 'utility/constant';
import { z } from 'zod';
import { makeErrorMap } from './utils';

const ErrorLabels = require(`public/i18n/${defaultLang}.json`);

const initZodLabels = () => {
  z.setErrorMap(makeErrorMap(ErrorLabels.errors));
};

const stringNotEmpty = z
  .string()
  .transform((t) => t?.trim())
  .pipe(z.string().min(1, ErrorLabels.errors.invalid_type_received_undefined));

const stringWithoutDigit = z.string().regex(/^([^0-9]*)$/);

export { initZodLabels, stringNotEmpty, stringWithoutDigit };
