/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-3.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina3 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-3', className].join(' ');
  return (
    <svg
      id="disciplina_tennis"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M19.38 6.913C11.95-2.73-1.687 7.296 4.62 17.088c7.334 9.616 21.088-.32 14.76-10.175ZM4.073 10.552c.14-.765.391-1.507.744-2.2.766 3.972 2.856 5.81 1.518 9.369a8.021 8.021 0 0 1-2.262-7.169Zm7.934 9.496c-1.67.021-3.607-.622-4.924-1.673C9.053 12.7 5.38 11.178 5.7 6.98c6.561-7.376 15.088.756 13.08 2.19-.95 1.238-3.141 1.336-4.722 2.412-3.22 1.962-3.883 5.998-2.033 8.466h-.018Zm7.917-6.599a8.02 8.02 0 0 1-2.335 4.349 8.074 8.074 0 0 1-4.445 2.17c-2.058-2.131-1.275-5.366.504-6.94 1.56-1.646 4.885-1.643 6.01-3.534.42 1.275.512 2.635.266 3.955Z"
      />
    </svg>
  );
};
export default Disciplina3;
