/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-129.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco129 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-129', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="35.482"
      viewBox="0 0 35.482 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M496.526,163.483H466.957A2.96,2.96,0,0,1,464,160.526V139.088a.739.739,0,0,1,.355-.631l17-10.349a.739.739,0,0,1,.768,0l17,10.349a.738.738,0,0,1,.356.631v21.437A2.96,2.96,0,0,1,496.526,163.483ZM465.478,139.5v21.022A1.48,1.48,0,0,0,466.957,162h29.569A1.48,1.48,0,0,0,498,160.526V139.5l-16.263-9.9Z"
          transform="translate(-464 -128.001)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(7.392 16.262)">
        <path
          className="a"
          d="M493.959,169.22a.739.739,0,0,1-.739-.739v-17H475.478v17a.739.739,0,1,1-1.478,0V150.739a.739.739,0,0,1,.739-.739h19.22a.739.739,0,0,1,.739.739V168.48A.739.739,0,0,1,493.959,169.22Z"
          transform="translate(-474 -150)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(10.349 25.133)">
        <path
          className="a"
          d="M492.045,163.478H478.739a.739.739,0,1,1,0-1.478h13.306a.739.739,0,1,1,0,1.478Z"
          transform="translate(-478 -162)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(10.349 29.568)">
        <path
          className="a"
          d="M492.045,169.478H478.739a.739.739,0,1,1,0-1.478h13.306a.739.739,0,1,1,0,1.478Z"
          transform="translate(-478 -168)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(10.349 20.698)">
        <path
          className="a"
          d="M492.045,157.478H478.739a.739.739,0,1,1,0-1.478h13.306a.739.739,0,1,1,0,1.478Z"
          transform="translate(-478 -156)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(7.392 11.827)">
        <path
          className="a"
          d="M479.174,145.478h-4.435a.739.739,0,1,1,0-1.478h4.435a.739.739,0,1,1,0,1.478Z"
          transform="translate(-474 -144)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(14.784 11.827)">
        <path
          className="a"
          d="M489.174,145.478h-4.435a.739.739,0,1,1,0-1.478h4.435a.739.739,0,1,1,0,1.478Z"
          transform="translate(-484 -144)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(22.177 11.827)">
        <path
          className="a"
          d="M499.174,145.478h-4.435a.739.739,0,1,1,0-1.478h4.435a.739.739,0,0,1,0,1.478Z"
          transform="translate(-494 -144)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco129;
