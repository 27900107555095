/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-80.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina80 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-80', className].join(' ');
  return (
    <svg
      id="disciplina_snooker"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M20.896 3.411C20.162 3.114 19.394 3 18.614 3H5.392c-.757 0-1.49.114-2.2.389-1.501.57-2.316 1.85-2.177 3.405.07.857.396 1.623.838 2.354 2.107 3.428 4.202 6.857 6.309 10.285.43.697.931 1.314 1.595 1.805 1.292.949 2.851 1.018 4.213.172.792-.491 1.362-1.166 1.827-1.943 2.107-3.44 4.226-6.868 6.332-10.308.361-.57.617-1.2.768-1.85.396-1.715-.372-3.223-2.001-3.898Zm1.187 3.406a4.07 4.07 0 0 1-.082.4c-.116.445-.302.88-.524 1.291l-.104.172c-1.048 1.736-2.107 3.462-3.166 5.188-1.06 1.725-2.119 3.45-3.19 5.176-.348.572-.768 1.075-1.315 1.474-1.094.8-2.328.8-3.422 0-.535-.388-.954-.89-1.303-1.45-1.932-3.166-3.888-6.343-5.832-9.509a24.75 24.75 0 0 1-1.024-1.885c-.21-.446-.268-.937-.221-1.429.07-.857.524-1.474 1.28-1.885.629-.343 1.316-.469 2.014-.48 2.27-.011 4.54 0 6.797 0 2.235 0 4.458-.011 6.693.011a6.073 6.073 0 0 1 1.56.24c.093.023.186.057.268.092.08.034.174.068.244.103A2.347 2.347 0 0 1 21.885 5.4c.012.034.035.068.046.103.035.068.059.148.082.217.012.034.023.08.035.114.07.309.093.651.035.983Zm-12.664.286c0-1.395 1.152-2.537 2.584-2.537 1.42 0 2.584 1.13 2.584 2.502 0 1.429-1.14 2.549-2.596 2.56-1.408 0-2.572-1.143-2.572-2.525Zm5.168 10.662c-.012 1.405-1.164 2.525-2.596 2.525a2.555 2.555 0 0 1-2.572-2.548c.012-1.406 1.164-2.526 2.596-2.526 1.42.012 2.572 1.143 2.572 2.549Zm3.108-5.372c.035 1.36-1.118 2.537-2.48 2.56-1.466.023-2.653-1.063-2.676-2.445-.035-1.44 1.094-2.583 2.572-2.606 1.385-.034 2.549 1.086 2.584 2.492Zm-8.707 2.56c-1.49.023-2.665-1.063-2.688-2.49-.024-1.395 1.117-2.55 2.572-2.572 1.397-.023 2.572 1.108 2.596 2.468.023 1.406-1.095 2.571-2.48 2.594Zm11.814-7.896c.012 1.428-1.117 2.56-2.584 2.571-1.396.011-2.56-1.12-2.572-2.491a2.527 2.527 0 0 1 2.538-2.56c1.443-.023 2.607 1.086 2.618 2.48ZM5.741 9.617A2.547 2.547 0 0 1 3.192 7.08c0-1.395 1.152-2.526 2.56-2.526h.024c1.42 0 2.572 1.131 2.572 2.514-.011 1.452-1.152 2.56-2.607 2.549Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina80;
