import { CasinoCatalogResponseApi } from 'lib/api/casinoCatalogResponseApi';
import { DatoCmsGame, DatoCmsVisualComposer } from 'lib/datoCms/types';
import { GamesSkillGameDto, GamesSlotCatalogDto, GamesStripe } from 'types/swagger';

export const createNewStripe = (stripe: GamesStripe, games: Array<GamesSkillGameDto>) => {
  const cardSlotInStripe = games?.find((game) => {
    if (game.playInfo?.codeGameDesktop === stripe.maxJackpot?.id) {
      return {
        title: game.title,
        img: game.image,
        tema: game.tema,
      };
    }
    return;
  });
  return {
    maxJackpot: {
      amount: stripe.maxJackpot?.amount ?? 0,
      cardSlot: cardSlotInStripe,
    },
    slotTotali: stripe.slotTotali,
    slotPromo: stripe.slotPromo,
    totJackpot: stripe.totJackpot,
  };
};

export const getGamesSlotByType = (
  slotCatalog: Omit<GamesSlotCatalogDto | CasinoCatalogResponseApi, 'stripe'>,
  key: string | undefined
): Array<GamesSkillGameDto> | undefined => {
  const { games, ...listOfSlots } = slotCatalog;
  return games?.filter((game: GamesSkillGameDto) => {
    if (key) {
      return slotCatalog[key as keyof typeof listOfSlots]?.some((element: string) => {
        return element === game?.id;
      });
    }
    return;
  });
};

export function matchCustomSlotFromGame(listSlotCustom: DatoCmsGame[], listOfGames: Array<GamesSkillGameDto>) {
  return listOfGames
    .filter((game) => {
      return listSlotCustom.some((slotCustom: DatoCmsGame) => {
        return slotCustom.middlewareId === game.id;
      });
    })
    .sort((a, b) => {
      return (
        listSlotCustom.findIndex((slotCustom) => slotCustom.middlewareId === a.id) -
        listSlotCustom.findIndex((slotCustom) => slotCustom.middlewareId === b.id)
      );
    });
}

export const getTypeFromListOfKeys = (list: string[], value: string | undefined): string | undefined => {
  return list.find((singleKey) => singleKey === value);
};

export const createIdListForIdMiddleware = (
  visualComposer: DatoCmsVisualComposer[] | undefined
): string[] | undefined => {
  const customSlotList =
    visualComposer &&
    visualComposer
      .filter((component: any) => component._modelApiKey === 'custom_slot_list')
      .map((component: any) => component.slot?.map((slot: any) => slot.middlewareId));

  if (customSlotList) {
    const customSlotListConcat = [].concat(...customSlotList);
    const uniqCustomSlotList = customSlotListConcat.filter((item, index, self) => self.indexOf(item) === index);
    return uniqCustomSlotList;
  }
  return undefined;
};

export const mergeSlotCatalogResponseWithUpdateSlot = (
  slotCatalogResponse: GamesSlotCatalogDto,
  slotsUpdated: Array<GamesSkillGameDto>
) => {
  const { games, ...rest } = slotCatalogResponse;
  const newList: Array<GamesSkillGameDto> = [...games!, ...slotsUpdated];
  const noDuplicate = newList.filter(
    (slot, index, self) => index === self.findIndex((elem) => slot.slug === elem.slug && slot.id === elem.id)
  );
  return {
    games: noDuplicate,
    ...rest,
  };
};
export const hasBannerPromoEngineInPage = (visualComponserList: any[]) => {
  const visualComposerPromoEngineList = visualComponserList.filter((visualcomposer: any) => {
    if (visualcomposer._modelApiKey === 'promo') {
      return visualcomposer;
    }
  });
  return visualComposerPromoEngineList.length;
};
