/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\menu.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Menu = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-menu', className].join(' ');
  return (
    <svg id="menu" viewBox="0 0 18 13" focusable="false" className={componentClassName} {...attribs}>
      <path
        d="M0 12.0195V10.0195H20V12.0195H0ZM0 7.01947V5.01947H20V7.01947H0ZM0 2.01947V0.0194702H20V2.01947H0Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Menu;
