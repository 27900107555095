export class KeyManager {
  protected _esitoId: string;
  protected _programmaId: string;
  protected _avvenimentoId: string;
  protected _tipoScommessaId: string;
  protected _separator: string;

  public esitoKey: string;
  public avvenimentoKey: string;
  public scommessaKey: string;

  get programmaId(): string {
    return this._programmaId;
  }

  get avvenimentoId(): string {
    return this._avvenimentoId;
  }

  get tipoScommessaId(): string {
    return this._tipoScommessaId;
  }

  set tipoScommessaId(tipoScommessaId: string) {
    this._tipoScommessaId = tipoScommessaId;
  }

  get esitoId(): string {
    return this._esitoId;
  }

  constructor(separator = '-') {
    this._separator = separator;
  }

  protected concatId(keys: Array<string>) {
    return keys.join(this._separator);
  }
}
