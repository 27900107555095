import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GeoComplyType } from './types';
import { getGeoComplyLicense } from './actions';

const initialState: GeoComplyType = {
  license: null,
  isImported: false,
  isInitialized: false,
};

const geoComplySlice = createSlice({
  name: 'geoComply',
  initialState,
  reducers: {
    setIsImported: (state, { payload }: PayloadAction<boolean>) => {
      state.isImported = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGeoComplyLicense.fulfilled, (state, { payload }: PayloadAction<GeoComplyType>) => {
      const { license } = payload;
      state.license = license;
    });
  },
});

export const { setIsImported } = geoComplySlice.actions;
export default geoComplySlice.reducer;
