/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-be.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneBe = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-be', className].join(' ');
  return (
    <svg
      id="nazione-be"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g fillRule="evenodd" clipRule="evenodd" clipPath="url(#a)">
        <path d="M0 0h5.334v16H0V0Z" fill="#000" />
        <path d="M5.334 0h5.332v16H5.334V0Z" fill="#FFD90C" />
        <path d="M10.666 0H16v16h-5.334V0Z" fill="#F31830" />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="16" height="16" rx="8" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NazioneBe;
