/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-ph.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazionePh = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-ph', className].join(' ');
  return (
    <svg
      id="nazione-ph"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <path d="M0 0h16v8H0V0Z" fill="#0038A8" />
        <path d="M0 8h16v8H0V8Z" fill="#CE1126" />
        <path d="M13.856 8 0 16V0" fill="#fff" />
        <path
          d="m1.788 2.387.48.416.56-.306-.25.584.469.438-.64-.053-.27.575-.146-.62-.632-.08.547-.329-.118-.625Zm9.84 4.77.013.634.603.209-.603.21-.013.634-.384-.507-.61.188L11 8l-.366-.522.61.184.384-.506Zm-9.487 4.802.268.575.635-.053-.463.438.247.584-.56-.306-.48.416.121-.625-.546-.329.63-.08.148-.62Zm2.825-7.334-.207.206.1 1.572-.103.01-.187-1.435-.172.169.256 1.281a1.594 1.594 0 0 0-.575.24L3.35 5.582h-.24l.88 1.15-.078.066-1.04-1.188h-.294v.297l1.188 1.04-.07.079-1.15-.881v.24l1.088.728a1.59 1.59 0 0 0-.237.575l-1.281-.256-.172.172 1.437.188-.012.106-1.572-.103-.21.206.21.206 1.572-.1.01.103-1.435.188.168.172 1.282-.257c.041.206.123.401.24.575l-1.087.729v.24l1.15-.881.066.078-1.188 1.04v.294h.3L3.915 9.2l.079.069-.882 1.15h.241l.728-1.088c.174.117.37.197.575.238L4.4 10.85l.172.172.187-1.438.103.013-.1 1.572.207.21.206-.21-.1-1.572.103-.01.188 1.435.171-.169-.256-1.281c.206-.042.401-.124.575-.24l.728 1.087h.241l-.888-1.15.079-.066 1.04 1.188h.294v-.297l-1.188-1.04.07-.08 1.15.882v-.24l-1.088-.729a1.59 1.59 0 0 0 .237-.575l1.282.257.171-.172-1.437-.188.012-.103 1.572.1.21-.206-.21-.206-1.572.103c0-.038-.006-.072-.009-.106l1.434-.188-.168-.172-1.282.256a1.594 1.594 0 0 0-.24-.575l1.087-.728v-.24l-1.15.88-.065-.077 1.187-1.04v-.295h-.297L6.016 6.8l-.079-.069.882-1.15h-.24L5.85 6.67a1.59 1.59 0 0 0-.575-.238l.256-1.281-.172-.172-.187 1.438-.103-.013.1-1.572-.203-.206Z"
          fill="#FCD116"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="16" height="16" rx="8" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NazionePh;
