/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\cash-multiple.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const CashMultiple = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-cash-multiple', className].join(' ');
  return (
    <svg
      id="cash-multiple"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M3.33329 4H15.3333V12H3.33329V4ZM9.33329 6C9.86373 6 10.3724 6.21071 10.7475 6.58579C11.1226 6.96086 11.3333 7.46957 11.3333 8C11.3333 8.53043 11.1226 9.03914 10.7475 9.41421C10.3724 9.78929 9.86373 10 9.33329 10C8.80286 10 8.29415 9.78929 7.91908 9.41421C7.54401 9.03914 7.33329 8.53043 7.33329 8C7.33329 7.46957 7.54401 6.96086 7.91908 6.58579C8.29415 6.21071 8.80286 6 9.33329 6ZM5.99996 5.33333C5.99996 5.68696 5.85948 6.02609 5.60944 6.27614C5.35939 6.52619 5.02025 6.66667 4.66663 6.66667V9.33333C5.02025 9.33333 5.35939 9.47381 5.60944 9.72386C5.85948 9.97391 5.99996 10.313 5.99996 10.6667H12.6666C12.6666 10.313 12.8071 9.97391 13.0572 9.72386C13.3072 9.47381 13.6463 9.33333 14 9.33333V6.66667C13.6463 6.66667 13.3072 6.52619 13.0572 6.27614C12.8071 6.02609 12.6666 5.68696 12.6666 5.33333H5.99996ZM0.666626 6.66667H1.99996V13.3333H12.6666V14.6667H0.666626V6.66667Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
    </svg>
  );
};
export default CashMultiple;
