/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\legenda-tot-light.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const LegendaTotLight = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-legenda-tot-light', className].join(' ');
  return (
    <svg
      id="legenda-tot-light"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M12 4c0-2.20914-1.7909-4-4-4H0l12 12V4Z" fill="#C67700" />
    </svg>
  );
};
export default LegendaTotLight;
