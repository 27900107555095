/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\alert.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Alert = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-alert', className].join(' ');
  return (
    <svg
      id="alert"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M16.0007 2.66536C23.3647 2.66536 29.334 8.6347 29.334 15.9987C29.334 23.3627 23.3647 29.332 16.0007 29.332C8.63665 29.332 2.66732 23.3627 2.66732 15.9987C2.66732 8.6347 8.63665 2.66536 16.0007 2.66536ZM17.334 11.9987V9.33203L14.6673 9.33203V11.9987L17.334 11.9987ZM17.334 22.6654V14.6654H14.6673L14.6673 22.6654H17.334Z"
        fill="#EBFF00"
      />
    </svg>
  );
};
export default Alert;
