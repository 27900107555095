/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-cn.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneCn = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-cn', className].join(' ');
  return (
    <svg
      id="nazione-cn"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <path d="M0 0h16v16H0V0Z" fill="#DE2910" />
        <path
          d="M6.4 3.28 2.32 5.92 4 1.6l1.44 4.32L1.6 3.28h4.8ZM7.393 1.033l1.454.712-1.522.261.987-1.152-.095 1.55-.824-1.371ZM9.246 2.444l1.063 1.222-1.505-.351 1.358-.678-.69 1.391-.226-1.584ZM9.58 4.767l.472 1.55-1.23-.935 1.517-.065-1.2.988.441-1.538ZM8.313 6.426l-.164 1.61L7.376 6.7l1.423.526-1.486.448 1-1.248Z"
          fill="#FFDE00"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="16" height="16" rx="8" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NazioneCn;
