/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-66.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina66 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-66', className].join(' ');
  return (
    <svg
      id="disciplina_olimpiadi"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M20.115 6a3.883 3.883 0 0 0-3.839 3.332c-.093-.005-.182-.014-.276-.014-.056 0-.113.005-.169.01A3.882 3.882 0 0 0 11.998 6a3.887 3.887 0 0 0-3.839 3.332c-.08-.005-.154-.014-.234-.014-.07 0-.14.005-.216.01A3.875 3.875 0 1 0 3.88 13.76c.07 0 .14-.005.216-.01a3.883 3.883 0 0 0 3.834 3.333 3.887 3.887 0 0 0 3.838-3.332c.08.004.154.014.234.014.056 0 .113-.005.169-.01a3.875 3.875 0 0 0 7.672-.004c.094.004.183.014.276.014A3.883 3.883 0 0 0 20.115 6ZM.8 9.88a3.077 3.077 0 0 1 6.126-.421 3.883 3.883 0 0 0-2.864 3.496 2.38 2.38 0 0 1-.183.01A3.088 3.088 0 0 1 .801 9.88Zm6.126.408a3.074 3.074 0 0 1-2.048 2.498 3.093 3.093 0 0 1 2.048-2.498Zm1.003 5.99a3.078 3.078 0 0 1-3.047-2.658 3.882 3.882 0 0 0 2.864-3.496c.06-.005.122-.01.183-.01.07 0 .135.005.201.01a3.88 3.88 0 0 0 2.845 3.487 3.078 3.078 0 0 1-3.046 2.667Zm1.024-5.98a3.084 3.084 0 0 1 2.024 2.483 3.084 3.084 0 0 1-2.024-2.483Zm2.844 2.652a3.88 3.88 0 0 0-2.844-3.487 3.075 3.075 0 0 1 6.092-.018 3.877 3.877 0 0 0-2.906 3.505c-.046 0-.089.005-.136.005a1.48 1.48 0 0 1-.208-.005h.002Zm3.253-2.676a3.09 3.09 0 0 1-2.1 2.536 3.086 3.086 0 0 1 2.097-2.536h.003Zm.951 6.004a3.085 3.085 0 0 1-3.046-2.644 3.877 3.877 0 0 0 2.906-3.505c.046 0 .089-.005.136-.005.083 0 .165.005.248.014a3.876 3.876 0 0 0 2.802 3.473A3.075 3.075 0 0 1 16 16.278h.002Zm1.073-5.962a3.098 3.098 0 0 1 1.978 2.451 3.081 3.081 0 0 1-1.98-2.45h.002Zm3.042 2.644c-.083 0-.166-.005-.248-.015a3.875 3.875 0 0 0-2.803-3.472 3.08 3.08 0 1 1 3.051 3.487Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina66;
