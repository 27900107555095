/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\new-light.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NewLight = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-new-light', className].join(' ');
  return (
    <svg
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00015 2.70302e-09C1.79095 -8.03577e-05 0 1.7908 0 4V21.9608L24.5 0.000745693L4.00015 2.70302e-09ZM1.38458 11.3241L5.08475 15.5806L5.91795 14.8563L3.77657 12.393L7.17379 13.7647L7.95868 13.0824L4.25851 8.8258L3.41927 9.55534L5.57115 12.0308L2.16948 10.6418L1.38458 11.3241ZM5.41425 7.82113L9.11443 12.0777L11.5053 9.9993L10.8125 9.20232L9.25483 10.5564L8.41507 9.5904L9.56827 8.58794L8.93845 7.86342L7.78526 8.86588L6.9455 7.89985L8.50322 6.54574L7.80517 5.74273L5.41425 7.82113ZM8.44474 5.18676L13.1411 8.57733L13.9924 7.83729L12.121 4.51385L15.1517 6.82959L16.003 6.08955L13.3051 0.961744L12.4658 1.69128L14.3632 5.12996L11.2462 2.75147L10.4976 3.40228L12.4191 6.81997L9.27794 4.46248L8.44474 5.18676Z"
        fill="#0057FF"
      />
    </svg>
  );
};
export default NewLight;
