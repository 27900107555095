import { ItemWithTranslation, SportsLiveSection } from 'types/swagger';
import { Sistema, TicketErrore, TicketErroreInserimento, TicketMessaggi } from 'features/carrello/types';

export type SportContenutoCarrello = {
  avvenimenti: SportTicketAvvenimento[];
  puntata?: number; // format centesimi x 100
  puntataPerScommessa?: { [idSistema: string]: number }; // idSistema 1/2 per esempio
  sistema?: boolean;
  maxVincita?: number;
};

export type SportTicket = {
  avvenimenti: SportTicketAvvenimento[];
  puntata: number; // da passare quando input utente
  puntataPerScommessa: { [idSistema: string]: number }; // solo sistemi
  sistema: boolean; // quando uguale a false è una singola o multipla
  tipo: TipoSportTicket;
  numEsiti: number; // quando numero esiti > 1 va visualizzato in multipla
  sistemi: Sistema[];
  moltBonus: number;
  percBonus: number;
  quotaTotale: number;
  prezzo: number; // importo Totale, manca nella UI segnalarlo
  bonus: number; // if bonus === 0 non considerare possibileVincitaMinWithBonus possibileVincitaMaxWithBonus
  minCombinazioni: number;
  maxCombinazioni: number;
  possibileVincitaMin: number;
  possibileVincitaMax: number;
  possibileVincitaMinWithBonus?: number;
  possibileVincitaMaxWithBonus?: number;
  messaggi: TicketMessaggi[];
  errori: Array<{
    type: TicketErrore;
    value?: number;
  }>;
  erroreInserimento: TicketErroreInserimento | undefined;
  transazioneBetReferral?: string;
  tax?: number;
};

/* eslint-disable no-unused-vars */
export enum TipoSportTicket {
  Singola,
  Multipla,
  Sistema,
  MultiplaSistema,
}
/* eslint-enable no-unused-vars */

export type SportTicketAvvenimento = ItemWithTranslation<{
  descrizione?: string;
  descrizioneManifestazione?: string;
}> & {
  id?: string; // è la key Avvenimento
  dataOra: string; // da inserire come props, si trova in endpoint scommesse
  data: string; // si trova in endpoint scommesse
  ora: string; // si trova in endpoint scommesse
  idDisciplina: number; // idDisciplina si trova in endpoint scommesse
  idAvvenimento: number; // idAvvenimento
  idProgramma: number; // idProgramma
  manifestazione?: string; // slug manifestazione
  isAntepost: boolean; // flag avvenimento antepost
  isFisso: boolean; // false sempre, da usare solo nei sistemi
  esiti: SportTicketEsito[];
  slugDisciplina: string;
  idManifestazione: number;
  live?: SportsLiveSection | null;
  // per formare il codice Palinstesto = pal - avvenimentoId
};

export type SportTicketEsito = ItemWithTranslation<{
  descrizioneEsito?: string; // 1 esitoMap
  descrizioneTipoScommessa?: string; // 1x2 Finale scommessaMap
  descrizioneTipoScommessaWithInfoAgg?: string;
}> & {
  id: string; // è la key Esito // esitoMap
  infoAggiuntiva: string; // '000001'; esitoMap
  idTipoInfoAggiuntiva: number; // esitoMap
  numEsito: number; // idEsito esitoMap
  quota: number; // esitoMap
  multipla: number; // scommessaMap
  minCombinazioni: number; // scommessaMap
  maxCombinazioni: number; // scommessaMap
  idTipoScommessa: number; // scommessaMap
  flagBonus: boolean;
  isIncreasing?: boolean;
  isDecreasing?: boolean;
  idEsito: number;
  isActive: boolean;
};

export type SviluppoSistemi = {
  quotaMinima?: number;
  quotaMassima?: number;
  dettaglioSviluppoSistemi: DettaglioSviluppoSistema[];
};

export type DettaglioSviluppoSistema = {
  codiceSistema: number;
  quotaMinimaSistema: number;
  quotaMassimaSistema: number;
  numeroMultiple: number;
};

export type Bonus11 = {
  tipo_gioco: number;
  tipo_bonus: number;
  soglia_quota: number;
  antepost: number;
  distanza: number;
  id_sport: string;
  num_fasce: number;
  fasce: Fasce[];
};

export type Fasce = {
  min_avv: number;
  max_avv: number;
  bonus: number;
};
