/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\cup.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Cup = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-cup', className].join(' ');
  return (
    <svg
      id="cup"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#clip0_7542_57476)">
        <path
          d="M13.6133 2.55005H12.1758V1.92505C12.1758 1.8563 12.1195 1.80005 12.0508 1.80005H4.05078C3.98203 1.80005 3.92578 1.8563 3.92578 1.92505V2.55005H2.48828C2.30594 2.55005 2.13108 2.62248 2.00215 2.75141C1.87321 2.88034 1.80078 3.05521 1.80078 3.23755V5.55005C1.80078 6.82661 2.73828 7.88755 3.96016 8.0813C4.20078 9.89692 5.66016 11.3282 7.48828 11.5266V13.1704H4.42578C4.14922 13.1704 3.92578 13.3938 3.92578 13.6704V14.175C3.92578 14.2438 3.98203 14.3 4.05078 14.3H12.0508C12.1195 14.3 12.1758 14.2438 12.1758 14.175V13.6704C12.1758 13.3938 11.9523 13.1704 11.6758 13.1704H8.61328V11.5266C10.4414 11.3282 11.9008 9.89692 12.1414 8.0813C13.3633 7.88755 14.3008 6.82661 14.3008 5.55005V3.23755C14.3008 3.05521 14.2283 2.88034 14.0994 2.75141C13.9705 2.62248 13.7956 2.55005 13.6133 2.55005ZM3.92578 6.9188C3.34609 6.73286 2.92578 6.18911 2.92578 5.55005V3.67505H3.92578V6.9188ZM13.1758 5.55005C13.1758 6.19067 12.7555 6.73442 12.1758 6.9188V3.67505H13.1758V5.55005Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_7542_57476">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Cup;
