/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\preferred-off.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const PreferredOff = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-preferred-off', className].join(' ');
  return (
    <svg id="preferred-off" viewBox="0 0 48 48" focusable="false" className={componentClassName} {...attribs}>
      <path
        d="M24 41.95L21.95 40.1C18.4167 36.8667 15.5 34.075 13.2 31.725C10.9 29.375 9.06667 27.275 7.7 25.425C6.33333 23.575 5.375 21.9 4.825 20.4C4.275 18.9 4 17.3833 4 15.85C4 12.85 5.00833 10.3417 7.025 8.32499C9.04167 6.30832 11.5333 5.29999 14.5 5.29999C16.4 5.29999 18.1583 5.74999 19.775 6.64999C21.3917 7.54999 22.8 8.84999 24 10.55C25.4 8.74999 26.8833 7.42499 28.45 6.57499C30.0167 5.72499 31.7 5.29999 33.5 5.29999C36.4667 5.29999 38.9583 6.30832 40.975 8.32499C42.9917 10.3417 44 12.85 44 15.85C44 17.3833 43.725 18.9 43.175 20.4C42.625 21.9 41.6667 23.575 40.3 25.425C38.9333 27.275 37.1 29.375 34.8 31.725C32.5 34.075 29.5833 36.8667 26.05 40.1L24 41.95ZM24 38C27.3667 34.9 30.1417 32.2417 32.325 30.025C34.5083 27.8083 36.2417 25.8667 37.525 24.2C38.8083 22.5333 39.7083 21.05 40.225 19.75C40.7417 18.45 41 17.15 41 15.85C41 13.65 40.3 11.8417 38.9 10.425C37.5 9.00832 35.7 8.29999 33.5 8.29999C31.8 8.29999 30.2167 8.82499 28.75 9.87499C27.2833 10.925 26.1 12.4 25.2 14.3H22.75C21.8833 12.4333 20.7167 10.9667 19.25 9.89999C17.7833 8.83332 16.2 8.29999 14.5 8.29999C12.3 8.29999 10.5 9.00832 9.1 10.425C7.7 11.8417 7 13.65 7 15.85C7 17.15 7.25833 18.4583 7.775 19.775C8.29167 21.0917 9.19167 22.5917 10.475 24.275C11.7583 25.9583 13.5 27.9 15.7 30.1C17.9 32.3 20.6667 34.9333 24 38Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default PreferredOff;
