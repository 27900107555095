/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-394.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco394 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-394', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M387.551,159.723a.592.592,0,0,0-.067-.133l-.089-.111-.111-.089a.574.574,0,0,0-.133-.066.449.449,0,0,0-.141-.044.736.736,0,0,0-.665.2.739.739,0,0,0,.525,1.264.887.887,0,0,0,.14-.015.449.449,0,0,0,.141-.044.573.573,0,0,0,.133-.067l.111-.089.089-.111a.592.592,0,0,0,.067-.133.446.446,0,0,0,.044-.133.713.713,0,0,0,0-.3A.446.446,0,0,0,387.551,159.723Zm2.4-8.97a4.8,4.8,0,1,0-6.155,0c-3.519.857-5.793,3.205-5.793,6.294v2.218a2.22,2.22,0,0,0,2.218,2.218h.739v1.478a1.48,1.48,0,0,0,1.478,1.478h8.871a1.48,1.48,0,0,0,1.478-1.478v-1.478h.739a2.22,2.22,0,0,0,2.218-2.218v-2.218C395.741,153.959,393.467,151.611,389.948,150.753Zm-6.4-3.686a3.327,3.327,0,1,1,3.326,3.326A3.331,3.331,0,0,1,383.544,147.068Zm-1.109,15.893v-5.914h8.871v5.914Zm11.828-3.7a.74.74,0,0,1-.739.739h-.739v-2.957a1.48,1.48,0,0,0-1.478-1.478h-8.871a1.48,1.48,0,0,0-1.478,1.478V160h-.739a.74.74,0,0,1-.739-.739v-2.218c0-3.047,3.04-5.175,7.392-5.175s7.392,2.127,7.392,5.175Zm17-33.265h-19.22a5.181,5.181,0,0,0-5.175,5.175v8.871a.739.739,0,0,0,1.478,0v-8.871a3.7,3.7,0,0,1,3.7-3.7h19.22a3.7,3.7,0,0,1,3.7,3.7V144.48a3.7,3.7,0,0,1-3.7,3.7H398.7a.739.739,0,0,0-.523.217l-2.957,2.957a.739.739,0,1,0,1.045,1.045l2.74-2.74h12.261a5.181,5.181,0,0,0,5.174-5.175V131.175A5.181,5.181,0,0,0,411.265,126ZM397.22,137.827a1.478,1.478,0,1,0-1.478,1.478A1.48,1.48,0,0,0,397.22,137.827Zm5.914,0a1.478,1.478,0,1,0-1.478,1.478A1.48,1.48,0,0,0,403.133,137.827Zm5.914,0a1.478,1.478,0,1,0-1.479,1.478A1.48,1.48,0,0,0,409.047,137.827Z"
        transform="translate(-378 -126)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco394;
