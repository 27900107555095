/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\legenda-inpagamento-dark.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const LegendaInpagamentoDark = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-legenda-inpagamento-dark', className].join(' ');
  return (
    <svg
      id="legenda-inpagamento-dark"
      width="100%"
      height="100%"
      viewBox="0 0 24 12"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path fill="#55D470" d="M0 0h24v12H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 .427734h24V0H0v.427734Zm0 .5v.499996h24V.927734H0Zm24 .999996H0v.5h24v-.5Zm0 1H0v.5h24v-.5Zm0 1H0v.5h24v-.5Zm0 1H0v.5h24v-.5Zm0 1H0v.5h24v-.5Zm0 1H0v.5h24v-.5Zm0 1H0v.5h24v-.5Zm0 1H0v.5h24v-.5Zm0 1H0v.49997h24v-.49997Zm0 .99997H0v.5h24v-.5Z"
        fill="#000"
        fillOpacity=".2"
      />
    </svg>
  );
};
export default LegendaInpagamentoDark;
