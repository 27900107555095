import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { getLoggingLevelTelemetry } from './utils';

export const reactPlugin = new ReactPlugin();

export const disableTelemetry = process.env.NODE_ENV !== 'production';

const appInsight = new ApplicationInsights({
  config: {
    connectionString: process.env.NEXT_PUBLIC_APP_INSIGHT_CONNECTION,
    enableAutoRouteTracking: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    isBrowserLinkTrackingEnabled: true,
    // @ts-ignore
    extensions: [reactPlugin],
    disableTelemetry,
    loggingLevelTelemetry: getLoggingLevelTelemetry(),
  },
});

appInsight.loadAppInsights();
appInsight.addTelemetryInitializer((envelope) => {
  if (!envelope.tags) {
    envelope.tags = [];
  }
  envelope.tags.push({ 'ai.cloud.role': 'FrontEnd' });
});

export { appInsight };
