/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-363.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco363 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-363', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.524"
      height="41.396"
      viewBox="0 0 32.524 41.396"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M497.548,129.413a3.343,3.343,0,0,0-4.72,0l-.217.217V127.7a3.7,3.7,0,0,0-3.7-3.7H475.61a3.7,3.7,0,0,0-3.7,3.7v8.213a5.155,5.155,0,0,0-3.7-1.561A2.22,2.22,0,0,0,466,136.567v18.48c0,5.707,5.637,10.349,12.567,10.349,4.971,0,9.421-2.386,11.448-6.1a3.692,3.692,0,0,0,2.527-2.816,3.154,3.154,0,0,0,2.81-.932l.718-.718a3.293,3.293,0,0,0-.683-5.231l.683-.683a3.293,3.293,0,0,0-.683-5.231l.683-.683a3.331,3.331,0,0,0-1.755-5.637l3.233-3.233A3.343,3.343,0,0,0,497.548,129.413ZM473.392,127.7a2.22,2.22,0,0,1,2.218-2.218h13.306a2.22,2.22,0,0,1,2.218,2.218v.739H473.392Zm0,2.218h17.741V138.5l-.5.5a3.294,3.294,0,0,0,.683,5.231l-.683.683a3.294,3.294,0,0,0,.683,5.231l-.683.683a3.319,3.319,0,0,0-.939,2.74h-16.3Zm5.174,34c-6.115,0-11.088-3.979-11.088-8.871v-18.48a.74.74,0,0,1,.739-.739,3.7,3.7,0,0,1,3.7,3.7v16.263a3.7,3.7,0,0,0,3.7,3.7h12.554A11.761,11.761,0,0,1,478.567,163.918ZM488.916,158H475.61a2.22,2.22,0,0,1-2.218-2.218v-.739h16.831a3.34,3.34,0,0,0,.409.5,3.3,3.3,0,0,0,.485.4A2.213,2.213,0,0,1,488.916,158Zm6.109-4.219-.718.718a1.864,1.864,0,0,1-2.629,0,1.838,1.838,0,0,1-.336-.48l-.005-.01a1.855,1.855,0,0,1,.341-2.14l.718-.718a1.859,1.859,0,0,1,2.629,2.629Zm0-5.914-.718.718a1.859,1.859,0,0,1-2.629-2.629l.718-.718a1.859,1.859,0,0,1,2.629,2.629Zm0-8.543a1.862,1.862,0,0,1,0,2.629l-.718.718a1.859,1.859,0,0,1-2.629-2.629l.718-.718a1.864,1.864,0,0,1,2.629,0Zm1.478-6.241-3.891,3.891V131.72l1.262-1.262a1.859,1.859,0,1,1,2.629,2.629Zm-14.979,5.7h2.957a.739.739,0,1,0,0-1.478H483v-1.478a.739.739,0,0,0-1.478,0v1.478a2.218,2.218,0,1,0,0,4.435H483a.739.739,0,1,1,0,1.478h-2.957a.739.739,0,0,0,0,1.478h1.478v1.478a.739.739,0,1,0,1.478,0V144.7a2.218,2.218,0,0,0,0-4.435h-1.478a.739.739,0,0,1,0-1.478Z"
        transform="translate(-466 -124)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco363;
