/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-201.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco201 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-201', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="32.526"
      viewBox="0 0 38.439 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0)">
        <g transform="translate(15.524 0)">
          <path
            className="a"
            d="M66.7,473.392a3.7,3.7,0,1,1,3.7-3.7A3.7,3.7,0,0,1,66.7,473.392Zm0-5.914a2.218,2.218,0,1,0,2.218,2.218A2.22,2.22,0,0,0,66.7,467.478Z"
            transform="translate(-63 -466)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M76.743,481.539h-3.39L66.6,474.781a.739.739,0,1,0-1.045,1.046l5.713,5.713H51.177l5.713-5.713a.739.739,0,1,0-1.045-1.046l-6.758,6.759H45.7a3.7,3.7,0,0,0-3.7,3.7v11.827a3.7,3.7,0,0,0,3.7,3.7H76.743a3.7,3.7,0,0,0,3.7-3.7V485.235A3.7,3.7,0,0,0,76.743,481.539Zm2.218,15.524a2.22,2.22,0,0,1-2.218,2.218H45.7a2.22,2.22,0,0,1-2.218-2.218V485.235a2.22,2.22,0,0,1,2.218-2.218H76.743a2.22,2.22,0,0,1,2.218,2.218Z"
          transform="translate(-42 -468.233)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M76.829,498.871h-3.7v-2.957h2.218a.739.739,0,1,0,0-1.478H73.133v-2.957h3.7a.739.739,0,1,0,0-1.478H72.394a.739.739,0,0,0-.739.739v8.871a.739.739,0,0,0,.739.739h4.435a.739.739,0,0,0,0-1.478Zm-7.392,0h-3.7v-8.131a.739.739,0,0,0-1.478,0v8.871a.739.739,0,0,0,.739.739h4.435a.739.739,0,0,0,0-1.478Zm-18.591-7.392h3.068a.739.739,0,1,0,0-1.478H50.846a2.847,2.847,0,0,0-.782,5.583l2.119.6a1.368,1.368,0,0,1-.376,2.683H48.739a.739.739,0,0,0,0,1.478h3.068a2.847,2.847,0,0,0,.782-5.583l-2.119-.6a1.368,1.368,0,0,1,.376-2.683Zm8.944-.973a.739.739,0,0,0-1.4,0l-2.957,8.871a.739.739,0,0,0,1.4.467l.719-2.156h3.074l.719,2.156a.739.739,0,0,0,1.4-.467Zm-1.746,5.7,1.045-3.133,1.045,3.133Z"
          transform="translate(-43.565 -472.259)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco201;
