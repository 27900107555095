/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\legenda-tqq-light.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const LegendaTqqLight = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-legenda-tqq-light', className].join(' ');
  return (
    <svg
      id="legenda-tqq-light"
      width="100%"
      height="100%"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M.625215 8.726H1.91795l1.15223-5.35832h1.37705l.26229-1.2459H.662686l-.262295 1.2459H1.76807L.625215 8.726ZM6.26375 8.76347 6.6853 10h1.34894l-.51522-1.26464c.98361-.24356 1.51757-1.06791 1.75176-2.15456l.43091-2.01406C10.067 2.90867 9.16773 2 7.80942 2c-1.37705 0-2.2014.9274-2.48243 2.29508l-.42155 1.98595c-.2904 1.40515.32787 2.27635 1.35831 2.48244Zm-.09367-2.36066.45901-2.16393c.16862-.75879.59953-1.00235 1.04918-1.00235.5527 0 .94614.31851.75878 1.1897l-.45901 2.16393c-.16862.75879-.59953.99298-1.04918.99298-.51522 0-1.00234-.2623-.75878-1.18033ZM12.1134 8.76347 12.535 10h1.3489l-.5152-1.26464c.9836-.24356 1.5176-1.06791 1.7518-2.15456l.4309-2.01406C15.9167 2.90867 15.0174 2 13.6591 2c-1.3771 0-2.2014.9274-2.4824 2.29508l-.4216 1.98595c-.2904 1.40515.3279 2.27635 1.3583 2.48244Zm-.0936-2.36066.459-2.16393c.1686-.75879.5995-1.00235 1.0492-1.00235.5526 0 .9461.31851.7587 1.1897l-.459 2.16393c-.1686.75879-.5995.99298-1.0492.99298-.5152 0-1.0023-.2623-.7587-1.18033Z"
        fill="#0F1315"
      />
    </svg>
  );
};
export default LegendaTqqLight;
