import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApiPayload, ApiStatus } from 'features/api/thunkUtils';
import { sequence as LiveFiltersKeys, LiveFiltersStatus, enFiltersKind } from 'features/live/liveFilters/types';
import { Direttissima, DirettissimeResponsePayload } from 'lib/api/sport/direttissimeResponseApi';
import { UpdateProssimiEventiPayload } from 'lib/api/sport/prossimiEventiResponseApi';
import { SnaiRunnerResponsePayload, SnaiRunnerStateType } from 'lib/api/sport/snaiRunnerResponseApi';
import {
  EsitoMap,
  InfoAggiuntivaMap,
  InfoTipoScommessaMap,
  ScommessaMap,
  ScommessaResponse,
  ScommessaResponsePayload,
} from 'lib/api/sport/sportScommesseBySlugResponse';
import {
  SportsAvvenimentoEsposto,
  SportsCacheScommesseCoreWithTranslationsDto,
  SportsCacheTemplateAvvenimentoDto,
  SportsCacheTemplateAvvenimentoMarcatoreDto,
  SportsCacheTemplateDisciplinaDto,
  SportsCacheTemplateDisciplinaLiveDto,
  SportsCacheTemplateManifestazioneDto,
  SportsGruppoScommessa,
  SportsInfoTipoScommessaGroupDto,
  SportsPiuGiocateTabDto,
  SportsRemoveGruppoScommessaSignalREvent,
  SportsUpdateAvvenimentoSignalREvent,
  SportsUpdateGruppoScommessaSignalREventWithTranslationsDto,
  SportsUpdateRisultatiniSignalREvent,
} from 'types/swagger';
import { hasValue, isMatch, isTruthy } from 'utility/functions';
import {
  ListenerType,
  ProssimiEventiStateType,
  SportFilters,
  UpdateCounterType,
  UpdateEventType,
  UpdateStatoInfoAgg,
} from './types';
import {
  applyAddAvvenimento,
  applyAvvenimento,
  applyInfoAggiuntiva,
  applyQuote,
  applyRemoveDataFromAvvenimento,
  applyRemoveGruppo,
  applyRisultatini,
  applyScommessa,
  applyScommesseAvvenimento,
  applyStatoScommessa,
  applyUpdateGruppo,
  applyUpdateInfoAgg,
  decodePathname,
} from './utils/utils';

import type { ApiStatusType } from 'features/api/thunkUtils';
import { navigate } from 'features/location/types';
import { getPathName } from 'features/location/utils';
import { purgePageKeys } from '../../hooks/useLingUI';
import { getProssimiEventi, getScommesseCustomPreMatch, removeAvvenimento } from './actions';

export interface SportState {
  slug?: string | undefined;
  isInfoOpen: boolean;
  templateSlug?: string | undefined;
  isTopTemplate: boolean;
  selectedFilters: SportFilters;
  isNavMobileOpened: boolean;
  isStatsWidgetOpen: boolean;
  avvenimentoFilter: Array<string>;
  scoreboardHasError: boolean;
  scommessaStatus?: ApiStatusType;
  selectedFiltersLive: LiveFiltersStatus;
  boxSearchTipiScommessa: {
    textSearched: string;
    indexFilter?: number;
    expandWindow: boolean;
    valueFilterSelected?:
      | {
          gruppoKey: string;
          value: string;
        }
      | undefined;
    isAccordionFilterClosed: boolean;
  };
  isVisualizzazioneEstesa: boolean;
  countersUpdateMessage: UpdateCounterType;

  scommessa?: ScommessaResponse;
  templateLive?: SportsCacheTemplateDisciplinaLiveDto;
  templateLiveStatus?: ApiStatusType;
  templateDisciplina?: SportsCacheTemplateDisciplinaDto;
  templateDisciplinaStatus?: ApiStatusType;
  templateAvvenimento?: SportsCacheTemplateAvvenimentoDto;
  templateAvvenimentoMarcatori?: SportsCacheTemplateAvvenimentoMarcatoreDto;
  templateAvvenimentoStatus?: ApiStatusType;
  templateManifestazione?: SportsCacheTemplateManifestazioneDto;
  templateManifestazioneStatus?: ApiStatusType;
  templateDirettissime?: {
    direttissime: Direttissima[];
  } & SportsCacheScommesseCoreWithTranslationsDto;
  templateProssimiEventi?: ProssimiEventiStateType;
  templateSnaiRunner?: SnaiRunnerStateType;
  currentTime: Date | null;
  isBetGeniusLoaded: boolean;
}

export const filtersDefault: SportFilters = {
  quota: [1, 100],
  orario: 'default',
  isResetted: false,
};

export const filtersLiveDefault: LiveFiltersStatus = {};

// Define the initial state using that type
export const initialState: SportState = {
  isNavMobileOpened: false,
  isStatsWidgetOpen: false,
  isVisualizzazioneEstesa: false,
  avvenimentoFilter: [],
  boxSearchTipiScommessa: {
    textSearched: '',
    expandWindow: false,
    isAccordionFilterClosed: false,
  },
  isTopTemplate: false,
  isInfoOpen: false,
  // streamingMode: VideoStreamingMode.off,
  scoreboardHasError: false,
  countersUpdateMessage: {
    avvenimento: {
      counter: 0,
    },
    infoAgg: {
      counter: 0,
    },
    quote: {
      counter: 0,
    },
  },
  selectedFilters: filtersDefault,
  selectedFiltersLive: filtersLiveDefault,
  currentTime: null,
  isBetGeniusLoaded: false,
};

const purgeInfoTipoScommessaKeyList = (tab: SportsPiuGiocateTabDto, scommessaResponse: ScommessaResponse): void => {
  (tab?.contentTabList ?? []).flatMap((contentTabList) => {
    let infoTipoScommessaKeyList: Array<string> = [];
    (contentTabList?.avvenimentoList ?? []).flatMap((avvenimento) => {
      infoTipoScommessaKeyList = (avvenimento.infoTipoScommessaKeyList ?? []).filter(
        (infoTipoScommessaKey) => scommessaResponse?.infoTipoScommessaMap[infoTipoScommessaKey]
      );
      avvenimento.infoTipoScommessaKeyList = infoTipoScommessaKeyList;
    });
  });
};

const reduceScommessa = (state: SportState, payload: ScommessaResponsePayload): void => {
  const { data, status } = payload ?? {};
  const nextSt = status ?? ApiStatus.idle;
  if (data) {
    state.scommessa = data;
  }
  if (state.templateLive?.tabList) {
    state.templateLive.tabList.flatMap((tab) => purgeInfoTipoScommessaKeyList(tab as any, state.scommessa!));
  }
  if (nextSt === ApiStatus.failed) {
    delete state.scommessa;
  }
  state.scommessaStatus = nextSt;
};

export const sportSlice = createSlice({
  name: 'sport',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    initScommessaResponse: (state, action: PayloadAction<ScommessaResponsePayload>) => {
      // const { data, status } = action.payload;
      // const nextSt = status ?? ApiStatus.idle;
      // if (data) {
      //   state.scommessa = data;
      // }
      // if (state.templateLive?.tabList) {
      //   state.templateLive.tabList.flatMap((tab) => purgeInfoTipoScommessaKeyList(tab as any, state.scommessa!));
      // }
      // state.scommessaStatus = nextSt;
      reduceScommessa(state, action.payload);
    },
    initTemplateLive: (state, { payload }: PayloadAction<ApiPayload<SportsCacheTemplateDisciplinaLiveDto>>) => {
      const { status, ...template } = payload;
      const nestSt = status ?? ApiStatus.idle;

      switch (nestSt) {
        case ApiStatus.loading: {
          purgePageKeys();
          delete state.scommessa;
          break;
        }
        case ApiStatus.idle: {
          const nextTemplate = decodePathname(Reflect.get(template ?? {}, 'slug'));
          if (nextTemplate !== state.templateSlug) {
            // delete state.scommessa; some times scommessa response is faster than template
            state.templateSlug = nextTemplate;
          }
          state.templateLive = template;
        }
      }
      state.templateLiveStatus = nestSt;
    },
    initTemplateAvvenimento(state, { payload }: PayloadAction<ApiPayload<SportsCacheTemplateAvvenimentoDto>>) {
      const { status, ...template } = payload;
      const nestSt = status ?? ApiStatus.idle;

      switch (nestSt) {
        case ApiStatus.loading: {
          purgePageKeys();
          delete state.scommessa;
          delete state.templateDisciplina;
          delete state.templateManifestazione;
          break;
        }
        case ApiStatus.idle: {
          const nextTemplate = decodePathname(Reflect.get(template ?? {}, 'slug'));
          if (nextTemplate !== state.templateSlug) {
            // delete state.scommessa; some times scommessa response is faster than template
            state.templateSlug = nextTemplate;
          }
          state.templateAvvenimento = template;
          break;
        }
      }
      state.templateAvvenimentoStatus = nestSt;
    },
    initTemplateAvvenimentoMarcatore(
      state,
      { payload }: PayloadAction<ApiPayload<SportsCacheTemplateAvvenimentoMarcatoreDto>>
    ) {
      const { status, ...template } = payload;
      const nestSt = status ?? ApiStatus.idle;

      switch (nestSt) {
        case ApiStatus.loading: {
          purgePageKeys();
          delete state.scommessa;
          delete state.templateDisciplina;
          delete state.templateManifestazione;
          break;
        }
        case ApiStatus.idle: {
          const nextTemplate = decodePathname(Reflect.get(template ?? {}, 'slug'));
          if (nextTemplate !== state.templateSlug) {
            // delete state.scommessa; some times scommessa response is faster than template
            state.templateSlug = nextTemplate;
          }
          state.templateAvvenimentoMarcatori = template;
          break;
        }
      }
      state.templateAvvenimentoStatus = nestSt;
    },
    initTemplateDisciplina(state, { payload }: PayloadAction<ApiPayload<SportsCacheTemplateDisciplinaDto>>) {
      const { status, ...template } = payload;
      const nestSt = status ?? ApiStatus.idle;

      switch (nestSt) {
        case ApiStatus.loading: {
          purgePageKeys();
          delete state.scommessa;
          delete state.templateAvvenimento;
          delete state.templateManifestazione;
          break;
        }
        case ApiStatus.idle: {
          const nextTemplate = decodePathname(Reflect.get(template ?? {}, 'slug'));
          if (nextTemplate !== state.templateSlug) {
            // delete state.scommessa; some times scommessa response is faster than template
            state.templateSlug = nextTemplate;
          }
          state.templateDisciplina = template;
          break;
        }
      }
      state.templateDisciplinaStatus = nestSt;
    },
    initTemplateManifestazione(state, { payload }: PayloadAction<ApiPayload<SportsCacheTemplateManifestazioneDto>>) {
      const { status, ...template } = payload ?? {};
      const nestSt = status ?? ApiStatus.idle;

      switch (nestSt) {
        case ApiStatus.loading: {
          purgePageKeys();
          delete state.scommessa;
          delete state.templateDisciplina;
          delete state.templateAvvenimento;
          break;
        }
        case ApiStatus.idle: {
          const nextTemplate = decodePathname(Reflect.get(template ?? {}, 'slug'));
          if (nextTemplate !== state.templateSlug) {
            // delete state.scommessa; some times scommessa response is faster than template
            state.templateSlug = nextTemplate;
          }
          state.templateManifestazione = template;
          break;
        }
      }
      state.templateManifestazioneStatus = nestSt;
    },
    templateManifestazioneLoadingUpdated: (state) => {
      state.templateManifestazioneStatus = ApiStatus.loading;
    },
    initTemplateDirettissime(state, { payload }: PayloadAction<DirettissimeResponsePayload>) {
      const { data } = payload;
      if (data) {
        state.templateDirettissime = data;
      }
    },
    initTemplateSnaiRunner(state, { payload }: PayloadAction<SnaiRunnerResponsePayload>) {
      const { data } = payload;
      if (data) {
        state.templateSnaiRunner = data;
      }
    },
    removeAvvenimentoContentTabList: (state, { payload }: PayloadAction<string>) => {
      if (!state.templateProssimiEventi) return;
      state.templateProssimiEventi.tabList.forEach((tab) => {
        tab?.contentTabList?.forEach((contentTab) => {
          const index = contentTab.avvenimentoList?.findIndex((avv) => avv.key === payload) ?? -1;
          if (index > -1) {
            contentTab.avvenimentoList?.splice(index, 1);
          }
        });
        tab.contentTabList = tab.contentTabList?.filter((contentTab) => (contentTab.avvenimentoList ?? []).length > 0);
      });
      state.templateProssimiEventi.tabList = state.templateProssimiEventi?.tabList.filter(
        (tab) => (tab.contentTabList ?? []).length > 0
      );
    },
    updateQuote: (state, action: PayloadAction<EsitoMap>) => {
      applyQuote(state?.scommessa, action.payload);
      if (!!state.templateProssimiEventi) applyQuote(state?.templateProssimiEventi, action.payload);
      if (!!state.templateSnaiRunner) applyQuote(state?.templateSnaiRunner, action.payload);
    },
    updateRisultatini: (state, { payload }: PayloadAction<SportsUpdateRisultatiniSignalREvent>) => {
      applyRisultatini(state.scommessa, payload);
    },
    updateInfoAgg: (
      state,
      {
        payload: { esitoMap, infoAggiuntivaMap },
      }: PayloadAction<{ infoAggiuntivaMap: InfoAggiuntivaMap; esitoMap: EsitoMap }>
    ) => {
      applyInfoAggiuntiva(state.scommessa, infoAggiuntivaMap, esitoMap);
      if (!!state.templateProssimiEventi)
        applyInfoAggiuntiva(state.templateProssimiEventi, infoAggiuntivaMap, esitoMap);
      if (!!state.templateSnaiRunner) applyInfoAggiuntiva(state.templateSnaiRunner, infoAggiuntivaMap, esitoMap);
    },
    addScommessa: (
      state,
      {
        payload,
      }: PayloadAction<{
        scommessaMap: ScommessaMap;
        infoAggiuntivaMap: InfoAggiuntivaMap;
        infoTipoScommessaMap: InfoTipoScommessaMap;
        esitoMap: EsitoMap;
        infoTipoScommessaGroupMap?: Record<string, SportsInfoTipoScommessaGroupDto>;
      }>
    ) => {
      applyScommessa(state.scommessa!, payload);
      if (!!state.templateProssimiEventi) applyScommessa(state.templateProssimiEventi!, payload);
    },
    updateStatoScommessa: (state, action: PayloadAction<ScommessaMap>) => {
      applyStatoScommessa(state.scommessa, action.payload);
      if (!!state.templateProssimiEventi) applyStatoScommessa(state.templateProssimiEventi, action.payload);
    },
    updateAvvenimento: (state, { payload }: PayloadAction<SportsUpdateAvvenimentoSignalREvent>) => {
      applyAvvenimento(state.scommessa, payload);
      if (!!state.templateProssimiEventi) applyAvvenimento(state.templateProssimiEventi, payload);
      if (!!state.templateSnaiRunner) applyAvvenimento(state.templateSnaiRunner, payload);
    },
    updateScommesseAvvenimento: (
      state,
      {
        payload,
      }: PayloadAction<{
        avvenimentoKey: string;
        allInfoAggAreActive: boolean;
      }>
    ) => {
      const { avvenimentoKey, allInfoAggAreActive } = payload;
      applyScommesseAvvenimento(state.scommessa, avvenimentoKey, allInfoAggAreActive);
      if (!!state.templateProssimiEventi)
        applyScommesseAvvenimento(state.templateProssimiEventi, avvenimentoKey, allInfoAggAreActive);
    },
    addAvvenimento: (
      state,
      {
        payload: { avvenimento, avvenimentoKey, updateTemplate, updateProssimiEventi },
      }: PayloadAction<{
        avvenimentoKey: string;
        avvenimento: SportsAvvenimentoEsposto;
        updateTemplate:
          | SportsCacheTemplateDisciplinaLiveDto
          | SportsCacheTemplateAvvenimentoDto
          | SportsCacheTemplateDisciplinaDto
          | SportsCacheTemplateManifestazioneDto;
        updateProssimiEventi: UpdateProssimiEventiPayload;
      }>
    ) => {
      if (state.templateLive && updateTemplate) {
        state.templateLive = updateTemplate as SportsCacheTemplateDisciplinaLiveDto;
      }
      if (state.templateDisciplina && updateTemplate) {
        state.templateDisciplina = updateTemplate as SportsCacheTemplateDisciplinaDto;
      }
      if (state.templateAvvenimento && updateTemplate) {
        state.templateAvvenimento = updateTemplate as SportsCacheTemplateAvvenimentoDto;
      }
      if (state.templateManifestazione && updateTemplate) {
        state.templateManifestazione = updateTemplate as SportsCacheTemplateManifestazioneDto;
      }
      if (state.templateProssimiEventi && updateProssimiEventi) {
        state.templateProssimiEventi = { ...state.templateProssimiEventi, ...updateProssimiEventi };
      }
      if (state.scommessa) {
        applyAddAvvenimento(state.scommessa, { [avvenimentoKey]: avvenimento });
      }
      if (state.templateProssimiEventi) {
        applyAddAvvenimento(state.templateProssimiEventi, { [avvenimentoKey]: avvenimento });
      }
    },
    removeScommessa: (
      state,
      action: PayloadAction<{ scommesseToRemove: Array<string>; gruppoList: Array<SportsGruppoScommessa> }>
    ) => {
      action.payload.scommesseToRemove.forEach((scommessaKey) => {
        delete state.scommessa?.scommessaMap[scommessaKey];
        delete state.scommessa?.infoAggiuntivaMap[scommessaKey];
      });
      if (state.templateAvvenimento?.gruppoList) {
        state.templateAvvenimento.gruppoList = action.payload.gruppoList;
      }
      if (state.templateDisciplina?.inEvidenza) {
        const inEvidenzaFiltered = [...state.templateDisciplina?.inEvidenza].filter(
          (element) => !action.payload.scommesseToRemove.includes(element)
        );
        state.templateDisciplina.inEvidenza = inEvidenzaFiltered;
      }
    },
    updateStatoInfoAgg: (state, action: PayloadAction<UpdateStatoInfoAgg>) => {
      applyUpdateInfoAgg(state.scommessa, action.payload);
      if (!!state.templateProssimiEventi) applyUpdateInfoAgg(state.templateProssimiEventi, action.payload);
      if (!!state.templateSnaiRunner) applyUpdateInfoAgg(state.templateSnaiRunner, action.payload);
    },
    setSlug: (state, action: PayloadAction<string>) => {
      state.slug = decodePathname(action.payload);
    },
    toggleNavMobile: (state, action: PayloadAction<boolean>) => {
      state.isNavMobileOpened = action.payload;
    },
    quotaSelected: (state, action: PayloadAction<number[]>) => {
      state.selectedFilters.quota = action.payload;
    },
    orarioSelected: (state, action: PayloadAction<string>) => {
      state.selectedFilters.orario = action.payload;
    },
    setIsResettedFilter: (state, action: PayloadAction<boolean>) => {
      state.selectedFilters.isResetted = action.payload;
    },
    resetMenuManifestazioniFilters: (state) => {
      state.selectedFilters = filtersDefault;
      state.selectedFiltersLive = filtersLiveDefault;
    },
    setFilterAvvenimento: (state, action: PayloadAction<string>) => {
      state.avvenimentoFilter.push(action.payload);
    },
    removeFilterAvvenimento: (state, action: PayloadAction<string>) => {
      state.avvenimentoFilter.splice(state.avvenimentoFilter.indexOf(action.payload), 1);
    },
    resetFilterAvvenimento: (state) => {
      const len = state.avvenimentoFilter.length;
      if (len > 0) {
        state.avvenimentoFilter.splice(0, len);
      }
    },
    setTextSearchedTipiScommessa: (state, action: PayloadAction<string>) => {
      state.boxSearchTipiScommessa.textSearched = action.payload;
    },
    setIndexFilterTipiScommessa: (state, action: PayloadAction<number | undefined>) => {
      state.boxSearchTipiScommessa.indexFilter = action.payload;
    },
    setExpandWindowTipiScommessa: (state, action: PayloadAction<boolean>) => {
      state.boxSearchTipiScommessa.expandWindow = action.payload;
    },
    setValueFilterSelectedTipiScommessa: (state, action: PayloadAction<{ gruppoKey: string; value: string }>) => {
      state.boxSearchTipiScommessa.valueFilterSelected = action.payload;
    },
    resetValueFilterSelectedTipiScommessa: (state, action: PayloadAction<undefined>) => {
      state.boxSearchTipiScommessa.valueFilterSelected = action.payload;
    },
    setAccordionFilter: (state, action: PayloadAction<boolean>) => {
      state.boxSearchTipiScommessa.isAccordionFilterClosed = action.payload;
    },
    setIsInfoOpen: (state, action: PayloadAction<boolean>) => {
      state.isInfoOpen = action.payload;
    },
    setScoreboardHasError: (state, { payload }: PayloadAction<boolean>) => {
      state.scoreboardHasError = payload;
    },
    increaseCounterMessage: (state, { payload }: PayloadAction<{ type: UpdateEventType }>) => {
      state.countersUpdateMessage[payload.type].counter++;
    },
    resetCounterMessage: (state) => {
      Object.keys(state.countersUpdateMessage).forEach((key) => (state.countersUpdateMessage[key].counter = 0));
    },
    updateGruppo: (state, { payload }: PayloadAction<SportsUpdateGruppoScommessaSignalREventWithTranslationsDto>) => {
      const { gruppo } = payload ?? {};

      if (`${gruppo?.slug}`.startsWith(`${state.templateSlug}/`)) {
        if (!!state.templateAvvenimento) {
          applyUpdateGruppo(state.templateAvvenimento!, gruppo);
        }
        if (!!state.templateManifestazione) {
          applyUpdateGruppo(state.templateManifestazione!, gruppo);
        }
      } else {
        console.error(`ERROR! gruppo: "${gruppo?.slug}" is not child of "${state.templateSlug}"`);
      }
    },
    removeGruppo: (state, { payload }: PayloadAction<SportsRemoveGruppoScommessaSignalREvent>) => {
      const { key } = payload;

      if (isTruthy(state.templateAvvenimento?.gruppoList?.length)) {
        applyRemoveGruppo(state.templateAvvenimento!, key);
      }
      if (isTruthy(state.templateManifestazione?.gruppoList?.length)) {
        applyRemoveGruppo(state.templateManifestazione!, key);
      }
    },
    setLiveFilters: (state, { payload }: PayloadAction<Record<string, any>>) => {
      for (const k of LiveFiltersKeys) {
        if (!hasValue(payload[k])) {
          continue;
        }
        const list = state.selectedFiltersLive[k] ?? [];
        const idx = isMatch(enFiltersKind.Minutaggio, `^${k}$`)
          ? list.findIndex((x) => x.from === payload[k].from)
          : list.indexOf(payload[k]);
        if (idx < 0) {
          list.push(payload[k]);
        } else {
          list.splice(idx, 1);
        }
        if (list.length > 0) {
          state.selectedFiltersLive[k] = list.sort();
        } else {
          delete state.selectedFiltersLive[k];
        }
      }
    },
    resetLiveFilters: (state) => {
      state.selectedFiltersLive = filtersLiveDefault;
    },
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload;
    },
    setIsBetGeniusLoaded: (state, { payload }: PayloadAction<boolean>) => {
      state.isBetGeniusLoaded = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(navigate.pending, (state, action) => {
        const nextPath = `${action?.meta?.arg}/`;
        const srcPath = `${getPathName()}/`;

        const [nextRoot, nextDisciplina] = nextPath
          .split('/')
          .filter((x) => !!x)
          .splice(0, 2);
        const [prevRoot, prevDisciplina] = srcPath
          .split('/')
          .filter((x) => !!x)
          .splice(0, 2);

        const isRootChanging = nextRoot !== prevRoot;
        const isDisciplinaChanging = prevDisciplina !== nextDisciplina;
        const isFromDisciplinaToRoot = isTruthy(prevDisciplina?.length) && !isTruthy(nextDisciplina?.length);
        if (isRootChanging || isDisciplinaChanging || isFromDisciplinaToRoot) {
          state.selectedFilters = filtersDefault;
          state.selectedFiltersLive = filtersLiveDefault;
        }
      })
      .addCase(removeAvvenimento.fulfilled, (state, action) => {
        const {
          avvenimentoKey,
          updateTemplate: updateTemplateLive,
          updateTemplatePrematch,
          updateProssimiEventi,
        } = action?.payload ?? {};
        let removeAvvenimento = true;
        const indexToRemove = (state?.scommessa?.avvenimentoList ?? []).findIndex((avv) => avv.key === avvenimentoKey);
        if (state?.scommessa?.avvenimentoList && indexToRemove > -1) {
          if (state.templateAvvenimento) {
            state.templateAvvenimento.gruppoList = [];
            removeAvvenimento = false;
          }
        }

        if (state.templateLive && updateTemplateLive) {
          state.templateLive = updateTemplateLive;
        }

        if (state.templateDisciplina && updateTemplatePrematch) {
          state.templateDisciplina = updateTemplatePrematch;
        }
        if (state.templateProssimiEventi && updateProssimiEventi) {
          state.templateProssimiEventi = { ...state.templateProssimiEventi, ...updateProssimiEventi };
        }
        applyRemoveDataFromAvvenimento(state.scommessa, avvenimentoKey, removeAvvenimento);
        if (state.templateProssimiEventi) {
          applyRemoveDataFromAvvenimento(state.templateProssimiEventi, avvenimentoKey, removeAvvenimento);
        }
      })
      .addCase(navigate.fulfilled, (state, action) => {
        state.slug = decodePathname(action.payload);
      })
      .addCase(getProssimiEventi.pending, (state) => {
        delete state.templateProssimiEventi;
      })
      .addCase(getProssimiEventi.fulfilled, (state, { payload }) => {
        state.templateProssimiEventi = payload;
      })
      .addCase(getScommesseCustomPreMatch.pending, (state) => {
        delete state.scommessa;
        reduceScommessa(state, { status: ApiStatus.loading });
      })
      .addCase(getScommesseCustomPreMatch.rejected, (state) => {
        // state.scommessaStatus = ApiStatus.failed;
        reduceScommessa(state, { status: ApiStatus.failed });
      })
      .addCase(getScommesseCustomPreMatch.fulfilled, (state, { payload }) => {
        // TODO : merge reducer with initScommessaResponse (should be the same)
        // state.scommessa = action.payload;
        // if (state.templateLive?.tabList) {
        //   state.templateLive.tabList.flatMap((tab) => purgeInfoTipoScommessaKeyList(tab as any, state.scommessa!));
        // }
        // state.scommessaStatus = ApiStatus.idle;

        reduceScommessa(state, { data: payload, status: ApiStatus.idle });
      });
  },
});

export const {
  setSlug,
  updateQuote,
  updateGruppo,
  removeGruppo,
  addScommessa,
  updateInfoAgg,
  setIsInfoOpen,
  quotaSelected,
  addAvvenimento,
  orarioSelected,
  setLiveFilters,
  toggleNavMobile,
  resetLiveFilters,
  removeScommessa,
  updateRisultatini,
  updateAvvenimento,
  updateStatoInfoAgg,
  setAccordionFilter,
  resetCounterMessage,
  setIsBetGeniusLoaded,
  updateStatoScommessa,
  setFilterAvvenimento,
  setScoreboardHasError,
  increaseCounterMessage,
  resetFilterAvvenimento,
  removeFilterAvvenimento,
  updateScommesseAvvenimento,
  setIndexFilterTipiScommessa,
  setTextSearchedTipiScommessa,
  setExpandWindowTipiScommessa,
  resetMenuManifestazioniFilters,
  initTemplateAvvenimentoMarcatore,
  setValueFilterSelectedTipiScommessa,
  resetValueFilterSelectedTipiScommessa,
  initScommessaResponse,
  initTemplateLive,
  initTemplateAvvenimento,
  initTemplateDisciplina,
  initTemplateManifestazione,
  setIsResettedFilter,
  templateManifestazioneLoadingUpdated,
  initTemplateDirettissime,
  setCurrentTime,
  initTemplateSnaiRunner,
  removeAvvenimentoContentTabList,
} = sportSlice.actions;

export const sportSignalRListeners: ListenerType<any>[] = [
  { eventName: 'UpdateTranslation' },
  {
    eventName: 'UpdateQuote',
    actionType: updateQuote.type, // Aggiorna la cache delle items
  },
  {
    eventName: 'UpdateInfoAgg',
    actionType: updateInfoAgg.type, // Aggiorna la cache di altri dati
  },
  {
    eventName: 'UpdateRisultatini',
    actionType: updateRisultatini.type, // Aggiorna la cache di altri dati
  },
  {
    eventName: 'RemoveScommessa',
    actionType: removeScommessa.type, // Aggiorna la cache di altri dati
  },
  {
    eventName: 'UpdateStatoScommessa',
    actionType: updateStatoScommessa.type, // Aggiorna la cache di altri dati
  },
  {
    eventName: 'UpdateAvvenimento',
    actionType: updateAvvenimento.type, // Aggiorna la cache di altri dati
  },
  {
    eventName: 'UpdateScommesseAvvenimento',
    actionType: updateScommesseAvvenimento.type, // Aggiorna la cache di altri dati
  },
  {
    eventName: 'RemoveAvvenimento',
    action: removeAvvenimento, // Aggiorna la cache di altri dati
  },
  {
    eventName: 'AddAvvenimento',
    actionType: addAvvenimento.type, // Aggiorna la cache di altri dati
  },
  {
    eventName: 'AddScommessa',
    actionType: addScommessa.type, // Aggiorna la cache di altri dati
  },
  {
    eventName: 'UpdateStatoInfoAgg',
    actionType: updateStatoInfoAgg.type,
  },
  {
    eventName: 'UpdateGruppoScommessa',
    actionType: updateGruppo.type,
  },
  {
    eventName: 'RemoveGruppoScommessa',
    actionType: removeGruppo.type,
  },
  // Aggiungi altri listener se necessario
];

export default sportSlice.reducer;
