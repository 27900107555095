/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\check.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Check = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-check', className].join(' ');
  return (
    <svg
      id="check"
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path d="m5.727 10.583-2.78-2.78-.947.94 3.727 3.726 8-8-.94-.94-7.06 7.054Z" fill="currentColor" />
    </svg>
  );
};
export default Check;
