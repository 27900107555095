/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\spid-logo.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const SpidLogo = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-spid-logo', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71.951"
      height="71.951"
      viewBox="0 0 71.951 71.951"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g>
        <path d="M71.951,35.976A35.976,35.976,0,1,1,35.976,0,35.969,35.969,0,0,1,71.951,35.976" fill="#fff" />
        <path
          d="M36.073,39.061a9.742,9.742,0,0,1-7.384-3.037,10.455,10.455,0,0,1-2.9-7.518,10.3,10.3,0,0,1,2.89-7.469,9.807,9.807,0,0,1,7.371-2.976,9.432,9.432,0,0,1,7.3,3.049,10.679,10.679,0,0,1,2.816,7.531,10.43,10.43,0,0,1-2.816,7.445,9.578,9.578,0,0,1-7.273,2.976"
          fill="#06c"
        />
        <path
          d="M25.788,53.767A10.341,10.341,0,0,1,28.665,46.3a9.825,9.825,0,0,1,7.4-2.976,9.388,9.388,0,0,1,7.286,3.049A10.721,10.721,0,0,1,46.163,53.9"
          fill="#06c"
        />
      </g>
    </svg>
  );
};
export default SpidLogo;
