/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-134.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco134 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-134', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44.353"
      height="29.569"
      viewBox="0 0 44.353 29.569"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <g transform="translate(39.918 19.22)">
          <path
            className="a"
            d="M515.7,243.478h-2.957a.739.739,0,0,1,0-1.478H515.7a.739.739,0,1,1,0,1.478Z"
            transform="translate(-512 -242)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M468.728,241.133h-3.771a1.48,1.48,0,0,1-1.479-1.478v-2.218a.739.739,0,1,0-1.478,0v2.218a2.96,2.96,0,0,0,2.957,2.957h3.771a.739.739,0,1,0,0-1.478Zm31.454-11.779-3.642-7.285a2.941,2.941,0,0,0-2.646-1.634h-5.282v-1.478A2.96,2.96,0,0,0,485.655,216h-20.7A2.96,2.96,0,0,0,462,218.957v6.653a.739.739,0,1,0,1.478,0v-6.653a1.48,1.48,0,0,1,1.479-1.478h20.7a1.48,1.48,0,0,1,1.479,1.478v19.959a.739.739,0,1,0,1.478,0v-17h5.282a1.47,1.47,0,0,1,1.323.818l3.287,6.575h-7.675a.739.739,0,1,0,0,1.478H499.7A2.22,2.22,0,0,1,501.918,233v7.392a.74.74,0,0,1-.739.739h-3.032a.739.739,0,0,0,0,1.478h3.032a2.22,2.22,0,0,0,2.218-2.218V233A3.689,3.689,0,0,0,500.181,229.355ZM475.97,242.612H490.9v-1.478H475.97Z"
          transform="translate(-459.043 -216)"
          fill="currentColor"
        />
        <g transform="translate(0 11.827)">
          <path
            className="a"
            d="M465.392,233.478h-6.653a.739.739,0,1,1,0-1.478h6.653a.739.739,0,1,1,0,1.478Z"
            transform="translate(-458 -232)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(2.218 14.784)">
          <path
            className="a"
            d="M466.914,237.478h-5.175a.739.739,0,1,1,0-1.478h5.175a.739.739,0,0,1,0,1.478Z"
            transform="translate(-461 -236)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(4.435 17.741)">
          <path
            className="a"
            d="M468.435,241.478h-3.7a.739.739,0,1,1,0-1.478h3.7a.739.739,0,1,1,0,1.478Z"
            transform="translate(-464 -240)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M474.435,244a4.435,4.435,0,1,0,4.435,4.435A4.44,4.44,0,0,0,474.435,244Zm0,7.392a2.957,2.957,0,1,1,2.957-2.957A2.96,2.96,0,0,1,474.435,251.392ZM496.612,244a4.435,4.435,0,1,0,4.435,4.435A4.44,4.44,0,0,0,496.612,244Zm0,7.392a2.957,2.957,0,1,1,2.957-2.957A2.96,2.96,0,0,1,496.612,251.392Z"
          transform="translate(-461.129 -223.302)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco134;
