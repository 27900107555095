/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-376.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco376 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-376', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M247.7,234.654a3.069,3.069,0,0,0-3.7-.644l-4.847,2.584a2.917,2.917,0,0,0-2.544-1.46h-7.527a2.135,2.135,0,0,1-1.5-.593,9.305,9.305,0,0,0-6.492-2.364,10,10,0,0,0-5.039,1.289,1.389,1.389,0,0,1-.135.053v-.6a.739.739,0,0,0-.739-.739h-4.435a.739.739,0,1,0,0,1.478h3.7v11.827h-2.218a.739.739,0,1,0,0,1.478h2.957a.739.739,0,0,0,.739-.739v-2.19a6.575,6.575,0,0,1,1.564.262l11.248,3.948a5.172,5.172,0,0,0,4.134-.577l14.17-8.509a2.987,2.987,0,0,0,.671-4.5Zm-.757,2.193a1.5,1.5,0,0,1-.685,1.048l-14.169,8.51a3.693,3.693,0,0,1-2.917.43l-11.23-3.944a8.123,8.123,0,0,0-2.028-.351v-7.476a2.828,2.828,0,0,0,.88-.322,8.531,8.531,0,0,1,4.295-1.087,7.94,7.94,0,0,1,5.46,1.943,3.6,3.6,0,0,0,2.536,1.013h7.527a1.479,1.479,0,0,1,1.476,1.559,1.565,1.565,0,0,1-1.6,1.4H225.524a.739.739,0,0,0,0,1.478h10.963a3.022,3.022,0,0,0,3.078-2.8c0-.065-.008-.127-.009-.192l5.132-2.736a1.572,1.572,0,0,1,1.895.3A1.5,1.5,0,0,1,246.944,236.846Zm-13.289-3.191a11.827,11.827,0,1,0-11.827-11.828A11.841,11.841,0,0,0,233.655,233.655Zm0-22.177a10.349,10.349,0,1,1-10.349,10.349A10.36,10.36,0,0,1,233.655,211.478Zm.739,12.567h-2.957a.739.739,0,1,0,0,1.478h1.478V227a.739.739,0,0,0,1.478,0v-1.478a2.218,2.218,0,0,0,0-4.435h-1.478a.739.739,0,0,1,0-1.478h2.957a.739.739,0,0,0,0-1.478h-1.478v-1.478a.739.739,0,0,0-1.478,0v1.478a2.218,2.218,0,0,0,0,4.435h1.478a.739.739,0,0,1,0,1.478Z"
        transform="translate(-210 -210)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco376;
