/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-384.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco384 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-384', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="32.526"
      viewBox="0 0 38.439 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M320.088,395.829a.739.739,0,0,0,1.045,0l5.914-5.914A.739.739,0,1,0,326,388.87l-5.392,5.391-2.434-2.434a.739.739,0,0,0-1.045,1.045Zm-20.175,6.87h4.435a1.48,1.48,0,0,0,1.478-1.478v-4.435a1.481,1.481,0,0,0-1.478-1.478h-4.435a1.48,1.48,0,0,0-1.479,1.478v4.435A1.48,1.48,0,0,0,299.914,402.7Zm0-5.914h4.435v4.435h-4.437Zm-.739,10.349h4.435a.739.739,0,1,0,0-1.478h-4.435a.739.739,0,1,0,0,1.478Zm6.653-.739a.739.739,0,0,0,.739.739H311a.739.739,0,1,0,0-1.478h-4.435A.739.739,0,0,0,305.827,406.394Zm8.131-.739a.739.739,0,1,0,0,1.478h4.435a.739.739,0,1,0,0-1.478Zm7.392,0a.739.739,0,1,0,0,1.478h4.435a.739.739,0,0,0,0-1.478Zm-22.177,4.435h10.349a.739.739,0,1,0,0-1.478H299.174a.739.739,0,1,0,0,1.478ZM322.09,402.7a10.349,10.349,0,1,0-10.349-10.349A10.36,10.36,0,0,0,322.09,402.7Zm0-19.22a8.871,8.871,0,1,1-8.871,8.871A8.88,8.88,0,0,1,322.09,383.479Zm8.131,17.741a.738.738,0,0,0-.739.739v8.871a2.22,2.22,0,0,1-2.218,2.218H297.7a2.221,2.221,0,0,1-2.218-2.218V393.088a2.22,2.22,0,0,1,2.218-2.218h11.827a.739.739,0,1,0,0-1.478H297.7a3.7,3.7,0,0,0-3.7,3.7v17.741a3.7,3.7,0,0,0,3.7,3.7h29.569a3.7,3.7,0,0,0,3.7-3.7v-8.871A.738.738,0,0,0,330.222,401.22Z"
        transform="translate(-294 -382)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco384;
