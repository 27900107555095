/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\medal.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Medal = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-medal', className].join(' ');
  return (
    <svg
      id="medal"
      viewBox="0 0 28 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path d="M7 18.5H21V41.5L14 36.5L7 41.5V18.5Z" fill="#F6B40E" />
      <path d="M8.5 22H19.5V38L14 34.5217L8.5 38V22Z" fill="#1B2022" />
      <circle cx="14" cy="14.5" r="14" fill="#F6B40E" />
      <circle cx="14" cy="14.5" r="11.5" fill="#FFCF57" stroke="#CA4B03" />
      <path
        d="M14 7.5L12.058 11.827L7.34261 12.3369L10.8577 15.521L9.8855 20.1631L14 17.804L18.1145 20.1631L17.1423 15.521L20.6574 12.3369L15.942 11.827L14 7.5Z"
        fill="#C70404"
      />
    </svg>
  );
};
export default Medal;
