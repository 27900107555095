/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\ticket.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Ticket = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-ticket', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <mask id="b" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
          <path fill="currentColor" d="M0 0h16v16H0z" />
        </mask>
        <g mask="url(#b)">
          <path
            d="M2.666 13.334c-.366 0-.68-.13-.941-.392A1.284 1.284 0 0 1 1.333 12V9.334c.367 0 .68-.131.942-.392.26-.261.391-.575.391-.942 0-.366-.13-.68-.391-.942a1.286 1.286 0 0 0-.942-.391V4c0-.366.13-.68.392-.941.26-.261.575-.392.941-.392h10.667c.367 0 .68.13.942.392.261.26.392.575.392.941v2.667c-.367 0-.681.13-.942.391-.261.262-.392.576-.392.942 0 .367.13.68.392.942.26.261.575.392.942.392V12c0 .367-.13.681-.392.942-.261.261-.575.392-.942.392H2.666Zm0-1.334h10.667v-1.7a2.73 2.73 0 0 1-.974-.974A2.563 2.563 0 0 1 11.999 8c0-.477.12-.92.36-1.325a2.73 2.73 0 0 1 .974-.975V4H2.666v1.7c.411.245.737.57.976.975C3.88 7.081 4 7.523 4 8c0 .478-.12.92-.358 1.326-.24.405-.565.73-.976.974V12ZM8 11.334a.646.646 0 0 0 .475-.192.645.645 0 0 0 .191-.475.647.647 0 0 0-.19-.475A.647.647 0 0 0 8 10a.644.644 0 0 0-.475.192.645.645 0 0 0-.192.475c0 .189.064.347.192.475a.643.643 0 0 0 .475.192Zm0-2.667a.646.646 0 0 0 .475-.192A.645.645 0 0 0 8.666 8a.647.647 0 0 0-.19-.475A.647.647 0 0 0 8 7.334a.645.645 0 0 0-.475.191.646.646 0 0 0-.192.475c0 .19.064.347.192.475A.643.643 0 0 0 8 8.667ZM8 6a.646.646 0 0 0 .475-.192.645.645 0 0 0 .191-.474.647.647 0 0 0-.19-.476A.647.647 0 0 0 8 4.667a.645.645 0 0 0-.475.191.646.646 0 0 0-.192.476c0 .189.064.347.192.474A.643.643 0 0 0 8 6Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Ticket;
