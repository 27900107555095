/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-75.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina75 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-75', className].join(' ');
  return (
    <svg
      id="disciplina_nuoto-in-acque-libere"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M14.77 3a2.597 2.597 0 1 1 0 5.194 2.597 2.597 0 0 1 0-5.194Zm-9.333 9.865c.213-.039.82-.024 1.254.095.917.241.94.803 1.447.95.595.117.595.117 1.157-.161.693-.344.997-.515 2.06-1.164l1.465-1.18 3.802.609c.3.048.608-.015.866-.177l4.98-3.133a1.253 1.253 0 0 0-1.336-2.12l-4.58 2.882-3.445-.55-1.956-2.432a1.756 1.756 0 0 0-2.398-.32l1.537.777-5.049-1.204a1.256 1.256 0 0 0-1.139.317L.412 9.586a1.253 1.253 0 0 0 1.734 1.81l3.13-2.999 4.652 1.041-4.15.145-2.815 2.697c.667.221.756.3 1.269.81m.398 2.581a1.483 1.483 0 0 1 2.1 0 3.09 3.09 0 0 0 4.37 0 1.484 1.484 0 0 1 2.1 0 3.093 3.093 0 0 0 4.366 0 1.484 1.484 0 0 1 2.1 0 3.093 3.093 0 0 0 4.37 0 .802.802 0 1 0-1.131-1.133 1.484 1.484 0 0 1-2.1 0 3.093 3.093 0 0 0-4.37 0 1.486 1.486 0 0 1-2.099 0 3.09 3.09 0 0 0-4.37 0 1.485 1.485 0 0 1-2.099 0 3.093 3.093 0 0 0-4.37 0 1.484 1.484 0 0 1-2.099 0 .802.802 0 1 0-1.134 1.133 3.093 3.093 0 0 0 4.366 0Zm18.275 2.29a1.484 1.484 0 0 1-2.1 0 3.093 3.093 0 0 0-4.37 0 1.487 1.487 0 0 1-2.099 0 3.09 3.09 0 0 0-4.37 0 1.485 1.485 0 0 1-2.099 0 3.093 3.093 0 0 0-4.37 0 1.484 1.484 0 0 1-2.099 0 .803.803 0 0 0-1.134 1.134 3.093 3.093 0 0 0 4.366 0 1.485 1.485 0 0 1 2.1 0 3.09 3.09 0 0 0 4.37 0 1.484 1.484 0 0 1 2.099 0 3.093 3.093 0 0 0 4.37 0 1.484 1.484 0 0 1 2.1 0 3.093 3.093 0 0 0 4.369 0 .803.803 0 0 0-1.133-1.135Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina75;
