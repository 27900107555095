/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-11.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco11 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-11', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.44"
      height="38.44"
      viewBox="0 0 38.44 38.44"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0)">
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M76.743,148.916a.737.737,0,0,1-.523-.217l-15-15-15,15a.738.738,0,0,1-1.045,0l-2.957-2.957a.738.738,0,0,1,0-1.045l18.48-18.48a.738.738,0,0,1,1.045,0l6.87,6.87V129.7a.739.739,0,0,1,.739-.739h4.435a.739.739,0,0,1,.739.739V139l5.7,5.7a.738.738,0,0,1,0,1.045L77.266,148.7A.737.737,0,0,1,76.743,148.916Zm-15.524-17a.737.737,0,0,1,.523.217l15,15,1.912-1.912-5.391-5.391a.737.737,0,0,1-.217-.523v-8.871H70.09v4.435a.739.739,0,0,1-1.262.523l-7.609-7.609L43.784,145.22l1.912,1.912,15-15A.737.737,0,0,1,61.22,131.914Z"
            transform="translate(-42 -126)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M76.09,154v15.524a.74.74,0,0,1-.739.739H68.7V156.218a.74.74,0,0,0-.739-.739H57.61a.74.74,0,0,0-.739.739v14.045H50.218a.74.74,0,0,1-.739-.739V154H48v15.524a2.22,2.22,0,0,0,2.218,2.218H75.351a2.22,2.22,0,0,0,2.218-2.218V154Zm-17.741,2.957H67.22v13.306H58.349Z"
          transform="translate(-43.565 -133.302)"
          fill="currentColor"
        />
        <g transform="translate(13.306 12.567)">
          <path
            className="a"
            d="M71.088,149.653H60.739a.739.739,0,0,1-.523-1.262l5.175-5.175a.738.738,0,0,1,1.045,0l5.175,5.175a.739.739,0,0,1-.523,1.262Zm-8.565-1.478H69.3l-3.39-3.39Z"
            transform="translate(-60 -143)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco11;
