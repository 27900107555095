/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-385.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco385 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-385', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="32.526"
      viewBox="0 0 38.439 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M467.914,402.7h4.435a1.48,1.48,0,0,0,1.478-1.478v-4.435a1.481,1.481,0,0,0-1.478-1.478h-4.435a1.48,1.48,0,0,0-1.478,1.478v4.435A1.48,1.48,0,0,0,467.914,402.7Zm0-5.914h4.435v4.435h-4.437Zm-.739,10.349h4.435a.739.739,0,1,0,0-1.478h-4.435a.739.739,0,1,0,0,1.478Zm6.653-.739a.738.738,0,0,0,.739.739H479a.739.739,0,0,0,0-1.478h-4.435A.739.739,0,0,0,473.827,406.394Zm8.131-.739a.739.739,0,0,0,0,1.478h4.435a.739.739,0,1,0,0-1.478Zm7.392,0a.739.739,0,1,0,0,1.478h4.435a.739.739,0,0,0,0-1.478Zm-22.177,4.435h10.349a.739.739,0,1,0,0-1.478H467.175a.739.739,0,1,0,0,1.478ZM490.09,402.7a10.349,10.349,0,1,0-10.349-10.349A10.36,10.36,0,0,0,490.09,402.7Zm0-19.22a8.871,8.871,0,1,1-8.871,8.871A8.88,8.88,0,0,1,490.09,383.479Zm8.131,17.741a.739.739,0,0,0-.739.739v8.871a2.22,2.22,0,0,1-2.218,2.218H465.7a2.22,2.22,0,0,1-2.218-2.218V393.088a2.22,2.22,0,0,1,2.218-2.218h11.828a.739.739,0,1,0,0-1.478H465.7a3.7,3.7,0,0,0-3.7,3.7v17.741a3.7,3.7,0,0,0,3.7,3.7h29.569a3.7,3.7,0,0,0,3.7-3.7v-8.871A.738.738,0,0,0,498.222,401.22Zm-12.567-8.131h3.7v3.7a.739.739,0,1,0,1.478,0v-3.7h3.7a.739.739,0,1,0,0-1.478h-3.7v-3.7a.739.739,0,1,0-1.478,0v3.7h-3.7a.739.739,0,0,0,0,1.478Z"
        transform="translate(-462 -382)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco385;
