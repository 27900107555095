/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-271.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco271 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-271', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(7.392 7.392)">
        <path
          className="a"
          d="M59.392,402.784a7.392,7.392,0,1,1,7.392-7.392A7.4,7.4,0,0,1,59.392,402.784Zm0-13.306a5.914,5.914,0,1,0,5.914,5.914A5.921,5.921,0,0,0,59.392,389.478Z"
          transform="translate(-52 -388)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(13.719 2.989)">
        <ellipse className="a" cx="2" cy="1.5" rx="2" ry="1.5" transform="translate(0.107 0.001)" fill="currentColor" />
      </g>
      <g transform="translate(10.349 10.349)">
        <path
          className="a"
          d="M60.435,400.871a4.435,4.435,0,1,1,4.435-4.435A4.44,4.44,0,0,1,60.435,400.871Zm0-7.392a2.957,2.957,0,1,0,2.957,2.957A2.96,2.96,0,0,0,60.435,393.478Z"
          transform="translate(-56 -392)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M57.088,421.553h1.478a2.22,2.22,0,0,0,2.218-2.218v-3.149H59.306v3.149a.74.74,0,0,1-.739.739H57.088a.74.74,0,0,1-.739-.739v-3.149H54.871v3.149A2.22,2.22,0,0,0,57.088,421.553ZM67.42,424.51H48.236a.742.742,0,0,1-.571-.263.782.782,0,0,1-.177-.63,10.512,10.512,0,0,1,5.443-7.608.739.739,0,1,0-.694-1.305,12,12,0,0,0-6.209,8.685,2.257,2.257,0,0,0,.514,1.819,2.229,2.229,0,0,0,1.694.781H67.42a.739.739,0,1,0,0-1.478Z"
        transform="translate(-43.043 -387.549)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M82.957,415.478a1.478,1.478,0,1,0-2.218,1.273v.945a.739.739,0,1,0,1.478,0v-.945A1.474,1.474,0,0,0,82.957,415.478Z"
        transform="translate(-51.91 -387.388)"
        fill="currentColor"
      />
      <g transform="translate(20.698 22.177)">
        <path
          className="a"
          d="M84.784,421.306H72.957A2.96,2.96,0,0,1,70,418.349v-7.392A2.96,2.96,0,0,1,72.957,408H84.784a2.96,2.96,0,0,1,2.957,2.957v7.392A2.96,2.96,0,0,1,84.784,421.306ZM72.957,409.478a1.48,1.48,0,0,0-1.478,1.478v7.392a1.48,1.48,0,0,0,1.478,1.478H84.784a1.48,1.48,0,0,0,1.478-1.478v-7.392a1.48,1.48,0,0,0-1.478-1.478Z"
          transform="translate(-70 -408)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(23.655 13.306)">
        <path
          className="a"
          d="M85.088,406.349a.739.739,0,0,1-.739-.739v-3.7a4.435,4.435,0,1,0-8.871,0v3.7a.739.739,0,1,1-1.478,0v-3.7a5.914,5.914,0,1,1,11.827,0v3.7A.739.739,0,0,1,85.088,406.349Z"
          transform="translate(-74 -396)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M56.784,407.569A14.784,14.784,0,1,1,71.062,389a.739.739,0,1,1-1.429.379,13.31,13.31,0,1,0-9.348,16.237.74.74,0,0,1,.39,1.427A14.758,14.758,0,0,1,56.784,407.569Z"
          transform="translate(-42 -378)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco271;
