/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-36.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco36 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-36', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.569"
      height="38.439"
      viewBox="0 0 29.569 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <g transform="translate(0 0)">
          <g transform="translate(8.131 2.966)">
            <g transform="translate(0 31.038)">
              <path
                className="a"
                d="M230.7,173.478h-2.957a.739.739,0,0,1,0-1.478H230.7a.739.739,0,1,1,0,1.478Z"
                transform="translate(-227 -172)"
                fill="currentColor"
              />
            </g>
            <g transform="translate(1.478)">
              <path
                className="a"
                d="M229.739,131.482a.732.732,0,1,1,.525-.214A.777.777,0,0,1,229.739,131.482Z"
                transform="translate(-229 -130.012)"
                fill="currentColor"
              />
            </g>
          </g>
          <path
            className="a"
            d="M233.741,164.439H218.957A2.96,2.96,0,0,1,216,161.482V128.957A2.96,2.96,0,0,1,218.957,126h14.784a2.96,2.96,0,0,1,2.957,2.957v3.7a.739.739,0,1,1-1.478,0v-3.7a1.48,1.48,0,0,0-1.478-1.478H218.957a1.48,1.48,0,0,0-1.478,1.478v32.526a1.48,1.48,0,0,0,1.478,1.478h14.784a1.48,1.48,0,0,0,1.478-1.478v-9.61a.739.739,0,0,1,1.478,0v9.61A2.96,2.96,0,0,1,233.741,164.439Z"
            transform="translate(-216 -126)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(4.435 5.914)">
          <path
            className="a"
            d="M227.174,156.177a.739.739,0,0,1-.739-.739v-3.7h-1.478A2.96,2.96,0,0,1,222,148.784V136.957A2.96,2.96,0,0,1,224.957,134h19.22a2.96,2.96,0,0,1,2.957,2.957v11.827a2.96,2.96,0,0,1-2.957,2.957H231.916L227.7,155.96A.739.739,0,0,1,227.174,156.177Zm-2.218-20.7a1.48,1.48,0,0,0-1.478,1.478v11.827a1.48,1.48,0,0,0,1.478,1.478h2.218a.739.739,0,0,1,.739.739v2.651l3.173-3.173a.74.74,0,0,1,.523-.217h12.567a1.48,1.48,0,0,0,1.478-1.478V136.957a1.48,1.48,0,0,0-1.478-1.478Z"
            transform="translate(-222 -134)"
            fill="currentColor"
          />
          <path
            className="a"
            d="M243.524,142H228.739a.739.739,0,1,0,0,1.478h14.784a.739.739,0,0,0,0-1.478Zm-2.957,4.435H228.739a.739.739,0,0,0,0,1.478h11.827a.739.739,0,0,0,0-1.478Z"
            transform="translate(-223.565 -136.086)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco36;
