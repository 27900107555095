/************************************************************************************************************
    DO NOT ADD COMPONENTS IN THIS FILE
    ADD YOUR .SVG IN \common\tasks\image-lib\src FOLDER
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

//* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-unused-vars */
export enum IconsEnum {
  ACCOUNT = 'account',
  ACME = 'acme',
  ADM_COLORFUL_HORIZONTAL = 'adm-colorful-horizontal',
  ADM_WHITE_18 = 'adm-white-18',
  ADM_WHITE_HORIZONTAL = 'adm-white-horizontal',
  ALERT = 'alert',
  ARCHIVE = 'archive',
  ARROW = 'arrow',
  ARROW_BACK = 'arrow-back',
  ARROW_DECREASE = 'arrow-decrease',
  ARROW_DECREASE_LIGHT = 'arrow-decrease-light',
  ARROW_INCREASE = 'arrow-increase',
  ARROW_INCREASE_LIGHT = 'arrow-increase-light',
  ARROW_UP = 'arrow-up',
  AUTOESCLUSION_24H = 'autoesclusion-24h',
  AVATAR = 'avatar',
  AWESOME_USER = 'awesome-user',
  BONUS = 'bonus',
  BONUS_GREY = 'bonus-grey',
  CALCULATOR = 'calculator',
  CALCULATOR_MD = 'calculator-md',
  CALENDAR = 'calendar',
  CANCEL = 'cancel',
  CANCEL_FULL = 'cancel-full',
  CASH_MULTIPLE = 'cash-multiple',
  CAVALLO = 'cavallo',
  CAVALLO_PARAOCCHI = 'cavallo-paraocchi',
  CHART = 'chart',
  CHAT = 'chat',
  CHECK = 'check',
  CHECK_GREEN = 'check-green',
  CHECKMARK = 'checkmark',
  CHEWRON_DOWN = 'chewron-down',
  CHEWRON_LEFT = 'chewron-left',
  CHEWRON_RIGHT = 'chewron-right',
  CHEWRON_UP = 'chewron-up',
  CIRCLE_WARNING = 'circle-warning',
  CLOSE = 'close',
  CLOSE_CIRCLE = 'close-circle',
  COMMENT = 'comment',
  CONTRACT_PDF = 'contract-pdf',
  CONTROLLER_OFF = 'controller-off',
  CUORI = 'cuori',
  CUP = 'cup',
  DARK_MODE = 'dark-mode',
  DASHBOARD = 'dashboard',
  DASHBOARD_UPDATED = 'dashboard-updated',
  DEPOSIT = 'deposit',
  DICES = 'dices',
  DISCIPLINA_00 = 'disciplina-00',
  DISCIPLINA_001 = 'disciplina-001',
  DISCIPLINA_003 = 'disciplina-003',
  DISCIPLINA_01 = 'disciplina-01',
  DISCIPLINA_02 = 'disciplina-02',
  DISCIPLINA_03 = 'disciplina-03',
  DISCIPLINA_1 = 'disciplina-1',
  DISCIPLINA_10 = 'disciplina-10',
  DISCIPLINA_100 = 'disciplina-100',
  DISCIPLINA_1001 = 'disciplina-1001',
  DISCIPLINA_1003 = 'disciplina-1003',
  DISCIPLINA_1004 = 'disciplina-1004',
  DISCIPLINA_1007 = 'disciplina-1007',
  DISCIPLINA_101 = 'disciplina-101',
  DISCIPLINA_1013 = 'disciplina-1013',
  DISCIPLINA_102 = 'disciplina-102',
  DISCIPLINA_1026 = 'disciplina-1026',
  DISCIPLINA_1027 = 'disciplina-1027',
  DISCIPLINA_1028 = 'disciplina-1028',
  DISCIPLINA_103 = 'disciplina-103',
  DISCIPLINA_104 = 'disciplina-104',
  DISCIPLINA_105 = 'disciplina-105',
  DISCIPLINA_106 = 'disciplina-106',
  DISCIPLINA_107 = 'disciplina-107',
  DISCIPLINA_108 = 'disciplina-108',
  DISCIPLINA_109 = 'disciplina-109',
  DISCIPLINA_11 = 'disciplina-11',
  DISCIPLINA_110 = 'disciplina-110',
  DISCIPLINA_112 = 'disciplina-112',
  DISCIPLINA_114 = 'disciplina-114',
  DISCIPLINA_115 = 'disciplina-115',
  DISCIPLINA_116 = 'disciplina-116',
  DISCIPLINA_117 = 'disciplina-117',
  DISCIPLINA_118 = 'disciplina-118',
  DISCIPLINA_119 = 'disciplina-119',
  DISCIPLINA_12 = 'disciplina-12',
  DISCIPLINA_120 = 'disciplina-120',
  DISCIPLINA_121 = 'disciplina-121',
  DISCIPLINA_123 = 'disciplina-123',
  DISCIPLINA_124 = 'disciplina-124',
  DISCIPLINA_125 = 'disciplina-125',
  DISCIPLINA_126 = 'disciplina-126',
  DISCIPLINA_127 = 'disciplina-127',
  DISCIPLINA_128 = 'disciplina-128',
  DISCIPLINA_129 = 'disciplina-129',
  DISCIPLINA_13 = 'disciplina-13',
  DISCIPLINA_130 = 'disciplina-130',
  DISCIPLINA_131 = 'disciplina-131',
  DISCIPLINA_132 = 'disciplina-132',
  DISCIPLINA_134 = 'disciplina-134',
  DISCIPLINA_135 = 'disciplina-135',
  DISCIPLINA_136 = 'disciplina-136',
  DISCIPLINA_138 = 'disciplina-138',
  DISCIPLINA_14 = 'disciplina-14',
  DISCIPLINA_140 = 'disciplina-140',
  DISCIPLINA_142 = 'disciplina-142',
  DISCIPLINA_143 = 'disciplina-143',
  DISCIPLINA_144 = 'disciplina-144',
  DISCIPLINA_147 = 'disciplina-147',
  DISCIPLINA_148 = 'disciplina-148',
  DISCIPLINA_15 = 'disciplina-15',
  DISCIPLINA_150 = 'disciplina-150',
  DISCIPLINA_152 = 'disciplina-152',
  DISCIPLINA_154 = 'disciplina-154',
  DISCIPLINA_155 = 'disciplina-155',
  DISCIPLINA_156 = 'disciplina-156',
  DISCIPLINA_157 = 'disciplina-157',
  DISCIPLINA_158 = 'disciplina-158',
  DISCIPLINA_159 = 'disciplina-159',
  DISCIPLINA_16 = 'disciplina-16',
  DISCIPLINA_17 = 'disciplina-17',
  DISCIPLINA_18 = 'disciplina-18',
  DISCIPLINA_19 = 'disciplina-19',
  DISCIPLINA_2 = 'disciplina-2',
  DISCIPLINA_20 = 'disciplina-20',
  DISCIPLINA_21 = 'disciplina-21',
  DISCIPLINA_22 = 'disciplina-22',
  DISCIPLINA_23 = 'disciplina-23',
  DISCIPLINA_24 = 'disciplina-24',
  DISCIPLINA_27 = 'disciplina-27',
  DISCIPLINA_28 = 'disciplina-28',
  DISCIPLINA_29 = 'disciplina-29',
  DISCIPLINA_3 = 'disciplina-3',
  DISCIPLINA_30 = 'disciplina-30',
  DISCIPLINA_31 = 'disciplina-31',
  DISCIPLINA_32 = 'disciplina-32',
  DISCIPLINA_33 = 'disciplina-33',
  DISCIPLINA_34 = 'disciplina-34',
  DISCIPLINA_35 = 'disciplina-35',
  DISCIPLINA_36 = 'disciplina-36',
  DISCIPLINA_37 = 'disciplina-37',
  DISCIPLINA_38 = 'disciplina-38',
  DISCIPLINA_39 = 'disciplina-39',
  DISCIPLINA_40 = 'disciplina-40',
  DISCIPLINA_41 = 'disciplina-41',
  DISCIPLINA_42 = 'disciplina-42',
  DISCIPLINA_43 = 'disciplina-43',
  DISCIPLINA_44 = 'disciplina-44',
  DISCIPLINA_45 = 'disciplina-45',
  DISCIPLINA_46 = 'disciplina-46',
  DISCIPLINA_47 = 'disciplina-47',
  DISCIPLINA_48 = 'disciplina-48',
  DISCIPLINA_49 = 'disciplina-49',
  DISCIPLINA_5 = 'disciplina-5',
  DISCIPLINA_50 = 'disciplina-50',
  DISCIPLINA_51 = 'disciplina-51',
  DISCIPLINA_52 = 'disciplina-52',
  DISCIPLINA_53 = 'disciplina-53',
  DISCIPLINA_54 = 'disciplina-54',
  DISCIPLINA_55 = 'disciplina-55',
  DISCIPLINA_56 = 'disciplina-56',
  DISCIPLINA_57 = 'disciplina-57',
  DISCIPLINA_58 = 'disciplina-58',
  DISCIPLINA_59 = 'disciplina-59',
  DISCIPLINA_6 = 'disciplina-6',
  DISCIPLINA_60 = 'disciplina-60',
  DISCIPLINA_61 = 'disciplina-61',
  DISCIPLINA_62 = 'disciplina-62',
  DISCIPLINA_64 = 'disciplina-64',
  DISCIPLINA_65 = 'disciplina-65',
  DISCIPLINA_66 = 'disciplina-66',
  DISCIPLINA_67 = 'disciplina-67',
  DISCIPLINA_68 = 'disciplina-68',
  DISCIPLINA_69 = 'disciplina-69',
  DISCIPLINA_7 = 'disciplina-7',
  DISCIPLINA_70 = 'disciplina-70',
  DISCIPLINA_71 = 'disciplina-71',
  DISCIPLINA_73 = 'disciplina-73',
  DISCIPLINA_75 = 'disciplina-75',
  DISCIPLINA_76 = 'disciplina-76',
  DISCIPLINA_77 = 'disciplina-77',
  DISCIPLINA_78 = 'disciplina-78',
  DISCIPLINA_79 = 'disciplina-79',
  DISCIPLINA_8 = 'disciplina-8',
  DISCIPLINA_80 = 'disciplina-80',
  DISCIPLINA_81 = 'disciplina-81',
  DISCIPLINA_82 = 'disciplina-82',
  DISCIPLINA_83 = 'disciplina-83',
  DISCIPLINA_85 = 'disciplina-85',
  DISCIPLINA_86 = 'disciplina-86',
  DISCIPLINA_87 = 'disciplina-87',
  DISCIPLINA_88 = 'disciplina-88',
  DISCIPLINA_89 = 'disciplina-89',
  DISCIPLINA_9 = 'disciplina-9',
  DISCIPLINA_90 = 'disciplina-90',
  DISCIPLINA_91 = 'disciplina-91',
  DISCIPLINA_92 = 'disciplina-92',
  DISCIPLINA_93 = 'disciplina-93',
  DISCIPLINA_94 = 'disciplina-94',
  DISCIPLINA_95 = 'disciplina-95',
  DISCIPLINA_96 = 'disciplina-96',
  DISCIPLINA_97 = 'disciplina-97',
  DISCIPLINA_98 = 'disciplina-98',
  DISCIPLINA_99 = 'disciplina-99',
  DISCIPLINA_ESPORTS_NBA2K_LEAGUE = 'disciplina-esports-nba2k-league',
  DISCIPLINA_ESPORTS_FORMULA_E = 'disciplina-esports-formula-e',
  DISCIPLINA_ESPORTS_MOTOCICLISMO = 'disciplina-esports-motociclismo',
  DISCIPLINA_ESPORTS_STARCRAFT_2 = 'disciplina-esports-starcraft-2',
  DISCIPLINA_ESPORTS_WORLD_OF_WARCRAFT = 'disciplina-esports-world-of-warcraft',
  DISCIPLINA_FALLBACK = 'disciplina-fallback',
  DISCIPLINA_HOCKEY_INLIINE = 'disciplina-hockey-inliine',
  DISCIPLINA_MANIFESTAZIONI_SPETTACOLO = 'disciplina-manifestazioni-spettacolo',
  DISCIPLINA_PATTINAGGIO_FIGURATO = 'disciplina-pattinaggio-figurato',
  DISCIPLINA_SPORT_GAELICI = 'disciplina-sport-gaelici',
  DISCIPLINA_TIRO_CON_VOLO = 'disciplina-tiro-con-volo',
  DOUBLE_CHEVRON_RIGHT = 'double-chevron-right',
  DOWNLOAD = 'download',
  DOWNLOAD_APPLE = 'download-apple',
  DOWNLOAD_VIRTUAL = 'download-virtual',
  DUPLICATE = 'duplicate',
  ECLAMATION_MARK_OUTLINE = 'eclamation-mark-outline',
  EXCLAMATION_CIRCLE = 'exclamation-circle',
  EXIT = 'exit',
  EYE = 'eye',
  EYE_OFF = 'eye-off',
  FERRI_DAVANTI = 'ferri-davanti',
  FERRI_DIETRO = 'ferri-dietro',
  FERRI_FULL = 'ferri-full',
  FERRI_NONE = 'ferri-none',
  FILTER = 'filter',
  FILTER_VERTICAL = 'filter-vertical',
  FIORI = 'fiori',
  FORM = 'form',
  FULL_PIN = 'full-pin',
  FULL_PINNED = 'full-pinned',
  GAME_AUTOEXCLUSION = 'game-autoexclusion',
  GAME_SLASH = 'game-slash',
  GEAR = 'gear',
  GIFT = 'gift',
  GOTO_ARROW = 'goto-arrow',
  HAPPYBET_LOGO = 'happybet-logo',
  HEART_OUTLINE = 'heart-outline',
  IMAGE = 'image',
  INFO = 'info',
  INFO_SOLID = 'info-solid',
  INFO_YELLOW = 'info-yellow',
  INSTANT_PANEL = 'instant-panel',
  ITALIA = 'italia',
  LAPTOP_MAC = 'laptop-mac',
  LEGENDA_ANNULLATA_DARK = 'legenda-annullata-dark',
  LEGENDA_ANNULLATA_LIGHT = 'legenda-annullata-light',
  LEGENDA_APERTA_DARK = 'legenda-aperta-dark',
  LEGENDA_APERTA_LIGHT = 'legenda-aperta-light',
  LEGENDA_CHIUSA_DARK = 'legenda-chiusa-dark',
  LEGENDA_CHIUSA_LIGHT = 'legenda-chiusa-light',
  LEGENDA_INATTESA_DARK = 'legenda-inattesa-dark',
  LEGENDA_INATTESA_LIGHT = 'legenda-inattesa-light',
  LEGENDA_INPAGAMENTO_DARK = 'legenda-inpagamento-dark',
  LEGENDA_INPAGAMENTO_LIGHT = 'legenda-inpagamento-light',
  LEGENDA_TOT_DARK = 'legenda-tot-dark',
  LEGENDA_TOT_LIGHT = 'legenda-tot-light',
  LEGENDA_TQQ_DARK = 'legenda-tqq-dark',
  LEGENDA_TQQ_LIGHT = 'legenda-tqq-light',
  LEGENDA_TRIS_DARK = 'legenda-tris-dark',
  LEGENDA_TRIS_LIGHT = 'legenda-tris-light',
  LIGHT_MODE = 'light-mode',
  LINK_EXTERNAL = 'link-external',
  LIST_MENU = 'list-menu',
  LIVE = 'live',
  LIVE_HB = 'live_hb',
  MATCH_LIVE = 'match-live',
  MEDAL = 'medal',
  MEDIA = 'media',
  MENU = 'menu',
  MESSAGE = 'message',
  MESSAGE_CENTER_ICO_101 = 'message-center-ico-101',
  MESSAGE_CENTER_ICO_102 = 'message-center-ico-102',
  MESSAGE_CENTER_ICO_105 = 'message-center-ico-105',
  MESSAGE_CENTER_ICO_109 = 'message-center-ico-109',
  MESSAGE_CENTER_ICO_11 = 'message-center-ico-11',
  MESSAGE_CENTER_ICO_12 = 'message-center-ico-12',
  MESSAGE_CENTER_ICO_120 = 'message-center-ico-120',
  MESSAGE_CENTER_ICO_129 = 'message-center-ico-129',
  MESSAGE_CENTER_ICO_134 = 'message-center-ico-134',
  MESSAGE_CENTER_ICO_140 = 'message-center-ico-140',
  MESSAGE_CENTER_ICO_141 = 'message-center-ico-141',
  MESSAGE_CENTER_ICO_146 = 'message-center-ico-146',
  MESSAGE_CENTER_ICO_147 = 'message-center-ico-147',
  MESSAGE_CENTER_ICO_151 = 'message-center-ico-151',
  MESSAGE_CENTER_ICO_152 = 'message-center-ico-152',
  MESSAGE_CENTER_ICO_158 = 'message-center-ico-158',
  MESSAGE_CENTER_ICO_159 = 'message-center-ico-159',
  MESSAGE_CENTER_ICO_163 = 'message-center-ico-163',
  MESSAGE_CENTER_ICO_167 = 'message-center-ico-167',
  MESSAGE_CENTER_ICO_17 = 'message-center-ico-17',
  MESSAGE_CENTER_ICO_173 = 'message-center-ico-173',
  MESSAGE_CENTER_ICO_180 = 'message-center-ico-180',
  MESSAGE_CENTER_ICO_186 = 'message-center-ico-186',
  MESSAGE_CENTER_ICO_188 = 'message-center-ico-188',
  MESSAGE_CENTER_ICO_190 = 'message-center-ico-190',
  MESSAGE_CENTER_ICO_196 = 'message-center-ico-196',
  MESSAGE_CENTER_ICO_199 = 'message-center-ico-199',
  MESSAGE_CENTER_ICO_201 = 'message-center-ico-201',
  MESSAGE_CENTER_ICO_205 = 'message-center-ico-205',
  MESSAGE_CENTER_ICO_208 = 'message-center-ico-208',
  MESSAGE_CENTER_ICO_21 = 'message-center-ico-21',
  MESSAGE_CENTER_ICO_210 = 'message-center-ico-210',
  MESSAGE_CENTER_ICO_215 = 'message-center-ico-215',
  MESSAGE_CENTER_ICO_217 = 'message-center-ico-217',
  MESSAGE_CENTER_ICO_222 = 'message-center-ico-222',
  MESSAGE_CENTER_ICO_225 = 'message-center-ico-225',
  MESSAGE_CENTER_ICO_230 = 'message-center-ico-230',
  MESSAGE_CENTER_ICO_235 = 'message-center-ico-235',
  MESSAGE_CENTER_ICO_238 = 'message-center-ico-238',
  MESSAGE_CENTER_ICO_241 = 'message-center-ico-241',
  MESSAGE_CENTER_ICO_244 = 'message-center-ico-244',
  MESSAGE_CENTER_ICO_245 = 'message-center-ico-245',
  MESSAGE_CENTER_ICO_248 = 'message-center-ico-248',
  MESSAGE_CENTER_ICO_25 = 'message-center-ico-25',
  MESSAGE_CENTER_ICO_252 = 'message-center-ico-252',
  MESSAGE_CENTER_ICO_254 = 'message-center-ico-254',
  MESSAGE_CENTER_ICO_259 = 'message-center-ico-259',
  MESSAGE_CENTER_ICO_264 = 'message-center-ico-264',
  MESSAGE_CENTER_ICO_271 = 'message-center-ico-271',
  MESSAGE_CENTER_ICO_276 = 'message-center-ico-276',
  MESSAGE_CENTER_ICO_28 = 'message-center-ico-28',
  MESSAGE_CENTER_ICO_283 = 'message-center-ico-283',
  MESSAGE_CENTER_ICO_288 = 'message-center-ico-288',
  MESSAGE_CENTER_ICO_291 = 'message-center-ico-291',
  MESSAGE_CENTER_ICO_294 = 'message-center-ico-294',
  MESSAGE_CENTER_ICO_298 = 'message-center-ico-298',
  MESSAGE_CENTER_ICO_301 = 'message-center-ico-301',
  MESSAGE_CENTER_ICO_303 = 'message-center-ico-303',
  MESSAGE_CENTER_ICO_308 = 'message-center-ico-308',
  MESSAGE_CENTER_ICO_309 = 'message-center-ico-309',
  MESSAGE_CENTER_ICO_313 = 'message-center-ico-313',
  MESSAGE_CENTER_ICO_314 = 'message-center-ico-314',
  MESSAGE_CENTER_ICO_317 = 'message-center-ico-317',
  MESSAGE_CENTER_ICO_319 = 'message-center-ico-319',
  MESSAGE_CENTER_ICO_329 = 'message-center-ico-329',
  MESSAGE_CENTER_ICO_36 = 'message-center-ico-36',
  MESSAGE_CENTER_ICO_362 = 'message-center-ico-362',
  MESSAGE_CENTER_ICO_363 = 'message-center-ico-363',
  MESSAGE_CENTER_ICO_364 = 'message-center-ico-364',
  MESSAGE_CENTER_ICO_365 = 'message-center-ico-365',
  MESSAGE_CENTER_ICO_366 = 'message-center-ico-366',
  MESSAGE_CENTER_ICO_367 = 'message-center-ico-367',
  MESSAGE_CENTER_ICO_368 = 'message-center-ico-368',
  MESSAGE_CENTER_ICO_369 = 'message-center-ico-369',
  MESSAGE_CENTER_ICO_370 = 'message-center-ico-370',
  MESSAGE_CENTER_ICO_371 = 'message-center-ico-371',
  MESSAGE_CENTER_ICO_372 = 'message-center-ico-372',
  MESSAGE_CENTER_ICO_373 = 'message-center-ico-373',
  MESSAGE_CENTER_ICO_374 = 'message-center-ico-374',
  MESSAGE_CENTER_ICO_375 = 'message-center-ico-375',
  MESSAGE_CENTER_ICO_376 = 'message-center-ico-376',
  MESSAGE_CENTER_ICO_377 = 'message-center-ico-377',
  MESSAGE_CENTER_ICO_378 = 'message-center-ico-378',
  MESSAGE_CENTER_ICO_379 = 'message-center-ico-379',
  MESSAGE_CENTER_ICO_380 = 'message-center-ico-380',
  MESSAGE_CENTER_ICO_381 = 'message-center-ico-381',
  MESSAGE_CENTER_ICO_382 = 'message-center-ico-382',
  MESSAGE_CENTER_ICO_383 = 'message-center-ico-383',
  MESSAGE_CENTER_ICO_384 = 'message-center-ico-384',
  MESSAGE_CENTER_ICO_385 = 'message-center-ico-385',
  MESSAGE_CENTER_ICO_386 = 'message-center-ico-386',
  MESSAGE_CENTER_ICO_387 = 'message-center-ico-387',
  MESSAGE_CENTER_ICO_388 = 'message-center-ico-388',
  MESSAGE_CENTER_ICO_389 = 'message-center-ico-389',
  MESSAGE_CENTER_ICO_390 = 'message-center-ico-390',
  MESSAGE_CENTER_ICO_391 = 'message-center-ico-391',
  MESSAGE_CENTER_ICO_392 = 'message-center-ico-392',
  MESSAGE_CENTER_ICO_393 = 'message-center-ico-393',
  MESSAGE_CENTER_ICO_394 = 'message-center-ico-394',
  MESSAGE_CENTER_ICO_395 = 'message-center-ico-395',
  MESSAGE_CENTER_ICO_396 = 'message-center-ico-396',
  MESSAGE_CENTER_ICO_397 = 'message-center-ico-397',
  MESSAGE_CENTER_ICO_398 = 'message-center-ico-398',
  MESSAGE_CENTER_ICO_399 = 'message-center-ico-399',
  MESSAGE_CENTER_ICO_40 = 'message-center-ico-40',
  MESSAGE_CENTER_ICO_400 = 'message-center-ico-400',
  MESSAGE_CENTER_ICO_401 = 'message-center-ico-401',
  MESSAGE_CENTER_ICO_402 = 'message-center-ico-402',
  MESSAGE_CENTER_ICO_403 = 'message-center-ico-403',
  MESSAGE_CENTER_ICO_404 = 'message-center-ico-404',
  MESSAGE_CENTER_ICO_405 = 'message-center-ico-405',
  MESSAGE_CENTER_ICO_406 = 'message-center-ico-406',
  MESSAGE_CENTER_ICO_407 = 'message-center-ico-407',
  MESSAGE_CENTER_ICO_408 = 'message-center-ico-408',
  MESSAGE_CENTER_ICO_409 = 'message-center-ico-409',
  MESSAGE_CENTER_ICO_41 = 'message-center-ico-41',
  MESSAGE_CENTER_ICO_410 = 'message-center-ico-410',
  MESSAGE_CENTER_ICO_411 = 'message-center-ico-411',
  MESSAGE_CENTER_ICO_412 = 'message-center-ico-412',
  MESSAGE_CENTER_ICO_413 = 'message-center-ico-413',
  MESSAGE_CENTER_ICO_414 = 'message-center-ico-414',
  MESSAGE_CENTER_ICO_415 = 'message-center-ico-415',
  MESSAGE_CENTER_ICO_416 = 'message-center-ico-416',
  MESSAGE_CENTER_ICO_417 = 'message-center-ico-417',
  MESSAGE_CENTER_ICO_418 = 'message-center-ico-418',
  MESSAGE_CENTER_ICO_419 = 'message-center-ico-419',
  MESSAGE_CENTER_ICO_42 = 'message-center-ico-42',
  MESSAGE_CENTER_ICO_420 = 'message-center-ico-420',
  MESSAGE_CENTER_ICO_421 = 'message-center-ico-421',
  MESSAGE_CENTER_ICO_43 = 'message-center-ico-43',
  MESSAGE_CENTER_ICO_47 = 'message-center-ico-47',
  MESSAGE_CENTER_ICO_49 = 'message-center-ico-49',
  MESSAGE_CENTER_ICO_52 = 'message-center-ico-52',
  MESSAGE_CENTER_ICO_53 = 'message-center-ico-53',
  MESSAGE_CENTER_ICO_55 = 'message-center-ico-55',
  MESSAGE_CENTER_ICO_57 = 'message-center-ico-57',
  MESSAGE_CENTER_ICO_59 = 'message-center-ico-59',
  MESSAGE_CENTER_ICO_61 = 'message-center-ico-61',
  MESSAGE_CENTER_ICO_63 = 'message-center-ico-63',
  MESSAGE_CENTER_ICO_69 = 'message-center-ico-69',
  MESSAGE_CENTER_ICO_71 = 'message-center-ico-71',
  MESSAGE_CENTER_ICO_73 = 'message-center-ico-73',
  MESSAGE_CENTER_ICO_74 = 'message-center-ico-74',
  MESSAGE_CENTER_ICO_76 = 'message-center-ico-76',
  MESSAGE_CENTER_ICO_79 = 'message-center-ico-79',
  MESSAGE_CENTER_ICO_8 = 'message-center-ico-8',
  MESSAGE_CENTER_ICO_82 = 'message-center-ico-82',
  MESSAGE_CENTER_ICO_85 = 'message-center-ico-85',
  MESSAGE_CENTER_ICO_87 = 'message-center-ico-87',
  MESSAGE_CENTER_ICO_91 = 'message-center-ico-91',
  MESSAGE_CENTER_ICO_98 = 'message-center-ico-98',
  METEO_BELLO = 'meteo-bello',
  METEO_COPERTO = 'meteo-coperto',
  METEO_FOSCHIA = 'meteo-foschia',
  METEO_NEBBIA = 'meteo-nebbia',
  METEO_PIOGGIA = 'meteo-pioggia',
  METEO_VARIABILE = 'meteo-variabile',
  METEO_VENTO = 'meteo-vento',
  MINUS = 'minus',
  MINUS_CIRCLE = 'minus-circle',
  NAZIONE_AE = 'nazione-ae',
  NAZIONE_AG = 'nazione-ag',
  NAZIONE_AR = 'nazione-ar',
  NAZIONE_AT = 'nazione-at',
  NAZIONE_AU = 'nazione-au',
  NAZIONE_AZ = 'nazione-az',
  NAZIONE_BB = 'nazione-bb',
  NAZIONE_BE = 'nazione-be',
  NAZIONE_BH = 'nazione-bh',
  NAZIONE_BR = 'nazione-br',
  NAZIONE_CA = 'nazione-ca',
  NAZIONE_CL = 'nazione-cl',
  NAZIONE_CN = 'nazione-cn',
  NAZIONE_CO = 'nazione-co',
  NAZIONE_CY = 'nazione-cy',
  NAZIONE_DE = 'nazione-de',
  NAZIONE_DK = 'nazione-dk',
  NAZIONE_EE = 'nazione-ee',
  NAZIONE_ES = 'nazione-es',
  NAZIONE_FI = 'nazione-fi',
  NAZIONE_FR = 'nazione-fr',
  NAZIONE_GB = 'nazione-gb',
  NAZIONE_GR = 'nazione-gr',
  NAZIONE_HR = 'nazione-hr',
  NAZIONE_IE = 'nazione-ie',
  NAZIONE_IN = 'nazione-in',
  NAZIONE_IT = 'nazione-it',
  NAZIONE_JM = 'nazione-jm',
  NAZIONE_JP = 'nazione-jp',
  NAZIONE_KE = 'nazione-ke',
  NAZIONE_KR = 'nazione-kr',
  NAZIONE_LB = 'nazione-lb',
  NAZIONE_PH = 'nazione-ph',
  NAZIONE_SA = 'nazione-sa',
  NAZIONE_US = 'nazione-us',
  NEW_DARK = 'new-dark',
  NEW_LIGHT = 'new-light',
  PATTINAGGIO_VELOCITA = 'pattinaggio-velocita',
  PENCIL = 'pencil',
  PICCHE = 'picche',
  PIG = 'pig',
  PIN = 'pin',
  PINLOCATION = 'pinlocation',
  PLAY_STROKE = 'play-stroke',
  PLAYER = 'player',
  PLUS = 'plus',
  PLUS_CIRCLE = 'plus-circle',
  POINT_HAMBURGER = 'point-hamburger',
  PREFERRED_OFF = 'preferred-off',
  PREFERRED_OFF_BCK = 'preferred-off-bck',
  PREFERRED_ON = 'preferred-on',
  PRINTER = 'printer',
  PROGRAMMED = 'programmed',
  QUADRI = 'quadri',
  QUESTIONS = 'questions',
  RADIO = 'radio',
  REGNO_UNITO = 'regno-unito',
  RELOAD_SPIN = 'reload-spin',
  RIBBON = 'ribbon',
  ROUND_QUESTION = 'round-question',
  ROUND_QUESTION_32 = 'round-question-32',
  SEARCH = 'search',
  SEARCH_OFF = 'search-off',
  SHARE = 'share',
  SHIELD = 'shield',
  SLOTS = 'slots',
  SMART_TOY = 'smart-toy',
  SMARTPHONE = 'smartphone',
  SNAI_LOGO = 'snai-logo',
  SNAI_LOGO_SHORT = 'snai-logo-short',
  SNAI_PICTO = 'snai-picto',
  SNAI_RUNNER = 'snai-runner',
  SPID_LOGO = 'spid-logo',
  STADIA_CONTROLLER = 'stadia-controller',
  STAR = 'star',
  STAR_OUTLINE = 'star-outline',
  STATS = 'stats',
  STREAMING = 'streaming',
  SUCCESS = 'success',
  THREE_DOTS = 'three-dots',
  TICKET = 'ticket',
  TRASH_CAN = 'trash-can',
  TRIP = 'trip',
  TROPHY = 'trophy',
  TV = 'tv',
  USER = 'user',
  VECTOR = 'vector',
  VOUCHER_DOCUMENT = 'voucher-document',
  VOUCHER_LOGO = 'voucher-logo',
  VOUCHER_USER = 'voucher-user',
}

export type IconsType = `${IconsEnum}`;

/* eslint-enable no-unused-vars */
//* eslint-enable @typescript-eslint/camelcase */

const capitalize = (input: string): string => {
  const segments = (input ?? '').split('');
  if (Array.isArray(segments) && segments.length > 0) {
    segments[0] = segments[0].toUpperCase();
  }
  return segments.join('');
};

export const camelize = (input: string): string => {
  return (input ?? '')
    .split(/\.| |-|@/gim)
    .map((x) => capitalize(x))
    .join('');
};

export const toIconsEnum = (raw: string): IconsEnum => {
  const value = (raw ?? '').replace(/-/gim, '_').trim();
  const key = Object.keys(IconsEnum).find((x) => x.toLowerCase() === value.toLowerCase());
  if (key) {
    return Reflect.get(IconsEnum, key);
  }
  const fallback = (raw ?? '').replace(/_/gim, '-').trim().toLowerCase();
  return IconsEnum[fallback as keyof typeof IconsEnum];
};

export interface IconProps extends React.SVGProps<SVGSVGElement | HTMLImageElement> {
  iconId: string | IconsType;
}

// notes:
//  prefer using Icon when the image is statically defined and you are sure is one of the managed images
//  prefer IconLoader when image comes from parameters, need to be decoded or loading effect can be a plus
export { Dynamic as IconLoader } from 'components/Icons/ZZ-Dynamic-Loader.tsx';
export { Static as Icon } from 'components/Icons/ZZ-Static-Loader.tsx';
