import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const useIsSeamlessMode = () => {
  const [isSeamlessMode, setIsSeamlessMode] = useState<boolean>(false);
  const router = useRouter();
  useEffect(() => {
    const keyValuePairs = router.asPath.split('?')[1]?.split('&') ?? [];
    const seamless = keyValuePairs.find((keyValuePair) => keyValuePair.includes('seamless'));
    setIsSeamlessMode(seamless ? true : false);
  }, [router.asPath]);

  return isSeamlessMode;
};
