/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\cancel-full.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const CancelFull = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-cancel-full', className].join(' ');
  return (
    <svg
      id="cancel-full"
      width="100%"
      height="100%"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M7 .332a6.66 6.66 0 0 1 6.667 6.667A6.66 6.66 0 0 1 7 13.665 6.66 6.66 0 0 1 .333 7 6.66 6.66 0 0 1 7 .332Zm2.393 3.333L7 6.06 4.607 3.665l-.94.94L6.06 7 3.667 9.392l.94.94L7 7.939l2.393 2.393.94-.94L7.94 6.999l2.394-2.394-.94-.94Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default CancelFull;
