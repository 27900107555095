/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-esports-world-of-warcraft.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const DisciplinaEsportsWorldOfWarcraft = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-esports-world-of-warcraft', className].join(' ');
  return (
    <svg
      id="disciplina-esports-world-of-warcraft"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M2.852 21.116c0-.943.006-1.876-.006-2.809a.948.948 0 0 0-.127-.45c-2.292-3.906-2.292-7.811 0-11.715a.953.953 0 0 0 .128-.45c.012-.932.006-1.865.006-2.842h.312c.848 0 1.697.006 2.544-.005a.902.902 0 0 0 .424-.119c3.915-2.301 7.829-2.301 11.742 0a.886.886 0 0 0 .423.12c.94.011 1.882.006 2.857.006v1.296c0 .533-.091 1.088.026 1.595.115.498.451.945.673 1.421 1.694 3.652 1.51 7.208-.551 10.67a1.06 1.06 0 0 0-.143.501c-.013.924-.006 1.848-.006 2.814h-.312c-.848 0-1.696-.006-2.544.005a.893.893 0 0 0-.424.12c-3.915 2.302-7.829 2.302-11.742 0a.902.902 0 0 0-.423-.12c-.924-.011-1.847-.007-2.773-.01a.41.41 0 0 1-.084-.028Zm1.83-1.796c.55 0 1.056-.013 1.56.007.175.006.346.06.494.155 1.515 1.043 3.186 1.614 5.026 1.662 2.017.052 3.85-.518 5.51-1.662a.98.98 0 0 1 .495-.155c.51-.02 1.023-.008 1.558-.008 0-.533-.014-1.037.008-1.538.006-.185.061-.365.16-.521 2.225-3.263 2.222-7.283-.01-10.534a.976.976 0 0 1-.15-.495c-.02-.51-.008-1.022-.008-1.551-.51 0-.988-.011-1.46.004-.236.01-.469-.06-.66-.198-3.19-2.187-7.217-2.187-10.404 0a1 1 0 0 1-.63.197c-.491-.015-.983-.005-1.488-.005 0 .565.008 1.088-.005 1.611a.764.764 0 0 1-.12.391c-2.282 3.34-2.284 7.3-.008 10.627a.82.82 0 0 1 .126.418c.015.521.006 1.043.006 1.595Zm7.32-11.89-2.08 5.34c-.038-.118-.062-.178-.077-.24-.313-1.255-.62-2.512-.94-3.765A1.087 1.087 0 0 1 9 7.938c.086-.156.156-.319.242-.495H5.578c.243.228.454.38.602.58.172.228.357.482.419.752.55 2.392 1.074 4.79 1.609 7.187.093.417.011.77-.313 1.063-.148.133-.282.281-.448.45h3.625c-.092-.188-.157-.357-.252-.507-.14-.22-.132-.423-.04-.668.382-1.015.739-2.04 1.106-3.06.029-.081.062-.16.12-.306.451 1.25.876 2.421 1.289 3.596.029.083-.01.204-.049.293-.093.215-.206.422-.317.645h3.676c-.209-.189-.356-.323-.505-.455-.318-.282-.4-.63-.31-1.037.537-2.406 1.064-4.812 1.617-7.214.06-.262.239-.507.403-.73.152-.207.36-.372.578-.592h-3.625c.1.203.21.373.266.559.061.204.14.438.094.632-.32 1.361-.67 2.715-1.032 4.158L12.002 7.43Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default DisciplinaEsportsWorldOfWarcraft;
