import { AnyAction, Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { IppicaSignalRListeners, doReset } from 'features/ippica/ippicaSlice';
import { MAX_CONNECTION_ATTEMPTS, decodePathname, nextDelay } from 'features/sport';
import { setSignalRConnection, signalRs } from 'features/signalR/signalRSlice';

import { LocationEventHandler } from './types';
import { RootState } from 'lib/centralStore';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsight } from 'components/appInsight/AppInsight';
import { getUpdatedQuote } from 'features/ippica/components/ippicaTicket/ippicaTicketActions';
import { selectIsCurrentlyIppica } from 'features/ippica/selectors';
import { selectIsOnline } from 'features/location/selectors';
import { selectSignalRConnection } from '../selectors';

export interface IppicaEventHandler
  extends Omit<LocationEventHandler, 'onSubscribeHandler' | 'onUnsubscribeHandler' | 'onLocationEventHandler'> {
  onLocationEventHandler: (_path: string, _pending: boolean) => void;
}

export const ippicaConnectionHubManager = (store: MiddlewareAPI<Dispatch<AnyAction>, any>): IppicaEventHandler => {
  const key = signalRs.ippica;

  const registerListeners = (connection: HubConnection) => {
    (IppicaSignalRListeners ?? []).forEach(({ eventName, action, actionType: type }) => {
      connection.on(eventName, (a: boolean, b: string, payload: any) => {
        const state = store.getState() as RootState;
        const isIppica = selectIsCurrentlyIppica(state);

        if (!isIppica) return;

        if (action) {
          store.dispatch(action(payload) as unknown as AnyAction);
        } else {
          store.dispatch({ type, payload });
        }
      });
    });
  };

  const buildConnection = (): HubConnection => {
    // TODO: change the url with signalR for side menu (when available)
    const connection = new HubConnectionBuilder()
      .withUrl(`${process.env.NEXT_PUBLIC_API_BASE_URL}/signalr-ippica/sigr-hubs/stato-avvenimento`)
      .build();

    connection.onclose(() => {
      const state = store.getState() as RootState;
      const isIppica = selectIsCurrentlyIppica(state);

      if (isIppica) {
        appInsight.trackTrace({
          message: `[ippicaConnectionHubManager] signalR closed`,
          severityLevel: SeverityLevel.Information,
        });

        store.dispatch(setSignalRConnection({ key }));
        ensureConnected(undefined);
      }
    });

    connection.onreconnected(async () => {
      // just in case of restoring withAutomaticReconnect option
      appInsight.trackTrace({
        message: `[ippicaConnectionHubManager] signalR reconnected`,
        severityLevel: SeverityLevel.Information,
      });
    });

    registerListeners(connection);

    store.dispatch(setSignalRConnection({ key, connection }));

    return connection;
  };

  const ensureConnected = async (hub?: HubConnection): Promise<HubConnection> => {
    const connection = hub ?? buildConnection();

    let success = true;

    for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && connection.state !== HubConnectionState.Connected; x++) {
      success = false;
      if (connection.state === HubConnectionState.Disconnected) {
        try {
          await connection.start();
          success = true;
        } catch (e) {
          appInsight.trackTrace({
            message: `[ippicaConnectionHubManager] unable to Start signalR, state is ${connection.state} - attempt n. ${
              x + 1
            }`,
            severityLevel: SeverityLevel.Error,
          });
        }
      }
      await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
    }

    if (!success) {
      appInsight.trackTrace({
        message: `[ippicaConnectionHubManager] unable to Start signalR`,
        severityLevel: SeverityLevel.Critical,
      });
    }

    return Promise.resolve(connection);
  };

  const onLocationEventHandler = async (nextPath: string, pending: boolean): Promise<void> => {
    const state = store.getState() as RootState;

    const isIppica = selectIsCurrentlyIppica(state);
    const nextPathname = decodePathname(nextPath) ?? '';
    const nextIsIppica = (nextPathname.match(/^ippica/gim) ?? []).length > 0;

    const connectionSignalR = selectSignalRConnection(state)(key);

    if (pending) {
      if (nextIsIppica) {
        await ensureConnected(connectionSignalR);
        store.dispatch(getUpdatedQuote() as unknown as AnyAction);
      }
    } else if (isIppica && !nextIsIppica) {
      if (connectionSignalR?.state === HubConnectionState.Connected) {
        connectionSignalR.stop();
      }
      store.dispatch(setSignalRConnection({ key }));
      store.dispatch(doReset());
    }

    return Promise.resolve();
  };

  const onIsOnlineEventHandler = async (isOnline: boolean): Promise<void> => {
    const state = store.getState() as RootState;
    const prevOnline = selectIsOnline(state);
    const isIppica = selectIsCurrentlyIppica(state);

    if (isIppica === true && isOnline === true && prevOnline === false) {
      const connectionSignalR = selectSignalRConnection(state)(key);
      await ensureConnected(connectionSignalR);
    }

    return Promise.resolve();
  };

  return {
    onLocationEventHandler,
    onIsOnlineEventHandler,
  };
};
