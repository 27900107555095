/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-73.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina73 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-73', className].join(' ');
  return (
    <svg
      id="disciplina_polo"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="m19.36 2.218-.496.496-5.17 5.17-1.02-1.02 1.24-1.24.002.003 3.93-3.93.079-.074.432-.407a.708.708 0 1 1 1.003 1.002Zm-9.044 7.004-4.164 4.164.003.002-2.222 2.222-1.99-1.991a.574.574 0 0 0-.81 0l-.966.964a.573.573 0 0 0 0 .809l4.25 4.254c.015.014.03.027.047.039l-.003.005 2.14 2.196a.565.565 0 0 0 1.01-.497s.211-.554-.374-2.11c-.309-.823-1.5-1.862-1.5-1.862l-.787-.787 5.363-5.364.86-.86h.003l.163-.165-1.023-1.019ZM4.641 2.218l.496.496 7.691 7.692h.003l.86.86h-.002l5.36 5.362-.786.786s-1.193 1.04-1.5 1.863c-.584 1.556-.375 2.11-.375 2.11a.566.566 0 0 0 1.01.497l2.14-2.197-.002-.005a.499.499 0 0 0 .047-.039l4.25-4.25a.573.573 0 0 0 0-.809l-.966-.966a.574.574 0 0 0-.81 0l-1.99 1.992-2.221-2.222.002-.002-7.762-7.761v.002l-3.932-3.93-.078-.074-.433-.407a.709.709 0 0 0-1.002 1.002ZM12 15.69A2.133 2.133 0 1 0 12 19.957a2.133 2.133 0 0 0 0-4.267Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina73;
