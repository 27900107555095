/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-az.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneAz = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-az', className].join(' ');
  return (
    <svg
      id="nazione-az"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <path d="M0 0h16v16H0V0Z" fill="#3F9C35" />
        <path d="M0 0h16v10.666H0V0Z" fill="#ED2939" />
        <path d="M0 0h16v5.334H0V0Z" fill="#00B9E4" />
        <path d="M7.463 10.4a2.4 2.4 0 1 0 0-4.8 2.4 2.4 0 0 0 0 4.8Z" fill="#fff" />
        <path d="M7.997 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" fill="#ED2939" />
        <path
          d="m10.131 6.666.253.718.688-.328-.325.688.719.256-.72.256.326.688-.688-.328-.253.718-.256-.718-.688.328.329-.688L8.796 8l.72-.256-.329-.688.688.328.256-.718Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="16" height="16" rx="8" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NazioneAz;
