/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-397.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco397 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-397', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="32.526"
      viewBox="0 0 41.396 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M484.394,139.61a.739.739,0,0,0-.739-.739H468.131a.739.739,0,1,0,0,1.478h15.524A.739.739,0,0,0,484.394,139.61Zm-4.435,8.131H468.131a.739.739,0,1,0,0,1.478h11.827a.739.739,0,1,0,0-1.478Zm0-4.435H468.131a.739.739,0,1,0,0,1.478h11.827a.739.739,0,1,0,0-1.478Zm14.784,1.478a.739.739,0,0,0-.739.739v7.392a2.22,2.22,0,0,1-2.218,2.218h-19.22a.739.739,0,0,0-.739.739V160l-4.652-4.652a.74.74,0,0,0-.523-.217H463.7a2.22,2.22,0,0,1-2.218-2.218V135.174a2.22,2.22,0,0,1,2.218-2.218h26.612a.739.739,0,0,0,0-1.478H463.7a3.7,3.7,0,0,0-3.7,3.7v17.741a3.7,3.7,0,0,0,3.7,3.7h2.651l5.7,5.7a.739.739,0,0,0,1.262-.523v-5.175h18.48a3.7,3.7,0,0,0,3.7-3.7v-7.392A.739.739,0,0,0,494.743,144.784ZM501.375,135a7.377,7.377,0,0,0-4.976-4.976.738.738,0,0,0-.7.2l-13.306,13.306a.739.739,0,0,0-.217.523v4.435a.739.739,0,0,0,.739.739h4.435a.737.737,0,0,0,.523-.217L501.18,135.7A.739.739,0,0,0,501.375,135Zm-14.33,12.744h-3.39v-3.39L494,134l3.39,3.39Zm11.394-11.394-3.39-3.39,1.382-1.382a6.156,6.156,0,0,1,3.39,3.39Z"
        transform="translate(-460 -130)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco397;
