/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-13.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina13 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-13', className].join(' ');
  return (
    <svg
      id="disciplina_vela"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="m12.682 1.183.282 17.037s2.135-1.323 4.289-1.28c2.153.042 5.02.59 5.02.59-3.8-4.696-6.93-10.228-9.59-16.347ZM12.274 1s-1.642 4.007-2.177 5.945c-.704 2.567-.76 3.299 0 5.903.591 2.031 1.628 4.936 1.628 4.936s-2.36-2.862-4.007-2.867c-1.924-.005-3.458 1.52-3.458 1.52s-.155-4.27 1.253-8.525C6.695 4.34 12.274 1 12.274 1ZM.258 15.213s5.147 3.162 9.487 3.838c4.34.676 14.255.591 14.255.591s-.887 2.318-1.436 2.36c-.549.043-19.064 0-19.064 0S.046 16.94.004 15.888c-.042-1.05.254-.675.254-.675Z"
      />
    </svg>
  );
};
export default Disciplina13;
