/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-367.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco367 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-367', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.483"
      height="38.439"
      viewBox="0 0 35.483 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M317.437,306.567a.739.739,0,0,0,.739-.739v-6.653a.739.739,0,1,0-1.478,0v6.653A.739.739,0,0,0,317.437,306.567ZM328.526,294H313.741a2.96,2.96,0,0,0-2.957,2.957v8.871H305.3a3.689,3.689,0,0,0-3.589,2.811l-2.428,9.839a.732.732,0,0,0-.845.133l-2.218,2.218a.739.739,0,0,0,1.045,1.045l1.7-1.7,9.3,9.3-1.7,1.7a.739.739,0,1,0,1.045,1.045l2.218-2.218a.738.738,0,0,0,0-1.045l-1.182-1.182c3.4-.992,5.717-5.058,6.447-7.749a2.2,2.2,0,0,1,.567-.977l5.168-5.168a3.291,3.291,0,0,0,.3-.373v10.059h-5.467a.739.739,0,1,0,0,1.478h12.859a2.96,2.96,0,0,0,2.957-2.957V296.957A2.96,2.96,0,0,0,328.526,294Zm-7.392,15.553a3.149,3.149,0,0,0-2.66-1.352h0a3.3,3.3,0,0,0-2.351.976l-5.27,5.276a.769.769,0,0,1-.092.078.539.539,0,0,1-.5.064.755.755,0,0,1-.482-.475l-.316-.949a.74.74,0,0,0-1.4.467l.316.95A2.241,2.241,0,0,0,309.807,316a2.02,2.02,0,0,0,1.814-.271,2.2,2.2,0,0,0,.274-.234l5.271-5.277a1.835,1.835,0,0,1,1.305-.542h0a1.851,1.851,0,0,1,1.311,3.158l-5.168,5.169a3.678,3.678,0,0,0-.948,1.636c-.753,2.773-3.183,6.635-6.294,6.862l-6.859-6.859,2.629-10.652a2.215,2.215,0,0,1,2.156-1.687h5.486v3.335a.739.739,0,0,0,1.478,0V296.957a1.48,1.48,0,0,1,1.478-1.478h7.392Zm4.435,14.016h-2.957v-28.09h2.957ZM330,322.09a1.48,1.48,0,0,1-1.478,1.478h-1.478v-28.09h1.478A1.481,1.481,0,0,1,330,296.957Z"
        transform="translate(-296 -294)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco367;
