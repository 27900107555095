/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\match-live.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MatchLive = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-match-live', className].join(' ');
  return (
    <svg
      id="match-live"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333496 6.9987C0.333496 3.3187 3.3135 0.332031 6.9935 0.332031C10.6802 0.332031 13.6668 3.3187 13.6668 6.9987C13.6668 10.6787 10.6802 13.6654 6.9935 13.6654C3.3135 13.6654 0.333496 10.6787 0.333496 6.9987ZM5.50016 9.69867L9.70016 6.99867L5.50016 4.29867V9.69867Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default MatchLive;
