/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\shield.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Shield = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-shield', className].join(' ');
  return (
    <svg
      id="shield"
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M6.9 14.1016L12.6 8.40156L11.55 7.37656L6.975 11.9516L4.45 9.42656L3.35 10.5266L6.9 14.1016ZM8 20.9766C5.66667 20.3932 3.75 19.0391 2.25 16.9141C0.75 14.7891 0 12.4599 0 9.92656V3.97656L8 0.976562L16 3.97656V9.92656C16 12.4599 15.25 14.7891 13.75 16.9141C12.25 19.0391 10.3333 20.3932 8 20.9766Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Shield;
