/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-76.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina76 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-76', className].join(' ');
  return (
    <svg
      id="disciplina_medagliere"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M16.093 0 12.5 3.12 8.909 0H2l10.5 9.122L23 0h-6.907ZM12.5 9.122a7.561 7.561 0 0 0-4.172 1.254 7.454 7.454 0 0 0-2.766 3.339 7.373 7.373 0 0 0-.427 4.298A7.418 7.418 0 0 0 7.19 21.82a7.533 7.533 0 0 0 3.845 2.036 7.577 7.577 0 0 0 4.34-.423 7.495 7.495 0 0 0 3.37-2.74 7.389 7.389 0 0 0 .694-6.98 7.436 7.436 0 0 0-1.628-2.413 7.515 7.515 0 0 0-2.437-1.613 7.572 7.572 0 0 0-2.874-.566Zm2.708 11.57-2.708-1.41-2.708 1.41.516-2.99-2.192-2.115 3.029-.438 1.354-2.718 1.356 2.718 3.029.438-2.192 2.115.516 2.99Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina76;
