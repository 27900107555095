/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-118.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina118 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-118', className].join(' ');
  return (
    <svg
      id="disciplina_esports-calcio"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M18.758 12.716v.01h-5.689v-.018h-2.453v.02H5.214v-.01A5.585 5.585 0 0 0 .023 18.31c0 3.095 2.523 5.6 5.613 5.6a5.588 5.588 0 0 0 4.793-2.697h3.17a5.597 5.597 0 1 0 5.159-8.497ZM8.314 19.089h-1.96v1.876H4.886v-1.876H2.93V17.54h1.96v-1.876h1.468v1.876h1.96v1.548h-.004Zm7.56-.014a.951.951 0 1 1 0-1.903.951.951 0 0 1 0 1.903Zm2.508 2.513a.952.952 0 1 1 0-1.903.952.952 0 0 1 0 1.903Zm0-5.027a.952.952 0 1 1 0-1.904.952.952 0 0 1 0 1.904Zm2.514 2.514a.951.951 0 1 1 0-1.903.951.951 0 0 1 0 1.903Zm-1.618-16.62-.07-.047C18.017 1.494 16.54.768 14.903.36 8.469-1.245 1.955 2.673.351 9.105c-.16.64-.267 1.293-.32 1.951H.005v.411c0 .035-.004.07-.005.105h24a12.034 12.034 0 0 0-4.722-9.117Zm-1.528-.342c.009.005.018.01.023.005A11.558 11.558 0 0 1 22.439 7.3l.01.028.084.197c.01.018.018.042.028.06-.793-1.176-1.684-1.758-2.27-2.04a10.908 10.908 0 0 0-1.388-2.227l-.023-.023a9.545 9.545 0 0 0-1.088-1.144l-.047-.038h.005Zm-6.49-.047c.548-.197 2.086-.581 4.881-.216a9.27 9.27 0 0 1 2.148 1.928c.489.604.908 1.262 1.25 1.96l.01.019-.526 2.865-5.946.642-3.31-3.168 1.398-4.005c.033-.01.066-.015.094-.025ZM3.057 5.958c.436-1.013.947-2.147 1.796-2.879.905-.778 1.983-1.346 3.1-1.768.102-.047.21-.084.318-.126.572.192 1.51.515 2.148.745L9.017 5.956l-4.01 2.302L2.64 7.025c-.033-.016.384-.987.417-1.067ZM.657 11.056c.294-.97.9-2.746 1.59-3.381l.015-.005 2.361 1.24.357 2.148-4.323-.002Zm12.065 0 .49-1.055 5.932-.638 1.018 1.693h-7.44Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina118;
