/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-283.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco283 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-283', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="35.482"
      viewBox="0 0 35.482 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M218.653,246H215.7a2.22,2.22,0,0,1-2.218-2.218V240.83a.739.739,0,1,0-1.478,0v2.957a3.7,3.7,0,0,0,3.7,3.7h2.957a.739.739,0,0,0,0-1.478Zm25.133-34h-2.957a.739.739,0,1,0,0,1.478h2.957A2.22,2.22,0,0,1,246,215.7v2.957a.739.739,0,1,0,1.478,0V215.7A3.7,3.7,0,0,0,243.786,212Zm2.957,28.09a.74.74,0,0,0-.739.739v2.957A2.22,2.22,0,0,1,243.786,246h-2.957a.739.739,0,1,0,0,1.478h2.957a3.7,3.7,0,0,0,3.7-3.7V240.83A.74.74,0,0,0,246.743,240.09ZM218.653,212H215.7a3.7,3.7,0,0,0-3.7,3.7v2.957a.739.739,0,1,0,1.478,0V215.7a2.22,2.22,0,0,1,2.218-2.218h2.957a.739.739,0,1,0,0-1.478Z"
        transform="translate(-212 -212)"
        fill="currentColor"
      />
      <g transform="translate(2.957 8.871)">
        <path
          className="a"
          d="M230.784,244.7a11.841,11.841,0,0,1-11.827-11.827v-1.553A3.7,3.7,0,0,1,219.7,224a.739.739,0,1,1,0,1.478,2.218,2.218,0,1,0,0,4.435.739.739,0,0,1,.739.739v2.218a10.349,10.349,0,1,0,20.7,0v-2.218a.74.74,0,0,1,.739-.739,2.218,2.218,0,1,0,0-4.435.739.739,0,1,1,0-1.478,3.7,3.7,0,0,1,.739,7.318v1.553A11.841,11.841,0,0,1,230.784,244.7Z"
          transform="translate(-216 -224)"
          fill="currentColor"
        />
        <g transform="translate(10.349 11.827)">
          <path
            className="a"
            d="M234.435,242.952a6.045,6.045,0,0,1-4.21-1.682.739.739,0,0,1,1.028-1.063,4.62,4.62,0,0,0,6.365,0,.739.739,0,1,1,1.028,1.063A6.048,6.048,0,0,1,234.435,242.952Z"
            transform="translate(-230 -240)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(13.306 1.479)">
          <path
            className="a"
            d="M236.749,233.392h-2.01a.739.739,0,0,1,0-1.478h2.01l-1.249-4.995a.739.739,0,1,1,1.434-.359l1.249,5a1.479,1.479,0,0,1-1.435,1.837Z"
            transform="translate(-234 -226)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(19.959)">
          <path
            className="a"
            d="M243.739,226.957a.739.739,0,0,1-.739-.739v-1.478a.739.739,0,0,1,1.478,0v1.478A.739.739,0,0,1,243.739,226.957Z"
            transform="translate(-243 -224)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(8.131)">
          <path
            className="a"
            d="M227.739,226.957a.739.739,0,0,1-.739-.739v-1.478a.739.739,0,0,1,1.478,0v1.478A.739.739,0,0,1,227.739,226.957Z"
            transform="translate(-227 -224)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco283;
