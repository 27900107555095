/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-1001.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina1001 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-1001', className].join(' ');
  return (
    <svg
      id="disciplina_auto-ev"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M13.2252 4c-2.8919.53153-5.50448 2.07207-8.99097.51351.04505.16217.09009.31532.13514.47748h-.82883l-.06306-.23423H3L7.26126 19.8198h.47748l-1.9009-6.7207h.82883c.05405.1892.1081.3694.15315.5495 3.48648 1.5586 6.09008.0091 8.99098-.5135-.8649-3.045-1.7207-6.09006-2.5856-9.1351ZM7.64865 5.66667c.98198-.01802 1.90991-.22523 2.83785-.46847.2432.87387.4955 1.73874.7387 2.61261-.9189.24324-1.85583.44144-2.83781.46847-.24325-.87387-.4955-1.73874-.73874-2.61261ZM5.73874 12.7387l-2.0991-7.38735h.82883c.7027 2.45946 1.39639 4.92795 2.09009 7.39635h-.81982v-.009Zm.66667-2.2522c-.24325-.84686-.47748-1.70272-.72073-2.54956.97298.36036 1.87388.47747 2.73874.45946.24325.84684.47748 1.7027.72072 2.5495-.86486.0181-1.76576-.0991-2.73873-.4594Zm6.39639 2.9009c-.9189.2432-1.8468.4414-2.83784.4685-.24324-.8739-.49549-1.7388-.73874-2.6127.98198-.018 1.90988-.2252 2.83788-.4684.2432.8738.4865 1.7387.7387 2.6126Zm1.6036-3.54055c-.8198.18015-1.6216.42345-2.4324.63065-.2433-.84687-.4775-1.70273-.7207-2.54957.8108-.21622 1.6126-.45946 2.4324-.63063.2432.84684.4775 1.69369.7207 2.54955Zm-.3874-4.36036c.0451.16216.0901.31531.1352.47747.3693-.05405.7477-.09009 1.1351-.0991.2432.87388.4955 1.73874.7387 2.61262-.3874.00901-.7657.04504-1.1351.0991.2793.99099.5586 1.97302.8378 2.96392.3694-.054.7478-.09 1.1352-.0991.2432.8739.4955 1.7388.7387 2.6127-.982.018-1.9099.2252-2.8378.4684-.0541-.2072-.1172-.4054-.1712-.6126-.955.2523-1.9009.5496-2.8829.7207.0991.3604.2072.7208.3063 1.0901 2.8919-.5315 5.5045-2.0721 8.982-.5135-.8649-3.045-1.7207-6.09008-2.5856-9.13513-1.6036-.73874-3.036-.79279-4.3964-.58558Zm5.5045 6.10811c-.973-.3604-1.8739-.4775-2.7387-.4595-.2433-.8468-.4775-1.70267-.7207-2.54951.8648-.01802 1.7657.09909 2.7387.45945.2432.84685.4865 1.69366.7207 2.54956Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina1001;
