import { createSelector } from '@reduxjs/toolkit';
import { TicketMessaggiErrori } from 'features/carrello/types';
import { KeyManagerIppica } from 'features/ippica/utils/keyManagerIppica';
import { RootState } from 'lib/centralStore';
import { CartIppicaScommessaAvvenimento } from 'types/swagger';
import { IppicaTicketState } from './ippicaTicketSlice';
import { IppicaTicket } from './utils/calcoloCarrello/types';
import { isTipoVenditaQuotaFissa } from './utils/calcoloCarrello/utils';

const _self = (state: RootState): IppicaTicketState => state.ippicaTicket;

export const selectIsScommettibile = (state: RootState) => state.ippicaTicket.isScommettibile;

export const selectPuntataSingolaMultipla = (state: RootState) => state.ippicaTicket.puntataSingolaMultipla;

export const selectErroreInserimento = (state: RootState) => state.ippicaTicket.errori.erroreInserimento;

export const selectIsSistema = (state: RootState) => state.ippicaTicket.ticket?.sistema;

export const selectTotalePuntataScommesseSistema = createSelector(_self, (state) => {
  let totale = 0;
  const puntataPerScommessa = state.puntataPerScommessa;
  for (let id in puntataPerScommessa) {
    if (puntataPerScommessa.hasOwnProperty(id)) {
      totale += puntataPerScommessa[id];
    }
  }
  return totale;
});

export const selectEsiti = createSelector(_self, (slice): Record<string, boolean> => slice.esiti);

export const selectTicket = createSelector(_self, (slice) => (slice.ticket ?? {}) as IppicaTicket);

export const selectTipo = createSelector(selectTicket, (slice) => slice.tipo);

export const selectBonus = createSelector(selectTicket, (slice) => slice.bonus);

export const selectPrezzo = createSelector(selectTicket, (slice) => slice.prezzo);

export const selectSistema = createSelector(selectTicket, (slice) => slice.sistema);

export const selectSistemi = createSelector(selectTicket, (slice) => slice.sistemi);

export const selectPossibileVincitaMin = createSelector(selectTicket, (slice) => slice.possibileVincitaMin);

export const selectPossibileVincitaMax = createSelector(selectTicket, (slice) => slice.possibileVincitaMax);
export const selectCombinazioniTotalizzatore = createSelector(
  selectTicket,
  (ticket) => ticket.numeroCombinazioniTotalizzatore
);

export const selectPossibileVincitaMinWithBonus = createSelector(
  selectTicket,
  (slice) => slice.possibileVincitaMinWithBonus
);

export const selectPossibileVincitaMaxWithBonus = createSelector(
  selectTicket,
  (slice) => slice.possibileVincitaMinWithBonus
);

export const selectErrori = createSelector([_self], (slice): TicketMessaggiErrori => slice?.errori ?? {});

export const selectErroriCarrelloIppica = createSelector([selectErrori], (errori) => errori?.erroriCarrello);

export const selectErroriSistema = createSelector([selectErrori], (errori) => errori?.erroriSistema);

export const selectErroriEvento = createSelector([selectErrori], (errori): Array<string> => errori.erroriEvento);

export const selectQuotaTotale = createSelector([selectTicket], (slice) => slice.quotaTotale);

export const selectAvvenimenti = createSelector(
  selectTicket,
  (ticket): Array<CartIppicaScommessaAvvenimento> => ticket?.avvenimenti ?? []
);

export const selectNumEsitiIppica = createSelector(selectTicket, (ticket) => ticket?.numEsiti);

export const selectAvvenimentiKeys = createSelector(
  selectAvvenimenti,
  (avvenimenti): Array<number> => avvenimenti.map((avvenimento) => avvenimento.numeroAvvenimento)
);

export const selectIsOpenCombinazioni = createSelector([_self], (slice): boolean => slice.isOpenCombinazioni);

export const selectEsitiByAvvenimenti = createSelector(selectAvvenimenti, (avvenimenti) =>
  avvenimenti.map((avv) => avv.esiti).flat()
);

export const selectEsitiKeyByAvvenimenti = createSelector(_self, selectEsitiByAvvenimenti, (state, esiti) => {
  if (state.ticket) {
    if (isTipoVenditaQuotaFissa(state.ticket?.tipoVendita)) {
      return esiti.map((esito) => esito?.id);
    }
    return ['totalizzatore'];
  }
  return [];
});

export const selectScommesseKeys = createSelector(selectEsitiByAvvenimenti, (esiti): Array<string> => {
  return [
    ...new Set(
      esiti.map((esito) => {
        return new KeyManagerIppica(esito?.id!).scommessaKey;
      })
    ),
  ];
});

export const selectAvvenimentoById = createSelector(
  [selectAvvenimenti, (_state: RootState, id: number) => id],
  (avvenimenti, id): CartIppicaScommessaAvvenimento | undefined => {
    const avvenimento = avvenimenti?.find((avvenimento) => avvenimento.numeroAvvenimento === id);
    return avvenimento ?? undefined;
  }
);

export const selectTipoVendita = createSelector(selectTicket, (ticket) => ticket.tipoVendita);

export const selectMoltiplicatore = createSelector(_self, (slice) => slice.moltiplicatore);

export const selectUnita = createSelector(_self, (slice) => slice.unita);
