/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\cancel.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Cancel = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-cancel', className].join(' ');
  return (
    <svg id="cancel" viewBox="0 0 14 14" focusable="false" className={componentClassName} {...attribs}>
      <path
        d="M4.6497 10.2001L7.0001 7.8497L9.3505 10.2001L10.2001 9.3505L7.8497 7.0001L10.2001 4.6497L9.3505 3.8001L7.0001 6.1505L4.6497 3.8001L3.8001 4.6497L6.1505 7.0001L3.8001 9.3505L4.6497 10.2001ZM7.0001 13.4001C6.12223 13.4001 5.2945 13.2334 4.5169 12.9001C3.7393 12.5668 3.05876 12.1084 2.4753 11.5249C1.89183 10.9414 1.43343 10.2609 1.1001 9.4833C0.766764 8.7057 0.600098 7.87796 0.600098 7.0001C0.600098 6.11103 0.766764 5.28036 1.1001 4.5081C1.43343 3.73636 1.89183 3.05876 2.4753 2.4753C3.05876 1.89183 3.7393 1.43343 4.5169 1.1001C5.2945 0.766764 6.12223 0.600098 7.0001 0.600098C7.88916 0.600098 8.71983 0.766764 9.4921 1.1001C10.2638 1.43343 10.9414 1.89183 11.5249 2.4753C12.1084 3.05876 12.5668 3.73636 12.9001 4.5081C13.2334 5.28036 13.4001 6.11103 13.4001 7.0001C13.4001 7.87796 13.2334 8.7057 12.9001 9.4833C12.5668 10.2609 12.1084 10.9414 11.5249 11.5249C10.9414 12.1084 10.2638 12.5668 9.4921 12.9001C8.71983 13.2334 7.88916 13.4001 7.0001 13.4001ZM7.0001 12.2001C8.44436 12.2001 9.6721 11.6945 10.6833 10.6833C11.6945 9.6721 12.2001 8.44436 12.2001 7.0001C12.2001 5.55583 11.6945 4.3281 10.6833 3.3169C9.6721 2.3057 8.44436 1.8001 7.0001 1.8001C5.55583 1.8001 4.3281 2.3057 3.3169 3.3169C2.3057 4.3281 1.8001 5.55583 1.8001 7.0001C1.8001 8.44436 2.3057 9.6721 3.3169 10.6833C4.3281 11.6945 5.55583 12.2001 7.0001 12.2001Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Cancel;
