import { Sistema, TicketErrore, TicketErroreInserimento, TicketMessaggi } from 'features/carrello/types';
import { CartScommessaAvvenimentoVirtual } from 'types/swagger';

export type VirtualContenutoCarrello = {
  avvenimenti: VirtualTicketAvvenimento[];
  puntata?: number; // format centesimi x 100
  puntataPerScommessa?: { [idSistema: string]: number }; // idSistema 1/2 per esempio
  sistema?: boolean;
  maxVincita?: number;
};

export type VirtualTicket = {
  avvenimenti: VirtualTicketAvvenimento[];
  puntata: number; // da passare quando input utente
  puntataPerScommessa: { [idSistema: string]: number }; // solo sistemi
  sistema: boolean; // quando uguale a false è una singola o multipla
  tipo: TipoVirtualTicket;
  numEsiti: number; // quando numero esiti > 1 va visualizzato in multipla
  sistemi: Sistema[];
  moltBonus: number;
  percBonus: number;
  quotaTotale: number;
  prezzo: number; // importo Totale, manca nella UI segnalarlo
  bonus: number; // if bonus === 0 non considerare possibileVincitaMinWithBonus possibileVincitaMaxWithBonus
  minCombinazioni: number;
  maxCombinazioni: number;
  possibileVincitaMin: number;
  possibileVincitaMax: number;
  possibileVincitaMinWithBonus?: number;
  possibileVincitaMaxWithBonus?: number;
  messaggi: TicketMessaggi[];
  errori: Array<{
    type: TicketErrore;
    value?: number;
  }>;
  erroreInserimento: TicketErroreInserimento | undefined;
  transazioneBetReferral?: string;
};

export enum TipoVirtualTicket {
  Singola,
  Multipla,
  Sistema,
  MultiplaSistema,
}

export type VirtualTicketEsito = {
  id: string; // è la key Esito // idProgramma-idAvvenimento-idTipoScommessa-idesito
  descrizione: string; //  "label": "1-2-4",
  idTipoScommessa: number; // scommessaMap // da prendere in infoaggiuntiva map
  idEsito: number | string; // TODO DEFINIRLO STRING perché se idEsito è 1-2-3 non può essere number
  quota: number; // esitoMap
  flagBonus: boolean;
  descrizioneTipoScommessa: string; // "label": "Piazzato nei 2" "title": "Trio in Disordine", "labelTipoScommessa": "Accoppiata",
};

export type VirtualTicketAvvenimento = CartScommessaAvvenimentoVirtual;

export type SviluppoSistemi = {
  quotaMinima?: number;
  quotaMassima?: number;
  dettaglioSviluppoSistemi: DettaglioSviluppoSistema[];
};

export type DettaglioSviluppoSistema = {
  codiceSistema: number;
  quotaMinimaSistema: number;
  quotaMassimaSistema: number;
  numeroMultiple: number;
};
