/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-19.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina19 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-19', className].join(' ');
  return (
    <svg
      id="disciplina_canottaggio"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M2.345 7.183A2.092 2.092 0 1 0 2.335 3a2.092 2.092 0 0 0 .01 4.183Zm4.713 8.606 2.031 1.59a1.184 1.184 0 1 0 1.459-1.867l-2.495-1.96-.995 2.237Zm7.954-8.606a2.092 2.092 0 1 0 0-4.183 2.092 2.092 0 0 0 0 4.183Zm4.718 8.606 2.03 1.59a1.184 1.184 0 0 0 1.46-1.867l-2.496-1.96-.994 2.237ZM23.773 19a.787.787 0 0 0-1.117 0 .985.985 0 0 1-1.364 0c-.727-.727-1.857-.9-2.781-.534-.296.117-.6.29-.83.525l4.192-9.469.028-.004a.987.987 0 1 0-.267-1.956l-.868.117-4.117.554a1.809 1.809 0 0 0-1.266-.526h-.75a1.801 1.801 0 0 0-1.801 1.801v5.389a1.987 1.987 0 0 0 2.72 1.843l1.406-.558.005-.005 1.337-2.992-1.15.413.038-3.447c3.081-.418 2.42-.328 3.316-.446l-3.935 8.817-.216.488c-.004-.005-.014-.01-.018-.014a2.547 2.547 0 0 0-3.602 0c-.361.36-.999.36-1.365 0-.961-.962-2.607-.995-3.63.032a.947.947 0 0 1-1.303 0 2.222 2.222 0 0 0-.662-.45 2.828 2.828 0 0 0-.539-.187l3.972-8.869.033-.004a.987.987 0 1 0-.267-1.956L8.1 7.68l-4.123.554a1.809 1.809 0 0 0-1.266-.526h-.75A1.801 1.801 0 0 0 .16 9.51v5.389a1.987 1.987 0 0 0 2.72 1.843l1.416-.563 1.332-2.992-1.163.417.038-3.451c3.095-.418 2.434-.328 3.32-.446l-3.92 8.737a.265.265 0 0 0-.057.019c-.36.11-.688.304-.957.567a1.096 1.096 0 0 1-1.547 0 .79.79 0 0 0-1.116 1.116 2.673 2.673 0 0 0 3.78 0c.323-.323.89-.393 1.364.061.947.887 2.519.896 3.508-.089a.985.985 0 0 1 1.365 0c.957.957 2.645.957 3.597 0a.967.967 0 0 1 1.364 0c.962.962 2.636.962 3.598 0a.984.984 0 0 1 1.364 0c.962.962 2.636.962 3.597 0a.788.788 0 0 0 .01-1.116Z"
      />
    </svg>
  );
};
export default Disciplina19;
