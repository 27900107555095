/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\info.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Info = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-info', className].join(' ');
  return (
    <svg id="info" viewBox="0 0 20 20" focusable="false" className={componentClassName} {...attribs}>
      <path
        d="M9.0625 15H10.9375V8.75H9.0625V15ZM10 6.875C10.26 6.875 10.4813 6.78375 10.6638 6.60125C10.8463 6.41875 10.9375 6.1975 10.9375 5.9375C10.9375 5.6775 10.8463 5.45625 10.6638 5.27375C10.4813 5.09125 10.26 5 10 5C9.74 5 9.51875 5.09125 9.33625 5.27375C9.15375 5.45625 9.0625 5.6775 9.0625 5.9375C9.0625 6.1975 9.15375 6.41875 9.33625 6.60125C9.51875 6.78375 9.74 6.875 10 6.875ZM10 20C8.62833 20 7.335 19.7396 6.12 19.2188C4.905 18.6979 3.84167 17.9817 2.93 17.07C2.01833 16.1583 1.30208 15.095 0.78125 13.88C0.260417 12.665 0 11.3717 0 10C0 8.61083 0.260417 7.31292 0.78125 6.10625C1.30208 4.90042 2.01833 3.84167 2.93 2.93C3.84167 2.01833 4.905 1.30208 6.12 0.78125C7.335 0.260417 8.62833 0 10 0C11.3892 0 12.6871 0.260417 13.8937 0.78125C15.0996 1.30208 16.1583 2.01833 17.07 2.93C17.9817 3.84167 18.6979 4.90042 19.2188 6.10625C19.7396 7.31292 20 8.61083 20 10C20 11.3717 19.7396 12.665 19.2188 13.88C18.6979 15.095 17.9817 16.1583 17.07 17.07C16.1583 17.9817 15.0996 18.6979 13.8937 19.2188C12.6871 19.7396 11.3892 20 10 20ZM10 18.125C12.2567 18.125 14.175 17.335 15.755 15.755C17.335 14.175 18.125 12.2567 18.125 10C18.125 7.74333 17.335 5.825 15.755 4.245C14.175 2.665 12.2567 1.875 10 1.875C7.74333 1.875 5.825 2.665 4.245 4.245C2.665 5.825 1.875 7.74333 1.875 10C1.875 12.2567 2.665 14.175 4.245 15.755C5.825 17.335 7.74333 18.125 10 18.125Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Info;
