/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\minus.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Minus = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-minus', className].join(' ');
  return (
    <svg id="minus" viewBox="0 0 14 2" focusable="false" className={componentClassName} {...attribs}>
      <path d="M6 2H0V0H6H8H14V2H8H6Z" fill="currentColor" />
    </svg>
  );
};
export default Minus;
