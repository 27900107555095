/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\share.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Share = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-share', className].join(' ');
  return (
    <svg
      id="share"
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M8.66699 3.33464L7.72032 4.2813L6.66033 3.2213V10.668H5.34033V3.2213L4.28033 4.2813L3.33366 3.33464L6.00033 0.667969L8.66699 3.33464ZM11.3337 6.66797V14.0013C11.3337 14.7346 10.7337 15.3346 10.0003 15.3346H2.00033C1.26033 15.3346 0.666992 14.7346 0.666992 14.0013V6.66797C0.666992 5.92797 1.26033 5.33464 2.00033 5.33464H4.00033V6.66797H2.00033V14.0013H10.0003V6.66797H8.00033V5.33464H10.0003C10.7337 5.33464 11.3337 5.92797 11.3337 6.66797Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Share;
