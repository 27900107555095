/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-314.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco314 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-314', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="35.483"
      viewBox="0 0 41.396 35.483"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M397.715,322.382a.74.74,0,0,0-.992.331.726.726,0,0,0-.038.089,5.18,5.18,0,0,1-6.625,3.086h0l-.171-.073-.013,0a5.175,5.175,0,0,1-2.918-6.531.739.739,0,0,0-1.348-.606.811.811,0,0,0-.033.078,6.647,6.647,0,0,0,3.293,8.23V329.9a1.48,1.48,0,0,1-1.478,1.478h-6.653a.739.739,0,1,0,0,1.478h6.653a2.96,2.96,0,0,0,2.957-2.957v-2.391a6.4,6.4,0,0,0,2.957,0V329.9a5.921,5.921,0,0,1-5.914,5.914h-5.175a.739.739,0,0,0,0,1.478h5.175a7.4,7.4,0,0,0,7.392-7.392v-2.92a6.6,6.6,0,0,0,3.293-3.676l0-.007A.739.739,0,0,0,397.715,322.382Z"
        transform="translate(-377.043 -301.806)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M416.91,308.243,384,296.225a3.7,3.7,0,0,0-4.737,2.21l-1.518,4.168-1.516,4.168a3.694,3.694,0,0,0,2.209,4.737l25.7,9.355a1.479,1.479,0,0,0,1.9-.884l.506-1.389,3.472,1.263a.739.739,0,0,0,.947-.442l2.528-6.946a.732.732,0,0,0-.387-.914l3.892-1.952a.739.739,0,0,0-.078-1.355Zm-12.269,11.229-25.7-9.355a2.217,2.217,0,0,1-1.325-2.842l1.263-3.473,27.786,10.112L405.4,317.38l0,.007v.008Zm7.2-6.82-2.022,5.557-2.777-1.01,1.163-3.2,3.089-1.549Zm-.9-1.674-.007,0-3.365,1.688-28.181-10.257,1.264-3.474a2.22,2.22,0,0,1,2.842-1.326L414.8,309.044Z"
        transform="translate(-376 -296)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco314;
