/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-14.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina14 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-14', className].join(' ');
  return (
    <svg
      id="disciplina_atletica-leggera"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M21.052 7.496a.817.817 0 0 0-1.142-.103l-2.26 1.895-1.036-2.57a.973.973 0 0 0-.154-.249 2.184 2.184 0 0 0-1.087-1.108 2.281 2.281 0 0 0-.675-.18.993.993 0 0 0-.15-.078l-3.966-1.104a.823.823 0 0 0-.631.086.799.799 0 0 0-.494.464l-1.495 3.84a.816.816 0 1 0 1.52.585l1.26-3.248 1.808.503c-.043.073-.09.137-.129.214l-2.32 5.027c-.03.072-.055.147-.073.223l-2.814 4.722-4.726 1.577a1.206 1.206 0 0 0 1.435 1.938l4.82-1.663c.14-.105.255-.24.336-.395.06-.065.128-.116.176-.194l1.68-2.814 2.981 2.54-3.192 3.595a1.204 1.204 0 0 0 1.804 1.594l3.97-4.485c.118-.134.202-.295.245-.468.024-.092.037-.186.039-.28 0-.047.017-.09.013-.133a1.172 1.172 0 0 0-.413-.876l-2.736-2.342c.204-.192.37-.42.49-.674l1.778-3.846.571 1.517c.02.137.077.266.164.374.083.096.19.17.309.215a.128.128 0 0 0 .039.008c.071.03.146.048.223.056a.786.786 0 0 0 .28-.03h.004a.2.2 0 0 0 .073-.013.794.794 0 0 0 .318-.232l3.239-2.745c.348-.288.206-.8-.082-1.143ZM16.954 1a2.273 2.273 0 1 1 0 4.546 2.273 2.273 0 0 1 0-4.546Z"
      />
    </svg>
  );
};
export default Disciplina14;
