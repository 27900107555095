/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\snai-logo-short.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const SnaiLogoShort = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-snai-logo-short', className].join(' ');
  return (
    <svg
      id="snai-logo-short"
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g fill="currentColor" d="M0 0h32v32H0z">
        <path
          fill="currentColor"
          d="M12.5867 1.3892c-1.8036.16233-3.55853.69057-5.16463 1.55453-1.6061.86397-3.03186 2.04674-4.19585 3.48074C2.06224 7.85847 1.18284 9.5156.638276 11.3012c-.5445634 1.7856-.743627 3.6646-.5858121 5.5298.1569531 1.8653.6677451 3.6804 1.5031961 5.3416.83546 1.6611 1.9792 3.1357 3.36588 4.3395 1.38667 1.2038 2.9891 2.1133 4.71574 2.6764 1.72662.5631 3.54362.7689 5.34722.6055 3.6411-.3302 7.0062-2.1426 9.3553-5.0387 2.349-2.896 3.4896-6.6385 3.1707-10.4041-.1567-1.8651-.6671-3.6799-1.5023-5.34091-.8351-1.66095-1.9784-3.13547-3.3647-4.33928-1.3863-1.20382-2.9884-2.11335-4.7146-2.67664-1.7263-.56328-3.543-.76928-5.3463-.60623l.0041.00106Zm2.1114 25.026c-2.7761.2505-5.53479-.6493-7.66964-2.5017-2.13485-1.8523-3.47112-4.5056-3.71506-7.3765-.12062-1.4219.03081-2.8544.44564-4.2157s1.08493-2.62475 1.97202-3.71815c.88709-1.09341 1.97377-1.99534 3.19799-2.65429 1.22425-.65895 2.56195-1.062 3.93685-1.18611 1.3747-.12445 2.7596.03238 4.0756.46153 1.316.42916 2.5374 1.12223 3.5945 2.03964 1.057.9174 1.9289 2.04118 2.5659 3.30708.637 1.266 1.0267 2.6493 1.1467 4.071.243 2.871-.6266 5.7243-2.4176 7.9322-1.7911 2.2079-4.3568 3.5895-7.1329 3.841Z"
        />
        <path
          fill="var(--color-primary)"
          d="M27.2734 8.66686c1.2911.8505 2.0748 1.89704 2.1716 3.07004.3149 3.715-6.2987 7.352-14.77 8.115-3.2935.2962-6.3843.117-8.94719-.43 2.85332 1.3237 7.18729 1.9508 11.96869 1.5177 8.2043-.7378 14.5977-4.321 14.293-7.9865-.1579-1.8264-1.9453-3.3314-4.7161-4.28835"
        />
      </g>
    </svg>
  );
};
export default SnaiLogoShort;
