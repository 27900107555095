/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\regno-unito.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const RegnoUnito = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-regno-unito', className].join(' ');
  return (
    <svg
      id="regno-unito"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <path d="M0 0h16v16H0V0Z" fill="#012169" />
        <path
          d="M16 0v2l-5.938 6L16 13.844V16h-2.094l-5.969-5.875L2.125 16H0v-2.125l5.813-5.844L0 2.312V0h1.938l6 5.875L13.75 0H16Z"
          fill="#fff"
        />
        <path
          d="M4.5 10h3l-6.188 6H-1.5l6-6Zm4 0h2.813L16 14.594V17.5L8.5 10ZM16 1.5l-4.304 4.188h-2.98L16-1.5v3ZM0-1l7.1 6.688H4.187L0 1.53V-1Z"
          fill="#C8102E"
        />
        <path d="M5.5 0v16h5V0h-5ZM0 5.5v5h16v-5H0Z" fill="#fff" />
        <path d="M0 6.5v3h16v-3H0ZM6.5 0v16h3V0h-3Z" fill="#C8102E" />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="16" height="16" rx="8" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default RegnoUnito;
