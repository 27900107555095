/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\bonus.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Bonus = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-bonus', className].join(' ');
  return (
    <svg
      id="bonus"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      preserveAspectRatio="none"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M19.75 6.5h-4.11c.49-.48.8-1.14.8-1.88 0-1.45-1.18-2.62-2.62-2.62-.89 0-1.71.46-2.19 1.19-.46-.51-1.11-.81-1.81-.81-1.34 0-2.43 1.09-2.43 2.43 0 .66.26 1.25.69 1.69H3.75C2.79 6.5 2 7.29 2 8.25v3c0 .81.56 1.49 1.31 1.69v6.81c0 .96.79 1.75 1.75 1.75h13.38c.96 0 1.75-.79 1.75-1.75v-6.81c.75-.2 1.31-.88 1.31-1.69v-3c0-.96-.79-1.75-1.75-1.75ZM20 8.25v3c0 .14-.11.25-.25.25h-7.21V8h7.21c.14 0 .25.11.25.25Zm-9.01-3.44c0 .65-.53 1.18-1.18 1.18-.65 0-1.18-.53-1.18-1.18 0-.65.53-1.18 1.18-1.18.65 0 1.18.53 1.18 1.18Zm1.45-.19c0-.76.62-1.37 1.37-1.37.75 0 1.37.62 1.37 1.37 0 .75-.62 1.37-1.37 1.37-.75 0-1.37-.62-1.37-1.37ZM3.5 8.25c0-.14.11-.25.25-.25h7.28v3.5H3.75c-.14 0-.25-.11-.25-.25v-3Zm1.31 11.5V13h6.23v7H5.06c-.14 0-.25-.11-.25-.25Zm13.88 0c0 .14-.11.25-.25.25h-5.91v-7h6.16v6.75Z"
      />
    </svg>
  );
};
export default Bonus;
