/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-400.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco400 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-400', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M77.407,317.59l-5.838-1.946v-5.381a2.957,2.957,0,1,0-5.914,0v10.781l-.56-.56a4.442,4.442,0,0,0-6.273,0l-.344.344a.739.739,0,0,0,0,1.042l8.083,8.188a8.139,8.139,0,0,0,5.76,2.381c.188,0,.378-.007.568-.019a8.276,8.276,0,0,0,7.552-8.34V321.8A4.431,4.431,0,0,0,77.407,317.59Zm1.554,6.49a6.793,6.793,0,0,1-6.176,6.864,6.669,6.669,0,0,1-5.177-1.927l-7.556-7.655a2.963,2.963,0,0,1,4,.168l1.821,1.822a.739.739,0,0,0,1.262-.523V310.263a1.478,1.478,0,1,1,2.957,0v5.914a.737.737,0,0,0,.506.7l6.343,2.115a2.953,2.953,0,0,1,2.022,2.805Zm-22.653-2.014a13.3,13.3,0,1,1,13.38-16.474.739.739,0,0,0,1.436-.354,14.777,14.777,0,1,0-14.868,18.3h.027a.739.739,0,0,0,.026-1.478ZM59,316.916v-1.478a1.48,1.48,0,0,0-1.478-1.478H56.045a1.48,1.48,0,0,0-1.478,1.478v1.478a1.48,1.48,0,0,0,1.478,1.478h1.478A1.48,1.48,0,0,0,59,316.916Zm-2.957,0v-1.478h1.478v1.478Zm.384-9.29a5.16,5.16,0,0,0-1.862,3.976v.14a.739.739,0,0,0,.739.739h2.957a.781.781,0,0,0,.739-.786.735.735,0,0,1,.266-.568l2.092-1.743a5.774,5.774,0,0,0-3.7-10.209H56.045a5.921,5.921,0,0,0-5.914,5.914v.739a.739.739,0,0,0,.739.739h2.957a.739.739,0,0,0,.739-.739v-.739a1.48,1.48,0,0,1,1.478-1.478h1.682a1.274,1.274,0,0,1,.816,2.253Zm1.3-5.495H56.045a2.96,2.96,0,0,0-2.957,2.957H51.61a4.44,4.44,0,0,1,4.435-4.435h1.619a4.295,4.295,0,0,1,2.75,7.594l-2.092,1.744A2.187,2.187,0,0,0,57.637,311H56.093a3.686,3.686,0,0,1,1.282-2.24L59.49,307a2.753,2.753,0,0,0-1.762-4.868Z"
        transform="translate(-42 -294)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco400;
