/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\programmed.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Programmed = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-programmed', className].join(' ');
  return (
    <svg
      id="programmed"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333496 6.9987C0.333496 3.3187 3.3135 0.332031 6.9935 0.332031C10.6802 0.332031 13.6668 3.3187 13.6668 6.9987C13.6668 10.6787 10.6802 13.6654 6.9935 13.6654C3.3135 13.6654 0.333496 10.6787 0.333496 6.9987ZM6.3335 3.66536H7.3335V7.16536L10.3335 8.94536L9.8335 9.76536L6.3335 7.66536V3.66536Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Programmed;
