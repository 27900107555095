/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-40.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina40 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-40', className].join(' ');
  return (
    <svg
      id="disciplina_elezioni-politiche"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M10.866 10.095 4.158 4.952 7.189 1l6.709 5.143-3.032 3.952Zm10.6 1.836c-.52-.782-1.045-1.565-1.565-2.348-.297-.434-.766-.886-1.316-.946h-5.363l-1.255 1.737h2.76a.456.456 0 1 1 0 .912H8.935a.456.456 0 0 1 0-.912h.718L7.464 8.633H5.078c-.546.06-1.015.516-1.316.946-.525.787-1.045 1.57-1.57 2.352-.348.52-.21.946.31.946h18.654c.516 0 .658-.426.31-.946Zm.206 10.553v-8.115a.516.516 0 0 0-.516-.516H2.545a.516.516 0 0 0-.516.516v8.115a.516.516 0 0 0 .516.516h18.611a.516.516 0 0 0 .516-.516Z"
      />
    </svg>
  );
};
export default Disciplina40;
