/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\ferri-davanti.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const FerriDavanti = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-ferri-davanti', className].join(' ');
  return (
    <svg
      id="ferri-davanti"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M9.53516 2.96242c-1.9499-1.94989-5.12285-1.94989-7.07274 0-1.949893 1.94989-1.949893 5.12284 0 7.07278l1.43839-1.43844c-1.15668-1.15668-1.15668-3.03914 0-4.19584.56043-.56043 1.30573-.86877 2.09792-.86877.79223 0 1.53746.30827 2.09792.86877.56043.56043.86878 1.30573.86878 2.09792 0 .79223-.30827 1.53746-.86878 2.09792l1.4384 1.43844C10.4794 9.09082 11 7.83515 11 6.49888c.0001-1.33629-.5206-2.59214-1.46484-3.53646ZM2.11422 5.31447c.16436 0 .2981.13373.2981.2981 0 .16436-.13373.2981-.2981.2981-.16436 0-.29809-.13374-.29809-.2981 0-.16437.13373-.2981.29809-.2981Zm-.37364 1.58239c0-.16435.13373-.2981.2981-.2981.16436 0 .2981.13374.2981.2981 0 .16436-.13374.2981-.2981.2981-.16437 0-.2981-.13374-.2981-.2981Zm.64724 1.58239c-.16435 0-.2981-.13373-.2981-.2981 0-.16436.13374-.2981.2981-.2981.16437 0 .2981.13374.2981.2981 0 .16437-.13373.2981-.2981.2981Zm7.4975-3.16475c.16438 0 .29808.13374.29808.2981 0 .16437-.1337.2981-.29808.2981-.16437 0-.2981-.13373-.2981-.2981 0-.16436.13267-.2981.2981-.2981ZM9.6076 8.47925c-.16435 0-.2981-.13373-.2981-.2981 0-.16436.13374-.2981.2981-.2981.16437 0 .2981.13374.2981.2981.00007.16436-.13366.2981-.2981.2981Zm.35635-1.28429c-.16436 0-.2981-.13374-.2981-.2981 0-.16435.13373-.2981.2981-.2981.16435 0 .29805.13374.29805.2981 0 .16436-.1337.2981-.29805.2981Z"
        fill="currentColor"
      />
      <path
        d="M9.53516 14.9624c-1.9499-1.9499-5.12285-1.9499-7.07274 0-1.949893 1.9499-1.949893 5.1229 0 7.0728l1.43839-1.4384c-1.15668-1.1567-1.15668-3.0392 0-4.1959.56043-.5604 1.30573-.8688 2.09792-.8688.79223 0 1.53746.3083 2.09792.8688.56043.5605.86878 1.3058.86878 2.0979 0 .7923-.30827 1.5375-.86878 2.098l1.4384 1.4384C10.4794 21.0908 11 19.8351 11 18.4989c.0001-1.3363-.5206-2.5922-1.46484-3.5365Zm-7.42094 2.3521c.16436 0 .2981.1337.2981.2981 0 .1643-.13373.2981-.2981.2981-.16436 0-.29809-.1338-.29809-.2981 0-.1644.13373-.2981.29809-.2981Zm-.37364 1.5824c0-.1644.13373-.2981.2981-.2981.16436 0 .2981.1337.2981.2981 0 .1643-.13374.2981-.2981.2981-.16437 0-.2981-.1338-.2981-.2981Zm.64724 1.5824c-.16435 0-.2981-.1338-.2981-.2981 0-.1644.13374-.2981.2981-.2981.16437 0 .2981.1337.2981.2981 0 .1643-.13373.2981-.2981.2981Zm7.4975-3.1648c.16438 0 .29808.1337.29808.2981 0 .1644-.1337.2981-.29808.2981-.16437 0-.2981-.1337-.2981-.2981 0-.1644.13267-.2981.2981-.2981Zm-.27772 3.1648c-.16435 0-.2981-.1338-.2981-.2981 0-.1644.13374-.2981.2981-.2981.16437 0 .2981.1337.2981.2981.00007.1643-.13366.2981-.2981.2981Zm.35635-1.2843c-.16436 0-.2981-.1338-.2981-.2981 0-.1644.13373-.2981.2981-.2981.16435 0 .29805.1337.29805.2981 0 .1643-.1337.2981-.29805.2981Z"
        fill="currentColor"
        fillOpacity=".2"
      />
      <path
        d="M21.5352 2.96242c-1.9499-1.94989-5.1229-1.94989-7.0728 0-1.9499 1.94989-1.9499 5.12284 0 7.07278l1.4384-1.43844c-1.1567-1.15668-1.1567-3.03914 0-4.19584.5604-.56043 1.3057-.86877 2.0979-.86877.7923 0 1.5375.30827 2.098.86877.5604.56043.8687 1.30573.8687 2.09792 0 .79223-.3082 1.53746-.8687 2.09792l1.4383 1.43844C22.4794 9.09082 23 7.83515 23 6.49888c.0001-1.33629-.5206-2.59214-1.4648-3.53646Zm-7.421 2.35205c.1644 0 .2981.13373.2981.2981 0 .16436-.1337.2981-.2981.2981-.1643 0-.2981-.13374-.2981-.2981 0-.16437.1338-.2981.2981-.2981Zm-.3736 1.58239c0-.16436.1337-.2981.2981-.2981.1643 0 .2981.13374.2981.2981 0 .16436-.1338.2981-.2981.2981-.1644 0-.2981-.13374-.2981-.2981Zm.6472 1.58239c-.1643 0-.2981-.13373-.2981-.2981 0-.16436.1338-.2981.2981-.2981.1644 0 .2981.13374.2981.2981 0 .16437-.1337.2981-.2981.2981Zm7.4975-3.16475c.1644 0 .2981.13374.2981.2981 0 .16437-.1337.2981-.2981.2981-.1643 0-.2981-.13373-.2981-.2981 0-.16436.1327-.2981.2981-.2981Zm-.2777 3.16475c-.1644 0-.2981-.13373-.2981-.2981 0-.16436.1337-.2981.2981-.2981.1644 0 .2981.13374.2981.2981.0001.16436-.1337.2981-.2981.2981Zm.3563-1.28429c-.1643 0-.2981-.13374-.2981-.2981 0-.16436.1338-.2981.2981-.2981.1644 0 .2981.13374.2981.2981 0 .16436-.1337.2981-.2981.2981Z"
        fill="currentColor"
      />
      <path
        d="M21.5352 14.9624c-1.9499-1.9499-5.1229-1.9499-7.0728 0-1.9499 1.9499-1.9499 5.1229 0 7.0728l1.4384-1.4384c-1.1567-1.1567-1.1567-3.0392 0-4.1959.5604-.5604 1.3057-.8688 2.0979-.8688.7923 0 1.5375.3083 2.098.8688.5604.5605.8687 1.3058.8687 2.0979 0 .7923-.3082 1.5375-.8687 2.098l1.4383 1.4384c.9444-.9444 1.465-2.2001 1.465-3.5363.0001-1.3363-.5206-2.5922-1.4648-3.5365Zm-7.421 2.3521c.1644 0 .2981.1337.2981.2981 0 .1643-.1337.2981-.2981.2981-.1643 0-.2981-.1338-.2981-.2981 0-.1644.1338-.2981.2981-.2981Zm-.3736 1.5824c0-.1644.1337-.2981.2981-.2981.1643 0 .2981.1337.2981.2981 0 .1643-.1338.2981-.2981.2981-.1644 0-.2981-.1338-.2981-.2981Zm.6472 1.5824c-.1643 0-.2981-.1338-.2981-.2981 0-.1644.1338-.2981.2981-.2981.1644 0 .2981.1337.2981.2981 0 .1643-.1337.2981-.2981.2981Zm7.4975-3.1648c.1644 0 .2981.1337.2981.2981 0 .1644-.1337.2981-.2981.2981-.1643 0-.2981-.1337-.2981-.2981 0-.1644.1327-.2981.2981-.2981Zm-.2777 3.1648c-.1644 0-.2981-.1338-.2981-.2981 0-.1644.1337-.2981.2981-.2981.1644 0 .2981.1337.2981.2981.0001.1643-.1337.2981-.2981.2981Zm.3563-1.2843c-.1643 0-.2981-.1338-.2981-.2981 0-.1644.1338-.2981.2981-.2981.1644 0 .2981.1337.2981.2981 0 .1643-.1337.2981-.2981.2981Z"
        fill="currentColor"
        fillOpacity=".2"
      />
    </svg>
  );
};
export default FerriDavanti;
