/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\preferred-off-bck.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const PreferredOffBck = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-preferred-off-bck', className].join(' ');
  return (
    <svg id="preferred-off-bck" viewBox="0 0 48 48" focusable="false" className={componentClassName} {...attribs}>
      <path
        d="M24 38C27.3667 34.9 30.1417 32.2417 32.325 30.025C34.5083 27.8083 36.2417 25.8667 37.525 24.2C38.8083 22.5333 39.7083 21.05 40.225 19.75C40.7417 18.45 41 17.15 41 15.85C41 13.65 40.3 11.8417 38.9 10.425C37.5 9.00832 35.7 8.29999 33.5 8.29999C31.8 8.29999 30.2167 8.82499 28.75 9.87499C27.2833 10.925 26.1 12.4 25.2 14.3H22.75C21.8833 12.4333 20.7167 10.9667 19.25 9.89999C17.7833 8.83332 16.2 8.29999 14.5 8.29999C12.3 8.29999 10.5 9.00832 9.1 10.425C7.7 11.8417 7 13.65 7 15.85C7 17.15 7.25833 18.4583 7.775 19.775C8.29167 21.0917 9.19167 22.5917 10.475 24.275C11.7583 25.9583 13.5 27.9 15.7 30.1C17.9 32.3 20.6667 34.9333 24 38Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default PreferredOffBck;
