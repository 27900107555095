import { createAsyncThunk } from '@reduxjs/toolkit';
import { extendedApiIppica } from 'features/api/ippicaApiSlice';
import {
  venditaAlertFailedMessageErrorAdded,
  venditaAlertFailedOpenChange,
  venditaAlertSuccessOpenChange,
} from 'features/carrello/carrelloSlice';
import {
  resetCampiCavalliMatrix,
  resetCampiMatrixSelected,
  resetColumnAndRowCavalloSelected,
} from 'features/ippica/ippicaSlice';
import { RootState } from 'lib/centralStore';
import { CartVenditaResponseDto, IppicaQuoteCartDto } from 'types/swagger';
import { selectEsiti } from './ippicaTicketSelectors';
import {
  erroriAdded,
  quoteUpdated,
  ticketRemoved,
  ticketSaved,
  ticketUpdated,
  ticketUpdatedBySistemi,
} from './ippicaTicketSlice';
import { TicketErroreVendita } from 'features/carrello/types';

export type EsitoInErrore = {
  esitiSospesi: string[];
  esitiNonSospesi: string[];
};

export const getUpdatedQuote = createAsyncThunk('getUpdatedQuote', async (_, { getState, dispatch }) => {
  const state = getState() as RootState;
  const esiti = selectEsiti(state);

  if (Object.keys(esiti).length > 0) {
    await dispatch(
      extendedApiIppica.endpoints.getUpdateQuote.initiate(esiti, {
        subscriptionOptions: {
          pollingInterval: 60 * 1000,
        },
      })
    );
  }
  return Promise.resolve();
});

export const venditaIppica = createAsyncThunk(
  'venditaIppica',
  async (response: CartVenditaResponseDto, { dispatch }) => {
    switch (response.statusCodeResponse) {
      case 1024:
        dispatch(ticketSaved());
        dispatch(ticketRemoved());
        dispatch(resetCampiMatrixSelected());
        dispatch(resetCampiCavalliMatrix());
        dispatch(resetColumnAndRowCavalloSelected());
        dispatch(venditaAlertSuccessOpenChange(true));
        break;
      case 1030:
        if (response?.esitoMap) {
          const { esitoMap } = response;
          dispatch(updateQuoteFromApi(esitoMap));
        } else {
          console.error('response or esitoMap is missing');
        }
        dispatch(
          erroriAdded({
            tipoErrore: 'erroriVendita',
            errore: TicketErroreVendita.QuoteCambiate,
          })
        );
        break;
      default:
        if (response.errore) {
          dispatch(venditaAlertFailedMessageErrorAdded(response.errore));
          dispatch(venditaAlertFailedOpenChange(true));
        }
    }
  }
);

export const updateQuoteFromApi = createAsyncThunk(
  'updateQuoteFromApi',
  async (esitoMap: IppicaQuoteCartDto['esitoMap'], { dispatch }) => {
    dispatch(
      quoteUpdated({
        quoteToUpdate: esitoMap,
      })
    );
    dispatch(updateIppicaTicket());
    return Promise.resolve();
  }
);

export const updateIppicaTicket = createAsyncThunk(
  'updateCalcoloTicketWithBonus',
  async (_, { getState, dispatch }) => {
    const { ippicaTicket } = getState() as RootState;
    const { sistemiDaGiocare, ticket, puntataSingolaMultipla } = ippicaTicket;
    const { sistema } = ticket!;
    if (ticket) {
      if (sistema) {
        dispatch(ticketUpdatedBySistemi(sistemiDaGiocare));
      } else {
        dispatch(ticketUpdated({ puntata: puntataSingolaMultipla }));
      }
    }
  }
);
