import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CashoutResponseApi } from 'lib/api/cashoutResponseApi';
import { RootState } from 'lib/centralStore';
import { MyBet } from 'types/dashboard/myBet';
// Define a type for the slice state
export interface MyBetState {
  selectedBet?: MyBet;
  ticketCashout?: TicketCashoutType;
  idsRealTimeCashout: string[];
  blockedIdsRealTimeCashout: CashoutResponseApiWithTimer[];
}

export type CashoutResponseApiWithTimer = CashoutResponseApi & { timer?: NodeJS.Timeout };

export type TicketCashoutType = {
  idBiglietto?: string;
  importo?: number;
};

// Define the initial state using that type
export const initialState: MyBetState = {
  idsRealTimeCashout: [],
  blockedIdsRealTimeCashout: [],
};

export const myBetSlice = createSlice({
  name: 'myBet',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedBet: (state, action: PayloadAction<MyBet | undefined>) => {
      state.selectedBet = action.payload;
    },
    setTicketCashout: (state, action: PayloadAction<TicketCashoutType>) => {
      state.ticketCashout = action.payload;
    },
    setIdsRealTimeCashout: (state, action: PayloadAction<string[]>) => {
      state.idsRealTimeCashout = action.payload;
    },
    setBlockedIdsRealTimeCashout: (state, action: PayloadAction<CashoutResponseApiWithTimer[]>) => {
      state.blockedIdsRealTimeCashout = action.payload;
    },
  },
});

export const { setSelectedBet, setTicketCashout, setIdsRealTimeCashout, setBlockedIdsRealTimeCashout } =
  myBetSlice.actions;

export default myBetSlice.reducer;

export const selectTicketCashout = (state: RootState) => state.myBets.ticketCashout;
