/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-105.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco105 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-105', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M315.012,485.061l2.152-1.345a4.593,4.593,0,0,0,2.23-4.021,3.7,3.7,0,1,0-7.392,0,.739.739,0,0,0,1.478,0,2.218,2.218,0,0,1,4.435,0,3.16,3.16,0,0,1-1.534,2.766l-2.153,1.346A4.773,4.773,0,0,0,312,487.827v.739a.739.739,0,0,0,.739.739h5.914a.739.739,0,1,0,0-1.478h-5.175A3.286,3.286,0,0,1,315.012,485.061Zm12.513-.191h-.739v-8.131a.739.739,0,0,0-1.354-.41l-5.914,8.871a.739.739,0,0,0,.615,1.149h5.174v2.218a.739.739,0,1,0,1.478,0v-2.218h.739a.739.739,0,1,0,0-1.478Zm-2.218,0h-3.794l3.794-5.69Z"
        transform="translate(-298.694 -465.651)"
        fill="currentColor"
      />
      <g transform="translate(0 11.827)">
        <path
          className="a"
          d="M313.833,504.612c-3.776,0-8.723-2.436-13.06-6.773-6.316-6.316-8.6-13.927-5.2-17.327a23.863,23.863,0,0,1,2.7-2.153,1.954,1.954,0,0,1,2.782.554L304.7,484.7a1.955,1.955,0,0,1-.648,2.719l-2.137,1.282a.487.487,0,0,0-.227.316.459.459,0,0,0,.073.361,36.867,36.867,0,0,0,7.477,7.477.458.458,0,0,0,.362.073.489.489,0,0,0,.317-.226l1.282-2.138a1.955,1.955,0,0,1,2.719-.648l5.784,3.646a1.952,1.952,0,0,1,.553,2.781,23.649,23.649,0,0,1-2.153,2.7A5.84,5.84,0,0,1,313.833,504.612ZM299.4,479.478a.468.468,0,0,0-.273.088,22.494,22.494,0,0,0-2.507,1.991c-2.72,2.72-.339,9.7,5.2,15.237s12.518,7.921,15.238,5.2a22.337,22.337,0,0,0,1.991-2.508.464.464,0,0,0,.079-.365.471.471,0,0,0-.214-.311l-5.784-3.646a.477.477,0,0,0-.663.159l-1.283,2.137a1.971,1.971,0,0,1-1.279.912,1.942,1.942,0,0,1-1.52-.313,38.265,38.265,0,0,1-7.831-7.831,1.947,1.947,0,0,1-.312-1.52,1.97,1.97,0,0,1,.913-1.279l2.136-1.282a.478.478,0,0,0,.159-.663L299.8,479.7a.471.471,0,0,0-.311-.214A.439.439,0,0,0,299.4,479.478Z"
          transform="translate(-294 -478)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(5.029 0)">
        <path
          className="a"
          d="M324.587,495.407a.739.739,0,0,1-.355-1.387,16.254,16.254,0,1,0-22.039-22.032.739.739,0,0,1-1.3-.706,17.732,17.732,0,1,1,24.046,24.036A.733.733,0,0,1,324.587,495.407Z"
          transform="translate(-300.803 -462)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco105;
