/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\snai-runner.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const SnaiRunner = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-snai-runner', className].join(' ');
  return (
    <svg
      id="snai-runner"
      width="109"
      height="24"
      viewBox="0 0 109 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66 4H60L55 23.5H61L63 15.5L65.5 23.5H71.5L77.5 0H71L68 4.5H70.5L68.5 13L66 4ZM47 4H41.5L36.5 23.5H42.5L44.5 15L47 23.5H52.5L57.5 4H51.5L49.5 13L47 4ZM92.5 4H79L74 23.5H87.5L88.5 19H81L82 16H87.5L88.5 12H83L83.5 9H91L92.5 4ZM22.999 4H28.999L25.499 17C24.999 20 29 19.5 29.499 17L32.499 4H38.499L34.999 19C33.0326 25.3948 19.4503 25.4983 19.4861 19.3106C19.4873 19.107 19.5223 18.9004 19.5685 18.7022L22.999 4ZM0 23.5L5 4H12.1965C12.7298 4 13.2709 4.06581 13.7767 4.23472C23.5864 7.51048 15.4754 17.5123 14.5 18L15.5 23.5H9L8.5 19.5H7L6 23.5H0ZM8.5 14.5L10 9H12C13.5 9.5 12.5 14 10.5 14.5H8.5ZM95 4L90 23.5H96L97 19.5H98.5L99 23.5H105.5L104.5 18C105.475 17.5123 113.586 7.51048 103.777 4.23472C103.271 4.06581 102.73 4 102.196 4H95ZM100 9L98.5 14.5H100.5C102.5 14 103.5 9.5 102 9H100Z"
        fill="#E96105"
      />
    </svg>
  );
};
export default SnaiRunner;
