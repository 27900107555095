/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-68.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina68 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-68', className].join(' ');
  return (
    <svg
      id="disciplina_eventi-cronaca-e-costume"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M23.144 3.681h-2.758a.29.29 0 0 0-.264.173.277.277 0 0 0-.021.108v15.714a.511.511 0 0 0 .15.365c.097.098.23.153.368.155.468 0 .922.16 1.282.456.36.294.602.704.687 1.157a.28.28 0 0 0 .197.222.29.29 0 0 0 .291-.074A3.276 3.276 0 0 0 24 19.682V4.522a.834.834 0 0 0-.25-.594.863.863 0 0 0-.606-.247Zm-2.519 17.636c-.441 0-.864-.174-1.177-.48a1.625 1.625 0 0 1-.488-1.157V.841a.83.83 0 0 0-.25-.595.856.856 0 0 0-.607-.246H.856A.863.863 0 0 0 .25.246.834.834 0 0 0 0 .841v18.841c.001.88.358 1.723.99 2.345A3.41 3.41 0 0 0 3.375 23h17.25a.864.864 0 0 0 .605-.247.835.835 0 0 0 0-1.19.863.863 0 0 0-.605-.246ZM7.72 18.552H3.925a.579.579 0 0 1-.404-.165.56.56 0 0 1-.167-.396v-3.08a.552.552 0 0 1 .167-.397.571.571 0 0 1 .404-.164h3.794c.151 0 .296.06.403.164a.556.556 0 0 1 .167.397v3.083c0 .149-.06.291-.167.396a.575.575 0 0 1-.403.164l.001-.002Zm7.316 0H10.74a.576.576 0 0 1-.394-.17.556.556 0 0 1 0-.783.576.576 0 0 1 .393-.17h4.295a.58.58 0 0 1 .414.159.562.562 0 0 1 .173.402.554.554 0 0 1-.173.403.573.573 0 0 1-.414.159h.002Zm0-3.083H10.74a.576.576 0 0 1-.394-.17.557.557 0 0 1 0-.782.576.576 0 0 1 .393-.17h4.295a.582.582 0 0 1 .414.158.56.56 0 0 1 .173.403.553.553 0 0 1-.173.402.573.573 0 0 1-.414.159h.002Zm0-3.083H3.926a.575.575 0 0 1-.393-.17.556.556 0 0 1 0-.782.575.575 0 0 1 .393-.17h11.11a.58.58 0 0 1 .414.159.562.562 0 0 1 .173.402.554.554 0 0 1-.173.403.573.573 0 0 1-.414.158Zm.57-3.643a.553.553 0 0 1-.167.396.571.571 0 0 1-.403.165H3.926a.58.58 0 0 1-.403-.165.56.56 0 0 1-.167-.396v-4.22c0-.149.06-.291.166-.397a.575.575 0 0 1 .403-.165h11.11c.15 0 .296.06.403.165a.557.557 0 0 1 .167.397v4.222l.002-.002Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina68;
