/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\cuori.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Cuori = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-cuori', className].join(' ');
  return (
    <svg
      id="cuori"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M17.2231 14.5691C15.3518 16.0262 13.2967 17.6264 12.1072 20.0293C12.0952 20.0536 12.0754 20.0744 12.0492 20.0873C11.986 20.1185 11.9094 20.0925 11.8782 20.0293C10.6787 17.6061 8.62195 16.0141 6.75219 14.5667C4.51765 12.837 2.54541 11.3104 2.54541 8.80404C2.54541 7.4333 3.10103 6.19231 3.99933 5.294C4.89763 4.3957 6.13862 3.84009 7.50936 3.84009C8.52003 3.84009 9.46066 4.14274 10.2491 4.66094C10.9898 5.14778 11.5955 5.82469 11.998 6.61935C12.4018 5.82128 13.0057 5.14521 13.7427 4.65964C14.5299 4.14105 15.4682 3.84009 16.4761 3.84009C17.8468 3.84009 19.0878 4.3957 19.9861 5.294C20.8844 6.19231 21.44 7.4333 21.44 8.80404C21.44 11.2855 19.4606 12.8269 17.2231 14.5691Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Cuori;
