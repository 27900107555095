/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-402.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco402 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-402', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="32.526"
      viewBox="0 0 38.439 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M500.192,214.188a.739.739,0,0,0-.743-.144L462.489,227.35a.739.739,0,0,0-.13,1.329l11.469,6.882v8.747a.74.74,0,0,0,1.193.584l6.253-4.864,10.654,6.392a.739.739,0,0,0,1.1-.463l7.392-31.047A.738.738,0,0,0,500.192,214.188Zm-8.369,30.445-10.2-6.123-.036-.021-2.2-1.319a.739.739,0,0,0-.761,1.268l1.293.775-4.608,3.584v-7.29l16.711-12.788a.739.739,0,1,0-.9-1.174l-16.6,12.7-10.061-6.036L498.664,215.9Z"
        transform="translate(-462 -214)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco402;
