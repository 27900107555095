/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-79.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco79 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-79', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="32.526"
      viewBox="0 0 38.439 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <g transform="translate(13.306 11.828)">
          <path
            className="a"
            d="M233.914,409.827a5.914,5.914,0,1,1,5.914-5.914A5.921,5.921,0,0,1,233.914,409.827Zm0-10.349a4.435,4.435,0,1,0,4.435,4.435A4.44,4.44,0,0,0,233.914,399.478Z"
            transform="translate(-228 -398)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M229.22,382c-10.778,0-19.22,4.513-19.22,10.274a28.632,28.632,0,0,0,.466,4.146,2.2,2.2,0,0,0,.952,1.468,2.231,2.231,0,0,0,1.724.32l3.7-.839-2.054,8.9a1.113,1.113,0,0,1-1.09.866,2.22,2.22,0,0,0-2.218,2.218v2.957a2.22,2.22,0,0,0,2.218,2.218h31.047a2.22,2.22,0,0,0,2.218-2.218v-2.957a2.22,2.22,0,0,0-2.218-2.218h0a1.112,1.112,0,0,1-1.088-.865l-2.054-8.9,3.7.839a2.208,2.208,0,0,0,.487.054,2.232,2.232,0,0,0,1.237-.374,2.2,2.2,0,0,0,.952-1.468,28.632,28.632,0,0,0,.466-4.146C248.439,386.513,240,382,229.22,382Zm15.521,26.612h0a.74.74,0,0,1,.739.739v2.957a.74.74,0,0,1-.739.739H213.7a.74.74,0,0,1-.739-.739v-2.957a.74.74,0,0,1,.741-.739,2.584,2.584,0,0,0,2.529-2.011l3.97-17.208,3.108-.021a2.958,2.958,0,0,0,2.957,2.978h5.914a2.958,2.958,0,0,0,2.957-2.957h3.108l3.972,17.209A2.582,2.582,0,0,0,244.741,408.612Zm1.774-12.442a.73.73,0,0,1-.317.489.721.721,0,0,1-.574.106l-4.393-1-1.549-6.709a1.471,1.471,0,0,0-1.44-1.146h-3.108a1.5,1.5,0,0,0-1.057.44,1.436,1.436,0,0,0-.421,1.028,1.479,1.479,0,0,1-1.478,1.489h-5.914a1.479,1.479,0,0,1-1.478-1.489,1.436,1.436,0,0,0-.421-1.028,1.5,1.5,0,0,0-1.057-.44H220.2a1.471,1.471,0,0,0-1.44,1.146l-1.549,6.709-4.392,1a.739.739,0,0,1-.892-.6,27.3,27.3,0,0,1-.446-3.9c0-4.768,8.125-8.8,17.741-8.8s17.741,4.027,17.741,8.8A27.3,27.3,0,0,1,246.515,396.17Z"
          transform="translate(-210 -382)"
          fill="currentColor"
        />
        <g transform="translate(7.392 25.133)">
          <path
            className="a"
            d="M242.916,417.478H220.739a.739.739,0,1,1,0-1.478h22.177a.739.739,0,1,1,0,1.478Z"
            transform="translate(-220 -416)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco79;
