/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-158.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina158 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-158', className].join(' ');
  return (
    <svg
      id="disciplina_tennis-virtual"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M12.0088 3.00006c-.4406.85454-.9891 1.63712-1.6545 2.32974-.37771.39579-.78236.7466-1.21399 1.12439-.42263.3688-.86325.7466-1.2679 1.16937-1.10605 1.16037-1.70853 2.68054-1.67256 4.28164.03597 1.6012.68341 3.0944 1.84341 4.2098 1.12403 1.0794 2.59874 1.673 4.15444 1.673h.1259c1.6006-.0359 3.0933-.6836 4.2083-1.844.4047-.4227.7644-.8725 1.1241-1.3132.3596-.4498.6924-.8726 1.07-1.2594.6655-.6926 1.4208-1.2683 2.2661-1.745.0989 2.3657-.7284 4.7674-2.4999 6.6114-3.444 3.5801-9.13607 3.697-12.71498.2519-3.59689-3.4542-3.71379-9.14807-.26977-12.72813 1.77147-1.844 4.13643-2.7705 6.50135-2.76151ZM9.87768 7.29973c-.42264.3688-.82729.71961-1.19597 1.09741-.89922.93549-1.3848 2.16786-1.35783 3.47216.02698 1.3043.55752 2.5186 1.49271 3.4181.9352.8995 2.16711 1.3853 3.47101 1.3583 1.3039-.027 2.5178-.5577 3.417-1.4932.3687-.3868.7014-.8006 1.0521-1.2413.3597-.4498.7284-.9086 1.1421-1.3403.8542-.8815 1.8434-1.6101 2.9404-2.1678-.3237-1.80807-1.196-3.53513-2.6167-4.90239-1.4208-1.37625-3.1833-2.17682-4.9997-2.42868-.5126 1.12439-1.205 2.14084-2.0502 3.02236-.4137.44076-.8633.82755-1.29492 1.20534Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina158;
