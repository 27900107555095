import { AnyAction, createAsyncThunk, ThunkAction } from '@reduxjs/toolkit';
import { tipoCarrelloSelected } from 'features/carrello/carrelloSlice';
import { CarrelloTipo } from 'features/carrello/types';
import { UpdateProssimiEventiPayload } from 'lib/api/sport/prossimiEventiResponseApi';
import { ScommessaResponse } from 'lib/api/sport/sportScommesseBySlugResponse';
import { RootState } from 'lib/centralStore';
import { SportsCacheScommesseCoreDto, sportsCli, SportsRemoveAvvenimentoSignalREvent } from 'types/swagger';
import { dynamicDataLoader, isTruthy } from 'utility/functions';
import raise from 'utility/raise';
import { scommessaAdded } from './components/sportTicket/sportTicketSlice';
import { resetMenuManifestazioniFilters, setIsResettedFilter } from './sportSlice';
import { getProssimiEventiProps, ProssimiEventiStateType, ScommessaAddedPayload } from './types';
import { KeyManagerSport } from './utils/keyManager';
import { feedLingUI } from 'hooks/useLingUI';

export const addToSportCartByKey =
  ({
    esitoKey,
    pathScommessa,
  }: {
    esitoKey: string;
    pathScommessa: string[];
  }): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const store = getState();

    const { avvenimentoList, scommessaMap, infoTipoScommessaMap, esitoMap } = dynamicDataLoader<
      RootState,
      ScommessaResponse
    >(store, pathScommessa, false);

    const keyManager = new KeyManagerSport(esitoKey);
    const { avvenimentoKey } = keyManager;

    const esito = esitoMap[esitoKey];
    const avvenimento =
      avvenimentoList.find((avv) => avv.key === avvenimentoKey) ??
      raise(`No avvenimento for given id ${avvenimentoKey}`);

    if (esito.splitInfoAggiuntiva) {
      keyManager.scommessaKey = `${keyManager.scommessaKey}_${esito.splitInfoAggiuntiva}`;
      keyManager.tipoScommessaId = `${keyManager.tipoScommessaId}_${esito.splitInfoAggiuntiva}`;
    }
    const { scommessaKey, tipoScommessaId } = keyManager;

    const scommessa = scommessaMap[scommessaKey];
    const { isAntepost } = infoTipoScommessaMap[tipoScommessaId];

    const {
      key,
      ora,
      data,
      dataOra,
      idProgramma,
      idDisciplina,
      idAvvenimento,
      slugDisciplina,
      idManifestazione,
      slugManifestazione,
      descrizioneTrKey: descrizioneAvvenimentoTrKey,
      live: liveAvvenimento,
      descrizioneManifestazione,
      descrizioneManifestazioneTrKey,
    } = avvenimento;

    const {
      multipla,
      descrizione,
      minCombinazioni,
      maxCombinazioni,
      idTipoScommessa,
      idTipoInfoAggiuntiva,
      descrizioneAvvenimento,
    } = scommessa;

    const {
      quota,
      idEsito,
      isActive,
      descrizione: descrizioneEsito,
      descrizioneTrKey: descrizioneEsitoTrKey,
      infoAggiuntiva,
      descrizioneTipoScommessaWithInfoAgg,
      descrizioneTipoScommessaWithInfoAggTrKey,
    } = esito;

    const ticketAvvenimento = {
      key,
      ora,
      data,
      dataOra,
      live: liveAvvenimento,
      idProgramma,
      idDisciplina,
      idAvvenimento,
      descrizioneAvvenimento,
      descrizioneTrKey: descrizioneAvvenimentoTrKey,
      slugDisciplina,
      idManifestazione,
      slugManifestazione,
      descrizioneManifestazione,
      descrizioneManifestazioneTrKey,
    };

    const ticketScommessa = {
      multipla,
      isAntepost,
      descrizione,
      minCombinazioni,
      maxCombinazioni,
      idTipoScommessa,
      idTipoInfoAggiuntiva,
    };

    const ticketEsito = {
      id: esitoKey,
      quota,
      idEsito,
      isActive,
      descrizione: descrizioneEsito,
      descrizioneTrKey: descrizioneEsitoTrKey,
      infoAggiuntiva,
      descrizioneTipoScommessaWithInfoAgg,
      descrizioneTipoScommessaWithInfoAggTrKey,
    };

    dispatch(scommessaAdded({ ticketAvvenimento, ticketScommessa, ticketEsito } as ScommessaAddedPayload));
    dispatch(tipoCarrelloSelected(CarrelloTipo.Sport));
    return Promise.resolve();
  };

export const removeAvvenimento = createAsyncThunk(
  'removeAvvenimento',
  async (
    props: SportsRemoveAvvenimentoSignalREvent & { updateProssimiEventi?: UpdateProssimiEventiPayload }
  ): Promise<SportsRemoveAvvenimentoSignalREvent & { updateProssimiEventi?: UpdateProssimiEventiPayload }> => {
    return Promise.resolve(props);
  }
);

export const resetFiltersSelected = createAsyncThunk('resetFiltersSelected', async (_, { dispatch }) => {
  dispatch(setIsResettedFilter(true));
  await Promise.resolve(dispatch(setIsResettedFilter(true)));
  dispatch(resetMenuManifestazioniFilters());
});

// replace initTemplateProssimiEventi
// TODO: ma non sarebbe meglio propagare il modello "pulito" come arriva da MW?
export const getProssimiEventi = createAsyncThunk(
  'getProssimiEventi',
  async ({ slug }: getProssimiEventiProps): Promise<ProssimiEventiStateType> => {
    let { ok, data, error } = await sportsCli.sports.prossimiEventiList({ slug: slug?.toLowerCase() });

    if (isTruthy(ok)) {
      const { scommesse, tabList } = data ?? {};
      const { traduzioneMap, ...oth } = scommesse ?? {};

      const traduzione = Reflect.get(data ?? {}, 'traduzioneMap');
      const messages = Object.assign({}, traduzione, traduzioneMap);

      feedLingUI(messages);

      return Promise.resolve({ tabList, ...oth } as unknown as ProssimiEventiStateType);
    }
    return Promise.reject(error);
  }
);

export const getScommesseCustomPreMatch = createAsyncThunk(
  'getScommesseCustomPreMatch',
  async (slug: string): Promise<ScommessaResponse> => {
    const result = await sportsCli.sports.scommesseCustomSectionList({ slug });

    if (isTruthy(result.ok)) {
      const { traduzioneMap, ...payload } = result.data;

      feedLingUI(traduzioneMap);

      return Promise.resolve({ ...payload, slug } as unknown as ScommessaResponse);
    }

    return Promise.reject();
  }
);
