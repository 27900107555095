/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-241.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco241 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-241', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.569"
      height="38.439"
      viewBox="0 0 29.569 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 16.263)">
        <path
          className="a"
          d="M492.394,170.177h-19.22A5.181,5.181,0,0,1,468,165V153.175A5.181,5.181,0,0,1,473.174,148h19.22a5.181,5.181,0,0,1,5.174,5.175V165A5.181,5.181,0,0,1,492.394,170.177Zm-19.22-20.7a3.7,3.7,0,0,0-3.7,3.7V165a3.7,3.7,0,0,0,3.7,3.7h19.22a3.7,3.7,0,0,0,3.7-3.7V153.175a3.7,3.7,0,0,0-3.7-3.7Z"
          transform="translate(-468 -148)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(4.435 0)">
        <path
          className="a"
          d="M493.959,143.741a.739.739,0,0,1-.739-.739v-6.653a8.871,8.871,0,1,0-17.741,0V143A.739.739,0,1,1,474,143v-6.653a10.349,10.349,0,1,1,20.7,0V143A.739.739,0,0,1,493.959,143.741Z"
          transform="translate(-474 -126)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M489.914,160.957a2.957,2.957,0,1,0-3.7,2.852v2.323a.739.739,0,1,0,1.478,0v-2.323A2.954,2.954,0,0,0,489.914,160.957Zm-2.957,1.478a1.478,1.478,0,1,1,1.478-1.478A1.48,1.48,0,0,1,486.957,162.435Z"
        transform="translate(-472.173 -134.345)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco241;
