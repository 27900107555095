/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-27.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina27 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-27', className].join(' ');
  return (
    <svg
      id="disciplina_salto-con-gli-sci"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M21.94 13.008a.557.557 0 0 0-.327.727c.48 1.294-2.425 2.864-3.905 3.422l-6.124 2.297 3.812-5.602a2.143 2.143 0 0 0 1.317-1.153l2.639-5.766A2.154 2.154 0 0 0 18.3 4.088a2.112 2.112 0 0 0-2.125.201l-5.96 1.725a.85.85 0 0 0-.599 1.002.853.853 0 0 0 .958.662l4.003-.59-1.747 3.82a2.122 2.122 0 0 0-.145 1.35l-3.368 6.02a1.285 1.285 0 0 0 .393 1.678c.07.048.145.088.224.122l-7.572 2.83a.563.563 0 0 0-.327.727.563.563 0 0 0 .724.329l15.34-5.757c.056-.023 1.34-.506 2.541-1.284 2.238-1.453 2.336-2.752 2.027-3.586a.57.57 0 0 0-.728-.329ZM20.67 0a2.325 2.325 0 0 1 2.153 1.444 2.347 2.347 0 0 1-.506 2.55 2.328 2.328 0 0 1-3.979-1.655A2.343 2.343 0 0 1 19.777.177C20.06.06 20.363 0 20.669 0Z"
      />
    </svg>
  );
};
export default Disciplina27;
