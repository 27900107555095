/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\legenda-tris-dark.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const LegendaTrisDark = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-legenda-tris-dark', className].join(' ');
  return (
    <svg
      id="legenda-tris-dark"
      width="100%"
      height="100%"
      viewBox="0 0 5 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M.518571 10H1.88878l1.22128-5.67943h1.45957L4.84765 3H.558288L.280273 4.32057H1.72992L.518571 10Z"
        fill="#fff"
      />
    </svg>
  );
};
export default LegendaTrisDark;
