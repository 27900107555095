/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-208.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco208 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-208', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.655"
      height="38.439"
      viewBox="0 0 23.655 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M143.7,474h-2.957a.739.739,0,0,0-.739.739V477.7a.739.739,0,1,0,1.478,0v-2.218H143.7a.739.739,0,1,0,0-1.478Zm0,16.263h-2.218v-2.218a.739.739,0,0,0-1.478,0V491a.739.739,0,0,0,.739.739H143.7a.739.739,0,1,0,0-1.478ZM157,474h-2.957a.739.739,0,1,0,0,1.478h2.218V477.7a.739.739,0,1,0,1.478,0v-2.957A.739.739,0,0,0,157,474Zm0,13.306a.739.739,0,0,0-.739.739v2.218h-2.218a.739.739,0,1,0,0,1.478H157a.739.739,0,0,0,.739-.739v-2.957A.739.739,0,0,0,157,487.306Z"
        transform="translate(-137.043 -465.129)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M156.7,462H138.957A2.96,2.96,0,0,0,136,464.957v32.526a2.96,2.96,0,0,0,2.957,2.957H156.7a2.96,2.96,0,0,0,2.957-2.957V464.957A2.96,2.96,0,0,0,156.7,462Zm-19.22,5.914h20.7v25.133h-20.7Zm1.478-4.435H156.7a1.48,1.48,0,0,1,1.478,1.478v1.478h-20.7v-1.478A1.48,1.48,0,0,1,138.957,463.478ZM156.7,498.961H138.957a1.48,1.48,0,0,1-1.478-1.478v-2.957h20.7v2.957A1.48,1.48,0,0,1,156.7,498.961Z"
        transform="translate(-136 -462)"
        fill="currentColor"
      />
      <g transform="translate(9.61 34.004)">
        <path
          className="a"
          d="M152.7,509.478h-2.957a.739.739,0,1,1,0-1.478H152.7a.739.739,0,1,1,0,1.478Z"
          transform="translate(-149 -508)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(11.088 2.227)">
        <path
          className="a"
          d="M151.739,466.482a.736.736,0,0,1-.281-.059.854.854,0,0,1-.244-.155.732.732,0,0,1-.155-.806.853.853,0,0,1,.155-.244.774.774,0,0,1,1.05,0,.762.762,0,0,1,.155.244.7.7,0,0,1,0,.562.865.865,0,0,1-.155.244.854.854,0,0,1-.244.155A.736.736,0,0,1,151.739,466.482Z"
          transform="translate(-151 -465.013)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M149.914,481.7v-2.033a.185.185,0,0,1,.185-.185h.515a.765.765,0,0,0,.768-.616.739.739,0,0,0-.729-.863h-1.478a.739.739,0,0,0-.739.739v2.033a.185.185,0,0,1-.185.185h-.515a.767.767,0,0,0-.769.616.74.74,0,0,0,.73.863h1.478A.739.739,0,0,0,149.914,481.7Zm5.05.75a.766.766,0,0,0-.615.768v1.994a.185.185,0,0,1-.185.185h-.554a.739.739,0,0,0-.739.739v2.033a.185.185,0,0,1-.185.185h-.515a.767.767,0,0,0-.769.616.74.74,0,0,0,.73.863h2.918a.765.765,0,0,0,.768-.616.739.739,0,0,0-.729-.863h-.554a.185.185,0,0,1-.185-.185v-1.109a.185.185,0,0,1,.185-.185h.554a.739.739,0,0,0,.739-.739v-2.957A.739.739,0,0,0,154.964,482.446Zm-8.786,1.468h-.515a.185.185,0,0,1-.185-.185v-.515a.766.766,0,0,0-.615-.768.739.739,0,0,0-.863.729v1.478a.739.739,0,0,0,.739.739h1.478a.739.739,0,0,0,.729-.863A.765.765,0,0,0,146.178,483.914Zm5.214-.739a.739.739,0,0,0,1.478,0V481.7a.739.739,0,1,0-1.478,0Zm-.778,3.7H150.1a.185.185,0,0,1-.185-.185v-1.109a.185.185,0,0,1,.185-.185h.515a.765.765,0,0,0,.768-.616.739.739,0,0,0-.729-.863h-1.478a.739.739,0,0,0-.739.739v4.4a.766.766,0,0,0,.615.768.739.739,0,0,0,.863-.729v-.554a.185.185,0,0,1,.185-.185h.554a.739.739,0,0,0,.729-.863A.765.765,0,0,0,150.614,486.871Zm-5.875-5.914h1.478a.739.739,0,0,0,.739-.739v-1.478a.739.739,0,0,0-.739-.739h-1.478a.739.739,0,0,0-.739.739v1.478A.739.739,0,0,0,144.739,480.957Zm1.478,5.914h-1.478a.739.739,0,0,0-.739.739v1.479a.739.739,0,0,0,.739.739h1.478a.739.739,0,0,0,.739-.739V487.61A.739.739,0,0,0,146.218,486.871ZM155.088,478H153.61a.739.739,0,0,0-.739.739v1.478a.739.739,0,0,0,.739.739h1.478a.739.739,0,0,0,.739-.739v-1.478A.739.739,0,0,0,155.088,478Z"
        transform="translate(-138.086 -466.172)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco208;
