/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-82.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco82 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-82', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="41.396"
      viewBox="0 0 35.482 41.396"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(1.478 0)">
        <path
          className="a"
          d="M386.288,328.961a.738.738,0,0,1-.655-.4A34.7,34.7,0,0,1,382,312.7v-4.435a16.263,16.263,0,0,1,32.526,0V312.7a34.7,34.7,0,0,1-3.634,15.867.739.739,0,0,1-1.308-.687,33.209,33.209,0,0,0,3.464-15.18v-4.435a14.784,14.784,0,0,0-29.569,0V312.7a33.219,33.219,0,0,0,3.463,15.18.739.739,0,0,1-.653,1.083Z"
          transform="translate(-382 -292)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(13.306 25.134)">
        <path
          className="a"
          d="M402.435,328.957a6.026,6.026,0,0,1-4.209-1.685.739.739,0,0,1,1.026-1.065,4.551,4.551,0,0,0,3.183,1.272.739.739,0,1,1,0,1.478Z"
          transform="translate(-398 -326)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M396.739,314a.738.738,0,0,0-.739.739v1.478a.739.739,0,0,0,1.478,0v-1.478A.739.739,0,0,0,396.739,314Zm10.349,0a.738.738,0,0,0-.739.739v1.478a.739.739,0,1,0,1.478,0v-1.478A.738.738,0,0,0,407.088,314Z"
        transform="translate(-384.173 -297.737)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M414.952,340.138l-12.036-3.54v-1.115a.739.739,0,0,0-1.478,0v1.444l-3.7,5.544-3.7-5.544v-1.444a.739.739,0,0,0-1.478,0V336.6l-12.036,3.54a.739.739,0,0,0,.208,1.448.73.73,0,0,0,.208-.03l12.045-3.542,4.134,6.2a.739.739,0,0,0,1.23,0l4.134-6.2,12.045,3.542a.73.73,0,0,0,.208.03.739.739,0,0,0,.208-1.448Zm-7.6,2.927h-4.435a.739.739,0,1,0,0,1.478h4.435a.739.739,0,1,0,0-1.478Z"
        transform="translate(-380 -303.147)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M410.916,308.914c-3.669,0-6.653-2.322-6.653-5.174a.739.739,0,1,0-1.478,0c0,2.447-5.768,5.174-14.045,5.174a.739.739,0,0,0-.739.739v7.392a11.827,11.827,0,1,0,23.655,0v-7.392A.739.739,0,0,0,410.916,308.914Zm-11.088,18.48a10.36,10.36,0,0,1-10.349-10.349V310.38c6.452-.125,11.75-1.862,13.827-4.319a8.083,8.083,0,0,0,6.871,4.3v6.682A10.36,10.36,0,0,1,399.827,327.394Z"
        transform="translate(-382.086 -294.869)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M409.351,311a.739.739,0,0,0-.739.739v7.392c0,2.283-3.055,4.227-6.795,4.417a2.22,2.22,0,1,0,.012,1.477c3.52-.153,6.416-1.612,7.635-3.682A5.172,5.172,0,0,0,409.351,311Zm-9.61,14.045a.739.739,0,1,1,.739-.739A.74.74,0,0,1,399.741,325.045ZM410.09,319.8v-7.243a3.7,3.7,0,0,1,0,7.243ZM387.175,311a5.175,5.175,0,1,0,0,10.349.739.739,0,0,0,.739-.739v-8.871A.739.739,0,0,0,387.175,311Zm-.739,8.8a3.7,3.7,0,0,1,0-7.243Z"
        transform="translate(-380.522 -296.955)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco82;
