/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-85.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina85 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-85', className].join(' ');
  return (
    <svg
      id="disciplina_surf"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M11.193 2.416a2.06 2.06 0 1 1 4.056-.713 2.06 2.06 0 0 1-4.056.713ZM2.196 19.462c.722-.506 2.511-1.028 4.896-1.452l-1.18-3.647a1.376 1.376 0 0 1 .743-1.676l2.537-1.15.93-4.984-2.516.342-2.64 2.185a.918.918 0 0 1-1.17-1.415l2.84-2.352a.916.916 0 0 1 .463-.202l4.422-.599c.13-.017.262-.016.392.004l2.175.33 2.735-.925 1.667-2.822a.918.918 0 1 1 1.58.932L18.233 5.15a.916.916 0 0 1-.496.403l-3.942 1.334-.72 3.876 2.341-.313a1.377 1.377 0 0 1 1.56 1.378l-.05 5.323c3.374 0 5.716.377 5.771 1.033.044.504-1.266 1.07-3.37 1.563-1.312-.699-3.018-.61-4.226.283-1.35-.998-3.335-1-4.688 0a3.914 3.914 0 0 0-2.339-.768h-.005a3.913 3.913 0 0 0-2.342.769c-1.01-.739-2.356-.92-3.53-.569Zm6.714-4.775.943 2.915c.73-.09 1.487-.168 2.27-.236.7-.06 1.383-.105 2.046-.141l.036-3.838-3.442.46-1.853.84Zm14.86 6.732a.747.747 0 0 0-1.128 0 .605.605 0 0 1-.91 0 2.135 2.135 0 0 0-3.165 0 .617.617 0 0 1-.908 0 2.139 2.139 0 0 0-3.165 0 .62.62 0 0 1-.91 0 2.137 2.137 0 0 0-3.166.002.613.613 0 0 1-.896.014l-.014-.015c-.424-.466-.984-.723-1.58-.723h-.002c-.598 0-1.16.257-1.583.724a.613.613 0 0 1-.906 0 2.151 2.151 0 0 0-3.164 0 .605.605 0 0 1-.91 0 .747.747 0 0 0-1.13 0 .943.943 0 0 0 0 1.243c.875.956 2.296.956 3.17-.004a.616.616 0 0 1 .907 0 2.139 2.139 0 0 0 3.164 0 .61.61 0 0 1 .455-.208.601.601 0 0 1 .438.193l.016.017a2.157 2.157 0 0 0 1.582.723c.598 0 1.16-.257 1.584-.725a.616.616 0 0 1 .908 0c.833.918 2.334.918 3.165 0a.614.614 0 0 1 .908 0 2.14 2.14 0 0 0 3.166 0 .612.612 0 0 1 .908 0c.873.96 2.294.96 3.165 0a.943.943 0 0 0 .001-1.241Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina85;
