/* eslint-disable no-unused-vars */

import { VirtualAvvenimentoCorsa, VirtualAvvenimentoSport, VirtualEsito } from 'types/swagger';

export const QUOTE_SPORT_TICKET_AVVENIMENTO_API = 'QuoteBySportTicketAvvenimento';

export type UpdateScommesseAvvenimentoType = {
  avvenimentoKey: string;
  allScommesseClosed: boolean;
};

export const enum VENDITA_STATUS {
  SUCCESS = 1024,
  CAMBIO_QUOTE = 1030,
  SCOMMESSE_CHIUSE_SOSPESE = 799,
  BET_REFERRAL = -12001,
  BET_REFERRAL_PARTIAL = -12004,
}

export type VirtualTicketInfoAvvenimento = Pick<
  VirtualAvvenimentoSport | VirtualAvvenimentoCorsa,
  | 'key'
  | 'dataOra'
  | 'idProgramma'
  | 'idDisciplina'
  | 'idAvvenimento'
  | 'slugDisciplina'
  | 'descrizione'
  | 'palinsesto'
  | 'disciplina'
>;

export type VirtualTicketInfoEsito = {
  id: string;
} & Pick<VirtualEsito, 'quota' | 'idEsito' | 'descrizione' | 'idTipoScommessa'> & {
    descrizioneEsito?: string;
    descrizioneTipoScommessa?: string;
  };

export type ScommessaAddedPayload = {
  ticketAvvenimento: VirtualTicketInfoAvvenimento;
  ticketEsito: VirtualTicketInfoEsito;
};
