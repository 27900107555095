/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-406.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco406 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-406', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M77.433,157.4l-6.864-3.168-2.105-2.808c-.011-.015-.029-.021-.041-.035a10.908,10.908,0,0,0,1.322-3.215l0-.008c.02,0,.039.006.059.006h2.5a2.22,2.22,0,0,0,2.218-2.218v-4.435a2.214,2.214,0,0,0-1.478-2.082V135.61a9.621,9.621,0,0,0-9.61-9.61H59a9.621,9.621,0,0,0-9.61,9.61v3.832a2.214,2.214,0,0,0-1.478,2.082v4.435a2.22,2.22,0,0,0,2.218,2.218h2.5c.021,0,.041-.005.061-.006a10.912,10.912,0,0,0,1.323,3.223c-.013.014-.03.021-.041.035l-2.105,2.808L45.006,157.4A5.19,5.19,0,0,0,42,162.1v1.6a.739.739,0,1,0,1.478,0v-1.6a3.708,3.708,0,0,1,2.147-3.356l6.472-2.987,4.82,6.886a.736.736,0,0,0,.5.308.718.718,0,0,0,.1.007.74.74,0,0,0,.461-.162l3.235-2.588,3.235,2.588a.735.735,0,0,0,.461.162.718.718,0,0,0,.1-.007.736.736,0,0,0,.5-.308l4.82-6.886,6.472,2.987a3.708,3.708,0,0,1,2.147,3.356v1.6a.739.739,0,1,0,1.478,0v-1.6A5.19,5.19,0,0,0,77.433,157.4Zm-16.213.756-2.76-3.066a8.252,8.252,0,0,0,5.52,0Zm11.827-12.2a.74.74,0,0,1-.739.739H70.09v-5.914h2.218a.74.74,0,0,1,.739.739ZM59,127.478h4.435a8.141,8.141,0,0,1,8.131,8.131v3.7H70a19.2,19.2,0,0,0-1.218-4.724.74.74,0,0,0-1.113-.311,9.953,9.953,0,0,1-5.2,1.634,14.077,14.077,0,0,1-3.627-.615,12.779,12.779,0,0,0-3.253-.568c-1.613,0-2.782,2.169-3.131,4.583H50.871v-3.7A8.141,8.141,0,0,1,59,127.478ZM52.349,146.7H50.131a.74.74,0,0,1-.739-.739v-4.435a.74.74,0,0,1,.739-.739h2.218Zm1.478-5.914c0-2.53,1.067-4.583,1.763-4.583a11.729,11.729,0,0,1,2.876.519,15.446,15.446,0,0,0,4,.664,11.183,11.183,0,0,0,5.248-1.413,15.929,15.929,0,0,1,.893,4.814v4.435a11.252,11.252,0,0,1-.276,2.476,4.781,4.781,0,0,1-4.16,1.959h-.206a1.478,1.478,0,1,0,0,1.478h.206a7.382,7.382,0,0,0,3.062-.648,6.967,6.967,0,0,1-6.019,3.605c-4.214,0-7.392-3.814-7.392-8.871Zm3.85,20.368-4.415-6.308,1.356-1.809,5.528,6.141Zm7.085,0-2.469-1.975,5.528-6.141,1.356,1.809Z"
        transform="translate(-42 -126)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco406;
