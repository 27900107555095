import 'sites/snai/styles/app.scss';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Persist, { StorageKind } from 'lib/persist';
import { appInsight, appInsightPageLoad } from 'components/appInsight';
import { isClientSide, isTruthy } from 'utility/functions';
import { useEffect, useMemo, useRef } from 'react';

import { AKAMAI_SESSION_COOKIE, FORCE_LOGOUT, IS_LOGGED } from 'utility/constant';
import { AppProps } from 'next/app';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import { I18nProvider } from '@lingui/react';
import type { Metric } from 'web-vitals';
import { Provider } from 'react-redux';
import { RefreshTokenEngine } from 'components/refreshTokenEngine';
import Script from 'next/script';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ThemeProvider } from 'next-themes';
import { WrapperInactiveLogout } from 'components/wrapperInactiveLogout';
import { fromSearchSetSecondary } from 'lib/mwNodeSwitch';
import { getConfig } from 'features/configuration/actions';
import { initZodLabels } from 'lib/labels';
import { navigate } from 'features/location/types';
import { store } from 'sites/snai/lib/store';
import { useInitLingUI } from 'hooks/useLingUI';
import { useRouter } from 'next/router';
import { getGeoComplyLicense } from 'features/geoComply/actions';
import { setIsImported } from 'features/geoComply/geoComplySlice';
import { cookieReset } from 'lib/persist/cookie';
import { decrementTabCount, getTabCount, incrementTabCount } from 'utility/tabCounter';

export const trackWV = (metric: Metric, pathName: string, tagName = 'Web Vitals') => {
  const { name, value } = metric;
  appInsight.trackTrace({
    message: `[${tagName}] ${pathName} - ${name} : ${value}`,
    severityLevel: SeverityLevel.Information,
  });
};

// await is required because of signalR connection dependenry
const { getState, dispatch } = store;
await dispatch(getConfig());

initZodLabels();

const cookie = Persist(StorageKind.cookie);

export default function MyApp({ Component, pageProps }: AppProps<{ session: Session }>) {
  const { asPath, events, replace } = useRouter();

  // TODO : set cms labels always to labels
  const labels = Reflect.get(pageProps ?? {}, 'labels');
  const allLabels = Reflect.get(pageProps ?? {}, 'allLabels');
  const messages = Object.assign({}, labels, allLabels);
  const i18n = useInitLingUI(messages);

  const path = useMemo(() => {
    const [path] = asPath.split(/\?|#/gim);
    return decodeURI(path);
  }, [asPath]);

  const initialized = useRef(false);
  useEffect(() => {
    if (isClientSide() && !initialized.current) {
      initialized.current = true;
      cookieReset();
      fromSearchSetSecondary(document.location.search);
    }
  }, []);

  /* useEffect(() => {
    setTimeout(() => {
      onCLS((m: Metric) => trackWV(m, path));
      onFID((m: Metric) => trackWV(m, path));
      onLCP((m: Metric) => trackWV(m, path));
      onINP((m: Metric) => trackWV(m, path));
      onFCP((m: Metric) => trackWV(m, path));
      onTTFB((m: Metric) => trackWV(m, path));
    }, 0);
  }, [path]); */

  useEffect(() => {
    incrementTabCount();

    const handleBeforeUnload = () => {
      const tabCount = getTabCount();
      if (tabCount === 1) {
        decrementTabCount();
        if (isTruthy(cookie.getItem(IS_LOGGED))) localStorage.setItem(FORCE_LOGOUT, 'true');
      } else decrementTabCount();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      decrementTabCount();
    };
  }, []);

  useEffect(() => {
    appInsightPageLoad(false);
    cookie.removeItem(AKAMAI_SESSION_COOKIE);
    dispatch(navigate(path));
  }, [path]);

  // Aggiorna il pathname corrente e precedente quando cambia la pagina
  useEffect(() => {
    const handleChangeStart = (pathname) => {
      appInsightPageLoad(true);
      dispatch({ type: navigate.pending.type, meta: { arg: decodeURI(pathname) } });
    };

    const handleChangeError = (pathname) => {
      appInsightPageLoad(false, true);
      dispatch({ type: navigate.rejected.type, payload: decodeURI(pathname) });
    };

    const handleChangeComplete = (pathname) => {
      appInsightPageLoad(false);
      dispatch({ type: navigate.fulfilled.type, payload: decodeURI(pathname) });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'custom_page_view_trigger',
        page: decodeURI(pathname),
      });
    };

    events.on('routeChangeError', handleChangeError);
    events.on('routeChangeStart', handleChangeStart);
    events.on('routeChangeComplete', handleChangeComplete);

    // If the component is unmounted, unsubscribe  from the event with the `off` method:
    return () => {
      events.off('routeChangeError', handleChangeError);
      events.off('routeChangeStart', handleChangeStart);
      events.off('routeChangeComplete', handleChangeComplete);
    };
  }, [events]);

  return (
    <>
      <Provider store={store} serverState={getState()}>
        <I18nProvider i18n={i18n}>
          <ThemeProvider enableSystem={false} defaultTheme="dark">
            <SessionProvider session={pageProps.session}>
              <ErrorBoundary asPath={asPath} replace={replace} session={pageProps.session} key={i18n.locale}>
                <Component {...pageProps} key={i18n.locale} />
              </ErrorBoundary>
              <RefreshTokenEngine />
              <WrapperInactiveLogout />
            </SessionProvider>
          </ThemeProvider>
        </I18nProvider>
      </Provider>
      <Script
        id="geo-comply"
        src="https://stg-cdn.geocomply.com/199/gc-html5.js"
        strategy="lazyOnload"
        crossOrigin="anonymous"
        onLoad={() => {
          dispatch(setIsImported(true));
          dispatch(getGeoComplyLicense());
        }}
      />
      <Script
        id="google-tag-manager"
        src="/api/gtm"
        type="text/javascript"
        strategy="lazyOnload"
        crossOrigin="anonymous"
      ></Script>
      {isTruthy(process.env.DYNATRACE_IS_ACTIVE) && (
        <Script
          id="dynatrace"
          src="https://js-cdn.dynatrace.com/jstag/15a8a271790/bf10832aqd/f789de422e567e33_complete.js"
          type="text/javascript"
          strategy="lazyOnload"
          crossOrigin="anonymous"
        ></Script>
      )}
      {/* <Script id="accessiway" src={ACSBAPP_URL} strategy="lazyOnload">{`
          (function () {
            var s = document.createElement("script"),
            e = !document.body ? document.querySelector("head") : document.body;
            s.src = "${ACSBAPP_URL}";
            s.async = true;
            s.onload = function () {
              acsbJS.init({
                statementLink: "",
                footerHtml: '<a>AccessiWay. The Web Accessibility Solution</a>',
                hideMobile: false,
                hideTrigger: false,
                language: "it",
                position: "left",
                leadColor: "#E96105",
                triggerColor: "#E96105",
                triggerRadius: "50%",
                triggerPositionX: "left",
                triggerPositionY: "bottom",
                triggerIcon: "people",
                triggerSize: "small",
                triggerOffsetX: 8,
                triggerOffsetY: 104,
                mobile: {
                  triggerSize: "small",
                  triggerRadius: "50%",
                  triggerOffsetX: 4,
                  triggerOffsetY: 80,
                  triggerPositionX: "left",
                  triggerPositionY: "bottom",
                },
              });
            };
            e.appendChild(s);
          })();`}</Script> */}
    </>
  );
}
