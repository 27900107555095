/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\game-slash.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const GameSlash = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-game-slash', className].join(' ');
  return (
    <svg id="game-slash" viewBox="0 0 41 41" fill="none" focusable="false" className={componentClassName} {...attribs}>
      <path
        d="M16 27.9998C16 29.6567 14.6569 30.9998 13 30.9998C11.3431 30.9998 10 29.6567 10 27.9998C10 26.343 11.3431 24.9998 13 24.9998C14.6569 24.9998 16 26.343 16 27.9998Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6.99981L0 2.99981L2.82843 0.171387L40.8284 38.1714L38 40.9998L34 36.9998H8C5.8 36.9998 4 35.1998 4 32.9998V6.99981ZM8 10.9998L30 32.9998H8V10.9998Z"
        fill="currentColor"
      />
      <path
        d="M8.00001 0.999813C7.32406 0.999813 6.68586 1.16974 6.12604 1.46899L9.65686 4.99981H36V31.343L39.5308 34.8738C39.8301 34.314 40 33.6758 40 32.9998V4.99981C40 2.79981 38.2 0.999813 36 0.999813H8.00001Z"
        fill="currentColor"
      />
      <path
        d="M11.8749 7.21791L15.7819 11.1249C15.9226 10.7774 16 10.3977 16 9.99981C16 8.34296 14.6569 6.99981 13 6.99981C12.6021 6.99981 12.2224 7.07726 11.8749 7.21791Z"
        fill="currentColor"
      />
      <path
        d="M20.8749 16.2179L24.7819 20.1249C24.9226 19.7774 25 19.3977 25 18.9998C25 17.343 23.6569 15.9998 22 15.9998C21.6021 15.9998 21.2224 16.0773 20.8749 16.2179Z"
        fill="currentColor"
      />
      <path
        d="M29.8749 25.2179L33.7819 29.1249C33.9226 28.7774 34 28.3977 34 27.9998C34 26.343 32.6569 24.9998 31 24.9998C30.6021 24.9998 30.2224 25.0773 29.8749 25.2179Z"
        fill="currentColor"
      />
      <path d="M31 12.9998C32.6569 12.9998 34 11.6567 34 9.99981C34 8.34296 32.6569 6.99981 31 6.99981C29.3431 6.99981 28 8.34296 28 9.99981C28 11.6567 29.3431 12.9998 31 12.9998Z" />
    </svg>
  );
};
export default GameSlash;
