import { Esito, EsitoMap, InfoAggiuntivaMap, ScommessaResponse } from 'lib/api/sport/sportScommesseBySlugResponse';
import { FetchPayload, doFetch } from 'features/api/thunkUtils';
import {
  LIVE_FAVOURITE_DETAILS,
  LIVE_MENU_AVVENIMENTI_API,
  LiveNavActionPayload,
  LiveNavState,
  feedLiveNavProps,
  feedLiveNavPayload,
  initDisciplinaPayload,
  initManifestazionePayload,
} from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  SportsAddAvvenimentoSignalREventWithTranslationsDto,
  SportsAddDisciplinaSignalREventWithTranslationsDto,
  SportsAddManifestazioneSignalREventWithTranslationsDto,
  SportsAddScommessaSignalREventWithTranslationsDto,
  SportsAvvenimentoEsposto,
  SportsRemoveAvvenimentoSignalREventWithTranslationsDto,
  SportsRemoveDisciplinaSignalREventWithTranslationsDto,
  SportsRemoveManifestazioneSignalREventWithTranslationsDto,
  SportsUpdateAvvenimentoSignalREventWithTranslationsDto,
  SportsUpdateDisciplinaSignalREventWithTranslationsDto,
  SportsUpdateInfoAggSignalREventWithTranslationsDto,
  SportsUpdateQuoteSignalREventWithTranslationsDto,
  SportsUpdateRisultatiniSignalREventWithTranslationsDto,
  SportsUpdateScommesseAvvenimentoSignalREventWithTranslationsDto,
  SportsUpdateStatoInfoAggSignalREventWithTranslationsDto,
} from 'types/swagger';
import {
  applyAddAvvenimento,
  applyAvvenimento,
  applyInfoAggiuntiva,
  applyQuote,
  applyRemoveDataFromAvvenimento,
  applyRisultatini,
  applyScommessa,
  applyScommesseAvvenimento,
  applyUpdateInfoAgg,
  removeAvvenimento,
} from 'features/sport';
import { isMatch, isTruthy } from 'utility/functions';
import { mergeDisciplina, mergeManifestazione, mergeMenu } from './utility';

import { AppFragment } from 'enums/path-fragment';
import { ListenerType } from 'features/sport/types';
import { feedLingUI } from 'hooks/useLingUI';
import { feedLiveNav } from './actions';
import { navigate } from 'features/location/types';

export const initialState: LiveNavState = {
  ts: -1,
  isLoading: {},
  favourites: {},
  discipline: {},
  expandedItems: [],
};

const _mergeMenu = (st: LiveNavState, payload: feedLiveNavProps | feedLiveNavPayload) => {
  const { data } = payload ?? {};

  const { discipline, now, traduzioneMap } = data ?? {};

  // appy only newst updates
  const nextTs = now ?? -1;
  if (nextTs > st.ts) {
    feedLingUI(traduzioneMap);

    mergeMenu(st, discipline ?? []);

    st.ts = nextTs;
  }
};

export const liveNavSlice = createSlice({
  name: 'liveNav',
  initialState,
  reducers: {
    doReset: (state) => {
      state.isLoading = {};
      state.discipline = {};
      state.expandedItems.splice(0, state.expandedItems.length);
    },
    initDisciplina: (state, action: PayloadAction<initDisciplinaPayload>) => {
      mergeDisciplina(state, action.payload);
    },
    initManifestazione: (state, action: PayloadAction<initManifestazionePayload>) => {
      mergeManifestazione(state, action.payload);
    },
    openMenu: (state, action: PayloadAction<LiveNavActionPayload>) => {
      const { entryId } = action.payload;

      if (entryId && !state.expandedItems.includes(entryId)) {
        state.expandedItems.push(entryId);
      }
    },
    closeMenu: (state, action: PayloadAction<LiveNavActionPayload>) => {
      const { entryId } = action.payload;
      if (!!entryId) {
        const [idDisciplina, idManifestazione] = `${entryId}-`.split('-');
        if (!!idDisciplina) {
          const disciplina = state.discipline[idDisciplina];
          if (disciplina?.manifestazioni) {
            if (!!idManifestazione) {
              const manifestazione = disciplina.manifestazioni[idManifestazione];
              delete manifestazione.scommesse;
            } else {
              delete disciplina.manifestazioni;
            }
          }
        }

        const idx = state.expandedItems.indexOf(entryId);
        if (idx > -1) {
          state.expandedItems.splice(idx, 1);
        }
      }
    },
    addDisciplina: (state, { payload }: PayloadAction<SportsAddDisciplinaSignalREventWithTranslationsDto>) => {
      const { traduzioneMap, disciplinaMenuItem } = payload;

      feedLingUI(traduzioneMap);

      const { id } = disciplinaMenuItem ?? {};
      if (disciplinaMenuItem && id) {
        state.discipline[id] = disciplinaMenuItem;
      }
    },
    updateDisciplina: (state, { payload }: PayloadAction<SportsUpdateDisciplinaSignalREventWithTranslationsDto>) => {
      const { id, counter, priorita, isLive, isPrematch, traduzioneMap } = payload;

      feedLingUI(traduzioneMap);

      if (id) {
        state.discipline[id] = state.discipline[id] ?? { id, counter, isLive, isPrematch };
        const nextCounter = counter ?? 0;
        if (nextCounter !== state.discipline[id].counter) {
          state.discipline[id].counter = nextCounter;
        }
        const nextPriorita = priorita ?? 999;
        if (nextPriorita !== state.discipline[id].priorita) {
          state.discipline[id].priorita = nextPriorita;
        }
        const nextIsPrematch = isPrematch ?? false;
        if (nextIsPrematch !== state.discipline[id].isPrematch) {
          state.discipline[id].isPrematch = nextIsPrematch;
        }
        const nextIsLive = isLive ?? false;
        if (nextIsLive !== state.discipline[id].isLive) {
          state.discipline[id].isLive = nextIsLive;
        }
      }
    },
    removeDisciplina: (state, action: PayloadAction<SportsRemoveDisciplinaSignalREventWithTranslationsDto>) => {
      const { traduzioneMap, id } = action.payload;

      feedLingUI(traduzioneMap);

      for (let key of Object.keys(state.expandedItems)) {
        if (key === `${id}` || key.startsWith(`${id}-`)) {
          delete state.expandedItems[key];
        }
      }
      delete state.discipline[id].manifestazioni;
      state.discipline[id].counter = 0;

      state.discipline[id].isPrematch = false;
      state.discipline[id].isLive = false;
    },
    addManifestazione: (state, { payload }: PayloadAction<SportsAddManifestazioneSignalREventWithTranslationsDto>) => {
      const { traduzioneMap, manifestazioneMenuItem: manifestazione } = payload;

      feedLingUI(traduzioneMap);

      const { idDisciplina, id } = manifestazione;
      const disciplina = state.discipline[idDisciplina];
      if (disciplina) {
        disciplina.manifestazioni = disciplina.manifestazioni ?? {};
        disciplina.manifestazioni[id] = manifestazione;
      }
    },
    removeManifestazione: (
      state,
      { payload }: PayloadAction<SportsRemoveManifestazioneSignalREventWithTranslationsDto>
    ) => {
      const { id, idDisciplina, traduzioneMap } = payload;

      feedLingUI(traduzioneMap);

      delete state?.discipline?.[idDisciplina]?.manifestazioni?.[id];
    },
    updateQuote: (
      state,
      action: PayloadAction<SportsUpdateQuoteSignalREventWithTranslationsDto | Record<string, Esito>>
    ) => {
      if (action.payload.hasOwnProperty('slug')) {
        const { traduzioneMap, esiti, slug } = action.payload as SportsUpdateQuoteSignalREventWithTranslationsDto;

        feedLingUI(traduzioneMap);

        for (let disciplina of Object.values(state.discipline)) {
          for (let manifestazione of Object.values(disciplina.manifestazioni ?? {})) {
            if (manifestazione.slug === slug) {
              applyQuote(manifestazione.scommesse as unknown as ScommessaResponse, esiti as unknown as EsitoMap);
            }
          }
        }
      }
    },
    addScommessa: (state, { payload }: PayloadAction<SportsAddScommessaSignalREventWithTranslationsDto>) => {
      const { traduzioneMap, slug, ...scommessa } = payload;
      feedLingUI(traduzioneMap);
      if (slug) {
        for (let disciplina of Object.values(state.discipline)) {
          for (let manifestazione of Object.values(disciplina.manifestazioni ?? {})) {
            if (manifestazione.slug === slug) {
              applyScommessa(manifestazione.scommesse as unknown as ScommessaResponse, scommessa as any);
            }
          }
        }
      }
    },
    updateRisultatini: (state, { payload }: PayloadAction<SportsUpdateRisultatiniSignalREventWithTranslationsDto>) => {
      const { traduzioneMap, ...risultatini } = payload;

      feedLingUI(traduzioneMap);

      for (let disciplina of Object.values(state.discipline)) {
        for (let manifestazione of Object.values(disciplina.manifestazioni ?? {})) {
          applyRisultatini(manifestazione.scommesse as unknown as ScommessaResponse, risultatini);
        }
      }
    },
    updateInfoAgg: (state, { payload }: PayloadAction<SportsUpdateInfoAggSignalREventWithTranslationsDto>) => {
      const { slug, esitoMap, infoAggiuntivaMap, traduzioneMap } = payload;

      feedLingUI(traduzioneMap);

      if (slug) {
        for (let disciplina of Object.values(state.discipline)) {
          for (let manifestazione of Object.values(disciplina.manifestazioni ?? {})) {
            if (manifestazione.slug === slug) {
              applyQuote(manifestazione.scommesse as unknown as ScommessaResponse, esitoMap as unknown as EsitoMap);
              applyInfoAggiuntiva(
                manifestazione.scommesse as unknown as ScommessaResponse,
                infoAggiuntivaMap as unknown as InfoAggiuntivaMap
              );
            }
          }
        }
      }
    },
    updateAvvenimento: (state, { payload }: PayloadAction<SportsUpdateAvvenimentoSignalREventWithTranslationsDto>) => {
      const { slug, traduzioneMap, ...scommessa } = payload;

      feedLingUI(traduzioneMap);

      for (let disciplina of Object.values(state.discipline)) {
        for (let manifestazione of Object.values(disciplina.manifestazioni ?? {})) {
          if (manifestazione.slug === slug) {
            applyAvvenimento(manifestazione.scommesse as unknown as ScommessaResponse, { slug, ...scommessa });
          }
        }
      }
    },
    updateScommesseAvvenimento: (
      state,
      { payload }: PayloadAction<SportsUpdateScommesseAvvenimentoSignalREventWithTranslationsDto>
    ) => {
      const { slug, avvenimentoKey, allInfoAggAreActive, traduzioneMap } = payload;

      feedLingUI(traduzioneMap);

      for (let disciplina of Object.values(state.discipline ?? {})) {
        for (let manifestazione of Object.values(disciplina.manifestazioni ?? {})) {
          if (manifestazione.slug === slug) {
            applyScommesseAvvenimento(manifestazione.scommesse as any, avvenimentoKey, allInfoAggAreActive);
          }
        }
      }
    },
    updateStatoInfoAgg: (
      state,
      { payload }: PayloadAction<SportsUpdateStatoInfoAggSignalREventWithTranslationsDto>
    ) => {
      const { updateStatoInfoAggMap, slug, traduzioneMap } = payload;

      feedLingUI(traduzioneMap);

      if (!updateStatoInfoAggMap) return;
      for (let disciplina of Object.values(state.discipline)) {
        for (let manifestazione of Object.values(disciplina.manifestazioni ?? {})) {
          if (manifestazione.slug === slug) {
            applyUpdateInfoAgg(manifestazione.scommesse as unknown as ScommessaResponse, { updateStatoInfoAggMap });
          }
        }
      }
    },
    addAvvenimento: (state, { payload }: PayloadAction<SportsAddAvvenimentoSignalREventWithTranslationsDto>) => {
      const { traduzioneMap, avvenimento } = payload;
      const { key, idDisciplina, idManifestazione } = avvenimento ?? {};

      feedLingUI(traduzioneMap);

      if (key && idDisciplina && idManifestazione) {
        const avvenimentoMap: Record<string, SportsAvvenimentoEsposto> = {};
        avvenimentoMap[key] = avvenimento;

        const disciplina = state.discipline[idDisciplina];
        if (disciplina && disciplina.manifestazioni) {
          const manifestazione = disciplina.manifestazioni?.[idManifestazione];
          const { scommesse } = manifestazione ?? {};
          if (scommesse) {
            applyAddAvvenimento(scommesse as unknown as ScommessaResponse, avvenimentoMap);
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(navigate.fulfilled, (state, { payload }) => {
        const isSport =
          payload?.length > 1 &&
          [AppFragment.Sport, AppFragment.Live, AppFragment.Ippica].some((x) => isMatch(payload, `^(\/*)${x}($|\/)`));
        if (isSport) return state;
        return initialState;
      })
      .addCase(doFetch.pending, (state, action) => {
        const { uid, slug } = action.meta.arg;
        if (uid !== LIVE_FAVOURITE_DETAILS) return;
        state.isLoading[slug] = true;
      })
      .addCase(doFetch.rejected, (state, action) => {
        const { uid, slug } = action.meta.arg;
        if (uid !== LIVE_FAVOURITE_DETAILS) return;
        delete state.isLoading[slug];
        delete state.favourites[slug];
      })
      .addCase(doFetch.fulfilled, (state, action) => {
        const { uid, slug } = action.meta.arg;
        if (uid !== LIVE_FAVOURITE_DETAILS) return;
        const respose = Reflect.get(action, 'payload') as FetchPayload<ScommessaResponse>;
        state.favourites[slug] = respose.data;
        delete state.isLoading[slug];
      })
      .addCase(removeAvvenimento.fulfilled, (state, action) => {
        const { traduzioneMap, avvenimentoKey, slug } = Reflect.get(
          action,
          'payload'
        ) as SportsRemoveAvvenimentoSignalREventWithTranslationsDto;

        feedLingUI(traduzioneMap);

        if (slug) {
          for (let disciplina of Object.values(state.discipline)) {
            for (let manifestazione of Object.values(disciplina.manifestazioni ?? {})) {
              if (manifestazione.slug === slug) {
                applyRemoveDataFromAvvenimento(
                  manifestazione.scommesse as unknown as ScommessaResponse,
                  avvenimentoKey
                );
              }
            }
          }
        }
      })
      .addCase(feedLiveNav.pending, (state, { meta }) => {
        if (isTruthy(state.isLoading[LIVE_MENU_AVVENIMENTI_API])) return;
        state.isLoading[LIVE_MENU_AVVENIMENTI_API] = true;
        // HYDRATION DATA
        _mergeMenu(state, meta?.arg);
      })
      .addCase(feedLiveNav.fulfilled, (state, { payload }) => {
        delete state.isLoading[LIVE_MENU_AVVENIMENTI_API];
        // FRESH MW RESULTS
        _mergeMenu(state, payload);
      });
  },
});

export const {
  doReset,
  openMenu,
  closeMenu,
  updateQuote,
  addScommessa,
  updateInfoAgg,
  addDisciplina,
  addAvvenimento,
  initDisciplina,
  updateDisciplina,
  removeDisciplina,
  addManifestazione,
  updateRisultatini,
  updateAvvenimento,
  updateStatoInfoAgg,
  initManifestazione,
  removeManifestazione,
  updateScommesseAvvenimento,
} = liveNavSlice.actions;

export const liveNavSignalRListeners: ListenerType<any>[] = [
  { eventName: 'UpdateTranslation' },
  { eventName: 'UpdateQuote', actionType: updateQuote.type },
  { eventName: 'AddScommessa', actionType: addScommessa.type },
  { eventName: 'UpdateInfoAgg', actionType: updateInfoAgg.type },
  { eventName: 'AddDisciplina', actionType: addDisciplina.type },
  { eventName: 'AddAvvenimento', actionType: addAvvenimento.type },
  { eventName: 'UpdateDisciplina', actionType: updateDisciplina.type },
  { eventName: 'RemoveDisciplina', actionType: removeDisciplina.type },
  { eventName: 'AddManifestazione', actionType: addManifestazione.type },
  { eventName: 'UpdateRisultatini', actionType: updateRisultatini.type },
  { eventName: 'UpdateAvvenimento', actionType: updateAvvenimento.type },
  { eventName: 'RemoveAvvenimento', actionType: removeAvvenimento.fulfilled.type },
  { eventName: 'UpdateStatoInfoAgg', actionType: updateStatoInfoAgg.type },
  { eventName: 'RemoveManifestazione', actionType: removeManifestazione.type },
  { eventName: 'UpdateScommesseAvvenimento', actionType: updateScommesseAvvenimento.type },
];

export default liveNavSlice.reducer;
