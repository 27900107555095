/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\list-menu.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const ListMenu = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-list-menu', className].join(' ');
  return (
    <svg
      id="list-menu"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      fill="none"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#clip0_13070_41097)">
        <path
          d="M3.50039 7.9C2.89172 7.9 2.40039 8.39133 2.40039 9C2.40039 9.60867 2.89172 10.1 3.50039 10.1C4.10906 10.1 4.60039 9.60867 4.60039 9C4.60039 8.39133 4.10906 7.9 3.50039 7.9ZM3.50039 3.5C2.89172 3.5 2.40039 3.99133 2.40039 4.6C2.40039 5.20867 2.89172 5.7 3.50039 5.7C4.10906 5.7 4.60039 5.20867 4.60039 4.6C4.60039 3.99133 4.10906 3.5 3.50039 3.5ZM3.50039 12.3C2.89172 12.3 2.40039 12.7987 2.40039 13.4C2.40039 14.0013 2.89906 14.5 3.50039 14.5C4.10172 14.5 4.60039 14.0013 4.60039 13.4C4.60039 12.7987 4.10906 12.3 3.50039 12.3ZM5.70039 14.1333L15.9671 14.1333V12.6667L5.70039 12.6667V14.1333ZM5.70039 9.73333L15.9671 9.73333L15.9671 8.26667L5.70039 8.26667V9.73333ZM5.70039 3.86667V5.33333L15.9671 5.33333V3.86667L5.70039 3.86667Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_13070_41097">
          <rect width="17.6" height="17.6" fill="white" transform="translate(0.200195 0.199219)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ListMenu;
