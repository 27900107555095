/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-112.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina112 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-112', className].join(' ');
  return (
    <svg
      id="disciplina_scacchi"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M4.192 14.813h5.144l.065.75H4.127l.065-.75Zm.185-3.74h.151l-.27 2.98H9.26l-.271-2.98h.152c.705 0 1.28-.585 1.28-1.302 0-.629-.434-1.147-1.01-1.28l.5-2.261a.96.96 0 0 0-.174-.784.91.91 0 0 0-.716-.353H4.507a.894.894 0 0 0-.716.353.922.922 0 0 0-.174.784l.5 2.261a1.307 1.307 0 0 0-1.021 1.28 1.287 1.287 0 0 0 1.28 1.302Zm1.67-5.969H7.48V3.78h1.302V2.324H7.48V1H6.048v1.324H4.746V3.78h1.302v1.324Zm4.385 15.667h-.206c.13-.22.206-.474.206-.75 0-.552-.304-1.037-.749-1.29l-.217-2.44H4.062l-.217 2.44a1.49 1.49 0 0 0-.749 1.29c0 .276.076.53.206.75h-.206A1.11 1.11 0 0 0 2 21.886C2 22.503 2.5 23 3.096 23h7.336a1.11 1.11 0 0 0 1.096-1.114c0-.607-.5-1.115-1.096-1.115Zm9.897 0h-.195c.13-.22.206-.474.206-.75a1.49 1.49 0 0 0-.75-1.29l-.683-7.658h.152c.706 0 1.28-.585 1.28-1.302 0-.441-.216-.827-.553-1.07a1.095 1.095 0 0 0 .087-1.06L22 2.877h-2.246l-1.096 1.853h-.814l.369-1.864H15.12l.369 1.864h-.814l-1.096-1.864h-2.246L13.46 7.63a1.199 1.199 0 0 0-.098.452c0 .232.065.43.184.607a1.327 1.327 0 0 0-.553 1.07c0 .717.575 1.302 1.28 1.302h.152l-.683 7.657a1.49 1.49 0 0 0-.75 1.291c0 .276.077.53.207.75h-.206a1.11 1.11 0 0 0-1.096 1.115c0 .617.5 1.114 1.096 1.114h7.336a1.11 1.11 0 0 0 1.096-1.114 1.093 1.093 0 0 0-1.096-1.104Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina112;
