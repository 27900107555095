/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\filter.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Filter = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-filter', className].join(' ');
  return (
    <svg id="filter" viewBox="0 0 18 14" focusable="false" className={componentClassName} {...attribs}>
      <path
        d="M18 10.8648C18 11.0637 17.921 11.2544 17.7803 11.3951C17.6397 11.5357 17.4489 11.6148 17.25 11.6148H15.2625C15.0993 12.1541 14.7669 12.6266 14.3144 12.9624C13.862 13.2982 13.3135 13.4795 12.75 13.4795C12.1865 13.4795 11.638 13.2982 11.1856 12.9624C10.7331 12.6266 10.4007 12.1541 10.2375 11.6148H0.75C0.551088 11.6148 0.360322 11.5357 0.21967 11.3951C0.0790178 11.2544 0 11.0637 0 10.8648C0 10.6658 0.0790178 10.4751 0.21967 10.3344C0.360322 10.1938 0.551088 10.1148 0.75 10.1148H10.2375C10.4007 9.57544 10.7331 9.10294 11.1856 8.76713C11.638 8.43131 12.1865 8.25 12.75 8.25C13.3135 8.25 13.862 8.43131 14.3144 8.76713C14.7669 9.10294 15.0993 9.57544 15.2625 10.1148H17.25C17.4489 10.1148 17.6397 10.1938 17.7803 10.3344C17.921 10.4751 18 10.6658 18 10.8648ZM0.75 3.36476H4.2375C4.40069 3.90408 4.73309 4.37657 5.18555 4.71239C5.63802 5.0482 6.18653 5.22952 6.75 5.22952C7.31347 5.22952 7.86198 5.0482 8.31445 4.71239C8.76691 4.37657 9.09931 3.90408 9.2625 3.36476H17.25C17.4489 3.36476 17.6397 3.28574 17.7803 3.14509C17.921 3.00444 18 2.81367 18 2.61476C18 2.41585 17.921 2.22508 17.7803 2.08443C17.6397 1.94378 17.4489 1.86476 17.25 1.86476H9.2625C9.09931 1.32544 8.76691 0.852942 8.31445 0.517127C7.86198 0.181311 7.31347 0 6.75 0C6.18653 0 5.63802 0.181311 5.18555 0.517127C4.73309 0.852942 4.40069 1.32544 4.2375 1.86476H0.75C0.551088 1.86476 0.360322 1.94378 0.21967 2.08443C0.0790178 2.22508 0 2.41585 0 2.61476C0 2.81367 0.0790178 3.00444 0.21967 3.14509C0.360322 3.28574 0.551088 3.36476 0.75 3.36476Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Filter;
