/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\printer.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Printer = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-printer', className].join(' ');
  return (
    <svg
      id="printer"
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M10.9997 0H2.99967V2.66667H10.9997M11.6663 6C11.4895 6 11.32 5.92976 11.1949 5.80474C11.0699 5.67971 10.9997 5.51014 10.9997 5.33333C10.9997 5.15652 11.0699 4.98695 11.1949 4.86193C11.32 4.7369 11.4895 4.66667 11.6663 4.66667C11.8432 4.66667 12.0127 4.7369 12.1377 4.86193C12.2628 4.98695 12.333 5.15652 12.333 5.33333C12.333 5.51014 12.2628 5.67971 12.1377 5.80474C12.0127 5.92976 11.8432 6 11.6663 6ZM9.66634 10.6667H4.33301V7.33333H9.66634M11.6663 3.33333H2.33301C1.80257 3.33333 1.29387 3.54405 0.918794 3.91912C0.543722 4.29419 0.333008 4.8029 0.333008 5.33333V9.33333H2.99967V12H10.9997V9.33333H13.6663V5.33333C13.6663 4.8029 13.4556 4.29419 13.0806 3.91912C12.7055 3.54405 12.1968 3.33333 11.6663 3.33333Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Printer;
