/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\dark-mode.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const DarkMode = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-dark-mode', className].join(' ');
  return (
    <svg
      id="dark-mode"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M6.39922 2.79998c-.13333 0-.26667.0056-.4.0168-.13333.0112-.26667.02773-.4.0496.6336.71146 1.12533 1.50613 1.4752 2.384.34987.87733.5248 1.79386.5248 2.7496 0 .95573-.17493 1.8696-.5248 2.74162-.34987.872-.8416 1.6693-1.4752 2.392.13333.0218.26667.0384.4.0496.13333.0112.26667.0168.4.0168 1.44427 0 2.672-.5056 3.68318-1.5168 1.0112-1.0112 1.5168-2.23896 1.5168-3.68322 0-1.44427-.5056-2.672-1.5168-3.6832-1.01118-1.0112-2.23891-1.5168-3.68318-1.5168Zm0-1.2c.88907 0 1.71973.16666 2.492.5.77173.33333 1.44938.79173 2.03278 1.3752.5835.58346 1.0419 1.26106 1.3752 2.0328.3334.77226.5 1.60293.5 2.492 0 .87786-.1666 1.7056-.5 2.48322-.3333.7776-.7917 1.4581-1.3752 2.0416-.5834.5834-1.26105 1.0418-2.03278 1.3752-.77227.3333-1.60293.5-2.492.5-.5664 0-1.1192-.0694-1.6584-.208-.53867-.1392-1.05253-.3531-1.5416-.6416.9776-.6006 1.75547-1.3896 2.3336-2.3672.5776-.9776.8664-2.03869.8664-3.18322 0-1.14454-.2888-2.2056-.8664-3.1832-.57813-.9776-1.356-1.76667-2.3336-2.3672.48907-.28854 1.00293-.5024 1.5416-.6416.5392-.13867 1.092-.208 1.6584-.208Z"
        fill="#fff"
        fillOpacity=".6"
      />
    </svg>
  );
};
export default DarkMode;
