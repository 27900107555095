/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-46.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina46 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-46', className].join(' ');
  return (
    <svg
      id="disciplina_hockey-prato"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="m9.043 17.215-3.87 3.87a1.04 1.04 0 0 1-.744.306 1.047 1.047 0 0 1-.116.009h-2.26A1.055 1.055 0 0 1 1 20.346v-.043a1.054 1.054 0 0 1 1.053-1.053h1.92l3.554-3.553a1.056 1.056 0 0 1 1.486 0l.03.03a1.047 1.047 0 0 1 0 1.487Zm13.65 3.368-4.068-4.069V11.51a1.002 1.002 0 0 0-.125-.49 1.235 1.235 0 0 0-.257-.378l-4.138-4.138a1.292 1.292 0 0 0-1.818 0l-1.34 1.336c-.112.112-.18.228-.27.361-.104.146-.194.262-.246.425l-1.813 5.436a1.055 1.055 0 0 0 .666 1.332l.073.025a1.055 1.055 0 0 0 1.332-.665l1.34-4.014 2.69 2.69-2.702 2.703a1.203 1.203 0 0 0-.005 1.598l4.67 4.67a1.055 1.055 0 0 0 1.488 0l.124-.124a1.056 1.056 0 0 0 0-1.487l-3.931-3.931 2.135-2.136v2.355c0 .087.012.174.034.258.031.225.136.433.297.593l4.262 4.262a1.056 1.056 0 0 0 1.487 0l.116-.116a1.061 1.061 0 0 0 0-1.49ZM10.097 2a2.243 2.243 0 1 0 0 4.486 2.243 2.243 0 0 0 0-4.486Z"
      />
    </svg>
  );
};
export default Disciplina46;
