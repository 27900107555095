import { Dispatch } from '@reduxjs/toolkit';
import { selectCodiceProgramma, selectNumeroAvvenimento } from 'features/ippica/selectors';
import { RootState } from 'lib/centralStore';

export const composeNumeroCavallo = (idInfoAggiuntiva: string, numeroEvento: string): number => {
  let numeroCavallo: number;

  if (numeroEvento === '1') {
    numeroCavallo = parseInt(idInfoAggiuntiva.substring(idInfoAggiuntiva.length - 2), 16);
  } else {
    numeroCavallo = parseInt(idInfoAggiuntiva.substring(idInfoAggiuntiva.length - 4, idInfoAggiuntiva.length - 2), 16);
  }

  // Il risultato è già nell'ordine corretto grazie all'inversione iniziale
  return numeroCavallo;
};

export const composeCoppiaCavalli = (idInfoAggiuntiva: string): string => {
  const numeroCavallo1 = parseInt(idInfoAggiuntiva.substring(idInfoAggiuntiva.length - 2), 16);
  const numeroCavallo2 = parseInt(
    idInfoAggiuntiva.substring(idInfoAggiuntiva.length - 4, idInfoAggiuntiva.length - 2),
    16
  );

  return `[${numeroCavallo1} - ${numeroCavallo2}]`;
};
