export const getExpirationDateFromMilliseconds = (ms: number): number => {
  return new Date().getTime() + ms * 1000;
};

export const toUTC = (dt: Date): Date => {
  // add offset
  dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
  // soulf be UTC time
  return dt;
};
export const utcNow = (): Date => {
  // new date
  return toUTC(new Date());
};
