/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-288.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco288 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-288', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="35.482"
      viewBox="0 0 38.439 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M320.957,417.478a1.478,1.478,0,1,0-2.218,1.273v.945a.739.739,0,0,0,1.478,0v-.945A1.474,1.474,0,0,0,320.957,417.478Z"
        transform="translate(-300.259 -389.388)"
        fill="currentColor"
      />
      <g transform="translate(10.349 22.177)">
        <path
          className="a"
          d="M322.784,423.306H310.957A2.96,2.96,0,0,1,308,420.349v-7.392A2.96,2.96,0,0,1,310.957,410h11.828a2.96,2.96,0,0,1,2.957,2.957v7.392A2.96,2.96,0,0,1,322.784,423.306Zm-11.828-11.827a1.48,1.48,0,0,0-1.478,1.478v7.392a1.48,1.48,0,0,0,1.478,1.478h11.828a1.48,1.48,0,0,0,1.478-1.478v-7.392a1.481,1.481,0,0,0-1.478-1.478Z"
          transform="translate(-308 -410)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(13.306 13.306)">
        <path
          className="a"
          d="M323.088,408.349a.739.739,0,0,1-.739-.739v-3.7a4.435,4.435,0,0,0-8.871,0v3.7a.739.739,0,1,1-1.478,0v-3.7a5.914,5.914,0,0,1,11.828,0v3.7A.739.739,0,0,1,323.088,408.349Z"
          transform="translate(-312 -398)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(23.655 4.435)">
        <path
          className="a"
          d="M331.175,391.914a.738.738,0,0,1-.739-.739,3.7,3.7,0,0,0-3.7-3.7.739.739,0,1,1,0-1.478,5.181,5.181,0,0,1,5.175,5.175A.739.739,0,0,1,331.175,391.914Z"
          transform="translate(-326 -386)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M324.308,405.133a.739.739,0,0,1,0-1.478,6.653,6.653,0,0,0,2.633-12.763.74.74,0,0,1-.444-.729l.007-.092c.01-.153.022-.306.022-.462a8.13,8.13,0,0,0-15.861-2.516.739.739,0,0,1-1.082.406,5.888,5.888,0,0,0-8.93,5.067.789.789,0,0,1-.645.784,5.172,5.172,0,0,0,.645,10.3h1.478a.739.739,0,0,1,0,1.478h-1.478a6.651,6.651,0,0,1-1.456-13.14,7.4,7.4,0,0,1,7.37-6.819,7.314,7.314,0,0,1,3,.65A9.609,9.609,0,0,1,328,389.61q0,.074,0,.149a8.131,8.131,0,0,1-3.694,15.375Z"
          transform="translate(-294 -380)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco288;
