/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-69.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco69 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-69', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.526"
      height="38.439"
      viewBox="0 0 32.526 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0)">
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M158.829,416.439H132.957A2.96,2.96,0,0,1,130,413.482V380.957A2.96,2.96,0,0,1,132.957,378h24.394a2.22,2.22,0,0,1,2.218,2.218v29.569A2.22,2.22,0,0,1,157.351,412H132.957a1.478,1.478,0,1,0,0,2.957h25.873a.739.739,0,0,1,0,1.479Zm-25.873-36.961a1.48,1.48,0,0,0-1.478,1.478v29.966a2.935,2.935,0,0,1,1.478-.4h24.394a.74.74,0,0,0,.739-.739V380.218a.74.74,0,0,0-.739-.739Z"
            transform="translate(-130 -378)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(28.09 4.435)">
          <path
            className="a"
            d="M171.7,391.392h-2.957a.739.739,0,0,1,0-1.478h2.218v-4.435h-2.218a.739.739,0,0,1,0-1.478H171.7a.739.739,0,0,1,.739.739v5.914A.739.739,0,0,1,171.7,391.392Z"
            transform="translate(-168 -384)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(28.09 13.306)">
          <path
            className="a"
            d="M171.7,400.435h-2.957a.739.739,0,1,1,0-1.478h2.218v-2.218a.739.739,0,0,1,1.478,0V399.7A.739.739,0,0,1,171.7,400.435Z"
            transform="translate(-168 -396)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(28.09 19.22)">
          <path
            className="a"
            d="M171.7,408.435h-2.957a.739.739,0,0,1,0-1.478h2.218v-2.218a.739.739,0,0,1,1.478,0V407.7A.739.739,0,0,1,171.7,408.435Z"
            transform="translate(-168 -404)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(5.914 4.435)">
          <path
            className="a"
            d="M146.871,401.741a8.871,8.871,0,1,1,8.871-8.871A8.881,8.881,0,0,1,146.871,401.741Zm0-16.263a7.392,7.392,0,1,0,7.392,7.392A7.4,7.4,0,0,0,146.871,385.479Z"
            transform="translate(-138 -384)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M140.739,411.478h13.306a.739.739,0,1,0,0-1.478H140.739a.739.739,0,1,0,0,1.478Zm13.306,1.478H140.739a.739.739,0,0,0,0,1.478h13.306a.739.739,0,0,0,0-1.478Z"
          transform="translate(-132.608 -386.345)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M153.179,397.138a6.792,6.792,0,0,0-3.243-2.213,3.7,3.7,0,1,0-3.523,0,6.792,6.792,0,0,0-3.243,2.213.739.739,0,1,0,1.138.943,4.807,4.807,0,0,1,7.732,0,.739.739,0,1,0,1.138-.943Zm-5-7.66a2.218,2.218,0,1,1-2.218,2.218A2.22,2.22,0,0,1,148.174,389.478Z"
          transform="translate(-133.39 -380.608)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco69;
