/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\gift.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Gift = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-gift', className].join(' ');
  return (
    <svg
      id="gift"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 32"
      fill="none"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M26.8332 8.66406H21.3532C22.0065 8.02406 22.4198 7.14406 22.4198 6.1574C22.4198 4.22406 20.8465 2.66406 18.9265 2.66406C17.7398 2.66406 16.6465 3.2774 16.0065 4.25073C15.3932 3.57073 14.5265 3.17073 13.5932 3.17073C11.8065 3.17073 10.3532 4.62406 10.3532 6.41073C10.3532 7.29073 10.6998 8.0774 11.2732 8.66406H5.49984C4.21984 8.66406 3.1665 9.7174 3.1665 10.9974V14.9974C3.1665 16.0774 3.91317 16.9841 4.91317 17.2507V26.3307C4.91317 27.6107 5.9665 28.6641 7.2465 28.6641H25.0865C26.3665 28.6641 27.4198 27.6107 27.4198 26.3307V17.2507C28.4198 16.9841 29.1665 16.0774 29.1665 14.9974V10.9974C29.1665 9.7174 28.1132 8.66406 26.8332 8.66406ZM27.1665 10.9974V14.9974C27.1665 15.1841 27.0198 15.3307 26.8332 15.3307H17.2198V10.6641H26.8332C27.0198 10.6641 27.1665 10.8107 27.1665 10.9974ZM15.1532 6.41073C15.1532 7.2774 14.4465 7.98406 13.5798 7.98406C12.7132 7.98406 12.0065 7.2774 12.0065 6.41073C12.0065 5.54406 12.7132 4.8374 13.5798 4.8374C14.4465 4.8374 15.1532 5.54406 15.1532 6.41073ZM17.0865 6.1574C17.0865 5.14406 17.9132 4.33073 18.9132 4.33073C19.9132 4.33073 20.7398 5.1574 20.7398 6.1574C20.7398 7.1574 19.9132 7.98406 18.9132 7.98406C17.9132 7.98406 17.0865 7.1574 17.0865 6.1574ZM5.1665 10.9974C5.1665 10.8107 5.31317 10.6641 5.49984 10.6641H15.2065V15.3307H5.49984C5.31317 15.3307 5.1665 15.1841 5.1665 14.9974V10.9974ZM6.91317 26.3307V17.3307H15.2198V26.6641H7.2465C7.05984 26.6641 6.91317 26.5174 6.91317 26.3307ZM25.4198 26.3307C25.4198 26.5174 25.2732 26.6641 25.0865 26.6641H17.2065V17.3307H25.4198V26.3307Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Gift;
