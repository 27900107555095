/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\dices.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Dices = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-dices', className].join(' ');
  return (
    <svg
      id="dices"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M0.25 5.19365C0.30236 4.9829 0.32956 4.76134 0.411159 4.56206C0.891918 3.38602 1.3822 2.21336 1.87656 1.04273C2.24716 0.165255 3.20323 -0.227209 4.08723 0.133507C5.26431 0.613785 6.43935 1.09744 7.6137 1.58447C8.4943 1.94924 8.8955 2.9044 8.53374 3.77984C8.05094 4.94912 7.56339 6.11706 7.07243 7.28297C6.69911 8.16922 5.74575 8.56236 4.85019 8.19759C3.67787 7.72001 2.50828 7.23703 1.34004 6.75203C0.679759 6.47777 0.28196 5.88874 0.25 5.19365ZM5.45879 7.6707C5.95383 7.64909 6.27139 7.44981 6.43867 7.05532C6.93575 5.87996 7.42602 4.70122 7.91154 3.52112C8.13662 2.97465 7.90406 2.42749 7.3553 2.20053C6.18095 1.71417 5.00591 1.23051 3.82883 0.750235C3.28007 0.526646 2.72792 0.754964 2.49808 1.30077C2.00712 2.46667 1.52024 3.63393 1.03744 4.80322C0.808278 5.35848 1.04016 5.90428 1.59844 6.13597C2.76735 6.6203 3.93763 7.10126 5.10927 7.57816C5.23779 7.63017 5.38059 7.65044 5.45879 7.6707Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
      <path
        d="M5.303 10.8823C5.34312 10.1015 5.65048 9.56106 6.306 9.28006C7.51572 8.76128 8.7336 8.26141 9.95624 7.77438C10.7906 7.44203 11.7399 7.85408 12.0887 8.67819C12.5878 9.85896 13.0808 11.0424 13.5697 12.2273C13.922 13.0797 13.5357 14.0356 12.6817 14.3956C11.4842 14.9002 10.2826 15.394 9.077 15.8783C8.23312 16.2174 7.27976 15.8243 6.92412 14.9914C6.41616 13.8018 5.9218 12.6069 5.43221 11.4099C5.35469 11.2208 5.33292 11.01 5.303 10.8823ZM10.4982 8.32828C10.4248 8.34517 10.2983 8.358 10.1854 8.40394C8.98248 8.89435 7.77888 9.38476 6.5814 9.88665C6.0748 10.0988 5.85788 10.6405 6.05032 11.1519C6.10948 11.3099 6.17816 11.4639 6.24344 11.6193C6.6698 12.6413 7.09548 13.664 7.52456 14.6854C7.7102 15.1258 8.07944 15.3602 8.53708 15.3251C8.65336 15.3163 8.771 15.2818 8.8798 15.2373C10.046 14.7617 11.2115 14.2835 12.3757 13.8025C12.9102 13.5816 13.1726 13.048 12.9652 12.536C12.4729 11.3221 11.967 10.1136 11.4522 8.90921C11.289 8.52755 10.966 8.34585 10.4982 8.32828Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
      <path
        d="M4.47755 4.66339C4.19535 4.66542 3.96687 4.44385 3.96619 4.1669C3.96619 3.89738 4.18583 3.67446 4.45919 3.66906C4.74275 3.66365 4.97259 3.87914 4.97803 4.15677C4.98415 4.43304 4.75839 4.66136 4.47755 4.66339Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
      <path
        d="M5.14939 6.33524C4.87399 6.3393 4.64143 6.1103 4.64075 5.8347C4.64007 5.56315 4.85631 5.34024 5.12899 5.33213C5.40575 5.32403 5.64103 5.54626 5.64783 5.82254C5.65463 6.09679 5.42615 6.33119 5.14939 6.33524Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
      <path
        d="M4.30415 2.50855C4.30007 2.78686 4.06955 3.00639 3.78939 3.00032C3.51875 2.99424 3.29843 2.76997 3.29775 2.50045C3.29775 2.22485 3.52963 1.99585 3.80571 1.99855C4.08451 2.00193 4.30823 2.23092 4.30415 2.50855Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
      <path
        d="M10.6797 10.5031C10.6763 10.7787 10.4403 11.007 10.1656 11.0003C9.8868 10.9935 9.66648 10.7618 9.67328 10.4842C9.6794 10.2153 9.90652 9.99513 10.1772 9.99581C10.4532 9.99581 10.6831 10.2282 10.6797 10.5031Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
      <path
        d="M8.85141 12.6631C9.13089 12.6739 9.34577 12.907 9.33557 13.186C9.32605 13.4535 9.09281 13.673 8.82421 13.6669C8.54745 13.6608 8.32101 13.4251 8.32985 13.1508C8.33869 12.8739 8.57601 12.6523 8.85141 12.6631Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
    </svg>
  );
};
export default Dices;
