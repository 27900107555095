import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UsersSignUpDataDto } from 'types/swagger';
import { mergeSignupState } from './signUpHooks';
import {
  AccessInfo,
  AntiFraudType,
  ContactsData,
  DocumentData,
  ExtraInformation,
  MaximalData,
  PersonalInformation,
  PrivacyConsentsData,
  PromoData,
  RegistrationFromPage,
  SignupState,
  SpidType,
} from './types';

export const initialSignupState: SignupState = {
  cap: '',
  city: '',
  name: '',
  email: '',
  isSpid: false,
  pinCode: '',
  surname: '',
  address: '',
  province: '',
  username: '',
  password: '',
  promoCode: '',
  birthDate: '',
  telephone: '',
  releasedBy: '',
  fiscalCode: '',
  cityOfBirth: '',
  domicileCap: '',
  releaseDate: '',
  contractCode: '',
  domicileCity: '',
  secretAnswer: '',
  documentType: '',
  releasePlace: '',
  antiFraudCode: '',
  weeklyMaximal: '',
  documentNumber: '',
  expirationDate: '',
  secretQuestion: '',
  acceptContract: false,
  profilingUsage: undefined,
  marketingUsage: undefined,
  provinceOfBirth: '',
  domicileAddress: '',
  isExternalState: false,
  domicileProvince: '',
  isSameAsDomicile: true,
  privacyInformation: false,
  isFiscalCodeCalculated: false,
  acceptTermAndCondition: false,
  isExternalResidencial: false,
  registrationFromPage: RegistrationFromPage.REGISTRATION,
  triggerForm: false,
};

export type UnregisterModal = {
  feedbackUnregistration: string;
};

const signupSlice = createSlice({
  name: 'Signup',
  initialState: initialSignupState,
  reducers: {
    setAccessData: (state, action: PayloadAction<AccessInfo>) => {
      state.username = action.payload.username;
      state.password = action.payload.password;
      state.secretQuestion = action.payload.secretQuestion;
      state.secretAnswer = action.payload.secretAnswer;
    },
    setContacts: (state, action: PayloadAction<ContactsData>) => {
      state.email = action.payload.email;
      state.telephone = action.payload.telephone;
    },
    setPersonalData: (state, action: PayloadAction<PersonalInformation>) => {
      state.name = action.payload.name;
      state.surname = action.payload.surname;
      state.birthDate = action.payload.birthDate;
      state.gender = action.payload.gender;
      state.provinceOfBirth = action.payload.provinceOfBirth;
      state.cityOfBirth = action.payload.cityOfBirth;
      state.fiscalCode = action.payload.fiscalCode;
      state.address = action.payload.address;
      state.province = action.payload.province;
      state.city = action.payload.city;
      state.cap = action.payload.cap;
      state.domicileAddress = action.payload.domicileAddress;
      state.domicileCap = action.payload.domicileCap;
      state.domicileCity = action.payload.domicileCity;
      state.domicileProvince = action.payload.domicileProvince;
    },
    setDocumentData: (state, action: PayloadAction<DocumentData>) => {
      state.documentType = action.payload.documentType;
      state.documentNumber = action.payload.documentNumber;
      state.releasedBy = action.payload.releasedBy;
      state.releasePlace = action.payload.releasePlace;
      state.releaseDate = action.payload.releaseDate;
      state.expirationDate = action.payload.expirationDate;
    },
    setPrivacyConsents: (state, action: PayloadAction<PrivacyConsentsData>) => {
      state.acceptContract = action.payload.acceptContract;
      state.acceptTermAndCondition = action.payload.acceptTermAndCondition;
      state.privacyInformation = action.payload.privacyInformation;
      state.profilingUsage = action.payload.profilingUsage;
      state.marketingUsage = action.payload.marketingUsage;
    },
    setEmailSessionID: (state, action: PayloadAction<ContactsData['userSessionEmail']>) => {
      state.userSessionEmail = action.payload;
    },
    setTelephoneSessionID: (state, action: PayloadAction<ContactsData['userSessionTelephone']>) => {
      state.userSessionTelephone = action.payload;
    },
    setPromoCode: (state, action: PayloadAction<PromoData>) => {
      state.promoCode = action.payload.promoCode;
    },
    setMaximal: (state, action: PayloadAction<MaximalData>) => {
      state.weeklyMaximal = action.payload.weeklyMaximal;
    },
    setContractAccount: (state, action: PayloadAction<{ contractCode: string; pincode: string }>) => {
      (state.contractCode = action.payload.contractCode), (state.pinCode = action.payload.pincode);
    },
    setIsSpid: (state, action: PayloadAction<SpidType>) => {
      state.isSpid = action.payload.isSpid;
      state.spidCode = action.payload.spidCode;
    },
    setIsSameAsResidence: (state, { payload }: PayloadAction<boolean>) => {
      state.isSameAsDomicile = payload;
    },
    setIsCalculatedFiscalCode: (state, { payload }: PayloadAction<boolean>) => {
      state.isFiscalCodeCalculated = payload;
    },
    setAntiFraudCode: (state, { payload: code }: PayloadAction<AntiFraudType['antiFraudCode']>) => {
      state.antiFraudCode = code;
    },
    setIsExternalState: (state, { payload: isExternalState }: PayloadAction<boolean>) => {
      state.isExternalState = isExternalState;
    },
    setSignup: (state, { payload }: PayloadAction<Partial<UsersSignUpDataDto>>) => {
      mergeSignupState(state, payload);
    },
    setExtraData: (state, { payload }: PayloadAction<ExtraInformation>) => {
      state.btag = payload.btag;
      state.provenienceCode = payload.provenienceCode;
    },
    setFiscalCodeSignup: (state, { payload }: PayloadAction<string>) => {
      state.fiscalCode = payload;
    },
    setIsExternalResidencial: (state, { payload: isExternalResidencial }: PayloadAction<boolean>) => {
      state.isExternalResidencial = isExternalResidencial;
    },
    setRegistrationFromPage: (state, { payload }: PayloadAction<RegistrationFromPage>) => {
      state.registrationFromPage = payload;
    },
    setTriggerForm: (state, { payload }: PayloadAction<boolean>) => {
      state.triggerForm = payload;
    },
    resetSignUp: () => initialSignupState,
  },
});

export const {
  setIsSpid,
  setSignup,
  setMaximal,
  setContacts,
  resetSignUp,
  setExtraData,
  setPromoCode,
  setAccessData,
  setPersonalData,
  setDocumentData,
  setAntiFraudCode,
  setEmailSessionID,
  setIsExternalState,
  setPrivacyConsents,
  setContractAccount,
  setIsSameAsResidence,
  setTelephoneSessionID,
  setIsCalculatedFiscalCode,
  setFiscalCodeSignup,
  setIsExternalResidencial,
  setRegistrationFromPage,
  setTriggerForm,
} = signupSlice.actions;

export default signupSlice.reducer;
