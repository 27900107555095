/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-396.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco396 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-396', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="41.396"
      viewBox="0 0 38.439 41.396"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M133.556,238.154a3.7,3.7,0,1,0-4.762,0A5.176,5.176,0,0,0,126,242.743a.739.739,0,1,0,1.478,0,3.7,3.7,0,0,1,7.392,0,.739.739,0,1,0,1.478,0A5.176,5.176,0,0,0,133.556,238.154Zm-2.381-.585a2.218,2.218,0,1,1,2.218-2.218A2.22,2.22,0,0,1,131.175,237.569Zm16.426,6.5a3.7,3.7,0,1,0-4.762,0,5.176,5.176,0,0,0-2.794,4.589.739.739,0,1,0,1.478,0,3.7,3.7,0,0,1,7.392,0,.739.739,0,1,0,1.478,0A5.176,5.176,0,0,0,147.6,244.068Zm-2.381-.585a2.218,2.218,0,1,1,2.218-2.218A2.22,2.22,0,0,1,145.22,243.482Zm16.426-5.328a3.7,3.7,0,1,0-4.762,0,5.176,5.176,0,0,0-2.794,4.589.739.739,0,1,0,1.478,0,3.7,3.7,0,0,1,7.392,0,.739.739,0,1,0,1.478,0A5.176,5.176,0,0,0,161.646,238.154Zm-2.381-.585a2.218,2.218,0,1,1,2.218-2.218A2.22,2.22,0,0,1,159.265,237.569Zm-18.7-5.7a.738.738,0,0,0-1.045,0l-2.957,2.957a.739.739,0,1,0,1.045,1.045l2.957-2.957A.738.738,0,0,0,140.568,231.872Zm13.306,2.957-2.957-2.957a.739.739,0,0,0-1.045,1.045l2.957,2.957a.739.739,0,1,0,1.045-1.045Zm-9.393-2.434v2.957a.739.739,0,0,0,1.478,0v-2.957a.739.739,0,0,0-1.478,0Zm1.02-7.333a.769.769,0,0,0-.806.155.739.739,0,0,0,.525,1.264.62.62,0,0,0,.281-.059.853.853,0,0,0,.244-.155c.03-.037.059-.074.089-.118a.411.411,0,0,0,.067-.126.47.47,0,0,0,.044-.133,1,1,0,0,0,.015-.148.778.778,0,0,0-.214-.525A.854.854,0,0,0,145.5,225.061Zm-6.934,2.159h.739V228.7a1.48,1.48,0,0,0,1.478,1.478h8.871a1.48,1.48,0,0,0,1.478-1.478V227.22h.739A2.22,2.22,0,0,0,154.09,225v-2.218c0-3.088-2.274-5.436-5.793-6.294a4.8,4.8,0,1,0-6.155,0c-3.519.858-5.793,3.205-5.793,6.294V225A2.22,2.22,0,0,0,138.567,227.22Zm2.218,1.478v-5.914h8.871V228.7Zm1.109-15.893a3.326,3.326,0,1,1,3.326,3.327A3.331,3.331,0,0,1,141.893,212.8Zm-4.066,9.979c0-3.047,3.04-5.175,7.392-5.175s7.392,2.128,7.392,5.175V225a.74.74,0,0,1-.739.739h-.739v-2.957a1.48,1.48,0,0,0-1.478-1.478h-8.871a1.48,1.48,0,0,0-1.478,1.478v2.957h-.739a.74.74,0,0,1-.739-.739Z"
        transform="translate(-126 -208)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco396;
