import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type SettingsState = {
  isVisualizzazioneEstesa: boolean;
};

const initialState: SettingsState = {
  isVisualizzazioneEstesa: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setIsVisualizzazioneEstesa: (state, { payload }: PayloadAction<boolean>) => {
      state.isVisualizzazioneEstesa = payload;
    },
  },
});

export const { setIsVisualizzazioneEstesa } = settingsSlice.actions;

export default settingsSlice.reducer;
