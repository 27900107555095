/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-365.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco365 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-365', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.526"
      height="38.439"
      viewBox="0 0 32.526 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M236.916,409.047a.739.739,0,0,0-.739.739v.739h-20.7V383.914h20.7v.739a.739.739,0,0,0,1.478,0V381.7a3.7,3.7,0,0,0-3.7-3.7H217.7a3.7,3.7,0,0,0-3.7,3.7v31.047a3.7,3.7,0,0,0,3.7,3.7h16.263a3.7,3.7,0,0,0,3.7-3.7v-2.957A.739.739,0,0,0,236.916,409.047ZM215.478,381.7a2.22,2.22,0,0,1,2.218-2.218h16.263a2.22,2.22,0,0,1,2.218,2.218v.739h-20.7Zm20.7,31.047a2.22,2.22,0,0,1-2.218,2.218H217.7a2.22,2.22,0,0,1-2.218-2.218V412h20.7Zm8.871-25.873H217.7a.739.739,0,1,0,0,1.478h21.481a6.662,6.662,0,0,0,5.87,5.871v6a6.662,6.662,0,0,0-5.87,5.87H217.7a.739.739,0,0,0,0,1.478h27.351a1.48,1.48,0,0,0,1.478-1.478V388.349A1.48,1.48,0,0,0,245.047,386.871Zm0,19.22h-4.376a5.178,5.178,0,0,1,4.376-4.376Zm0-13.365a5.178,5.178,0,0,1-4.376-4.376h4.376Zm-8.131,4.494a8.871,8.871,0,1,0-8.871,8.871A8.881,8.881,0,0,0,236.916,397.22Zm-8.871,7.392a7.392,7.392,0,1,1,7.392-7.392A7.4,7.4,0,0,1,228.045,404.612Zm-.739-9.61h2.957a.739.739,0,0,0,0-1.478h-1.478v-.739a.739.739,0,1,0-1.478,0v.739a2.218,2.218,0,0,0,0,4.435h1.478a.739.739,0,1,1,0,1.478h-2.957a.739.739,0,1,0,0,1.478h1.478v.739a.739.739,0,0,0,1.478,0v-.739a2.218,2.218,0,1,0,0-4.435h-1.478a.739.739,0,1,1,0-1.478Z"
        transform="translate(-214 -378)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco365;
