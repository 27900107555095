/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-93.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina93 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-93', className].join(' ');
  return (
    <svg
      id="disciplina_biliardo-pool"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M8.78 5.867C3.93 5.877-.008 9.9 0 14.837c.007 4.846 4.025 8.82 8.914 8.813 4.91-.007 8.877-3.988 8.871-8.904-.007-4.966-3.984-8.888-9.006-8.88ZM6.357 20.126a3.847 3.847 0 0 1-3.851-3.854c.003-2.107 1.729-3.82 3.851-3.823 2.111-.004 3.826 1.717 3.83 3.84a3.836 3.836 0 0 1-3.83 3.837ZM23.94 5.73c-.434-3.33-3.519-5.906-6.932-5.724-1.747.094-3.247.768-4.475 2.018a6.394 6.394 0 0 0-1.638 2.867c4.355 1.148 6.987 3.894 7.934 8.28 3.18-.69 5.586-3.802 5.111-7.44Zm-4.457 3.364a2.883 2.883 0 0 1-2.866-2.909c.009-1.587 1.32-2.871 2.92-2.859 1.57.012 2.861 1.321 2.857 2.898-.004 1.586-1.315 2.879-2.91 2.87h-.001Zm-13.551 5.92c-.027.246-.047.45-.073.668.808-.25 1.267-.125 1.612.416a1.51 1.51 0 0 1-.134 1.825c-.428.474-1.162.625-1.72.29-.245-.148-.44-.406-.604-.65-.125-.188-.084-.412.15-.534.218-.114.386-.044.54.21.273.453.756.535 1.087.188a.667.667 0 0 0 .077-.838.69.69 0 0 0-.834-.25c-.14.059-.264.152-.401.216-.329.157-.616-.023-.588-.387.04-.512.104-1.023.166-1.533.028-.229.163-.356.403-.356H7.1c.237 0 .34.138.34.365 0 .227-.108.364-.341.368-.38.008-.758.002-1.166.002Zm13.367-9.547a.373.373 0 1 1 .029.143.37.37 0 0 1-.028-.143h-.001Zm.817 1.361a.46.46 0 0 1-.449.458.453.453 0 0 1-.009-.906.46.46 0 0 1 .458.45v-.002Zm.235-.775c.274-.454.226-.914-.129-1.207a.862.862 0 0 0-1.102.006c-.353.305-.399.751-.117 1.22-.43.573-.44 1.116-.036 1.501a1.013 1.013 0 0 0 1.33.067c.48-.379.496-.872.054-1.587Zm-.68-.957a.373.373 0 1 1-.142.027.373.373 0 0 1 .143-.029v.002Zm-.004 2.193a.453.453 0 0 1-.009-.906.453.453 0 0 1 .01.906Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina93;
