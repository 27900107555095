import {
  DisciplinaMenuItem,
  ExtendedDisciplinaMenuItem,
  LIVE_MENU_AVVENIMENTI_API,
  LiveNavState,
  initDisciplinaPayload,
  initManifestazionePayload,
} from './types';

import { AppFragment } from 'enums/path-fragment';
import { LiveFavouriteItem } from '../liveInternalLayout/liveFavouriteAvvenimentoSlice';
import { ScommessaResponse } from 'lib/api/sport/sportScommesseBySlugResponse';
import { SportsMenuDisciplinaItem } from 'types/swagger';
import { isSnaiSite } from 'utility/constant';

export const miLive: DisciplinaMenuItem = {
  id: '00',
  slug: AppFragment.Live,
  isLive: true,
  isRoot: true,
  counter: 0,
  isActive: true,
  isPrematch: true,
  descrizioneTrKey: 'MI-R00T-0',
  isVirtual: false,
};
export const miSport: DisciplinaMenuItem = {
  id: '01',
  slug: AppFragment.Sport,
  isLive: true,
  isRoot: true,
  counter: 0,
  isActive: true,
  isPrematch: true,
  descrizioneTrKey: 'MI-R00T-1',
  isVirtual: false,
};
export const miIppica: DisciplinaMenuItem = {
  id: '02',
  slug: AppFragment.Ippica,
  isLive: false,
  isRoot: true,
  counter: 0,
  isActive: true,
  isPrematch: true,
  descrizioneTrKey: 'MI-R00T-2',
  isVirtual: false,
};

export const miMarcatori: DisciplinaMenuItem = {
  id: '003',
  slug: `${AppFragment.Sport}/${AppFragment.Marcatori}`,
  isLive: false,
  isRoot: true,
  counter: 0,
  isActive: true,
  isPrematch: true,
  descrizioneTrKey: 'MI-R00T-003',
  customDescription: 'top-sport-sport_marcatori',
  isVirtual: false,
};

export const miStreaming: DisciplinaMenuItem = {
  id: '001',
  slug: 'streaming',
  isLive: true,
  isRoot: false,
  counter: 0,
  isActive: true,
  isPrematch: false,
  descrizioneTrKey: 'MI-R00T-3',
  isVirtual: false,
};

export const rootItems = [miLive, miSport, miIppica, miMarcatori];
export const rootSlugs: Array<string> = rootItems.map((x) => `${x.slug}`);
// NOTE aggiunto dopo per miStreaming ha slug figlio di live (non è root)
// rootItems.push(miStreaming);

export const isDisciplinaActive = (item: ExtendedDisciplinaMenuItem, isLive?: Boolean | boolean): boolean => {
  let wLive = isLive ?? false;

  const { slug, isPrematch } = item ?? {};

  const ckActive = (wLive === false && isPrematch === true) || (wLive === true && isLive === true);
  if (ckActive) return !!slug && rootSlugs.includes(slug) ? false : true;

  return false;
};

export const buildMenu = (
  t: (_k?: string, _f?: string) => string | undefined,
  discipline: Record<number, ExtendedDisciplinaMenuItem>,
  isTop?: Boolean | boolean,
  isLive?: Boolean | boolean,
  isActiveMarcatori?: boolean,
  isActiveStreaming?: Boolean | boolean
) => {
  let wTop = isTop ?? false;
  let wLive = isLive ?? false;

  let result = Object.values(discipline)
    .filter((x) => isDisciplinaActive(x, wLive))
    .sort((a, b) => {
      const order = (a?.priorita ?? 0) - (b?.priorita ?? 0);
      if (order === 0) {
        return `${t(a?.descrizioneTrKey, a?.descrizione)}` > `${t(b?.descrizioneTrKey, b?.descrizione)}` ? 1 : -1;
      }
      return order;
    })
    .map(({ id, counter, isVirtual }) => ({ id: id.toString(), counter, isActive: true, isVirtual }));

  if (!isActiveStreaming) {
    result = result.filter((item) => item.id !== '-1');
  }

  if (wTop) {
    const tmp = isSnaiSite ? [miLive] : [];
    if (wLive) {
      // "live" menu
      // tmp.push(miStreaming);
      if (isSnaiSite) {
        // for snai, compose [sport + live] sequence
        tmp.splice(0, 0, miSport);
      }
      // for happybet return [live]
    } else {
      // "sport" menu
      if (isSnaiSite) {
        // for snai compose [live + sport] sequence
        tmp.push(miSport);

        if (isActiveMarcatori) {
          result.splice(1, 0, miIppica, miMarcatori);
        } else {
          // add ippica as second element of disciplina-list
          result.splice(1, 0, miIppica);
        }
      } else {
        // for happybet return [sport]
        tmp.splice(0, 1, miSport);
        if (isActiveMarcatori) {
          tmp.push(miMarcatori);
        }
      }
    }
    result.unshift(...tmp);
  }

  return result ?? [];
};

export const mergeMenu = (state: LiveNavState, payload: Array<SportsMenuDisciplinaItem>): void => {
  if (!payload) return;

  Object.keys(state.discipline).forEach((k) => {
    state.discipline[k].isTop = false;
    state.discipline[k].isLive = false;
    state.discipline[k].counter = 0;
  });
  for (const k in payload) {
    const disciplina = payload[k];
    const prev = state.discipline[`${disciplina.id}`] ?? {};
    Reflect.set(state.discipline, disciplina.id, { ...disciplina, manifestazioni: prev?.manifestazioni ?? {} });
  }

  delete state.isLoading[LIVE_MENU_AVVENIMENTI_API];
};

export const mergeDisciplina = (state: LiveNavState, payload: initDisciplinaPayload): void => {
  if (!payload) return;

  const { id, slug, isLoading, data } = payload;

  if (isLoading) {
    state.isLoading[slug] = true;
  } else {
    delete state.isLoading[slug];
  }

  const disciplina = state.discipline ? state.discipline[id] : undefined;
  if (disciplina) {
    if (data) {
      disciplina.manifestazioni = {};
      for (const m of data) {
        disciplina.manifestazioni[m.id] = m;
      }
    } else {
      delete disciplina.manifestazioni;
    }
  }
};

export const mergeManifestazione = (state: LiveNavState, payload: initManifestazionePayload): void => {
  if (!payload) return;

  const { id, idDisciplina, slug, isLoading, data } = payload;

  if (isLoading) {
    state.isLoading[slug] = true;
  } else {
    delete state.isLoading[slug];
  }

  const disciplina = state.discipline ? state.discipline[idDisciplina] : undefined;
  if (disciplina) {
    const manifestazione = disciplina.manifestazioni ? disciplina.manifestazioni[id] : undefined;
    if (manifestazione) {
      manifestazione.scommesse = data;
    }
  }
};

export const cleanFavouriteEntry = (entry: ScommessaResponse, item: LiveFavouriteItem): void => {
  const { keyAvvenimento, infoTipoScommessaKey } = item;

  const prefix: string[] = [];
  const nextAvvenimenti = (entry?.avvenimentoList || []).filter((avv) => avv.key === keyAvvenimento) ?? [];
  const nextInfoTipoScommessaMap: Record<string, any> = {};
  for (let key of infoTipoScommessaKey) {
    prefix.push(`${keyAvvenimento}-${key}`);
    nextInfoTipoScommessaMap[key] = entry.infoTipoScommessaMap[key];
  }

  const nextEsitoMap = {};
  Object.keys(entry!.esitoMap)
    .filter((x) => prefix.some((prefix) => x.startsWith(`${prefix}-`)))
    .forEach((k) => {
      nextEsitoMap[k] = entry!.esitoMap[k];
    });

  const nextInfoAggiuntivaMap = {};
  Object.keys(entry!.infoAggiuntivaMap)
    .filter((x) => prefix.some((prefix) => prefix === x))
    .forEach((k) => {
      nextInfoAggiuntivaMap[k] = entry!.infoAggiuntivaMap[k];
    });

  const nextscommessaMap = {};
  Object.keys(entry!.scommessaMap)
    .filter((x) => prefix.some((prefix) => prefix === x))
    .forEach((k) => {
      nextscommessaMap[k] = entry!.scommessaMap[k];
    });

  entry.infoTipoScommessaMap = nextInfoTipoScommessaMap;
  entry.infoAggiuntivaMap = nextInfoAggiuntivaMap;
  entry.avvenimentoList = nextAvvenimenti;
  entry.scommessaMap = nextscommessaMap;
  entry.esitoMap = nextEsitoMap;
};

export const cleanFavourites = (state: LiveNavState, payload: Array<LiveFavouriteItem>): void => {
  for (let slug of Object.keys(state.favourites)) {
    const item = payload.find((x) => x.slugAvvenimento === slug);
    if (item) {
      // pulire i dati non necessari
      cleanFavouriteEntry(state.favourites[slug], item);
    } else {
      // l'evento non esiste - rimuoverlo dallo stato
      delete state.favourites[slug];
    }
  }
};
