import { hasValue, isClientSide, isTruthy } from 'utility/functions';

import { AuthTokenType } from 'features/auth/types';
import { getLngFromCultureName } from 'hooks/useLingUI';
import { getUseSecondary } from 'lib/mwNodeSwitch';
import { TokenResponse } from 'types/login';
import { TOKEN_BASE_REFRESH, defaultLang } from 'utility/constant';
import { utcNow } from 'utility/date';
import { emptyToken } from '.';

interface doGetProps {
  body?: any;
  headers?: Record<string, any>;
  onError?: (_: Response) => Promise<TokenResponse>;
}

// TODO: when update dto, switch to authCli method
const accesstokenUrl = `${process.env.NEXT_API_BASE_URL ?? process.env.NEXT_PUBLIC_API_BASE_URL}/auth/accesstoken`;

export const doGet = async (props?: doGetProps): Promise<TokenResponse> => {
  console.log('');
  console.log('  AUTH.web: Access-Token:');
  console.log('');

  const { headers: headParams, body, onError } = props ?? {};

  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Language: getLngFromCultureName(defaultLang),
    Country: `${process.env.NEXT_PUBLIC_COUNTRY}`,
  };

  if (headParams) {
    for (const [name, value] of Object.entries(headParams)) {
      Reflect.set(headers, name, value);
    }
  }

  if (isClientSide()) {
    const head = getUseSecondary();
    if (head) {
      Reflect.set(headers, head.name, head.value);
    }
  } else {
    // TODO : akamai escape rule
    Reflect.set(headers, 'AU-AV', 'zWthffFpLZWp9bLRCci1rZXk=');
  }

  const reqBody = body ?? {
    scope: 'common',
    client_id: `${process.env.NEXT_PUBLIC_AUTH_CLIENT_ID}`,
    grant_type: 'client_credentials',
    client_secret: `${process.env.NEXT_PUBLIC_AUTH_CLIENT_SECRET}`,
  };

  let res: Response | undefined;
  try {
    res = await fetch(accesstokenUrl, {
      body: JSON.stringify(reqBody),
      method: 'POST',
      headers,
    });
  } catch (e) {
    const error = JSON.stringify(e);
    console.log('  accesstoken: api call failed');
    console.log(error);
    console.log('');

    res = {
      ok: false,
      status: 500,
      json: () =>
        new Promise((resolve) =>
          resolve({
            data: emptyToken,
            error,
          })
        ),
    } as Response;
  }

  if (isTruthy(res.ok)) {
    console.log('  accesstoken: OK');
    const result = await res.json();
    console.log('');

    return Promise.resolve(result);
  } else {
    console.log('  accesstoken: KO');
    console.log(`${accesstokenUrl} -> ${res.status} (${res.statusText})`);
    console.log(`  head: ${JSON.stringify(headers)}`);
    console.log(`  body: ${JSON.stringify(reqBody)}`);
    if (typeof onError === 'function') {
      await onError(res);
    } else {
      console.log(`  response: ${await res.text()}`);
    }

    console.log('');
  }

  return Promise.resolve(emptyToken);
};

interface TokenUtilsProps {
  request: {
    headers: Record<string, any>;
    cookies: Record<string, any>;
  };
  onExceptionHandler: (_: any) => void;
}

interface TokenUtilsType {
  status: number;
  data?: AuthTokenType | any;
}
export const getAccessToken = async ({ request, onExceptionHandler }: TokenUtilsProps): Promise<TokenUtilsType> => {
  const ip = request.headers['true-client-ip'];
  const headers = {};
  if (ip) {
    headers['True-Client-IP'] = ip;
  }

  const body = {
    scope: 'common',
    client_id: `${process.env.NEXT_PUBLIC_AUTH_CLIENT_ID}`,
    grant_type: 'client_credentials',
    client_secret: `${process.env.NEXT_PUBLIC_AUTH_CLIENT_SECRET}`,
  };

  try {
    const dtNow = utcNow();
    const data = await doGet({
      headers,
      body,
      onError: async (r: Response) => {
        console.log(`accessToken: KO #1. IP:${ip}`);
        console.log(`${accesstokenUrl} -> ${r.status} (${r.statusText})`);
        console.log(`head: ${JSON.stringify(headers)}`);
        console.log(`error: ${JSON.stringify(await r.text())}`);

        try {
          onExceptionHandler({
            exception: new Error(JSON.stringify(r)),
            properties: {
              id: `Accesstoken: att.no #1`,
              ip,
              statu: r.status,
              statusText: r.statusText,
            },
          });
        } catch (e) {
          console.log('tracer error: ', e);
        }

        // await new Promise(resolve => setTimeout(resolve, 350));

        const data = await doGet({
          headers,
          body,
          onError: async (r: Response) => {
            console.log(`accessToken: KO #2. IP:${ip}`);
            console.log(`${accesstokenUrl} -> ${r.status} (${r.statusText})`);

            try {
              onExceptionHandler({
                exception: new Error(JSON.stringify(r)),
                properties: {
                  id: `Accesstoken: att.no #2`,
                  ip,
                  statu: r.status,
                  statusText: r.statusText,
                },
              });
            } catch (e) {
              console.log('tracer error: ', e);
            }

            return Promise.reject();
          },
        });

        return Promise.resolve(data);
      },
    });

    let { expires_in, access_token, refresh_token, ...oth } = data ?? {};

    const result: AuthTokenType = {
      ...oth,
      ts: dtNow.getTime(),
      expiresIn: expires_in,
      expireDate: dtNow.setSeconds(dtNow.getSeconds() + expires_in * 0.9),
      accessToken: access_token,
      refreshToken: refresh_token,
    };
    if (hasValue(ip)) {
      Reflect.set(result, 'clientIP', ip);
    }

    return Promise.resolve({
      status: 200,
      data: result,
    });
  } catch (error) {
    console.log('accesstoken: KO');
    console.log(`head: ${JSON.stringify(headers)}`);
    console.log(`body: ${JSON.stringify(body)}`);

    return Promise.resolve({
      status: 500,
      data: { error },
    });
  }
};

export const getRefreshToken = async ({ request, onExceptionHandler }: TokenUtilsProps): Promise<TokenUtilsType> => {
  const { refreshToken } = JSON.parse(request.cookies[TOKEN_BASE_REFRESH] ?? '{}') as AuthTokenType;
  if (!refreshToken) {
    return Promise.resolve({ status: 400, data: { error: 'Missing parameter refreshToken' } });
  }

  const headers = { Authorization: null };
  const ip = request.headers['true-client-ip'];
  if (ip) {
    headers['True-Client-IP'] = ip;
  }

  const body = {
    client_id: `${process.env.NEXT_AUTH_CLIENT_ID}`,
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    client_secret: `${process.env.NEXT_AUTH_CLIENT_SECRET}`,
  };

  try {
    const dtNow = utcNow();

    const data = await doGet({
      headers,
      body,
      onError: async (r: Response) => {
        console.log(`refreshToken: KO #1. IP:${ip}`);
        console.log(`${accesstokenUrl} -> ${r.status} (${r.statusText})`);
        console.log(`head: ${JSON.stringify(headers)}`);
        console.log(`error: ${JSON.stringify(await r.text())}`);

        try {
          onExceptionHandler({
            exception: new Error(JSON.stringify(r)),
            properties: {
              id: `Refreshtoken: att.no #1`,
              ip,
              statu: r.status,
              statusText: r.statusText,
            },
          });
        } catch (e) {
          console.log('tracer error: ', e);
        }

        const data = await doGet({
          headers,
          body,
          onError: async (r: Response) => {
            console.log(`accessToken: KO #2. IP:${ip}`);
            console.log(`${accesstokenUrl} -> ${r.status} (${r.statusText})`);

            try {
              onExceptionHandler({
                exception: new Error(JSON.stringify(r)),
                properties: {
                  id: `Refreshtoken: att.no #2`,
                  ip,
                  statu: r.status,
                  statusText: r.statusText,
                },
              });
            } catch (e) {
              console.log('tracer error: ', e);
            }

            return Promise.reject();
          },
        });

        return Promise.resolve(data);
      },
    });

    const { expires_in, access_token, refresh_token, ...oth } = data ?? {};

    const result: AuthTokenType = {
      ...oth,
      ts: dtNow.getTime(),
      expiresIn: expires_in,
      expireDate: dtNow.setSeconds(dtNow.getSeconds() + expires_in * 0.9),
      accessToken: access_token,
      refreshToken: refresh_token,
    };
    if (hasValue(ip)) {
      Reflect.set(result, 'clientIP', ip);
    }

    return Promise.resolve({
      status: 200,
      data: result,
    });
  } catch (error) {
    console.log('accesstoken: KO');
    console.log(`head: ${JSON.stringify(headers)}`);
    console.log(`body: ${JSON.stringify(body)}`);

    return Promise.resolve({
      status: 500,
      data: { error },
    });
  }
};
