import { SportTicket, SportTicketAvvenimento, SportTicketEsito } from './utils/calcoloCarrello/types';

import { EventiSospesi } from './types';
import { KeyManagerSport } from 'features/sport/utils/keyManager';
import { RootState } from 'lib/centralStore';
import { SportTicketState } from './sportTicketSlice';
import { TicketMessaggiErrori } from 'features/carrello/types';
import { createSelector } from '@reduxjs/toolkit';

const _self = (state: RootState): SportTicketState => state.sportTicket;

export const selectIsScommettibile = (state: RootState) => state.sportTicket.isScommettibile;

export const selectPuntataSingolaMultipla = (state: RootState) => state.sportTicket.puntataSingolaMultipla;

export const selectErroreInserimento = (state: RootState) => state.sportTicket.errori.erroreInserimento;

export const selectIsSistema = (state: RootState) => state.sportTicket.ticket?.sistema;

export const selectBetReferral = (state: RootState) => state.sportTicket.betReferral;

export const selectBetReferralIsActive = (state: RootState) => state.sportTicket.betReferral.isActive;

export const selectTotalePuntataScommesseSistema = createSelector(_self, (state) => {
  let totale = 0;
  const puntataPerScommessa = state.puntataPerScommessa;
  for (let id in puntataPerScommessa) {
    if (puntataPerScommessa.hasOwnProperty(id)) {
      totale += puntataPerScommessa[id];
    }
  }
  return totale;
});

export const selectEsiti = createSelector(_self, (slice): Record<string, boolean> => slice.esiti);

export const selectTicket = createSelector(_self, (slice) => (slice.ticket ?? {}) as SportTicket);

export const selectTipo = createSelector(selectTicket, (slice) => slice.tipo);

export const selectBonus = createSelector(selectTicket, (slice) => slice.bonus);

export const selectPrezzo = createSelector(selectTicket, (slice) => slice.prezzo);

export const selectSistema = createSelector(selectTicket, (slice) => slice.sistema);

export const selectSistemi = createSelector(selectTicket, (slice) => slice.sistemi);

export const selectPossibileVincitaMin = createSelector(selectTicket, (slice) => slice.possibileVincitaMin);

export const selectPossibileVincitaMax = createSelector(selectTicket, (slice) => slice.possibileVincitaMax);

export const selectPossibileVincitaMinWithBonus = createSelector(
  selectTicket,
  (slice) => slice.possibileVincitaMinWithBonus
);

export const selectPossibileVincitaMaxWithBonus = createSelector(
  selectTicket,
  (slice) => slice.possibileVincitaMinWithBonus
);

export const selectErrori = createSelector([_self], (slice): TicketMessaggiErrori => slice?.errori ?? {});

export const selectErroriCarrelloSport = createSelector([selectErrori], (errori) => errori?.erroriCarrello);

export const selectErroriSistema = createSelector([selectErrori], (errori) => errori?.erroriSistema);

export const selectErroriEvento = createSelector([selectErrori], (errori): Array<string> => errori.erroriEvento);

export const selectQuotaTotale = createSelector([selectTicket], (slice) => slice.quotaTotale);

export const selectEventiSospesi = createSelector([_self], (slice): EventiSospesi => {
  return slice.eventiSospesi;
});

export const selectAvvenimenti = createSelector(
  selectTicket,
  (ticket): Array<SportTicketAvvenimento> => ticket?.avvenimenti ?? []
);

export const selectNumEsitiSport = createSelector(selectTicket, (ticket) => ticket?.numEsiti);

export const selectAvvenimentiKeys = createSelector(
  selectAvvenimenti,
  (avvenimenti): Array<string> => avvenimenti.filter(({ id }) => !!id).map(({ id }) => id!)
);

export const selectIsOpenCombinazioni = createSelector([_self], (slice): boolean => slice.isOpenCombinazioni);

export const selectEsitiByAvvenimenti = createSelector(
  selectAvvenimenti,
  (avvenimenti): Array<SportTicketEsito> => avvenimenti.map((avv) => avv.esiti).flat()
);

export const selectEsitiKeyByAvvenimenti = createSelector(
  selectEsitiByAvvenimenti,
  (esiti): Array<string> => esiti.map((esito) => esito.id)
);

export const selectScommesseKeys = createSelector(selectEsitiByAvvenimenti, (esiti): Array<string> => {
  return [
    ...new Set(
      esiti.map((esito) => {
        return new KeyManagerSport(esito.id).scommessaKey;
      })
    ),
  ];
});

export const selectAvvenimentoById = createSelector(
  [selectAvvenimenti, (_state: RootState, id: string) => id],
  (avvenimenti, id): SportTicketAvvenimento | undefined => {
    const avvenimento = avvenimenti?.find((avvenimento) => avvenimento.id === id);
    return avvenimento ?? undefined;
  }
);

export const selectInfoAggiuntiveKeys = createSelector(selectEsitiByAvvenimenti, (esiti): Array<string> => {
  return esiti.map((esito) => new KeyManagerSport(esito.id).infoAggiuntivaKey);
});
// Vengono verificati avvenimenti e le infoAgg
export const selectHasSospesi = createSelector([selectEventiSospesi], (eventiSospesi): boolean => {
  if (Object.keys(eventiSospesi.avvenimenti).length > 0) {
    return true;
  } else if (Object.keys(eventiSospesi.infoAggiuntive).length > 0) {
    return true;
  }
  return false;
});

export const selectIsOpenSettings = createSelector(_self, (slice) => slice.isOpenSettings);

export const selectEsitoIsSospeso = createSelector(
  [selectEventiSospesi, selectEsitiByAvvenimenti, (_state: RootState, esitoId: string) => esitoId],
  (eventiSospesi, esiti, esitoId): boolean => {
    const { avvenimentoKey, infoAggiuntivaKey } = new KeyManagerSport(esitoId);

    if (eventiSospesi?.avvenimenti.hasOwnProperty(avvenimentoKey)) {
      return true;
    } else if (eventiSospesi?.infoAggiuntive.hasOwnProperty(infoAggiuntivaKey)) {
      return true;
    } else if (esiti.find((esito) => esito.id === esitoId && !esito.isActive)) {
      return true;
    } else {
      return false;
    }
  }
);
export const selectSnaiRunnerEsiti = createSelector(_self, (state) => state.snaiRunnerEsiti);
