/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-86.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina86 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-86', className].join(' ');
  return (
    <svg
      id="disciplina_freccette"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m16.768 14.61-1.548 1.537-.704-.71 1.548-1.536.704.71ZM17.718 15.726l-1.537 1.536-.707-.707 1.536-1.537.707.708ZM18.763 16.768l-1.536 1.548-.71-.704 1.536-1.548.71.704ZM15.558 2.646c.326-.326.803-.216 1.044.024l4.372 4.373a.733.733 0 0 1 .032 1.05L18.57 10.53a.723.723 0 0 1-.522.206h-.016l-2.884-.184-1.32 1.396-.727-.688.937-.99-.66-.661-1.16 1.067-.677-.736 1.56-1.435-.185-2.885v-.016c0-.06.004-.15.038-.251a.686.686 0 0 1 .168-.27l2.436-2.437Zm-1.393 6.84-.242-3.79 2.145-2.145L20.1 7.584l-2.145 2.145-3.79-.242Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.441 2.634 10.89 5.07a.722.722 0 0 1 .207.522v.016l-.185 2.884 8.817 8.288.1 2.676-2.676-.1-8.3-8.817-2.884.185h-.016a.784.784 0 0 1-.251-.038.687.687 0 0 1-.27-.168L2.993 8.082c-.326-.327-.217-.803.024-1.044l4.373-4.372a.733.733 0 0 1 1.05-.032Zm1.525 7.63 7.633 8.108 1.19.044-.045-1.188L10.63 9.6l-.665.665Zm-.12-.79.243-3.79L7.932 3.54 3.9 7.572l2.145 2.145 3.802-.243Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.041 19.843 19.94 18.74l1.101 1.102ZM19.94 18.74a.58.58 0 0 0-.878.011.594.594 0 0 0 .024.814l1.116 1.116a.594.594 0 0 0 .84-.84M4.638 4.278a.594.594 0 0 1 .84 0l5.027 5.028a.594.594 0 0 1-.839.84L4.638 5.118a.594.594 0 0 1 0-.84ZM10.326 11.13l.683.731-5.752 5.378-.037 1.18 1.185-.036 5.379-5.59.72.694-5.661 5.882-2.655.083.083-2.66 6.055-5.661Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.888 18.702a.58.58 0 0 1 .011.878l-1.102 1.102m0 0a.594.594 0 0 1-.839-.84l1.116-1.115a.594.594 0 0 1 .814-.025M18.522 4.278a.594.594 0 0 1 .84.84l-5.028 5.028a.594.594 0 0 1-.84-.84l5.028-5.028ZM9.03 16.41l-1.548-1.548.707-.707 1.548 1.548-.707.707ZM7.914 17.358l-1.536-1.536.707-.707 1.536 1.536-.707.707ZM6.872 18.403l-1.548-1.536.704-.71 1.548 1.536-.704.71Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina86;
