/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-230.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco230 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-230', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.526"
      height="38.439"
      viewBox="0 0 32.526 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0)">
        <g transform="translate(13.306 19.22)">
          <path
            className="a"
            d="M153.914,160.131h-1.478v-6.653h-2.957v6.653H148v-7.392a.739.739,0,0,1,.739-.739h4.435a.739.739,0,0,1,.739.739Z"
            transform="translate(-148 -152)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M146.263,164.439a.727.727,0,0,1-.251-.044A24.351,24.351,0,0,1,130,141.524V131.175a.74.74,0,0,1,.329-.615,30.121,30.121,0,0,1,31.868,0,.74.74,0,0,1,.329.615v10.349A24.351,24.351,0,0,1,146.513,164.4.73.73,0,0,1,146.263,164.439Zm-14.784-32.854v9.938a22.871,22.871,0,0,0,14.784,21.389,22.872,22.872,0,0,0,14.784-21.389v-9.938a28.663,28.663,0,0,0-29.569,0Z"
            transform="translate(-130 -126)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(7.392 19.22)">
          <path
            className="a"
            d="M155.524,160.871H142.218A2.22,2.22,0,0,1,140,158.653V152h1.478v6.653a.74.74,0,0,0,.739.739h13.306a.74.74,0,0,0,.739-.739V152h1.478v6.653A2.22,2.22,0,0,1,155.524,160.871Z"
            transform="translate(-140 -152)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(4.435 5.914)">
          <path
            className="a"
            d="M156.7,148.784a.737.737,0,0,1-.523-.217l-8.348-8.348-8.348,8.348a.738.738,0,0,1-1.045,0l-2.218-2.218a.738.738,0,0,1,0-1.045L147.3,134.217a.738.738,0,0,1,1.045,0l3.913,3.913v-1.912a.739.739,0,0,1,.739-.739h2.957a.739.739,0,0,1,.739.739v6.347l2.74,2.74a.738.738,0,0,1,0,1.045l-2.218,2.218A.737.737,0,0,1,156.7,148.784Zm-8.871-10.349a.737.737,0,0,1,.523.217L156.7,147l1.172-1.172-2.434-2.434a.737.737,0,0,1-.217-.523v-5.914h-1.478v2.957a.739.739,0,0,1-1.262.523l-4.652-4.652-10.043,10.043L138.957,147l8.348-8.348A.737.737,0,0,1,147.827,138.435Z"
            transform="translate(-136 -134)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco230;
