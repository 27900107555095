import { DefaultHttpClient, HttpRequest, HttpResponse } from '@microsoft/signalr';
import { getUseSecondary } from 'lib/mwNodeSwitch';

export class CustomHttpClient extends DefaultHttpClient {
  constructor() {
    super(console);
  }

  public send(request: HttpRequest): Promise<HttpResponse> {
    const head = getUseSecondary();
    if (head) {
      const headers = request.headers ?? {};
      Reflect.set(headers, head.name, head.value);
      request.headers = headers;
    }

    return super.send(request);
  }
}
