/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-407.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco407 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-407', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="38.439"
      viewBox="0 0 35.482 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M247.482,152.718a.739.739,0,0,0-.739-.739c-1.215,0-2.661-.532-2.661-3.063,0-14.148-2.4-22.916-14.341-22.916S215.4,134.768,215.4,148.916c0,2.531-1.447,3.063-2.661,3.063a.739.739,0,0,0-.739.739,5.684,5.684,0,0,0,2.865,4.769,14.537,14.537,0,0,0-1.386,6.214.739.739,0,1,0,1.478,0,13.081,13.081,0,0,1,1.25-5.6,8.031,8.031,0,0,0,2.6.421,8.353,8.353,0,0,0,5.4-2.029l4.949,6.187a.74.74,0,0,0,1.155,0l4.946-6.184a8.378,8.378,0,0,0,5.407,2.025,8.031,8.031,0,0,0,2.6-.421,13.08,13.08,0,0,1,1.25,5.6.739.739,0,1,0,1.478,0,14.538,14.538,0,0,0-1.386-6.214A5.684,5.684,0,0,0,247.482,152.718Zm-17.741-25.239c6.983,0,10.648,3.04,12.1,10.937a2.929,2.929,0,0,0-1.751-.588h-1.762a1.19,1.19,0,0,0-.278.036.721.721,0,0,0-.178-.036c-2.994,0-4.056-2.788-4.056-5.175a.739.739,0,1,0-1.478,0c0,2.757-5.013,5.175-10.728,5.175a.714.714,0,0,0-.178.036,1.19,1.19,0,0,0-.278-.036h-1.762a2.929,2.929,0,0,0-1.751.588C219.094,130.518,222.759,127.478,229.741,127.478Zm0,25.133c-4.145,0-7.392-3.9-7.392-8.871v-4.447c4.63-.152,8.573-1.716,10.392-3.911a5.288,5.288,0,0,0,4.392,3.877v4.481a10.835,10.835,0,0,1-.294,2.508,4.806,4.806,0,0,1-4.141,1.927h-.206a1.478,1.478,0,1,0,0,1.478h.206a7.4,7.4,0,0,0,3-.622A7.014,7.014,0,0,1,229.741,152.612Zm8.871-13.306h1.478a1.48,1.48,0,0,1,1.478,1.478v2.957a1.48,1.48,0,0,1-1.478,1.478h-1.478Zm-17.741,5.914h-1.478a1.48,1.48,0,0,1-1.478-1.478v-2.957a1.48,1.48,0,0,1,1.478-1.478h1.478Zm-2.059,11.827c-2.986,0-4.9-1.762-5.267-3.657,1.73-.291,3.335-1.575,3.335-4.474,0-1.237.023-2.4.066-3.518a2.956,2.956,0,0,0,2.448,1.3h1.762c.025,0,.049-.006.075-.007a10.149,10.149,0,0,0,3.706,5.786C224.694,154.668,222.094,157.047,218.811,157.047Zm10.93,3.991-4.5-5.628a5.9,5.9,0,0,0,1.039-2.111,7.968,7.968,0,0,0,6.921,0,5.823,5.823,0,0,0,1.041,2.115Zm4.8-8.559a10.143,10.143,0,0,0,3.7-5.728l.028-.058c.02,0,.039.006.059.006h1.762a2.956,2.956,0,0,0,2.448-1.3c.042,1.114.066,2.281.066,3.518,0,2.9,1.6,4.183,3.335,4.474-.37,1.9-2.28,3.657-5.267,3.657C237.37,157.047,234.759,154.642,234.545,152.479Z"
        transform="translate(-212 -126)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco407;
