import Persist, { StorageKind } from 'lib/persist';

const localStorage = Persist(StorageKind.local);

export function incrementTabCount() {
  const count = parseInt(localStorage.getItem('tabCount') || '0', 10);
  localStorage.setItem('tabCount', `${count + 1}`);
}

export function decrementTabCount() {
  const count = parseInt(localStorage.getItem('tabCount') || '0', 10);
  if (count > 0) {
    localStorage.setItem('tabCount', `${count - 1}`);
  }
}

export function getTabCount() {
  return parseInt(localStorage.getItem('tabCount') || '0', 10);
}
