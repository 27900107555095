import {
  DropdownEsitiPropType,
  DropdownInfoAgg,
  DropdownInfoAggPropType,
  EsitiSortingInfo,
  EsitoCell,
  EsitoDescription,
  InfoAggCell,
} from './types';
import {
  Esito,
  EsitoMap,
  InfoAggiuntivaMap,
  InfoTipoScommessa,
  InfoTipoScommessaMap,
  ScommessaMap,
  ScommessaResponse,
  VisualizationEnum,
} from 'lib/api/sport/sportScommesseBySlugResponse';
import { MatrixCell, MatrixSelect } from 'components/scommesseTipo/components/scommesseQuoteList/ScommesseQuoteList';
import { SportTicketInfoEsito, UpdateEventType, UpdateStatoInfoAgg } from '../types';
import {
  SportsAvvenimentoEsposto,
  SportsCacheTemplateAvvenimentoDto,
  SportsCacheTemplateManifestazioneDto,
  SportsGruppoScommessa,
  SportsInfoAggiuntivaDto,
  SportsInfoTipoScommessa,
  SportsInfoTipoScommessaGroupDto,
  SportsLiveSection,
  SportsTipoOrdinamentoEsitiEnum,
  SportsUpdateAvvenimentoSignalREvent,
  SportsUpdateRisultatiniSignalREvent,
} from 'types/swagger';
import { isMatch, isTruthy, purgeNulls, toSportUrlCase } from 'utility/functions';
import { sortByDescriptionExceptions, sortByEsitoExceptions, sortByQuotaExceptions } from './exceptions';

import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsight } from 'components/appInsight/AppInsight';
import { character } from 'utility/constant';
import format from 'date-fns/format';

export const getSortingField = (tipo?: SportsInfoTipoScommessa): EsitiSortingInfo => {
  const { key, tipoVisualizzazione, tipoOrdinamentoEsiti } = tipo ?? {};
  const byQuota: EsitiSortingInfo = { fieldName: 'quota', fallback: 0 };
  const byEsito: EsitiSortingInfo = { fieldName: 'idEsito', fallback: '' };
  const byDescription: EsitiSortingInfo = { fieldName: 'descrizione', fallback: '' };

  if (sortByQuotaExceptions.includes(`${key}`)) {
    return byQuota;
  }

  if (sortByEsitoExceptions.includes(`${key}`)) {
    return byEsito;
  }

  if (sortByDescriptionExceptions.includes(`${key}`)) {
    return byDescription;
  }

  switch (tipoVisualizzazione) {
    case VisualizationEnum.EsitiUnderThree: {
      return byEsito;
    }
    // case VisualizationEnum.EsitiOverThree: {}
    case VisualizationEnum.Antepost: {
      return byQuota;
    }
    case VisualizationEnum.SingleInfoAggEsitiUnderThree: {
      return byEsito;
    }
    // case VisualizationEnum.SingleInfoAggEsitiOverThree: {}
    // case VisualizationEnum.MultipleInfoAggEsitiUnderThree: {}
    // case VisualizationEnum.RecursiveInfoAgg: {}
  }

  switch (tipoOrdinamentoEsiti) {
    case SportsTipoOrdinamentoEsitiEnum.Alfabetico: {
      return byDescription;
    }
    case SportsTipoOrdinamentoEsitiEnum.QuotaAsc: {
      return byQuota;
    }
  }

  return byEsito;
};

export const sortEsiti = (
  keys?: string[],
  map?: EsitoMap,
  infoTipoScommessa?: SportsInfoTipoScommessa
): Array<string> => {
  if (!keys) return [];
  if (!map) return keys;

  // identify all occurrences of 'altro' to move them at the end of the list
  const othIds = Object.keys(map ?? {}).filter((x) => isMatch(map[x].descrizione, 'altro'));
  const { fieldName, fallback } = getSortingField(infoTipoScommessa);

  return [...keys].sort((a, b) => {
    if (othIds.includes(a)) {
      return 1;
    }
    if (othIds.includes(b)) {
      return -1;
    }

    const wa: string = Reflect.get(map[a] ?? {}, fieldName) ?? fallback;
    const wb: string = Reflect.get(map[b] ?? {}, fieldName) ?? fallback;

    if (wa < wb) {
      return -1;
    }
    if (wa > wb) {
      return 1;
    }
    return 0;
  });
};

export const sortHeaders = (
  keys?: string[],
  infoTipoScommessa?: SportsInfoTipoScommessa,
  gap?: Record<string, number>
) => {
  const { headers, headersTrKey } = infoTipoScommessa ?? {};

  return (keys ?? []).map((key) => {
    const esitoId = `${key}`.split('-').pop();
    const index = Number(esitoId) - (Reflect.get(gap ?? {}, esitoId!) ?? 1);
    return {
      key,
      header: headers?.[index],
      headerTrKey: headersTrKey?.[index],
    };
  });
};

export const sortInfoTipoHeaders = (
  infoTipoScommessa: InfoTipoScommessa,
  headerSequence?: Record<string, number[]>
) => {
  const { key, headers: wHeaders, headersTrKey: wHeadersTrKey, ...oth } = infoTipoScommessa ?? {};
  const [tipo] = `${key}_`.split('_');

  const sHeaders: Array<string> = [...(wHeaders ?? [])];
  const sHeadersTrKey: Array<string> = [...(wHeadersTrKey ?? [])];

  let headers: Array<string> = [];
  let headersTrKey: Array<string> = [];

  const seq = headerSequence?.[tipo];
  if (isTruthy(seq?.length)) {
    for (const index of seq!) {
      headers.push(sHeaders[index]);
      headersTrKey.push(sHeadersTrKey[index]);
    }
  } else {
    headers = sHeaders;
    headersTrKey = sHeadersTrKey;
  }

  return {
    key,
    headers,
    headersTrKey,
    ...oth,
  };
};

export const getLiveMinutes = (minutes: string | undefined): string | undefined => {
  if (minutes) {
    return `${minutes}${character.apostrophe}`;
  }
  return;
};

export const truncateText = (text: string, max: number) => {
  return text?.length > max ? text.substring(0, max - 1) + '...' : text;
};

export const selectTicketEsitoFromResult = (esito: Esito): SportTicketInfoEsito => {
  const {
    quota,
    idEsito,
    isActive,
    descrizione,
    descrizioneTrKey,
    idProgramma,
    idAvvenimento,
    infoAggiuntiva,
    idTipoScommessa,
    descrizioneTipoScommessaWithInfoAgg,
    descrizioneTipoScommessaWithInfoAggTrKey,
  } = esito ?? {};
  return {
    ticketEsito: {
      id: `${idProgramma}-${idAvvenimento}-${idTipoScommessa}-${infoAggiuntiva}-${idEsito}`,
      quota,
      idEsito,
      isActive,
      descrizione,
      descrizioneTrKey,
      infoAggiuntiva,
      descrizioneTipoScommessaWithInfoAgg,
      descrizioneTipoScommessaWithInfoAggTrKey,
    },
  };
};

/**
 *
 * @returns Array of two position, the first one returns the date formatted in day/month.
 * The second one returns the hour formatted in hh/mm
 */
export const getDateAndHoursValue = (dateHour?: Date): [string, string] => {
  if (!dateHour) return ['', ''];
  return [`${format(dateHour, 'dd/MM')}`, `${format(dateHour, 'HH:mm')}`];
};

export const retrieveBilanciataIndex = (matrix: MatrixSelect): number => {
  let minIndex: number = 0;
  let minDiff = Number.POSITIVE_INFINITY;

  matrix.forEach((row, rowIndex) => {
    const values: number[] = row
      .map((item) => (typeof item.value === 'number' && item.value > 0 ? item.value : undefined))
      .filter((value) => value !== undefined) as number[];

    if (values.length >= 2) {
      const diffs = values.flatMap((value, i, arr) =>
        arr.slice(i + 1).map((otherValue) => Math.abs(value - otherValue))
      );

      const minRowDiff = Math.min(...diffs);
      if (minRowDiff < minDiff) {
        minDiff = minRowDiff;
        minIndex = rowIndex;
      }
    }
  });

  return minIndex;
};

export const retrieveBilanciataIndexSimple = (values: number[][]) => {
  const [minIndex, _minValue] = values.reduce<[number, number]>(
    ([currIndex, currDiff], currentRow, rowIndex) => {
      const diffs = currentRow.flatMap((value, i, arr) => {
        if (value !== 0) {
          return arr.slice(i + 1).map((otherValue) => Math.abs(value - otherValue));
        }
        return Number.POSITIVE_INFINITY;
      });
      const minRowDiff = Math.min(...diffs);
      return minRowDiff < currDiff ? [rowIndex, minRowDiff] : [currIndex, currDiff];
    },
    [0, Number.POSITIVE_INFINITY]
  );

  return minIndex;
};

export const populateDropdownInfoAgg = ({
  esitoMap,
  defaultIndex,
  infoTipoScommessa,
  sizeInfoAggiuntive,
  infoAggiuntivaList,
}: DropdownInfoAggPropType): DropdownInfoAgg => {
  if (infoAggiuntivaList && esitoMap) {
    const workingDefaultIndex = defaultIndex ?? -1;
    const defaultValue = workingDefaultIndex > -1 ? infoAggiuntivaList?.[workingDefaultIndex]?.valore : undefined;
    const result = infoAggiuntivaList
      ?.filter(({ isActive }) => isActive)
      ?.sort((a, b) => {
        const wa = `${a.valore ?? 'ZZZ'}`;
        const wb = `${b.valore ?? 'ZZZ'}`;

        if (wa < wb) {
          return -1;
        }
        if (wa > wb) {
          return 1;
        }
        return 0;
      })
      ?.map(({ esitoKeyList, valore, isActive, isBilanciata }, _index, list) => {
        const index = defaultValue ? list.findIndex((x) => x.valore === defaultValue) : -1;
        const infoAgg: InfoAggCell = {
          size: sizeInfoAggiuntive,
          value: valore,
          isActive,
          isInfoAgg: true,
          isBalanced: isBilanciata,
          defaultIndex: index > -1 ? index : undefined,
        };

        const esiti: Array<EsitoCell> =
          sortHeaders(esitoKeyList, infoTipoScommessa).map(({ key, header, headerTrKey }) => {
            const { isActive, quota } = esitoMap[key] ?? {};
            return {
              value: isActive ? quota : '-',
              header,
              headerTrKey,
              keyEsito: key,
              isInfoAgg: false,
            } as EsitoCell;
          }) ?? [];
        return [infoAgg, ...esiti];
      })
      ?.filter((row) => !row.slice(1).every((cell: EsitoCell) => cell.value === '-')) as DropdownInfoAgg;
    // ?.sort((a, b) => (a[0] as InfoAggCell).value.localeCompare((b[0] as InfoAggCell).value)) as DropdownInfoAgg;

    return result ?? [];
  }

  return [];
};

export const populateEsitiDropdown = ({
  esitoMap,
  infoAggIndex = 0,
  infoTipoScommessa,
  infoAggiuntivaList,
}: DropdownEsitiPropType): Array<[EsitoDescription, EsitoCell]> => {
  if (!infoAggiuntivaList || !esitoMap || !infoAggiuntivaList[infoAggIndex]) {
    return [];
  }
  const { esitoKeyList, isActive } = infoAggiuntivaList[infoAggIndex];

  const wEsitoKeyList = esitoKeyList ?? [];

  const lst = sortEsiti(wEsitoKeyList, esitoMap, infoTipoScommessa);

  return lst.map((esitoKey) => {
    const description: EsitoDescription = {
      value: esitoMap[esitoKey]?.descrizione,
      valueTrKey: esitoMap[esitoKey]?.descrizioneTrKey,
    };
    const esito: EsitoCell = {
      value: esitoMap[esitoKey]?.isActive && isActive ? esitoMap[esitoKey]?.quota : '-',
      header: infoTipoScommessa?.headers?.[wEsitoKeyList.indexOf(esitoKey)] ?? '',
      headerTrKey: infoTipoScommessa?.headersTrKey?.[wEsitoKeyList.indexOf(esitoKey)] ?? '',
      keyEsito: esitoKey,
      isInfoAgg: false,
    };
    return [description, esito];
  });
};

export const populateDropdown = ({
  esitoMap,
  defaultIndex,
  infoAggHeader,
  infoTipoScommessa,
  infoAggiuntivaList,
  sizeInfoAggiuntive,
  infoAggHeaderTrKey,
}: DropdownInfoAggPropType): MatrixSelect => {
  if (infoAggiuntivaList && esitoMap) {
    const rawMainEsiti = infoAggiuntivaList
      ?.filter(({ isActive }) => isActive)
      ?.flatMap((x) => x.esitoKeyList)
      ?.filter((x) => !!x) as Array<string>;
    const sortedMainEsiti = sortEsiti(rawMainEsiti, esitoMap, infoTipoScommessa);

    const result = infoAggiuntivaList
      ?.filter(({ isActive }) => isActive)
      ?.sort((a, b) => {
        const wa = sortedMainEsiti.findIndex((x) => a.esitoKeyList?.includes(x));
        const wb = sortedMainEsiti.findIndex((x) => b.esitoKeyList?.includes(x));
        if (wa < wb) {
          return -1;
        }
        if (wa > wb) {
          return 1;
        }
        return 0;
      })
      ?.map(({ valore, esitoKeyList, isBilanciata, isActive, infoAggiuntivaHex }, indexInfoAgg, lst) => {
        if (!valore) {
          console.error(
            `InfoAgg ${infoAggiuntivaHex} senza linea - MANDATORIA => mostro fallback per incogruenza dati`,
            `TIPO VISUALIZZAZIONE: ${infoTipoScommessa?.tipoVisualizzazione}`,
            `LINEA: ${lst[indexInfoAgg]}`
          );
          appInsight.trackException({
            id: 'emptyInfoAgg',
            exception: new Error(`InfoAgg ${infoAggiuntivaHex} without linea`),
            properties: {
              linea: lst[indexInfoAgg],
              infoAggiuntivaHex,
              tipoVisualizzazione: infoTipoScommessa?.tipoVisualizzazione,
            },
          });
          throw new Error();
        }

        const wEsitoKeyList = esitoKeyList ?? [];
        const sortedEsiti = sortEsiti(wEsitoKeyList, esitoMap, infoTipoScommessa);
        const hasEsitiWithSelect = wEsitoKeyList.length > 3;
        let quoteListWithDescription = sortedEsiti.map((esito) => [
          {
            value: esitoMap[esito]?.descrizione,
            valueTrKey: esitoMap[esito]?.descrizioneTrKey,
            isBalanced: isBilanciata,
            defaultIndex: defaultIndex ?? 0,
          },
          {
            id: esito,
            value: esitoMap[esito]?.isActive && isActive ? esitoMap[esito]?.quota : '-',
            header: infoTipoScommessa?.headers?.[wEsitoKeyList.indexOf(esito)],
            keyEsito: esito,
            headerTrKey: infoTipoScommessa?.headersTrKey?.[wEsitoKeyList.indexOf(esito)],
            ticketEsito: selectTicketEsitoFromResult(esitoMap[esito]),
            isIncreasing: esitoMap[esito]?.isIncreasing,
            isDecreasing: esitoMap[esito]?.isDecreasing,
          },
        ]);

        const quoteList: Array<MatrixCell> = sortedEsiti.map((esito) => ({
          id: esito,
          value: esitoMap[esito]?.isActive && isActive ? esitoMap[esito]?.quota : '-',
          header: infoTipoScommessa?.headers?.[wEsitoKeyList.indexOf(esito)],
          keyEsito: esito,
          headerTrKey: infoTipoScommessa?.headersTrKey?.[wEsitoKeyList.indexOf(esito)],
          ticketEsito: selectTicketEsitoFromResult(esitoMap[esito]),
          isIncreasing: esitoMap[esito]?.isIncreasing,
          isDecreasing: esitoMap[esito]?.isDecreasing,
        }));

        const hIdx = infoAggiuntivaList?.findIndex((x) => x?.infoAggiuntivaHex === infoAggiuntivaHex);
        const infoAgg = {
          size: sizeInfoAggiuntive,
          value: valore,
          header: infoAggHeader?.[hIdx] ?? '',
          headerTrKEy: infoAggHeaderTrKey?.[hIdx],
          isActive,
          isInfoAgg: true,
          isBalanced: isBilanciata,
          defaultIndex: defaultIndex ?? 0,
        };

        if (!valore) {
          return hasEsitiWithSelect ? quoteListWithDescription : [quoteList];
        } else if (hasEsitiWithSelect) {
          return [[infoAgg], ...quoteListWithDescription];
        }

        return [[infoAgg, [...quoteList]].flat()];
      })
      .flat();

    // TODO : DANGEROUS CASTING
    return (result ?? []) as MatrixSelect;
  }

  return [];
};

export const hasKey = (obj: any, keys: string[]) => {
  return (
    keys.length > 0 &&
    keys.every((key) => {
      if (typeof obj !== 'object' || !obj.hasOwnProperty(key)) return false;
      // obj = obj[key]; ??
      return true;
    })
  );
};

export const getPositionString = (string: string, subString: string, index: number): number => {
  return string.split(subString, index).join(subString).length;
};

export const sendTracking = (eventType: UpdateEventType, counter: number) => {
  appInsight.trackTrace({
    message: `${eventType} received ${counter} times`,
  });
};

export const decodePathname = (input: string | undefined): string => {
  const [pathname] = decodeURI(input ?? '')
    .trim()
    .toLowerCase()
    .split('?');
  const chunks = pathname.split('/').filter((x) => !!x);
  const result = ['live', 'sport', 'ippica', 'virtual', 'homepage'].some((x) => isMatch(x, chunks?.[0]))
    ? chunks.join('/')
    : '';

  // console.log(input, '-> decodePathname ->', result)

  appInsight.trackTrace({
    message: `[sportConnectionHubManager] pathname-to-slug(${input}) -> ${result}`,
    severityLevel: SeverityLevel.Information,
  });

  return result;
};

export const applyQuote = (scommessa?: ScommessaResponse, esito?: EsitoMap): void => {
  if (!esito) return;
  if (!scommessa) return;

  Object.entries(esito).forEach((entry) => {
    const [key, value] = entry;

    if (!purgeNulls(value)) return;

    if (scommessa && hasKey(scommessa?.esitoMap, [key])) {
      let { quota, isActive } = value;

      if (!!quota) {
        quota = Number(`${quota}`);
        const { quota: prevQuota, isActive: prevActive } = scommessa.esitoMap[key];
        scommessa.esitoMap[key].isIncreasing = prevActive && quota > prevQuota ? true : false;
        scommessa.esitoMap[key].isDecreasing = prevActive && quota < prevQuota ? true : false;
      }

      scommessa.esitoMap[key].isActive = isActive;
      scommessa.esitoMap[key].quota = quota;
    } else if (scommessa.esitoMap) {
      scommessa.esitoMap[key] = value;
    }
  });
};

export const applyUpdateGruppo = (
  templateToUpdate: SportsCacheTemplateAvvenimentoDto | SportsCacheTemplateManifestazioneDto,
  updateGruppo: SportsGruppoScommessa
): void => {
  if (!templateToUpdate.gruppoList) {
    templateToUpdate.gruppoList = [];
  }
  const index = templateToUpdate.gruppoList.findIndex((gruppo) => gruppo.key === updateGruppo.key);

  if (index > -1) {
    templateToUpdate.gruppoList[index] = updateGruppo;
  } else {
    templateToUpdate.gruppoList.push(updateGruppo);
  }
};

export const applyRemoveGruppo = (
  templateToRemove: SportsCacheTemplateAvvenimentoDto | SportsCacheTemplateManifestazioneDto,
  gruppoKey: string
) => {
  const indexToRemove = (templateToRemove?.gruppoList ?? []).findIndex((gruppo) => gruppo.key === gruppoKey);

  if (indexToRemove > -1) {
    templateToRemove.gruppoList!.splice(indexToRemove, 1);
  }
};

export const applyRisultatini = (
  scommessa?: ScommessaResponse,
  avvenimento?: SportsUpdateRisultatiniSignalREvent
): void => {
  if (!scommessa) return;
  if (!avvenimento) return;

  const index = scommessa?.avvenimentoList?.findIndex((a) => avvenimento.key === a?.key);
  if (index > -1) {
    const { live: original } = scommessa.avvenimentoList[index];
    scommessa.avvenimentoList[index].live = purgeNulls({ ...original, ...avvenimento.live }) as SportsLiveSection;
  }
};

export const applyInfoAggiuntiva = (
  scommessa?: ScommessaResponse,
  infoAggiuntivaMap?: InfoAggiuntivaMap,
  esitoMap?: EsitoMap
): void => {
  if (!scommessa) return;
  if (!infoAggiuntivaMap) return;
  if (!esitoMap) return;

  Object.entries(infoAggiuntivaMap).forEach(([key, value]) => {
    if (scommessa?.infoAggiuntivaMap && hasKey(scommessa?.infoAggiuntivaMap, [key])) {
      scommessa.infoAggiuntivaMap[key].infoAggiuntivaList = value.infoAggiuntivaList;
    }
  });

  Object.entries(esitoMap).forEach(([key, value]) => {
    if (scommessa.esitoMap) {
      if (hasKey(scommessa.esitoMap, [key])) {
        const src = (Reflect.get(scommessa?.esitoMap ?? {}, key) ?? {}) as Partial<Esito>;
        const {
          descrizione,
          descrizioneTrKey,
          splitInfoAggiuntiva,
          descrizioneTipoScommessaWithInfoAgg,
          descrizioneTipoScommessaWithInfoAggTrKey,
        } = value ?? {};
        if (descrizioneTipoScommessaWithInfoAggTrKey) {
          Reflect.set(src, 'descrizioneTipoScommessaWithInfoAggTrKey', descrizioneTipoScommessaWithInfoAggTrKey);
        } else {
          delete src.descrizioneTipoScommessaWithInfoAggTrKey;
        }
        if (descrizioneTipoScommessaWithInfoAgg) {
          Reflect.set(src, 'descrizioneTipoScommessaWithInfoAgg', descrizioneTipoScommessaWithInfoAgg);
        } else {
          delete src.descrizioneTipoScommessaWithInfoAgg;
        }
        if (splitInfoAggiuntiva) {
          Reflect.set(src, 'splitInfoAggiuntiva', splitInfoAggiuntiva);
        } else {
          delete src.splitInfoAggiuntiva;
        }
        if (descrizioneTrKey) {
          Reflect.set(src, 'descrizioneTrKey', descrizioneTrKey);
        } else {
          delete src.descrizioneTrKey;
        }
        if (descrizione) {
          Reflect.set(src, 'descrizione', descrizione);
        } else {
          delete src.descrizione;
        }
        Reflect.set(scommessa.esitoMap, key, src);
      } else {
        Reflect.set(scommessa.esitoMap, key, value);
      }
    }
  });
};

export const applyScommessa = (
  scommessa: ScommessaResponse,
  payload: {
    esitoMap?: EsitoMap;
    scommessaMap?: ScommessaMap;
    infoAggiuntivaMap?: InfoAggiuntivaMap;
    infoTipoScommessaMap?: InfoTipoScommessaMap;
    infoTipoScommessaGroupMap?: Record<string, SportsInfoTipoScommessaGroupDto>;
  }
): void => {
  if (!scommessa) return;
  if (!payload.scommessaMap) return;

  const objectToUpdate = [
    'esitoMap',
    'scommessaMap',
    'infoAggiuntivaMap',
    'infoTipoScommessaMap',
    'infoTipoScommessaGroupMap',
  ];

  objectToUpdate.forEach((objToUpdate) => {
    Object.entries(payload[objToUpdate] ?? {}).forEach(([key, value]) => {
      scommessa[objToUpdate] = scommessa[objToUpdate] ?? {};
      Reflect.set(scommessa[objToUpdate], key, value);
    });
  });
};

export const applyStatoScommessa = (scommessa?: ScommessaResponse, scommessaMap?: ScommessaMap): void => {
  if (!scommessa) return;
  if (!scommessaMap) return;

  Object.entries(scommessaMap).forEach(([key, value]) => {
    if (scommessa?.scommessaMap && hasKey(scommessa?.scommessaMap, [key])) {
      scommessa.scommessaMap[key].isClosed = value.isClosed;
    }
  });
};

export const applyAvvenimento = (
  scommessa?: ScommessaResponse,
  payload?: SportsUpdateAvvenimentoSignalREvent
): void => {
  if (!scommessa?.avvenimentoList) return;

  const { avvenimentoKey, dataOra, descrizione, descrizioneTrKey } = payload ?? {};
  if (!avvenimentoKey) return;

  const toUpdate = scommessa?.avvenimentoList?.find((avv) => avv.key === avvenimentoKey);
  if (toUpdate) {
    if (!!descrizioneTrKey) {
      toUpdate.descrizioneTrKey = descrizioneTrKey;
    }
    if (!!descrizione) {
      toUpdate.descrizione = descrizione;
    }
    if (!!dataOra) {
      toUpdate.dataOra = dataOra;
    }
  }
};

export const applyScommesseAvvenimento = (
  scommessa?: ScommessaResponse,
  avvenimentoKey?: string,
  allScommesseClosed?: boolean
): void => {
  if (!scommessa) return;
  if (!avvenimentoKey) return;
  if (!allScommesseClosed) return;

  for (const key in scommessa.scommessaMap) {
    if (key.startsWith(avvenimentoKey)) {
      scommessa.scommessaMap[key].isClosed = allScommesseClosed;
    }
  }
};

export const applyRemoveDataFromAvvenimento = (
  scommessa?: ScommessaResponse,
  avvenimentoToRemove?: string,
  removeAvvenimento: boolean = true
) => {
  if (!scommessa) return;
  if (!avvenimentoToRemove) return;

  if (scommessa.esitoMap) {
    for (let keyToRemove of Object.keys(scommessa.esitoMap).filter((x) => x.startsWith(`${avvenimentoToRemove}-`))) {
      delete scommessa.esitoMap[keyToRemove];
    }
  }
  if (scommessa.infoAggiuntivaMap) {
    for (let keyToRemove of Object.keys(scommessa.infoAggiuntivaMap).filter((x) =>
      x.startsWith(`${avvenimentoToRemove}-`)
    )) {
      delete scommessa.infoAggiuntivaMap[keyToRemove];
    }
  }
  if (scommessa.scommessaMap) {
    for (let keyToRemove of Object.keys(scommessa.scommessaMap).filter((x) =>
      x.startsWith(`${avvenimentoToRemove}-`)
    )) {
      delete scommessa.scommessaMap[keyToRemove];
    }
  }
  if (removeAvvenimento && scommessa.avvenimentoList) {
    const idToRemove = scommessa.avvenimentoList.findIndex((x) => x.key === avvenimentoToRemove);
    if (idToRemove > -1) {
      scommessa.avvenimentoList.splice(idToRemove, 1);
    }
  }
};

export const applyAddAvvenimento = (
  scommessa?: ScommessaResponse,
  avvenimentoMap?: Record<string, SportsAvvenimentoEsposto>
): void => {
  if (!scommessa) return;
  if (!avvenimentoMap) return;

  if (!scommessa.avvenimentoList) {
    scommessa.avvenimentoList = [];
  }

  Object.values(avvenimentoMap).forEach((avvenimento) => {
    const idx = scommessa.avvenimentoList.findIndex((item) => `${item?.key}` === `${avvenimento?.key}`);
    if (idx < 0) {
      scommessa.avvenimentoList.push(avvenimento);
    } else {
      const src = scommessa.avvenimentoList[idx];
      const updAvvenimento = { ...src, ...avvenimento };
      scommessa.avvenimentoList.splice(idx, 1, updAvvenimento);
    }
  });
};

export const isCurrentInfoAggItemActive = (infoAgg: SportsInfoAggiuntivaDto, esitoKey: string) => {
  const { isActive, esitoKeyList, nextInfoAgg } = infoAgg ?? {};

  if ((esitoKeyList || []).includes(esitoKey)) {
    return isActive;
  } else if (!!nextInfoAgg) {
    const childResult = nextInfoAgg.some((agg) => isCurrentInfoAggItemActive(agg, esitoKey));
    return isActive && childResult;
  }

  return false;
};

const updateInfoAggItem = (infoAgg: SportsInfoAggiuntivaDto, isActive: boolean, infoAggiuntivaHex?: string): void => {
  if (!!infoAgg.infoAggiuntivaHex && infoAgg.infoAggiuntivaHex === infoAggiuntivaHex) {
    infoAgg.isActive = isActive;
  } else if (!!infoAgg.nextInfoAgg) {
    infoAgg.nextInfoAgg.forEach((subItem) => updateInfoAggItem(subItem, isActive, infoAggiuntivaHex));
  }
};

export const applyUpdateInfoAgg = (scommessa?: ScommessaResponse, payload?: UpdateStatoInfoAgg): void => {
  if (!scommessa) return;
  const { updateStatoInfoAggMap } = payload ?? {};
  if (!updateStatoInfoAggMap) return;

  // TODO : VALUTARE REFACTOR PER AUMENTARE PRESTAZIONI
  Object.entries(updateStatoInfoAggMap).forEach(([key, value]) => {
    value.infoAggList.forEach((infoAggHex) =>
      scommessa.infoAggiuntivaMap?.[key]?.infoAggiuntivaList?.map((infoAgg) =>
        updateInfoAggItem(infoAgg, value.isActive, infoAggHex)
      )
    );
  });
};

/**
 * Data una lista di key scommessa miste (singole/group) e una chiave avvenimento,
 * ritorna la lista di chiavi complete
 *
 * `(['23', 'group-16_17'], '111-111') => ['111-111-23','111-111-16','111-111-17']`
 */
export const normalizeKeyStringList = (infoTipoScommessaKeyStringList?: string[], avvenimentoKey?: string) => {
  const keyList = (infoTipoScommessaKeyStringList ?? []).flatMap((key) =>
    key.startsWith('group-')
      ? key
          .replaceAll('group-', '')
          .split('_')
          .map((groupKey) => `${avvenimentoKey}-${groupKey}`)
      : `${avvenimentoKey}-${key}`
  );
  return keyList;
};

export const retrieveActiveEsiti = (esitoKeyList: string[] | undefined, esitoMap: EsitoMap): Array<string> => {
  return (esitoKeyList ?? []).filter((esito) => {
    const { isActive, quota } = esitoMap?.[esito] ?? {};
    if (isTruthy(isActive)) {
      return isTruthy(quota);
    }
    return false;
  });
};

export const getUrlManifestazioneByAvvenimento = (avvenimento?: SportsAvvenimentoEsposto) => {
  if (!avvenimento) return undefined;

  const { slug, slugManifestazione } = avvenimento ?? {};
  const idx = slug?.indexOf(`/${slugManifestazione}/`);
  const result = [(slug ?? '').substring(0, idx), slugManifestazione].join('/');

  return toSportUrlCase(result);
};
