import { CartTipoVenditaEnum, IppicaCorsaDetailsDto } from 'types/swagger';
import {
  COD_SCOM_TRIO,
  accoppiataTrioDisordineHeader,
  accoppiataTrioOrdineHeader,
  quotaFissaResults,
  quotaTotalizzatoreResults,
  totalizzatoreHeadersList,
  totalizzatoreHeadersListWithCampi,
} from './constants';
import {
  convertDecimalQuota,
  filterQuoteKeysByCodiceTipoScommessa,
  hasTipoScommessaTrioByValue,
  isEqualStatoScommessaByKey,
  retrieveCodiceTipoScommessa,
  splitKeysAndValues,
} from './functions';
import { BroadcastEnum, IppicaLocale, IppicaScommesseType, Stato, TabValue, VideoStreamingType } from './types';

import { createSelector } from '@reduxjs/toolkit';
import { ItemWithInfo } from 'components/scommesseFiltersListWithInfo/ScommesseFiltersListWithInfo';
import format from 'date-fns/format';
import { RootState } from 'lib/centralStore';
import { convertAmountWithoutCurrencyValue } from 'utility/functions';
import raise from 'utility/raise';
import { IppicaBroadcastBoxType } from './components/ippicaBroadcast/ippicaBroadcastBox';
import { GruppoScommessaList } from './components/ippicaScommesseFilter/ippicaScommsseFilterList/IppicaScommesseFilterList';
import { SettingsType } from './settings';
import { IppicaDetailRace } from './types/ippicaDetailRace';
import { QuotaWithInfoAgg } from './types/quotaWithInfoAgg';
import { KeyManagerIppica } from './utils/keyManagerIppica';
import { BlockScommessa, GroupItem } from './settings/settings-types';

const emptyRaceDetails = {} as IppicaDetailRace;
const emptyGruppiFromSettings = {} as SettingsType['gruppo'];
// const emptyTipoScommessaListFromSettings = {} as SettingsType['tipoScommessaList'];

const _self = (state: RootState) => state.ippica;
const _selfNav = createSelector([_self], ({ nav }) => nav);
const _selfIppicaSettings = createSelector([_self], ({ ippicaSettings }) => ippicaSettings);
const _selfTodayMenuItemToggle = createSelector([_self], ({ todayExpandedItems }) => todayExpandedItems);
const _selfIppicaTipoScommessa = createSelector([_self], ({ ippicaTipoScommessa }) => ippicaTipoScommessa);

export const selectQuoteFinaliResponseList = createSelector([_self], (state) => state.fetchSuccessList);
// export const selectQuoteFinali = createSelector([_self], (ippicaState) => ippicaState.ippicaQuoteFinali);
// export const selectIsLoadingQuoteFinali = createSelector(
//   [_self],
//   (ippicaState) => ippicaState.isLoadingIppicaQuoteFinali
// );
// from state
export const selectIsLoadingIppicaDetailsRace = createSelector([_self], (state) => state.isLoadingIppicaDetailRace);
export const selectIsLoadingIppicaTipoScommessa = createSelector(
  [_self],
  (state) => state.isLoadingIppicaTipoScommessa
);

export const selectIppidaToday = (idAvvenimento: string) =>
  createSelector([_self], (ippicaState) => ippicaState?.ippicaTodayMap?.[idAvvenimento]);

export const selectIsEqualToInitialState = createSelector(_self, (ippicaState) => ippicaState.isEqualeToInitialState);
export const selectIsLoadingIppicaToday = createSelector(_self, (ippicaState) => ippicaState.isIppicaTodayMapLoading);
export const selectIppicaTodayTablist = createSelector(_self, (ippicaState) => ippicaState.ippicaTodayMap.titleList);
export const selectIppicaTodayCorseDaylist = createSelector(
  [_self],
  (ippicaState) => ippicaState.ippicaTodayMap.corseList
);
export const selectIppicaTodayDictionary = (keyAvvenimento: string) =>
  createSelector(_self, (ippicaState) => ippicaState.ippicaTodayMap.dictionary[keyAvvenimento]);

export const selectCurrentScommesseType = createSelector(_self, (ippicaState) => {
  return ippicaState.currentScommesseType;
});

export const selectQuotaFinale = createSelector([_self], (state) => state.ippicaQuoteFinali);

export const selectTipoScommessaKey = createSelector([_self], (ippicaState) => ippicaState.tipoScommessaKey);

export const selectDefaultItem = createSelector([_self], (state) => state.ippicaDefaultItemSelected);
export const selectFiltersToday = createSelector([_self], (ippicaState) => ippicaState.ippicaTodayFilters);

export const selectQueryParamObjList = createSelector([_self], (ippicaState) => ippicaState.ippicaQueryParamList);

export const selectBlockIppicaScommessa = createSelector([_self], (state) => state.blockIppicaScommessa);

export const selectQueryParamList = createSelector([selectQueryParamObjList], (queryParamList) => {
  return queryParamList.flatMap((item) => {
    // eslint-disable-next-line no-unused-vars
    const { label, queryGroup, ...rest } = item;
    return splitKeysAndValues(rest);
  });
});

// selettori dettaglio

export const selectIppicaRaceDetail = createSelector(_self, (state) => state.ippicaDetailRace ?? emptyRaceDetails);
export const selectIppicaInfoTipoScommessa = createSelector(_self, (state) => state.ippicaInfoTipoScommessa);
export const selectCodiceRiunione = createSelector([selectIppicaRaceDetail], (race) => race.codiceRiunione);
export const selectPronostico = createSelector([selectIppicaRaceDetail], (race) => race.pronostico);
export const selectDescrizionePremio = createSelector([selectIppicaRaceDetail], (race) => race.descrizionePremio);
export const selectDescrizioneRiunione = createSelector([selectIppicaRaceDetail], (race) => race.descrizioneRiunione);
export const selectPista = createSelector([selectIppicaRaceDetail], (race) => race.pista);
export const selectTipoIppica = createSelector([selectIppicaRaceDetail], (race) => race.tipoIppica);
export const selectTipoGara = createSelector([selectIppicaRaceDetail], (race) => race.tipoGara);
export const selectTipoCorsa = createSelector([selectIppicaRaceDetail], (race) => race.tipoCorsa);
export const selectNumeroCorsa = createSelector([selectIppicaRaceDetail], (race) => race.numeroCorsa);
export const selectSiglaRiunione = createSelector([selectIppicaRaceDetail], (race) => race.siglaRiunione);
export const selectCodiceProgramma = createSelector([selectIppicaRaceDetail], (race) => race.codiceProgramma);
export const selectNumeroAvvenimento = createSelector([selectIppicaRaceDetail], (race) => race.numeroAvvenimento);
export const selectCavalliKeyList = createSelector(
  selectIppicaRaceDetail,
  (ippicaRaceDetail) => ippicaRaceDetail.cavalliKeyList
);
export const selectDescrizioneAvvenimento = createSelector(
  [selectIppicaRaceDetail],
  (race) => race.descrizioneAvvenimento
);

// codProgramma_numAvv_codTipoScommessa
export const selectScommesseListByScommessaKey = (scommessaKey: string) =>
  createSelector([selectIppicaRaceDetail], (ippicaDetailRace) => ippicaDetailRace?.scommesseMap?.[scommessaKey]);

export const selectQuotaFromEsitoMap = (esitoKey: string) =>
  createSelector([selectIppicaRaceDetail], (ippicaRaceDetail) => ippicaRaceDetail?.esitoMap?.[esitoKey].quota);

export const selectTrisIdProgIdAvv = createSelector(selectIppicaRaceDetail, (race) => {
  return {
    trisCodiceProgramma: race.trisCodiceProgramma,
    trisNumeroAvvenimento: race.trisNumeroAvvenimento,
  };
});
export const selectTotIdProgIdAvv = createSelector(selectIppicaRaceDetail, (race) => {
  return {
    totCodiceProgramma: race.totCodiceProgramma,
    totNumeroAvvenimento: race.totNumeroAvvenimento,
  };
});

export const selectCommentoCorsa = createSelector([selectIppicaRaceDetail], (race) => race.commentoCorsa);
export const selectMeteo = createSelector([selectIppicaRaceDetail], (race) => race.meteo);
export const selectDataOra = createSelector([selectIppicaRaceDetail], (race) => race.dataOraAvvenimento);

export const selectIsAntepost = createSelector([selectIppicaRaceDetail], (race) => race?.isAntepost ?? false);

export const selectIsTris = createSelector(selectIppicaRaceDetail, (race) => race.isTris);
export const selectIsTot = createSelector(selectIppicaRaceDetail, (race) => race.isTot);
export const selectIsQf = createSelector([selectIppicaRaceDetail], (race) => race.isQf);

export const selectCodiceProgrammaAndAvvenimentoTotOrNazionale = createSelector(
  selectIppicaRaceDetail,
  (state: RootState) => _selfIppicaSettings(state),
  (state: RootState, codiceTipoScommessa: string) => codiceTipoScommessa,
  (ippicaRaceDetail, settings, codiceTipoScommessa) => {
    const { trisCodiceProgramma, trisNumeroAvvenimento, totNumeroAvvenimento, totCodiceProgramma } = ippicaRaceDetail;
    let codiceProgramma;
    let numeroAvvenimento;
    if (codiceTipoScommessa) {
      const tipoVendita = settings!.tipoScommessaList![codiceTipoScommessa].type;
      if (tipoVendita === CartTipoVenditaEnum.TOTALIZZATORE) {
        codiceProgramma = totCodiceProgramma;
        numeroAvvenimento = totNumeroAvvenimento;
      } else {
        codiceProgramma = trisCodiceProgramma;
        numeroAvvenimento = trisNumeroAvvenimento;
      }
      return {
        tipoVendita,
        codiceProgramma,
        numeroAvvenimento,
      };
    }
    return;
  }
);

export const selectStatoAvvenimento = createSelector([selectIppicaRaceDetail], (race) => race.codiceStatoAvvenimento);

// TODO CAPIRE SE OK
// export const selectCavalli = createSelector([selectIppicaRaceDetail], (race) => race.cavalli);
export const selectCavalli = createSelector([selectIppicaRaceDetail], (race) => {
  return race.cavalliDict;
});

export const selectCavallo = createSelector(
  [(st: RootState, _key: string) => selectCavalli(st), (_st: RootState, key: string) => key],
  (cavalli, key) => {
    return cavalli[key];
  }
);

export const selectCavalloFactory = () =>
  createSelector([(st: RootState, key: string) => selectCavallo(st, key)], (cavallo) => cavallo);

export const selectCavalloFromEsitoKey = createSelector(
  selectIppicaRaceDetail,
  (state: RootState, esitoKey: string) => esitoKey,
  (ippicaRaceDetail, esitoKey) => {
    const { avvenimentoKey, numeroCavallo } = new KeyManagerIppica(esitoKey);
    const cavalloKey = [avvenimentoKey, numeroCavallo].join('_');

    return ippicaRaceDetail.cavalliDict[cavalloKey];
  }
);

// selettori cavallo

export const selectCavalliVincenti = createSelector([selectCavalli, selectIppicaRaceDetail], (cavalliMap, race) => {
  if (race) {
    return race.cavalliKeyList
      .filter((key) => {
        return cavalliMap[key].ordineArrivo;
      })
      .sort((a, b) => {
        const ordineArrivoA = Reflect.get(cavalliMap?.[a] ?? {}, 'ordineArrivo') ?? Infinity;
        const ordineArrivoB = Reflect.get(cavalliMap?.[b] ?? {}, 'ordineArrivo') ?? Infinity;
        if (ordineArrivoB > ordineArrivoA) return -1;
        return 1;
      });
  } else {
    return [];
  }
});
export const selectCavalliNonVincenti = createSelector([selectCavalli, selectIppicaRaceDetail], (cavalliMap, race) => {
  if (race) {
    return race.cavalliKeyList.filter((key: string) => {
      return !cavalliMap[key].ordineArrivo;
    });
  } else {
    return [];
  }
});

// Tipo Scommessa List
export const selectTipoScommessaList = createSelector(
  [_selfIppicaTipoScommessa],
  (ippicaTipoScommessa) => ippicaTipoScommessa ?? []
);

export const selectTipoScommessaFromEsitoKey = createSelector(
  (state: RootState) => selectTipoScommessaList(state),
  (state: RootState, key: string) => key,
  (tipoScommessaList, key) => {
    const { codiceTipoScommessa } = new KeyManagerIppica(key);
    const tipoScommessa = tipoScommessaList.find(
      (tipoScommessa) => `${tipoScommessa.codiceTipoScommessa}` === codiceTipoScommessa!
    );
    if (!tipoScommessa) {
      raise('TipoScommessa not found');
    }
    return tipoScommessa!;
  }
);
export const selectTipoScommessaFromEsitoKeyTot = createSelector(
  (state: RootState) => selectTipoScommessaList(state),
  (state: RootState) => _selfIppicaSettings(state),
  (state: RootState, key: string) => key,
  (tipoScommessaList, settings, key) => {
    const { codiceTipoScommessa } = new KeyManagerIppica(key);
    // console.log('codiceTipoScommessa_test', codiceTipoScommessa);
    const tipoScommessa = tipoScommessaList.find(
      (tipoScommessa) =>
        `${tipoScommessa.codiceTipoScommessa}` === settings?.tipoScommessaList[codiceTipoScommessa!].cod_scom!
    );
    if (!tipoScommessa) {
      raise('TipoScommessa not found');
    }
    // console.log('tiposcommessa', tipoScommessa);
    return tipoScommessa!;
  }
);
export const selectTipoScommessaFromCodiceScommessaCampi = createSelector(
  (state: RootState) => selectTipoScommessaList(state),
  (state: RootState) => _selfIppicaSettings(state),
  (state: RootState, key: string) => key,
  (tipoScommessaList, settings, key) => {
    const codScom = settings?.tipoScommessaList[key ?? raise('codiceTipoScommessa not found')].cod_scom;
    const tipoScommessa = tipoScommessaList.find((tipoScommessa) => `${tipoScommessa.codiceTipoScommessa}` === codScom);
    if (!tipoScommessa) {
      raise('TipoScommessa not found');
    }
    return tipoScommessa!;
  }
);
export const selectDescrizioneTot = createSelector(
  (state: RootState) => _selfIppicaSettings(state),
  (state: RootState, key: string) => key,
  (settings, key) => {
    const { codiceTipoScommessa } = new KeyManagerIppica(key);
    console.log('codiceTipoScommessa', codiceTipoScommessa);
    if (codiceTipoScommessa) {
      return settings?.tipoScommessaList[codiceTipoScommessa].descrizione;
    } else {
      return;
    }
  }
);

export const selectDescrizioneCampi = createSelector(
  (state: RootState) => _selfIppicaSettings(state),
  (state: RootState, key: string) => key,
  (settings, key) => {
    return settings?.tipoScommessaList[key].descrizione;
  }
);

export const selectCodiceStatoCavallo = (keyCavallo: string) =>
  createSelector([selectCavalli], (cavalli) => {
    return cavalli[keyCavallo].codiceStatoCavallo;
  });

// selettori spalla
/* export const selectIsCurrentlyIppica = createSelector(
  [selectCurrentPath],
  (currentPathname) => (currentPathname.match(/^ippica/gim) ?? []).length > 0
); */

// TODO ? s'è ghè?
export const selectIsCurrentlyIppica = (_state: RootState) => true;

export const selectActiveTab = createSelector([_self], ({ activeTab }) => activeTab);
export const selectMenuData = createSelector(
  [(state: RootState, _tab: TabValue) => _selfNav(state), (_state: RootState, tab: TabValue) => tab],
  (menu, tab) => menu?.[tab]?.entries
);
export const selectIsMenuLoading = createSelector(
  [(state: RootState, _tab: TabValue) => _selfNav(state), (_state: RootState, tab: TabValue) => tab],
  (menu, tab) => menu?.[tab]?.isLoading
);
export const selectTs = createSelector(
  [(state: RootState, _tab: TabValue) => _selfNav(state), (_state: RootState, tab: TabValue) => tab],
  (menu, tab) => menu?.[tab]?.ts
);

// selettori from ippicaSettings
export const selectGroupsFromIppicaSettings = createSelector(
  [_selfIppicaSettings],
  (ippicaSettings) => ippicaSettings?.gruppo ?? emptyGruppiFromSettings
);

export const selectTipoScommessaListFromIppicaSettings = createSelector([_selfIppicaSettings], (ippicaSettings) =>
  ippicaSettings ? ippicaSettings.tipoScommessaList : emptyGruppiFromSettings
);

export const selectCurrentTipoScommessaFromIppicaSettings = createSelector(
  [selectTipoScommessaKey, selectTipoScommessaListFromIppicaSettings],
  (key, tipoScommessaList) => {
    if (tipoScommessaList && key) {
      return tipoScommessaList[key];
    } else {
      return;
    }
  }
);
export const selectHasColumns = createSelector(
  [selectCurrentTipoScommessaFromIppicaSettings],
  (currentTipoScommessaFromIppicaSettings) => {
    return currentTipoScommessaFromIppicaSettings ? currentTipoScommessaFromIppicaSettings.colonne.length : undefined;
  }
);
// TODO HANDLE LOGIC
export const selectColonneByTipoScommessaFromSettings = createSelector(
  [selectCurrentTipoScommessaFromIppicaSettings],
  (currentTipoScommessaFromIppicaSettings) => {
    if (currentTipoScommessaFromIppicaSettings) {
      return currentTipoScommessaFromIppicaSettings.colonne;
    } else {
      return [];
    }
  }
);
export const selectCampiMatrix = createSelector([_self], (ippicaState) => ippicaState.campiMatrix);
export const selectNumeroCavalliMatrix = createSelector(
  [_self],
  (ippicaState) => ippicaState.matrixNumeroCavalloSelect
);

// export const selectValueCheckBox = createSelector([selectCheckColumn], (numberOfCheckbox) => {
//   if (numberOfCheckbox) {
//     return Array.from({ length: numberOfCheckbox }, (_, index) => ({
//       id: String(index + 1),
//       value: String(index + 1),
//       checked: false,
//       //numeroCavallo:1,
//     }));
//   } else {
//     return [];
//   }
// });

//filter calliDict( numeroCavalloSelected) [...]

// export const selectQuotaProva = createSelector([selectCheckColumn],(numberOfC))

//filter calliDict( numeroCavalloSelected) [...]

export const selectSiglaSistemaVincente = createSelector(
  [_selfIppicaSettings, selectCurrentScommesseType],
  (settings, currentScommesseType) => {
    if (currentScommesseType !== IppicaScommesseType.QF) {
      return settings?.tipoScommessaList['0-VIN'];
    } else {
      return;
    }
  }
);
export const selectSiglaSistemaPiazzatoMinMax = (codiceTipoScommessa: number | undefined) =>
  createSelector(
    [_selfIppicaSettings, selectCurrentScommesseType, selectBlockIppicaScommessa],
    (ippicaSettings, currentScommesseType, blockIppicaScommessa) => {
      if (currentScommesseType !== IppicaScommesseType.QF && blockIppicaScommessa === '0' && codiceTipoScommessa) {
        const siglaSistemaKey = [codiceTipoScommessa, blockIppicaScommessa].join('-');
        const { siglaSistemaMap, tipoScommessaList } = ippicaSettings;
        const siglaSistemaCombination =
          siglaSistemaMap && siglaSistemaMap[siglaSistemaKey] && siglaSistemaMap[siglaSistemaKey].length > 0
            ? siglaSistemaMap[siglaSistemaKey][0]
            : raise('SiglaSistema non trovata');
        return tipoScommessaList[siglaSistemaCombination];
      } else {
        return;
      }
    }
  );

// export const selectQuotaProva = createSelector([selectCheckColumn],(numberOfC))

// selettori componenti
export const selectIsTodayExpanded = createSelector(
  [(state: RootState, _key: string) => _selfTodayMenuItemToggle(state), (_state: RootState, key: string) => key],
  (todayExpandedItems, key) => {
    return todayExpandedItems?.includes(key);
  }
);

export const selectBroadCast = createSelector(
  [
    (streamingComplementare: Array<VideoStreamingType>) => streamingComplementare,
    (__, streamingStandard: Array<VideoStreamingType>) => streamingStandard,
  ],
  (streamingComplementare, streamingStandard): IppicaBroadcastBoxType[] | undefined => {
    if (!!streamingComplementare?.length && !!streamingStandard?.length) {
      const videoComplementareList: IppicaBroadcastBoxType[] = streamingComplementare.map((item) => {
        let regex = new RegExp('\\b' + 'SNAITV' + '\\b');
        let corrispondenze = item.des_riunione.match(regex);
        const label = !!corrispondenze?.length ? corrispondenze[0] : '';
        return {
          type: BroadcastEnum.TV,
          label: label,
          name: item.des_riunione.replace(new RegExp(label!, 'g'), ''),
          logged: Boolean(item.logged),
          url: item.url_hls,
        };
      });
      const videoStandardList: IppicaBroadcastBoxType[] = streamingStandard.map((item) => {
        return {
          type: BroadcastEnum.TV,
          name: item.des_riunione,
          url: item.url_hls,
        };
      });
      const radioList: IppicaBroadcastBoxType[] = [
        { type: BroadcastEnum.RADIO, name: 'radioSnai', url: process.env.NEXT_PUBLIC_ENDPOINT_RADIO },
      ];
      return [...radioList, ...videoComplementareList, ...videoStandardList];
    } else {
      return;
    }
  }
);

// scommesse

const extractActiveGroupScommesse = (race: IppicaCorsaDetailsDto, includeQf?: boolean) => {
  const { isQf, isTot, isTris, isTqq } = race;
  return Object.entries({ isQf: includeQf ? isQf : undefined, isTot, isTris, isTqq })
    .filter(([_, value]) => value!)
    .map(([key]) => key);
};

// Selettore per estrarre i flag attivi
export const selectActiveGroupScommesse = createSelector([selectIppicaRaceDetail], (ippicaDetailRaceState) => {
  return ippicaDetailRaceState ? extractActiveGroupScommesse(ippicaDetailRaceState, true) : [];
});
export const selectActiveGroupScommesseWithOutQuotaFissa = createSelector(
  [selectIppicaRaceDetail],
  (ippicaDetailRaceState) => {
    return ippicaDetailRaceState ? extractActiveGroupScommesse(ippicaDetailRaceState, false) : [];
  }
);

export const selectTipoScommessQuotaFissaList = createSelector(
  [selectIppicaRaceDetail],
  (ippicaCorsaDetail) => ippicaCorsaDetail.headersEsitoMap
);

export const selectCodiceTipoScommessaListFromSettings = createSelector(
  [_selfIppicaSettings],
  (ippicaSettings) => ippicaSettings?.codiceTipoScommessaList
);

// selettori pulsantiera
export const selectPulsantieraGruppoScommessa = createSelector(
  [
    selectActiveGroupScommesse,
    selectGroupsFromIppicaSettings,
    selectCodiceTipoScommessaListFromSettings,
    _selfIppicaTipoScommessa,
    selectIsTot,
    selectIsTris,
    selectIsQf,
    selectTipoScommessQuotaFissaList,
  ],
  (
    gruppiScommesseAttivi,
    gruppiScommesseFromIppicaSettings,
    codiceTipoScommessaListFromSettings,
    ippicaTipoScommessa,
    isTot,
    isTris,
    isQf,
    tipoScommessaQuotaFissaList
  ): GruppoScommessaList => {
    if (gruppiScommesseAttivi && gruppiScommesseFromIppicaSettings && ippicaTipoScommessa) {
      return gruppiScommesseAttivi.reduce<GruppoScommessaList>((gruppoScommessaList, currentValue) => {
        const existingGroup = gruppoScommessaList.find(
          (gruppoScommessa) => gruppoScommessa.title === gruppiScommesseFromIppicaSettings[currentValue].title
        );
        if (existingGroup) {
          existingGroup.items = [...existingGroup.items, ...gruppiScommesseFromIppicaSettings[currentValue].items];
        } else {
          if (
            currentValue === 'isQf' &&
            gruppiScommesseFromIppicaSettings[currentValue]?.items &&
            tipoScommessaQuotaFissaList
          ) {
            const { title, items: gruppoScommesseQuotaFissaFromSettingsList } =
              gruppiScommesseFromIppicaSettings[currentValue];

            gruppoScommessaList.push({
              title,
              items: gruppoScommesseQuotaFissaFromSettingsList.filter((gruppoScommesseQuotaFissaFromSettings) =>
                tipoScommessaQuotaFissaList.some((tipoScommessaQuotaFissa) => {
                  const { block } = gruppoScommesseQuotaFissaFromSettings;
                  const { codTipoScommessa: codiceTipoScommessa } = tipoScommessaQuotaFissa;
                  if (block && codiceTipoScommessaListFromSettings) {
                    const codiceTipoScommessaListFromSettingsByBlock = codiceTipoScommessaListFromSettings[block];
                    return codiceTipoScommessaListFromSettingsByBlock.some(
                      (codiceTipoScommessaFromSettings) => codiceTipoScommessaFromSettings === codiceTipoScommessa
                    );
                  }
                  return false;
                })
              ),
            });
          } else {
            gruppoScommessaList.push({
              title: gruppiScommesseFromIppicaSettings[currentValue].title,
              items:
                // check to verify tipoScommessa is 10 ( 10 is Trio for Totalizzatore Tris for National)
                isTot && !isTris && hasTipoScommessaTrioByValue(ippicaTipoScommessa, COD_SCOM_TRIO)
                  ? gruppiScommesseFromIppicaSettings[currentValue].items
                  : gruppiScommesseFromIppicaSettings[currentValue].items.slice(0, 2),
            });
          }
        }
        return gruppoScommessaList;
      }, []);
    } else {
      return [];
    }
  }
);

export const selectCodiceTipoScommessaListFromTotalizzatore = createSelector(
  [_selfIppicaTipoScommessa],
  (ippicaTipoScommessa) => {
    if (!ippicaTipoScommessa?.length) {
      return [];
    }
    return ippicaTipoScommessa
      .filter((scommessa) => !scommessa.isQf)
      .map((scommessa) => scommessa.codiceTipoScommessa!)
      .sort((a, b) => a! - b!);
  }
);
export const selectIppicaTotalizzatoreScommesseList = createSelector(
  [_selfIppicaTipoScommessa],
  (ippicaTipoScommessa) => {
    if (!ippicaTipoScommessa?.length) {
      return [];
    }
    return ippicaTipoScommessa
      .filter((scommessa) => !scommessa.isQf)
      .map((scommessa) => scommessa!)
      .sort((a, b) => a.codiceTipoScommessa! - b.codiceTipoScommessa!);
  }
);

export const selectDettagliTipoScommessaIppica = createSelector(
  [_selfIppicaSettings],
  (ippicaSettings) => ippicaSettings?.dettagliTipoScommessaIppica
);

export const selectSiglaSistemiListFromSettings = createSelector(
  [_selfIppicaSettings, selectIsTot, selectIsTris],
  (ippicaSettings, isTot, isTris) => {
    if (isTot && !isTris) {
      // TOTALIZZATORE
      return ippicaSettings?.siglaSistemaTotalizzatore;
    } else if (!isTot && isTris) {
      return ippicaSettings?.siglaSistemaNazionale;
      // NAIONALE
    } else if (isTot && isTris) {
      return ippicaSettings?.siglaSistemaMap;
      // TOTALIZZATORE + NAIONALE
    } else {
      return [];
    }
  }
);

export const selectPulsantieraTipoScommessa = (blockValue: string | undefined) =>
  createSelector(
    [
      selectSiglaSistemiListFromSettings,
      selectCodiceTipoScommessaListFromSettings,
      selectCodiceTipoScommessaListFromTotalizzatore,
      selectDettagliTipoScommessaIppica,
      selectTipoScommessaListFromIppicaSettings,
    ],
    (
      siglaSistemaListFromSettings,
      codiceTipoScommessaListFromSettings,
      codiceTipoScommessaListFromTotalizzatore,
      dettagliTipoScommessaIppica,
      tipoScommessaListFromSettings
    ): Array<ItemWithInfo> => {
      if (
        siglaSistemaListFromSettings &&
        blockValue &&
        codiceTipoScommessaListFromSettings &&
        dettagliTipoScommessaIppica &&
        tipoScommessaListFromSettings
      ) {
        return codiceTipoScommessaListFromSettings[blockValue]
          ?.filter((codiceScommessa: number) => {
            return codiceTipoScommessaListFromTotalizzatore.includes(codiceScommessa);
          })
          ?.flatMap((codiceScommessa) => {
            return siglaSistemaListFromSettings[`${codiceScommessa}-${blockValue}`];
          })
          .map((siglaSistema: string) => {
            return {
              label: tipoScommessaListFromSettings[siglaSistema]?.descrizione,
              title: dettagliTipoScommessaIppica[siglaSistema]?.titolo,
              description: dettagliTipoScommessaIppica[siglaSistema]?.descrizione,
              key: siglaSistema,
            };
          });
      } else {
        return [];
      }
    }
  );

export const selectCodiceTipoScommessaQuotaFissaList = createSelector(
  _selfIppicaTipoScommessa,
  (ippicaTipoScommessa) => {
    if (ippicaTipoScommessa) {
      return (
        ippicaTipoScommessa
          .filter((tipoScommessa) => tipoScommessa.isQf)
          // .sort((a, b) => a.codiceTipoScommessa - b.codiceTipoScommessa) // TODO CHECK
          .map((tipoScommessa) => tipoScommessa.codiceTipoScommessa)
      );
    } else {
      return [];
    }
  }
);

// export const selectTipiScommessaTotalizzatoreList = createSelector(
//   _selfIppicaTipoScommessaList,
//   (ippicaTipoScommessaList) => {
//     if (ippicaTipoScommessaList) {
//       return ippicaTipoScommessaList
//         .filter((tipoScommessa) => !tipoScommessa.isQf)
//         .sort((a, b) => a.codiceTipoScommessa - b.codiceTipoScommessa)
//         .map((tipoScommessa) => tipoScommessa.codiceTipoScommessa);
//     } else {
//       return [];
//     }
//   }
// );

export const selectMovimentiTotalizzatoreTitleList = createSelector(
  [_selfIppicaSettings],
  (settingsIppica) => settingsIppica?.movimentiTotalizzatoreTitlesList
);

export const selectMovimentiNazionaleTitleList = createSelector(
  [_selfIppicaSettings],
  (settingsIppica) => settingsIppica?.movimentiNazionaleTitlesList
);

export const selectTotalizzatoreMovFromTipoScommessaList = createSelector(
  [_selfIppicaTipoScommessa],
  (ippicaTipoScommessa) => {
    if (ippicaTipoScommessa) {
      return ippicaTipoScommessa
        .filter((scommessa) => !scommessa.isQf)
        .reduce<{ [key: string]: number }>((scommessaAcc, currentScommessa) => {
          if (currentScommessa.mov) {
            scommessaAcc[currentScommessa.codiceTipoScommessa] = currentScommessa.mov;
          }
          return scommessaAcc;
        }, {});
    } else {
      return [];
    }
  }
);
export const selectMovimentiList = createSelector(
  [
    selectIsTris,
    selectIsTot,
    selectCodiceTipoScommessaListFromTotalizzatore,
    selectTotalizzatoreMovFromTipoScommessaList,
    selectMovimentiTotalizzatoreTitleList,
    selectMovimentiNazionaleTitleList,
  ],
  (
    isTris,
    isTot,
    ippicaTotalizzatoreCodiciTipoScommessaList,
    movimentiTotalizzatoreList,
    totalizzatoreTitles,
    nazionaleTitles
  ) => {
    if (ippicaTotalizzatoreCodiciTipoScommessaList && totalizzatoreTitles && nazionaleTitles) {
      return ippicaTotalizzatoreCodiciTipoScommessaList.map((tipoScommessa) => {
        return {
          esito:
            'Movimento Totale ' +
            (isTot && !isTris ? totalizzatoreTitles[tipoScommessa] : nazionaleTitles[tipoScommessa]),
          quota: convertAmountWithoutCurrencyValue(movimentiTotalizzatoreList[tipoScommessa] / 100, IppicaLocale.it),
        };
      });
    } else {
      return [];
    }
  }
);

export const selectTiltlesQuoteByGroup = createSelector(
  [_selfIppicaSettings, selectIsTris, selectIsTot],
  (settingsIppica, isTris, isTot) => {
    if (settingsIppica) {
      if (isTris && isTot) {
        return settingsIppica.quoteMisteTitlesList;
      } else if (!isTris && isTot) {
        return settingsIppica?.quoteTotTitlesList;
      } else if (isTris && !isTot) {
        return settingsIppica?.quoteMisteTitlesList;
      } else {
        return {};
      }
    } else {
      return {};
    }
  }
);

export const selectCodiceStatoAvvenimento = createSelector(
  [selectIppicaRaceDetail],
  (race) => race.codiceStatoAvvenimento
);

export const selectListTitlesResults = createSelector(
  [selectIsQf, selectIsTot, selectIsTris],
  (isQf, isTot, isTris) => {
    if (isQf && isTot && isTris) {
      return [quotaFissaResults, quotaTotalizzatoreResults];
    } else if (isQf && !isTot && !isTris) {
      return [quotaFissaResults];
    } else if (isQf && isTot && !isTris) {
      return [quotaFissaResults, quotaTotalizzatoreResults];
    } else if (!isQf && isTot && isTris) {
      return [quotaTotalizzatoreResults];
    } else if (!isQf && !isTot && isTris) {
      return [quotaTotalizzatoreResults];
    } else if (!isQf && isTot && !isTris) {
      return [quotaTotalizzatoreResults];
    } else {
      return [];
    }
  }
);

export const selectHeadersQuotaFissaList = createSelector([selectIppicaRaceDetail], (race) => race.headersQuotaFissa);

export const selectHeaderList = (hasCampi?: boolean) =>
  createSelector(
    [selectCurrentScommesseType, selectHeadersQuotaFissaList, selectBlockIppicaScommessa],
    (currentScommesseType, headersQuotaFissaList, blockIppicaScommessa) => {
      if (currentScommesseType === IppicaScommesseType.QF) {
        if (
          blockIppicaScommessa === BlockScommessa.AccoppiataDisordine ||
          blockIppicaScommessa === BlockScommessa.TrioDisordine
        ) {
          return accoppiataTrioDisordineHeader;
        } else if (
          blockIppicaScommessa === BlockScommessa.AccoppiataOrdine ||
          blockIppicaScommessa === BlockScommessa.TrioOrdine
        ) {
          return accoppiataTrioOrdineHeader;
        } else {
          return headersQuotaFissaList;
        }
      } else {
        if (hasCampi) {
          return totalizzatoreHeadersListWithCampi;
        } else {
          return totalizzatoreHeadersList;
        }
      }
    }
  );

/**
 * QUOTE
 */
export const selectQuoteKeyList = (keyCavallo: string) =>
  createSelector([selectCavalli], (cavalliDict) => {
    return cavalliDict[keyCavallo]?.quoteKeyList;
  });

export const selectKeyListQuotaFissa = (keyCavallo: string) =>
  createSelector([selectCavalli, selectCodiceTipoScommessaQuotaFissaList], (cavalliMap, codiceTipoScommessaQfList) => {
    const quoteKeyList = cavalliMap[keyCavallo]?.quoteKeyList;
    return filterQuoteKeysByCodiceTipoScommessa(quoteKeyList, codiceTipoScommessaQfList, true);
  });

export const selectKeyListQuotaFissaResults = (keyCavallo: string) =>
  createSelector([selectKeyListQuotaFissa(keyCavallo), selectCavalli], (keyListQuotaFissa, cavalliMap) => {
    if (!cavalliMap) {
      return [];
    }
    return keyListQuotaFissa.filter((key) => {
      if (cavalliMap[key]?.ordineArrivo || cavalliMap[key]?.ordineArrivo !== null) {
        return key;
      } else {
        return;
      }
    });
  });
export const selectKeyListQuoteTotalizzatore = (keyCavallo: string) =>
  createSelector([selectCavalli, selectCodiceTipoScommessaQuotaFissaList], (cavalliMap, codiceTipoScommessaQfList) => {
    const quoteKeyList = cavalliMap[keyCavallo]?.quoteKeyList;
    return filterQuoteKeysByCodiceTipoScommessa(quoteKeyList, codiceTipoScommessaQfList, false);
  });

export const selectKeyListQuoteVincenti = (keyList: string[]) =>
  createSelector([selectIsTris], (isTris) => {
    return keyList.filter((key) => {
      const codiceTipoScommessa = retrieveCodiceTipoScommessa(key);
      if (isTris) {
        return codiceTipoScommessa === 10;
      } else {
        return codiceTipoScommessa === 1;
      }
    });
  });

export const selectKeyListQuoteVincentiResults = (keyList: string[], keyCavallo: string) =>
  createSelector([selectKeyListQuoteVincenti(keyList), selectCavalli], (keyListQuoteVincenti, cavalliDict) => {
    return keyListQuoteVincenti
      .filter((quoteVincenti) => {
        if (cavalliDict[keyCavallo]?.ordineArrivo === 1) {
          return quoteVincenti;
        } else {
          return;
        }
      })
      .map((quoteVincenti) => quoteVincenti);
  });

export const selectQuoteDict = (keyCavallo: string) =>
  createSelector([selectCavalli], (cavalliDict) => {
    return cavalliDict[keyCavallo]?.quoteDict;
  });

export const selectEsitoFromQuoteDict = createSelector(
  (state: RootState, key: string) => selectCavalloFromEsitoKey(state, key),
  (state: RootState, key: string) => key,
  (cavallo, key) => {
    const { scommessaKey } = new KeyManagerIppica(key);
    return cavallo.quoteDict[scommessaKey];
  }
);

export const selectEsitoFromEsitoMap = createSelector(
  (state: RootState) => selectIppicaRaceDetail(state),
  (state: RootState, esitoKey: string) => esitoKey,
  (ippicaRaceDetail, esitoKey) => {
    return ippicaRaceDetail?.esitoMap?.[esitoKey] ?? undefined;
  }
);

export const selectQuotaFissa = (keyCavallo: string, keyQuota: string) =>
  createSelector(
    [selectCodiceTipoScommessaQuotaFissaList, selectQuoteDict(keyCavallo)],
    (scommesseQuotaFissaList, quoteMap) => {
      if (scommesseQuotaFissaList.includes(quoteMap[keyQuota]?.codiceTipoScommessa)) {
        return {
          infoAggId: quoteMap[keyQuota].infoAggiuntiva,
          value: quoteMap[keyQuota].quota,
        } as QuotaWithInfoAgg;
      }

      return undefined;
    }
  );

export const selectQuotaFissaResults = (keyCavallo: string, keyQuota: string) =>
  createSelector(
    [selectCodiceTipoScommessaQuotaFissaList, selectQuoteDict(keyCavallo), selectCavalli],
    (scommesseQuotaFissaList, quoteMap /*, cavalliMap */) => {
      if (scommesseQuotaFissaList.includes(quoteMap[keyQuota]?.codiceTipoScommessa)) {
        return quoteMap[keyQuota].quota;
      } else {
        return undefined;
      }
    }
  );

export const selectQuotaVincente = (keyCavallo: string, keyQuota: string) =>
  createSelector([selectQuoteDict(keyCavallo), selectIsTris, selectIsTot], (quoteMap, isTris, isTot) => {
    if (isTris && quoteMap[keyQuota]?.codiceTipoScommessa === 10) {
      return {
        value: quoteMap[keyQuota].quota,
        infoAggId: quoteMap[keyQuota].infoAggiuntiva,
      } as QuotaWithInfoAgg;
    }
    if (isTot && !isTris && quoteMap[keyQuota]?.codiceTipoScommessa === 1) {
      return {
        value: quoteMap[keyQuota].quota,
        infoAggId: quoteMap[keyQuota].infoAggiuntiva,
      } as QuotaWithInfoAgg;
    }
    return undefined;
  });

export const selectQuotaVincenteResults = (keyCavallo: string, keyQuota: string) =>
  createSelector(
    [selectQuoteDict(keyCavallo), selectIsTris, selectIsTot, selectCavalli],
    (quoteMap, isTris, isTot, cavalliMap) => {
      if (isTris && quoteMap[keyQuota]?.codiceTipoScommessa === 10 && cavalliMap[keyCavallo].ordineArrivo) {
        return quoteMap[keyQuota]?.quota;
      } else if (
        isTot &&
        !isTris &&
        quoteMap[keyQuota]?.codiceTipoScommessa === 1 &&
        cavalliMap[keyCavallo].ordineArrivo
      ) {
        return quoteMap[keyQuota]?.quota;
      } else {
        return;
      }
    }
  );
export const selectQuotaIppica = (keyCavallo: string, keyQuota: string) =>
  createSelector(
    [
      selectQuotaFissa(keyCavallo, keyQuota),
      selectQuotaVincente(keyCavallo, keyQuota),
      selectCurrentScommesseType,
      selectStatoAvvenimento,
      selectQuotaFissaResults(keyCavallo, keyQuota),
      selectQuotaVincenteResults(keyCavallo, keyQuota),
    ],
    (
      selectQuotaFissaFunc,
      selectQuotaVincenteFunc,
      currentScommesseType,
      statoAvvenimento,
      quotaFissaResultsFunc,
      quotaVincenteFunc
    ): QuotaWithInfoAgg | undefined => {
      let result: QuotaWithInfoAgg | undefined;
      if (currentScommesseType === IppicaScommesseType.QF) {
        if (isEqualStatoScommessaByKey(statoAvvenimento, Stato.Aperta)) {
          result = selectQuotaFissaFunc; // Restituisci null se quotaFissa è undefined
        } else {
          result = { value: quotaFissaResultsFunc };
        }
      } else {
        if (isEqualStatoScommessaByKey(statoAvvenimento, Stato.Aperta)) {
          result = selectQuotaVincenteFunc; // Restituisci null se quotaVincente è undefined
        } else {
          result = { value: quotaVincenteFunc };
        }
      }

      return result;
    }
  );

export const selectKeyListQuoteMinMax = (keyCavallo: string) =>
  createSelector(selectKeyListQuoteTotalizzatore(keyCavallo), (keyList) => {
    return keyList.filter((key) => {
      const codiceTipoScommessa = retrieveCodiceTipoScommessa(key);
      return codiceTipoScommessa !== 10 && codiceTipoScommessa !== 1;
    });
  });

export const selectQuoteMinMaxList = (keyList: string[], keyCavallo: string) =>
  createSelector([selectQuoteDict(keyCavallo), selectCurrentScommesseType], (quoteMap, currentScommesseType) => {
    if (currentScommesseType === IppicaScommesseType.QF) {
      return;
    }

    const x = keyList
      .map((key) => {
        return quoteMap[key];
      })
      .reduce<Array<{ quota: string }>>((accumulator, result, indice) => {
        // Se l'indice è dispari, aggiungi la proprietà 'quote' all'oggetto precedente
        if (indice % 2 === 0) {
          // Converti 'quota' in stringa e aggiungi un trattino
          accumulator.push({ ...result, quota: !!result?.quota ? result?.quota?.toString() : '>>' });
        } else {
          // Aggiorna l'oggetto precedente con la nuova 'quota'
          if (
            accumulator[accumulator.length - 1] &&
            typeof accumulator[accumulator.length - 1].quota === 'string' &&
            !!accumulator[accumulator.length - 1]?.quota &&
            accumulator[accumulator.length - 1]?.quota !== '>>'
          ) {
            let newQuota = convertDecimalQuota(+accumulator[accumulator.length - 1].quota);
            let secondQuota = convertDecimalQuota(result.quota);
            accumulator[accumulator.length - 1].quota = newQuota += '-' + secondQuota;
          } else {
            accumulator.push({ ...result, quota: '>>' }); // TODO CHECK
          }
        }
        return accumulator;
      }, []);
    return x;
  });

export const selectListQuotePiazzato = (keyListTot: string[], keyCavallo: string) =>
  createSelector(
    [selectQuoteDict(keyCavallo), selectCavalli, selectIsTris, selectIsTot],
    (quoteMap, cavalliMap /*, isTris, isTot */) => {
      return keyListTot
        .filter((key) => {
          return quoteMap[key].codiceTipoScommessa === 3;
          // if ((isTris && isTot) || (isTris && !isTot)) {
          // }
          // if (isTot && !isTris) {
          //   return quoteMap[key].codiceTipoScommessa === 2;
          // }
          // return;
        })
        .map((key) => {
          return {
            quota: cavalliMap[keyCavallo].ordineArrivo ? convertDecimalQuota(quoteMap[key].quota) : undefined,
          };
        });
    }
  );

/*
export const selectKeyListPiazzato = (keyListTot: string[], keyCavallo: string) =>
  createSelector([selectQuoteDict(keyCavallo), selectIsTris, selectIsTot], (quoteMap, isTris, isTot) => {
    return keyListTot
      .filter((key) => {
        if ((isTris && isTot) || (isTris && !isTot)) {
          return quoteMap[key].codiceTipoScommessa === 3;
        }
        if (isTot && !isTris) {
          return quoteMap[key].codiceTipoScommessa === 2;
        }
        return;
      })
      .map((key) => {});
  });
*/

export const selectCavalliVincentiKeyList = createSelector(
  [selectIppicaRaceDetail, selectCavalli],
  (race, cavalliMap) => {
    if (race) {
      const { cavalliKeyList } = race;
      return cavalliKeyList
        .filter((key: string) => {
          return cavalliMap[key].ordineArrivo;
        })
        .map((key: string) => {
          return {
            ordineArrivo: cavalliMap[key].ordineArrivo,
            numeroCavallo: cavalliMap[key].numeroCavallo,
          };
        })
        .sort((a, b) => {
          const ordineArrivoA = Reflect.get(a ?? {}, 'ordineArrivo') ?? Infinity;
          const ordineArrivoB = Reflect.get(b ?? {}, 'ordineArrivo') ?? Infinity;

          if (ordineArrivoB > ordineArrivoA) return -1;
          return 1;
        });
    } else {
      return [];
    }
  }
);

export const selectCavalliVincentiOrdineArrivoList = createSelector(
  [selectCavalliVincentiKeyList],
  (cavalliVincenti) => {
    return cavalliVincenti.flatMap((cavallo) => cavallo.numeroCavallo).join(',');
  }
);
export const selectKeyListQuoteAntepost = (keyCavallo: string) =>
  createSelector([selectCavalli], (cavalliMap) => {
    const quoteKeyList = cavalliMap[keyCavallo].quoteKeyList;
    return filterQuoteKeysByCodiceTipoScommessa(quoteKeyList, [101, 102], true);
  });
export const selectQuoteAntepost = (keyCavallo: string, keyQuota: string) =>
  createSelector([selectQuoteDict(keyCavallo)], (quoteMap) => {
    return quoteMap[keyQuota].quota;
  });
export const selectIsLoadingQuoteCombinate = createSelector([_self, selectIsTris], (state, isTris) => {
  if (isTris) {
    return state.isLoadingIppicaQuoteNaz;
  } else {
    return state.isLoadingIppicaQuoteTot;
  }
});

export const selectIppicaQuoteFinali = createSelector([_self, selectIsTris, selectIsTot], (state, isTris, isTot) => {
  if (isTris && isTot) {
    // TODO CHECK
    return state.ippicaQuoteNaz;
  } else if (isTris && !isTot) {
    return state.ippicaQuoteNaz;
  } else if (!isTris && isTot) {
    return state.ippicaQuoteTot;
  } else {
    return [];
  }
});

export const selectActiveScommessaForQuoteFinali = (hasVincente?: boolean) =>
  createSelector([selectIppicaTotalizzatoreScommesseList, selectTiltlesQuoteByGroup], (scommesseList, titleList) => {
    return scommesseList.filter((scommessa) => {
      if (!hasVincente) {
        return titleList.hasOwnProperty(scommessa.codiceTipoScommessa);
      } else {
        return scommessa.codiceTipoScommessa !== 1
          ? titleList.hasOwnProperty(scommessa.codiceTipoScommessa)
          : undefined;
      }
    });
  });

/*
export const selectCavalloFromSiglaSistema = createSelector(
  (state: RootState) => _selfIppicaSettings(state),
  (state: RootState, key: string) => selectCavalloFromEsitoKey(state, key),
  (_state: RootState, key: string) => key,
  (settings, cavallo, key) => {
    // const { codiceTipoScommessa } = new KeyManagerIppica(key);
    // const codiceTipoScommessaReal = codiceTipoScommessa
    //   ? settings?.tipoScommessaList[codiceTipoScommessa].cod_scom
    //   : raise('Error');
    // const { avvenimentoKey } = new KeyManagerIppica(key);
    return cavallo; //quoteDict[[avvenimentoKey, codiceTipoScommessaReal].join('_')].numeroEvento ?? 0;
  }
);
*/
export const selectCavalloFromSiglaSistema = createSelector([selectCavalloFromEsitoKey], (cavallo) => cavallo);

export const selectQuotaTotValue = createSelector(
  (state: RootState) => _selfIppicaSettings(state),
  (_state: RootState, key: string) => key,
  (settings, key) => {
    const { codiceTipoScommessa } = new KeyManagerIppica(key);
    if (codiceTipoScommessa) {
      const element = settings?.tipoScommessaList[codiceTipoScommessa] ?? raise('TipoScommessaList non trovata');
      return {
        unita: element.unita,
        minSco: element.min_sco,
        codiceTipoScommessa: +element.cod_scom,
        codiceSistema: element.cod_sistema,
        siglaSistema: `${element.sigla_sistema}`,
      };
    }
    return;
  }
);

export const selectQuotaCampiTotValue = createSelector(
  (state: RootState) => _selfIppicaSettings(state),
  (_state: RootState, key: string | undefined) => key,
  (settings, key) => {
    if (key) {
      const element = settings?.tipoScommessaList[key] ?? raise('TipoScommessaList non trovata');
      return {
        unita: element.unita,
        minSco: element.min_sco,
        codiceTipoScommessa: +element.cod_scom,
        codiceSistema: element.cod_sistema,
        siglaSistema: `${element.sigla_sistema}`,
      };
    } else {
      return;
    }
  }
);

export const selectValidation = createSelector(
  (state: RootState) => _selfIppicaSettings(state),
  (state: RootState) => selectTipoScommessaKey(state),
  (state: RootState) => selectNumeroCavalliMatrix(state),
  (ippicaSettings, key, numeroCavalliMatrix) => {
    if (ippicaSettings && key && !!numeroCavalliMatrix?.length) {
      const numeroGruppi = ippicaSettings.tipoScommessaList[key].n_gruppi;
      return ippicaSettings.tipoScommessaList[key].colonne.map((colonna, index) => {
        const from = ippicaSettings.tipoScommessaList[key].checkColonne[colonna].from;
        const to = ippicaSettings.tipoScommessaList[key].checkColonne[colonna].to;
        return (
          numeroCavalliMatrix.length === numeroGruppi &&
          numeroCavalliMatrix[index].length >= from &&
          numeroCavalliMatrix[index].length <= to
        );
      })[0];
    } else {
      return false;
    }
  }
);

export const selectCodiceTipoScommessaPiazzatoMinMax = createSelector(
  (state: RootState) => selectTipoScommessaList(state),
  (tipoScommessaList): number | undefined => {
    const tipoScommessaPiazzatoMinMax = tipoScommessaList.find(
      (item) => item.codiceTipoScommessa === 3 || item.codiceTipoScommessa === 2
    );
    if (tipoScommessaPiazzatoMinMax) {
      return tipoScommessaPiazzatoMinMax?.codiceTipoScommessa;
    } else {
      return;
    }
    // if ((isTris && isTot) || (!isTot && isTris)) {
    //   console.log('3');
    //   return 3;
    // } else {
    //   console.log('2');
    //   return 2;
    // }
  }
);

export const selectCurrentTime = createSelector([_self], (state) => state.currentTime);
export const selectCurrentTab = createSelector(
  (state: RootState) => selectCurrentTime(state),
  (state: RootState) => selectIppicaTodayTablist(state),
  (currentTime, tabList) => {
    if (currentTime && tabList) {
      const hour = format(new Date(currentTime), 'kk');
      return tabList.findIndex((tab) => {
        const [tabRangeFrom, tabRangeTo] = tab.split('-');
        return hour >= tabRangeFrom && hour < tabRangeTo;
      });
    } else {
      return;
    }
  }
);

export const selectActiveIndex = createSelector([_self], (state) => state.ippicaTodayIndexActive);

export const selectScommesseMap = createSelector(
  [selectIppicaRaceDetail],
  (ippicaDetailRace) => ippicaDetailRace.scommesseMap
);

export const selectFilteredCodiceTipoScommessa = (block: string) =>
  createSelector(
    [selectScommesseMap, selectCodiceTipoScommessaListFromSettings, selectCodiceProgramma, selectNumeroAvvenimento],
    (scommesseMap, codiceTipoScommessaListFromSettings, codiceProgramma, numeroAvvenimento) => {
      const codiceTipoScommessaList = codiceTipoScommessaListFromSettings[block];
      return codiceTipoScommessaList?.filter((codiceTipoScommessa) => {
        const keyScommessa = `${codiceProgramma}_${numeroAvvenimento}_${codiceTipoScommessa}`;
        return scommesseMap && keyScommessa in scommesseMap;
      });
    }
  );
