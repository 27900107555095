/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-81.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina81 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-81', className].join(' ');
  return (
    <svg
      id="disciplina_rugby-league"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M21.16 10.935c.775-.855.915-1.885.911-2.95-.003-.962-.031-1.923-.047-2.885 0-.155.003-.31.016-.464.053-.648.436-1.068.965-1.062.539.006.988.475.99 1.111.008 3.23.008 6.46 0 9.689 0 .645-.364 1.12-.76 1.588-.653.773-1.343 1.525-1.906 2.36-1.044 1.556-1.577 3.305-1.697 5.177-.008.117-.02.235-.035.387H15.12v-2.532c0-1.357.014-2.714 0-4.071-.008-.767.244-1.403.792-1.94 1.155-1.129 2.297-2.272 3.448-3.403.547-.537 1.145-.557 1.628-.072.465.466.435 1.035-.104 1.58-.843.857-1.698 1.703-2.549 2.553-.084.086-.217.158-.244.26-.053.2-.047.415-.068.624.21-.027.494.024.617-.094a83.43 83.43 0 0 0 2.953-2.947c.823-.873.665-1.871-.433-2.909ZM1.962 8.181c0-.464.02-.927.02-1.391 0-.756.016-1.513-.025-2.267-.029-.539-.458-.929-.927-.937-.468-.008-.937.425-1 .942a4.735 4.735 0 0 0-.015.566c0 2.869.024 5.738-.016 8.607-.014 1.017.303 1.807 1.023 2.527 1.76 1.757 2.892 3.865 3.301 6.333.072.433.104.874.152 1.293h4.363c0-.666-.006-1.284 0-1.902.017-1.547.049-3.094.054-4.638 0-.72-.16-1.386-.712-1.922-1.196-1.16-2.367-2.346-3.563-3.505a1.407 1.407 0 0 0-.656-.363c-.493-.099-.874.138-1.102.574-.246.469-.147.905.22 1.271.911.91 1.83 1.813 2.748 2.72.213.21.473.431.164.728-.31.297-.524.024-.733-.184l-2.48-2.478c-1.151-1.151-1.132-2.162.11-3.291-.722-.773-.924-1.695-.926-2.683Zm12.925-4.403v2.755h-.839v-.958h-1.563v.96h-.84v-.961H9.952v.959h-.84V3.776h.84v.959h1.693v-.957h.84v.957h1.563v-.957h.84Zm2.329 5.182a10.732 10.732 0 0 1-9.383.512A10.693 10.693 0 0 1 4.43 7.177a10.709 10.709 0 0 1-1.446-1.794l-.145-.227.145-.227A10.692 10.692 0 0 1 12 0a10.715 10.715 0 0 1 9.016 4.928l.144.226-.144.227a10.804 10.804 0 0 1-3.8 3.58Zm1.76-5.233c-.277-.277-.57-.538-.878-.781v4.42a9.833 9.833 0 0 0 2.063-2.212c-.35-.511-.747-.989-1.185-1.427ZM5.9 7.362v-4.42a9.836 9.836 0 0 0-2.063 2.212 10.052 10.052 0 0 0 2.063 2.208Zm11.36.594V2.349a9.892 9.892 0 0 0-10.52 0v5.61a9.79 9.79 0 0 0 5.26 1.514 9.834 9.834 0 0 0 5.26-1.517Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina81;
