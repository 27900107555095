/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-259.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco259 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-259', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="35.482"
      viewBox="0 0 38.439 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(4.435 16.263)">
        <path
          className="a"
          d="M473.914,409.392h-4.435A1.48,1.48,0,0,1,468,407.914v-4.435A1.48,1.48,0,0,1,469.478,402h4.435a1.481,1.481,0,0,1,1.478,1.478v4.435A1.48,1.48,0,0,1,473.914,409.392Zm-4.435-5.914v4.435h4.437v-4.435Z"
          transform="translate(-468 -402)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M468.739,417.478h4.435a.739.739,0,1,0,0-1.478h-4.435a.739.739,0,1,0,0,1.478ZM480.567,416h-4.435a.739.739,0,1,0,0,1.478h4.435a.739.739,0,1,0,0-1.478Zm7.392,0h-4.435a.739.739,0,1,0,0,1.478h4.435a.739.739,0,1,0,0-1.478Zm7.392,0h-4.435a.739.739,0,1,0,0,1.478h4.435a.739.739,0,1,0,0-1.478Zm-17.741,2.957h-8.871a.739.739,0,1,0,0,1.478h8.871a.739.739,0,1,0,0-1.478Z"
        transform="translate(-463.565 -389.388)"
        fill="currentColor"
      />
      <g transform="translate(0 10.349)">
        <path
          className="a"
          d="M495.265,419.133H465.7a3.7,3.7,0,0,1-3.7-3.7V397.7a3.7,3.7,0,0,1,3.7-3.7H480.48a.739.739,0,1,1,0,1.478H465.7a2.22,2.22,0,0,0-2.218,2.218v17.741a2.22,2.22,0,0,0,2.218,2.218h29.569a2.22,2.22,0,0,0,2.218-2.218v-7.392a.739.739,0,1,1,1.478,0v7.392A3.7,3.7,0,0,1,495.265,419.133Z"
          transform="translate(-462 -394)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M502.957,399.478a1.478,1.478,0,1,0-2.218,1.273v.945a.739.739,0,0,0,1.478,0v-.945A1.473,1.473,0,0,0,502.957,399.478Z"
        transform="translate(-471.91 -384.694)"
        fill="currentColor"
      />
      <g transform="translate(20.698 8.871)">
        <path
          className="a"
          d="M504.784,405.306H492.957A2.96,2.96,0,0,1,490,402.349v-7.392A2.96,2.96,0,0,1,492.957,392h11.828a2.96,2.96,0,0,1,2.957,2.957v7.392A2.96,2.96,0,0,1,504.784,405.306Zm-11.828-11.827a1.48,1.48,0,0,0-1.478,1.478v7.392a1.48,1.48,0,0,0,1.478,1.478h11.828a1.48,1.48,0,0,0,1.479-1.478v-7.392a1.481,1.481,0,0,0-1.479-1.478Z"
          transform="translate(-490 -392)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(23.655 0)">
        <path
          className="a"
          d="M505.088,390.349a.739.739,0,0,1-.739-.739v-3.7a4.435,4.435,0,0,0-8.871,0v3.7a.739.739,0,1,1-1.478,0v-3.7a5.914,5.914,0,1,1,11.828,0v3.7A.739.739,0,0,1,505.088,390.349Z"
          transform="translate(-494 -380)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco259;
