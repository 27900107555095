/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-40.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco40 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-40', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="38.439"
      viewBox="0 0 41.396 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(18.48 34.004)">
        <path
          className="a"
          d="M68.7,341.478H65.739a.739.739,0,1,1,0-1.478H68.7a.739.739,0,0,1,0,1.478Z"
          transform="translate(-65 -340)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(19.959 2.959)">
        <path
          className="a"
          d="M67.739,299.479a.735.735,0,0,1-.525-.214.863.863,0,0,1-.155-.244A.736.736,0,0,1,67,298.74a.777.777,0,0,1,.214-.525.752.752,0,0,1,.806-.155.763.763,0,0,1,.244.155.777.777,0,0,1,.214.525.619.619,0,0,1-.059.281.863.863,0,0,1-.155.244A.735.735,0,0,1,67.739,299.479Z"
          transform="translate(-67 -298.003)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(10.349 0)">
        <path
          className="a"
          d="M71.741,332.439H56.957A2.96,2.96,0,0,1,54,329.482V296.957A2.96,2.96,0,0,1,56.957,294H71.741a2.96,2.96,0,0,1,2.957,2.957v32.526A2.96,2.96,0,0,1,71.741,332.439ZM56.957,295.478a1.48,1.48,0,0,0-1.478,1.478v32.526a1.48,1.48,0,0,0,1.478,1.478H71.741a1.48,1.48,0,0,0,1.478-1.478V296.957a1.48,1.48,0,0,0-1.478-1.478Z"
          transform="translate(-54 -294)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M77.715,307.9a.739.739,0,0,0-1.045,1.045,11.086,11.086,0,0,1,0,15.68.739.739,0,1,0,1.045,1.046,12.565,12.565,0,0,0,0-17.772Zm-3.136,3.136a.739.739,0,0,0-1.045,1.045,6.661,6.661,0,0,1,0,9.409.739.739,0,1,0,1.045,1.045A8.14,8.14,0,0,0,74.579,311.039Zm-33.1,5.75a11.012,11.012,0,0,1,3.248-7.84.739.739,0,1,0-1.045-1.045,12.565,12.565,0,0,0,0,17.772.739.739,0,0,0,1.045-1.046A11.012,11.012,0,0,1,41.478,316.789Zm6.384-5.75a.739.739,0,0,0-1.045,0,8.14,8.14,0,0,0,0,11.5.739.739,0,0,0,1.045-1.045,6.661,6.661,0,0,1,0-9.409A.74.74,0,0,0,47.862,311.039Z"
        transform="translate(-40 -297.569)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco40;
