/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-001.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina001 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-001', className].join(' ');
  return (
    <svg
      id="disciplina_streaming"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M8.544 4L7.275 5.25333L9.606 7.55556H4.8C3.801 7.55556 3 8.34667 3 9.33333V18.2222C3 19.2089 3.801 20 4.8 20H19.2C20.199 20 21 19.2089 21 18.2222V9.33333C21 8.34667 20.199 7.55556 19.2 7.55556H14.394L16.725 5.25333L15.456 4L12 7.41333L8.544 4ZM4.8 9.33333H16.5V18.2222H4.8V9.33333ZM18.75 9.33333C18.9887 9.33333 19.2176 9.42698 19.3864 9.59368C19.5552 9.76038 19.65 9.98647 19.65 10.2222C19.65 10.458 19.5552 10.6841 19.3864 10.8508C19.2176 11.0175 18.9887 11.1111 18.75 11.1111C18.5113 11.1111 18.2824 11.0175 18.1136 10.8508C17.9448 10.6841 17.85 10.458 17.85 10.2222C17.85 9.98647 17.9448 9.76038 18.1136 9.59368C18.2824 9.42698 18.5113 9.33333 18.75 9.33333ZM18.75 12C18.9887 12 19.2176 12.0937 19.3864 12.2604C19.5552 12.427 19.65 12.6531 19.65 12.8889C19.65 13.1246 19.5552 13.3507 19.3864 13.5174C19.2176 13.6841 18.9887 13.7778 18.75 13.7778C18.5113 13.7778 18.2824 13.6841 18.1136 13.5174C17.9448 13.3507 17.85 13.1246 17.85 12.8889C17.85 12.6531 17.9448 12.427 18.1136 12.2604C18.2824 12.0937 18.5113 12 18.75 12Z"
      />
    </svg>
  );
};
export default Disciplina001;
