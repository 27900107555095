/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-50.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina50 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-50', className].join(' ');
  return (
    <svg
      id="disciplina_judo"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M18.457 17.568a1.268 1.268 0 0 1-1.945 1.626l-1.374-1.636c-.019-.023-.037-.051-.056-.075l-1.383-1.655 1.027-1.908c.325.263.695.465 1.092.595.624.197 1.34.193 1.34.193l-.496.806 1.796 2.054ZM21.055 5.04A2.525 2.525 0 1 0 21.5.01a2.525 2.525 0 0 0-.445 5.03ZM5.34 8.83a2.527 2.527 0 1 0 0-5.054 2.527 2.527 0 0 0 0 5.054Zm.918 1.66a.924.924 0 0 0-.21-.174c-.404-.52-1.074-.863-1.829-.863-.999 0-1.842.595-2.128 1.416-.03.087-.054.176-.07.267-.17.647-.606 2.32-.845 3.507-.239 1.172-.178 2.32-.14 2.762-.001.061.005.122.018.182v.005c.02.12.058.235.113.342l2.672 5.364a1.279 1.279 0 0 0 1.707.572 1.279 1.279 0 0 0 .572-1.707L4.57 19.058l2.846 3.02a1.274 1.274 0 0 0 1.851-1.749l-3.82-4.055.735-3.198 2.321 2.565a.9.9 0 1 0 1.336-1.205l-3.582-3.948ZM21.218 6.7c-.379-.628-.95-1.158-1.743-1.228-.31-.028-.59.051-.886.108l-2.959.497-2.236-.929a.898.898 0 0 0-1.246.833.897.897 0 0 0 .557.832l2.813 1.172 1.388-.291-.994 1.81-.657 1.26-5.087-2.235a1.257 1.257 0 0 0-1.65.642l-.042.094a1.257 1.257 0 0 0 .642 1.65l6.967 3.057c.157.068.326.103.497.103.008 0 .016 0 .024.005a1.957 1.957 0 0 0 1.748-.788c.12-.159.211-.336.272-.525l1.97-3.75c.032-.024.06-.052.093-.076l.68.886-1.66 1.646a.9.9 0 1 0 1.27 1.28l1.764-1.688 1.097-1.06L21.219 6.7Z"
      />
    </svg>
  );
};
export default Disciplina50;
