/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-fr.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneFr = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-fr', className].join(' ');
  return (
    <svg
      id="nazione-fr"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <g fillRule="evenodd" clipPath="url(#b)" clipRule="evenodd">
          <path fill="#fff" d="M0 0h16v16H0V0Z" />
          <path fill="#002654" d="M0 0h5.334v16H0V0Z" />
          <path fill="#CE1126" d="M10.666 0H16v16h-5.334V0Z" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="16" height="16" fill="#fff" rx="8" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NazioneFr;
