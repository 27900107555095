import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { isClientSide } from 'utility/functions';

type WindowSize = {
  width: number | undefined;
  height: number | undefined;
};

export const useIsomorphicLayoutEffect = isClientSide() ? useLayoutEffect : useEffect;

const useWindowSize = (): WindowSize => {
  // Prevents SSR issues
  const [windowDimensions, setWindowDimensions] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  const handleResize = useCallback(() => {
    if (isClientSide()) {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (isClientSide()) {
      window.addEventListener('resize', handleResize);
    }

    handleResize();

    return () => {
      if (isClientSide()) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [handleResize]);

  return windowDimensions;
};

export default useWindowSize;
