import { CookiesOptions } from 'next-auth';
import { stringDictionary } from './types';

export const PAGE_LOADING = 'data-loading';
export const NAVIGATION_STARTED = 'data-st-loading';
export const NAVIGATION_ENDED = 'data-end-loading';

export const breakpoint = {
  xs: 360,
  sm: 768,
  md: 1024,
  lg: 1366,
  xl: 1920,
};

export const cardWidth = {
  small: 303,
  big: 320,
};
/* eslint-disable no-unused-vars */
export enum SlotList {
  News = 'ListNovita',
  Popular = 'ListPopolari',
  Promotions = 'ListInPromozione',
  Jackpot = 'ListJackpot',
  ChoseFromSnai = '',
  InstantGame = '',
  All = '',
}

const GTMKEYS: Record<string, string> = {
  it: 'GTM-5TXRZHR',
  de: 'GTM-MXGM56F7',
  at: 'GTM-MN5BPVNX',
};

export const GTAGKEYS: Record<string, string> = {
  de_reg: 'AW-372726570/WOhRCIqp2pQCEKq23bEB',
  at_reg: 'AW-372726141/gddBCIXB8-UCEP2y3bEB',
  de_ftd: 'AW-372726570/N_K6CM79h5UCEKq23bEB',
  at_ftd: 'AW-372726141/8nNZCNnW8-UCEP2y3bEB',
  de_dep: 'AW-372726570/TZWUCLfbgZUCEKq23bEB',
  at_dep: 'AW-372726141/hM3BCIjf8-UCEP2y3bEB',
};

export const GTMCODE = GTMKEYS[`${process.env.NEXT_PUBLIC_COUNTRY}`];

export const character = {
  poundSign: String.fromCharCode(35),
  minus: String.fromCharCode(45),
  colon: String.fromCharCode(58),
  whiteSpace: String.fromCharCode(160),
  apostrophe: String.fromCharCode(39),
};
// middleware list
//TODO cambiare nome dictionary
export const dictionaryOfSlot: stringDictionary = {
  'slot-novita': 'ListNovita',
  'slot-popolari': 'ListPopolari',
  'slot-in-promozione': 'ListInPromozione',
  jackpot: 'ListJackpot',
  'scelti-da-snai': '',
  'instant-game': '',
  'slot-tutte': '',
};
//TODO cambiare nome dictionary
export const dictionaryCasinoLive = {
  roulette: 'listRoulette',
  'altre-lingue': 'listAltreLingue',
  blackjack: 'listBlackjack',
  'in-italiano': 'listInItaliano',
  poker: 'listPoker',
};
export const dictionarySlotList = {
  mostPlayedList: 'listPiuGiocate',
  favoritesList: 'listPreferiti',
};
export const dictionaryPoker = {
  leavingList: 'listInPartenza',
};

export const pageSize = 48;

// in seconds
export const ISR_REVALIDATE = 6 * 60; // 6 minutes

export const WINDOW_CLOSED = 'window_closed';

export const FIRTS_LOGIN_COOKIE = 'snai_first_login';

export const AKAMAI_SESSION_COOKIE = 'bm_sv';

export const urlMainTournaments = '/poker/tornei-principali/';
export const urlLeavingTournaments = '/poker/tornei-in-partenza/';

// FOR SLOT
export const MOST_PLAYED_LIST = 'listPiuGiocate';
export const FAVORITE_LIST = 'listPreferiti';

// FOR POKER
export const LEAVING_LIST = 'listInPartenza';

export const SEARCH_DELAY = 2000;

export const isLocalHost = ['localhost', '0.0.0.0', '127.0.0.1'].some((address) =>
  `${process.env.NEXT_PUBLIC_BASE_URL}`.toLowerCase().includes(`//${address}`)
);

export const isSnaiSite = process.env.NEXT_PUBLIC_COUNTRY === 'it' || process.env.NEXT_PUBLIC_MODULE_SITE === 'snai';
export const isHappybetSite = process.env.NEXT_PUBLIC_MODULE_SITE === 'happybet';

export const isGermanSite = process.env.NEXT_PUBLIC_COUNTRY === 'de';
export const isAustrianSite = process.env.NEXT_PUBLIC_COUNTRY === 'at';

export const langs = isSnaiSite ? ['it'] : isGermanSite ? ['de', 'en', 'tr'] : ['de_AT', 'en_AT'];
export const defaultLang = isSnaiSite ? 'it' : isGermanSite ? 'de' : 'de_AT';

export const isRealTimeCashoutEnabled = process.env.NEXT_PUBLIC_REALTIME_CASHOUT_ENABLED === 'true';

export const TEXT_HIDDEN = '*******';

export const SISAL_SUPPLIER = 27;
export const LOTTOMATICA_SUPPLIER = 94;
export const GRATTA_E_VINCI_SUPPLIER = 180;

export const FROM_REGISTRATION = 'from_registration';

export const PROVENIENCE_QUERY_PARAM = 'provenienza';

export const SSR_ROOT_CACHE_PATH = ['.next', 'cache'];

/* auth */
const [site, domain] = `${process.env.NEXT_PUBLIC_API_BASE_URL}`.split(/\.|\//gim).slice(3);
// token used to invoke MW end-points
export const TOKEN_BASE_NAME = `__${domain}-${site}:token`;
// contains json data expire-date (+ refreshToken for logged-in users)
export const TOKEN_BASE_REFRESH = `__${domain}-${site}:refresh`;
// flag indicating usr is logged
export const IS_LOGGED = `__${domain}-${site}-auth:logged`;
// falg indicating force logout
export const FORCE_LOGOUT = `__${domain}-${site}-auth:force_logout`;
// next-auth self-managed token
export const TOKEN_AUTH_NAME = `__${domain}-${site}-auth:token`;
// wrapper inactive logout ref
export const IDLE_KEY_NAME = `__${domain}-${site}-idle:mgmt`;
// refresh token ref
export const REFRESH_KEY_NAME = `__${domain}-${site}-refresh:gmgt`;
// check if client reach server
export const CONNECTED_KEY_NAME = `__${domain}-${site}-ping:gmgt`;

export const STORAGE_PREFIX_KEY = '~';

export const SESSION_UID = `${STORAGE_PREFIX_KEY}uID`;

export const cookies: Pick<CookiesOptions, 'sessionToken'> = {
  sessionToken: {
    name: TOKEN_AUTH_NAME,
    options: {
      path: '/',
      maxAge: 120 * 60,
      secure: isLocalHost ? false : true,
      expires: null,
      httpOnly: true,
    },
  },
};

export const SELECTED_CARD_COOKIE_NAME = `__${domain}-${site}:selected-card`;
