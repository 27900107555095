/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\picche.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Picche = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-picche', className].join(' ');
  return (
    <svg
      id="picche"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M11.9305 2.41164c.0203-.01004.0421-.01416.0632-.01302.0212-.00114.0429.00298.0633.01302.0635.03132.0649.09872.0935.15479 1.4506 2.84652 3.9299 4.61988 6.1494 6.20717.2171.15521.4318.30877.6118.43874.2152.15271.4169.32318.603.50928.846.84598 1.3693 2.01468 1.3693 3.30558 0 1.291-.5233 2.4597-1.3693 3.3057-.846.8459-2.0147 1.3692-3.3056 1.3692-1.2909 0-2.4596-.5233-3.3056-1.3692-.0739-.0739-.1453-.1502-.2141-.2288l2.4445 5.0086c.0759.142-.0373.3142-.1965.3142H9.10133c-.15753 0-.27324-.1707-.19653-.3142l2.5156-5.1544c-.1054.1308-.2177.2559-.3364.3746-.846.8459-2.0147 1.3692-3.30562 1.3692-1.29091 0-2.45963-.5233-3.30562-1.3692-.84599-.846-1.36924-2.0147-1.36924-3.3057 0-1.2909.52325-2.4596 1.36924-3.30558.18608-.18607.38776-.35653.60294-.50923.18003-.12998.39478-.28356.61184-.43879C7.9077 7.18587 10.3877 5.41196 11.8382 2.56406c.0282-.05552.0292-.12129.0923-.15242Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Picche;
