import { hasValue } from 'utility/functions';

export function getIndicies<T1, T2>(list: Array<T1>, fieldName: string, value?: T2): Array<number> {
  const result: Array<number> = [];
  for (const x in list) {
    const ckFilter = !hasValue(value);
    if (ckFilter) {
      result.push(Number(x));
    } else {
      const ckValue = (Reflect.get(list[x] ?? {}, fieldName) as T2) === value;
      if (ckValue) {
        result.push(Number(x));
      }
    }
  }
  return result;
}

export const getPastQuarter = (): string => {
  const dtNow = new Date();
  const yy = dtNow.getUTCFullYear();
  const mm = 1 + dtNow.getUTCMonth();
  const dd = dtNow.getUTCDate();
  const hh = dtNow.getUTCHours();
  const wMi = dtNow.getUTCMinutes();
  let mi = '45';
  if (wMi <= 14) {
    mi = '00';
  } else if (wMi <= 29) {
    mi = '15';
  } else if (wMi <= 44) {
    mi = '30';
  }

  return [yy, mm, dd, hh, mi].join('#');
};

/*
export const createStatsPath = (dettaglioCorsa: IppicaDetailRaceResponseApi, cavallo: InfoCavallo) => {
  const { tipoIppica, tipoGara, siglaRiunione, dataOraAvvenimento, numeroCorsa } = dettaglioCorsa;
  const { numeroCavallo } = cavallo;

  const date = new Date(dataOraAvvenimento);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const stringDate = year + '-' + month + '-' + day;

  switch (tipoIppica) {
    case 1:
    case 2: {
      return `${process.env.NEXT_PUBLIC_IPPICA_SINTESI}/${tipoGara.charAt(0).toUpperCase()}/${siglaRiunione.substring(
        0,
        2
      )}/${stringDate}/${numeroCorsa}/${numeroCavallo}`;
    }
    case 3: {
      return `${process.env.NEXT_PUBLIC_IPPICA_SINTESI}/${tipoGara
        .charAt(0)
        .toUpperCase()}/${siglaRiunione}/${stringDate}/${numeroCorsa}/${numeroCavallo}`;
    }
    default: {
      return '';
    }
  }
};
*/

export function isTipoScommessaInDisordine(tipoScommessa: number) {
  return tipoScommessa === 112 || tipoScommessa === 114 || tipoScommessa === 115 || tipoScommessa === 117;
}

/**
 * Converti un numero di cavalli in una stringa esadecimale
 * @param numeroCavalliMatrix - Un array di numeri, ognuno rappresentante un numero di cavalli
 * @returns Una stringa esadecimale di 8 caratteri
 * @example
 * convertiNumeroCavalliMatrixInStringaEsadecimale([1, 2, 3]); // '00010203'
 *
 */
export function convertiNumeroCavalliMatrixInStringaEsadecimale(
  numeroCavalliMatrix: number[][],
  tipoScommessa: number
): string {
  // Passo 1: Convertire ogni numero in esadecimale e concatenarli

  const isInDisordine = isTipoScommessaInDisordine(tipoScommessa);
  const numeroCavalliMatrixFlat = numeroCavalliMatrix.flat();
  if (isInDisordine) {
    numeroCavalliMatrixFlat.sort((a, b) => b - a);
  }
  let parteEsadecimale = numeroCavalliMatrixFlat.map((n) => n.toString(16).padStart(2, '0')).join('');
  // Passo 2: Assicurarsi che la parte esadecimale sia lunga 6 caratteri, aggiungendo zeri all'inizio se necessario
  parteEsadecimale = parteEsadecimale.padStart(8, parteEsadecimale.length === 4 ? '0001' : '01');

  // Passo 3: Aggiungere zeri all'inizio per raggiungere una lunghezza totale di 8 caratteri
  const risultato = parteEsadecimale;

  return risultato;
}
