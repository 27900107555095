/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-119.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina119 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-119', className].join(' ');
  return (
    <svg
      id="disciplina_ciclismo-su-pista"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M22.955 17.355c-.448-3.112-4.044-4.59-6.575-2.712-1.39 1.034-1.983 2.912-1.423 4.534.594 1.711 2.162 2.845 3.932 2.823 1.938-.023 3.517-1.267 3.988-3.134.045-.178.078-.367.123-.556v-.81c-.022-.056-.045-.1-.045-.145Zm-4.088 3.278c-1.557-.011-2.745-1.2-2.745-2.734 0-1.544 1.199-2.722 2.756-2.711 1.557 0 2.744 1.189 2.744 2.733-.011 1.534-1.21 2.723-2.755 2.712ZM5.1 13.82c-2.285.011-4.111 1.822-4.1 4.09C1 20.176 2.848 22 5.122 22c2.285-.011 4.122-1.834 4.122-4.09A4.093 4.093 0 0 0 5.1 13.82Zm.022 5.445c-.717.011-1.378-.633-1.378-1.356 0-.722.639-1.355 1.367-1.366.728-.012 1.378.622 1.378 1.355.011.711-.627 1.356-1.367 1.367Zm8.67-5.178c-.291 1.478-.593 2.944-.896 4.422-.09.445-.46.734-.918.745-.392.011-.762-.278-.863-.7a1.042 1.042 0 0 1 0-.456c.235-1.222.482-2.433.74-3.656.033-.166-.012-.233-.146-.31-1.4-.768-2.8-1.534-4.2-2.312-1.222-.667-1.334-2.223-.225-3.034 1.087-.8 2.196-1.6 3.305-2.378.459-.333.952-.645 1.534-.733.314-.045.639-.067.941-.012.224.034.448.19.616.345a206.92 206.92 0 0 1 3.148 3.111c.1.1.201.145.358.145h3.025c.582 0 .986.422.952 1-.023.456-.426.811-.963.822-1.199.011-2.409.011-3.607 0-.292 0-.516-.155-.74-.344a171.736 171.736 0 0 0-2.14-1.878c-.021-.011-.033-.022-.077-.034-.964.623-1.927 1.256-2.913 1.9.437.379.851.734 1.266 1.09.481.41.952.822 1.434 1.222.325.278.459.622.37 1.045Zm-.907-9.568c-.582 0-1.165.078-1.748.133a8.447 8.447 0 0 1 1.748-1.578c.784-.533 1.635-.9 2.588-1.033.806-.111 1.545-.022 2.195.533.851.745.997 2.19.28 3.078a2.294 2.294 0 0 1-1.523.867c-.415.056-.75-.044-1.009-.378-.156-.2-.302-.4-.459-.589-.515-.689-1.22-1.033-2.072-1.033Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina119;
