/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\meteo-pioggia.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MeteoPioggia = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-meteo-pioggia', className].join(' ');
  return (
    <svg
      id="meteo-pioggia"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.79232 14.6404C8.96454 14.7015 9.13399 14.6876 9.30065 14.5987C9.46732 14.5098 9.58121 14.3793 9.64232 14.207C9.70343 14.0348 9.68954 13.8654 9.60065 13.6987L8.60065 11.6987C8.51176 11.532 8.38121 11.4181 8.20898 11.357C8.03676 11.2959 7.86732 11.3098 7.70065 11.3987C7.53398 11.4876 7.4201 11.6181 7.35898 11.7904C7.29787 11.9626 7.31176 12.132 7.40065 12.2987L8.40065 14.2987C8.48954 14.4654 8.6201 14.5793 8.79232 14.6404ZM12.7923 14.6404C12.9645 14.7015 13.134 14.6876 13.3007 14.5987C13.4673 14.5098 13.5812 14.3793 13.6423 14.207C13.7034 14.0348 13.6895 13.8654 13.6007 13.6987L12.6007 11.6987C12.5118 11.532 12.3812 11.4181 12.209 11.357C12.0368 11.2959 11.8673 11.3098 11.7007 11.3987C11.534 11.4876 11.4201 11.6181 11.359 11.7904C11.2979 11.9626 11.3118 12.132 11.4007 12.2987L12.4007 14.2987C12.4895 14.4654 12.6201 14.5793 12.7923 14.6404ZM4.79232 14.6404C4.96454 14.7015 5.13398 14.6876 5.30065 14.5987C5.46732 14.5098 5.58121 14.3793 5.64232 14.207C5.70343 14.0348 5.68954 13.8654 5.60065 13.6987L4.60065 11.6987C4.51176 11.532 4.38121 11.4181 4.20898 11.357C4.03676 11.2959 3.86732 11.3098 3.70065 11.3987C3.53398 11.4876 3.4201 11.6181 3.35898 11.7904C3.29787 11.9626 3.31176 12.132 3.40065 12.2987L4.40065 14.2987C4.48954 14.4654 4.6201 14.5793 4.79232 14.6404Z"
        fill="#4D98C2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00065 10.6654C3.98954 10.6654 3.12565 10.307 2.40898 9.59037C1.69232 8.8737 1.33398 8.00981 1.33398 6.9987C1.33398 6.07648 1.63954 5.27092 2.25065 4.58203C2.86176 3.89314 3.61732 3.48759 4.51732 3.36536C4.87287 2.73203 5.35898 2.23481 5.97565 1.8737C6.59232 1.51259 7.26732 1.33203 8.00065 1.33203C9.00065 1.33203 9.8701 1.65148 10.609 2.29036C11.3479 2.92925 11.7951 3.72648 11.9507 4.68203C12.7173 4.7487 13.3618 5.06536 13.884 5.63203C14.4062 6.1987 14.6673 6.87648 14.6673 7.66536C14.6673 8.4987 14.3757 9.20703 13.7923 9.79037C13.209 10.3737 12.5007 10.6654 11.6673 10.6654H5.00065ZM11.6673 9.33203H5.00065C4.35621 9.33203 3.80621 9.10425 3.35065 8.6487C2.8951 8.19314 2.66732 7.64314 2.66732 6.9987C2.66732 6.36537 2.89232 5.8237 3.34232 5.3737C3.79232 4.9237 4.33398 4.68759 4.96732 4.66536H5.38398L5.55065 4.26536C5.77287 3.77648 6.10343 3.38759 6.54232 3.0987C6.98121 2.80981 7.46732 2.66536 8.00065 2.66536C8.73398 2.66536 9.36176 2.92648 9.88398 3.4487C10.4062 3.97092 10.6673 4.5987 10.6673 5.33203V5.9987H11.6673C12.134 5.9987 12.5284 6.15981 12.8507 6.48203C13.1729 6.80425 13.334 7.1987 13.334 7.66536C13.334 8.13203 13.1729 8.52648 12.8507 8.8487C12.5284 9.17092 12.134 9.33203 11.6673 9.33203Z"
        fill="#757575"
      />
    </svg>
  );
};
export default MeteoPioggia;
