/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\legenda-inattesa-light.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const LegendaInattesaLight = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-legenda-inattesa-light', className].join(' ');
  return (
    <svg
      id="legenda-inattesa-light"
      width="100%"
      height="100%"
      viewBox="0 0 24 12"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path fill="#0F1315" d="M0 0h24v12H0z" />
      <path
        opacity=".4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1v1h1v1h1V2h1V1H2V0H1v1H0Zm8-1v1H7v1h1v1h1V2h1V1H9V0H8Zm7 0v1h-1v1h1v1h1V2h1V1h-1V0h-1Zm7 0v1h-1v1h1v1h1V2h1V1h-1V0h-1Zm2 6h-1V5h-1v1h-1v1h1v1h1V7h1V6Zm0 5h-1v-1h-1v1h-1v1h3v-1Zm-7 1v-1h-1v-1h-1v1h-1v1h3Zm-7 0v-1H9v-1H8v1H7v1h3Zm-7 0v-1H2v-1H1v1H0v1h3ZM0 7h1v1h1V7h1V6H2V5H1v1H0v1Zm8-2v1H7v1h1v1h1V7h1V6H9V5H8Zm7 0v1h-1v1h1v1h1V7h1V6h-1V5h-1Z"
        fill="#AEAEAE"
      />
    </svg>
  );
};
export default LegendaInattesaLight;
