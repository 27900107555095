/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\pattinaggio-velocita.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const PattinaggioVelocita = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-pattinaggio-velocita', className].join(' ');
  return (
    <svg
      id="pattinaggio_velocita"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M8.793 5.456c-.174.38-.37.812-.567 1.287H4.654a.641.641 0 0 1-.642-.644.644.644 0 0 1 .642-.643h4.139Zm-8.15 2.26A.641.641 0 0 0 0 8.358a.644.644 0 0 0 .642.643h6.693c.16-.446.324-.873.488-1.287H.643Zm2.361 2.902a.641.641 0 0 0-.642.644.644.644 0 0 0 .642.643h3.422c.112-.432.234-.864.366-1.287H3.004Zm.628 2.899a.641.641 0 0 0-.642.643.644.644 0 0 0 .642.644h2.26c.042-.423.103-.855.182-1.287H3.632Zm18.388 5.566c.117.296.136.648.032 1.029C21.776 21.15 20.707 22 19.672 22H3.295c-.295 0-.473-.24-.394-.54.08-.3.385-.54.68-.54h3.871l.45-1.682H6.454l.047-3.006v-.005c.019-4.871 3.515-11.532 3.66-11.814a.776.776 0 0 1 .544-.395l6.98-1.01a.43.43 0 0 1 .384.113.413.413 0 0 1 .098.38 21.907 21.907 0 0 0-.3 2.941l-2.189.329c-.305.047-.581.32-.614.615-.028.268.15.456.408.456.028 0 .056 0 .084-.005l2.302-.343c.004.306.023.616.046.926l-2.512.935c-.304.112-.52.427-.478.704.028.207.197.334.408.334a.61.61 0 0 0 .216-.043l2.498-.925c.051.31.117.62.197.916l-2.217 1.456c-.282.183-.413.522-.291.756.07.137.206.202.366.202a.67.67 0 0 0 .365-.117l2.09-1.372c.141.337.319.657.53.954l-1.767 1.794c-.239.24-.272.578-.08.752.08.07.181.107.287.103a.722.722 0 0 0 .496-.225l1.711-1.738c.121.099.25.188.384.268.525.31 1.027.563 1.472.789 1.645.836 2.831 1.442 2.287 3.335-.243.836-.918 1.475-1.846 1.785ZM8.526 20.924h8.324l.426-1.592c-2.643-.16-4.86-.841-5.835-1.438-.117-.07-.192-.14-.375-.201l-.412 1.55H8.976c0-.005-.45 1.681-.45 1.681Zm12.453-.812c.113-.414-.112-.752-.497-.804-.684.038-1.415.066-2.038.066h-.099l-.412 1.55h2.034c.436 0 .895-.366 1.012-.812Z"
      />
    </svg>
  );
};
export default PattinaggioVelocita;
