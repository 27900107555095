/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-57.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco57 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-57', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="35.482"
      viewBox="0 0 35.482 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0)">
        <path
          className="a"
          d="M147.826,304a11.827,11.827,0,1,0,5.886,22.078.739.739,0,1,0-.737-1.282,10.343,10.343,0,1,1,5.2-8.969c0,2.446-1.658,4.435-3.7,4.435a2.466,2.466,0,0,1-2.442-2.815l.956-6.69a.739.739,0,1,0-1.464-.21l-.146,1.019a3.785,3.785,0,0,0-1.575-1.324c-2.4-1.058-5.588.567-7.1,3.624a6.7,6.7,0,0,0-.476,5.186,3.968,3.968,0,0,0,2.131,2.36,3.884,3.884,0,0,0,1.574.324,6.242,6.242,0,0,0,4.781-2.752,3.933,3.933,0,0,0,3.759,2.757c2.853,0,5.175-2.653,5.175-5.914A11.841,11.841,0,0,0,147.826,304Zm-2.864,16.059a2.548,2.548,0,0,1-1.337-1.512,5.3,5.3,0,0,1,.412-4.027,5.208,5.208,0,0,1,4.191-3.125,2.441,2.441,0,0,1,.987.2,2.551,2.551,0,0,1,1.337,1.512,5.3,5.3,0,0,1-.412,4.028h0C149,319.452,146.627,320.791,144.962,320.059Z"
          transform="translate(-130.086 -298.086)"
          fill="currentColor"
        />
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M156.09,331.482h-20.7A7.4,7.4,0,0,1,128,324.09v-20.7A7.4,7.4,0,0,1,135.392,296h20.7a7.4,7.4,0,0,1,7.392,7.392v20.7A7.4,7.4,0,0,1,156.09,331.482Zm-20.7-34a5.921,5.921,0,0,0-5.914,5.914v20.7A5.921,5.921,0,0,0,135.392,330h20.7A5.921,5.921,0,0,0,162,324.09v-20.7a5.921,5.921,0,0,0-5.914-5.914Z"
            transform="translate(-128 -296)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco57;
