/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-102.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco102 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-102', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="35.482"
      viewBox="0 0 38.439 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0)">
        <path
          className="a"
          d="M160.743,216h-5.914v1.478h5.914a2.22,2.22,0,0,1,2.218,2.218V244.83a2.22,2.22,0,0,1-2.218,2.218H129.7a2.22,2.22,0,0,1-2.218-2.218V224.871h33.265a.739.739,0,1,0,0-1.478H127.478v-3.7a2.22,2.22,0,0,1,2.218-2.218h5.914V216H129.7a3.7,3.7,0,0,0-3.7,3.7V244.83a3.7,3.7,0,0,0,3.7,3.7h31.047a3.7,3.7,0,0,0,3.7-3.7V219.7A3.7,3.7,0,0,0,160.743,216Zm-8.871,0H138.567v1.478h13.306Z"
          transform="translate(-126 -213.043)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M154.76,232.895a.741.741,0,0,0-1.038.13l-10.349,13.306a.74.74,0,0,0,1.168.908l10.349-13.306A.741.741,0,0,0,154.76,232.895Zm-6.368,2.8a3.7,3.7,0,1,0-3.7,3.7A3.7,3.7,0,0,0,148.392,235.7Zm-5.914,0a2.218,2.218,0,1,1,2.218,2.218A2.22,2.22,0,0,1,142.478,235.7Zm11.088,5.175a3.7,3.7,0,1,0,3.7,3.7A3.7,3.7,0,0,0,153.567,240.871Zm0,5.914a2.218,2.218,0,1,1,2.218-2.218A2.22,2.22,0,0,1,153.567,246.784Z"
          transform="translate(-129.912 -217.216)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M140.218,212A2.22,2.22,0,0,0,138,214.218v4.435a2.218,2.218,0,1,0,4.435,0v-4.435A2.22,2.22,0,0,0,140.218,212Zm.739,6.653a.739.739,0,1,1-1.478,0v-4.435a.739.739,0,1,1,1.478,0ZM156.48,212a2.22,2.22,0,0,0-2.218,2.218v4.435a2.218,2.218,0,0,0,4.435,0v-4.435A2.22,2.22,0,0,0,156.48,212Zm.739,6.653a.739.739,0,1,1-1.478,0v-4.435a.739.739,0,1,1,1.478,0Z"
          transform="translate(-129.129 -212)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco102;
