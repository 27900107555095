/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-21.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco21 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-21', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.569"
      height="41.396"
      viewBox="0 0 29.569 41.396"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 5.914)">
        <path
          className="a"
          d="M325.873,419.482H303.7a3.7,3.7,0,0,1-3.7-3.7V387.7a3.7,3.7,0,0,1,3.7-3.7.739.739,0,0,1,0,1.478,2.22,2.22,0,0,0-2.218,2.218v28.09A2.22,2.22,0,0,0,303.7,418h22.177a2.22,2.22,0,0,0,2.218-2.218V387.7a2.22,2.22,0,0,0-2.218-2.218.739.739,0,0,1,0-1.478,3.7,3.7,0,0,1,3.7,3.7v28.09A3.7,3.7,0,0,1,325.873,419.482Z"
          transform="translate(-300 -384)"
          fill="currentColor"
        />
      </g>
      <ellipse className="a" cx="0.5" cy="1.5" rx="0.5" ry="1.5" transform="translate(14.803 3.195)" />
      <g transform="translate(5.914 0)">
        <path
          className="a"
          d="M323.524,387.828H310.218A2.22,2.22,0,0,1,308,385.61v-3.183a2.211,2.211,0,0,1,1.853-2.187l2.13-.355a.735.735,0,0,0,.588-.544,4.437,4.437,0,0,1,8.6,0,.736.736,0,0,0,.589.545l2.13.355a2.211,2.211,0,0,1,1.853,2.187v3.183A2.22,2.22,0,0,1,323.524,387.828Zm-6.653-10.349A2.954,2.954,0,0,0,314,379.7a2.211,2.211,0,0,1-1.779,1.638l-2.13.355a.737.737,0,0,0-.617.729v3.183a.74.74,0,0,0,.739.739h13.306a.74.74,0,0,0,.739-.739v-3.183a.736.736,0,0,0-.617-.729l-2.13-.355a2.213,2.213,0,0,1-1.779-1.639A2.953,2.953,0,0,0,316.871,377.478Z"
          transform="translate(-308 -376)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(4.435 14.784)">
        <path
          className="a"
          d="M309.7,400.435h-2.957A.739.739,0,0,1,306,399.7v-2.957a.738.738,0,0,1,.739-.739H309.7a.738.738,0,0,1,.739.739V399.7A.739.739,0,0,1,309.7,400.435Zm-2.218-1.478h1.478v-1.478h-1.478Z"
          transform="translate(-306 -396)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M328.567,396H316.739a.739.739,0,1,0,0,1.478h11.827a.739.739,0,1,0,0-1.478Zm-4.435,2.957h-7.392a.739.739,0,1,0,0,1.478h7.392a.739.739,0,1,0,0-1.478Z"
          transform="translate(-308.608 -396)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(4.435 22.177)">
        <path
          className="a"
          d="M309.7,410.435h-2.957A.739.739,0,0,1,306,409.7v-2.957a.739.739,0,0,1,.739-.739H309.7a.739.739,0,0,1,.739.739V409.7A.738.738,0,0,1,309.7,410.435Zm-2.218-1.478h1.478v-1.478h-1.478Z"
          transform="translate(-306 -406)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M324.131,408.957h-7.392a.739.739,0,0,0,0,1.478h7.392a.739.739,0,0,0,0-1.478ZM328.567,406H316.739a.739.739,0,1,0,0,1.478h11.827a.739.739,0,1,0,0-1.478Z"
          transform="translate(-308.608 -406)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(4.435 29.569)">
        <path
          className="a"
          d="M309.7,420.435h-2.957A.738.738,0,0,1,306,419.7v-2.957a.739.739,0,0,1,.739-.739H309.7a.739.739,0,0,1,.739.739V419.7A.738.738,0,0,1,309.7,420.435Zm-2.218-1.478h1.478v-1.479h-1.478Z"
          transform="translate(-306 -416)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M324.131,418.957h-7.392a.739.739,0,1,0,0,1.478h7.392a.739.739,0,1,0,0-1.478ZM328.567,416H316.739a.739.739,0,1,0,0,1.478h11.827a.739.739,0,1,0,0-1.478Z"
          transform="translate(-308.608 -416)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco21;
