import { LocationState } from './types';
import { RootState } from 'lib/centralStore';
import { createSelector } from '@reduxjs/toolkit';

const _self = (state: RootState) => state.location;

export const selectIsLoading = createSelector([_self], ({ isLoading }: LocationState) => isLoading);
export const selectIsLoadingFactory = () =>
  createSelector([(state: RootState) => selectIsLoading(state)], (isLoading) => isLoading);

export const selectIsOnline = createSelector([_self], ({ isOnline }: LocationState) => isOnline);
export const selectIsOnlineFactory = () =>
  createSelector([(state: RootState) => selectIsOnline(state)], (isOnline) => isOnline);
