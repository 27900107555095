/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\point-hamburger.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const PointHamburger = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-point-hamburger', className].join(' ');
  return (
    <svg
      id="point-hamburger"
      width="100%"
      height="100%"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M1.49996 4.9C0.891297 4.9 0.399963 5.39133 0.399963 6C0.399963 6.60867 0.891297 7.1 1.49996 7.1C2.10863 7.1 2.59996 6.60867 2.59996 6C2.59996 5.39133 2.10863 4.9 1.49996 4.9ZM1.49996 0.5C0.891297 0.5 0.399963 0.991333 0.399963 1.6C0.399963 2.20867 0.891297 2.7 1.49996 2.7C2.10863 2.7 2.59996 2.20867 2.59996 1.6C2.59996 0.991333 2.10863 0.5 1.49996 0.5ZM1.49996 9.3C0.891297 9.3 0.399963 9.79867 0.399963 10.4C0.399963 11.0013 0.89863 11.5 1.49996 11.5C2.1013 11.5 2.59996 11.0013 2.59996 10.4C2.59996 9.79867 2.10863 9.3 1.49996 9.3ZM3.69996 11.1333L13.9666 11.1333V9.66667L3.69996 9.66667L3.69996 11.1333ZM3.69996 6.73333L13.9666 6.73333L13.9666 5.26667L3.69996 5.26667V6.73333ZM3.69996 0.866667L3.69996 2.33333L13.9666 2.33333V0.866667L3.69996 0.866667Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default PointHamburger;
