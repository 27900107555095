/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-147.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco147 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-147', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M70.089,225a.738.738,0,0,0-1.045,0l-4.652,4.652V210.739a.739.739,0,1,0-1.478,0v18.914L58.262,225a.739.739,0,0,0-1.045,1.045l5.914,5.914a.739.739,0,0,0,1.045,0l5.914-5.914A.738.738,0,0,0,70.089,225Z"
          transform="translate(-45.912 -210)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M53.957,254a2.957,2.957,0,1,0,2.957,2.957A2.96,2.96,0,0,0,53.957,254Zm0,4.435a1.478,1.478,0,1,1,1.478-1.478A1.48,1.48,0,0,1,53.957,258.435ZM70.22,254a2.957,2.957,0,1,0,2.957,2.957A2.96,2.96,0,0,0,70.22,254Zm0,4.435a1.478,1.478,0,1,1,1.478-1.478A1.48,1.48,0,0,1,70.22,258.435Z"
          transform="translate(-44.347 -221.474)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M79.7,214H75.265a.739.739,0,0,0-.72.571L69.5,236.177H48.468l-4.744-16.263h27.1a.739.739,0,0,0,0-1.478H42.739a.739.739,0,0,0-.71.946L47.2,237.123a.74.74,0,0,0,.71.532H70.09a2.218,2.218,0,1,1,0,4.435H48.653a.739.739,0,0,0,0,1.478H70.09a3.689,3.689,0,0,0,.9-7.268l4.858-20.822H79.7a.739.739,0,0,0,0-1.478Z"
          transform="translate(-42 -211.043)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco147;
