import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type Omit2<T, K extends keyof T | (string & {}) | (number & {}) | (symbol | {})> = { [P in Exclude<keyof T, K>]: T[P] };

export type ResetPasswordState = {
  fiscalCode: string;
  name: string;
  surname: string;
  email?: string;
  secretQuestion?: number;
  answer?: string;
  channelType: 'sms' | 'mail' | '';
  telephoneNumber?: string;
  carta?: string;
};

const initialState: ResetPasswordState = {
  fiscalCode: '',
  name: '',
  surname: '',
  email: '',
  secretQuestion: 0,
  answer: '',
  channelType: '',
};

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    setResetPassword: (state, action: PayloadAction<ResetPasswordState>) => {
      state = action.payload;
    },
    setFiscalCode: (state, action: PayloadAction<{ fiscalCode: string }>) => {
      state.fiscalCode = action.payload.fiscalCode;
    },
    setSecretQuestion: (state, action: PayloadAction<{ secretQuestion: number }>) => {
      state.secretQuestion = action.payload.secretQuestion;
    },
    setResetPersonalInformation: (
      state,
      action: PayloadAction<Omit2<ResetPasswordState, 'channelType' | 'fiscalCode'>>
    ) => {
      state.answer = action.payload.answer;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.surname = action.payload.surname;
      state.secretQuestion = action.payload.secretQuestion;
      state.telephoneNumber = action.payload.telephoneNumber;
    },
    clearResetPassword: () => {
      return initialState;
    },
  },
});

export const { setResetPassword, setFiscalCode, setSecretQuestion, setResetPersonalInformation, clearResetPassword } =
  resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
