import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsight, reactPlugin } from 'components/appInsight';
import { Session } from 'next-auth';
import { NextRouter } from 'next/router';
import React from 'react';

interface Props {
  children: React.ReactNode;
  replace: NextRouter['replace'];
  session: Session;
  asPath: string;
}

type State = {
  hasError: boolean;
  error: ReturnType<typeof Error> | undefined;
};

class ErrorBoundaryClass extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, error: undefined };
  }
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true, error };
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    // Perform preparations based on nextProps or nextState
    if (this.state.hasError && this.props.asPath.includes('error') && !nextProps.asPath.includes('error')) {
      this.setState({ hasError: false });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    // Check if the error is thrown
    if (this.state.hasError && !this.props.asPath.includes('error') && !prevProps.asPath.includes('error')) {
      const cardNumber = this.props.session?.user.cardNumber ?? 'unauthenticated';
      console.error('Error occurred', this.state.error);
      appInsight.trackException({
        exception: this.state.error,
        id: 'error-boundary',
        severityLevel: SeverityLevel.Critical,
        properties: {
          cardNumber,
          userId: appInsight.context?.user?.id,
          sessionId: appInsight.context?.getSessionId(),
          version: process.env.NEXT_PUBLIC_FRONTEND_VERSION,
        },
      });
      this.props.replace('/error');
      return;
    }
  }

  render() {
    // Return children components in case of no error
    return this.props.children;
  }
}

export default withAITracking(reactPlugin, ErrorBoundaryClass);
