/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-43.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina43 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-43', className].join(' ');
  return (
    <svg
      id="disciplina_evento-televisivo"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="m16.64 3.47-3.581 3.58a.73.73 0 0 1-1.023 0l-.34-.317L8.433 3.47a.799.799 0 0 1-.095-.116L7.195 2.21a.715.715 0 0 1 1.01-1.01l2.064 2.063 2.27 2.27 3.078-3.087a.73.73 0 0 1 1.023 0 .73.73 0 0 1 0 1.024Zm6.199 12.213a45.61 45.61 0 0 1-.322 5.051c-.1.671-.74 1.342-1.393 1.466a53.4 53.4 0 0 1-18.413 0c-.653-.124-1.29-.795-1.393-1.466A48.872 48.872 0 0 1 1 15.684c.014-1.69.122-3.376.322-5.052.1-.67.74-1.342 1.393-1.466a53.398 53.398 0 0 1 18.413 0c.653.124 1.29.795 1.393 1.466.193 1.677.299 3.363.318 5.051Zm-9.028.413 1.892-1.84-2.61-.379-1.17-2.364-1.169 2.364-2.61.379 1.893 1.84-.448 2.6 2.335-1.229 2.334 1.23-.447-2.601Z"
      />
    </svg>
  );
};
export default Disciplina43;
