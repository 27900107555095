/*****************************************************************************************

  FILE GENERATE AUTOMAGICALLY READING "SORTING EXCEPTION" CONFIGURATION FROM CMS 
    
*****************************************************************************************/

// ScommesseType exception : sorting should be appliead by Esito
export const sortByEsitoExceptions: Array<string> = [];

// ScommesseType exception : sorting should be appliead by Quota
export const sortByQuotaExceptions: Array<string> = [
  '10929',
  '10930',
  '10931',
  '12312',
  '14135',
  '14136',
  '14441',
  '14442',
  '14831',
  '14832',
  '15087',
  '17287',
  '17288',
  '17290',
  '17291',
  '17301',
  '20502',
  '20503',
];

// ScommesseType exception : sorting should be appliead by Description
export const sortByDescriptionExceptions: Array<string> = [];
