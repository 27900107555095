import { GamesSkillGameDto } from 'types/swagger';
import { HintCategory, SearchResponseApi } from '../../lib/api/searchResponseApi';

export const MOST_SEARCHED_ID = 'GetMostSearched';
export const AUTOCOMPLETE_GAMES_ID = 'GetAutocompleteGame';

export type HintGame = Pick<GamesSkillGameDto, 'slug' | 'id' | 'title' | 'produttore' | 'category'> & {
  type: 'skillgame';
};
export type Hint = HintCategory | HintGame;

export type SearchState = {
  mostSearched?: SearchResponseApi;
  isMostSearchedLoading?: boolean;
  autocompleteGame?: SearchResponseApi;
  isAutocompleteGameLoading?: boolean;
};
