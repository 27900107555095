/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-kr.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneKr = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-kr', className].join(' ');
  return (
    <svg
      id="nazione-kr"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0h16v16H0V0Z" fill="#fff" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m.687 5.236 1.95-2.925.488.325-1.95 2.925-.488-.325Zm.731.487L3.368 2.8l.488.325-1.95 2.925-.488-.326Zm.731.488L4.1 3.286l.488.325-1.95 2.925-.488-.325Z"
          fill="#000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m.687 5.236 1.95-2.925.488.325-1.95 2.925-.488-.325Zm.731.487L3.368 2.8l.488.325-1.95 2.925-.488-.326Zm.731.488L4.1 3.286l.488.325-1.95 2.925-.488-.325ZM11.2 12.3l1.95-2.925.488.325-1.95 2.925-.488-.325Zm.731.488 1.95-2.925.488.325-1.95 2.925-.488-.325Zm.731.487 1.951-2.925.488.326L13.15 13.6l-.488-.325Z"
          fill="#000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m11.2 12.3 1.95-2.925.488.325-1.95 2.925-.488-.325Zm.731.488 1.95-2.925.488.325-1.95 2.925-.488-.325Zm.731.487 1.951-2.925.488.326L13.15 13.6l-.488-.325ZM12.143 10.763l2.437 1.626-2.437-1.626Z"
          fill="#000"
        />
        <path d="m12.143 10.763 2.437 1.626" stroke="#fff" strokeWidth=".3" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.074 6.05a3.516 3.516 0 0 1 5.85 3.9l-5.85-3.9Z"
          fill="#CD2E3A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.074 6.05a3.516 3.516 0 0 0 5.85 3.9A1.758 1.758 0 0 0 8 8L5.074 6.05Z"
          fill="#0047A0"
        />
        <path d="M8 8a1.758 1.758 0 1 0-2.926-1.95A1.758 1.758 0 0 0 8 8Z" fill="#CD2E3A" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m2.639 13.688-1.95-2.924.487-.325 1.95 2.924-.487.325Zm.731-.487-1.95-2.925.487-.325 1.95 2.925-.487.325Zm.731-.488-1.95-2.925.487-.325 1.95 2.925-.487.325Z"
          fill="#000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m2.639 13.688-1.95-2.924.487-.325 1.95 2.924-.487.325Zm.731-.487-1.95-2.925.487-.325 1.95 2.925-.487.325Zm.731-.488-1.95-2.925.487-.325 1.95 2.925-.487.325ZM13.3 6.55l-1.95-2.925.487-.325 1.95 2.925-.487.325Zm.731-.488-1.95-2.925.487-.325 1.95 2.925-.487.325Zm.731-.487-1.95-2.925.487-.325 1.95 2.925-.487.325Z"
          fill="#000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m13.3 6.55-1.95-2.925.487-.325 1.95 2.925-.487.325Zm.731-.488-1.95-2.925.487-.325 1.95 2.925-.487.325Zm.731-.487-1.95-2.925.487-.325 1.95 2.925-.487.325Z"
          fill="#000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m2.273 11.82.731-.488-.731.488Zm9.871-6.584.853-.569-.853.57ZM13.73 4.18l.73-.488-.73.488Z"
          fill="#000"
        />
        <path d="m2.273 11.82.731-.488m9.14-6.096.853-.569m.732-.487.73-.488" stroke="#fff" strokeWidth=".3" />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="16" height="16" rx="8" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NazioneKr;
