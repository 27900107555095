/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-245.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco245 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-245', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="38.439"
      viewBox="0 0 41.396 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M212.435,316.3v2.354a2.22,2.22,0,0,0,2.218,2.218h5.914a.739.739,0,1,0,0-1.478h-5.914a.74.74,0,0,1-.739-.739V316.3a2.218,2.218,0,1,0-1.478,0Zm.739-2.821a.739.739,0,1,1-.739.739A.74.74,0,0,1,213.175,313.479Zm7.392,14.784h-4.435a2.22,2.22,0,0,0-2.218,2.218v2.354a2.218,2.218,0,1,0,1.478,0v-2.354a.74.74,0,0,1,.739-.739h4.435a.739.739,0,1,0,0-1.478Zm-5.914,7.392a.739.739,0,1,1,.739-.739A.74.74,0,0,1,214.653,335.655Zm32.526-13.306a2.215,2.215,0,0,0-2.082,1.478H236.09a.74.74,0,0,0-.523.217l-4.435,4.435a.738.738,0,0,0,0,1.045l2,2v.127l-4,4H228.7V321.61a.739.739,0,1,0-1.478,0v13.739l-2.957-2.957v-7.825a.739.739,0,0,0-.739-.739H212.3a2.218,2.218,0,1,0,0,1.478h10.485V332.7a.736.736,0,0,0,.217.523l3.7,3.7a.738.738,0,0,0,.523.217h2.218a.739.739,0,0,0,.523-.217l4.435-4.435a.739.739,0,0,0,.217-.523v-.739a.74.74,0,0,0-.217-.523l-1.7-1.7,3.7-3.7h8.7a2.214,2.214,0,1,0,2.082-2.957Zm-36.961,2.957a.739.739,0,1,1,.739-.739A.74.74,0,0,1,210.218,325.306Zm36.961,0a.739.739,0,1,1,.739-.739A.74.74,0,0,1,247.179,325.306Zm-15.307-.217a.739.739,0,0,0,1.045-1.045l-.217-.217,1.7-1.7a.739.739,0,0,0,.217-.523v-4.976a.739.739,0,1,0-1.478,0v4.67l-2,2a.738.738,0,0,0,0,1.045Zm-8.348-2.74a.739.739,0,0,0,.739-.739v-4.976a.739.739,0,1,0-1.478,0v4.976A.739.739,0,0,0,223.524,322.349Zm13.306-1.478h5.914a2.22,2.22,0,0,0,2.218-2.218V316.3a2.218,2.218,0,1,0-1.478,0v2.354a.74.74,0,0,1-.739.739h-5.914a.739.739,0,0,0,0,1.478Zm7.392-7.392a.739.739,0,1,1-.739.739A.74.74,0,0,1,244.222,313.479Zm-.739,19.356v-2.354a2.22,2.22,0,0,0-2.218-2.218h-4.435a.739.739,0,0,0,0,1.478h4.435a.74.74,0,0,1,.739.739v2.354a2.218,2.218,0,1,0,1.478,0Zm-.739,2.821a.739.739,0,1,1,.739-.739A.74.74,0,0,1,242.743,335.655Z"
        transform="translate(-208 -298.694)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M241.734,296.7c-3.611-3.593-16.2-3.593-19.814,0-1.2,1.2-1.921,3.783-1.921,6.915s.719,5.717,1.921,6.915c1.805,1.8,5.856,2.694,9.907,2.694s8.1-.9,9.907-2.694c1.2-1.2,1.921-3.783,1.921-6.915S242.936,297.893,241.734,296.7Zm-1.043,12.782c-3.064,3.051-14.662,3.053-17.728,0-.9-.9-1.485-3.2-1.485-5.866s.583-4.97,1.485-5.867c1.532-1.526,5.2-2.288,8.864-2.288s7.332.762,8.864,2.288c.9.9,1.485,3.2,1.485,5.867S241.593,308.58,240.691,309.477Zm-8.864-12.516a3.7,3.7,0,1,0,3.7,3.7A3.7,3.7,0,0,0,231.827,296.961Zm0,5.914a2.218,2.218,0,1,1,2.218-2.218A2.22,2.22,0,0,1,231.827,302.874Z"
        transform="translate(-211.129 -294)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco245;
