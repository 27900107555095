/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-98.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco98 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-98', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="41.396"
      viewBox="0 0 41.396 41.396"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(31.047 19.22)">
        <path
          className="a"
          d="M251.478,488.957a1.478,1.478,0,1,1,1.478-1.478A1.48,1.48,0,0,1,251.478,488.957Zm0-1.478h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Z"
          transform="translate(-250 -486)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(14.784 11.832)">
        <path
          className="a"
          d="M239.088,478.354a.735.735,0,0,1-.245-.042,15.192,15.192,0,0,0-9.86-.01.739.739,0,0,1-.486-1.4,16.674,16.674,0,0,1,10.838.011.74.74,0,0,1-.245,1.437Z"
          transform="translate(-228 -476.006)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(0 19.959)">
        <path
          className="a"
          d="M211.7,491.435a3.7,3.7,0,0,1-3.7-3.7.739.739,0,0,1,1.478,0,2.22,2.22,0,0,0,2.218,2.218.739.739,0,1,1,0,1.478Z"
          transform="translate(-208 -487)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(2.957 8.41)">
        <path
          className="a"
          d="M240.09,504.363h-4.435a1.48,1.48,0,0,1-1.478-1.479v-1.947a21.309,21.309,0,0,1-10.365-.378l.016,2.325a1.48,1.48,0,0,1-1.478,1.479h-4.435a1.48,1.48,0,0,1-1.478-1.479v-4.457a5.22,5.22,0,0,0-1.234-3.347,13.052,13.052,0,0,1-3.2-8.458c0-8.152,7.959-14.784,17.741-14.784a20.779,20.779,0,0,1,6.446,1.019.729.729,0,0,0,.4.018l5.807-1.451a1.478,1.478,0,0,1,1.789,1.807l-.988,3.78a13.675,13.675,0,0,1,4.1,7.5.748.748,0,0,0,.727.637h.933a1.48,1.48,0,0,1,1.478,1.478v5.027a2.215,2.215,0,0,1-1.174,1.957l-5.739,3.06a3.689,3.689,0,0,0-1.957,3.261v2.958A1.48,1.48,0,0,1,240.09,504.363Zm-5.9-4.905a1.456,1.456,0,0,1,.918.327,1.478,1.478,0,0,1,.551,1.153v1.947h4.435v-2.958a5.166,5.166,0,0,1,2.74-4.567l5.739-3.059a.738.738,0,0,0,.391-.652v-5.027h-.933a2.223,2.223,0,0,1-2.184-1.866,12.249,12.249,0,0,0-3.671-6.688,1.483,1.483,0,0,1-.406-1.431l.988-3.78-5.807,1.452a2.205,2.205,0,0,1-1.22-.047,19.316,19.316,0,0,0-5.988-.945c-8.967,0-16.263,5.969-16.263,13.306a11.6,11.6,0,0,0,2.853,7.507,6.7,6.7,0,0,1,1.582,4.3v4.457h4.435v-2.321a1.49,1.49,0,0,1,.6-1.189,1.445,1.445,0,0,1,1.285-.232,19.826,19.826,0,0,0,9.649.347A1.5,1.5,0,0,1,234.186,499.458Z"
          transform="translate(-212 -471.376)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(5.914 22.916)">
        <path
          className="a"
          d="M220.857,500.316a.738.738,0,0,1-.458-.159,10.722,10.722,0,0,1-4.4-8.417.739.739,0,1,1,1.478,0A9.273,9.273,0,0,0,221.316,499a.74.74,0,0,1-.458,1.32Z"
          transform="translate(-216 -491)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(15.524 0)">
        <path
          className="a"
          d="M234.175,470.349a5.175,5.175,0,1,1,5.174-5.175A5.181,5.181,0,0,1,234.175,470.349Zm0-8.871a3.7,3.7,0,1,0,3.7,3.7A3.7,3.7,0,0,0,234.175,461.478Z"
          transform="translate(-229 -460)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco98;
