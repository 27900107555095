/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-414.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco414 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-414', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="32.526"
      viewBox="0 0 38.439 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M413.482,382H380.957A2.96,2.96,0,0,0,378,384.957v22.177a2.96,2.96,0,0,0,2.957,2.957h11.694l-.493,2.957h-1.592a.739.739,0,1,0,0,1.478h13.306a.739.739,0,1,0,0-1.478h-1.592l-.493-2.957h11.694a2.96,2.96,0,0,0,2.957-2.957V384.957A2.96,2.96,0,0,0,413.482,382Zm-19.826,31.047.493-2.957h6.14l.493,2.957Zm21.3-5.914a1.48,1.48,0,0,1-1.478,1.478H380.957a1.48,1.48,0,0,1-1.479-1.478v-1.478h35.482Zm0-2.957H379.478v-19.22a1.48,1.48,0,0,1,1.479-1.478h32.526a1.481,1.481,0,0,1,1.478,1.478Zm-12.021-7.153a7.4,7.4,0,1,0-1.045,1.045l4.412,4.412a.739.739,0,0,0,1.045-1.045Zm-5.72,1.239a5.914,5.914,0,1,1,5.914-5.914A5.921,5.921,0,0,1,397.22,398.263Z"
        transform="translate(-378 -382)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco414;
