/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\form.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Form = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-form', className].join(' ');
  return (
    <svg id="form" viewBox="0 0 19 16" focusable="false" className={componentClassName} {...attribs}>
      <path
        d="M1.5 6.5C0.67 6.5 0 7.17 0 8C0 8.83 0.67 9.5 1.5 9.5C2.33 9.5 3 8.83 3 8C3 7.17 2.33 6.5 1.5 6.5ZM1.5 0.5C0.67 0.5 0 1.17 0 2C0 2.83 0.67 3.5 1.5 3.5C2.33 3.5 3 2.83 3 2C3 1.17 2.33 0.5 1.5 0.5ZM1.5 12.5C0.67 12.5 0 13.18 0 14C0 14.82 0.68 15.5 1.5 15.5C2.32 15.5 3 14.82 3 14C3 13.18 2.33 12.5 1.5 12.5ZM4.5 15H18.5V13H4.5V15ZM4.5 9H18.5V7H4.5V9ZM4.5 1V3H18.5V1H4.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Form;
