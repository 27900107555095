import formatTime from 'date-fns/format';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  calculateInitialTime,
  calculateRemainingDays,
  calculateRemainingHours,
  calculateRemainingMinutes,
  calculateRemainingSeconds,
  isClientSide,
} from '../utility/functions';

import { Countdown, Time } from '../utility/types';

type useCountdownParams = {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  format?: string;
  canExceedDay?: boolean;
  onCompleted?: VoidFunction;
};

/**
 * @name useCountdown
 * @description React hook countdown timer.
 */
const useCountdown = ({
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
  onCompleted,
  format = 'mm:ss',
  canExceedDay = false,
}: useCountdownParams = {}): Countdown => {
  const id = useRef(0);

  // time
  const [remainingTime, setRemainingTime] = useState(calculateInitialTime({ minutes, seconds, days, hours }));

  // status
  const [isActive, setIsActive] = useState(false);
  const [isRunning, setIsRunning] = useState(false);

  const calculateRemainingTime = useCallback(() => {
    setRemainingTime((time) => {
      if (time - 1000 <= 0) {
        if (isClientSide()) {
          window.clearInterval(id.current);
        }
        onCompleted?.();
        setIsActive(false);
        setIsRunning(false);

        return 0;
      }

      return time - 1000;
    });
  }, [onCompleted]);

  useEffect(() => {
    if (isClientSide()) {
      id.current = window.setInterval(calculateRemainingTime, 1000);
    }
    setIsActive(true);
    setIsRunning(true);

    return () => {
      if (isClientSide()) {
        window.clearInterval(id.current);
      }
    };
  }, [calculateRemainingTime]);

  const reset = (time: Time = { minutes, seconds, days, hours }) => {
    if (isClientSide()) {
      window.clearInterval(id.current);
      id.current = window.setInterval(calculateRemainingTime, 1000);
    }

    setIsActive(true);
    setIsRunning(true);

    setRemainingTime(calculateInitialTime(time));
  };

  const countdown: Countdown = {
    days: calculateRemainingDays(remainingTime),
    hours: calculateRemainingHours(remainingTime, canExceedDay),
    minutes: calculateRemainingMinutes(remainingTime),
    seconds: calculateRemainingSeconds(remainingTime),
    formatted: formatTime(remainingTime, format),
    isActive,
    isRunning,
    reset,
  };

  return countdown;
};

export default useCountdown;
