/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\download.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Download = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-download', className].join(' ');
  return (
    <svg
      id="download"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M0 12H2V17H18V12H20V17C20 18.11 19.11 19 18 19H2C0.9 19 0 18.11 0 17V12ZM10 13L15.55 7.54L14.13 6.13L11 9.25V0H9V9.25L5.88 6.13L4.46 7.55L10 13Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Download;
