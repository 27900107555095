/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-217.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco217 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-217', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="41.396"
      viewBox="0 0 38.439 41.396"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M151,238.308h1.478v-8.871H151ZM151.739,208a.739.739,0,0,0-.739.739V211.7a.739.739,0,0,0,1.478,0v-2.957A.739.739,0,0,0,151.739,208Z"
          transform="translate(-132.52 -208)"
          fill="currentColor"
        />
        <g transform="translate(11.088 29.569)">
          <path
            className="a"
            d="M146.175,259.827A5.18,5.18,0,0,1,141,254.653a2.218,2.218,0,1,1,4.435,0,.739.739,0,0,0,1.478,0v-5.174A1.48,1.48,0,0,1,148.392,248h1.478a1.48,1.48,0,0,1,1.478,1.478v5.174A5.18,5.18,0,0,1,146.175,259.827Zm-2.957-5.914a.74.74,0,0,0-.739.739,3.7,3.7,0,1,0,7.392,0v-5.174h-1.478v5.174a2.218,2.218,0,0,1-4.435,0A.74.74,0,0,0,143.218,253.914Z"
            transform="translate(-141 -248)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M145.22,212A19.242,19.242,0,0,0,126,231.22v2.218a.739.739,0,0,0,1.3.477,6.657,6.657,0,0,1,10.7,0,.739.739,0,0,0,.553.262.69.69,0,0,0,.561-.245,8.224,8.224,0,0,1,12.207,0,.747.747,0,0,0,.561.245.739.739,0,0,0,.553-.262,6.657,6.657,0,0,1,10.7,0,.739.739,0,0,0,1.3-.477V231.22A19.242,19.242,0,0,0,145.22,212Zm17.741,19.668a8.108,8.108,0,0,0-5.174-1.926,8.3,8.3,0,0,0-5.933,2.621,9.7,9.7,0,0,0-13.267,0,8.3,8.3,0,0,0-5.933-2.621,8.108,8.108,0,0,0-5.175,1.926v-.448a17.768,17.768,0,0,1,14.456-17.432c-2.453,2.856-4.107,8.854-4.107,15.953a.739.739,0,0,0,1.478,0c0-7.692,2.429-16.263,5.914-16.263s5.914,8.571,5.914,16.263a.739.739,0,0,0,1.478,0c0-7.1-1.654-13.1-4.107-15.953a17.768,17.768,0,0,1,14.456,17.432Z"
          transform="translate(-126 -209.043)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco217;
