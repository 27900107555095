/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-55.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco55 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-55', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.569"
      height="38.439"
      viewBox="0 0 29.569 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <g transform="translate(9.61 33.265)">
          <path
            className="a"
            d="M402.174,176.175A5.181,5.181,0,0,1,397,171h1.478a3.7,3.7,0,0,0,7.392,0h1.478A5.181,5.181,0,0,1,402.174,176.175Z"
            transform="translate(-397 -171)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M398.784,133.392a7.4,7.4,0,0,0-7.392,7.392.739.739,0,1,0,1.478,0,5.921,5.921,0,0,1,5.914-5.914.739.739,0,0,0,0-1.478Zm14.637,20.994-2.661-3.548a8.179,8.179,0,0,1-1.626-4.879v-5.175a10.359,10.359,0,0,0-7.987-10.067,2.928,2.928,0,0,0,.6-1.761,2.957,2.957,0,1,0-5.914,0,2.928,2.928,0,0,0,.6,1.761,10.359,10.359,0,0,0-7.987,10.067v5.175a8.179,8.179,0,0,1-1.626,4.879l-2.661,3.548a.766.766,0,0,0-.052.1.7.7,0,0,0-.042.081.727.727,0,0,0-.053.262v2.957A2.22,2.22,0,0,0,386.218,160h25.133a2.22,2.22,0,0,0,2.218-2.218V154.83A.739.739,0,0,0,413.421,154.386Zm-14.636-26.908a1.478,1.478,0,1,1-1.478,1.478A1.48,1.48,0,0,1,398.784,127.478Zm13.306,30.308a.74.74,0,0,1-.739.739H386.218a.74.74,0,0,1-.739-.739v-2.218h23.655a.739.739,0,1,0,0-1.478H386.218l1.773-2.365a9.667,9.667,0,0,0,1.923-5.766v-5.175a8.871,8.871,0,1,1,17.741,0v5.175a9.667,9.667,0,0,0,1.923,5.766l2.513,3.352Z"
          transform="translate(-384 -126)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco55;
