/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-49.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco49 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-49', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.612"
      height="38.439"
      viewBox="0 0 26.612 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M315.827,210A11.841,11.841,0,0,0,304,221.827c0,4.425,3,8.126,5.907,11.706,2.665,3.284,5.181,6.386,5.181,9.732a.739.739,0,1,0,1.478,0c0-3.346,2.516-6.447,5.181-9.732,2.9-3.579,5.907-7.281,5.907-11.706A11.841,11.841,0,0,0,315.827,210Zm4.772,22.6a30.136,30.136,0,0,0-4.772,7.131,30.139,30.139,0,0,0-4.772-7.131c-2.742-3.38-5.577-6.874-5.577-10.774a10.349,10.349,0,0,1,20.7,0C326.177,225.728,323.342,229.222,320.6,232.6Zm-4.772-15.209a4.435,4.435,0,1,0,4.435,4.435A4.44,4.44,0,0,0,315.827,217.392Zm0,7.392a2.957,2.957,0,1,1,2.957-2.957A2.96,2.96,0,0,1,315.827,224.784Z"
        transform="translate(-302.521 -210)"
        fill="currentColor"
      />
      <g transform="translate(0 28.09)">
        <path
          className="a"
          d="M327.873,258.349H302.739a.739.739,0,0,1-.661-1.07l4.435-8.871a.739.739,0,0,1,.661-.409h6.9a.739.739,0,1,1,0,1.478h-6.44l-3.7,7.392h22.741l-3.7-7.392h-3.006a.739.739,0,1,1,0-1.478h3.462a.739.739,0,0,1,.661.409l4.435,8.871a.739.739,0,0,1-.661,1.07Z"
          transform="translate(-302 -248)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco49;
