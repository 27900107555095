/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-47.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina47 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-47', className].join(' ');
  return (
    <svg
      id="disciplina_badminton"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M23.998 13.424a.57.57 0 0 0-.394-.497l-2.975-.952-.004-.001-.001-.004-.957-2.972a.573.573 0 0 0-.371-.37L16.32 7.67h-.003l-.001-.004-.958-2.972a.573.573 0 0 0-.37-.37l-2.971-.952-.003-.002-.002-.003-.957-2.972A.57.57 0 0 0 10.004.31L3.039 13.864v.005l-1.544 1.542c-2.098 2.096-1.98 5.56.305 7.45a5.018 5.018 0 0 0 6.73-.329l1.596-1.593h.004l13.568-6.957a.582.582 0 0 0 .3-.558Zm-13.6-11.34.535 1.664v.009l-3.99 6.507a.572.572 0 1 0 .977.6l3.89-6.348c0-.004.01-.004.01-.004l2.539.81a.005.005 0 0 1 .005.005l.844 2.635c0 .005 0 .01-.005.01L13.2 9.981a.57.57 0 1 0 .807.807l2.008-2.006c.005-.005.005-.005.01-.005l2.637.844.004.001v.003l.813 2.537c0 .004 0 .009-.005.009L8.826 18.688c-.005.005-.01 0-.014 0l-1.352-1.35a.01.01 0 0 1-.002-.003.01.01 0 0 1 0-.008l.002-.003 4.928-4.922a.57.57 0 1 0-.807-.807l-4.928 4.923a.009.009 0 0 1-.011.002.01.01 0 0 1-.003-.002l-1.352-1.35a.009.009 0 0 1-.003-.01l.003-.004 1.446-2.358a.572.572 0 1 0-.976-.6l-1.3 2.118a.009.009 0 0 1-.011.003.009.009 0 0 1-.004-.003l-.225-.225c-.004-.004-.004-.009 0-.009l6.167-12.001c0-.005.01-.005.014.004ZM7.713 21.725a3.865 3.865 0 0 1-5.462 0 3.866 3.866 0 0 1 0-5.461l1.248-1.247.003-.003a.009.009 0 0 1 .008 0l.003.003 5.454 5.447a.009.009 0 0 1 .003.007l-.001.004a.01.01 0 0 1-.002.003l-1.254 1.247Zm2.173-1.96-.225-.224c-.005-.005-.005-.015 0-.015l10.578-6.474h.01l1.666.535a.01.01 0 0 1 .005.003.01.01 0 0 1 .001.006.01.01 0 0 1-.007.01l-12.018 6.16c0 .004-.005.004-.01 0Z"
      />
    </svg>
  );
};
export default Disciplina47;
