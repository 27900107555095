/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\chewron-right.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const ChewronRight = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-chewron-right', className].join(' ');
  return (
    <svg id="chewron-right" viewBox="0 0 8 12" focusable="false" className={componentClassName} {...attribs}>
      <path
        d="M7.69999 6L1.69999 12L0.299988 10.6L4.89999 6L0.299987 1.4L1.69999 -6.11959e-08L7.69999 6Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default ChewronRight;
