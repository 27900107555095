/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\comment.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Comment = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-comment', className].join(' ');
  return (
    <svg
      id="comment"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#clip0_7830_254678)">
        <path
          d="M6.6 16.9H6.39289L6.24645 17.0464L3.5 19.7929V4.8C3.5 4.08614 4.08614 3.5 4.8 3.5H19.2C19.9139 3.5 20.5 4.08614 20.5 4.8V15.6C20.5 16.3139 19.9139 16.9 19.2 16.9H6.6Z"
          fill="currentColor"
          stroke="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_7830_254678">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Comment;
