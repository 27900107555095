/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-42.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina42 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-42', className].join(' ');
  return (
    <svg
      id="disciplina_miss-italia"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="m1 7.936 5.732 3.472L11.854 4l5.195 7.408L23 8.129l-1.117 7.915H1.873L1 7.936Zm.958 9.062.237 2.084h19.228l.33-2.084H1.959Z"
      />
    </svg>
  );
};
export default Disciplina42;
