import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getConfig } from './actions';
import { initialState } from './initialState';
import { ConfigurationType } from './types';

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setInstantScriptLoaded: (state, action: PayloadAction<boolean>) => {
      state.isInstantScriptLoaded = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConfig.fulfilled, (state, { payload }: PayloadAction<ConfigurationType>) => {
      const {
        modalTutorial,
        isActiveIppica,
        isEnabledTrace,
        isActiveVirtual,
        webSocketInterval,
        betReferralTiming,
        isActiveMarcatori,
        isActiveBingoLive,
        sportPacketMessage,
        isSportSearchEnabled,
        isSlotTracingEnabled,
        isNewRicaricaPreleva,
      } = payload ?? initialState;
      state.modalTutorial = modalTutorial;
      state.isActiveIppica = isActiveIppica;
      state.isEnabledTrace = isEnabledTrace;
      state.isActiveVirtual = isActiveVirtual;
      state.isActiveMarcatori = isActiveMarcatori;
      state.webSocketInterval = webSocketInterval;
      state.betReferralTiming = betReferralTiming;
      state.isActiveBingoLive = isActiveBingoLive;
      state.sportPacketMessage = sportPacketMessage;
      state.isSportSearchEnabled = isSportSearchEnabled;
      state.isSlotTracingEnabled = isSlotTracingEnabled;
      state.isNewRicaricaPreleva = isNewRicaricaPreleva;
    });
  },
});

export const { setInstantScriptLoaded } = configurationSlice.actions;

export default configurationSlice.reducer;
