/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-389.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco389 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-389', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="35.482"
      viewBox="0 0 41.396 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M153.938,223.828a2.59,2.59,0,0,0-2.587,2.587.739.739,0,1,0,1.478,0,1.109,1.109,0,0,1,2.218,0,.739.739,0,1,0,1.478,0A2.59,2.59,0,0,0,153.938,223.828ZM139.6,217.913a.718.718,0,0,0,.147-.015.739.739,0,0,0,.578-.871,2.958,2.958,0,1,1,5.794,0,.739.739,0,0,0,1.449.294,4.435,4.435,0,1,0-8.781-.886,4.41,4.41,0,0,0,.089.886A.738.738,0,0,0,139.6,217.913Zm24.32,7.393h-3.071a11.814,11.814,0,0,0-4.321-4.3V215.7a.739.739,0,0,0-.739-.739,7.4,7.4,0,0,0-6.768,4.435h-9.911a12.376,12.376,0,0,0-8.726,3.669,11.6,11.6,0,0,0-3.383,7.3,2.214,2.214,0,0,1-1.519-2.094.739.739,0,0,0-1.478,0,3.7,3.7,0,0,0,2.993,3.625,11.724,11.724,0,0,0,4.4,8.549v4.828a2.22,2.22,0,0,0,2.218,2.218h1.478a2.22,2.22,0,0,0,2.218-2.218v-2.309a12.059,12.059,0,0,0,1.478.092h8.871v2.218a2.22,2.22,0,0,0,2.218,2.218h1.478a2.22,2.22,0,0,0,2.218-2.218v-2.591a11.785,11.785,0,0,0,8.489-8.5h.381a2.96,2.96,0,0,0,2.957-2.957v-4.435A1.48,1.48,0,0,0,163.918,225.306Zm0,5.914a1.48,1.48,0,0,1-1.478,1.478h-2.218a.739.739,0,1,0,0,1.478h.3a10.308,10.308,0,0,1-7.842,7.184.739.739,0,0,0-.592.724v3.179a.74.74,0,0,1-.739.739h-1.478a.74.74,0,0,1-.739-.739v-2.957a.739.739,0,0,0-.739-.739h-9.61a10.461,10.461,0,0,1-2.071-.208.761.761,0,0,0-.147-.015.74.74,0,0,0-.739.739v3.179a.74.74,0,0,1-.739.739H133.61a.74.74,0,0,1-.739-.739v-5.189a.741.741,0,0,0-.294-.591,10.278,10.278,0,0,1-4.14-8.159,10.087,10.087,0,0,1,3-7.223,10.885,10.885,0,0,1,7.676-3.232h9.911a1.5,1.5,0,0,0,1.362-.907,5.921,5.921,0,0,1,4.666-3.482v4.956a.74.74,0,0,0,.393.653,10.345,10.345,0,0,1,4.318,4.3.74.74,0,0,0,.653.393h3.506ZM142.48,229h2.957a.739.739,0,1,0,0-1.478h-1.478v-1.478a.739.739,0,0,0-1.478,0v1.478a2.218,2.218,0,1,0,0,4.435h1.478a.739.739,0,1,1,0,1.478H141a.739.739,0,1,0,0,1.478h1.478v1.478a.739.739,0,1,0,1.478,0v-1.478a2.218,2.218,0,1,0,0-4.435H142.48a.739.739,0,0,1,0-1.478Z"
        transform="translate(-124 -212)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco389;
