/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-107.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina107 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-107', className].join(' ');
  return (
    <svg
      id="disciplina_motonautica"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M4.076 12.565c.157-.119.837-.414 1.362-.592.524-.177 3.397-1.301 3.397-1.301s5.959-1.715 7.735-2.07c1.777-.355 6.325-.235 7.109-.177.784.057 0 1.064-.836 1.833-.614.564-2.293 3.665-3.796 3.377-1.025-.197-1.839-.266-1.673-.118 0 0-2.262 1.39-2.56 1.182-.259-.178-.837-1.361-2.614-1.182-.355.035-2.096.766-3.24 1.064-.678.178-1.145-.379-1.933-.828-1.34-.763-3.502.355-3.502.355s.394-1.425.551-1.543Zm2.875-1.952s4.704-1.656 5.906-1.893l3.92-.769s-.516-.626-1.098-1.241c-.607-.645-1.045-.71-1.045-.71s-1.123.516-1.986.946c-1.443.715-4.704 2.84-5.018 3.017-.313.177-.784.65-.784.65h.105Zm-2.561-.65c-.261-.296-1.202-.355-1.831-.414-.63-.06-1.2.71-1.356 1.064-.156.354.104 1.361.365 2.188.262.828 1.307.71 1.307.71.171-.413.382-.81.628-1.183.366-.532 1.254-.887 1.254-.887s-.106-1.183-.367-1.478ZM.23 16.91a.965.965 0 0 1 0-1.25.717.717 0 0 1 1.104 0 1.328 1.328 0 0 0 2.042 0c1.173-1.328 3.086-1.328 4.26 0a1.328 1.328 0 0 0 2.042 0c.57-.64 1.327-.997 2.13-.997.802 0 1.559.352 2.13.998.57.646 1.48.64 2.046 0 1.173-1.329 3.086-1.329 4.26 0a1.328 1.328 0 0 0 2.042 0 .717.717 0 0 1 1.104 0 .965.965 0 0 1 0 1.25c-1.173 1.328-3.086 1.328-4.26 0a1.33 1.33 0 0 0-2.042 0c-1.173 1.328-3.085 1.328-4.26 0a1.33 1.33 0 0 0-2.041 0c-.57.641-1.328.996-2.13.996-.803 0-1.56-.352-2.13-.997a1.33 1.33 0 0 0-2.042 0c-1.174 1.328-3.081 1.328-4.255 0Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina107;
