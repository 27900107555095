/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\legenda-tris-light.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const LegendaTrisLight = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-legenda-tris-light', className].join(' ');
  return (
    <svg
      id="legenda-tris-light"
      width="100%"
      height="100%"
      viewBox="0 0 5 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M.517595 10H1.88781l1.22127-5.67943h1.45958L4.84667 3H.557311L.279297 4.32057H1.72894L.517595 10Z"
        fill="#0F1315"
      />
    </svg>
  );
};
export default LegendaTrisLight;
