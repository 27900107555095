/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-215.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco215 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-215', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="32.526"
      viewBox="0 0 38.439 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(4.435 3.697)">
        <path
          className="a"
          d="M48.739,304.482a.735.735,0,0,1-.281-.059.853.853,0,0,1-.244-.155.732.732,0,0,1-.155-.806.854.854,0,0,1,.155-.244.774.774,0,0,1,1.05,0,.854.854,0,0,1,.155.244.7.7,0,0,1,0,.562.864.864,0,0,1-.155.244.853.853,0,0,1-.244.155A.735.735,0,0,1,48.739,304.482Z"
          transform="translate(-48 -303.004)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M52.739,304.479a.735.735,0,0,1-.525-.214.864.864,0,0,1-.155-.244.7.7,0,0,1,0-.562.764.764,0,0,1,.155-.244.855.855,0,0,1,.244-.155.739.739,0,0,1,.806.155.764.764,0,0,1,.155.244.732.732,0,0,1-.68,1.02Z"
          transform="translate(-49.043 -303.001)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M56.739,304.482a.735.735,0,0,1-.525-.214.864.864,0,0,1-.155-.244.7.7,0,0,1,0-.562.762.762,0,0,1,.155-.244.774.774,0,0,1,1.05,0,.854.854,0,0,1,.155.244.7.7,0,0,1,0,.562.865.865,0,0,1-.155.244.853.853,0,0,1-.244.155A.735.735,0,0,1,56.739,304.482Z"
          transform="translate(-50.086 -303.004)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M75.265,298H47.175A5.181,5.181,0,0,0,42,303.175v22.177a5.181,5.181,0,0,0,5.175,5.175h28.09a5.181,5.181,0,0,0,5.175-5.175V303.175A5.181,5.181,0,0,0,75.265,298Zm3.7,27.351a3.7,3.7,0,0,1-3.7,3.7H47.175a3.7,3.7,0,0,1-3.7-3.7v-18.48H76.743a.739.739,0,0,0,0-1.478H43.478v-2.218a3.7,3.7,0,0,1,3.7-3.7h28.09a3.7,3.7,0,0,1,3.7,3.7Z"
        transform="translate(-42 -298)"
        fill="currentColor"
      />
      <g transform="translate(10.349 10.349)">
        <path
          className="a"
          d="M64.871,329.741a8.871,8.871,0,1,1,8.871-8.871A8.88,8.88,0,0,1,64.871,329.741Zm0-16.263a7.392,7.392,0,1,0,7.392,7.392A7.4,7.4,0,0,0,64.871,313.479Z"
          transform="translate(-56 -312)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(14.045 15.523)">
        <path
          className="a"
          d="M64.7,326.392a.737.737,0,0,1-.523-.217l-2.956-2.957a.739.739,0,1,1,1.045-1.045l2.433,2.434,5.392-5.391a.739.739,0,0,1,1.045,1.045l-5.914,5.914A.737.737,0,0,1,64.7,326.392Z"
          transform="translate(-61 -319)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco215;
