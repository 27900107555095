/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-12.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco12 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-12', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="41.396"
      viewBox="0 0 35.482 41.396"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M66.48,480H59.088a.739.739,0,1,0,0,1.478H66.48a.739.739,0,1,0,0-1.478ZM51.7,484.435H48.739a.739.739,0,1,0,0,1.478H51.7a.739.739,0,1,0,0-1.478Zm0,4.435H48.739a.739.739,0,0,0,0,1.478H51.7a.739.739,0,0,0,0-1.478Zm0,4.435H48.739a.739.739,0,0,0,0,1.478H51.7a.739.739,0,0,0,0-1.478Zm0,4.435H48.739a.739.739,0,1,0,0,1.478H51.7a.739.739,0,1,0,0-1.478Zm22.177-11.828h2.957a.739.739,0,1,0,0-1.478H73.873a.739.739,0,1,0,0,1.478Zm2.957,2.957H73.873a.739.739,0,1,0,0,1.478h2.957a.739.739,0,0,0,0-1.478Zm0,4.435H73.873a.739.739,0,1,0,0,1.478h2.957a.739.739,0,0,0,0-1.478Zm0,4.435H73.873a.739.739,0,1,0,0,1.478h2.957a.739.739,0,1,0,0-1.478ZM66.48,484.435H59.088a.739.739,0,1,0,0,1.478H66.48a.739.739,0,1,0,0-1.478Zm0,4.435H59.088a.739.739,0,0,0,0,1.478H66.48a.739.739,0,0,0,0-1.478Zm0,4.435H59.088a.739.739,0,0,0,0,1.478H66.48a.739.739,0,0,0,0-1.478Zm0,4.435H59.088a.739.739,0,1,0,0,1.478H66.48a.739.739,0,1,0,0-1.478Z"
          transform="translate(-45.043 -465.216)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M78.743,474.784H70.612V469.61a.739.739,0,0,0-.739-.739h-3.7v-3.7a.739.739,0,0,0-.739-.739H62.48v-3.7a.739.739,0,0,0-1.478,0v3.7H58.045a.739.739,0,0,0-.739.739v3.7h-3.7a.739.739,0,0,0-.739.739v5.174H44.739a.739.739,0,0,0-.739.739v20.7a.739.739,0,1,0,1.478,0V476.263h7.392v24.394a.739.739,0,1,0,1.478,0V470.349H69.133v30.308a.739.739,0,1,0,1.478,0V476.263H78v19.959a.739.739,0,1,0,1.478,0v-20.7A.739.739,0,0,0,78.743,474.784ZM64.7,468.871H58.784v-2.957H64.7Zm-.739,28.09H59.524a.739.739,0,0,0-.739.739v2.957a.739.739,0,1,0,1.478,0v-2.218H63.22v2.218a.739.739,0,1,0,1.478,0V497.7A.739.739,0,0,0,63.959,496.961Z"
          transform="translate(-44 -460)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco12;
