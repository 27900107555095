/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-64.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina64 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-64', className].join(' ');
  return (
    <svg
      id="disciplina_triathlon"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M19.622 4.711c.123-1.094-.653-2.081-1.733-2.204a1.974 1.974 0 0 0-2.18 1.753c-.122 1.094.653 2.081 1.733 2.204a1.974 1.974 0 0 0 2.18-1.753Zm-11.4 17.03 3.614-6.205c.108.084 2.111.962 2.891 1.493-.81.49-1.62.986-2.43 1.477-1.34.815-.117 2.936 1.228 2.12 1.252-.76 4.148-2.606 4.236-2.807.378-.437.467-1.08-.093-1.576a15.094 15.094 0 0 0-3.068-2.11 107.551 107.551 0 0 1 2.337-4.321c2.601.805 5.076-.147 6.838-2.641.913-1.291-1.193-2.514-2.096-1.237-1.38 1.958-3.23 2.184-5.218.986-.138-.083-.737-.417-.87-.495-3.2-1.92-6.396-1.169-8.541 1.87-.914 1.291 1.193 2.514 2.096 1.237 1.11-1.57 2.513-2.022 4.055-1.527-.79 1.385-5.548 9.868-7.074 12.494-.796 1.37 1.306 2.607 2.096 1.242ZM2.937 3.572a1.286 1.286 0 1 1 0-2.572 1.286 1.286 0 0 1 0 2.572ZM2.572 5.86A1.286 1.286 0 1 1 0 5.86a1.286 1.286 0 0 1 2.572 0Zm3.304.015a1.286 1.286 0 1 1-2.572 0 1.286 1.286 0 0 1 2.572 0Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina64;
