/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\deposit.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Deposit = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-deposit', className].join(' ');
  return (
    <svg
      id="deposit"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 32"
      fill="none"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M21.4997 14.6641C21.8775 14.6641 22.1941 14.5363 22.4497 14.2807C22.7052 14.0252 22.833 13.7085 22.833 13.3307C22.833 12.953 22.7052 12.6363 22.4497 12.3807C22.1941 12.1252 21.8775 11.9974 21.4997 11.9974C21.1219 11.9974 20.8052 12.1252 20.5497 12.3807C20.2941 12.6363 20.1663 12.953 20.1663 13.3307C20.1663 13.7085 20.2941 14.0252 20.5497 14.2807C20.8052 14.5363 21.1219 14.6641 21.4997 14.6641ZM10.833 11.3307H17.4997V9.33073H10.833V11.3307ZM6.16634 27.9974C5.41079 25.4641 4.66634 22.9363 3.93301 20.4141C3.19967 17.8918 2.83301 15.3085 2.83301 12.6641C2.83301 10.6196 3.54412 8.88629 4.96634 7.46406C6.38856 6.04184 8.1219 5.33073 10.1663 5.33073H16.833C17.4775 4.48628 18.2608 3.83073 19.183 3.36406C20.1052 2.8974 21.0997 2.66406 22.1663 2.66406C22.7219 2.66406 23.1941 2.85851 23.583 3.2474C23.9719 3.63628 24.1663 4.10851 24.1663 4.66406C24.1663 4.7974 24.1497 4.93073 24.1163 5.06406C24.083 5.1974 24.0441 5.31962 23.9997 5.43073C23.9108 5.67517 23.8275 5.92517 23.7497 6.18073C23.6719 6.43629 23.6108 6.6974 23.5663 6.96406L26.5997 9.9974H29.4997V19.2974L25.733 20.5307L23.4997 27.9974H16.1663V25.3307H13.4997V27.9974H6.16634ZM7.66634 25.9974H11.4997V23.3307H18.1663V25.9974H21.9997L24.0997 18.9974L27.4997 17.8307V11.9974H25.7663L21.4997 7.73073C21.5219 7.17517 21.5941 6.63628 21.7163 6.11406C21.8386 5.59184 21.9663 5.06406 22.0997 4.53073C21.2552 4.75295 20.4552 5.08073 19.6997 5.51406C18.9441 5.9474 18.3663 6.55295 17.9663 7.33073H10.1663C8.69332 7.33073 7.43617 7.85136 6.39491 8.89263C5.35364 9.9339 4.83301 11.191 4.83301 12.6641C4.83301 14.9565 5.15523 17.1962 5.79967 19.3834C6.44412 21.5705 7.06634 23.7752 7.66634 25.9974Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Deposit;
