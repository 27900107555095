/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-37.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina37 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-37', className].join(' ');
  return (
    <svg
      id="disciplina_cinematografia"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M22.162 10.35H5.902L21.11 4.81a.558.558 0 0 0 .331-.713l-.992-2.733a.555.555 0 0 0-.713-.331L2.366 7.359a.559.559 0 0 0-.33.713l.992 2.729a.579.579 0 0 0 .073.133v11.507A.559.559 0 0 0 3.66 23h18.506a.559.559 0 0 0 .56-.559V10.904a.562.562 0 0 0-.564-.554Zm-2.29-7.314.335.92-.52.189.185-1.109Zm-4.15.645 2.156-.786-.34 2.028-2.156.786.34-2.028Zm-4.306 1.568 2.152-.786-.34 2.028-2.152.786.34-2.028ZM7.114 6.818l2.153-.787-.34 2.029-2.152.786.34-2.028ZM4.962 7.6l-.336 2.028-.739.27-.614-1.684L4.962 7.6Zm.064 5.655h-.79v-1.792h1.8l-1.01 1.792Zm4.58 0H7.313l1.014-1.792h2.295l-1.014 1.792Zm4.581 0h-2.294l1.014-1.792h2.29l-1.01 1.792Zm4.58 0h-2.294l1.014-1.792h2.295l-1.014 1.792Zm2.84 0h-.553l.554-.98v.98Z"
      />
    </svg>
  );
};
export default Disciplina37;
