import { createAsyncThunk } from '@reduxjs/toolkit';

export type LocationState = {
  pathname: string;
  isOnline: boolean;
  isLoading: boolean;
};

export const navigate = createAsyncThunk('navigate', async (pathname: string) => {
  return Promise.resolve(pathname);
});
