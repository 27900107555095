/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-378.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco378 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-378', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="38.439"
      viewBox="0 0 41.396 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M295.7,218.871h8.871a.739.739,0,0,0,0-1.478H295.7a.739.739,0,0,0,0,1.478Zm34,20.7h-8.871a.739.739,0,1,0,0,1.478H329.7a.739.739,0,0,0,0-1.478Zm3.386-22.039-10.349-7.392a.739.739,0,0,0-1.169.6v3.7H300.131a.739.739,0,0,0,0,1.478h22.177a.739.739,0,0,0,.739-.739v-3l8.338,5.956-8.338,5.956v-3a.739.739,0,0,0-.739-.739H297.914a1.48,1.48,0,0,0-1.478,1.478V236.76l-4.126,2.947a.739.739,0,0,0,0,1.2l10.349,7.392a.739.739,0,0,0,1.169-.6V244h21.437a.739.739,0,0,0,0-1.478H303.088a.739.739,0,0,0-.739.739v3l-8.338-5.956,8.338-5.956v3a.739.739,0,0,0,.739.739h24.394a1.48,1.48,0,0,0,1.478-1.478V221.68l4.126-2.947a.739.739,0,0,0,0-1.2Zm-35.173,4.3h3.63a4.441,4.441,0,0,1-3.63,3.63Zm29.569,14.784h-3.63a4.442,4.442,0,0,1,3.63-3.63Zm0-5.123a5.918,5.918,0,0,0-5.124,5.123H303.827v-3.7a.739.739,0,0,0-1.169-.6l-4.745,3.389v-8.752a5.918,5.918,0,0,0,5.123-5.124h18.532v3.7a.739.739,0,0,0,1.169.6l4.745-3.389ZM312.7,223.306a5.914,5.914,0,1,0,5.914,5.914A5.921,5.921,0,0,0,312.7,223.306Zm0,10.349a4.435,4.435,0,1,1,4.435-4.435A4.44,4.44,0,0,1,312.7,233.655Z"
        transform="translate(-292 -210)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco378;
