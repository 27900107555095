/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-420.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco420 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-420', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M161.482,479.816v-3.771a14.045,14.045,0,0,0-28.09,0v3.771a3.7,3.7,0,0,0-2.957,3.621v1.478a3.694,3.694,0,0,0,3.074,3.633,2.213,2.213,0,0,0,2.1,1.541h1.478a2.22,2.22,0,0,0,2.218-2.218V480.48a2.22,2.22,0,0,0-2.218-2.218H135.61a2.193,2.193,0,0,0-.739.136v-2.354a12.567,12.567,0,0,1,25.133,0V478.4a2.193,2.193,0,0,0-.739-.136h-1.478a2.22,2.22,0,0,0-2.218,2.218v7.392a2.22,2.22,0,0,0,2.218,2.218h1.478a2.219,2.219,0,0,0,.527-.07c-.786,4.046-3.734,7.119-7.327,7.427a2.222,2.222,0,1,0,.022,1.478c4.82-.354,8.677-4.817,8.966-10.385a3.7,3.7,0,0,0,2.986-3.624v-1.478A3.7,3.7,0,0,0,161.482,479.816ZM133.392,487a2.214,2.214,0,0,1-1.478-2.082v-1.478a2.214,2.214,0,0,1,1.478-2.082Zm2.218-7.256h1.478a.74.74,0,0,1,.739.739v7.392a.74.74,0,0,1-.739.739H135.61a.74.74,0,0,1-.739-.739V480.48A.74.74,0,0,1,135.61,479.741Zm14.784,19.22a.739.739,0,1,1,.739-.739A.74.74,0,0,1,150.394,498.961ZM160,487.873a.74.74,0,0,1-.739.739h-1.478a.74.74,0,0,1-.739-.739V480.48a.74.74,0,0,1,.739-.739h1.478a.74.74,0,0,1,.739.739Zm2.957-2.957A2.215,2.215,0,0,1,161.482,487v-5.642a2.215,2.215,0,0,1,1.478,2.082Zm-10.349,5.914V477.524a3.7,3.7,0,0,0-3.7-3.7H137.088a.739.739,0,1,0,0,1.478h11.827a2.22,2.22,0,0,1,2.218,2.218v13.306a2.22,2.22,0,0,1-2.218,2.218H137.088a.739.739,0,0,0-.739.739v3.39l-3.913-3.913a.738.738,0,0,0-.523-.217H129.7a2.22,2.22,0,0,1-2.218-2.218V477.524a2.22,2.22,0,0,1,2.218-2.218h1.478a.739.739,0,1,0,0-1.478H129.7a3.7,3.7,0,0,0-3.7,3.7v13.306a3.7,3.7,0,0,0,3.7,3.7h1.912l4.958,4.958a.74.74,0,0,0,.523.217.731.731,0,0,0,.283-.056.74.74,0,0,0,.456-.683v-4.435h11.088A3.7,3.7,0,0,0,152.612,490.829Z"
        transform="translate(-126 -462)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco420;
