/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-186.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco186 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-186', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="35.482"
      viewBox="0 0 38.439 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M81.266,408.477a3.068,3.068,0,0,0-3.7-.644l-4.847,2.583a2.918,2.918,0,0,0-2.544-1.46H64.128a2.136,2.136,0,0,1-1.5-.593A9.3,9.3,0,0,0,56.131,406a10,10,0,0,0-5.039,1.288,1.563,1.563,0,0,1-.785.19H48.739a.739.739,0,0,0,0,1.478h1.569a3.047,3.047,0,0,0,1.529-.39,8.524,8.524,0,0,1,4.295-1.088,7.937,7.937,0,0,1,5.46,1.944,3.6,3.6,0,0,0,2.536,1.013h6.049a1.479,1.479,0,0,1,1.476,1.559,1.566,1.566,0,0,1-1.6,1.4H59.088a.739.739,0,1,0,0,1.478H70.051a3.021,3.021,0,0,0,3.078-2.8c0-.065-.008-.128-.009-.192l5.132-2.736a1.57,1.57,0,0,1,1.895.3,1.508,1.508,0,0,1-.324,2.274l-14.169,8.51a3.7,3.7,0,0,1-2.917.431l-11.23-3.944a8.117,8.117,0,0,0-2.41-.365h-.358a.739.739,0,0,0,0,1.478H49.1a6.607,6.607,0,0,1,1.946.291l11.248,3.949a5.18,5.18,0,0,0,4.134-.577l14.17-8.51a2.987,2.987,0,0,0,.671-4.5Z"
        transform="translate(-43.565 -386.78)"
        fill="currentColor"
      />
      <g transform="translate(17.002 0)">
        <path
          className="a"
          d="M71.653,388.871H65.739a.739.739,0,0,1-.739-.739v-7.392a.739.739,0,0,1,1.478,0v6.653h4.435v-6.653a.739.739,0,0,1,1.478,0v7.392A.739.739,0,0,1,71.653,388.871Z"
          transform="translate(-65 -380)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(7.392 0)">
        <path
          className="a"
          d="M77.873,403.024a.739.739,0,0,1-.739-.739V382.957a1.48,1.48,0,0,0-1.478-1.478h-20.7a1.48,1.48,0,0,0-1.478,1.478v18.066a.739.739,0,1,1-1.478,0V382.957A2.96,2.96,0,0,1,54.957,380h20.7a2.96,2.96,0,0,1,2.957,2.957v19.328A.739.739,0,0,1,77.873,403.024Z"
          transform="translate(-52 -380)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(23.655 16.263)">
        <path
          className="a"
          d="M80.653,403.478H74.739a.739.739,0,1,1,0-1.478h5.914a.739.739,0,1,1,0,1.478Z"
          transform="translate(-74 -402)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(26.612 19.22)">
        <path
          className="a"
          d="M81.7,407.478H78.739a.739.739,0,1,1,0-1.478H81.7a.739.739,0,1,1,0,1.478Z"
          transform="translate(-78 -406)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(0 19.22)">
        <path
          className="a"
          d="M47.175,420.784H44.218a.739.739,0,0,1,0-1.479h2.218V407.478h-3.7a.739.739,0,1,1,0-1.478h4.435a.739.739,0,0,1,.739.739v13.306A.739.739,0,0,1,47.175,420.784Z"
          transform="translate(-42 -406)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco186;
