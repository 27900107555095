/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-65.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina65 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-65', className].join(' ');
  return (
    <svg
      id="disciplina_tuffi"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M4.538 22.891a1.468 1.468 0 0 1 2.074 0 3.062 3.062 0 0 0 4.329 0 1.468 1.468 0 0 1 2.074 0 3.065 3.065 0 0 0 4.329 0 1.468 1.468 0 0 1 2.075 0 3.065 3.065 0 0 0 4.328 0 .794.794 0 0 0-1.122-1.122 1.47 1.47 0 0 1-2.075 0 3.065 3.065 0 0 0-4.328 0 1.469 1.469 0 0 1-2.08 0 3.062 3.062 0 0 0-4.328 0 1.47 1.47 0 0 1-2.075 0 3.065 3.065 0 0 0-4.328 0 1.47 1.47 0 0 1-2.075 0 .794.794 0 0 0-1.121 1.122 3.059 3.059 0 0 0 4.323 0Zm10.11-14.853c-.91.375-1.79.713-2.648 1.101-1.152.52-2.286 1.078-3.428 1.623a1.215 1.215 0 0 1-1.626-.549c-.281-.565-.047-1.255.542-1.58.249-.138.502-.265.794-.418-.339-.531-.647-1.071-1.01-1.572a167.81 167.81 0 0 0-2.395-3.196c-.444-.582-.383-1.296.184-1.81.476-.43 1.277-.421 1.708.108a77.268 77.268 0 0 1 2.404 3.15c.439.603.82 1.247 1.219 1.876.23.365.218.359.624.196 1.461-.584 2.92-1.174 4.393-1.732.482-.182.993-.333 1.502-.386.622-.065 1.092.316 1.303.911.474 1.336.844 2.705 1.02 4.106.13 1.045.17 2.098.123 3.15-.054 1.177-.394 2.304-1.14 3.252-.748.947-1.715 1.57-2.949 1.711-1.353.155-2.25-.536-2.839-1.637-.54-1.006-.947-2.085-1.433-3.123-.348-.744-.729-1.473-1.116-2.25l2.344-1.108c.606 1.302 1.205 2.576 1.79 3.855.188.412.356.462.704.148.362-.328.457-.778.496-1.23.094-1.095-.042-2.176-.281-3.246-.093-.431-.183-.863-.286-1.35ZM14 0c-1.3-.02-2.462 1.055-2.438 2.408.027 1.423 1.052 2.41 2.426 2.41 1.353 0 2.36-1.057 2.39-2.416C16.382 1.102 15.293.022 14 0Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina65;
