import { HubConnection } from '@microsoft/signalr';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

/* eslint-disable no-unused-vars */
export enum signalRs {
  sport = 'sport',
  ippica = 'ippica',
  ticket = 'ticket',
  liveNav = 'liveNav',
}
/* eslint-enable no-unused-vars */

const initialState = {} as Record<signalRs, HubConnection>;

export interface SetSignalRConnectionType {
  key: signalRs;
  connection?: HubConnection;
}

const signalRSlice = createSlice({
  name: 'signalR',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSignalRConnection: (state, action: PayloadAction<SetSignalRConnectionType>) => {
      const { key, connection } = action.payload;
      if (connection) {
        state[key] = connection;
      } else {
        delete state[key];
      }
    },
  },
});

export const { setSignalRConnection } = signalRSlice.actions;

export default signalRSlice.reducer;
