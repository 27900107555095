/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\smart-toy.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const SmartToy = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-smart-toy', className].join(' ');
  return (
    <svg
      id="smart-toy"
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M2.66699 8.9987C2.11144 8.9987 1.63921 8.80425 1.25033 8.41537C0.861437 8.02648 0.666992 7.55425 0.666992 6.9987C0.666992 6.44314 0.861437 5.97092 1.25033 5.58203C1.63921 5.19314 2.11144 4.9987 2.66699 4.9987V3.66536C2.66699 3.2987 2.79766 2.98492 3.05899 2.72403C3.31988 2.4627 3.63366 2.33203 4.00033 2.33203H6.00033C6.00033 1.77648 6.19477 1.30425 6.58366 0.915365C6.97255 0.526476 7.44477 0.332031 8.00033 0.332031C8.55588 0.332031 9.0281 0.526476 9.41699 0.915365C9.80588 1.30425 10.0003 1.77648 10.0003 2.33203H12.0003C12.367 2.33203 12.681 2.4627 12.9423 2.72403C13.2032 2.98492 13.3337 3.2987 13.3337 3.66536V4.9987C13.8892 4.9987 14.3614 5.19314 14.7503 5.58203C15.1392 5.97092 15.3337 6.44314 15.3337 6.9987C15.3337 7.55425 15.1392 8.02648 14.7503 8.41537C14.3614 8.80425 13.8892 8.9987 13.3337 8.9987V11.6654C13.3337 12.032 13.2032 12.346 12.9423 12.6074C12.681 12.8683 12.367 12.9987 12.0003 12.9987H4.00033C3.63366 12.9987 3.31988 12.8683 3.05899 12.6074C2.79766 12.346 2.66699 12.032 2.66699 11.6654V8.9987ZM6.00033 7.66536C6.2781 7.66536 6.5141 7.56803 6.70833 7.37336C6.90299 7.17914 7.00033 6.94314 7.00033 6.66536C7.00033 6.38759 6.90299 6.15159 6.70833 5.95736C6.5141 5.7627 6.2781 5.66536 6.00033 5.66536C5.72255 5.66536 5.48655 5.7627 5.29233 5.95736C5.09766 6.15159 5.00033 6.38759 5.00033 6.66536C5.00033 6.94314 5.09766 7.17914 5.29233 7.37336C5.48655 7.56803 5.72255 7.66536 6.00033 7.66536ZM10.0003 7.66536C10.2781 7.66536 10.5141 7.56803 10.7083 7.37336C10.903 7.17914 11.0003 6.94314 11.0003 6.66536C11.0003 6.38759 10.903 6.15159 10.7083 5.95736C10.5141 5.7627 10.2781 5.66536 10.0003 5.66536C9.72255 5.66536 9.48655 5.7627 9.29233 5.95736C9.09766 6.15159 9.00033 6.38759 9.00033 6.66536C9.00033 6.94314 9.09766 7.17914 9.29233 7.37336C9.48655 7.56803 9.72255 7.66536 10.0003 7.66536ZM5.33366 10.332H10.667V8.9987H5.33366V10.332ZM4.00033 11.6654H12.0003V3.66536H4.00033V11.6654Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
    </svg>
  );
};
export default SmartToy;
