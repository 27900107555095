/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-167.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco167 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-167', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="32.525"
      viewBox="0 0 38.439 32.525"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <g transform="translate(10.349 11.827)">
          <path
            className="a"
            d="M232.871,331.741a8.871,8.871,0,1,1,8.871-8.871A8.881,8.881,0,0,1,232.871,331.741Zm0-16.263a7.392,7.392,0,1,0,7.392,7.392A7.4,7.4,0,0,0,232.871,315.478Z"
            transform="translate(-224 -314)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M246.222,310h-34A2.22,2.22,0,0,0,210,312.218v19.22a2.22,2.22,0,0,0,2.218,2.218h34a2.22,2.22,0,0,0,2.218-2.218v-19.22A2.22,2.22,0,0,0,246.222,310Zm-34.743,7.349a6.662,6.662,0,0,0,5.87-5.87h23.742a6.662,6.662,0,0,0,5.87,5.87v8.958a6.662,6.662,0,0,0-5.87,5.87H217.349a6.662,6.662,0,0,0-5.87-5.87Zm35.482-5.131v3.637a5.178,5.178,0,0,1-4.376-4.376h3.637A.74.74,0,0,1,246.961,312.218Zm-34.743-.739h3.637a5.178,5.178,0,0,1-4.376,4.376v-3.637A.74.74,0,0,1,212.218,311.478Zm-.739,19.959V327.8a5.178,5.178,0,0,1,4.376,4.376h-3.637A.74.74,0,0,1,211.478,331.437Zm34.743.739h-3.637a5.178,5.178,0,0,1,4.376-4.376v3.637A.74.74,0,0,1,246.222,332.177Z"
          transform="translate(-210 -301.13)"
          fill="currentColor"
        />
        <g transform="translate(2.957 4.435)">
          <path
            className="a"
            d="M245.786,305.478H214.739a.739.739,0,1,1,0-1.478h31.047a.739.739,0,1,1,0,1.478Z"
            transform="translate(-214 -304)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(5.914 0)">
          <path
            className="a"
            d="M243.873,299.478H218.739a.739.739,0,1,1,0-1.478h25.133a.739.739,0,1,1,0,1.478Z"
            transform="translate(-218 -298)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M235.435,322.914h-1.478a1.478,1.478,0,0,1,0-2.957h1.478a1.48,1.48,0,0,1,1.478,1.478.739.739,0,1,0,1.478,0,2.96,2.96,0,0,0-2.957-2.957v-.739a.739.739,0,1,0-1.478,0v.739a2.957,2.957,0,0,0,0,5.914h1.478a1.478,1.478,0,1,1,0,2.957h-1.478a1.48,1.48,0,0,1-1.478-1.479.739.739,0,0,0-1.478,0,2.96,2.96,0,0,0,2.957,2.957v.739a.739.739,0,1,0,1.478,0v-.739a2.957,2.957,0,1,0,0-5.914Z"
          transform="translate(-215.476 -302.955)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco167;
