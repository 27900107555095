/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-382.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco382 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-382', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M137.827,403.133A11.827,11.827,0,1,0,126,391.306,11.841,11.841,0,0,0,137.827,403.133Zm0-22.177a10.349,10.349,0,1,1-10.349,10.349A10.36,10.36,0,0,1,137.827,380.957Zm.739,12.567H135.61a.739.739,0,0,0,0,1.478h1.478v.739a.739.739,0,1,0,1.478,0V395a2.218,2.218,0,0,0,0-4.435h-1.478a.739.739,0,1,1,0-1.478h2.957a.739.739,0,0,0,0-1.478h-1.478v-.739a.739.739,0,1,0-1.478,0v.739a2.218,2.218,0,1,0,0,4.435h1.478a.739.739,0,0,1,0,1.478Zm10.349-14.045h5.914A5.181,5.181,0,0,1,160,384.653v3.472l-1.6-2.4a.739.739,0,1,0-1.23.82l2.957,4.435a.739.739,0,0,0,1.23,0l2.957-4.435a.739.739,0,1,0-1.23-.82l-1.6,2.4v-3.472A6.661,6.661,0,0,0,154.829,378h-5.914a.739.739,0,0,0,0,1.478Zm-7.392,35.482H135.61a5.181,5.181,0,0,1-5.175-5.174v-3.472l1.6,2.4a.739.739,0,1,0,1.23-.821l-2.957-4.435a.768.768,0,0,0-1.23,0l-2.957,4.435a.739.739,0,1,0,1.23.821l1.6-2.4v3.472a6.661,6.661,0,0,0,6.653,6.653h5.914a.739.739,0,0,0,0-1.479Zm14.455-16.139a.738.738,0,0,0-1.025.2l-2.342,3.513-2.342-3.513a.739.739,0,1,0-1.23.821l2.19,3.286h-.836a.739.739,0,1,0,0,1.478h1.478v2.218a.739.739,0,0,0,1.478,0v-2.218h1.478a.739.739,0,1,0,0-1.478h-.837l2.191-3.286A.74.74,0,0,0,155.979,398.822Zm-3.367-7.516c-.28,0-.557.018-.832.039a.739.739,0,1,0,.107,1.474c.24-.017.48-.034.725-.034a10.349,10.349,0,1,1-10.218,11.91.739.739,0,0,0-1.461.221,11.822,11.822,0,1,0,11.68-13.61Z"
        transform="translate(-126 -378)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco382;
