/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\legenda-aperta-dark.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const LegendaApertaDark = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-legenda-aperta-dark', className].join(' ');
  return (
    <svg
      id="legenda-aperta-dark"
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      viewBox="0 0 24 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <path fill="#4DADDD" d="M0 0h24v12H0z" />
        <path
          d="M1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0m1 12V0"
          stroke="#000"
          strokeOpacity=".2"
          strokeWidth=".5"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default LegendaApertaDark;
