import { Esito } from 'lib/api/sport/sportScommesseBySlugResponse';
import { useEffect, useRef, useState } from 'react';

export const useQuotaVariation = (esito?: Esito) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const prevEsito = useRef<Esito>();
  const [isArrowVisible, setIsArrowVisible] = useState(true);
  const [isIncreasing, setIsIncreasing] = useState(false);
  const [isDecreasing, setIsDecreasing] = useState(false);

  useEffect(() => {
    if (prevEsito?.current) {
      setIsIncreasing(Boolean(esito?.isIncreasing));
      setIsDecreasing(Boolean(esito?.isDecreasing));
    }
    setIsArrowVisible(true);
    timeoutRef.current = setTimeout(() => {
      setIsArrowVisible(false);
    }, 5000);
    prevEsito.current = esito;
    return () => {
      if (timeoutRef?.current) clearTimeout(timeoutRef.current);
    };
  }, [esito]);

  return [isArrowVisible, isIncreasing, isDecreasing];
};
