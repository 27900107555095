import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { feedVirtualNav } from './actions';

import { AppFragment } from 'enums/path-fragment';
import { feedVirtualNavPayload, feedVirtualNavProps, VirtualNav } from './types';
import { ScommessaResponse } from 'lib/api/sport/sportScommesseBySlugResponse';
import { decodePathname } from 'features/sport';
import { isMatch, isTruthy } from 'utility/functions';
import { navigate } from 'features/location/types';
import { VirtualTopNavigationItem } from 'types/swagger';

export interface VirtualState {
  ts: number;
  menu: Array<VirtualTopNavigationItem>;
  isLoading?: boolean;

  slug?: string | undefined;
  canale?: string;
  isStarted: boolean;
  isOpenTab: boolean;
  scommessa?: ScommessaResponse;
  currentTime: Date | null;
  indexGiornata: number;
  slugAvvenimento: string;
  isUltimiRisultatiUpdated: boolean;
  ultimiRisultatiPageNumber: number;
  indexGiornataTipoScommessa: number;
  slugDisciplinaUltimiRisultati: string;
}

// Define the initial state using that type
export const initialState: VirtualState = {
  ts: -1,
  menu: [],

  canale: '0',
  isOpenTab: false,
  isStarted: false,
  currentTime: null,
  indexGiornata: 0,
  slugAvvenimento: '',
  isUltimiRisultatiUpdated: false,
  ultimiRisultatiPageNumber: 1,
  indexGiornataTipoScommessa: 0,
  slugDisciplinaUltimiRisultati: '',
};

const _mergeMenu = (st: VirtualState, payload: feedVirtualNavProps | feedVirtualNavPayload) => {
  const { data } = payload ?? {};

  // appy only newst updates
  const nextTs = data?.ts ?? -1;
  if (nextTs > st.ts) {
    st.menu = data.menu ?? [];
    st.ts = nextTs;
  }
};

export const virtualSlice = createSlice({
  name: 'virtual',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload;
    },
    setIsStarted: (state, action) => {
      state.isStarted = action.payload;
    },
    resetIsStarted: (state) => {
      state.isStarted = initialState.isStarted;
    },
    setSlugDisciplinaUltimiRisultati: (state, action) => {
      state.slugDisciplinaUltimiRisultati = action.payload;
    },
    setUltimiRisultati: (state, action) => {
      return {
        ...state,
        isUltimiRisultatiUpdated: action.payload,
      };
    },
    incrementUltimiRisultatiPagination: (state, action: PayloadAction<{ pageNumber: number }>) => {
      return {
        ...state,
        ultimiRisultatiPageNumber: (action.payload.pageNumber += 1),
      };
    },
    decrementUltimiRisultatiPagination: (state, action: PayloadAction<{ pageNumber: number }>) => {
      return {
        ...state,
        ultimiRisultatiPageNumber: (action.payload.pageNumber -= 1),
      };
    },
    resetUltimiRisultatiPagination: (state) => {
      return {
        ...state,
        ultimiRisultatiPageNumber: 1,
      };
    },
    initVirtualScommessaResponse: (state, action: PayloadAction<ScommessaResponse>) => {
      return {
        ...state,
        scommessa: action.payload,
      };
    },
    handleOpenTab: (state, action: PayloadAction<{ value: boolean }>) => {
      state.isOpenTab = action.payload.value;
    },
    updateIndex: (state, action: PayloadAction<{ index: number; name: string }>) => {
      state[`${action.payload.name}`] = action.payload.index;
    },
    setSlug: (state, action: PayloadAction<string | undefined>) => {
      state.slug = decodePathname(action.payload);
    },
    setCanale: (state, action: PayloadAction<string>) => {
      state.canale = action.payload;
    },
    setSlugAvvenimento: (state, action: PayloadAction<string>) => {
      state.slugAvvenimento = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(navigate.fulfilled, (state, { payload }) => {
        const isVirtual =
          payload?.length > 1 && [AppFragment.Virtuali].some((x) => isMatch(payload, `^(\/*)${x}($|\/)`));
        if (isVirtual) return state;
        return initialState;
      })
      .addCase(feedVirtualNav.pending, (state, { meta }) => {
        if (isTruthy(state.isLoading)) return;
        state.isLoading = true;
        // HYDRATION DATA
        const { data } = meta?.arg ?? {};
        data.ts = 0; // force ts to 0 to keep snapshot access until hydration completed
        _mergeMenu(state, { data });
      })
      .addCase(feedVirtualNav.fulfilled, (state, { payload }) => {
        delete state.isLoading;
        // FRESH MW RESULTS
        _mergeMenu(state, payload);
      });
  },
});

export const {
  setSlug,
  setCanale,
  updateIndex,
  setIsStarted,
  handleOpenTab,
  setCurrentTime,
  resetIsStarted,
  setSlugAvvenimento,
  initVirtualScommessaResponse,
  resetUltimiRisultatiPagination,
  setSlugDisciplinaUltimiRisultati,
  incrementUltimiRisultatiPagination,
  decrementUltimiRisultatiPagination,
} = virtualSlice.actions;

export default virtualSlice.reducer;
