/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-33.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina33 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-33', className].join(' ');
  return (
    <svg
      id="disciplina_hockey-pista"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M7.282 3A1.956 1.956 0 1 0 7.3 6.913 1.956 1.956 0 0 0 7.282 3ZM4.066 18.416c-.166.295-.007.534.351.534h1.086c.359 0 .65-.239.65-.534v-.01l2.23-4.613a.809.809 0 0 0 .583-.25l2.187-2.26a.63.63 0 0 0 .046-.056l.85.812-2.77 2.513a.975.975 0 0 0-.01 1.435l4.3 4.04a.98.98 0 0 0 1.576-.355.98.98 0 0 0-.237-1.073l-3.53-3.315 1.68-1.526.616 1.955a.944.944 0 0 0 .292.443l4.264 3.705a.976.976 0 1 0 1.283-1.473l-4.053-3.523-.479-1.518V10.32c0-.542-.333-1.26-.745-1.61l-3.132-3.024a.812.812 0 0 0-1.347.615v4.082L7.8 12.408a.813.813 0 0 0-.01 1.118l-2.113 4.377a.888.888 0 0 0-.17-.021h-.488c-.362.003-.787.239-.952.534Zm1.262 1.17a.707.707 0 1 1 0 1.415.707.707 0 0 1 0-1.414Z"
      />
    </svg>
  );
};
export default Disciplina33;
