import {
  SportsManifestazioneMenuItem,
  SportsMenuDisciplinaItem,
  SportsMenuDisciplineWithTranslationsDto,
} from 'types/swagger';

import { ScommessaResponse } from 'lib/api/sport/sportScommesseBySlugResponse';

export const LIVEMENU_KEY = 'menu-live';

export const LIVE_FAVOURITES = 'LiveFavouriteMenu';
export const LIVE_FAVOURITE_DETAILS = 'LiveFavouriteAvvenimento';
export const LIVE_DISCIPLINA_DETAILS = 'LiveDisciplinaDetails';
export const LIVE_MENU_AVVENIMENTI_API = 'LiveMenuAvvenimenti';
export const LIVE_MANIFESTAZIONE_DETAILS = 'LiveManifestazioneDetails';

export interface feedLiveNavProps {
  data: SportsMenuDisciplineWithTranslationsDto;
}
export type feedLiveNavPayload = feedLiveNavProps;

export interface MenuParams {
  isTop?: boolean | undefined;
  isLive?: boolean | undefined;
  hasStreaming?: boolean | undefined;
}

export interface BaseMenuItem extends Pick<SportsManifestazioneMenuItem, 'counter'> {
  id: number | string;
}

export interface DisciplinaMenuItem extends BaseMenuItem {
  isActive: boolean;
}

export interface ExtendedManifestazioneMenuItem extends SportsManifestazioneMenuItem {
  scommesse?: ScommessaResponse;
}

export interface ExtendedDisciplinaMenuItem extends SportsMenuDisciplinaItem {
  manifestazioni?: Record<number, ExtendedManifestazioneMenuItem>;
  customDescription?: string;
}

export interface DisciplinaMenuItem extends Omit<ExtendedDisciplinaMenuItem, 'id' | 'priorita' | 'descrizione'> {
  id: string;
  isActive: boolean;
  customDescription?: string;
}

export interface initDisciplinaPayload {
  id: number;
  slug: string;
  data: Array<ExtendedManifestazioneMenuItem>;
  isLoading: boolean;
}

export interface initManifestazionePayload extends Omit<initDisciplinaPayload, 'data'> {
  idDisciplina: number | string;
  data?: ScommessaResponse;
}

export type LiveNavState = {
  ts: number;
  isLoading: Record<string, boolean>;
  discipline: Record<number, ExtendedDisciplinaMenuItem>;
  favourites: Record<string, ScommessaResponse>;
  expandedItems: Array<string>;
};

export interface MenuActionPayload {
  slug?: string | undefined;
  avvenimentoKey?: string | undefined;
}

export interface LiveNavActionPayload extends MenuActionPayload {
  entryId: string | undefined;
}
