/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-8.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina8 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-8', className].join(' ');
  return (
    <svg
      id="disciplina_motociclismo"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M3.951 10.52a3.948 3.948 0 1 0 3.586 5.596l.937.722s6.889.047 7.268-.164c0 0-.852-2.334.23-3.323a8.15 8.15 0 0 1 .417-.347 3.946 3.946 0 1 0 3.665-2.479c-.037 0-.07.005-.108.005l.469-.3-.295-.262s.853-.197.656-.624c-.197-.426-2.268-2.994-2.268-2.994s.365-.788-.394-1.35l-.366.787s-.928-.642-1.298-.52-.923.183-1.096.455l-.174.159s-.047.286.235.703c0 0 2.849.876 2.985 1.518 0 0 .197.408-.652.507l-.173.22-.455.023-.825-1.888s-.792.21-1.195.53l.061.318s-3.318-2.259-6.439 1.087c0 0-2.305.666-3.922-1.382 0 0-.024-.666-3.923-.544l.272.286s-.272.023-.384.197c-.113.173.06.356.951.356 0 0 2.01 2.296 3.552 2.16l2.194 1.06.974 1.663s0 .145.014.361c-.225.047-.478.103-.75.16a3.957 3.957 0 0 0-3.749-2.695Zm16.103 1.21a2.737 2.737 0 1 1 0 5.473 2.737 2.737 0 0 1 0-5.473ZM3.951 17.208a2.737 2.737 0 1 1 2.55-3.726c-1.453.31-2.892.614-2.892.614l.188 1.022 2.84-.127a2.74 2.74 0 0 1-2.686 2.217Zm4.743-2.306c.08.272.188.493.323.586l-1.27.052c.056-.197.097-.397.122-.6l.825-.038Z"
      />
    </svg>
  );
};
export default Disciplina8;
