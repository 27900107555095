/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-388.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco388 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-388', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.569"
      height="38.439"
      viewBox="0 0 29.569 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M152,473.216a2.2,2.2,0,0,0-.436-3.529c.7-1.312,1.872-3.675,1.872-4.73a2.956,2.956,0,0,0-4.8-2.307,3.05,3.05,0,0,0-3.7,0,2.956,2.956,0,0,0-4.8,2.307c0,1.055,1.175,3.418,1.872,4.73a2.2,2.2,0,0,0-.436,3.529C135.8,477.311,132,483.938,132,490.09a14.74,14.74,0,0,0,.228,2.587,9.5,9.5,0,0,0,9.432,7.762h10.249a9.5,9.5,0,0,0,9.432-7.762,14.814,14.814,0,0,0,.228-2.587C161.569,483.938,157.77,477.311,152,473.216Zm-10.391-8.259a1.477,1.477,0,0,1,2.71-.815.767.767,0,0,0,1.232,0,1.475,1.475,0,0,1,2.464,0,.767.767,0,0,0,1.232,0,1.477,1.477,0,0,1,2.711.815,17.723,17.723,0,0,1-1.917,4.435h-6.515A17.724,17.724,0,0,1,141.61,464.957Zm9.61,6.653a.74.74,0,0,1-.739.739h-7.392a.739.739,0,1,1,0-1.478h7.392A.74.74,0,0,1,151.22,471.61Zm8.667,20.809a8.024,8.024,0,0,1-7.978,6.542H141.66a8.023,8.023,0,0,1-7.978-6.542,13.367,13.367,0,0,1-.2-2.329c0-6,3.94-12.505,9.824-16.263h6.963c5.884,3.757,9.824,10.268,9.824,16.263A13.364,13.364,0,0,1,159.886,492.419Zm-13.841-7.5H149a.739.739,0,1,0,0-1.478h-1.478v-1.479a.739.739,0,0,0-1.478,0v1.479a2.218,2.218,0,0,0,0,4.435h1.478a.739.739,0,1,1,0,1.478h-2.957a.739.739,0,1,0,0,1.478h1.478v1.479a.739.739,0,0,0,1.478,0v-1.479a2.218,2.218,0,1,0,0-4.435h-1.478a.739.739,0,1,1,0-1.478Z"
        transform="translate(-132 -462)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco388;
