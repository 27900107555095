/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-108.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina108 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-108', className].join(' ');
  return (
    <svg
      id="disciplina_esports-heroes-of-the-storm"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M1.037 10.787c.875-7.19 7.164-9.138 7.164-9.138-8.403 8.937 1.651 15.297 1.651 15.297l-6.81 1.487c.05.007-2.369-2.87-2.005-7.646Zm15.31 11.404c-7.22 2.618-11.444-1.955-11.444-1.955C16.71 23.215 17.536 11.3 17.536 11.3l4.534 5.186s-1.29 3.7-5.722 5.706Zm6.448-8.164c-3.608-11.758-13.77-6.16-13.77-6.16l2.166-6.831s4.331-.563 8.25 2.77c4.795 4.161 3.354 10.22 3.354 10.22Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina108;
