/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-94.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina94 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-94', className].join(' ');
  return (
    <svg
      id="disciplina_bowls"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M11.984 1a11.007 11.007 0 0 0-9.927 6.288 10.979 10.979 0 0 0 1.427 11.656h-.043c.32.395.668.766 1.042 1.11h.043a11.205 11.205 0 0 0 2.345 1.67h-.048c1.012.538 2.103.912 3.232 1.11h.032v-.005a11.039 11.039 0 0 0 2.794.139l.155-.016c.032-.005.064-.005.091-.01C18.672 22.37 23 17.688 23 12c0-6.074-4.931-11-11.016-11Zm-9.675 9.185c0-.069.005-.144.01-.213a9.924 9.924 0 0 1 2.727-5.004 9.94 9.94 0 0 1 5.03-2.682c.328.077.638.214.914.406.86.598 1.314 1.697 1.282 3.095-.037 1.479-.614 3.096-1.624 4.542-1.458 2.093-3.585 3.443-5.406 3.443-.63 0-1.186-.165-1.646-.486-.865-.608-1.32-1.708-1.287-3.1Zm.967 6.448a9.887 9.887 0 0 1-1.042-3.101c.209.251.451.472.721.656.652.454 1.421.683 2.281.683 1.074 0 2.228-.363 3.35-1.056 1.122-.694 2.148-1.682 2.976-2.866 1.132-1.623 1.784-3.454 1.822-5.15.037-1.57-.444-2.867-1.363-3.716 1.042 0 2.076.164 3.067.486.322 1.08.484 2.2.48 3.325 0 6.042-4.594 10.958-10.24 10.958-.69 0-1.378-.073-2.052-.22Zm8.745 5.257a9.93 9.93 0 0 1-7.95-3.992c.417.049.837.074 1.256.074 3.04 0 5.898-1.26 8.04-3.549 2.137-2.279 3.313-5.305 3.313-8.518-.001-.942-.103-1.882-.305-2.802 3.286 1.612 5.556 4.99 5.556 8.892 0 5.454-4.445 9.895-9.91 9.895Z"
        fill="currentColor"
      />
      <path
        d="M4.713 11.674c.42.287.918.436 1.426.427 1.325 0 2.762-.886 3.756-2.31.668-.956 1.047-2.029 1.068-3.032.027-1.089-.363-1.964-1.095-2.477a2.46 2.46 0 0 0-1.426-.427c-1.325 0-2.762.886-3.756 2.311-.668.956-1.047 2.029-1.068 3.032-.027 1.089.363 1.97 1.095 2.476ZM5.6 6.802c.78-1.116 1.896-1.836 2.842-1.836.28-.009.554.072.785.229.417.288.636.833.614 1.537-.021.785-.325 1.65-.87 2.423-.78 1.116-1.897 1.836-2.843 1.836a1.325 1.325 0 0 1-.785-.23c-.913-.634-.796-2.449.257-3.96Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina94;
