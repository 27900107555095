/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-jp.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneJp = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-jp', className].join(' ');
  return (
    <svg
      id="nazione-jp"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <mask id="a" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
        <path d="M0 0h16v16H0V0Z" fill="#fff" />
      </mask>
      <g mask="url(#a)">
        <path fillRule="evenodd" clipRule="evenodd" d="M-4 0h24v16H-4V0Z" fill="#fff" />
        <path d="M8 12.975a4.973 4.973 0 1 0 0-9.947 4.973 4.973 0 0 0 0 9.947Z" fill="#D30000" />
      </g>
    </svg>
  );
};
export default NazioneJp;
