/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\smartphone.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Smartphone = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-smartphone', className].join(' ');
  return (
    <svg id="smartphone" viewBox="0 0 14 22" fill="none" focusable="false" className={componentClassName} {...attribs}>
      <path
        d="M2.3 21.5C1.8 21.5 1.375 21.325 1.025 20.975C0.675 20.625 0.5 20.2 0.5 19.7V2.3C0.5 1.8 0.675 1.375 1.025 1.025C1.375 0.675 1.8 0.5 2.3 0.5H11.7C12.2 0.5 12.625 0.675 12.975 1.025C13.325 1.375 13.5 1.8 13.5 2.3V19.7C13.5 20.2 13.325 20.625 12.975 20.975C12.625 21.325 12.2 21.5 11.7 21.5H2.3ZM2 18.75V19.7C2 19.7667 2.03333 19.8333 2.1 19.9C2.16667 19.9667 2.23333 20 2.3 20H11.7C11.7667 20 11.8333 19.9667 11.9 19.9C11.9667 19.8333 12 19.7667 12 19.7V18.75H2ZM2 17.25H12V4.75H2V17.25ZM2 3.25H12V2.3C12 2.23333 11.9667 2.16667 11.9 2.1C11.8333 2.03333 11.7667 2 11.7 2H2.3C2.23333 2 2.16667 2.03333 2.1 2.1C2.03333 2.16667 2 2.23333 2 2.3V3.25ZM2 18.75V20V19.7V18.75Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Smartphone;
