/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\streaming.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Streaming = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-streaming', className].join(' ');
  return (
    <svg
      id="streaming"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M8.544 4 7.275 5.253l2.331 2.303H4.8c-.999 0-1.8.79-1.8 1.777v8.89C3 19.208 3.801 20 4.8 20h14.4c.999 0 1.8-.791 1.8-1.778V9.333c0-.986-.801-1.777-1.8-1.777h-4.806l2.331-2.303L15.456 4 12 7.413 8.544 4ZM4.8 9.333h11.7v8.89H4.8v-8.89Zm13.95 0c.239 0 .468.094.636.26a.883.883 0 0 1 0 1.258.906.906 0 0 1-1.272 0 .883.883 0 0 1 0-1.257.906.906 0 0 1 .636-.26Zm0 2.667c.239 0 .468.094.636.26a.883.883 0 0 1 0 1.257.906.906 0 0 1-1.272 0 .883.883 0 0 1 0-1.257.906.906 0 0 1 .636-.26Z"
      />
    </svg>
  );
};
export default Streaming;
