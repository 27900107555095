/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-1007.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina1007 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-1007', className].join(' ');
  return (
    <svg
      id="disciplina_ippica-galoppo-ev"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M19.7506 10.2796L20.0113 10.4068C20.2901 10.5158 20.6227 10.434 20.8295 10.1888C21.0903 9.8799 21.0453 9.40753 20.7396 9.14409L18.8964 7.36363L18.1592 6.02828L18.0243 7.10019L14.6076 9.6528L14.5267 9.66188L13.9333 9.08959L14.239 8.23569L14.248 8.22661C14.9853 7.80874 14.7605 6.53698 13.7445 6.55515L10.7144 6.8095C10.3458 6.97301 10.0761 7.36363 10.184 7.86325L10.6425 10.0616L7.34275 10.4068C6.47959 10.4068 3 10.8246 3 10.8246V11.8875L7.47761 11.1153V11.7603L6.56051 12.9957L4.15087 16.1842C3.98004 16.3931 3.99802 16.7111 4.20482 16.8837C4.41162 17.0653 4.71732 17.0381 4.89714 16.8292L7.2888 14.3129L7.82827 14.1494L8.23287 14.0767L8.13397 14.322L8.70941 18.3553C8.76336 18.6278 9.0241 18.8004 9.29384 18.7368C9.55458 18.6823 9.72541 18.4189 9.67147 18.1463L9.57256 14.7853L10.8583 13.1411L11.0741 13.0502L14.0861 14.0767L15.0662 14.0222L17.5118 17.892C17.6467 18.1282 17.9434 18.2099 18.1771 18.0737C18.4109 17.9374 18.4918 17.6286 18.357 17.3924L16.8464 14.4401L18.0962 14.9215L19.8675 16.6657C20.0203 16.8928 20.326 16.9473 20.5508 16.7928C20.7756 16.6293 20.8295 16.3295 20.6767 16.1024L18.7436 13.5589L17.0353 12.6596L17.1701 11.2243C17.332 11.2698 19.7506 10.2796 19.7506 10.2796ZM18.4109 9.78906L19.346 10.1524C19.346 10.1524 17.4578 10.8973 17.3499 10.8701L18.4109 9.78906ZM12.1171 9.91623L11.7664 8.32653L13.241 8.35378H13.259L13.0072 9.06234C12.9802 9.14409 12.9802 9.23493 12.9892 9.31669V9.32577C13.0072 9.40753 13.0522 9.48929 13.1151 9.55287L13.3668 9.79814L12.1171 9.91623ZM14.7964 6.73683C14.8684 6.26446 15.3 5.93744 15.7675 6.01011C16.235 6.0737 16.5587 6.51881 16.4868 6.99118C16.4239 7.46355 15.9833 7.79058 15.5157 7.72699C15.0572 7.65432 14.7245 7.21828 14.7964 6.73683Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina1007;
