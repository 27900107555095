/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-110.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina110 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-110', className].join(' ');
  return (
    <svg
      id="disciplina_esports-hearthstone"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="m24 11.939-4.526-2.744s-.206-.8-1.028-1.783l.822-3.02-3.085.961s-1.029-.8-1.577-.892L12 0 9.256 4.665s-.548.252-1.645.686L4.73 4.116l.892 3.225s-.754 1.075-.892 1.92l-4.73 2.677 4.593 2.68s.39 1.212.823 1.785l-1.028 2.949 3.154-.757s1.486 1.053 2.057 1.234l2.331 4.391 2.606-4.39s1.577-.686 2.057-1.03l3.086.824-.96-3.157s.823-1.327 1.097-1.987L24 11.94Zm-8.641 3.98s-1.285 1.064-2.947 1.165c0 0-2.898.378-3.91-.96l2.264-.822s3.445-1.132 3.497-2.335c0 0 1.046-1.94-.412-3.636l-.137-.343-.274.068s-1.509-.514-2.811.823c0 0-.737 1.269.205 2.129 0 0 .515.291.48-.274l-.137-.755s2.092-.993 2.126 2.197c0 0-1.697 1.68-3.498.617 0 0-2.28-2.025-1.85-3.636l-.07-.824L9.392 8.03l1.987-1.165.686.137.617-.275.343.412 1.92.48s1.697 1.75 1.714 2.47c.003-.002 1.237 3.787-1.301 5.83Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina110;
