/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-303.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco303 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-303', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="32.526"
      viewBox="0 0 38.439 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M77.482,466H44.957A2.96,2.96,0,0,0,42,468.957v22.177a2.96,2.96,0,0,0,2.957,2.957H56.651l-.493,2.957H54.567a.739.739,0,0,0,0,1.478H67.873a.739.739,0,0,0,0-1.478H66.281l-.493-2.957H77.482a2.96,2.96,0,0,0,2.957-2.957V468.957A2.96,2.96,0,0,0,77.482,466Zm-32.526,1.478H77.482a1.48,1.48,0,0,1,1.478,1.478v19.22H43.478v-19.22A1.48,1.48,0,0,1,44.957,467.478Zm19.826,29.569H57.657l.493-2.957h6.14Zm12.7-4.435H44.957a1.48,1.48,0,0,1-1.478-1.478v-1.479H78.961v1.479A1.48,1.48,0,0,1,77.482,492.612Z"
        transform="translate(-42 -466)"
        fill="currentColor"
      />
      <g transform="translate(11.827 5.914)">
        <path
          className="a"
          d="M63.914,485.827a.737.737,0,0,1-.523-.217l-5.175-5.175a.738.738,0,0,1,0-1.045l2.218-2.218a.739.739,0,0,1,1.045,0l2.434,2.434,5.391-5.391a.738.738,0,0,1,1.045,0l2.218,2.218a.738.738,0,0,1,0,1.045l-8.131,8.131A.737.737,0,0,1,63.914,485.827Zm-4.129-5.914,4.129,4.129L71,476.957l-1.172-1.172-5.391,5.391a.738.738,0,0,1-1.045,0l-2.434-2.434Z"
          transform="translate(-58 -474)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco303;
