/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-22.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina22 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-22', className].join(' ');
  return (
    <svg
      id="disciplina_bob"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M8.826 15.745H2.485a.638.638 0 0 0-.448.183.621.621 0 0 0 0 .884c.119.117.28.183.448.183H8.83a.638.638 0 0 0 .448-.183.622.622 0 0 0 .185-.442.629.629 0 0 0-.189-.441.645.645 0 0 0-.449-.184ZM20.881 17a.638.638 0 0 0 .448-.183.622.622 0 0 0 0-.884.637.637 0 0 0-.448-.183h-6.342a.637.637 0 0 0-.447.183.621.621 0 0 0 0 .884c.118.117.28.183.447.183h6.342ZM2.33 14.898h18.747c.775 0 1.518-.305 2.066-.846a2.867 2.867 0 0 0 .856-2.04V10.93c0-.765-.308-1.499-.856-2.04a2.941 2.941 0 0 0-2.066-.845h-.723v1.232c0 .553-.223 1.085-.62 1.476a2.13 2.13 0 0 1-1.495.612h-6.98a2.13 2.13 0 0 1-1.495-.612 2.076 2.076 0 0 1-.62-1.476V8.044H7.673c-1.614 0-4.137.542-5.357 1.588-.75.635-1.524 1.473-2.087 2.52-.76 1.403.483 2.746 2.101 2.746ZM11.8 9.39l-1.844-.213c.197.524.576.962 1.07 1.235.494.274 1.07.365 1.626.257a2.393 2.393 0 0 0 1.408-.843 2.338 2.338 0 0 0-.095-3.05 2.412 2.412 0 0 0-3.064-.401 2.361 2.361 0 0 0-.992 1.297l2.087.227c0-.004.516.764-.197 1.491Zm5.205 0-1.843-.213c.197.524.577.962 1.07 1.235.495.274 1.071.365 1.627.257a2.393 2.393 0 0 0 1.407-.843 2.338 2.338 0 0 0-.095-3.05 2.412 2.412 0 0 0-3.064-.401 2.36 2.36 0 0 0-.992 1.297l2.087.227c0-.004.517.764-.197 1.491Z"
      />
    </svg>
  );
};
export default Disciplina22;
