/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-1028.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina1028 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-1028', className].join(' ');
  return (
    <svg
      id="disciplina_campionato-ev"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="m12.7469 9.99338 4.113-.16775-3.229 2.45107 1.398 3.7372-3.4455-2.4417-2.97656 2.6747 1.03728-3.9702-3.193-2.1901 3.85148-.0653 1.2717-4.24044 1.1726 4.21252Zm4.1942-.20503 2.5977.57785.0361 3.877.9471-3.5042.478.9413s-.3969-3.57882-1.4071-4.4642l-.1804.14912-2.9585-2.48838 2.2279 3.29919-1.7408 1.61232Zm-5.3577-4.14729 1.0372-1.69619 3.7162.8481-2.7691-1.5564-.009-.0932s-1.9032-.37279-3.65301.08388L7.10956 4.9514l3.47264-1.04381 1.0012 1.73347ZM6.45112 17.8592 4.0699 14.7278l1.28983 3.4856-.36079-.0186s.28863.5219 1.39806 1.4259C7.50643 20.5247 8.8594 21 8.8594 21l-.48707-.6337 3.48167.0932-3.64403-1.4353.3157-2.6375-2.07455 1.4725Zm-.1353-7.8472L4.64717 8.60474l2.27298-3.53218-2.59769 2.52565S3.20401 9.11733 3.00557 11.5964c-.0451.5871.19844-.1212.19844-.1212l.86589 3.0755v-3.7931l2.24592-.7456Zm8.79428 6.2349.4961 2.7494-3.4997 1.4538 3.4997-.3168-.0902.7455s2.3-.9413 3.8244-3.0848l-.7487-.2889.9471-3.0755-2.1016 3.0755-2.3271-1.2582Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina1028;
