/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-248.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco248 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-248', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.569"
      height="38.439"
      viewBox="0 0 29.569 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M413.511,219.325a.736.736,0,0,0-.16-.239l-8.869-8.869a.741.741,0,0,0-.239-.16.73.73,0,0,0-.285-.058H387.7a3.7,3.7,0,0,0-3.7,3.7v31.047a3.7,3.7,0,0,0,3.7,3.7h22.177a3.7,3.7,0,0,0,3.7-3.7V219.61A.733.733,0,0,0,413.511,219.325Zm-8.813-6.8,6.347,6.347h-4.869a1.48,1.48,0,0,1-1.478-1.478Zm5.175,34.437H387.7a2.22,2.22,0,0,1-2.218-2.218V213.7a2.22,2.22,0,0,1,2.218-2.218H403.22v5.914a2.96,2.96,0,0,0,2.957,2.957h5.914v24.394A2.22,2.22,0,0,1,409.873,246.961Z"
        transform="translate(-384 -210)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M404.957,245.478a1.478,1.478,0,1,0-2.218,1.273v.945a.739.739,0,1,0,1.478,0v-.945A1.474,1.474,0,0,0,404.957,245.478Z"
        transform="translate(-388.694 -218.867)"
        fill="currentColor"
      />
      <g transform="translate(5.914 20.698)">
        <path
          className="a"
          d="M406.784,251.306H394.957A2.96,2.96,0,0,1,392,248.349v-7.392A2.96,2.96,0,0,1,394.957,238h11.827a2.96,2.96,0,0,1,2.957,2.957v7.392A2.96,2.96,0,0,1,406.784,251.306Zm-11.827-11.827a1.48,1.48,0,0,0-1.478,1.478v7.392a1.48,1.48,0,0,0,1.478,1.478h11.827a1.48,1.48,0,0,0,1.478-1.478v-7.392a1.48,1.48,0,0,0-1.478-1.478Z"
          transform="translate(-392 -238)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(8.871 11.827)">
        <path
          className="a"
          d="M407.088,236.349a.739.739,0,0,1-.739-.739v-3.7a4.435,4.435,0,0,0-8.871,0v3.7a.739.739,0,0,1-1.478,0v-3.7a5.914,5.914,0,0,1,11.827,0v3.7A.739.739,0,0,1,407.088,236.349Z"
          transform="translate(-396 -226)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco248;
