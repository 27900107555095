/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-238.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco238 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-238', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="35.482"
      viewBox="0 0 38.439 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M497.482,216h-8.131a.739.739,0,1,0,0,1.478h8.131a1.48,1.48,0,0,1,1.478,1.478v19.22H463.478v-19.22a1.48,1.48,0,0,1,1.479-1.478h8.131a.739.739,0,1,0,0-1.478h-8.131A2.96,2.96,0,0,0,462,218.957v22.177a2.96,2.96,0,0,0,2.957,2.957h11.694l-.493,2.957h-1.592a.739.739,0,0,0,0,1.478h13.306a.739.739,0,0,0,0-1.478h-1.592l-.493-2.957h11.694a2.96,2.96,0,0,0,2.957-2.957V218.957A2.96,2.96,0,0,0,497.482,216Zm-12.7,31.047h-7.126l.493-2.957h6.14Zm12.7-4.435H464.957a1.48,1.48,0,0,1-1.479-1.478v-1.478h35.482v1.478A1.48,1.48,0,0,1,497.482,242.612Z"
        transform="translate(-462 -213.043)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M488.957,231.478a1.478,1.478,0,1,0-2.218,1.273v.945a.739.739,0,1,0,1.478,0v-.945A1.474,1.474,0,0,0,488.957,231.478Z"
        transform="translate(-468.259 -216.694)"
        fill="currentColor"
      />
      <g transform="translate(10.349 8.871)">
        <path
          className="a"
          d="M490.784,237.306H478.957A2.96,2.96,0,0,1,476,234.349v-7.392A2.96,2.96,0,0,1,478.957,224h11.828a2.96,2.96,0,0,1,2.957,2.957v7.392A2.96,2.96,0,0,1,490.784,237.306Zm-11.828-11.827a1.48,1.48,0,0,0-1.478,1.478v7.392a1.48,1.48,0,0,0,1.478,1.478h11.828a1.48,1.48,0,0,0,1.478-1.478v-7.392a1.48,1.48,0,0,0-1.478-1.478Z"
          transform="translate(-476 -224)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(13.306 0)">
        <path
          className="a"
          d="M491.088,222.349a.739.739,0,0,1-.739-.739v-3.7a4.435,4.435,0,1,0-8.871,0v3.7a.739.739,0,1,1-1.478,0v-3.7a5.914,5.914,0,0,1,11.827,0v3.7A.739.739,0,0,1,491.088,222.349Z"
          transform="translate(-480 -212)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco238;
