/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-7.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina7 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-7', className].join(' ');
  return (
    <svg
      id="disciplina_automobilismo"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M13.622 1c-3.858.703-7.331 2.76-11.972.684.06.211.117.422.178.633H.718l-.09-.314H0L5.681 22.07h.633l-2.536-8.953h1.106c.07.249.141.492.207.736 4.645 2.077 8.118.019 11.971-.684C15.92 9.114 14.773 5.058 13.623 1ZM6.188 3.217c1.307-.028 2.55-.3 3.773-.623l.984 3.478c-1.223.323-2.465.59-3.773.623a2573.52 2573.52 0 0 0-.984-3.478ZM3.642 12.64.853 2.791H1.96c.929 3.28 1.858 6.564 2.79 9.848H3.641Zm.89-2.995c-.318-1.135-.641-2.264-.96-3.399a9.75 9.75 0 0 0 3.647.61c.318 1.134.642 2.264.96 3.398a9.938 9.938 0 0 1-3.646-.61Zm8.518 3.853c-1.223.323-2.466.59-3.773.619-.329-1.157-.657-2.316-.985-3.479 1.308-.028 2.55-.3 3.774-.623.328 1.167.656 2.325.984 3.483Zm2.142-4.711c-1.092.239-2.156.562-3.234.844-.319-1.135-.642-2.264-.961-3.399 1.078-.286 2.147-.61 3.234-.843l.961 3.398Zm-.515-5.813c.06.211.117.422.178.633a12.446 12.446 0 0 1 1.514-.136l.984 3.478a11.5 11.5 0 0 0-1.514.136c.375 1.318.745 2.635 1.12 3.952a12.446 12.446 0 0 1 1.514-.136c.329 1.158.657 2.317.985 3.478-1.308.028-2.55.3-3.778.619-.075-.272-.155-.544-.23-.816-1.275.333-2.531.727-3.834.966.136.483.276.966.412 1.448 3.858-.703 7.331-2.76 11.972-.684L20.555 3.742c-2.157-.97-4.064-1.04-5.878-.769Zm7.335 8.138a9.75 9.75 0 0 0-3.646-.61c-.32-1.134-.643-2.263-.961-3.398a9.734 9.734 0 0 1 3.647.61c.318 1.134.642 2.268.96 3.398Z"
      />
    </svg>
  );
};
export default Disciplina7;
