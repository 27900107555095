/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-85.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco85 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-85', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="35.482"
      viewBox="0 0 38.439 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M391.392,220.871a4.435,4.435,0,1,0-4.435-4.435A4.44,4.44,0,0,0,391.392,220.871Zm0-7.392a2.957,2.957,0,1,1-2.957,2.957A2.96,2.96,0,0,1,391.392,213.478Zm5.533,8.366-2.3-.921a.741.741,0,0,0-.8.164l-2.434,2.434-2.434-2.434a.741.741,0,0,0-.8-.164l-2.3.921A2.943,2.943,0,0,0,384,224.59V229h1.478V224.59a1.472,1.472,0,0,1,.929-1.373l1.85-.74,2.612,2.612a.738.738,0,0,0,1.045,0l2.612-2.612,1.85.74a1.472,1.472,0,0,1,.929,1.373V229h1.478V224.59A2.943,2.943,0,0,0,396.925,221.844Z"
          transform="translate(-379.565 -212)"
          fill="currentColor"
        />
        <g transform="translate(5.914 19.22)">
          <path
            className="a"
            d="M394.131,254.263a8.131,8.131,0,1,1,8.131-8.131A8.141,8.141,0,0,1,394.131,254.263Zm0-14.784a6.653,6.653,0,1,0,6.653,6.653A6.661,6.661,0,0,0,394.131,239.478Z"
            transform="translate(-386 -238)"
            fill="currentColor"
          />
          <path
            className="a"
            d="M397.7,249.653h-.739V246.7a.739.739,0,0,0-.739-.739h-1.478a.739.739,0,1,0,0,1.478h.739v2.218h-.739a.739.739,0,1,0,0,1.478H397.7a.739.739,0,1,0,0-1.478Zm-1.848-4.435a1.109,1.109,0,1,0-1.109-1.109A1.11,1.11,0,0,0,395.848,245.218Z"
            transform="translate(-388.086 -239.304)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M402.394,234H378.739a.739.739,0,1,0,0,1.478h.739v8.131a.739.739,0,1,0,1.479,0v-8.131h21.437a.739.739,0,1,0,0-1.478Z"
          transform="translate(-378 -217.737)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M422.688,221.442a13.5,13.5,0,0,0-3.213-1.093,4.435,4.435,0,1,0-4.166,0,13.491,13.491,0,0,0-3.213,1.093,3.689,3.689,0,0,0-2.1,3.333v10.141a2.96,2.96,0,0,0,2.957,2.957v7.392a.739.739,0,0,0,1.478,0v-19.22a.739.739,0,1,0-1.478,0v10.349a1.48,1.48,0,0,1-1.478-1.478V224.775a2.219,2.219,0,0,1,1.265-2,9.886,9.886,0,0,1,9.3,0,2.219,2.219,0,0,1,1.265,2v10.141a1.48,1.48,0,0,1-1.479,1.478V226.045a.739.739,0,1,0-1.478,0v19.22a.739.739,0,1,0,1.478,0v-7.392a2.96,2.96,0,0,0,2.957-2.957V224.775A3.689,3.689,0,0,0,422.688,221.442Zm-8.253-5.007a2.957,2.957,0,1,1,2.957,2.957A2.96,2.96,0,0,1,414.435,216.435Z"
          transform="translate(-386.345 -212)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco85;
