/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-ke.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneKe = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-ke', className].join(' ');
  return (
    <svg
      id="nazione-ke"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <path d="M0 0h16v16H0V0Z" fill="#fff" />
        <path d="M0 0h16v5H0V0Z" fill="#000" />
        <path d="M0 11.5h16v5H0v-5Z" fill="#060" />
        <path
          d="m5.09 13.47-.025.044.045.024.189.106.043.024.025-.043 4.905-8.508a.7.7 0 0 0 .352-.237c.098-.114.179-.254.257-.39l.001-.002.015-.027c.126-.224.523-.935.696-1.852l.028-.144-.11.098c-.503.447-.93.974-1.263 1.56v.002c-.079.135-.16.275-.211.417a.756.756 0 0 0-.04.43L5.09 13.47Z"
          fill="#fff"
          stroke="#0F1315"
          strokeWidth=".1"
        />
        <path
          d="m10.9 13.47.026.044-.045.024-.19.106-.042.024-.025-.043L5.72 5.117a.7.7 0 0 1-.353-.237 2.582 2.582 0 0 1-.256-.39l-.002-.002-.015-.027c-.125-.224-.523-.935-.696-1.852l-.027-.144.11.098c.503.447.93.974 1.263 1.56v.002c.079.135.16.275.21.417.05.138.075.281.041.43L10.9 13.47Z"
          fill="#fff"
          stroke="#0F1315"
          strokeWidth=".1"
        />
        <path
          d="M7.994 3.5c-.6 0-1.6 1.6-1.9 2.4H0v4.8h6.094c.303.8 1.303 2.4 1.903 2.4.6 0 1.6-1.6 1.9-2.4H16V5.9H9.894c-.3-.8-1.3-2.4-1.9-2.4Z"
          fill="#B00"
        />
        <path
          d="M6.097 10.7a6.876 6.876 0 0 1-.5-2.4c.021-.824.19-1.636.5-2.4.309.764.478 1.576.5 2.4a6.875 6.875 0 0 1-.5 2.4ZM9.9 10.7a6.875 6.875 0 0 1-.5-2.4c.022-.823.19-1.636.5-2.4.31.764.479 1.577.5 2.4a6.875 6.875 0 0 1-.5 2.4Z"
          fill="#000"
        />
        <path d="M7.994 8.88c.22 0 .4-.269.4-.6 0-.331-.18-.6-.4-.6-.221 0-.4.269-.4.6 0 .331.179.6.4.6Z" fill="#fff" />
        <path
          d="M8.094 8.86s.4.8.4 2.1-.4 2.1-.4 2.1v-4.2ZM7.894 7.7s-.4-.8-.4-2.1.4-2.1.4-2.1v4.2ZM7.894 8.86s-.4.8-.4 2.1.4 2.1.4 2.1v-4.2ZM8.094 7.7s.4-.8.4-2.1-.4-2.1-.4-2.1v4.2Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="16" height="16" rx="8" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NazioneKe;
