/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-44.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina44 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-44', className].join(' ');
  return (
    <svg
      id="disciplina_nazione-ospitante"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M12 1c6.074 0 10.994 4.923 11 11.008.006 6.064-4.94 11-11.014 10.995C5.926 23 .992 18.06 1 12.008 1.009 5.92 5.923 1 12 1ZM3.972 17.844l2.785-1.44a13.864 13.864 0 0 1-.733-3.85H2.09a9.86 9.86 0 0 0 1.883 5.29Zm16.05.003c1.135-1.567 1.764-3.33 1.885-5.288h-3.783a13.871 13.871 0 0 1-.75 3.903l2.647 1.385ZM6.587 7.843 3.821 6.376a9.803 9.803 0 0 0-1.728 5.068h3.846l.65-3.6Zm15.32 3.608a9.815 9.815 0 0 0-1.733-5.078l-2.642 1.418.677 3.662 3.698-.002Zm-4.86 1.108h-4.495v2.85l3.813.66c.399-1.13.628-2.313.682-3.51Zm-5.603 2.857v-2.86H7.106c.048 1.185.272 2.357.665 3.476l3.672-.616Zm5.002-7.185-3.89.681v2.54h4.463c.068-.617-.282-2.537-.573-3.221Zm-4.997.679-3.77-.645c-.28.61-.634 2.64-.538 3.185h4.308V8.91Zm4.602-1.78c-.829-1.932-2.009-3.572-3.496-5.056v5.674a13.206 13.206 0 0 0 3.496-.617Zm-4.607.619V2.155c-1.447 1.483-2.59 3.111-3.382 5.01 1.097.342 2.234.539 3.382.584Zm1.108 8.83v5.378c1.434-1.426 2.565-2.985 3.376-4.775-.561-.275-2.454-.611-3.376-.603Zm-1.11 5.209v-5.219a13.18 13.18 0 0 0-3.23.54c.39 1.2 2.256 3.905 3.23 4.678ZM9.763 2.356C7.68 2.83 5.942 3.87 4.482 5.52l2.546 1.338c.695-1.682 1.618-3.147 2.737-4.502Zm7.302 4.41C17.93 6.41 18.725 6 19.48 5.47c-1.418-1.58-3.11-2.61-5.135-3.092 1.112 1.328 2.032 2.767 2.721 4.388Zm-7.27 14.917c-.501-.7-1.008-1.347-1.446-2.037-.438-.69-.816-1.436-1.227-2.17-.835.333-1.652.73-2.421 1.255 1.413 1.5 3.087 2.478 5.094 2.952Zm4.588-.07c1.93-.462 3.553-1.43 4.952-2.921l-2.358-1.222c-.68 1.54-1.561 2.886-2.594 4.142Z"
      />
    </svg>
  );
};
export default Disciplina44;
