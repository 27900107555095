/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-291.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco291 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-291', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.569"
      height="38.439"
      viewBox="0 0 29.569 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0)">
        <g transform="translate(7.392 10.349)">
          <path
            className="a"
            d="M156.056,504.09a8.139,8.139,0,0,1-5.76-2.381l-8.083-8.188a.739.739,0,0,1,0-1.042l.344-.344a4.442,4.442,0,0,1,6.273,0l.56.56V478.957a2.957,2.957,0,0,1,5.914,0v6.859l5.838,1.946a4.429,4.429,0,0,1,3.033,4.207v3.762a8.276,8.276,0,0,1-7.552,8.34h0C156.435,504.084,156.245,504.09,156.056,504.09Zm.517-.757h0Zm-12.785-10.32,7.556,7.655a6.67,6.67,0,0,0,5.177,1.927,6.793,6.793,0,0,0,6.176-6.864v-3.762a2.952,2.952,0,0,0-2.022-2.8l-6.343-2.115a.737.737,0,0,1-.506-.7v-7.392a1.478,1.478,0,1,0-2.957,0V494.48a.739.739,0,0,1-1.262.523l-1.821-1.822A2.961,2.961,0,0,0,143.788,493.013Z"
            transform="translate(-142 -476)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M144.567,485.655H135.7a3.7,3.7,0,0,1-3.7-3.7V465.7a3.7,3.7,0,0,1,3.7-3.7h16.263a3.7,3.7,0,0,1,3.7,3.7V480.48a.739.739,0,1,1-1.478,0V465.7a2.22,2.22,0,0,0-2.218-2.218H135.7a2.22,2.22,0,0,0-2.218,2.218v16.263a2.22,2.22,0,0,0,2.218,2.218h8.871a.739.739,0,0,1,0,1.478Z"
            transform="translate(-132 -462)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M139.478,468a1.478,1.478,0,1,0,1.478,1.478A1.48,1.48,0,0,0,139.478,468Zm5.914,0a1.478,1.478,0,1,0,1.478,1.478A1.48,1.48,0,0,0,145.392,468Zm5.914,0a1.478,1.478,0,1,0,1.478,1.478A1.48,1.48,0,0,0,151.306,468Zm-11.827,5.914a1.478,1.478,0,1,0,1.478,1.478A1.48,1.48,0,0,0,139.478,473.914Zm5.914,0a1.478,1.478,0,1,0,1.478,1.478A1.48,1.48,0,0,0,145.392,473.914Zm-5.914,5.914a1.478,1.478,0,1,0,1.478,1.479A1.48,1.48,0,0,0,139.478,479.827Zm5.914,0a1.478,1.478,0,1,0,1.478,1.479A1.48,1.48,0,0,0,145.392,479.827Z"
          transform="translate(-133.565 -463.565)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco291;
