/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-199.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco199 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-199', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="35.483"
      viewBox="0 0 35.482 35.483"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M497.265,394H466.218A2.22,2.22,0,0,0,464,396.218v2.957a2.213,2.213,0,0,0,1.574,2.111l2.563,15.376a2.947,2.947,0,0,0,2.916,2.471H492.43a2.947,2.947,0,0,0,2.916-2.471l2.563-15.376a2.213,2.213,0,0,0,1.574-2.111v-2.957A2.22,2.22,0,0,0,497.265,394Zm-3.377,22.42a1.475,1.475,0,0,1-1.458,1.235H471.053a1.474,1.474,0,0,1-1.458-1.235l-2.5-15.028h29.3ZM498,399.175a.739.739,0,0,1-.739.739H466.218a.739.739,0,0,1-.739-.739v-2.957a.74.74,0,0,1,.739-.739h31.047a.74.74,0,0,1,.739.739Z"
        transform="translate(-464 -383.651)"
        fill="currentColor"
      />
      <g transform="translate(8.131 0)">
        <path
          className="a"
          d="M485.327,391.826h-5.121a.738.738,0,0,1-.64-.37l-4.268-7.392a2.217,2.217,0,0,1,.812-3.029l1.28-.739a2.224,2.224,0,0,1,3.03.811l5.548,9.61a.739.739,0,0,1-.64,1.109Zm-4.695-1.478h3.414l-4.907-8.5a.742.742,0,0,0-1.011-.27l-1.28.739a.736.736,0,0,0-.27,1.008Z"
          transform="translate(-474.999 -380)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(17.002 13.306)">
        <path
          className="a"
          d="M487.739,399.478a.732.732,0,0,1-.739-.739.774.774,0,0,1,.214-.525.737.737,0,0,1,.664-.2.45.45,0,0,1,.141.044.569.569,0,0,1,.133.067,1.25,1.25,0,0,1,.111.089.777.777,0,0,1,.214.525.732.732,0,0,1-.739.739Z"
          transform="translate(-487 -397.999)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M487.889,408a.726.726,0,0,0-.8.675l-.739,8.871a.738.738,0,0,0,.675.8.6.6,0,0,0,.062,0,.738.738,0,0,0,.736-.678l.739-8.871A.738.738,0,0,0,487.889,408Zm5.234.007a.736.736,0,0,0-.85.608l-1.478,8.871a.738.738,0,0,0,.608.85.687.687,0,0,0,.122.01.739.739,0,0,0,.728-.618l1.478-8.871A.739.739,0,0,0,493.123,408.01ZM481.852,408a.738.738,0,0,0-.675.8l.739,8.871a.738.738,0,0,0,.735.678.6.6,0,0,0,.062,0,.738.738,0,0,0,.675-.8l-.739-8.871A.725.725,0,0,0,481.852,408Zm-5.234.007a.739.739,0,0,0-.608.85l1.478,8.871a.739.739,0,0,0,.728.618.684.684,0,0,0,.122-.01.739.739,0,0,0,.608-.85l-1.478-8.871A.737.737,0,0,0,476.618,408.01Z"
        transform="translate(-467.129 -387.301)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco199;
