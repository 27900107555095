/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\heart-outline.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const HeartOutline = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-heart-outline', className].join(' ');
  return (
    <svg
      id="heart-outline"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M10 18.975L8.975 18.05C7.20833 16.4334 5.75 15.0375 4.6 13.8625C3.45 12.6875 2.53333 11.6375 1.85 10.7125C1.16667 9.78752 0.6875 8.95002 0.4125 8.20002C0.1375 7.45002 0 6.69169 0 5.92502C0 4.42502 0.504167 3.17086 1.5125 2.16252C2.52083 1.15419 3.76667 0.650024 5.25 0.650024C6.2 0.650024 7.07917 0.875024 7.8875 1.32502C8.69583 1.77502 9.4 2.42502 10 3.27502C10.7 2.37502 11.4417 1.71252 12.225 1.28752C13.0083 0.862524 13.85 0.650024 14.75 0.650024C16.2333 0.650024 17.4792 1.15419 18.4875 2.16252C19.4958 3.17086 20 4.42502 20 5.92502C20 6.69169 19.8625 7.45002 19.5875 8.20002C19.3125 8.95002 18.8333 9.78752 18.15 10.7125C17.4667 11.6375 16.55 12.6875 15.4 13.8625C14.25 15.0375 12.7917 16.4334 11.025 18.05L10 18.975ZM10 17C11.6833 15.45 13.0708 14.1209 14.1625 13.0125C15.2542 11.9042 16.1208 10.9334 16.7625 10.1C17.4042 9.26669 17.8542 8.52502 18.1125 7.87502C18.3708 7.22502 18.5 6.57502 18.5 5.92502C18.5 4.82502 18.15 3.92086 17.45 3.21252C16.75 2.50419 15.85 2.15002 14.75 2.15002C13.9 2.15002 13.1083 2.41252 12.375 2.93752C11.6417 3.46252 11.05 4.20002 10.6 5.15002H9.375C8.94167 4.21669 8.35833 3.48336 7.625 2.95002C6.89167 2.41669 6.1 2.15002 5.25 2.15002C4.15 2.15002 3.25 2.50419 2.55 3.21252C1.85 3.92086 1.5 4.82502 1.5 5.92502C1.5 6.57502 1.62917 7.22919 1.8875 7.88752C2.14583 8.54586 2.59583 9.29586 3.2375 10.1375C3.87917 10.9792 4.75 11.95 5.85 13.05C6.95 14.15 8.33333 15.4667 10 17Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default HeartOutline;
