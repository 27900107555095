/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-17.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco17 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-17', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="35.482"
      viewBox="0 0 35.482 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M145.741,128a17.758,17.758,0,0,0-15.6,9.3c0,.01-.01.018-.014.028a17.712,17.712,0,0,0,0,16.819c0,.01.01.018.014.027A17.74,17.74,0,1,0,145.741,128ZM129.5,146.48H135.4a29.1,29.1,0,0,0,.9,6.654h-5.04A16.154,16.154,0,0,1,129.5,146.48Zm1.766-8.131h5.04a29.1,29.1,0,0,0-.9,6.654H129.5A16.155,16.155,0,0,1,131.263,138.348ZM145,129.539v7.332h-6.69C139.77,132.781,142.2,129.965,145,129.539Zm0,8.809V145h-8.121a27.3,27.3,0,0,1,.963-6.654Zm-8.121,8.131H145v6.654h-7.158A27.3,27.3,0,0,1,136.881,146.48ZM145,154.611v7.332c-2.8-.426-5.232-3.243-6.69-7.332Zm1.478,7.332v-7.332h6.69C151.713,158.7,149.279,161.518,146.48,161.943Zm0-8.81V146.48H154.6a27.307,27.307,0,0,1-.963,6.654Zm0-8.131v-6.654h7.158A27.308,27.308,0,0,1,154.6,145Zm0-8.131v-7.332c2.8.426,5.232,3.242,6.69,7.332Zm-5.943-6.533a16.244,16.244,0,0,0-3.8,6.533h-4.618A16.335,16.335,0,0,1,140.537,130.337Zm-8.416,24.274h4.618a16.247,16.247,0,0,0,3.8,6.534A16.336,16.336,0,0,1,132.121,154.611Zm18.824,6.534a16.247,16.247,0,0,0,3.8-6.534h2.975a.739.739,0,1,0,0-1.478H155.18a29.1,29.1,0,0,0,.9-6.654h3.706a.739.739,0,1,0,0-1.478H156.08a29.1,29.1,0,0,0-.9-6.654h2.539a.739.739,0,1,0,0-1.478h-2.975a16.244,16.244,0,0,0-3.8-6.533,16.257,16.257,0,0,1,0,30.808Z"
        transform="translate(-128 -128)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco17;
