/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-151.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco151 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-151', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="35.482"
      viewBox="0 0 38.439 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M389.957,252a2.957,2.957,0,1,0,2.957,2.957A2.96,2.96,0,0,0,389.957,252Zm0,4.435a1.478,1.478,0,1,1,1.478-1.478A1.48,1.48,0,0,1,389.957,256.435ZM406.22,252a2.957,2.957,0,1,0,2.957,2.957A2.96,2.96,0,0,0,406.22,252Zm0,4.435a1.478,1.478,0,1,1,1.478-1.478A1.48,1.48,0,0,1,406.22,256.435Z"
        transform="translate(-380.347 -222.431)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M415.7,212h-4.435a.739.739,0,0,0-.72.571L405.5,234.177H384.468l-4.744-16.263h27.1a.739.739,0,0,0,0-1.478h-28.09a.739.739,0,0,0-.71.946l5.174,17.741a.739.739,0,0,0,.71.532H406.09a2.218,2.218,0,0,1,0,4.435H384.653a.739.739,0,1,0,0,1.478H406.09a3.689,3.689,0,0,0,.9-7.268l4.859-20.822H415.7a.739.739,0,1,0,0-1.478Z"
        transform="translate(-378 -212)"
        fill="currentColor"
      />
      <g transform="translate(22.177 7.392)">
        <path
          className="a"
          d="M408.74,235.306a.65.65,0,0,1-.092-.006.738.738,0,0,1-.642-.825l1.478-11.828a.739.739,0,0,1,1.467.183l-1.478,11.827A.739.739,0,0,1,408.74,235.306Z"
          transform="translate(-408 -222)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(8.871 7.392)">
        <path
          className="a"
          d="M392.217,235.306a.739.739,0,0,1-.733-.648l-1.478-11.827a.739.739,0,0,1,1.467-.183l1.478,11.828a.739.739,0,0,1-.642.825A.651.651,0,0,1,392.217,235.306Z"
          transform="translate(-390 -222)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(16.263 7.392)">
        <path
          className="a"
          d="M400.739,235.306a.739.739,0,0,1-.739-.739V222.739a.739.739,0,1,1,1.478,0v11.827A.739.739,0,0,1,400.739,235.306Z"
          transform="translate(-400 -222)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco151;
