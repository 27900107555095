/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-154.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina154 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-154', className].join(' ');
  return (
    <svg
      id="disciplina_motociclismo-virtual"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M3.73726 17.3612H19.1657s1.8072-1.5485 1.8342-5.0905C21.0268 8.72882 17.0169 5 12.5484 5 9.05989 5 8.01694 6.57518 7.77419 6.81546c-.24276.24028 4.04591 3.27494 4.04591 3.27494v1.8155l-2.20277 3.6398h-6.2487v1.4506l.36863.3649Zm7.34564-7.2708v1.8155l-1.8342 3.2749H3s1.12387-3.8267 1.83415-5.0904c.71029-1.26369 1.84314-2.91007 1.84314-2.91007l4.40561 2.91007ZM3.73726 17.726H19.1657v.3649H3.73726v-.3649Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina154;
