/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\player.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Player = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-player', className].join(' ');
  return (
    <svg
      id="player"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#clip0_7542_57470)">
        <path
          d="M5 5C5 6.654 6.346 8 8 8C9.654 8 11 6.654 11 5C11 3.346 9.654 2 8 2C6.346 2 5 3.346 5 5ZM13.3333 14.6667H14V14C14 11.4273 11.906 9.33333 9.33333 9.33333H6.66667C4.09333 9.33333 2 11.4273 2 14V14.6667H13.3333Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_7542_57470">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Player;
