/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-370.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco370 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-370', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="32.526"
      viewBox="0 0 38.439 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M246.222,138.871h-34A2.22,2.22,0,0,0,210,141.088v19.22a2.22,2.22,0,0,0,2.218,2.218h34a2.22,2.22,0,0,0,2.218-2.218v-19.22A2.22,2.22,0,0,0,246.222,138.871Zm-34.743,2.218a.74.74,0,0,1,.739-.739h3.637a5.176,5.176,0,0,1-4.376,4.376Zm.739,19.959a.74.74,0,0,1-.739-.739v-3.637a5.176,5.176,0,0,1,4.376,4.376Zm34.743-.739a.74.74,0,0,1-.739.739h-3.637a5.176,5.176,0,0,1,4.376-4.376Zm0-5.131a6.663,6.663,0,0,0-5.87,5.87H217.349a6.663,6.663,0,0,0-5.87-5.87v-8.958a6.663,6.663,0,0,0,5.87-5.87h23.742a6.663,6.663,0,0,0,5.87,5.87Zm0-10.452a5.176,5.176,0,0,1-4.376-4.376h3.637a.74.74,0,0,1,.739.739ZM213.7,135.914h31.047a.739.739,0,0,0,0-1.478H213.7a.739.739,0,1,0,0,1.478Zm2.957-4.435h25.133a.739.739,0,0,0,0-1.478H216.653a.739.739,0,1,0,0,1.478Zm12.567,10.349a8.871,8.871,0,1,0,8.871,8.871A8.881,8.881,0,0,0,229.22,141.827Zm0,16.263a7.392,7.392,0,1,1,7.392-7.392A7.4,7.4,0,0,1,229.22,158.09Zm-.739-9.61h2.957a.739.739,0,1,0,0-1.478h-1.478v-.739a.739.739,0,1,0-1.478,0V147a2.218,2.218,0,0,0,0,4.435h1.478a.739.739,0,1,1,0,1.478H227a.739.739,0,0,0,0,1.478h1.478v.739a.739.739,0,1,0,1.478,0v-.739a2.218,2.218,0,1,0,0-4.435H228.48a.739.739,0,0,1,0-1.478Z"
        transform="translate(-210 -130)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco370;
