/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\info-yellow.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const InfoYellow = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-info-yellow', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M6 1C8.7615 1 11 3.2385 11 6C11 8.7615 8.7615 11 6 11C3.2385 11 1 8.7615 1 6C1 3.2385 3.2385 1 6 1ZM6.5 4.5V3.5H5.5V4.5H6.5ZM6.5 8.5L6.5 5.5H5.5L5.5 8.5H6.5Z"
        fill="#EBFF00"
      />
    </svg>
  );
};
export default InfoYellow;
