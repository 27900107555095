/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-329.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco329 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-329', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="35.482"
      viewBox="0 0 35.482 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M406.613,159.823a16.322,16.322,0,0,1-8.132-14.082.739.739,0,0,0-1.478,0,17.806,17.806,0,0,0,8.87,15.362.739.739,0,0,0,.741-1.28Zm7.236-8.991a5.162,5.162,0,0,1-4.279-5.091,11.827,11.827,0,1,0-23.655,0A28.636,28.636,0,0,0,390.949,162a.74.74,0,1,0,1.22-.836,27.156,27.156,0,0,1-4.777-15.419,10.349,10.349,0,1,1,20.7,0,6.638,6.638,0,0,0,5.5,6.547.739.739,0,1,0,.256-1.456Zm-2.956,5.293a11.141,11.141,0,0,1-7.237-10.384,5.914,5.914,0,1,0-11.828,0A23.429,23.429,0,0,0,399.82,163.3a.739.739,0,0,0,.965-1.121,21.93,21.93,0,0,1-7.479-16.442,4.435,4.435,0,1,1,8.871,0,12.624,12.624,0,0,0,8.2,11.769.739.739,0,1,0,.515-1.385ZM397.741,128a17.747,17.747,0,0,0-16.97,22.924.739.739,0,1,0,1.413-.431,16.293,16.293,0,1,1,31.822-6.167.73.73,0,0,0,.8.673.739.739,0,0,0,.673-.8A17.882,17.882,0,0,0,397.741,128Z"
        transform="translate(-380 -128)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco329;
