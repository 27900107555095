import { CarrelloTipo, CarrelloTipoType } from './types';
import { CartIppicaScommessaAvvenimento, CartScommessaAvvenimentoVirtual } from 'types/swagger';

import { SportTicketAvvenimento } from 'features/sport/components/sportTicket/utils/calcoloCarrello/types';
import { ippicaTicketSlice } from 'features/ippica/components/ippicaTicket/ippicaTicketSlice';
import { sportTicketSlice } from 'features/sport/components/sportTicket/sportTicketSlice';
import { virtualTicketSlice } from 'features/virtual/components/virtualTicket/virtualTicketSlice';

export const formatPrice = (price: string) => {
  const options = {
    style: 'decimal',
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return parseFloat(price).toLocaleString('it-IT', options);
};

export const getSliceByTipoTicket = (
  tipo: CarrelloTipo
): typeof virtualTicketSlice | typeof sportTicketSlice | typeof ippicaTicketSlice => {
  switch (tipo) {
    case CarrelloTipo.Sport:
      return sportTicketSlice;
    case CarrelloTipo.Ippica:
      return ippicaTicketSlice;
    default:
      return virtualTicketSlice;
  }
};

export const formatValueByDivisor = (num: number, divisor: number): number => {
  return num / divisor;
};

export const isCarrelloTypeSport = (type: CarrelloTipoType) => {
  return type === CarrelloTipo.Sport;
};

export const isCarrelloTypeVirtual = (type: CarrelloTipoType) => {
  return type === CarrelloTipo.Virtual;
};

export const isCarrelloTypeIppica = (type: CarrelloTipoType) => {
  return type === CarrelloTipo.Ippica;
};

export const isAvvenimentiIppica = (
  avvenimenti: SportTicketAvvenimento[] | CartScommessaAvvenimentoVirtual[] | CartIppicaScommessaAvvenimento[]
): avvenimenti is CartIppicaScommessaAvvenimento[] => {
  return avvenimenti.every((avvenimento) => avvenimento['descrizioneAvvenimento']);
};
