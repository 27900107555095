/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\cavallo-paraocchi.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const CavalloParaocchi = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-cavallo-paraocchi', className].join(' ');
  return (
    <svg
      id="cavallo-paraocchi"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M10.1319 1.33203c-1.7487.84865-2.61248 2.00714-2.65524 3.45309-.69369.07013-1.43691.2237-2.0347.43525.35493.98147.78476 1.90993 1.30057 2.77685.39676-1.59057 1.6244-2.79029 3.62117-3.32989.2365-1.04951.144-2.16432-.2318-3.3353Z"
        fill="currentColor"
      />
      <path
        d="M10.8048 5.03153c-2.28187.49808-3.47092 1.7789-3.70333 3.53938.14643.22227.29775.44102.45639.65468l.82256-.84442v-.00345c.33451-.33359.77644-.49908 1.21666-.49908.44012 0 .87942.16547 1.21392.49908.6584.65627.7648 1.72398.0884 2.39648l-.95514.9523c.42244.3464.86984.6746 1.34474.9829l2.382-2.1771-.8839-1.48828c-.8843-1.48851-.9472-2.9991-1.9823-4.01249Z"
        fill="currentColor"
      />
      <path
        d="M5.00633 5.3885c-1.38618.58253-2.07003 1.09569-3.35425 2.16179-.22289.19418-.43997.3935-.65208.59801l5.78852 6.5123c-.02635-.0856-.05166-.1714-.07575-.2581-.09599-.3462-.174-.7002-.22636-1.0665-.1049-.7326-.10577-1.5157.06667-2.392.88637 1.1616 2.1082 2.0172 4.27682 2.0098-2.81285-1.89-4.70468-4.4734-5.82357-7.5653Z"
        fill="currentColor"
      />
      <path
        d="M9.59619 8.33624c-.32084 0-.6421.12213-.88842.36775l-.86491.8894c.52093.65551 1.10208 1.26811 1.74793 1.83351l.98491-.9793c.487-.48407.4115-1.2417-.092-1.74361-.2462-.24564-.56665-.36775-.88751-.36775ZM13.9127 10.9377l-2.6083 2.383.9425.9389c.5427.541 1.4163.541 1.959 0l.3265-.3256c.5427-.5411.5427-1.412 0-1.9531l-.6197-1.0432Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default CavalloParaocchi;
