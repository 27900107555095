/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\search.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Search = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-search', className].join(' ');
  return (
    <svg id="search" fill="none" viewBox="0 0 24 24" focusable="false" className={componentClassName} {...attribs}>
      <path
        d="M17.6857 11.2743C17.6857 14.4713 15.1179 17.0486 11.9679 17.0486C8.81785 17.0486 6.25 14.4713 6.25 11.2743C6.25 8.07734 8.81785 5.5 11.9679 5.5C15.1179 5.5 17.6857 8.07734 17.6857 11.2743Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <line
        y1="-1"
        x2="3.83446"
        y2="-1"
        transform="matrix(0.704142 0.710059 -0.704142 0.710059 16.0503 16.0708)"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};
export default Search;
