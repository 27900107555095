/***************************************************************************************************

  DO NOT EDIT THIS FILE, APPLY YOUR CHANGES TO \common\tasks\policy\template.txt FILE, INSTEAD
  N.B.: placeholders are defined in Dato CMS
      ex: defaultPolicies feeds {#DEFAULT#} placeholder defined in the template
          policyName feeds {#POLICYNAME#}

***************************************************************************************************/
export const NUVEI_URL = 'https://*.safecharge.com';
export const CASINO_URL = 'https://extstg1-login.ptstaging.eu/';
export const DATOCMS_URL = 'https://www.datocms-assets.com';
export const ACSBAPP_URL = 'https://acsbapp.com/apps/app/dist/js/app.js';
export const CDNFONT_URL = 'https://fonts.cdnfonts.com';
export const BETPOINT_URL = 'https://b2b.betpoint.it/';
export const POSTEPAY_URL = 'https://acquistionlinetest.poste.it';
export const PLAYTECH_URL = 'https://login-pza.techonlinecorp.com';
export const CAPTAINUP_URL = 'https://captainup.com/assets/sdk.js';
export const SNAI_GAME_URL = 'https://www.snaiabilita.it/';
export const CHAT_SERVICE_URL = 'https://chatservice.snai.it/webplayer/';
export const DEP_IOVATION_URL = 'https://mpsnare.iesnare.com';
export const GIOCA_ONLINE_URL = 'https://skill-sn.gioconlineitalia.it/';
export const SPID_REGISTRY_URL = 'https://registry.spid.gov.it/entities-idp?&output=json&custom=info_display_base';
export const LIVE_STREAMING_URL = 'https://api.livestreaming.imgarena.com';
export const WIDGET_SIR_RADAR_URL = 'https://widgets.sir.sportradar.com/';
export const DIGITAL_ASSISTANT_URL =
  'https://chatservice.snai.it/webplayer/latest/js/digital-assistant.js?view.mobileMode=detect';
export const GOOGLE_TAG_MANAGER_URL = 'https://www.googletagmanager.com/gtm.js';

export const IOVATION_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/iovation`;

const WS = 'ws:';
const WSS = 'wss:';
const BLOB = 'blob:';
const DATA = 'data:';
const SELF = "'self'";
// const HTTPS = 'https:';
const UNSAFE_EVAL = "'unsafe-eval'";
const UNSAFE_INLINE = "'unsafe-inline'";
// const STRICT_DYNAMIC = "'strict-dynamic'";
export const NONCE_PLACEHOLDER = '//-=NoNcE=-//';

export const techs = [SELF];

export const defaults = [
  WS,
  WSS,
  DATOCMS_URL,
  '*',
  'https://*.google-analytics.com',
  process.env.NEXT_PUBLIC_TOTOCALCIO_URL,
];

export const scriptElems = [
  CASINO_URL,
  ACSBAPP_URL,
  BETPOINT_URL,
  CAPTAINUP_URL,
  SNAI_GAME_URL,
  DEP_IOVATION_URL,
  GIOCA_ONLINE_URL,
  WIDGET_SIR_RADAR_URL,
  GOOGLE_TAG_MANAGER_URL,
  'https://*.gntn-pgd.it/',
  '*',
  'https://*.go-mpulse.net',
  'https://*.game360.it',
  'https://*.isoftbet.com',
  'https://*.woosmap.com',
  'https://*.betpoint.it',
  'https://snai-pscp.mstchannel.com/mst/mstchannel.js',
  'https://*.cookiebot.com',
  'https://*.dynatrace.com',
  'https://*.pokersnai.it',
  '*.rfihub.net',
  '*.rfihub.com',
  'https://*.qa.gameaccount.com',
  'https://*.sisal.it/',
  'https://*.googleapis.com',
  'https://lit.grattaevinci.com',
  'https://onetag-sys.com',
  'https://*.gioconlineitalia.it',
  'https://snai-pscp-staging.mstchannel.com/',
  'https://*.giocaonline.casino',
  'https://snai.live.giocaonline.casino',
  'https://webapp.woosmap.com/webapp.js',
  UNSAFE_INLINE,
];

export const connections = [
  WS,
  WSS,
  DATOCMS_URL,
  ACSBAPP_URL,
  CAPTAINUP_URL,
  SPID_REGISTRY_URL,
  LIVE_STREAMING_URL,
  WIDGET_SIR_RADAR_URL,
  GOOGLE_TAG_MANAGER_URL,
  '*',
  'https://*.geniussports.com',
  'https://*.llnwd.net',
  'https://*.typekit.net',
  'https://*.go-mpulse.net',
  'https://*.woosmap.com',
  'https://*.cookiebot.com',
  'https://*.dynatrace.com',
  'https://*.sportradar.com',
  'https://*.akstat.io',
  'https://*.googleapis.com',
  'https://*.akamaihd.net',
  'https://onetag-sys.com',
  'https://*.akamaized.net',
  'https://*.google-analytics.com',
  'https://*.applicationinsights.azure.com',
];

export const images = [
  BLOB,
  DATA,
  DATOCMS_URL,
  PLAYTECH_URL,
  'https://imgsct.cookiebot.com',
  '*',
  'https://*.woosmap.com',
  'https://*.gstatic.com',
  'https://*.amazonaws.com',
  'https://*.sportradar.com',
  'https://*.googleapis.com',
  '*.doubleclick.net',
  'https://*.google-analytics.com',
  'https://www.googletagmanager.com',
];

export const scripts = [
  BLOB,
  ACSBAPP_URL,
  DEP_IOVATION_URL,
  DIGITAL_ASSISTANT_URL,
  GOOGLE_TAG_MANAGER_URL,
  '*',
  'https://*.typekit.net',
  'https://*.woosmap.com',
  'https://*.cookiebot.com',
  'https://*.dynatrace.com',
  'https://*.pokersnai.it',
  '*.rfihub.com',
  '*.rfihub.net',
  process.env.NEXT_PUBLIC_LAUNCH_GAN,
  process.env.NEXT_PUBLIC_LAUNCH_CAPECOD,
  process.env.NEXT_PUBLIC_LAUNCH_BETPOINT,
  process.env.NEXT_PUBLIC_LAUNCH_GAME_360,
  process.env.NEXT_PUBLIC_LAUNCH_ISOFTBET,
  process.env.NEXT_PUBLIC_LAUNCH_MICROGAME,
  process.env.NEXT_PUBLIC_SCRIPT_URL_POKER,
  process.env.NEXT_PUBLIC_CAPTAIN_UP_SDK_URL,
  process.env.NEXT_PUBLIC_VETRINA_SISAL_SCRIPT,
  process.env.NEXT_PUBLIC_LAUNCH_GOL_SLOT_CASINO,
  process.env.NEXT_PUBLIC_PROTOTYPE_LOTTERY_SCRIPT,
  process.env.NEXT_PUBLIC_LAUNCH_FIM_PLAYTECH_CASINO,
  process.env.NEXT_PUBLIC_VETRINA_GRATTA_E_VINCI_SCRIPT,
  process.env.NEXT_PUBLIC_GRATTA_E_VINCI_SCRIPT_SHOWCASE,
  UNSAFE_EVAL,
  UNSAFE_INLINE,
];

export const styles = [
  CDNFONT_URL,
  WIDGET_SIR_RADAR_URL,
  '*',
  'https://*.typekit.net',
  'https://*.googleapis.com',
  UNSAFE_INLINE,
];

export const frames = [
  POSTEPAY_URL,
  NUVEI_URL,
  'https://*.sisal.it/',
  'https://*.gntn-pgd.it/',
  '*',
  'https://*.mstchannel.com/',
  'https://*.cookiebot.com',
  'https://*.ptstaging.eu',
  'https://*.pokersnai.it',
  'https://*.jumio.ai',
  '*.rfihub.net',
  '*.rfihub.com',
  'https://snai-pscp-staging.mstchannel.com/',
  'https://report.liveg24.com',
  'https://login-it.casino.pokersnai.it',
  process.env.NEXT_PUBLIC_PROMO_URL,
  process.env.NEXT_PUBLIC_SNAI_PLUS_URL,
  process.env.NEXT_PUBLIC_BETGENIUS_URL,
  process.env.NEXT_PUBLIC_SCOREBOARD_URL,
  process.env.NEXT_PUBLIC_LAUNCH_SNAISKILL,
  process.env.NEXT_PUBLIC_LAUNCH_GOL_GIOCHI,
  process.env.NEXT_PUBLIC_SEAMLESS_BASE_URL,
  process.env.NEXT_PUBLIC_LOTTOMATICA_IFRAME,
  process.env.NEXT_PUBLIC_GRATTA_E_VINCI_IFRAME,
  process.env.NEXT_PUBLIC_VIRTUAL_WIDGET_IFRAME,
  process.env.NEXT_PUBLIC_LAUNCH_ULR_PLAYTECH_POKER,
  process.env.NEXT_PUBLIC_VIRTUAL_CLASSIFICA_GOLDEN,
  process.env.NEXT_PUBLIC_LAUNCH_CASINO_LIVE_MOBILE,
  process.env.NEXT_PUBLIC_LAUNCH_CASINO_LIVE_DESKTOP,
  process.env.NEXT_PUBLIC_LAUNCH_ULR_PLAYTECH_CASINO,
  UNSAFE_INLINE,
];

export const frameAncestors = ['https://*.gntn-pgd.it/', '*', 'https://*.snaitech.net'];

export const fonts = [
  DATA,
  CDNFONT_URL,
  '*',
  'https://*.typekit.net',
  'https://*.gstatic.com',
  'https://*.googleapis.com',
  'https://login-it.casino.pokersnai.it',
];

export const media = [
  BLOB,
  DATA,
  DEP_IOVATION_URL,
  LIVE_STREAMING_URL,
  '*',
  'https://*.geniussports.com',
  'https://*.llnwd.net',
  'https://*.akstat.io',
  'https://*.akamaized.net',
];

export const formActions = [];
