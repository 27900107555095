/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\meteo-vento.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MeteoVento = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-meteo-vento', className].join(' ');
  return (
    <svg
      id="meteo-vento"
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M6.66732 11.3333C6.11176 11.3333 5.63954 11.1389 5.25065 10.75C4.86176 10.3611 4.66732 9.88889 4.66732 9.33333H6.00065C6.00065 9.52222 6.06454 9.68056 6.19232 9.80833C6.3201 9.93611 6.47843 10 6.66732 10C6.85621 10 7.01454 9.93611 7.14232 9.80833C7.27009 9.68056 7.33398 9.52222 7.33398 9.33333C7.33398 9.14444 7.27009 8.98611 7.14232 8.85833C7.01454 8.73055 6.85621 8.66667 6.66732 8.66667H0.333984V7.33333H6.66732C7.22287 7.33333 7.6951 7.52778 8.08398 7.91667C8.47287 8.30556 8.66732 8.77778 8.66732 9.33333C8.66732 9.88889 8.47287 10.3611 8.08398 10.75C7.6951 11.1389 7.22287 11.3333 6.66732 11.3333ZM0.333984 4.66667V3.33333H9.33398C9.62287 3.33333 9.86176 3.23889 10.0507 3.05C10.2395 2.86111 10.334 2.62222 10.334 2.33333C10.334 2.04444 10.2395 1.80556 10.0507 1.61667C9.86176 1.42778 9.62287 1.33333 9.33398 1.33333C9.04509 1.33333 8.80621 1.42778 8.61732 1.61667C8.42843 1.80556 8.33398 2.04444 8.33398 2.33333H7.00065C7.00065 1.67778 7.22565 1.125 7.67565 0.675C8.12565 0.225 8.67843 0 9.33398 0C9.98954 0 10.5423 0.225 10.9923 0.675C11.4423 1.125 11.6673 1.67778 11.6673 2.33333C11.6673 2.98889 11.4423 3.54167 10.9923 3.99167C10.5423 4.44167 9.98954 4.66667 9.33398 4.66667H0.333984ZM11.334 10V8.66667C11.6229 8.66667 11.8618 8.57222 12.0507 8.38333C12.2395 8.19444 12.334 7.95555 12.334 7.66667C12.334 7.37778 12.2395 7.13889 12.0507 6.95C11.8618 6.76111 11.6229 6.66667 11.334 6.66667H0.333984V5.33333H11.334C11.9895 5.33333 12.5423 5.55833 12.9923 6.00833C13.4423 6.45833 13.6673 7.01111 13.6673 7.66667C13.6673 8.32222 13.4423 8.875 12.9923 9.325C12.5423 9.775 11.9895 10 11.334 10Z"
        fill="#757575"
      />
    </svg>
  );
};
export default MeteoVento;
