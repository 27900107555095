import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type ScorerType = {
  avvenimentoKey: string;
  competitor: 1 | 2;
};

export interface MarcatoriState {
  avvenimentoSelected: ScorerType | null;
}

export const initialState: MarcatoriState = {
  avvenimentoSelected: null,
};

export const marcatoriSlice = createSlice({
  name: 'marcatori',
  initialState,
  reducers: {
    setAvvenimentoSelected: (state, action: PayloadAction<ScorerType>) => {
      state.avvenimentoSelected = action.payload;
    },
  },
});

export const { setAvvenimentoSelected } = marcatoriSlice.actions;

export default marcatoriSlice.reducer;
