/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-41.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco41 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-41', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="26.612"
      viewBox="0 0 38.439 26.612"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M328.743,302H297.7a3.7,3.7,0,0,0-3.7,3.7v19.22a3.7,3.7,0,0,0,3.7,3.7h31.047a3.7,3.7,0,0,0,3.7-3.7V305.7A3.7,3.7,0,0,0,328.743,302Zm2.218,22.916a2.22,2.22,0,0,1-2.218,2.218H297.7a2.22,2.22,0,0,1-2.218-2.218V305.7a2.22,2.22,0,0,1,2.218-2.218h31.047a2.22,2.22,0,0,1,2.218,2.218Zm-1.7-19.742a.738.738,0,0,0-1.045,0l-13.433,13.433a2.271,2.271,0,0,1-3.136,0l-13.433-13.433a.739.739,0,1,0-1.045,1.045l9.087,9.087-9.087,9.087a.739.739,0,1,0,1.045,1.045l9.087-9.087,3.3,3.3a3.7,3.7,0,0,0,5.226,0l3.3-3.3,9.087,9.087a.739.739,0,0,0,1.045-1.045l-9.087-9.087,9.087-9.087A.738.738,0,0,0,329.266,305.173Z"
        transform="translate(-294 -302)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco41;
