/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-317.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco317 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-317', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.44"
      height="38.439"
      viewBox="0 0 38.44 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <g transform="translate(22.777 22.776)">
          <path
            className="a"
            d="M168.6,340.474a3.846,3.846,0,0,1-2.739-1.135l-8.833-8.831a.739.739,0,0,1,1.045-1.045l8.833,8.831a2.452,2.452,0,0,0,3.387,0l.018-.018a2.4,2.4,0,0,0-.015-3.372l-8.833-8.831a.739.739,0,0,1,1.045-1.045l8.833,8.831a3.878,3.878,0,0,1,0,5.478l-.021.021A3.849,3.849,0,0,1,168.6,340.474Z"
            transform="translate(-156.812 -324.811)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M141.524,325.047a15.524,15.524,0,1,1,15.524-15.524A15.541,15.541,0,0,1,141.524,325.047Zm0-29.569a14.045,14.045,0,1,0,14.045,14.045A14.061,14.061,0,0,0,141.524,295.478Z"
            transform="translate(-126 -294)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M148.349,314.424V312.8a3.7,3.7,0,0,0,2.957-3.621.739.739,0,1,0-1.478,0,2.215,2.215,0,0,1-1.478,2.082v-1.342a3.686,3.686,0,0,0-1.874-3.2,6.934,6.934,0,0,0,1.07-1.677.739.739,0,1,0-1.35-.6c-.326.727-1.063,1.78-1.543,1.78-.436,0-1.164-.939-1.543-1.781a.739.739,0,0,0-1.349.6,6.935,6.935,0,0,0,1.07,1.677,3.686,3.686,0,0,0-1.874,3.2v1.342a2.214,2.214,0,0,1-1.478-2.082.739.739,0,1,0-1.478,0,3.7,3.7,0,0,0,2.957,3.621v1.628A3.7,3.7,0,0,0,138,318.045a.739.739,0,0,0,1.478,0,2.213,2.213,0,0,1,1.492-2.085,3.685,3.685,0,0,0,7.366,0,2.213,2.213,0,0,1,1.492,2.085.739.739,0,0,0,1.478,0A3.7,3.7,0,0,0,148.349,314.424Zm-3.7-6.728a2.22,2.22,0,0,1,2.218,2.218h-4.435A2.22,2.22,0,0,1,144.653,307.7Zm2.218,8.131a2.218,2.218,0,0,1-4.435,0v-4.435h4.435Z"
          transform="translate(-129.129 -296.608)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco317;
