/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-61.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco61 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-61', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.483"
      height="35.482"
      viewBox="0 0 35.483 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M152.739,212a.739.739,0,1,0,0,1.478A15.541,15.541,0,0,1,168.263,229a.739.739,0,1,0,1.478,0A17.021,17.021,0,0,0,152.739,212Zm0,4.435a.739.739,0,1,0,0,1.478A11.1,11.1,0,0,1,163.827,229a.739.739,0,1,0,1.478,0A12.581,12.581,0,0,0,152.739,216.435Zm0,4.435a.739.739,0,0,0,0,1.478A6.661,6.661,0,0,1,159.392,229a.739.739,0,1,0,1.478,0A8.141,8.141,0,0,0,152.739,220.871Z"
          transform="translate(-134.259 -212)"
          fill="currentColor"
        />
        <g transform="translate(0 1.479)">
          <path
            className="a"
            d="M153.439,248c-4.817,0-11.161-3.135-16.732-8.706-8.086-8.085-11.04-17.8-6.727-22.11a30.347,30.347,0,0,1,3.462-2.761,2.291,2.291,0,0,1,1.785-.381,2.322,2.322,0,0,1,1.505,1.036l4.717,7.484a2.313,2.313,0,0,1-.768,3.215l-2.765,1.658a.836.836,0,0,0-.393.552.806.806,0,0,0,.129.637,47.822,47.822,0,0,0,9.726,9.726.8.8,0,0,0,.638.129.837.837,0,0,0,.551-.393l1.658-2.765a2.312,2.312,0,0,1,3.215-.767l7.484,4.716a2.324,2.324,0,0,1,1.036,1.505,2.284,2.284,0,0,1-.381,1.784,30.3,30.3,0,0,1-2.761,3.463A7.359,7.359,0,0,1,153.439,248Zm-18.665-32.526a.826.826,0,0,0-.479.154,29.243,29.243,0,0,0-3.271,2.6c-3.6,3.6-.522,12.771,6.727,20.021s16.418,10.33,20.021,6.727a29.275,29.275,0,0,0,2.6-3.272.832.832,0,0,0-.237-1.185l-7.483-4.717a.834.834,0,0,0-1.161.277l-1.658,2.765a2.332,2.332,0,0,1-1.515,1.079,2.291,2.291,0,0,1-1.794-.369,49.248,49.248,0,0,1-10.08-10.08,2.3,2.3,0,0,1-.369-1.794,2.334,2.334,0,0,1,1.079-1.515l2.766-1.658a.836.836,0,0,0,.277-1.161l-4.718-7.483a.834.834,0,0,0-.707-.39Z"
            transform="translate(-128 -214)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco61;
