/***************************************************************************************************

  DO NOT EDIT THIS FILE, APPLY YOUR CHANGES TO \common\tasks\policy\template.txt FILE, INSTEAD
  N.B.: placeholders are defined in Dato CMS
      ex: defaultPolicies feeds {#DEFAULT#} placeholder defined in the template
          policyName feeds {#POLICYNAME#}

***************************************************************************************************/
export const GENIUS_URL = 'https://*.geniussports.com';
export const DATOCMS_URL = 'https://www.datocms-assets.com';
export const ACSBAPP_URL = 'https://acsbapp.com/apps/app/dist/js/app.js';
export const BETPOINT_URL = 'https://b2b.betpoint.it/';
export const CDNFORNT_URL = 'https://fonts.cdnfonts.com';
export const SNAI_PSCP_URL = 'https://snai-pscp-staging.mstchannel.com/';
export const CHAT_SERVICE_URL = 'https://chatservice.snai.it/webplayer/';
export const LIVE_BETRDAR_URL = 'https://*.llnwd.net/';
export const DEP_IOVATION_URL = 'https://mpsnare.iesnare.com';
export const GIOCA_ONLINE_URL = 'https://skill-sn.gioconlineitalia.it/';
export const SPID_REGISTRY_URL = 'https://registry.spid.gov.it/entities-idp?&output=json&custom=info_display_base';
export const LIVE_STREAMING_URL = 'https://api.livestreaming.imgarena.com';
export const WIDGET_SIR_RADAR_URL = 'https://widgets.sir.sportradar.com/';
export const DIGITAL_ASSISTANT_URL =
  'https://chatservice.snai.it/webplayer/latest/js/digital-assistant.js?cid=205d106b17323bdccede4dca5681a4c994b54562&view.mobileMode=detect';
export const GOOGLE_TAG_MANAGER_URL = 'https://www.googletagmanager.com/gtm.js';

export const IOVATION_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/iovation`;

const WS = 'ws:';
const WSS = 'wss:';
const BLOB = 'blob:';
const DATA = 'data:';
const SELF = "'self'";
// const HTTPS = 'https:';
const UNSAFE_EVAL = "'unsafe-eval'";
const UNSAFE_INLINE = "'unsafe-inline'";
// const STRICT_DYNAMIC = "'strict-dynamic'";
export const NONCE_PLACEHOLDER = '//-=NoNcE=-//';

export const techs = [SELF, 'http://localhost:3000'];

export const defaults = [
  WS,
  WSS,
  DATOCMS_URL,
  '*',
  'https://*.google.com',
  'https://*.google-analytics.com',
  process.env.NEXT_PUBLIC_TOTOCALCIO_URL,
];

export const scriptElems = [
  ACSBAPP_URL,
  BETPOINT_URL,
  SNAI_PSCP_URL,
  DEP_IOVATION_URL,
  GIOCA_ONLINE_URL,
  WIDGET_SIR_RADAR_URL,
  GOOGLE_TAG_MANAGER_URL,
  'https://*.snai.it',
  '*',
  'https://*.go-mpulse.net/',
  'https://*.adition.com',
  'https://*.snapchat.com',
  'https://*.cookiebot.com',
  'https://*.dynatrace.com',
  'https://*.clarity.ms',
  'https://*.sportradar.com',
  'https://bat.bing.com',
  'https://*.doubleclick.net',
  'https://sc-static.net',
  'https://secure.adnxs.com',
  'https://*.sportradarserving.com',
  'https://connect.facebook.net',
  'https://*.googleadservices.com',
  UNSAFE_INLINE,
];

export const connections = [
  WS,
  WSS,
  GENIUS_URL,
  DATOCMS_URL,
  ACSBAPP_URL,
  LIVE_BETRDAR_URL,
  SPID_REGISTRY_URL,
  LIVE_STREAMING_URL,
  WIDGET_SIR_RADAR_URL,
  GOOGLE_TAG_MANAGER_URL,
  '*',
  'https://*.google.com',
  'https://*.typekit.net',
  'https://*.go-mpulse.net/',
  'https://*.snapchat.com',
  'https://*.cookiebot.com',
  'https://*.dynatrace.com',
  'https://*.akamaihd.net',
  'https://*.clarity.ms',
  'https://google.com',
  'https://*.sportradar.com',
  'https://*.akstat.io/',
  'https://*.doubleclick.net',
  'https://*.akamaized.net',
  'https://*.google-analytics.com',
  'https://chatservice.snai.it',
  'https://*.applicationinsights.azure.com/v2/track',
];

export const images = [
  BLOB,
  DATA,
  DATOCMS_URL,
  'https://*.bing.com',
  '*',
  'https://*.google.com',
  'https://*.facebook.com',
  'https://*.google.it',
  'https://*.bidswitch.net',
  'https://*.sportradar.com',
  'https://*.doubleclick.net',
  'https://*.google-analytics.com',
];

export const scripts = [
  BLOB,
  ACSBAPP_URL,
  DEP_IOVATION_URL,
  DIGITAL_ASSISTANT_URL,
  GOOGLE_TAG_MANAGER_URL,
  'https://*.snai.it',
  '*',
  'https://*.typekit.net',
  'https://*.adition.com',
  'https://*.snapchat.com',
  'https://*.cookiebot.com',
  'https://*.dynatrace.com',
  'https://*.clarity.ms',
  'https://*.sportradar.com',
  'https://bat.bing.com',
  'https://*.doubleclick.net',
  'https://*.woosmap.com',
  'https://sc-static.net',
  'https://secure.adnxs.com',
  'https://*.sportradarserving.com',
  'https://connect.facebook.net',
  process.env.NEXT_PUBLIC_LAUNCH_GAN,
  process.env.NEXT_PUBLIC_LAUNCH_CAPECOD,
  process.env.NEXT_PUBLIC_LAUNCH_BETPOINT,
  process.env.NEXT_PUBLIC_LAUNCH_GAME_360,
  process.env.NEXT_PUBLIC_LAUNCH_ISOFTBET,
  process.env.NEXT_PUBLIC_LAUNCH_MICROGAME,
  process.env.NEXT_PUBLIC_SCRIPT_URL_POKER,
  process.env.NEXT_PUBLIC_CAPTAIN_UP_SDK_URL,
  process.env.NEXT_PUBLIC_VETRINA_SISAL_SCRIPT,
  process.env.NEXT_PUBLIC_LAUNCH_GOL_SLOT_CASINO,
  process.env.NEXT_PUBLIC_PROTOTYPE_LOTTERY_SCRIPT,
  process.env.NEXT_PUBLIC_LAUNCH_FIM_PLAYTECH_CASINO,
  process.env.NEXT_PUBLIC_VETRINA_GRATTA_E_VINCI_SCRIPT,
  process.env.NEXT_PUBLIC_GRATTA_E_VINCI_SCRIPT_SHOWCASE,
  UNSAFE_EVAL,
  UNSAFE_INLINE,
];

export const styles = [CDNFORNT_URL, WIDGET_SIR_RADAR_URL, '*', 'https://*.typekit.net', UNSAFE_INLINE];

export const frames = [
  SNAI_PSCP_URL,
  '*',
  'https://*.snapchat.com',
  'https://*.facebook.com',
  'https://*.cookiebot.com',
  'https://*.jumio.ai',
  'https://*.classic.de',
  'https://*.classic.com',
  'https://*.doubleclick.net',
  'https://*.safecharge.com',
  'https://classic.hpybet.de',
  'https://classic.hpybet.com',
  process.env.NEXT_PUBLIC_PROMO_URL,
  process.env.NEXT_PUBLIC_SNAI_PLUS_URL,
  process.env.NEXT_PUBLIC_BETGENIUS_URL,
  process.env.NEXT_PUBLIC_SCOREBOARD_URL,
  process.env.NEXT_PUBLIC_LAUNCH_SNAISKILL,
  process.env.NEXT_PUBLIC_LAUNCH_GOL_GIOCHI,
  process.env.NEXT_PUBLIC_SEAMLESS_BASE_URL,
  process.env.NEXT_PUBLIC_LOTTOMATICA_IFRAME,
  process.env.NEXT_PUBLIC_GRATTA_E_VINCI_IFRAME,
  process.env.NEXT_PUBLIC_VIRTUAL_WIDGET_IFRAME,
  process.env.NEXT_PUBLIC_LAUNCH_ULR_PLAYTECH_POKER,
  process.env.NEXT_PUBLIC_VIRTUAL_CLASSIFICA_GOLDEN,
  process.env.NEXT_PUBLIC_LAUNCH_CASINO_LIVE_MOBILE,
  process.env.NEXT_PUBLIC_LAUNCH_CASINO_LIVE_DESKTOP,
  process.env.NEXT_PUBLIC_LAUNCH_ULR_PLAYTECH_CASINO,
  UNSAFE_INLINE,
];

export const frameAncestors = ['*', 'https://*.hpybet.de', 'https://*.hpybet.com', 'https://*.azurewebsites.net'];

export const fonts = [DATA, CDNFORNT_URL, '*', 'https://*.typekit.net', 'https://*.googleapis.com'];

export const media = [BLOB, DATA, DEP_IOVATION_URL, '*'];

export const formActions = ['https://*.facebook.com'];
