import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllCarrelloErrors } from 'lib/datoCms/types';
import { CarrelloTipo } from './types';

// Define a type for the slice state
export interface CarrelloState {
  isOpen: boolean;
  tab: string;
  tipo: CarrelloTipo;
  errorsLabels: AllCarrelloErrors[];
  isFullCart: boolean;
  openedBet?: number;
  isLoadingVendita: boolean;
  venditaAlertSuccess: boolean;
  venditaAlertFailed: {
    isOpen: boolean;
    typeError: string;
  };
}

// Define the initial state using that type
const initialState: CarrelloState = {
  isOpen: false,
  tab: 'tab1',
  tipo: CarrelloTipo.Sport,
  errorsLabels: [],
  isFullCart: true,
  isLoadingVendita: false,
  venditaAlertSuccess: false,
  venditaAlertFailed: {
    isOpen: false,
    typeError: '',
  },
};

export const carrelloSlice = createSlice({
  name: 'carrello',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    isLoadingVenditaChanged: (state, action: PayloadAction<boolean>) => {
      state.isLoadingVendita = action.payload;
    },
    carrelloToogled: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        isFullCart?: boolean;
      }>
    ) => {
      state.isOpen = action.payload.isOpen;
      if (action.payload.isFullCart !== undefined) {
        state.isFullCart = action.payload.isFullCart;
      }
    },
    toggleCarrello: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        isFullCart?: boolean;
      }>
    ) => {
      state.isOpen = action.payload.isOpen;
      if (action.payload.isFullCart !== undefined) {
        state.isFullCart = action.payload.isFullCart;
      }
    },
    tabSelected: (state, action: PayloadAction<string>) => {
      state.tab = action.payload;
    },
    errorLabelsUpdated: (state, action: PayloadAction<AllCarrelloErrors[]>) => {
      state.errorsLabels = action.payload;
    },
    setOpenedBet: (state, action: PayloadAction<number>) => {
      state.openedBet = action.payload;
    },
    tipoCarrelloSelected: (state, action: PayloadAction<CarrelloTipo>) => {
      state.tipo = action.payload;
    },
    venditaAlertSuccessOpenChange: (state, action: PayloadAction<boolean>) => {
      state.venditaAlertSuccess = action.payload;
    },
    venditaAlertFailedOpenChange: (state, action: PayloadAction<boolean>) => {
      state.venditaAlertFailed.isOpen = action.payload;
    },
    venditaAlertFailedMessageErrorAdded: (state, action: PayloadAction<string>) => {
      state.venditaAlertFailed.typeError = action.payload;
    },
  },
});

export const {
  toggleCarrello,
  tabSelected,
  errorLabelsUpdated,
  setOpenedBet,
  isLoadingVenditaChanged,
  tipoCarrelloSelected,
  venditaAlertSuccessOpenChange,
  venditaAlertFailedOpenChange,
  venditaAlertFailedMessageErrorAdded,
} = carrelloSlice.actions;

export default carrelloSlice.reducer;
