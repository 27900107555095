/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-gr.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneGr = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-gr', className].join(' ');
  return (
    <svg
      id="nazione-gr"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0h16v1.781H0V0Z" fill="#005BAE" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 1.781h16v1.782H0V1.78Z" fill="#fff" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 3.563h16v1.78H0v-1.78Z" fill="#005BAE" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 5.344h16v1.781H0V5.344Z" fill="#fff" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 7.125h16v1.778H0V7.125Z" fill="#005BAE" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 8.903h16v1.781H0v-1.78Z" fill="#fff" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 10.684h16v1.782H0v-1.782Z" fill="#005BAE" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 12.466h16v1.78H0v-1.78Z" fill="#fff" />
        <path d="M0 0h8.903v8.903H0V0Z" fill="#005BAE" />
        <path fillRule="evenodd" clipRule="evenodd" d="M3.561 0h1.78v8.903h-1.78V0Z" fill="#fff" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 3.561h8.903v1.78H0v-1.78Z" fill="#fff" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 14.219h16V16H0v-1.781Z" fill="#005BAE" />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="16" height="16" rx="8" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NazioneGr;
