/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-140.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco140 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-140', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44.353"
      height="32.526"
      viewBox="0 0 44.353 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(39.918 22.177)">
        <path
          className="a"
          d="M263.7,161.478h-2.957a.739.739,0,1,1,0-1.478H263.7a.739.739,0,0,1,0,1.478Z"
          transform="translate(-260 -160)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(16.928 28.09)">
        <rect className="a" width="14.933" height="1.478" />
      </g>
      <g transform="translate(11.789 7.428)">
        <path
          className="a"
          d="M222.688,141.529a.734.734,0,0,1-.521-.216l0,0a.738.738,0,1,1,.523.218Z"
          transform="translate(-221.948 -140.049)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(8.131 10.18)">
        <path
          className="a"
          d="M217.739,151.333a.739.739,0,1,1,0-1.478,4.414,4.414,0,0,0,4.362-5.214.739.739,0,0,1,1.456-.258,5.895,5.895,0,0,1-5.818,6.951Z"
          transform="translate(-217 -143.772)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M215.61,132.995v-1.516h1.478a.739.739,0,0,0,0-1.478h-4.435a.739.739,0,1,0,0,1.478h1.478v1.516a8.871,8.871,0,1,0,1.478,0Zm-.739,16.225a7.392,7.392,0,1,1,7.392-7.392A7.4,7.4,0,0,1,214.871,149.22Z"
          transform="translate(-206 -130)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M207.208,136.369a.736.736,0,0,0,.475-.173l2.266-1.9a.739.739,0,0,0-.95-1.132l-2.266,1.9a.739.739,0,0,0,.475,1.305Z"
          transform="translate(-206.122 -130.78)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M221.4,140.267a.74.74,0,0,0-1.045,0l-2.759,2.759a1.465,1.465,0,0,0-1.8,1.8l-.579.579a.739.739,0,1,0,1.045,1.045l.578-.578a1.5,1.5,0,0,0,.378.055,1.469,1.469,0,0,0,1.422-1.855l2.759-2.759A.74.74,0,0,0,221.4,140.267Z"
        transform="translate(-208.347 -132.621)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M248.181,147.355l-3.642-7.285a2.942,2.942,0,0,0-2.645-1.634h-5.282v-1.478A2.96,2.96,0,0,0,233.655,134H223.306a.739.739,0,1,0,0,1.478h10.349a1.48,1.48,0,0,1,1.478,1.478v19.959a.739.739,0,1,0,1.478,0v-17h5.282a1.471,1.471,0,0,1,1.322.818l3.287,6.575h-7.674a.739.739,0,0,0,0,1.478H247.7A2.22,2.22,0,0,1,249.918,151v7.392a.74.74,0,0,1-.739.739h-3.031a.739.739,0,1,0,0,1.478h3.031a2.22,2.22,0,0,0,2.218-2.218V151A3.689,3.689,0,0,0,248.181,147.355Zm-31.454,11.779h-3.771a1.48,1.48,0,0,1-1.478-1.478V152.48a.739.739,0,0,0-1.478,0v5.175a2.96,2.96,0,0,0,2.957,2.957h3.771a.739.739,0,1,0,0-1.478Z"
        transform="translate(-207.043 -131.043)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M222.435,162a4.435,4.435,0,1,0,4.435,4.435A4.44,4.44,0,0,0,222.435,162Zm0,7.392a2.957,2.957,0,1,1,2.957-2.957A2.96,2.96,0,0,1,222.435,169.392ZM244.612,162a4.435,4.435,0,1,0,4.435,4.435A4.44,4.44,0,0,0,244.612,162Zm0,7.392a2.957,2.957,0,1,1,2.957-2.957A2.96,2.96,0,0,1,244.612,169.392Z"
        transform="translate(-209.129 -138.345)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco140;
