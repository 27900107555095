/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\trip.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Trip = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-trip', className].join(' ');
  return (
    <svg
      id="trip"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M17 18c-.65 0-1.233-.188-1.75-.563A2.91 2.91 0 0 1 14.175 16H9c-1.1 0-2.042-.392-2.825-1.175C5.392 14.042 5 13.1 5 12s.392-2.042 1.175-2.825C6.958 8.392 7.9 8 9 8h2c.55 0 1.02-.196 1.412-.588C12.804 7.021 13 6.55 13 6c0-.55-.196-1.02-.588-1.412A1.926 1.926 0 0 0 11 4H5.825a3.09 3.09 0 0 1-1.088 1.438A2.852 2.852 0 0 1 3 6a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 0 3C0 2.167.292 1.458.875.875A2.893 2.893 0 0 1 3 0c.65 0 1.23.188 1.737.563.509.375.871.854 1.088 1.437H11c1.1 0 2.042.392 2.825 1.175C14.608 3.958 15 4.9 15 6s-.392 2.042-1.175 2.825C13.042 9.608 12.1 10 11 10H9c-.55 0-1.02.196-1.412.588A1.926 1.926 0 0 0 7 12c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588h5.175a3.09 3.09 0 0 1 1.087-1.438A2.851 2.851 0 0 1 17 12c.833 0 1.542.292 2.125.875S20 14.167 20 15s-.292 1.542-.875 2.125A2.893 2.893 0 0 1 17 18ZM3 4c.283 0 .52-.096.712-.288A.968.968 0 0 0 4 3a.968.968 0 0 0-.288-.712A.968.968 0 0 0 3 2a.968.968 0 0 0-.712.288A.968.968 0 0 0 2 3c0 .283.096.52.288.712.191.192.429.288.712.288Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Trip;
