/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\exclamation-circle.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const ExclamationCircle = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-exclamation-circle', className].join(' ');
  return (
    <svg
      id="exclamation-circle"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M10 19.7743C4.477 19.7743 0 15.3968 0 9.99653C0 4.59626 4.477 0.21875 10 0.21875C15.523 0.21875 20 4.59626 20 9.99653C20 15.3968 15.523 19.7743 10 19.7743ZM9 12.9299V14.8854H11V12.9299H9ZM9 5.10764V10.9743H11V5.10764H9Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default ExclamationCircle;
