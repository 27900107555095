/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-141.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco141 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-141', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="35.482"
      viewBox="0 0 38.439 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M52.739,156.957a.737.737,0,0,0,.523-.217l1.478-1.478a.739.739,0,1,0-1.045-1.045l-1.478,1.478a.739.739,0,0,0,.523,1.262Zm6.436-2.74a.738.738,0,0,0-1.045,0l-5.914,5.914a.739.739,0,1,0,1.045,1.045l5.914-5.914A.738.738,0,0,0,59.176,154.217Z"
          transform="translate(-44.608 -134.78)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M77.047,148.475v17.26a1.48,1.48,0,0,1-1.478,1.478H68.177v-8.871h.739a.739.739,0,1,0,0-1.478h-.739v-8.148a.739.739,0,0,0-1.478,0v14.062H47.478v-14.3H46v17.26a2.96,2.96,0,0,0,2.957,2.957H75.569a2.96,2.96,0,0,0,2.957-2.957v-17.26ZM66.7,167.214H48.957a1.48,1.48,0,0,1-1.478-1.478v-1.478H66.7Z"
          transform="translate(-43.043 -133.21)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M80.439,139.09v0h0a.738.738,0,0,0-.1-.367l-5.275-9.231A2.965,2.965,0,0,0,72.5,128H49.94a2.965,2.965,0,0,0-2.567,1.49L42.1,138.722a.738.738,0,0,0-.1.367h0v0h0a5.169,5.169,0,0,0,9.635,2.606,5.735,5.735,0,0,0,9.585-.039,5.735,5.735,0,0,0,9.585.039,5.169,5.169,0,0,0,9.635-2.606Zm-6.656-8.867,4.643,8.125h-7.04l-2.534-8.871H72.5A1.482,1.482,0,0,1,73.783,130.224ZM52.59,138.349l2.534-8.871H60.48v8.871Zm9.369-8.871h5.356l2.534,8.871h-7.89Zm-13.3.745a1.482,1.482,0,0,1,1.284-.745h3.647l-2.534,8.871h-7.04Zm-1.481,12.561a3.7,3.7,0,0,1-3.621-2.958H50.8A3.7,3.7,0,0,1,47.175,142.784Zm9.24,0a4,4,0,0,1-3.984-2.958H60.4A4,4,0,0,1,56.415,142.784Zm9.61,0a4,4,0,0,1-3.984-2.958h7.967A4,4,0,0,1,66.025,142.784Zm9.24,0a3.7,3.7,0,0,1-3.621-2.958h7.243A3.7,3.7,0,0,1,75.265,142.784Z"
          transform="translate(-42 -128)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco141;
