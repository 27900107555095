/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-28.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco28 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-28', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="38.439"
      viewBox="0 0 35.482 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <g transform="translate(16.263 3.697)">
          <path
            className="a"
            d="M243.61,242.351h-7.392a.739.739,0,1,1,0-1.478h7.392a8.141,8.141,0,0,0,8.131-8.131v-4.435a8.141,8.141,0,0,0-8.131-8.131h-1.478a.739.739,0,0,1-.739-.739v-2.158l-5.469,4.375,5.469,4.375v-2.158a.739.739,0,0,1,.739-.739h1.478a5.18,5.18,0,0,1,5.174,5.175v4.435a5.18,5.18,0,0,1-5.174,5.175h-8.871a.739.739,0,1,1,0-1.478h8.871a3.7,3.7,0,0,0,3.7-3.7v-4.435a3.7,3.7,0,0,0-3.7-3.7h-.739v2.957a.74.74,0,0,1-1.2.577l-7.392-5.914a.74.74,0,0,1,0-1.155l7.392-5.914a.74.74,0,0,1,1.2.577V218.7h.739a9.621,9.621,0,0,1,9.61,9.61v4.435A9.62,9.62,0,0,1,243.61,242.351Z"
            transform="translate(-234 -215.001)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M222.273,248.439C216.512,248.439,212,240,212,229.22S216.512,210,222.273,210a28.609,28.609,0,0,1,4.146.466,2.216,2.216,0,0,1,1.788,2.677l-1.84,8.113a2.218,2.218,0,0,1-2.7,1.661l-2.942-.736a.735.735,0,0,0-.594.1.711.711,0,0,0-.318.48,44.969,44.969,0,0,0,0,12.92.711.711,0,0,0,.318.48.744.744,0,0,0,.594.1l2.942-.737a2.218,2.218,0,0,1,2.7,1.661l1.84,8.114a2.216,2.216,0,0,1-1.788,2.676A28.6,28.6,0,0,1,222.273,248.439Zm0-36.961c-4.767,0-8.794,8.125-8.794,17.741s4.027,17.741,8.794,17.741a27.3,27.3,0,0,0,3.9-.446.738.738,0,0,0,.6-.891l-1.84-8.113a.74.74,0,0,0-.9-.554l-2.941.736a2.242,2.242,0,0,1-1.76-.295,2.2,2.2,0,0,1-.968-1.468,46.387,46.387,0,0,1,0-13.421,2.2,2.2,0,0,1,.968-1.469,2.249,2.249,0,0,1,1.76-.295l2.941.736a.74.74,0,0,0,.9-.554l1.84-8.113a.738.738,0,0,0-.6-.891A27.3,27.3,0,0,0,222.273,211.478Z"
            transform="translate(-212 -210)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco28;
