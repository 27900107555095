/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-it.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneIt = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-it', className].join(' ');
  return (
    <svg
      id="nazione-it"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path fill="#fff" fillRule="evenodd" d="M0 0h16v16H0V0Z" clipRule="evenodd" />
      <path fill="#009246" fillRule="evenodd" d="M0 0h5.334v16H0V0Z" clipRule="evenodd" />
      <path fill="#CE2B37" fillRule="evenodd" d="M10.666 0H16v16h-5.334V0Z" clipRule="evenodd" />
    </svg>
  );
};
export default NazioneIt;
