/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\reload-spin.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const ReloadSpin = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-reload-spin', className].join(' ');
  return (
    <svg
      id="reload-spin"
      width="100%"
      height="100%"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M9.7665 2.23464C8.79984 1.26797 7.47317 0.667969 5.99984 0.667969C4.58535 0.667969 3.2288 1.22987 2.2286 2.23007C1.22841 3.23026 0.666504 4.58681 0.666504 6.0013C0.666504 7.41579 1.22841 8.77234 2.2286 9.77254C3.2288 10.7727 4.58535 11.3346 5.99984 11.3346C8.4865 11.3346 10.5598 9.63464 11.1532 7.33464H9.7665C9.21984 8.88797 7.73984 10.0013 5.99984 10.0013C4.93897 10.0013 3.92156 9.57988 3.17141 8.82973C2.42126 8.07958 1.99984 7.06217 1.99984 6.0013C1.99984 4.94044 2.42126 3.92302 3.17141 3.17288C3.92156 2.42273 4.93897 2.0013 5.99984 2.0013C7.1065 2.0013 8.09317 2.4613 8.81317 3.18797L6.6665 5.33464H11.3332V0.667969L9.7665 2.23464Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default ReloadSpin;
