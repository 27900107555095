/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\instant-panel.webp FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const InstantPanel = ({ className, ...attribs }: React.SVGProps<HTMLImageElement>) => {
  const componentClassName = ['snai-image', 'snai-image-instant-panel', className].join(' ');
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      alt="instant-panel"
      src="data:image/webp;base64,UklGRi4DAABXRUJQVlA4WAoAAAAQAAAALwAALwAAQUxQSKoBAAABgBzZtpvoIr0kgDFLTDwKAVbjZ7YQxpgV4Yx3QRACKlH6X+9WYb55EUSEIrdtGzvBLHoqGr8BQRYCYDRffaxr1Xr9sZqNAUiBRPYE6N+9NQyyeXvoA9JLQgmcPW5Iet+pkqqd9yTrl3OgTIAAy4ZsvTJI9Y5sFoAk+OSfbJUJ1Jb8nUKi08odZYmJq1BG/IJ0zKAjL4ImO+88s9jpziTgFbUjs62CHE2njjvPNrrJwbAH/NLRgI7/Bx3Bgi2pBtZyCUGB8224oxlQNmcoBC+7aMSWj7vQr6lWUG76wL1dJB3vgFcmvxBNgOcbxk1CnXd4NNtFQ3rOV9ZYfbCzRMePNdUSynVN2qJWGlPtYV/YH7G/yP6dzK0xGxm/8+0Yb5bB8w24o7OD4wPQ39iVyroPwaPlP/MCKXDWUM1On6PYhaVVaLmAAD3g3+of/jnooMTE6p+fogzskc5ojwT3lBruqYPhZf4evERpumd9FV39gulvzh7/n+ynUcNiS7o0nbDEsUeGOH+pj3QISR7pkM3jGVCm65yHt21M59xFdU6CjhrPQjpqPorrKABWUDggXgEAAHAIAJ0BKjAAMAA+kUaaS6WjoiGjigCwEgloAL57DkPhRYcDjRkQAdiwwzm4myisawK78pxudXrcREPYcVeim6De474oGFe+hv2XEAAA/vTragEUr4Xdix9swbfYqEgapWQwecwE4AGev+Gsoqvymq7RkODJlbVYxB9Eg4FmtJzZh6Sp8DNZBIT9QYewFxrCLrcqPie0theT/gK08ZsZK/4Cpsz4xpYvK9nSlE0+/8JlszXG8YwBHx9zV1lp/VQFgjT5Ru1X1kvZ4AFXg4rwkZQed0Bnv3EuH4IBCzytAZIpIvdnCsn8E0vdgpsvxE58xMv7RRnmSHKvg3xSYpG0BIzzUG+1xeYmCH1RFlTVZa9WTN2bJoaYGKR4mMy6PZvh7sVGpxFSr57ZDGn21VAWra00+4SoPyWwEYeB+2A05eyORTCRwCqvI9AZiMX9JKI3EYbmFt+/mE3uHfBbU4AA"
      focusable="false"
      className={componentClassName}
      {...attribs}
    />
  );
};
export default InstantPanel;
