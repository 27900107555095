import { Filters } from '../types';
import { GamesSkillGameDto } from 'types/swagger';
import { SlotFilter } from '../SlotArchive';

export type SelectedFiltersType = {
  [key: string]: string[];
};

// NOTE funzione per togliere lo spazio
export const replaceSpace = (label: string) => label.replaceAll(' ', '%20');

// funzione per fare stringa di un filtro solo
export const singleFilterString = (key: string, values: string[]) => {
  let string = '';
  if (values.length === 0) string = '';
  else {
    values.map((value: string, index: number) => {
      if (values.length > 1 && index < values.length - 1) string = string + `${key}Filter=${replaceSpace(value)}`;
      else string = string + `&${key}Filter=${replaceSpace(value)}`;
    });
  }
  return string;
};

// funzione per mettere insieme le varie singleFilterString
export const allFilterString = (object: SelectedFiltersType) => {
  const keys = Object.keys(object);
  let string = '';
  keys.map((key) => {
    if (keys.length > 1 && singleFilterString(key, object[key]) !== '') {
      string = string + `&${singleFilterString(key, object[key])}`;
      //TODO CHECK IF KEYS ARE VOID
    } else string = string + `${singleFilterString(key, object[key])}`;
  });
  return string;
};

export const calculateFiltersList = (filterType: string | string[], list: Array<GamesSkillGameDto>) => {
  let filters: string[] = [];
  list?.map((item: GamesSkillGameDto) => {
    switch (filterType) {
      case 'functionality': {
        const lst = item.functionality ?? [];
        filters = [...filters, ...lst];
        break;
      }
      case 'lines':
        if (item.lines) {
          filters.push(item.lines);
        }
        break;
      case 'producer':
        if (item.produttore) {
          filters.push(item.produttore);
        }
        break;
      case 'category': {
        const lst = item.tema ?? [];
        filters = [...filters, ...lst];
        break;
      }
    }
  });

  return filters.filter((element, index) => {
    return filters.indexOf(element) === index;
  });
};

export const createFiltersObject = (filters: SlotFilter, values: string[]) => {
  return {
    label: filters.label,
    typeOfItem: filters.typeOfItem,
    tooltipContent: filters.tooltipContent,
    values: values,
  };
};

export const calculateAllSelectedFiltersTogether = (filters: Filters): string[] => {
  const selectedFiltersValues = Object.values(filters);
  let allFiltersValues: string[] = [];

  selectedFiltersValues.map((singleFilterValues: string[]) => {
    singleFilterValues.map((singleValue: string) => {
      allFiltersValues.push(singleValue);
    });
  });

  return allFiltersValues;
};

export const removeValueFromSelectedFilters = (filters: Filters, value: string): Filters => {
  const { functionality, volatility, lines, producer, category } = filters;

  const newFilters: Filters = {
    lines: lines,
    producer: producer,
    category: category,
    volatility: volatility,
    functionality: functionality,
  };

  if (functionality.includes(value)) {
    newFilters.functionality = [];
    newFilters.functionality.push(...functionality.filter((item) => item !== value));
  } else if (volatility.includes(value)) {
    newFilters.volatility = [];
    newFilters.volatility.push(...volatility.filter((item) => item !== value));
  } else if (lines.includes(value)) {
    newFilters.lines = [];
    newFilters.lines.push(...lines.filter((item) => item !== value));
  } else if (producer.includes(value)) {
    newFilters.producer = [];
    newFilters.producer.push(...producer.filter((item) => item !== value));
  } else if (category.includes(value)) {
    newFilters.category = [];
    newFilters.category.push(...category.filter((item) => item !== value));
  }

  return newFilters;
};

/* eslint-disable no-unused-vars */
export enum SlotFiltersTypes {
  FUNCTIONALITY = 'funzionalità',
  VOLATILITY = 'volatilità',
  LINES = 'linee',
  VLT = 'vlt',
  PRODUCER = 'produttore',
  CATEGORY = 'categoria',
}
