/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-379.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco379 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-379', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.526"
      height="35.483"
      viewBox="0 0 32.526 35.483"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M158.09,296H134.435A4.44,4.44,0,0,0,130,300.435v11.088a.739.739,0,0,0,.739.739h4.435a.739.739,0,0,0,0-1.478h-3.7V300.435a2.957,2.957,0,1,1,5.914,0v30.308a.746.746,0,0,0,.057.282.74.74,0,0,0,1.206.24l2.434-2.434,2.434,2.434a.739.739,0,0,0,1.045,0L147,328.832l2.434,2.434a.739.739,0,0,0,1.045,0l2.434-2.434,2.434,2.434a.739.739,0,0,0,1.045,0l2.434-2.434,2.434,2.434a.74.74,0,0,0,.241.16.73.73,0,0,0,.565,0,.742.742,0,0,0,.457-.682V300.435A4.44,4.44,0,0,0,158.09,296Zm2.957,32.959-1.7-1.695a.738.738,0,0,0-1.045,0l-2.434,2.434-2.434-2.434a.738.738,0,0,0-1.045,0l-2.434,2.434-2.434-2.434a.738.738,0,0,0-1.045,0l-2.434,2.434-2.434-2.434a.738.738,0,0,0-1.045,0l-1.7,1.695V300.435a4.419,4.419,0,0,0-1.132-2.957H158.09a2.96,2.96,0,0,1,2.957,2.957Zm-15.524-18.174h-2.957a.739.739,0,0,0,0,1.478h1.478V313a.739.739,0,1,0,1.478,0v-.739a2.218,2.218,0,1,0,0-4.435h-1.478a.739.739,0,0,1,0-1.478H147a.739.739,0,1,0,0-1.478h-1.478v-.739a.739.739,0,0,0-1.478,0v.739a2.218,2.218,0,1,0,0,4.435h1.478a.739.739,0,1,1,0,1.478Zm11.827-2.957h-5.914a.739.739,0,1,0,0,1.478h5.914a.739.739,0,1,0,0-1.478Zm0,4.435h-5.914a.739.739,0,1,0,0,1.478h5.914a.739.739,0,1,0,0-1.478Zm0-8.871h-5.914a.739.739,0,1,0,0,1.478h5.914a.739.739,0,1,0,0-1.478Zm0,14.784H142.567a.739.739,0,1,0,0,1.478h14.784a.739.739,0,1,0,0-1.478Zm0,4.435h-2.957a.739.739,0,0,0,0,1.478h2.957a.739.739,0,0,0,0-1.478Zm-7.392,0h-7.392a.739.739,0,0,0,0,1.478h7.392a.739.739,0,0,0,0-1.478Z"
        transform="translate(-130 -296)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco379;
