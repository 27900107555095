import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CardTypes } from '../../components/cards/types';

interface CardsGameArchiveState<T extends CardTypes> {
  allItems: T[];
  filteredItems: T[];
  selectedFilters: Filters;
}

export type Filters = {
  gameMode: string[];
};

const initialState: CardsGameArchiveState<CardTypes> = {
  allItems: [],
  filteredItems: [],
  selectedFilters: {
    gameMode: [],
  },
};
const cardsGameArchiveSlice = createSlice({
  name: 'CardsGameArchiveSlice',
  initialState,
  reducers: {
    setGameModeValue: (state, action: PayloadAction<string>) => {
      state.selectedFilters.gameMode.push(action.payload);
    },
    unsetGameModeValue: (state, action: PayloadAction<string>) => {
      state.selectedFilters.gameMode = state.selectedFilters.gameMode.filter((item: any) => item !== action.payload);
    },
    resetFilters: (state) => {
      state.selectedFilters = {
        gameMode: [],
      };
      state.filteredItems = state.allItems;
    },
    setFilteredItems: (state, action: PayloadAction<any[]>) => {
      state.filteredItems = action.payload;
    },
    setAllItems: (state, action: PayloadAction<any[]>) => {
      state.allItems.push(...action.payload);
    },
    resetAllItems: (state) => {
      state.allItems = [];
    },
  },
});
export const { setGameModeValue, unsetGameModeValue, resetFilters, setFilteredItems, setAllItems, resetAllItems } =
  cardsGameArchiveSlice.actions;

export default cardsGameArchiveSlice.reducer;
