/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-jm.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneJm = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-jm', className].join(' ');
  return (
    <svg
      id="nazione-jm"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path d="m0 0 8 8-8 8V0Zm16 0L8 8l8 8V0Z" fill="#000" />
        <path d="m0 0 8 8 8-8H0Zm0 16 8-8 8 8H0Z" fill="#090" />
        <path d="M16 0h-1.49L0 14.51V16h1.49L16 1.49V0Z" fill="#FC0" />
        <path d="M0 0v1.49L14.51 16H16v-1.49L1.49 0H0Z" fill="#FC0" />
      </g>
    </svg>
  );
};
export default NazioneJm;
