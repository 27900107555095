/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-70.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina70 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-70', className].join(' ');
  return (
    <svg
      id="disciplina_calcio-a-5"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M12 1C5.924 1 1 5.924 1 12s4.924 11 11 11 11-4.924 11-11S18.076 1 12 1Zm5.874 1.873 1.783 1.904 1.624 2.389-2.29-.529-3.171-3.66 2.054-.104Zm-6.175 0 3.442-.176 3.42 4.276-.885 2.891h-2.685l-4.082-4.627.79-2.364Zm-1.658 8.328c-.215-.237-.499-.357-.847-.357-.434 0-.82.19-1.164.567l-1.225-.176.774-4.039h3.991v1.392H8.721l-.236 1.32c.335-.168.68-.25 1.031-.25.67 0 1.238.24 1.702.718.464.481.7 1.104.7 1.869 0 .64-.189 1.207-.563 1.71-.51.683-1.224 1.027-2.13 1.027-.727 0-1.32-.193-1.78-.576-.46-.382-.73-.902-.82-1.55l1.504-.156c.042.336.171.602.382.795.21.198.455.293.73.293.314 0 .58-.125.8-.379.219-.253.326-.631.326-1.138 0-.473-.112-.83-.326-1.07ZM3.845 5.112 8.06 2.134l2.492.765-.34 2.02-5.835 3.807L2.873 7.9l.972-2.789Zm-2.467 9.135-.099-4.146 1.332-1.543 1.556.89-.013 5.616-1.324 1.448-1.452-2.265Zm1.835 3.077 1.267-1.461 6.828.219 1.152 2.995-1.105 1.414-5.594-.16-2.548-3.007Zm8.568 5.19-3.51-.575-2.257-1.208 5.367.43 1.225.795-.825.559Zm1.35-3.733-1.075-3.021 2.703-5.126 2.973-.048 1.783 3.232-2.213 5.117-4.172-.154Zm6.715.472-1.504 1.165-.322-1.431 2.11-4.839 2.02-.855-.074 2.286-2.23 3.674Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina70;
