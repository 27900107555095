/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-57.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina57 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-57', className].join(' ');
  return (
    <svg
      id="disciplina_scherma"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M12.703 3.21c0-.538.182-1.06.517-1.486.335-.426.804-.73 1.334-.866v4.7c-.53-.134-1-.437-1.335-.862a2.397 2.397 0 0 1-.516-1.486Zm5.677 0c0-.788-.293-1.548-.824-2.14A3.313 3.313 0 0 0 15.498 0v6.421a3.31 3.31 0 0 0 2.059-1.07c.53-.591.823-1.352.823-2.14Zm5.314-1.354a.553.553 0 0 0-.21-.028.466.466 0 0 0-.43.333l-.066.23-2.543 6.655h-.004s-.077.159-.186.196a1.172 1.172 0 0 0-.225-.023c-.586 0-1.059.412-1.059.923 0 .469.401.858.926.919h.005a.902.902 0 0 0 .291-.005c.09-.004.267 0 .381.08a1.123 1.123 0 0 0 .954.51c.601 0 1.088-.44 1.088-.983 0-.441-.32-.816-.763-.938 0-.028.01-.108.028-.234l2-6.805.09-.235a.457.457 0 0 0-.014-.353.47.47 0 0 0-.263-.242Zm-3.72 9.716h-2.64l-1.387-3.3a.662.662 0 0 0-.034-.089c-.653-1.785-1.646-2.198-2.943-2.428-.95-.168-2.476-.22-3.225-.239a1.183 1.183 0 0 0-.13-.004H6.11V1.65c0-.306-.123-.6-.343-.816a1.184 1.184 0 0 0-1.66 0c-.22.217-.344.51-.344.816v4.626a1 1 0 0 0 .005.117 1.145 1.145 0 0 0 .311 1.086c.22.216.518.338.83.339h3.859c-.463.895-1.508 2.905-1.508 2.905-.019.038-.033.075-.052.118-.277.45-.391 1.242-.258 2.113.105.69.339 1.29.63 1.683.027.051.059.1.095.145l3.073 3.881-2.119 3.604c-.154.266-.196.58-.116.877.08.296.277.548.546.703a1.2 1.2 0 0 0 .892.114 1.18 1.18 0 0 0 .716-.536l2.49-4.242a1.147 1.147 0 0 0 .047-1.059 1.23 1.23 0 0 0-.19-.356l-2.557-3.23 3.215-5.656h.014c.015.088.04.174.072.257l1.665 3.956c.108.224.275.416.484.556.209.14.452.223.704.24h3.354c.31 0 .609-.121.829-.338.22-.216.344-.509.344-.814a1.13 1.13 0 0 0-.333-.823 1.168 1.168 0 0 0-.83-.344ZM5.99 12.786a1.067 1.067 0 0 0-.29.323L.164 22.244a1.143 1.143 0 0 0 .41 1.584 1.19 1.19 0 0 0 1.613-.403L7.044 15.4a3.796 3.796 0 0 1-.682-.984 3.62 3.62 0 0 1-.372-1.631Z"
      />
    </svg>
  );
};
export default Disciplina57;
