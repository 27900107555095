import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
export interface HeaderSlice {
  headerIsSticky: boolean;
}

// Define the initial state using that type
const initialState: HeaderSlice = {
  headerIsSticky: true,
};

export const headerSlice = createSlice({
  name: 'header',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    toggleHeaderVisibility: (state, action: PayloadAction<boolean>) => {
      if (state.headerIsSticky !== action.payload) {
        state.headerIsSticky = action.payload;
      }
    },
  },
});

export const { toggleHeaderVisibility } = headerSlice.actions;

export default headerSlice.reducer;
