/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-101.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco101 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-101', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.569"
      height="41.396"
      viewBox="0 0 29.569 41.396"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M496.567,506.044l-4.475-12.3a.739.739,0,1,0-1.389.506l3.865,10.62-4.067-1.9a.742.742,0,0,0-.982.358l-1.9,4.068-3.535-9.711a.732.732,0,0,0-.45-.893.74.74,0,0,0-.947.442l-3.7,10.162-1.9-4.068a.741.741,0,0,0-.982-.358l-4.067,1.9,3.865-10.62a.739.739,0,0,0-1.389-.5l-4.476,12.3a.739.739,0,0,0,1.008.923l5.015-2.338,2.338,5.015a.74.74,0,0,0,.67.427h.033a.739.739,0,0,0,.662-.486l3.536-9.717,3.536,9.717a.74.74,0,0,0,.662.486h.032a.741.741,0,0,0,.67-.427l2.338-5.015,5.015,2.338a.739.739,0,0,0,1.007-.923Z"
        transform="translate(-468.522 -468.674)"
        fill="currentColor"
      />
      <g transform="translate(7.392 7.392)">
        <path
          className="a"
          d="M481.907,484.784a1.707,1.707,0,0,1-1.677-1.981l.432-2.671a1.2,1.2,0,0,0-.322-1.027l-1.858-1.91a1.706,1.706,0,0,1,.96-2.875l2.446-.375a1.187,1.187,0,0,0,.892-.67l1.072-2.294a1.7,1.7,0,0,1,3.079,0L488,473.276a1.184,1.184,0,0,0,.891.67l2.445.375a1.706,1.706,0,0,1,.96,2.875l-1.858,1.91a1.2,1.2,0,0,0-.322,1.027l.433,2.671a1.7,1.7,0,0,1-2.5,1.765l-2.091-1.161a1.171,1.171,0,0,0-1.142,0l-2.09,1.161A1.693,1.693,0,0,1,481.907,484.784Zm3.485-13.306a.211.211,0,0,0-.2.129l-1.072,2.294a2.659,2.659,0,0,1-2.006,1.506l-2.445.374a.212.212,0,0,0-.176.151.222.222,0,0,0,.052.233l1.858,1.91a2.687,2.687,0,0,1,.721,2.294l-.433,2.672a.219.219,0,0,0,.093.223.207.207,0,0,0,.231.013l2.09-1.161h0a2.638,2.638,0,0,1,2.577,0l2.09,1.161a.208.208,0,0,0,.231-.013.219.219,0,0,0,.093-.223l-.433-2.672a2.685,2.685,0,0,1,.721-2.294l1.858-1.91a.221.221,0,0,0,.052-.233.212.212,0,0,0-.177-.151l-2.445-.374a2.656,2.656,0,0,1-2.006-1.506l-1.074-2.294A.208.208,0,0,0,485.392,471.478Z"
          transform="translate(-478 -470)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M482.784,489.569a3.861,3.861,0,0,1-2.327-1.123,3.866,3.866,0,0,0-1.137-.735,4,4,0,0,0-1.415.064,2.911,2.911,0,0,1-3.946-2.294,2.282,2.282,0,0,0-1.872-1.873,2.909,2.909,0,0,1-2.293-3.945,4,4,0,0,0,.064-1.416,3.89,3.89,0,0,0-.735-1.137,2.971,2.971,0,0,1,0-4.653,3.89,3.89,0,0,0,.735-1.137,4,4,0,0,0-.064-1.416,2.91,2.91,0,0,1,2.293-3.945,2.284,2.284,0,0,0,1.873-1.874,2.91,2.91,0,0,1,3.945-2.292,4.036,4.036,0,0,0,1.415.064,3.869,3.869,0,0,0,1.137-.735,2.972,2.972,0,0,1,4.653,0,3.89,3.89,0,0,0,1.137.735,4.04,4.04,0,0,0,1.416-.064,2.91,2.91,0,0,1,3.945,2.294,2.283,2.283,0,0,0,1.873,1.873,2.909,2.909,0,0,1,2.293,3.944,4.006,4.006,0,0,0-.064,1.416,3.888,3.888,0,0,0,.735,1.137,2.971,2.971,0,0,1,0,4.653,3.888,3.888,0,0,0-.735,1.137,4.01,4.01,0,0,0,.064,1.416,2.909,2.909,0,0,1-2.294,3.945,2.281,2.281,0,0,0-1.873,1.872,2.909,2.909,0,0,1-3.944,2.293,4,4,0,0,0-1.416-.064,3.887,3.887,0,0,0-1.137.735A3.856,3.856,0,0,1,482.784,489.569Zm-3.867-3.38a2.993,2.993,0,0,1,.784.1,4.772,4.772,0,0,1,1.682,1.011,1.63,1.63,0,0,0,2.8,0,4.773,4.773,0,0,1,1.682-1.011,4.936,4.936,0,0,1,2.017.03,1.571,1.571,0,0,0,2.346-1.367,3.633,3.633,0,0,1,2.715-2.714,1.572,1.572,0,0,0,1.368-2.346,4.942,4.942,0,0,1-.03-2.018,4.766,4.766,0,0,1,1.011-1.682,1.63,1.63,0,0,0,0-2.8,4.766,4.766,0,0,1-1.011-1.682,4.939,4.939,0,0,1,.03-2.017,1.571,1.571,0,0,0-1.368-2.345,3.633,3.633,0,0,1-2.714-2.715,1.573,1.573,0,0,0-2.347-1.368,4.924,4.924,0,0,1-2.017.03,4.774,4.774,0,0,1-1.682-1.011,1.63,1.63,0,0,0-2.8,0,4.773,4.773,0,0,1-1.682,1.011,4.912,4.912,0,0,1-2.017-.03,1.571,1.571,0,0,0-2.345,1.367,3.635,3.635,0,0,1-2.715,2.715,1.573,1.573,0,0,0-1.368,2.347,4.936,4.936,0,0,1,.03,2.017,4.764,4.764,0,0,1-1.011,1.682,1.631,1.631,0,0,0,0,2.8,4.763,4.763,0,0,1,1.011,1.682,4.937,4.937,0,0,1-.03,2.017,1.571,1.571,0,0,0,1.368,2.345,3.633,3.633,0,0,1,2.714,2.715,1.573,1.573,0,0,0,2.347,1.368A8.34,8.34,0,0,1,478.918,486.188Z"
          transform="translate(-468 -460)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco101;
