/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-222.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco222 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-222', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.655"
      height="38.439"
      viewBox="0 0 23.655 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M71.959,210H55.7a3.7,3.7,0,0,0-3.7,3.7v31.047a3.7,3.7,0,0,0,3.7,3.7H71.959a3.7,3.7,0,0,0,3.7-3.7V213.7A3.7,3.7,0,0,0,71.959,210Zm-18.48,7.392h20.7v23.655h-20.7Zm2.218-5.914H71.959a2.22,2.22,0,0,1,2.218,2.218v2.218h-20.7V213.7A2.22,2.22,0,0,1,55.7,211.478Zm16.263,35.482H55.7a2.22,2.22,0,0,1-2.218-2.218v-2.218h20.7v2.218A2.22,2.22,0,0,1,71.959,246.961Z"
        transform="translate(-52 -210)"
        fill="currentColor"
      />
      <g transform="translate(9.61 34.004)">
        <path
          className="a"
          d="M68.7,257.478H65.739a.739.739,0,1,1,0-1.478H68.7a.739.739,0,0,1,0,1.478Z"
          transform="translate(-65 -256)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(11.088 2.961)">
        <path
          className="a"
          d="M67.739,215.48a.742.742,0,0,1-.525-1.264.764.764,0,0,1,.244-.155.755.755,0,0,1,.806.155.739.739,0,0,1-.525,1.264Z"
          transform="translate(-67 -214.006)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(4.435 17.741)">
        <path
          className="a"
          d="M69.827,244.349H60.957A2.96,2.96,0,0,1,58,241.392v-4.435A2.96,2.96,0,0,1,60.957,234h8.871a2.96,2.96,0,0,1,2.957,2.957v4.435A2.96,2.96,0,0,1,69.827,244.349Zm-8.871-8.871a1.48,1.48,0,0,0-1.478,1.478v4.435a1.48,1.48,0,0,0,1.478,1.478h8.871a1.48,1.48,0,0,0,1.478-1.478v-4.435a1.48,1.48,0,0,0-1.478-1.478Z"
          transform="translate(-58 -234)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(7.392 10.349)">
        <path
          className="a"
          d="M70.131,232.871a.739.739,0,0,1-.739-.739v-3.7a2.957,2.957,0,0,0-5.914,0v3.7a.739.739,0,0,1-1.478,0v-3.7a4.435,4.435,0,1,1,8.871,0v3.7A.739.739,0,0,1,70.131,232.871Z"
          transform="translate(-62 -224)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M68.957,239.478a1.478,1.478,0,1,0-2.218,1.273v.945a.739.739,0,1,0,1.478,0v-.945A1.474,1.474,0,0,0,68.957,239.478Z"
        transform="translate(-55.651 -217.302)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco222;
