/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-esports-NBA2K-league.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const DisciplinaEsportsNBA2KLeague = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-esports-NBA2K-league', className].join(' ');
  return (
    <svg
      id="disciplina_esports-NBA2K-league"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M12.49 11.51h2.977a10.71 10.71 0 0 1 3.148-7.136c.457-.458.954-.873 1.485-1.243A11.963 11.963 0 0 0 12.489 0l.001 11.51Zm3.959 0H24a11.965 11.965 0 0 0-3.188-7.67 9.809 9.809 0 0 0-4.363 7.67ZM3.9 3.13c.53.37 1.027.785 1.484 1.243a10.71 10.71 0 0 1 3.148 7.135h2.977V0A11.963 11.963 0 0 0 3.9 3.131Zm3.65 8.378a9.808 9.808 0 0 0-4.363-7.67A11.965 11.965 0 0 0 0 11.51h7.551Zm11.22 1.128v.008h-5.699v-.019h-2.46v.019H5.202v-.008A5.59 5.59 0 0 0 0 18.24c0 3.101 2.527 5.609 5.626 5.609a5.591 5.591 0 0 0 4.8-2.703h3.176a5.583 5.583 0 0 0 4.792 2.703c3.101 0 5.604-2.516 5.604-5.615a5.61 5.61 0 0 0-5.226-5.597v.001ZM8.309 19.018H6.346v1.88H4.874v-1.88H2.912v-1.55h1.962v-1.88h1.472v1.88h1.962v1.55Zm7.571-.016a.953.953 0 1 1 0-1.91.953.953 0 0 1 0 1.911v-.001Zm2.517 2.517a.953.953 0 1 1 0-1.908.953.953 0 0 1 0 1.91v-.002Zm0-5.034a.953.953 0 1 1 0-1.909.953.953 0 0 1 0 1.91v-.001Zm2.518 2.517a.954.954 0 1 1-.001-1.91.954.954 0 0 1 0 1.911v-.001Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default DisciplinaEsportsNBA2KLeague;
