/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-313.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco313 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-313', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="35.482"
      viewBox="0 0 41.396 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(8.87 0)">
        <path
          className="a"
          d="M326.915,493.569a.739.739,0,0,1-.738-.7l-1.036-19.686a8.13,8.13,0,0,0-8.12-7.7h-2.388a8.13,8.13,0,0,0-8.12,7.7l-1.036,19.686A.74.74,0,0,1,304,492.79l1.036-19.686a9.609,9.609,0,0,1,9.6-9.1h2.388a9.609,9.609,0,0,1,9.6,9.1l1.036,19.686a.74.74,0,0,1-.7.778Z"
          transform="translate(-304 -464)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M327.285,476.761a.736.736,0,0,0,.253-.044l4.168-1.517a.739.739,0,0,0-.506-1.389l-4.168,1.517a.739.739,0,0,0,.252,1.433Zm-29.427,9.185-4.168,1.517a.739.739,0,0,0,.506,1.389l4.168-1.517a.739.739,0,0,0-.5-1.389Zm.5-10.619L294.2,473.81a.739.739,0,1,0-.506,1.389l4.168,1.517a.739.739,0,0,0,.5-1.389Zm33.343,12.136-4.168-1.517a.739.739,0,0,0-.5,1.389l4.168,1.517a.739.739,0,0,0,.506-1.389Zm.951-6.871h-4.435a.739.739,0,0,0,0,1.479h4.435a.739.739,0,0,0,0-1.479Zm-34.743.739a.739.739,0,0,0-.739-.739h-4.435a.739.739,0,0,0,0,1.479h4.435A.739.739,0,0,0,297.914,481.331Z"
        transform="translate(-292 -466.547)"
        fill="currentColor"
      />
      <g transform="translate(13.545 4.435)">
        <path
          className="a"
          d="M311.064,490.7h-.04a.74.74,0,0,1-.7-.778l.79-15.017a5.175,5.175,0,0,1,5.169-4.9h2.386a.739.739,0,1,1,0,1.478h-2.386a3.7,3.7,0,0,0-3.691,3.5L311.8,490A.739.739,0,0,1,311.064,490.7Z"
          transform="translate(-310.324 -470)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(5.914 28.09)">
        <path
          className="a"
          d="M328.829,509.392h-28.09a.739.739,0,0,1-.739-.739v-3.7A2.96,2.96,0,0,1,302.957,502h23.655a2.96,2.96,0,0,1,2.957,2.957v3.7A.739.739,0,0,1,328.829,509.392Zm-27.351-1.478H328.09v-2.957a1.48,1.48,0,0,0-1.478-1.478H302.957a1.48,1.48,0,0,0-1.478,1.478Z"
          transform="translate(-300 -502)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco313;
