/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-369.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco369 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-369', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="32.526"
      viewBox="0 0 41.396 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M78.439,130H42.957A2.96,2.96,0,0,0,40,132.957v22.177a2.96,2.96,0,0,0,2.957,2.957H57.741v2.957h-3.7a.739.739,0,1,0,0,1.478H67.351a.739.739,0,0,0,0-1.478h-3.7V158.09H78.439a2.96,2.96,0,0,0,2.957-2.957V132.957A2.96,2.96,0,0,0,78.439,130ZM62.177,161.047H59.22V158.09h2.957Zm17.741-5.914a1.48,1.48,0,0,1-1.478,1.478H42.957a1.48,1.48,0,0,1-1.478-1.478V132.957a1.48,1.48,0,0,1,1.478-1.478H78.439a1.48,1.48,0,0,1,1.478,1.478ZM71.172,147.9l2.071-.887a.74.74,0,0,0-.032-1.372l-10.036-3.763a.739.739,0,0,0-.951.951l3.763,10.036a.74.74,0,0,0,.676.479.726.726,0,0,0,.7-.447l.887-2.071,2.615,2.615a.739.739,0,0,0,1.045,0l1.881-1.881a.738.738,0,0,0,0-1.045Zm.211,3.974-2.864-2.864a.739.739,0,0,0-1.2.231l-.592,1.382-2.546-6.789,6.789,2.546-1.382.592a.739.739,0,0,0-.231,1.2l2.864,2.864Zm-16.6-10.043h2.957a.739.739,0,1,0,0-1.478H56.263v-1.478a.739.739,0,0,0-1.478,0v1.478a2.218,2.218,0,1,0,0,4.435h1.478a.739.739,0,0,1,0,1.478H53.306a.739.739,0,1,0,0,1.478h1.478v1.478a.739.739,0,0,0,1.478,0v-1.478a2.218,2.218,0,0,0,0-4.435H54.784a.739.739,0,1,1,0-1.478Zm19.959-5.914H67.351a.739.739,0,1,0,0,1.478h7.392a.739.739,0,1,0,0-1.478Zm0,4.435H67.351a.739.739,0,1,0,0,1.478h7.392a.739.739,0,1,0,0-1.478Zm-12.908,8.592a8.136,8.136,0,1,1,.654-9.3.739.739,0,0,0,1.267-.761,9.6,9.6,0,1,0-.775,11,.739.739,0,0,0-1.147-.932Z"
        transform="translate(-40 -130)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco369;
