/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-5.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina5 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-5', className].join(' ');
  return (
    <svg
      id="disciplina_volley"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M12 3c-4.968 0-9 4.032-9 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9Zm0 .668c.54 0 1.077.051 1.607.155-3.439.917-6.058 2.97-8.199 5.298a10.26 10.26 0 0 1-.935-.7A8.322 8.322 0 0 1 12 3.668Zm2.953.538a8.321 8.321 0 0 1 2.176 1.223c-2.97.802-5.878 2.676-8.377 5.221-.94-.302-1.864-.657-2.767-1.174 2.317-2.493 5.14-4.595 8.968-5.27Zm2.795 1.758c.472.45.89.954 1.248 1.5-2.89.848-5.203 2.293-7.084 4.156-.819-.274-1.635-.502-2.44-.745 2.507-2.482 5.397-4.247 8.276-4.911Zm1.596 2.091a8.348 8.348 0 0 1-.995 9.341c.837-3.28.064-5.93-.53-8.83.492-.19.998-.36 1.525-.51Zm-2.151.767c.667 3.234 1.476 5.938.15 9.573a8.375 8.375 0 0 1-2.299 1.364c.813-2.943.799-6.268-.147-9.707.73-.473 1.497-.885 2.296-1.23ZM4.199 9.06c2.447 1.976 5.034 2.366 7.478 3.182.042.878.08 1.75.08 2.612-3.43-.897-5.948-2.103-7.973-4.279a8.678 8.678 0 0 1 .415-1.515Zm10.114 1.392c.904 3.428.844 6.712-.042 9.57a8.365 8.365 0 0 1-2.735.299c1.062-2.634.939-5.436.802-8.188a15.834 15.834 0 0 1 1.975-1.68Zm-10.627.978c2.095 2.074 4.696 3.248 8.064 4.113-.024.928-.105 1.846-.291 2.735-2.243-.45-5.045-1.199-7.52-4.16a8.442 8.442 0 0 1-.253-2.688Zm.857 4.292c2.31 2.145 4.806 2.809 6.76 3.203a9.74 9.74 0 0 1-.456 1.329 8.33 8.33 0 0 1-6.304-4.532Z"
      />
    </svg>
  );
};
export default Disciplina5;
