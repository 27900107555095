/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-148.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina148 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-148', className].join(' ');
  return (
    <svg
      id="disciplina_corsa-delle-biglie"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M12 3c-4.968 0-9 4.032-9 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9ZM8.625 17.184c-.567.477-1.404.405-1.881-.162l-.999-1.179c-.477-.567-.405-1.404.162-1.881.567-.477 1.404-.405 1.881.162l.999 1.179c.468.558.396 1.404-.162 1.881Zm7.938-6.768c-.639.342-1.449.072-1.809-.558l-.504-.891c-.099-.171-.297-.414-.45-.54l-.648-.549c-.594-.504-.63-1.431-.063-1.98.504-.486 1.305-.468 1.836-.009l.648.549c.693.513 1.341 1.62 1.413 1.872l.099.261c.279.711.153 1.485-.522 1.845Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina148;
