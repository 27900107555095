/************************************************************************************************************
    DO NOT MODIFY IN THIS FILE
************************************************************************************************************/
import React, { SVGProps, useCallback, useMemo } from 'react';

import dynamic from 'next/dynamic';
import type { IconProps } from 'components/Icons';
import { camelize } from 'components/Icons';

export const Dynamic = ({ iconId, ...oth }: IconProps): JSX.Element => {
  const componentId = camelize(iconId);

  const loading = useCallback(
    () => (
      <svg
        role="img"
        aria-label="fallback"
        className="snai-image snai-image-fallback"
        {...(oth as SVGProps<SVGSVGElement>)}
      />
    ),
    [oth]
  );

  const Icon = useMemo(
    () =>
      dynamic<Partial<IconProps>>(
        () => import(`components/Icons/${componentId}`).then((obj) => Reflect.get(obj, componentId)),
        { loading }
      ),
    [componentId, loading]
  );

  if (!iconId) return loading();

  return <Icon {...oth} />;
};
