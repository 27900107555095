/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\double-chevron-right.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const DoubleChevronRight = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-double-chevron-right', className].join(' ');
  return (
    <svg
      id="double-chevron-right"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 16 16"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="m4 12.333 3.5-4.666L4 3h1.233l3.5 4.667-3.5 4.666H4Zm4.2 0 3.5-4.666L8.2 3h1.233l3.5 4.667-3.5 4.666H8.2Z"
        fill="currentColor"
        fillOpacity=".6"
      />
    </svg>
  );
};
export default DoubleChevronRight;
