/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-00.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina00 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-00', className].join(' ');
  return (
    <svg
      id="disciplina_top"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.451 3.155a.499.499 0 0 0-.722 0 .499.499 0 0 0 0 .722 5.964 5.964 0 0 1 1.74 4.23 5.883 5.883 0 0 1-1.755 4.214.499.499 0 0 0 0 .722.512.512 0 0 0 .369.148.512.512 0 0 0 .368-.148A6.943 6.943 0 0 0 15.5 8.092a6.91 6.91 0 0 0-2.049-4.937ZM2.531 8.106c0-1.606.62-3.095 1.754-4.215a.499.499 0 0 0 0-.722.499.499 0 0 0-.722 0C2.223 4.466 1.5 6.235 1.5 8.106c0 1.872.722 3.625 2.048 4.952a.511.511 0 0 0 .369.147.511.511 0 0 0 .368-.147.499.499 0 0 0 0-.722 5.942 5.942 0 0 1-1.753-4.23Zm8.46-3.212a.499.499 0 0 1 .721 0 4.595 4.595 0 0 1 1.341 3.198 4.493 4.493 0 0 1-1.326 3.197.512.512 0 0 1-.369.148.512.512 0 0 1-.368-.147.498.498 0 0 1 0-.723 3.491 3.491 0 0 0 1.032-2.475c0-.929-.369-1.813-1.032-2.476a.498.498 0 0 1 0-.722ZM4.991 8.106c0-.943.369-1.813 1.032-2.476a.499.499 0 0 0 0-.722.499.499 0 0 0-.722 0 4.501 4.501 0 0 0-1.327 3.213c0 1.208.487 2.372 1.342 3.213a.512.512 0 0 0 .368.147.512.512 0 0 0 .368-.147.511.511 0 0 0 0-.723 3.492 3.492 0 0 1-1.06-2.505ZM7.188 6.56c0-.368.413-.59.722-.383l2.314 1.518c.28.191.28.59 0 .78L7.91 10.023a.464.464 0 0 1-.722-.383V6.56Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina00;
