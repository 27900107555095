/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\filter-vertical.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const FilterVertical = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-filter-vertical', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M31.7295 6C32.1273 6 32.5089 6.15804 32.7902 6.43934C33.0715 6.72064 33.2295 7.10218 33.2295 7.5V11.475C34.3082 11.8014 35.2531 12.4662 35.9248 13.3711C36.5964 14.276 36.959 15.3731 36.959 16.5C36.959 17.6269 36.5964 18.724 35.9248 19.6289C35.2531 20.5338 34.3082 21.1986 33.2295 21.525V40.5C33.2295 40.8978 33.0715 41.2794 32.7902 41.5607C32.5089 41.842 32.1273 42 31.7295 42C31.3317 42 30.9502 41.842 30.6689 41.5607C30.3875 41.2794 30.2295 40.8978 30.2295 40.5V21.525C29.1509 21.1986 28.2059 20.5338 27.5343 19.6289C26.8626 18.724 26.5 17.6269 26.5 16.5C26.5 15.3731 26.8626 14.276 27.5343 13.3711C28.2059 12.4662 29.1509 11.8014 30.2295 11.475V7.5C30.2295 7.10218 30.3875 6.72064 30.6689 6.43934C30.9502 6.15804 31.3317 6 31.7295 6ZM16.7295 40.5V33.525C17.8082 33.1986 18.7531 32.5338 19.4248 31.6289C20.0964 30.724 20.459 29.6269 20.459 28.5C20.459 27.3731 20.0964 26.276 19.4248 25.3711C18.7531 24.4662 17.8082 23.8014 16.7295 23.475V7.5C16.7295 7.10218 16.5715 6.72064 16.2902 6.43934C16.0089 6.15804 15.6273 6 15.2295 6C14.8317 6 14.4502 6.15804 14.1689 6.43934C13.8876 6.72064 13.7295 7.10218 13.7295 7.5V23.475C12.6509 23.8014 11.7059 24.4662 11.0343 25.3711C10.3626 26.276 10 27.3731 10 28.5C10 29.6269 10.3626 30.724 11.0343 31.6289C11.7059 32.5338 12.6509 33.1986 13.7295 33.525V40.5C13.7295 40.8978 13.8876 41.2794 14.1689 41.5607C14.4502 41.842 14.8317 42 15.2295 42C15.6273 42 16.0089 41.842 16.2902 41.5607C16.5715 41.2794 16.7295 40.8978 16.7295 40.5Z"
        fill="#509DD4"
      />
    </svg>
  );
};
export default FilterVertical;
