/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-159.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco159 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-159', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="35.482"
      viewBox="0 0 41.396 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M302.739,240.957a.737.737,0,0,0,.523-.217l1.478-1.478a.739.739,0,0,0-1.045-1.045l-1.478,1.478a.739.739,0,0,0,.523,1.262Zm6.436-2.74a.738.738,0,0,0-1.045,0l-5.914,5.914a.739.739,0,1,0,1.045,1.045l5.914-5.914A.738.738,0,0,0,309.175,238.217Z"
        transform="translate(-294.608 -218.78)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M324.09,251.129H298.957a1.48,1.48,0,0,1-1.478-1.478v-1.478h18.48a.739.739,0,1,0,0-1.478h-18.48v-14.3a.739.739,0,0,0-1.478,0v17.26a2.96,2.96,0,0,0,2.957,2.957H324.09a.739.739,0,0,0,0-1.478Z"
        transform="translate(-293.043 -217.125)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M330.349,224A10.36,10.36,0,0,0,320,234.349c0,7.3,9.458,15.723,9.86,16.078a.738.738,0,0,0,.977,0c.4-.355,9.86-8.781,9.86-16.078A10.36,10.36,0,0,0,330.349,224Zm0,24.867c-1.926-1.818-8.871-8.777-8.871-14.518a8.871,8.871,0,0,1,17.741,0C339.22,240.089,332.275,247.048,330.349,248.867Zm0-18.214a3.7,3.7,0,1,0,3.7,3.7A3.7,3.7,0,0,0,330.349,230.653Zm0,5.914a2.218,2.218,0,1,1,2.218-2.218A2.22,2.22,0,0,1,330.349,236.567Z"
        transform="translate(-299.302 -215.129)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M328.318,219.181l-3.252-5.69A2.963,2.963,0,0,0,322.5,212H299.94a2.962,2.962,0,0,0-2.567,1.49l-5.275,9.231a.738.738,0,0,0-.1.367h0v0a5.169,5.169,0,0,0,9.635,2.605,5.626,5.626,0,0,0,4.78,2.565,5.438,5.438,0,0,0,5.481-4.435h1.587a.739.739,0,1,0,0-1.478h-1.524v-8.871h5.356l1.672,5.852a.74.74,0,0,0,1.422-.407l-1.556-5.445H322.5a1.481,1.481,0,0,1,1.284.745l3.251,5.691a.739.739,0,1,0,1.284-.734Zm-29.663-4.957a1.481,1.481,0,0,1,1.284-.745h3.647l-2.535,8.871h-7.039Zm-1.481,12.561a3.7,3.7,0,0,1-3.621-2.958H300.8A3.7,3.7,0,0,1,297.175,226.784Zm9.24,0a4,4,0,0,1-3.984-2.958H310.4A4,4,0,0,1,306.415,226.784Zm4.066-4.435h-7.89l2.535-8.871h5.356Z"
        transform="translate(-292 -212)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco159;
