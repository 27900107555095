/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-92.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina92 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-92', className].join(' ');
  return (
    <svg
      id="disciplina_football-australiano"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M21.287 2.031C19.059.03 10.846.704 5.704 6.183.56 11.66-.228 19.573 2.669 21.883c2.752 2.197 10.44 1.327 15.578-4.157 5.138-5.483 6.044-12.995 3.04-15.695Z"
        fill="currentColor"
      />
      <path
        d="m18.557 5.037-.054-.049a.455.455 0 0 0-.637.027l-2.434 2.62-2.304-1.612a.49.49 0 0 0-.677.115.48.48 0 0 0 .116.67l2.201 1.537-2.631 2.832L9.913 9.62a.49.49 0 0 0-.677.116.48.48 0 0 0 .117.67l2.125 1.487-2.56 2.758-1.986-1.39a.49.49 0 0 0-.677.115.48.48 0 0 0 .117.67l1.882 1.32-2.178 2.344a.445.445 0 0 0 .027.63l.053.049c.184.164.471.155.637-.027l2.264-2.433 2.282 1.594a.49.49 0 0 0 .677-.115.48.48 0 0 0-.117-.67L9.72 15.212l2.56-2.757 2.036 1.425a.49.49 0 0 0 .677-.115.48.48 0 0 0-.117-.67l-1.932-1.355 2.631-2.832 1.964 1.372a.49.49 0 0 0 .677-.116.48.48 0 0 0-.117-.67l-1.864-1.301 2.349-2.526a.44.44 0 0 0-.027-.63Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina92;
