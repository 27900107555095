import { deleteCookie, getCookie, setCookie } from './cookie';

import { isClientSide } from 'utility/functions';

export enum StorageKind {
  // eslint-disable-next-line no-unused-vars
  cookie = 'cookie',
  // eslint-disable-next-line no-unused-vars
  local = 'localStorage',
  // eslint-disable-next-line no-unused-vars
  session = 'sessionStorage',
}

/* eslint-disable no-unused-vars */
export interface PersistType {
  // session options can be applied only to cookies - it drives cookie expiration
  setItem: (key: string, value: string, session?: boolean) => void;
  getItem: (key: string) => string | null;
  removeItem: (key: string) => void;
}
/* eslint-enable no-unused-vars */

const Persist = (storage: StorageKind): PersistType => {
  // next storage fallback (build support)
  const offlineStorage: Record<string, string> = {};

  const setItem = (key: string, value: string, session = false) => {
    if (isClientSide()) {
      if (storage === StorageKind.cookie) {
        setCookie(key, value, 1, true, session ? false : true);
      } else {
        window[storage].setItem(key, value);
      }
    } else {
      offlineStorage[key] = value;
    }
  };

  const getItem = (key: string) => {
    if (isClientSide()) {
      if (storage === StorageKind.cookie) {
        return getCookie(key);
      } else {
        return window[storage]?.getItem(key);
      }
    }
    return offlineStorage[key];
  };

  const removeItem = (key: string) => {
    if (isClientSide()) {
      if (storage === StorageKind.cookie) {
        deleteCookie(key);
      } else {
        window[storage].removeItem(key);
      }
    } else {
      delete offlineStorage[key];
    }
  };

  return {
    setItem,
    getItem,
    removeItem,
  };
};

export default Persist;
