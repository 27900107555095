/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-298.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco298 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-298', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.655"
      height="38.439"
      viewBox="0 0 23.655 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M323.959,294H307.7a3.7,3.7,0,0,0-3.7,3.7v31.047a3.7,3.7,0,0,0,3.7,3.7h16.263a3.7,3.7,0,0,0,3.7-3.7V297.7A3.7,3.7,0,0,0,323.959,294Zm-18.48,7.392h20.7v23.655h-20.7Zm2.218-5.914h16.263a2.22,2.22,0,0,1,2.218,2.218v2.218h-20.7V297.7A2.22,2.22,0,0,1,307.7,295.478Zm16.263,35.483H307.7a2.22,2.22,0,0,1-2.218-2.218v-2.218h20.7v2.218A2.22,2.22,0,0,1,323.959,330.961Z"
          transform="translate(-304 -294)"
          fill="currentColor"
        />
        <g transform="translate(9.61 34.004)">
          <path
            className="a"
            d="M320.7,341.478h-2.957a.739.739,0,1,1,0-1.478H320.7a.739.739,0,1,1,0,1.478Z"
            transform="translate(-317 -340)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(11.088 2.966)">
          <path
            className="a"
            d="M319.739,299.482a.732.732,0,0,1-.739-.739.61.61,0,0,1,.059-.281.855.855,0,0,1,.155-.244.774.774,0,0,1,1.05,0,.854.854,0,0,1,.155.244.732.732,0,0,1-.68,1.02Z"
            transform="translate(-319 -298.013)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(4.435 13.306)">
          <path
            className="a"
            d="M315.914,323.827a.738.738,0,0,1-.523-.217l-5.174-5.175a.738.738,0,0,1,0-1.045l2.218-2.218a.738.738,0,0,1,1.045,0l2.434,2.434,5.391-5.391a.738.738,0,0,1,1.045,0l2.218,2.218a.738.738,0,0,1,0,1.045l-8.131,8.131A.737.737,0,0,1,315.914,323.827Zm-4.129-5.914,4.129,4.129L323,314.957l-1.172-1.172-5.391,5.391a.738.738,0,0,1-1.045,0l-2.434-2.434Z"
            transform="translate(-310 -312)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco298;
