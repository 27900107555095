/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-15.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina15 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-15', className].join(' ');
  return (
    <svg
      id="disciplina_sci-alpino"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M20.337 4.35a2.663 2.663 0 1 1 0 5.324 2.663 2.663 0 0 1 0-5.325Zm.22 14.257a1.068 1.068 0 0 0-1.327.725 1.667 1.667 0 0 1-2.062 1.13l-3.706-1.087 1.172-3.706a2.668 2.668 0 0 0-1.739-3.345l-.111-.034 1.34-.529 5.136 1.692a1.07 1.07 0 0 0 .666-2.036l-3.535-1.163a2.132 2.132 0 0 0-2.697-2.92l-2.156.846-7.021-4.278.416-.682a.801.801 0 0 0-1.365-.838l-.417.687-.915-.558A.805.805 0 1 0 1.4 3.885l.915.554-.417.683a.803.803 0 0 0 .683 1.22.807.807 0 0 0 .687-.387l.417-.687 5.982 3.646-2.684 1.06a2.663 2.663 0 0 0 .172 5.021l2.915.923-.712 2.255-6.743-1.971a1.07 1.07 0 0 0-.6 2.052l14.553 4.256a3.796 3.796 0 0 0 4.715-2.576 1.071 1.071 0 0 0-.726-1.327Z"
      />
    </svg>
  );
};
export default Disciplina15;
