export const GOLDEN_LEAGUE = 'Golden League';
export const CANALE = 'canale';

export const PROSSIME_PARTENZE_GENERALI = 'prossimePartenzeGenerali';
export const PROSSIME_PARTENZE_CORSA = 'prossimePartenzeCorsa';
export const PROSSIME_PARTENZE_SPORT = 'prossimePartenzeSport';

export const VIRTUAL_SLUG = 'virtuali';

export const VALUE_ACCOPPIATA_IN_ORDINE_LIST = [1, 2];
export const VALUE_TRIO_IN_ORDINE_LIST = [1, 2, 3];

export const ULTIMI_RISULTATI_TEXT = 'Ultimi risultati';
