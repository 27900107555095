/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-276.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco276 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-276', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.526"
      height="38.439"
      viewBox="0 0 32.526 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M242.829,392.784H217.7a3.7,3.7,0,0,1-3.7-3.7V381.7a3.7,3.7,0,0,1,3.7-3.7h25.133a3.7,3.7,0,0,1,3.7,3.7v7.392A3.7,3.7,0,0,1,242.829,392.784ZM217.7,379.479a2.22,2.22,0,0,0-2.218,2.218v7.392a2.22,2.22,0,0,0,2.218,2.218h25.133a2.22,2.22,0,0,0,2.218-2.218V381.7a2.22,2.22,0,0,0-2.218-2.218Z"
          transform="translate(-214 -378)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(5.914 5.914)">
        <path
          className="a"
          d="M223.478,388.957a1.478,1.478,0,1,1,1.478-1.478A1.48,1.48,0,0,1,223.478,388.957Zm0-1.478h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Z"
          transform="translate(-222 -386)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M231.478,388.957a1.478,1.478,0,1,1,1.478-1.478A1.48,1.48,0,0,1,231.478,388.957Zm0-1.478h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Z"
          transform="translate(-224.086 -386)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M239.478,388.957a1.478,1.478,0,1,1,1.478-1.478A1.48,1.48,0,0,1,239.478,388.957Zm0-1.478h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Z"
          transform="translate(-226.173 -386)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M247.478,388.957a1.478,1.478,0,1,1,1.478-1.478A1.48,1.48,0,0,1,247.478,388.957Zm0-1.478h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Z"
          transform="translate(-228.259 -386)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M236.957,419.478a1.478,1.478,0,1,0-2.218,1.273v.945a.739.739,0,1,0,1.478,0v-.945A1.474,1.474,0,0,0,236.957,419.478Z"
        transform="translate(-219.216 -388.431)"
        fill="currentColor"
      />
      <g transform="translate(7.392 25.133)">
        <path
          className="a"
          d="M238.784,425.306H226.957A2.96,2.96,0,0,1,224,422.349v-7.392A2.96,2.96,0,0,1,226.957,412h11.827a2.96,2.96,0,0,1,2.957,2.957v7.392A2.96,2.96,0,0,1,238.784,425.306Zm-11.827-11.828a1.48,1.48,0,0,0-1.478,1.478v7.392a1.48,1.48,0,0,0,1.478,1.478h11.827a1.48,1.48,0,0,0,1.478-1.478v-7.392a1.48,1.48,0,0,0-1.478-1.478Z"
          transform="translate(-224 -412)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(10.349 16.263)">
        <path
          className="a"
          d="M239.088,410.349a.739.739,0,0,1-.739-.739v-3.7a4.435,4.435,0,0,0-8.871,0v3.7a.739.739,0,0,1-1.478,0v-3.7a5.914,5.914,0,0,1,11.827,0v3.7A.739.739,0,0,1,239.088,410.349Z"
          transform="translate(-228 -400)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco276;
