import { ComponentType, useEffect, useMemo, useRef, useState } from 'react';

import dynamic from 'next/dynamic';
import { isClientSide } from 'utility/functions';

interface LazyProps<T> {
  props?: T;
  width?: string | number;
  height?: string | number;
  placeholder?: any;
  doLoad: () => Promise<ComponentType<T>>;
}

export function useLazyLoad<T>({ props, width, height, placeholder, doLoad }: LazyProps<T>): JSX.Element {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const style = useMemo(() => ({ height: height, width: width, overflow: 'hidden' }), [height, width]);

  const objFallback = useMemo(() => {
    const p2 = `${Math.random()}`.substring(2);
    const p1 = new Date().toISOString().substring(0, 10).replace(/-/gim, '');
    return (
      <div style={style} key={`fb-${p1}-${p2}`}>
        {placeholder}
      </div>
    );
  }, [style, placeholder]);

  const [isIntersecting, setIntersecting] = useState(false);

  const { isClient, observer } = useMemo(() => {
    if (!isClientSide()) {
      setIntersecting(true);
      return {
        isClient: false,
        observer: null,
      };
    }

    return {
      isClient: true,
      observer: new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIntersecting(true);
        }
      }),
    };
  }, []);

  useEffect(() => {
    if (isClient && observer && wrapperRef.current) {
      observer.observe(wrapperRef.current);
    }

    return () => {
      if (isClient && observer) {
        observer.disconnect();
      }
    };
  }, [isClient, observer, wrapperRef]);

  useEffect(() => {
    if (isClient && observer && isIntersecting) {
      // once loaded stop observing
      observer.disconnect();
    }
  }, [isClient, isIntersecting, observer]);

  const Dynamic = useMemo(() => {
    if (isIntersecting) {
      return dynamic<T>(() => doLoad(), { loading: () => objFallback });
    }
    return null;
  }, [objFallback, isIntersecting, doLoad]);

  const doRender = () => {
    if (Dynamic) return <Dynamic {...(props as any)} />;
    return objFallback;
  };

  return (
    <div ref={wrapperRef} style={Dynamic ? {} : style}>
      {doRender()}
    </div>
  );
}

export default useLazyLoad;
