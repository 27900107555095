/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-130.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina130 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-130', className].join(' ');
  return (
    <svg
      id="disciplina_arrampicata-sportiva"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M3.183 4.61c0-1.08.88-1.955 1.966-1.955 1.087 0 1.966.874 1.966 1.955 0 1.08-.879 1.954-1.966 1.954A1.959 1.959 0 0 1 3.183 4.61Zm8.512 14.783-1.977-1.368-.81-.103 1.203 4.253c.173.598.798.954 1.4.781.6-.172.96-.793.786-1.39l-.602-2.173Zm2.95-1.816-3.516-2.437a1.147 1.147 0 0 0-.51-.196l-4.498-.586c.185.38.763 1.287.058 2.287l3.874.506 3.296 2.288c.52.356 1.226.23 1.585-.276a1.136 1.136 0 0 0-.29-1.586ZM21.524 0H7.89a.236.236 0 0 0-.22.16c-.034.093.012.196.093.254l.66.402-.07 4.254-1.781 1.954-2.36.241c-.728.08-1.26.724-1.19 1.449l.069.678 1.064-1.08-2.024 3.344a.927.927 0 0 0-.035.886l1.7 3.448a.95.95 0 0 0 1.272.426.937.937 0 0 0 .428-1.265L4.051 12.22l1.815-3.104-.798 2.805-.057.207.971 1.977 2.614-.23-.578-5.679 1.954-2.15a.95.95 0 0 0 .243-.62l.046-3.403c.3.276.52.621.636 1.012l.798 2.598c.104.345.301.678.555.93l2.764 2.817c.452.46.683 1.08.66 1.725l-.22 4.253c-.023.437.081.862.29 1.241l1.896 3.369c.231.402.578.724.994.93l2.672 1.311c.15.07.324.058.463-.023a.478.478 0 0 0 .231-.402V.471A.468.468 0 0 0 21.526 0Z"
      />
    </svg>
  );
};
export default Disciplina130;
