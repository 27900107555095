/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\ferri-dietro.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const FerriDietro = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-ferri-dietro', className].join(' ');
  return (
    <svg
      id="ferri-dietro"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.46242 2.96242c1.94989-1.94989 5.12284-1.94989 7.07274 0C10.4794 3.90674 11.0001 5.16259 11 6.49888c0 1.33627-.5206 2.59194-1.46495 3.53632l-1.4384-1.43844c.56051-.56046.86878-1.30569.86878-2.09792 0-.79219-.30835-1.53749-.86878-2.09792-.56046-.5605-1.30569-.86877-2.09792-.86877-.79219 0-1.53749.30834-2.09792.86877-1.15668 1.1567-1.15668 3.03916 0 4.19584L2.46242 10.0352c-1.949893-1.94994-1.949893-5.12289 0-7.07278Zm-.0501 2.65015c0-.16437-.13374-.2981-.2981-.2981-.16436 0-.29809.13373-.29809.2981 0 .16436.13373.2981.29809.2981.16437 0 .2981-.13374.2981-.2981Zm-.37364.98619c-.16437 0-.2981.13374-.2981.2981 0 .16436.13373.2981.2981.2981.16436 0 .2981-.13374.2981-.2981 0-.16436-.13374-.2981-.2981-.2981Zm.05104 1.58239c0 .16437.13375.2981.2981.2981.16437 0 .2981-.13373.2981-.2981 0-.16436-.13373-.2981-.2981-.2981-.16436 0-.2981.13374-.2981.2981ZM10.1834 5.6126c0-.16436-.1337-.2981-.29808-.2981-.16543 0-.2981.13374-.2981.2981 0 .16437.13373.2981.2981.2981.16438 0 .29808-.13373.29808-.2981Zm-.8739 2.56855c0 .16437.13375.2981.2981.2981.16444 0 .29817-.13374.2981-.2981 0-.16436-.13373-.2981-.2981-.2981-.16436 0-.2981.13374-.2981.2981Zm.35635-1.28429c0 .16436.13374.2981.2981.2981.16435 0 .29805-.13374.29805-.2981 0-.16436-.1337-.2981-.29805-.2981-.16437 0-.2981.13374-.2981.2981Z"
        fill="currentColor"
        fillOpacity=".2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.46242 14.9624c1.94989-1.9499 5.12284-1.9499 7.07274 0 .94424.9443 1.46494 2.2002 1.46484 3.5365 0 1.3362-.5206 2.5919-1.46495 3.5363l-1.4384-1.4384c.56051-.5605.86878-1.3057.86878-2.098 0-.7921-.30835-1.5374-.86878-2.0979-.56046-.5605-1.30569-.8688-2.09792-.8688-.79219 0-1.53749.3084-2.09792.8688-1.15668 1.1567-1.15668 3.0392 0 4.1959l-1.43839 1.4384c-1.949893-1.9499-1.949893-5.1229 0-7.0728Zm-.0501 2.6502c0-.1644-.13374-.2981-.2981-.2981-.16436 0-.29809.1337-.29809.2981 0 .1643.13373.2981.29809.2981.16437 0 .2981-.1338.2981-.2981Zm-.37364.9862c-.16437 0-.2981.1337-.2981.2981 0 .1643.13373.2981.2981.2981.16436 0 .2981-.1338.2981-.2981 0-.1644-.13374-.2981-.2981-.2981Zm.05104 1.5824c0 .1643.13375.2981.2981.2981.16437 0 .2981-.1338.2981-.2981 0-.1644-.13373-.2981-.2981-.2981-.16436 0-.2981.1337-.2981.2981Zm8.09368-2.5686c0-.1644-.1337-.2981-.29808-.2981-.16543 0-.2981.1337-.2981.2981 0 .1644.13373.2981.2981.2981.16438 0 .29808-.1337.29808-.2981Zm-.8739 2.5686c0 .1643.13375.2981.2981.2981.16444 0 .29817-.1338.2981-.2981 0-.1644-.13373-.2981-.2981-.2981-.16436 0-.2981.1337-.2981.2981Zm.35635-1.2843c0 .1643.13374.2981.2981.2981.16435 0 .29805-.1338.29805-.2981 0-.1644-.1337-.2981-.29805-.2981-.16437 0-.2981.1337-.2981.2981Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5352 2.96242c-1.9499-1.94989-5.1229-1.94989-7.0728 0-1.9499 1.94989-1.9499 5.12284 0 7.07278l1.4384-1.43844c-1.1567-1.15668-1.1567-3.03914 0-4.19584.5604-.56043 1.3057-.86877 2.0979-.86877.7923 0 1.5375.30827 2.098.86877.5604.56043.8687 1.30573.8687 2.09792 0 .79223-.3082 1.53746-.8687 2.09792l1.4383 1.43844C22.4794 9.09082 23 7.83515 23 6.49888c.0001-1.33629-.5206-2.59214-1.4648-3.53646Zm-7.421 2.35205c.1644 0 .2981.13373.2981.2981 0 .16436-.1337.2981-.2981.2981-.1643 0-.2981-.13374-.2981-.2981 0-.16437.1338-.2981.2981-.2981Zm-.3736 1.58239c0-.16436.1337-.2981.2981-.2981.1643 0 .2981.13374.2981.2981 0 .16436-.1338.2981-.2981.2981-.1644 0-.2981-.13374-.2981-.2981Zm.6472 1.58239c-.1643 0-.2981-.13373-.2981-.2981 0-.16436.1338-.2981.2981-.2981.1644 0 .2981.13374.2981.2981 0 .16437-.1337.2981-.2981.2981Zm7.4975-3.16475c.1644 0 .2981.13374.2981.2981 0 .16437-.1337.2981-.2981.2981-.1643 0-.2981-.13373-.2981-.2981 0-.16436.1327-.2981.2981-.2981Zm-.2777 3.16475c-.1644 0-.2981-.13373-.2981-.2981 0-.16436.1337-.2981.2981-.2981.1644 0 .2981.13374.2981.2981.0001.16436-.1337.2981-.2981.2981Zm.3563-1.28429c-.1643 0-.2981-.13374-.2981-.2981 0-.16436.1338-.2981.2981-.2981.1644 0 .2981.13374.2981.2981 0 .16436-.1337.2981-.2981.2981Z"
        fill="currentColor"
        fillOpacity=".2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4624 14.9624c1.9499-1.9499 5.1229-1.9499 7.0728 0 .9442.9443 1.4649 2.2002 1.4648 3.5365 0 1.3362-.5206 2.5919-1.465 3.5363l-1.4383-1.4384c.5605-.5605.8687-1.3057.8687-2.098 0-.7921-.3083-1.5374-.8687-2.0979-.5605-.5605-1.3057-.8688-2.098-.8688-.7922 0-1.5375.3084-2.0979.8688-1.1567 1.1567-1.1567 3.0392 0 4.1959l-1.4384 1.4384c-1.9499-1.9499-1.9499-5.1229 0-7.0728Zm-.0501 2.6502c0-.1644-.1337-.2981-.2981-.2981-.1643 0-.2981.1337-.2981.2981 0 .1643.1338.2981.2981.2981.1644 0 .2981-.1338.2981-.2981Zm-.3736.9862c-.1644 0-.2981.1337-.2981.2981 0 .1643.1337.2981.2981.2981.1643 0 .2981-.1338.2981-.2981 0-.1644-.1338-.2981-.2981-.2981Zm.051 1.5824c0 .1643.1338.2981.2981.2981.1644 0 .2981-.1338.2981-.2981 0-.1644-.1337-.2981-.2981-.2981-.1643 0-.2981.1337-.2981.2981Zm8.0937-2.5686c0-.1644-.1337-.2981-.2981-.2981-.1654 0-.2981.1337-.2981.2981 0 .1644.1338.2981.2981.2981.1644 0 .2981-.1337.2981-.2981Zm-.8739 2.5686c0 .1643.1337.2981.2981.2981.1644 0 .2982-.1338.2981-.2981 0-.1644-.1337-.2981-.2981-.2981-.1644 0-.2981.1337-.2981.2981Zm.3563-1.2843c0 .1643.1338.2981.2981.2981.1644 0 .2981-.1338.2981-.2981 0-.1644-.1337-.2981-.2981-.2981-.1643 0-.2981.1337-.2981.2981Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default FerriDietro;
