/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\meteo-coperto.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MeteoCoperto = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-meteo-coperto', className].join(' ');
  return (
    <svg
      id="meteo-coperto"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M4.33268 11.3346C3.32157 11.3346 2.45768 10.9846 1.74102 10.2846C1.02435 9.58464 0.666016 8.72908 0.666016 7.71797C0.666016 6.8513 0.927127 6.07908 1.44935 5.4013C1.97157 4.72352 2.6549 4.29019 3.49935 4.1013C3.77713 3.07908 4.33268 2.2513 5.16602 1.61797C5.99935 0.984635 6.94379 0.667969 7.99935 0.667969C9.29935 0.667969 10.4021 1.12075 11.3077 2.0263C12.2132 2.93186 12.666 4.03464 12.666 5.33464C13.4327 5.42352 14.0688 5.75408 14.5743 6.3263C15.0799 6.89852 15.3327 7.56797 15.3327 8.33464C15.3327 9.16797 15.041 9.8763 14.4577 10.4596C13.8743 11.043 13.166 11.3346 12.3327 11.3346H4.33268ZM4.33268 10.0013H12.3327C12.7993 10.0013 13.1938 9.84019 13.516 9.51797C13.8382 9.19575 13.9993 8.8013 13.9993 8.33464C13.9993 7.86797 13.8382 7.47352 13.516 7.1513C13.1938 6.82908 12.7993 6.66797 12.3327 6.66797H11.3327V5.33464C11.3327 4.41241 11.0077 3.6263 10.3577 2.9763C9.70768 2.3263 8.92157 2.0013 7.99935 2.0013C7.07713 2.0013 6.29102 2.3263 5.64102 2.9763C4.99102 3.6263 4.66602 4.41241 4.66602 5.33464H4.33268C3.68824 5.33464 3.13824 5.56241 2.68268 6.01797C2.22713 6.47352 1.99935 7.02352 1.99935 7.66797C1.99935 8.31241 2.22713 8.86241 2.68268 9.31797C3.13824 9.77353 3.68824 10.0013 4.33268 10.0013Z"
        fill="#757575"
      />
    </svg>
  );
};
export default MeteoCoperto;
