/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-90.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina90 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-90', className].join(' ');
  return (
    <svg
      id="disciplina_bandy"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M14.375 22.12c.017.136-.079.325-.184.426-.18.175-.388.319-.615.427-1.785.82-3.658 1.159-5.616.981a2.742 2.742 0 0 1-.48-.106c-.3-.082-.487-.252-.527-.59-.046-.412-.14-.819-.214-1.228a.081.081 0 0 1 .007-.042c2.615.447 5.06-.004 7.379-1.316.084.463.192.952.25 1.448Zm-5.89-.292.01-.042c.338-.025.677-.04 1.014-.077 1.181-.128 2.338-.365 3.423-.863.317-.146.613-.335.878-.563.295-.253.237-.536-.115-.697a2.017 2.017 0 0 0-.626-.18c-1.985-.2-3.879.152-5.687.98a2.371 2.371 0 0 0-.495.32c-.444.36-.396.72.15.873.47.132.964.17 1.448.249ZM21.897.089l-.242-.055a1.42 1.42 0 0 0-1.696 1.07l-.866 3.578c-.415 1.5-.833 3-1.27 4.497-.399 1.365-.741 2.754-1.45 4.006-.66 1.17-1.618 1.928-2.97 2.148-1.441.234-2.882.094-4.324.029-2.11-.098-4.218-.214-6.328-.288-1.081-.038-1.796.69-1.749 1.766.047 1.09.27 2.154.73 3.151.389.84.991 1.468 1.886 1.77.843.285 1.705.172 2.585.133-.01-.115 0-.205-.026-.285-.177-.569.064-1.004.49-1.322a4.715 4.715 0 0 1 1.166-.656 13.272 13.272 0 0 1 4.437-.8c.45-.004.915.093 1.348.228.512.16.846.523.91 1.097.032.302.104.599.173.984.786-.187 1.545-.335 2.284-.558 1.129-.34 1.862-1.108 2.219-2.235.073-.23.154-.458.206-.693.655-2.956 1.308-5.912 1.96-8.87.217-.979.438-1.957.656-2.935.03-.072.053-.146.07-.221l.87-3.84A1.426 1.426 0 0 0 21.897.088Z"
      />
    </svg>
  );
};
export default Disciplina90;
