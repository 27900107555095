/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\gear.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Gear = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-gear', className].join(' ');
  return (
    <svg
      id="gear"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g id="icon-24 / generic / settings" clipPath="url(#clip0_7540_230508)">
        <path
          id="Vector"
          d="M19.1401 13.4384C19.1801 13.1384 19.2001 12.8284 19.2001 12.4984C19.2001 12.1784 19.1801 11.8584 19.1301 11.5584L21.1601 9.97844C21.3401 9.83844 21.3901 9.56844 21.2801 9.36844L19.3601 6.04844C19.2401 5.82844 18.9901 5.75844 18.7701 5.82844L16.3801 6.78844C15.8801 6.40844 15.3501 6.08844 14.7601 5.84844L14.4001 3.30844C14.3601 3.06844 14.1601 2.89844 13.9201 2.89844H10.0801C9.84011 2.89844 9.65011 3.06844 9.61011 3.30844L9.25011 5.84844C8.66011 6.08844 8.12011 6.41844 7.63011 6.78844L5.24011 5.82844C5.02011 5.74844 4.77011 5.82844 4.65011 6.04844L2.74011 9.36844C2.62011 9.57844 2.66011 9.83844 2.86011 9.97844L4.89011 11.5584C4.84011 11.8584 4.80011 12.1884 4.80011 12.4984C4.80011 12.8084 4.82011 13.1384 4.87011 13.4384L2.84011 15.0184C2.66011 15.1584 2.61011 15.4284 2.72011 15.6284L4.64011 18.9484C4.76011 19.1684 5.01011 19.2384 5.23011 19.1684L7.62011 18.2084C8.12011 18.5884 8.65011 18.9084 9.24011 19.1484L9.60011 21.6884C9.65011 21.9284 9.84011 22.0984 10.0801 22.0984H13.9201C14.1601 22.0984 14.3601 21.9284 14.3901 21.6884L14.7501 19.1484C15.3401 18.9084 15.8801 18.5884 16.3701 18.2084L18.7601 19.1684C18.9801 19.2484 19.2301 19.1684 19.3501 18.9484L21.2701 15.6284C21.3901 15.4084 21.3401 15.1584 21.1501 15.0184L19.1401 13.4384ZM12.0001 16.0984C10.0201 16.0984 8.40011 14.4784 8.40011 12.4984C8.40011 10.5184 10.0201 8.89844 12.0001 8.89844C13.9801 8.89844 15.6001 10.5184 15.6001 12.4984C15.6001 14.4784 13.9801 16.0984 12.0001 16.0984Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_7540_230508">
          <rect width="100%" height="100%" fill="currentColor" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Gear;
