/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\fiori.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Fiori = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-fiori', className].join(' ');
  return (
    <svg
      id="fiori"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M16.4195 9.35485c1.2563 0 2.3938.50925 3.2171 1.33255.8233.8234 1.3326 1.9608 1.3326 3.2171 0 1.2564-.5093 2.3938-1.3326 3.2172-.8233.8233-1.9608 1.3326-3.2171 1.3326-1.2564 0-2.3938-.5093-3.2171-1.3326-.1123-.1123-.2186-.2304-.3187-.3538l2.1439 4.3926c.0158.0296.0231.0614.0231.0929 0 .0372-.0103.0742-.0291.1064-.0027.0045-.0056.0088-.0087.0129-.018.0263-.0418.0491-.0704.0654-.0308.0177-.0668.0282-.1062.0282H9.1565c-.03944 0-.07537-.0105-.10625-.0282-.03311-.0189-.05992-.0465-.07853-.0783-.01913-.0328-.02966-.0697-.02966-.1064 0-.0315.00737-.0633.02317-.0929l2.14997-4.4051c-.103.1279-.2128.2502-.3289.3663-.82329.8233-1.96072 1.3326-3.21707 1.3326-2.51269 0-4.5497-2.0371-4.5497-4.5498 0-1.2563.50925-2.3937 1.33259-3.2171.82334-.8233 1.96076-1.33255 3.21711-1.33255.15735 0 .31493.00832.47221.02455.05697.00588.11362.0128.16992.02075-.20447-.3189-.37022-.66484-.49058-1.03118-.14701-.44743-.22654-.92507-.22654-1.42083 0-1.25635.50925-2.39378 1.33259-3.21711.82334-.82334 1.96077-1.33259 3.21707-1.33259 1.2564 0 2.3938.50925 3.2172 1.33259.8233.82333 1.3325 1.96076 1.3325 3.21711 0 .49071-.0779.96381-.2222 1.40755-.1188.36578-.2831.71156-.4863 1.03073.0408-.00477.0818-.00901.123-.01271.1391-.01248.2764-.01886.4114-.01886Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Fiori;
