/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-309.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco309 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-309', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="32.526"
      viewBox="0 0 41.396 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M249.4,476.349v-2.957a1.48,1.48,0,0,0-1.478-1.478V469.7a3.7,3.7,0,0,0-3.7-3.7H211.7a3.7,3.7,0,0,0-3.7,3.7v22.176a3.7,3.7,0,0,0,3.7,3.7h.739v1.478a1.48,1.48,0,0,0,1.478,1.478h2.957a1.48,1.48,0,0,0,1.478-1.478v-1.478h19.22v1.478a1.48,1.48,0,0,0,1.478,1.478H242a1.48,1.48,0,0,0,1.478-1.478v-1.478h.739a3.7,3.7,0,0,0,3.7-3.7v-2.218a1.48,1.48,0,0,0,1.478-1.478V485.22a1.48,1.48,0,0,0-1.478-1.479v-5.914A1.48,1.48,0,0,0,249.4,476.349Zm-32.526,20.7h-2.957v-1.478h2.957Zm25.133,0h-2.957v-1.478H242Zm4.435-5.175a2.22,2.22,0,0,1-2.218,2.218H211.7a2.22,2.22,0,0,1-2.218-2.218V469.7a2.22,2.22,0,0,1,2.218-2.218h32.526a2.22,2.22,0,0,1,2.218,2.218v2.218h-2.957A1.48,1.48,0,0,0,242,473.392v2.957a1.48,1.48,0,0,0,1.478,1.478h2.957v5.914h-2.957A1.48,1.48,0,0,0,242,485.22v2.957a1.48,1.48,0,0,0,1.478,1.478h2.957Zm1.479-3.7h-4.436V485.22h4.435Zm-4.436-11.828v-2.957h4.435v2.957Z"
        transform="translate(-208 -466)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M222.871,474a8.871,8.871,0,1,0,8.871,8.871A8.88,8.88,0,0,0,222.871,474Zm0,16.263a7.392,7.392,0,1,1,7.392-7.392A7.4,7.4,0,0,1,222.871,490.263Zm5.175-8.131h-.806a4.407,4.407,0,0,0-.758-1.827l.571-.57a.739.739,0,1,0-1.045-1.045l-.571.57a4.408,4.408,0,0,0-1.827-.758V477.7a.739.739,0,0,0-1.478,0v.806a4.4,4.4,0,0,0-1.827.758l-.571-.57a.739.739,0,1,0-1.045,1.045l.571.57a4.408,4.408,0,0,0-.758,1.827H217.7a.739.739,0,0,0,0,1.478h.806a4.409,4.409,0,0,0,.758,1.827l-.571.57a.739.739,0,1,0,1.045,1.045l.571-.57a4.4,4.4,0,0,0,1.827.758v.806a.739.739,0,0,0,1.478,0v-.806a4.409,4.409,0,0,0,1.827-.758l.571.57a.739.739,0,0,0,1.045-1.045l-.571-.57a4.408,4.408,0,0,0,.758-1.827h.806a.739.739,0,0,0,0-1.478Zm-5.175,3.7a2.957,2.957,0,1,1,2.957-2.957A2.96,2.96,0,0,1,222.871,485.828Z"
        transform="translate(-209.565 -468.086)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco309;
