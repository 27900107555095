/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-308.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco308 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-308', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="32.526"
      viewBox="0 0 38.439 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0)">
        <g transform="translate(22.916 6.653)">
          <path
            className="a"
            d="M160.7,398.392a.737.737,0,0,1-.523-.217l-2.956-2.957a.739.739,0,1,1,1.045-1.045l2.433,2.434,5.392-5.391a.739.739,0,0,1,1.045,1.045l-5.914,5.914A.737.737,0,0,1,160.7,398.392Z"
            transform="translate(-157 -391)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(4.435 13.306)">
          <path
            className="a"
            d="M137.914,407.392h-4.435A1.48,1.48,0,0,1,132,405.914v-4.435A1.48,1.48,0,0,1,133.478,400h4.435a1.48,1.48,0,0,1,1.478,1.478v4.435A1.48,1.48,0,0,1,137.914,407.392Zm-4.435-5.914v4.435h4.436v-4.435Z"
            transform="translate(-132 -400)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M132.739,415.478h4.435a.739.739,0,0,0,0-1.478h-4.435a.739.739,0,1,0,0,1.478ZM144.567,414h-4.435a.739.739,0,1,0,0,1.478h4.435a.739.739,0,0,0,0-1.478Zm7.392,0h-4.435a.739.739,0,1,0,0,1.478h4.435a.739.739,0,1,0,0-1.478Zm7.392,0h-4.435a.739.739,0,1,0,0,1.478h4.435a.739.739,0,0,0,0-1.478Zm-16.263,2.957H132.739a.739.739,0,0,0,0,1.479h10.349a.739.739,0,0,0,0-1.479Z"
          transform="translate(-127.565 -390.345)"
          fill="currentColor"
        />
        <g transform="translate(17.741 0)">
          <path
            className="a"
            d="M160.349,402.7A10.349,10.349,0,1,1,170.7,392.349,10.36,10.36,0,0,1,160.349,402.7Zm0-19.22a8.871,8.871,0,1,0,8.871,8.871A8.88,8.88,0,0,0,160.349,383.479Z"
            transform="translate(-150 -382)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(0 7.392)">
          <path
            className="a"
            d="M159.265,417.133H129.7a3.7,3.7,0,0,1-3.7-3.7V395.7a3.7,3.7,0,0,1,3.7-3.7h11.827a.739.739,0,1,1,0,1.478H129.7a2.22,2.22,0,0,0-2.218,2.218v17.741a2.22,2.22,0,0,0,2.218,2.218h29.569a2.22,2.22,0,0,0,2.218-2.218v-8.871a.739.739,0,0,1,1.478,0v8.871A3.7,3.7,0,0,1,159.265,417.133Z"
            transform="translate(-126 -392)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco308;
