/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-30.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina30 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-30', className].join(' ');
  return (
    <svg
      id="disciplina_sci-nordico"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="m3.793 15.591 7.595-3.107-.086.221a1.137 1.137 0 0 0-.043.89l.817 2.222 1.85-1.484-.249-.677.74.286 1.536-1.232c-.486-.304-.77-.825-.808-1.168l-.4-3.845 1.295 3.75a.942.942 0 0 0 .654.603l3.627.942a.929.929 0 0 0 .982-.35.948.948 0 0 0-.517-1.482l-3.142-.816-1.17-3.394 1.695 2.557.616-1.624a1.417 1.417 0 0 0-.347-1.526 1.393 1.393 0 0 0-.462-.292l-2.324-.903c-.116-.043-.391-.182-.813-.134l-3.976.573a.932.932 0 0 0-.705.506.95.95 0 0 0 .008.874l1.902 3.589-8.838 3.62-.009-.022a.758.758 0 0 0-.402-.421.743.743 0 0 0-.826.157.756.756 0 0 0-.162.832l.009.022-.383.156a.763.763 0 0 0-.005 1.39c.18.08.385.084.569.012l.383-.156.008.022a.744.744 0 0 0 1.379.005.762.762 0 0 0 .011-.574l-.009-.022Zm9.613-8.45-.43 1.133-.53-.994.96-.139Zm6.82-4.053a2.1 2.1 0 0 1-.349 1.16c-.227.343-.55.61-.928.768a2.053 2.053 0 0 1-2.256-.452 2.104 2.104 0 0 1-.449-2.275c.157-.382.422-.708.763-.937a2.057 2.057 0 0 1 2.613.259 2.087 2.087 0 0 1 .606 1.477Zm2.612 6.215a.75.75 0 0 0-1.054-.122l-1.218.977.443.117c.396.103.746.337.994.664l.71-.569a.762.762 0 0 0 .125-1.067ZM10.015 20.56l8.77-7.04-1.82-.473-7.883 6.328-.018-.022a.752.752 0 0 0-.799-.269.747.747 0 0 0-.455.365.761.761 0 0 0 .08.846l.017.022-.323.256a.759.759 0 0 0 .386 1.335.746.746 0 0 0 .548-.15l.323-.26.017.021a.748.748 0 0 0 1.323-.389.762.762 0 0 0-.148-.553l-.018-.017Zm10.241-1.254h-.421a.745.745 0 0 0-.53.22.758.758 0 0 0 0 1.069c.14.141.331.22.53.22h.421a.33.33 0 0 1 .235.099.336.336 0 0 1-.235.57h-2.384l.306-4.123a1.12 1.12 0 0 0-.069-.477l-.473-1.28-1.85 1.488.129.352-.297 4.045h-2.083l1.011-3.399-3.085 2.479c-.245.824-.24.794-.258.924a.747.747 0 0 0-.49.262.76.76 0 0 0 .056 1.038c.14.133.324.206.516.206h8.971c.486 0 .952-.195 1.295-.54a1.855 1.855 0 0 0 0-2.613 1.822 1.822 0 0 0-1.295-.54Z"
      />
    </svg>
  );
};
export default Disciplina30;
