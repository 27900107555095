/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { setHeaders } from 'common/types/swagger/base';

import type { HttpResponseType } from 'common/types/swagger/base';
import { checkResponse, handleException } from 'common/types/swagger/base';

export interface GamesBingoCatalogDto {
  games?: Array<GamesGameResponseDto> | undefined;
  listJackpot?: Array<GamesJackpotDto> | undefined;
  listWinner?: Array<GamesWinnerDto> | undefined;
}

export interface GamesBingoLivePromotionRankingResDto {
  rankings?: Array<GamesPromotionRankingItem> | undefined;
}

export interface GamesBingoLiveScheduleResDto {
  bingoOneSchedule?: Array<GamesBingoOneItem> | undefined;
  bingoSchedule?: Record<string, Array<GamesRoundItem>>;
}

export interface GamesBingoLiveUrlLancioGiochiReqDto {
  username?: string | undefined;
}

export interface GamesBingoLiveUrlLancioGiochiResponseDto {
  url?: string | undefined;
}

export interface GamesBingoLiveWinnersRankingResDto {
  winners?: Array<GamesWinnersItem> | undefined;
}

export interface GamesBingoOneItem {
  /** @format int32 */
  ballNumber: number;
  /** @format double */
  cardPrice: number;
  /** @format int32 */
  roundId: number;
  specialPrizes?: Array<string> | undefined;
}

export interface GamesCardGameFiltersDto {
  modalGameModeFilter?: GamesGameFilterTypeDto | undefined;
}

export interface GamesCasinoCatalogDto {
  games?: Array<GamesSkillGameDto> | undefined;
  listInPromozione?: Array<string> | undefined;
  listNovita?: Array<string> | undefined;
  listPreferiti?: Array<string> | undefined;
}

export interface GamesCasinoLiveCatalogDto {
  games?: Array<GamesTableDto> | undefined;
  listAltreLingue?: Array<string> | undefined;
  listBaccarat?: Array<string> | undefined;
  listBlackjack?: Array<string> | undefined;
  listInEsclusiva?: Array<string> | undefined;
  listInItaliano?: Array<string> | undefined;
  listPoker?: Array<string> | undefined;
  listRoulette?: Array<string> | undefined;
  listTavoliVip?: Array<string> | undefined;
  listUltimiLiberi?: Array<string> | undefined;
}

export interface GamesCasinoLiveFiltersDto {
  modalLanguageFilter?: GamesGameFilterTypeDto | undefined;
}

export interface GamesCriterion {
  key?: string | undefined;
  value?: string | undefined;
}

export interface GamesDealer {
  asaCompliant: boolean;
  dealerName?: string | undefined;
  dealerPictureUrl?: string | undefined;
}

export interface GamesGameDto {
  /** @format int32 */
  amount?: number | undefined;
  id?: string | undefined;
  /** @format double */
  rawAmount: number;
  /** @format double */
  step: number;
}

export interface GamesGameFilterTypeDto {
  label?: string | undefined;
  tooltipContent?: string | undefined;
  typeOfItem: GamesUIComponentFilter;
  values?: Array<any> | undefined;
}

export interface GamesGamePokerDto {
  buyin?: string | undefined;
  description?: string | undefined;
  fee?: string | undefined;
  gameType?: string | undefined;
  id?: string | undefined;
  maxPlayers?: string | undefined;
  name?: string | undefined;
  slug?: string | undefined;
  /** @format date-time */
  startDate?: string | undefined;
  totalBuyin?: string | undefined;
}

export interface GamesGameResponseDto {
  /** @format int64 */
  cardPrice: number;
  /** @format date-time */
  closingDateTime?: string | undefined;
  currency?: string | undefined;
  displayName?: string | undefined;
  /** @format date-time */
  expirationTimeLeft: string;
  id?: string | undefined;
  isAlwaysOpen: boolean;
  jackpots?: Array<GamesJackpotDto> | undefined;
  /** @format int32 */
  numberOfBalls?: number | undefined;
  /** @format int32 */
  numberOfPlayers: number;
  /** @format date-time */
  openingDateTime?: string | undefined;
  /** @format int32 */
  priority: number;
  /** @format int64 */
  refreshTime: number;
  /** @format int32 */
  timeLeft: number;
  /** @format int64 */
  totalPrize: number;
}

export interface GamesImage {
  srcCover?: string | undefined;
  srcThumbnail?: string | undefined;
}

export interface GamesJackpotDto {
  /** @format int64 */
  amount?: number | undefined;
  clubName?: string | undefined;
  criterion?: Array<GamesCriterion> | undefined;
  currency?: string | undefined;
  name?: string | undefined;
}

export interface GamesPlayInfo {
  codeAAMS?: string | undefined;
  codeGameApp?: string | undefined;
  codeGameDesktop?: string | undefined;
  codeGameMobile?: string | undefined;
  height?: string | undefined;
  isReleasedDesktop: boolean;
  isReleasedMobile: boolean;
  product?: string | undefined;
  /** @format date-time */
  releaseDateDesktop?: string | undefined;
  /** @format date-time */
  releaseDateMobile?: string | undefined;
  width?: string | undefined;
}

export interface GamesPlayInfoQuickSlot {
  codeAAMS?: string | undefined;
  codeGameDesktop?: string | undefined;
  codeGameMobile?: string | undefined;
  height?: string | undefined;
  product?: string | undefined;
  width?: string | undefined;
}

export interface GamesPokerCatalogDto {
  games?: Array<GamesGamePokerDto> | undefined;
  listInPartenza?: Array<string> | undefined;
}

export interface GamesPromo {
  /** @format date-time */
  dataFine?: string | undefined;
  /** @format date-time */
  dataInizio?: string | undefined;
  url?: string | undefined;
}

export interface GamesPromotionRankingItem {
  /** @format int64 */
  creationDate: number;
  name?: string | undefined;
  rows?: Array<GamesRankingItem> | undefined;
}

export interface GamesQuickSlotCatalogDto {
  games?: Array<GamesQuickSlotDto> | undefined;
}

export interface GamesQuickSlotDto {
  /** @format int32 */
  codicePiattaforma?: number | undefined;
  id?: string | undefined;
  /** @format int32 */
  idProduttore?: number | undefined;
  image?: GamesImage | undefined;
  isInstant: boolean;
  playInfo?: GamesPlayInfoQuickSlot | undefined;
  produttore?: string | undefined;
  slug?: string | undefined;
  title?: string | undefined;
}

export interface GamesRankingItem {
  points?: string | undefined;
  /** @format int32 */
  position: number;
  user?: string | undefined;
}

export interface GamesRoundItem {
  /** @format double */
  cardPrice: number;
  hour?: string | undefined;
  /** @format int32 */
  roundId: number;
  specialPrizes?: Array<string> | undefined;
}

export enum GamesSection {
  ListTop10 = 'ListTop10',
  ListPopolari = 'ListPopolari',
  ListSceltePerTe = 'ListSceltePerTe',
  ListNovita = 'ListNovita',
  ListInPromozione = 'ListInPromozione',
  ListJackpot = 'ListJackpot',
  ListPiuVincenti = 'ListPiuVincenti',
  ListPromettenti = 'ListPromettenti',
  ListPiuGiocate = 'ListPiuGiocate',
  ListPreferiti = 'ListPreferiti',
  ListQuickSlot = 'ListQuickSlot',
  ListInstantGame = 'ListInstantGame',
  ListRoulette = 'ListRoulette',
  ListBlackjack = 'ListBlackjack',
  ListBaccarat = 'ListBaccarat',
  ListPoker = 'ListPoker',
  ListTavoliVip = 'ListTavoliVip',
  ListAltri = 'ListAltri',
  ListSceltiDaSnai = 'ListSceltiDaSnai',
  ListTutte = 'ListTutte',
}

export interface GamesSkillGameDto {
  categoriaVerticale?: string | undefined;
  category?: string | undefined;
  /** @format int32 */
  codicePiattaforma?: number | undefined;
  color?: string | undefined;
  description?: string | undefined;
  functionality?: Array<string> | undefined;
  gameMode?: Array<string> | undefined;
  hasDemo: boolean;
  hasJackpot: boolean;
  id?: string | undefined;
  /** @format int32 */
  idProduttore?: number | undefined;
  image?: GamesImage | undefined;
  isExclusive: boolean;
  isFavorite: boolean;
  isInstant: boolean;
  isLoggedIn: boolean;
  isNew: boolean;
  /** @format int32 */
  jackpot?: number | undefined;
  lines?: string | undefined;
  playInfo?: GamesPlayInfo | undefined;
  produttore?: string | undefined;
  promo?: GamesPromo | undefined;
  /** @format date-time */
  publicationDate?: string | undefined;
  /** @format float */
  rtp: number;
  slug?: string | undefined;
  tema?: Array<string> | undefined;
  title?: string | undefined;
  /** @format date-time */
  updateTime: string;
  verticale?: string | undefined;
  volatility?: GamesVolatilita | undefined;
}

export interface GamesSkillGameHints {
  hints?: Array<GamesSkillGameSearchHint> | undefined;
}

export interface GamesSkillGameSearchHint {
  category?: string | undefined;
  id?: string | undefined;
  name?: string | undefined;
  produttore?: string | undefined;
  slug?: string | undefined;
  title?: string | undefined;
  type: GamesSkillGameType;
}

export interface GamesSkillGameSearchResults {
  games?: Array<GamesSkillGameDto> | undefined;
}

export enum GamesSkillGameType {
  Category = 'category',
  Skillgame = 'skillgame',
}

export interface GamesSlotCatalogDto {
  listTop10?: Array<string> | undefined;
  games?: Array<GamesSkillGameDto> | undefined;
  listInPromozione?: Array<string> | undefined;
  listJackpot?: Array<string> | undefined;
  listNovita?: Array<string> | undefined;
  listPiuGiocate?: Array<string> | undefined;
  listPiuVincenti?: Array<string> | undefined;
  listPopolari?: Array<string> | undefined;
  listPreferiti?: Array<string> | undefined;
  listPromettenti?: Array<string> | undefined;
  listTutte?: Array<string> | undefined;
  stripe?: GamesStripe | undefined;
}

export interface GamesSlotFiltersDto {
  modalCategoryFilter?: GamesGameFilterTypeDto | undefined;
  modalFunctionalityFilter?: GamesGameFilterTypeDto | undefined;
  modalLinesFilter?: GamesGameFilterTypeDto | undefined;
  modalProducerFilter?: GamesGameFilterTypeDto | undefined;
  modalVolatilityFilter?: GamesGameFilterTypeDto | undefined;
}

export interface GamesStripe {
  maxJackpot?: GamesGameDto | undefined;
  /** @format int32 */
  slotPromo?: number | undefined;
  /** @format int32 */
  slotTotali?: number | undefined;
  /** @format int64 */
  totJackpot?: number | undefined;
}

export interface GamesTableDto {
  asaAlternativeImage?: string | undefined;
  continent?: string | undefined;
  /** @format date-time */
  created?: string | undefined;
  dealer?: GamesDealer | undefined;
  dedicated: boolean;
  dynamicImageUrl?: string | undefined;
  /** @format int32 */
  freeSeatsCount?: number | undefined;
  /** @format int32 */
  id: number;
  imsGameType?: string | undefined;
  language?: string | undefined;
  languageFlagImageUrl?: string | undefined;
  lastNumbers?: Array<number> | undefined;
  launchAlias?: Array<string> | undefined;
  liveGameType?: string | undefined;
  /** @format int32 */
  max?: number | undefined;
  /** @format int32 */
  min?: number | undefined;
  name?: string | undefined;
  /** @format int64 */
  openingDateTime?: number | undefined;
  /** @format int32 */
  playerCount?: number | undefined;
  seatsAvailability?: Array<number> | undefined;
  status?: string | undefined;
  tableColor?: string | undefined;
  /** @format date-time */
  updated?: string | undefined;
}

export enum GamesUIComponentFilter {
  Simple = 'simple',
  Toggle = 'toggle',
  Chip = 'chip',
}

export interface GamesVolatilita {
  label?: string | undefined;
  value?: string | undefined;
}

export interface GamesWinnerDto {
  /** @format int64 */
  amount?: number | undefined;
  currency?: string | undefined;
  name?: string | undefined;
  /** @format date-time */
  time?: string | undefined;
}

export interface GamesWinnersItem {
  bingoRoomName?: string | undefined;
  createdAt?: string | undefined;
  /** @format int32 */
  id: number;
  nickname?: string | undefined;
  prizeName?: string | undefined;
  /** @format int64 */
  sqPlay: number;
  username?: string | undefined;
  /** @format double */
  winAmount: number;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    const result: RequestParams = setHeaders({
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    });
    return result;
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponseType<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    })
      .then(async (response) => {
        const r = response as HttpResponse<T, E>;
        r.data = null as unknown as T;
        r.error = null as unknown as E;

        const data = !responseFormat
          ? r
          : await response[responseFormat]()
              .then((data) => {
                if (r.ok) {
                  r.data = data;
                } else {
                  r.error = data;
                }
                return r;
              })
              .catch((e) => {
                r.error = e;
                return r;
              });

        if (cancelToken) {
          this.abortControllers.delete(cancelToken);
        }

        return checkResponse<T, E>(data);
      })
      .catch((exception) => {
        return handleException<T, E>(exception);
      });
  };
}

/**
 * @title SnaiWebsite.Middleware.Games
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  externalgames = {
    /**
     * No description
     *
     * @tags ExternalGames
     * @name CasinoliveCatalogList
     * @request GET:/externalgames/casinolive/catalog
     */
    casinoliveCatalogList: (params: RequestParams = {}) =>
      this.request<GamesCasinoLiveCatalogDto, any>({
        path: `/externalgames/casinolive/catalog`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name CasinoliveFiltersList
     * @request GET:/externalgames/casinolive/filters
     */
    casinoliveFiltersList: (
      query?: {
        list?: GamesSection;
      },
      params: RequestParams = {}
    ) =>
      this.request<GamesCasinoLiveFiltersDto, any>({
        path: `/externalgames/casinolive/filters`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name BingoTokenList
     * @request GET:/externalgames/bingo/token
     */
    bingoTokenList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/externalgames/bingo/token`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name BingoCatalogList
     * @request GET:/externalgames/bingo/catalog
     */
    bingoCatalogList: (params: RequestParams = {}) =>
      this.request<GamesBingoCatalogDto, any>({
        path: `/externalgames/bingo/catalog`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name PokerTokenList
     * @request GET:/externalgames/poker/token
     */
    pokerTokenList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/externalgames/poker/token`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name PokerCatalogList
     * @request GET:/externalgames/poker/catalog
     */
    pokerCatalogList: (params: RequestParams = {}) =>
      this.request<GamesPokerCatalogDto, any>({
        path: `/externalgames/poker/catalog`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name PokerCatalogDetail
     * @request GET:/externalgames/poker/catalog/{slug}
     */
    pokerCatalogDetail: (slug: string, params: RequestParams = {}) =>
      this.request<GamesGamePokerDto, any>({
        path: `/externalgames/poker/catalog/${slug}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name SlotCatalogList
     * @request GET:/externalgames/slot/catalog
     */
    slotCatalogList: (params: RequestParams = {}) =>
      this.request<GamesSlotCatalogDto, any>({
        path: `/externalgames/slot/catalog`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name SlotCatalogDetail
     * @request GET:/externalgames/slot/catalog/{slug}
     */
    slotCatalogDetail: (slug: string, params: RequestParams = {}) =>
      this.request<GamesSkillGameDto, any>({
        path: `/externalgames/slot/catalog/${slug}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name SlotSearchList
     * @request GET:/externalgames/slot/search
     */
    slotSearchList: (
      query?: {
        category?: boolean;
        query?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GamesSkillGameSearchResults, any>({
        path: `/externalgames/slot/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name SlotAutocompleteList
     * @request GET:/externalgames/slot/autocomplete
     */
    slotAutocompleteList: (
      query?: {
        query?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GamesSkillGameHints, any>({
        path: `/externalgames/slot/autocomplete`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name SlotMostSearchedList
     * @request GET:/externalgames/slot/most-searched
     */
    slotMostSearchedList: (params: RequestParams = {}) =>
      this.request<GamesSkillGameHints, any>({
        path: `/externalgames/slot/most-searched`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name SlotTopList
     * @request GET:/externalgames/slot/top
     */
    slotTopList: (
      query?: {
        /** @format int32 */
        topSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<GamesSlotCatalogDto, any>({
        path: `/externalgames/slot/top`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name SlotListList
     * @request GET:/externalgames/slot/list
     */
    slotListList: (
      query?: {
        categoryFilter?: Array<string>;
        functionalityFilter?: Array<string>;
        linesFilter?: Array<string>;
        list?: GamesSection;
        /** @format int32 */
        pageNumber?: number;
        /** @format int32 */
        pageSize?: number;
        producerFilter?: Array<string>;
        volatilityFilter?: Array<string>;
      },
      params: RequestParams = {}
    ) =>
      this.request<GamesSlotCatalogDto, any>({
        path: `/externalgames/slot/list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name SlotListCreate
     * @request POST:/externalgames/slot/list
     */
    slotListCreate: (data: Array<string>, params: RequestParams = {}) =>
      this.request<GamesSlotCatalogDto, any>({
        path: `/externalgames/slot/list`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name SlotQuickList
     * @request GET:/externalgames/slot/quick
     */
    slotQuickList: (params: RequestParams = {}) =>
      this.request<GamesQuickSlotCatalogDto, any>({
        path: `/externalgames/slot/quick`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name SlotFiltersList
     * @request GET:/externalgames/slot/filters
     */
    slotFiltersList: (
      query?: {
        list?: GamesSection;
      },
      params: RequestParams = {}
    ) =>
      this.request<GamesSlotFiltersDto, any>({
        path: `/externalgames/slot/filters`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name SlotFiltersCreate
     * @request POST:/externalgames/slot/filters
     */
    slotFiltersCreate: (data: Array<string>, params: RequestParams = {}) =>
      this.request<GamesSlotFiltersDto, any>({
        path: `/externalgames/slot/filters`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name CasinoCatalogList
     * @request GET:/externalgames/casino/catalog
     */
    casinoCatalogList: (params: RequestParams = {}) =>
      this.request<GamesCasinoCatalogDto, any>({
        path: `/externalgames/casino/catalog`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name CasinoCatalogDetail
     * @request GET:/externalgames/casino/catalog/{slug}
     */
    casinoCatalogDetail: (slug: string, params: RequestParams = {}) =>
      this.request<GamesSkillGameDto, any>({
        path: `/externalgames/casino/catalog/${slug}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name CasinoSearchList
     * @request GET:/externalgames/casino/search
     */
    casinoSearchList: (
      query?: {
        category?: boolean;
        query?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GamesSkillGameSearchResults, any>({
        path: `/externalgames/casino/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name CasinoAutocompleteList
     * @request GET:/externalgames/casino/autocomplete
     */
    casinoAutocompleteList: (
      query?: {
        query?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GamesSkillGameHints, any>({
        path: `/externalgames/casino/autocomplete`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name CasinoMostSearchedList
     * @request GET:/externalgames/casino/most-searched
     */
    casinoMostSearchedList: (params: RequestParams = {}) =>
      this.request<GamesSkillGameHints, any>({
        path: `/externalgames/casino/most-searched`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name CardgameCatalogList
     * @request GET:/externalgames/cardgame/catalog
     */
    cardgameCatalogList: (params: RequestParams = {}) =>
      this.request<GamesCasinoCatalogDto, any>({
        path: `/externalgames/cardgame/catalog`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name CardgameCatalogDetail
     * @request GET:/externalgames/cardgame/catalog/{slug}
     */
    cardgameCatalogDetail: (slug: string, params: RequestParams = {}) =>
      this.request<GamesSkillGameDto, any>({
        path: `/externalgames/cardgame/catalog/${slug}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name CardgameSearchList
     * @request GET:/externalgames/cardgame/search
     */
    cardgameSearchList: (
      query?: {
        category?: boolean;
        query?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GamesSkillGameSearchResults, any>({
        path: `/externalgames/cardgame/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name CardgameAutocompleteList
     * @request GET:/externalgames/cardgame/autocomplete
     */
    cardgameAutocompleteList: (
      query?: {
        query?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<GamesSkillGameHints, any>({
        path: `/externalgames/cardgame/autocomplete`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name CardgameMostSearchedList
     * @request GET:/externalgames/cardgame/most-searched
     */
    cardgameMostSearchedList: (params: RequestParams = {}) =>
      this.request<GamesSkillGameHints, any>({
        path: `/externalgames/cardgame/most-searched`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name CardgameFiltersList
     * @request GET:/externalgames/cardgame/filters
     */
    cardgameFiltersList: (params: RequestParams = {}) =>
      this.request<GamesCardGameFiltersDto, any>({
        path: `/externalgames/cardgame/filters`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name CardgameFiltersCreate
     * @request POST:/externalgames/cardgame/filters
     */
    cardgameFiltersCreate: (data: Array<string>, params: RequestParams = {}) =>
      this.request<GamesCardGameFiltersDto, any>({
        path: `/externalgames/cardgame/filters`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name BingoliveLanciogiochiCreate
     * @request POST:/externalgames/bingolive/lanciogiochi
     */
    bingoliveLanciogiochiCreate: (data: GamesBingoLiveUrlLancioGiochiReqDto, params: RequestParams = {}) =>
      this.request<GamesBingoLiveUrlLancioGiochiResponseDto, any>({
        path: `/externalgames/bingolive/lanciogiochi`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name BingoliveScheduleList
     * @request GET:/externalgames/bingolive/schedule
     */
    bingoliveScheduleList: (params: RequestParams = {}) =>
      this.request<GamesBingoLiveScheduleResDto, any>({
        path: `/externalgames/bingolive/schedule`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name BingoliveWinnersrankingList
     * @request GET:/externalgames/bingolive/winnersranking
     */
    bingoliveWinnersrankingList: (params: RequestParams = {}) =>
      this.request<GamesBingoLiveWinnersRankingResDto, any>({
        path: `/externalgames/bingolive/winnersranking`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExternalGames
     * @name BingolivePromotionrankingList
     * @request GET:/externalgames/bingolive/promotionranking
     */
    bingolivePromotionrankingList: (params: RequestParams = {}) =>
      this.request<GamesBingoLivePromotionRankingResDto, any>({
        path: `/externalgames/bingolive/promotionranking`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
}
