export enum RegistrationValue {
  DEFAULT,
  SUCCESS,
  ERROR,
  LOADING,
}
export enum RegistrationFromPage {
  REGISTRATION,
  SPID,
  PERSONAL_AREA,
}
