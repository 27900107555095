/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-117.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina117 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-117', className].join(' ');
  return (
    <svg
      id="disciplina_beach-tennis"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M4.576 22.997a1.462 1.462 0 0 1 2.07 0 3.065 3.065 0 0 0 4.33 0 1.462 1.462 0 0 1 2.07 0 3.066 3.066 0 0 0 4.325 0 1.462 1.462 0 0 1 2.07 0 3.066 3.066 0 0 0 4.325 0 .79.79 0 1 0-1.12-1.12 1.461 1.461 0 0 1-2.07 0 3.065 3.065 0 0 0-4.325 0 1.467 1.467 0 0 1-2.08 0 3.065 3.065 0 0 0-4.329 0 1.461 1.461 0 0 1-2.07 0 3.065 3.065 0 0 0-4.325 0 1.461 1.461 0 0 1-2.07 0 .791.791 0 1 0-1.118 1.12 3.06 3.06 0 0 0 4.317 0ZM.252 20.504a.792.792 0 0 0-.225.455v-7.426l15.99 7.159c-.12.078-.252.14-.39.182l-.786.014a1.494 1.494 0 0 1-.67-.384 3.065 3.065 0 0 0-4.329 0 1.461 1.461 0 0 1-2.07 0 3.065 3.065 0 0 0-4.325 0 1.461 1.461 0 0 1-2.07 0 .788.788 0 0 0-1.125 0Zm22.1-16.588C14.944-5.735 1.35 4.3 7.637 14.098c7.31 9.623 21.022-.32 14.713-10.182ZM7.091 7.557a8.08 8.08 0 0 1 .742-2.202c.764 3.976 2.847 5.816 1.514 9.376a8.058 8.058 0 0 1-2.256-7.174Zm7.91 9.504c-1.665.02-3.596-.623-4.909-1.675 1.964-5.679-1.7-7.202-1.379-11.404 6.541-7.381 15.04.757 13.04 2.192-.947 1.239-3.132 1.337-4.708 2.414-3.21 1.963-3.87 6.002-2.027 8.472h-.018Zm7.891-6.606a8.038 8.038 0 0 1-6.759 6.525c-2.05-2.132-1.27-5.37.503-6.946 1.555-1.646 4.87-1.643 5.993-3.536a8.011 8.011 0 0 1 .263 3.959v-.002Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina117;
