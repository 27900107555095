/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-252.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco252 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-252', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.483"
      height="38.439"
      viewBox="0 0 35.483 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M494.308,332.439a.739.739,0,0,1-.739-.739V319.008a.739.739,0,0,1,.233-.538,13.177,13.177,0,0,0,4.2-9.686,13.306,13.306,0,1,0-26.612,0c0,.31,0,.31-5.677,6.039a.785.785,0,0,0-.169.869.767.767,0,0,0,.719.484h2.909a.739.739,0,0,1,.739.739v7.392a2.22,2.22,0,0,0,2.218,2.218h5.914a.739.739,0,0,1,.739.739V331.7a.739.739,0,0,1-1.478,0V328h-5.175a3.7,3.7,0,0,1-3.7-3.7v-6.653h-2.17a2.271,2.271,0,0,1-1.6-3.873c1.294-1.305,4.424-4.466,5.251-5.3a14.784,14.784,0,0,1,29.566.306,14.629,14.629,0,0,1-4.435,10.539V331.7A.739.739,0,0,1,494.308,332.439Z"
            transform="translate(-464 -294)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(11.828 14.784)">
          <path
            className="a"
            d="M491.827,324.349h-8.871A2.96,2.96,0,0,1,480,321.392v-4.435A2.96,2.96,0,0,1,482.957,314h8.871a2.96,2.96,0,0,1,2.957,2.957v4.435A2.96,2.96,0,0,1,491.827,324.349Zm-8.871-8.871a1.48,1.48,0,0,0-1.478,1.478v4.435a1.48,1.48,0,0,0,1.478,1.478h8.871a1.48,1.48,0,0,0,1.479-1.478v-4.435a1.481,1.481,0,0,0-1.479-1.478Z"
            transform="translate(-480 -314)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(14.785 7.392)">
          <path
            className="a"
            d="M492.131,312.871a.739.739,0,0,1-.739-.739v-3.7a2.957,2.957,0,1,0-5.914,0v3.7a.739.739,0,0,1-1.478,0v-3.7a4.435,4.435,0,1,1,8.87,0v3.7A.738.738,0,0,1,492.131,312.871Z"
            transform="translate(-484 -304)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M490.957,319.478a1.478,1.478,0,1,0-2.218,1.273v.945a.739.739,0,1,0,1.478,0v-.945A1.474,1.474,0,0,0,490.957,319.478Z"
          transform="translate(-470.258 -300.259)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco252;
