/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-103.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina103 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-103', className].join(' ');
  return (
    <svg
      id="disciplina_karate"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="m22.085 23.998-4.49-2.056c-.671-.308-1.332-.635-2.01-.924-1.742-.74-3.489-1.466-5.237-2.191a1.316 1.316 0 0 0-.42-.079c-1.118-.069-2.237-.128-3.354-.198-.75-.046-1.499-.104-2.286-.16.043.11.067.186.102.26l1.932 4.11c.247.524.129.819-.43 1.018-.176.062-.368.122-.55.115-.226-.01-.371-.176-.491-.367-1.153-1.841-2.31-3.68-3.47-5.518-.167-.264-.368-.501-.38-.844-.026-.722.555-1.444 1.463-1.447 1.266-.005 2.534 0 3.8 0h.274c.083-.873.163-1.724.246-2.575.022-.226.06-.45.085-.677a.452.452 0 0 0-.017-.206c-.788-1.943-1.565-3.892-2.38-5.824-.25-.59-.113-1.045.286-1.481C6.109 3.479 7.469 2.01 8.808.526c.218-.244.454-.433.766-.526h.385c.397.124.728.322.833.755.085.352.052.687-.204.968a2090.116 2090.116 0 0 1-3.25 3.578c-.207.23-.417.457-.61.697a.312.312 0 0 0-.047.26c.427 1.069.863 2.134 1.307 3.196a.28.28 0 0 0 .193.147c.938.088 1.88.148 2.816.252.306.034.602.129.87.279a755.111 755.111 0 0 1 6.975 4.093c.276.172.525.382.739.625.276.301.283.688.075 1-.228.345-.624.519-1.014.407a4.085 4.085 0 0 1-.768-.31c-1.923-1.011-3.843-2.029-5.76-3.05-.035-.019-.073-.033-.142-.063-.048.34-.095.669-.138.997-.077.583-.146 1.167-.23 1.748-.028.186.008.3.188.402 1.473.843 2.953 1.675 4.405 2.554 2.179 1.316 4.337 2.664 6.498 4.008.129.08.204.244.305.368v.284c-.199.322-.352.686-.77.805l-.145-.002ZM10.07 4.378c-1.292-.029-2.467.91-2.497 2.385-.026 1.3.96 2.42 2.436 2.444 1.317.021 2.432-.964 2.478-2.384.005-1.257-.94-2.412-2.417-2.445Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina103;
