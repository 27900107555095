/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-53.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina53 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-53', className].join(' ');
  return (
    <svg
      id="disciplina_nuoto-sincronizzato"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M4.214 7.234a2.353 2.353 0 1 1 4.706 0 2.353 2.353 0 0 1-4.706 0ZM1.668 11.44s.183.038.413.08c.23.042.37.3.319.577l-.404 2.095c.722-.155 1.51-.042 2.11.347l2.606 1.692.83-.628s.647-2.545.806-2.513c.16.033-.103 1.983-.103 1.983l.563-.426c.426-.324.975-.497 1.533-.53l.356-1.884a1.012 1.012 0 0 0-.802-1.182L4.017 9.925a1.013 1.013 0 0 1-.802-1.181l.61-3.652c.103-.548-.263-.989-.816-.989h-.01c-.557 0-1.096.445-1.204.99l-.928 5.18a.995.995 0 0 0 .801 1.166Zm17.03-.81a2.353 2.353 0 1 0 0-4.707 2.353 2.353 0 0 0 0 4.706ZM13.8 12.48l.825.16-.427 2.226.685-.445c.895-.582 2.297-.577 3.192.014l1.8 1.186s.446-1.575.605-1.543c.16.033-.075 1.894-.075 1.894l.216.146 1.945-1.44.262-1.401a1.012 1.012 0 0 0-.806-1.181l-5.883-1.13a1.008 1.008 0 0 1-.801-1.181l.81-4.796c.104-.548-.262-.989-.82-.989h-.019c-.557 0-1.092.445-1.2.99l-1.12 6.323a1.007 1.007 0 0 0 .811 1.167Zm10.088 2.738c-.192-.314-.558-.385-.816-.15l-2.447 2.203c-.145.131-.454.145-.604.019l-2.143-1.72a1.583 1.583 0 0 0-1.94-.01l-2.194 1.734c-.169.131-.511.136-.68.005l-2.283-1.753c-.567-.436-1.401-.394-1.94.098l-1.773 1.627c-.145.136-.45.15-.604.023L4.27 15.56c-.577-.46-1.411-.399-1.94.14L.21 17.863c-.248.253-.28.698-.075.998a.552.552 0 0 0 .45.258.544.544 0 0 0 .375-.164l2.124-2.161a.466.466 0 0 1 .558-.043l2.194 1.735c.567.45 1.401.413 1.94-.084l1.772-1.627c.15-.136.46-.155.614-.033l2.279 1.758a1.61 1.61 0 0 0 1.936-.014l2.193-1.735c.165-.13.497-.126.661.005l2.143 1.72a1.51 1.51 0 0 0 1.94-.06l2.447-2.204c.263-.234.314-.68.127-.993Z"
      />
    </svg>
  );
};
export default Disciplina53;
