/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-254.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco254 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-254', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M76.422,137.691a17.743,17.743,0,1,0-33.017,12.97c.007.016.014.03.021.046a17.766,17.766,0,0,0,16.315,10.776c.385,0,.766-.016,1.144-.041a.739.739,0,1,0-.094-1.475c-.347.022-.7.038-1.05.038-2.806,0-5.305-3.654-6.552-8.871h4.335a.739.739,0,1,0,0-1.478H52.878a33.353,33.353,0,0,1,0-11.828H74.883c.048.124.1.243.15.368a.739.739,0,0,0,1.39-.5Zm-20.857-9.666a17.772,17.772,0,0,0-3.914,8.324H45.263A16.328,16.328,0,0,1,55.565,128.025Zm0,31.433a16.327,16.327,0,0,1-10.3-8.325h6.388A17.768,17.768,0,0,0,55.565,159.458Zm-4.206-9.8H44.6a16.206,16.206,0,0,1,0-11.828h6.763a36.036,36.036,0,0,0,0,11.828Zm8.382-22.177c2.706,0,5.285,3.53,6.558,8.871H53.189C54.436,131.132,56.935,127.478,59.741,127.478Zm8.072,8.871a17.756,17.756,0,0,0-3.928-8.319,16.331,16.331,0,0,1,10.331,8.319Z"
        transform="translate(-42 -126)"
        fill="currentColor"
      />
      <g transform="translate(17.741 13.306)">
        <path
          className="a"
          d="M76.7,162.392a.737.737,0,0,1-.523-.217l-2.956-2.957a.739.739,0,1,1,1.045-1.045l2.434,2.434,5.392-5.391a.739.739,0,0,1,1.045,1.045l-5.914,5.914A.74.74,0,0,1,76.7,162.392Z"
          transform="translate(-67.825 -146.869)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M76.349,169.133a.728.728,0,0,1-.279-.055,20.164,20.164,0,0,1-5.008-3.166A13.268,13.268,0,0,1,66,155.778v-7.885a.739.739,0,0,1,.3-.6A18.715,18.715,0,0,1,76.349,144,18.715,18.715,0,0,1,86.4,147.3a.739.739,0,0,1,.3.6v7.885a13.268,13.268,0,0,1-5.062,10.135,20.164,20.164,0,0,1-5.008,3.166A.728.728,0,0,1,76.349,169.133Zm-8.871-20.85v7.494c0,7.3,7.334,11.094,8.87,11.808,1.534-.715,8.871-4.519,8.871-11.808v-7.493a17.383,17.383,0,0,0-8.871-2.807A17.329,17.329,0,0,0,67.478,148.284Z"
          transform="translate(-66 -144)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco254;
