/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-51.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina51 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-51', className].join(' ');
  return (
    <svg
      id="disciplina_lotta-libera"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M2.071 12.383a2.076 2.076 0 1 1 .01 4.152 2.076 2.076 0 0 1-.01-4.152Zm21.553 7.623-4.24-3.391a1.003 1.003 0 0 0-1.245-.01l-2.48 1.926a1.083 1.083 0 0 0-.033-.117l-1.531-3.776v-3.1a1.005 1.005 0 1 0-2.01 0v3.297a.926.926 0 0 0 .075.376l.92 2.273H9.252l.531-.766a.791.791 0 0 0 .085-.756l-.493-1.23.342.328a.799.799 0 0 0 .876.16.81.81 0 0 0 .49-.756.802.802 0 0 0-.248-.564l-2.451-2.353a.795.795 0 0 0-.489-.22l-2.16-.193.216-.503c.098-.235.38-.47.624-.53l2.677-.667 5.026-.193a.978.978 0 0 0 .394-.098l4.749-2.274a1.004 1.004 0 1 0-.86-1.817l-4.57 2.174-3.405.132L13.03 6.32a1.012 1.012 0 0 0 .61-.84l.376-4.388A1.008 1.008 0 0 0 12.009.92l-.324 3.785-3.419 1.451-3.673.86c-.244.075-.54.315-.662.53l-2.09 3.401a.376.376 0 0 0 .207.554l2.517 1.188c.108.038 2.39.174 2.828.202a.805.805 0 0 0-.15.836l.971 2.429-.925 1.338a.806.806 0 0 0-.06.108.451.451 0 0 0-.156.338v2.851a.46.46 0 0 0 .46.46h7.285a1.005 1.005 0 0 0 .845-.178l3.081-2.39 3.622 2.897a1.006 1.006 0 1 0 1.258-1.573ZM4.607 17.099a2.076 2.076 0 1 0 0 4.151 2.076 2.076 0 0 0 0-4.151Z"
      />
    </svg>
  );
};
export default Disciplina51;
