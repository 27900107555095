import { KeyManager } from 'utility/keyManager';

export class KeyManagerVirtual extends KeyManager {
  constructor(key: string, separator: string = '-') {
    super(separator);
    this.generateIds(key);
    this.generateKeys();
  }

  private generateIds(key: string) {
    const { programmaId, avvenimentoId, tipoScommessaId, esitoId } = this.retrieveInformationByKey(key);

    this._programmaId = programmaId;
    this._avvenimentoId = avvenimentoId;
    this._tipoScommessaId = tipoScommessaId;
    this._esitoId = esitoId;
  }

  private retrieveInformationByKey(key: string) {
    const [programmaId, avvenimentoId, tipoScommessaId, esitoId] = key.split(this._separator);
    return {
      programmaId,
      avvenimentoId,
      tipoScommessaId,
      esitoId,
    };
  }

  private generateKeys() {
    this.avvenimentoKey = this.concatId([this.programmaId, this._avvenimentoId]);
    this.scommessaKey = this.concatId([this.programmaId, this._avvenimentoId, this._tipoScommessaId]);
    this.esitoKey = this.concatId([this.programmaId, this._avvenimentoId, this._tipoScommessaId, this._esitoId]);
  }
}
