/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-180.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco180 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-180', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.526"
      height="41.396"
      viewBox="0 0 32.526 41.396"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M136.739,321.089a.739.739,0,1,0,.739.739A.741.741,0,0,0,136.739,321.089Zm5.537-8.124a.732.732,0,0,0,.739-.739.755.755,0,0,0-.1-.37.738.738,0,0,0-1.375.377.723.723,0,0,0,.739.732Zm-4.421,13.772a.716.716,0,0,0-.37.636.727.727,0,0,0,.1.37.746.746,0,0,0,.643.37.679.679,0,0,0,.37-.1.73.73,0,0,0,.37-.635.755.755,0,0,0-.1-.37A.729.729,0,0,0,137.855,326.736Zm-.007-9.809a.749.749,0,0,0,1.013-.274v.007a.746.746,0,0,0,.1-.37.72.72,0,0,0-.37-.643.729.729,0,0,0-1.005.266v.007a.708.708,0,0,0-.1.362A.744.744,0,0,0,137.848,316.927Zm4.8,13.868v-.007a.743.743,0,0,0-.747,1.285v-.007a.685.685,0,0,0,.37.1.735.735,0,0,0,.643-.37.844.844,0,0,0,.1-.37A.731.731,0,0,0,142.653,330.794Zm4.65-19.53a.854.854,0,0,0,.244.155.732.732,0,0,0,1.02-.68.778.778,0,0,0-.214-.525.856.856,0,0,0-.244-.155.739.739,0,0,0-.806.155.777.777,0,0,0-.214.525.736.736,0,0,0,.059.281A.861.861,0,0,0,147.3,311.264Z"
          transform="translate(-131.565 -296.694)"
          fill="currentColor"
        />
        <g transform="translate(15.524 20.351)">
          <path
            className="a"
            d="M151.739,336.14a.739.739,0,0,1,0-1.478,10.332,10.332,0,0,0,9.628-14.123.739.739,0,0,1,1.376-.54,11.811,11.811,0,0,1-11,16.142Z"
            transform="translate(-151 -319.53)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M159.059,314.611a.738.738,0,0,0-1.045,0l-5.825,5.825a2.925,2.925,0,0,0-1.492-.419,2.96,2.96,0,0,0-2.957,2.957,2.918,2.918,0,0,0,.419,1.493l-.942.942a.739.739,0,1,0,1.045,1.045l.942-.942a2.918,2.918,0,0,0,1.493.419,2.96,2.96,0,0,0,2.957-2.957,2.918,2.918,0,0,0-.419-1.492l5.825-5.825A.738.738,0,0,0,159.059,314.611Zm-8.363,9.841a1.478,1.478,0,1,1,1.478-1.478A1.48,1.48,0,0,1,150.7,324.452Z"
          transform="translate(-134.433 -297.84)"
          fill="currentColor"
        />
        <g transform="translate(24.807 9.632)">
          <path
            className="a"
            d="M167.435,311.986h-.053a.739.739,0,0,1-.539-.294,14.836,14.836,0,0,0-2.987-2.987.738.738,0,0,1-.08-1.115l1.909-1.909a2.219,2.219,0,0,1,3.137,0l1.045,1.045a2.217,2.217,0,0,1,0,3.137l-1.909,1.909A.739.739,0,0,1,167.435,311.986Zm-2.026-3.94a16.292,16.292,0,0,1,2.091,2.091l1.322-1.322a.739.739,0,0,0,0-1.045l-1.045-1.045a.74.74,0,0,0-1.046,0Z"
            transform="translate(-163.559 -305.03)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(0.761 9.632)">
          <path
            className="a"
            d="M134.111,311.986a.739.739,0,0,1-.523-.216l-1.909-1.909a2.217,2.217,0,0,1,0-3.137l1.045-1.045a2.219,2.219,0,0,1,3.137,0l1.909,1.909a.74.74,0,0,1-.08,1.115,14.858,14.858,0,0,0-2.988,2.987.736.736,0,0,1-.539.294Zm.182-5.478a.737.737,0,0,0-.523.216l-1.045,1.045a.738.738,0,0,0,0,1.044l1.322,1.323a16.292,16.292,0,0,1,2.091-2.091l-1.322-1.322A.737.737,0,0,0,134.293,306.509Z"
            transform="translate(-131.029 -305.03)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M153.458,292h-8.131a3.327,3.327,0,0,0,0,6.653h1.109v3.133h1.478v-3.133h2.957v3.1c0,.409,1.478.409,1.478,0v-3.1h1.109a3.327,3.327,0,0,0,0-6.653Zm0,5.174h-8.131a1.848,1.848,0,0,1,0-3.7h8.131a1.848,1.848,0,1,1,0,3.7Z"
          transform="translate(-133.129 -292)"
          fill="currentColor"
        />
        <g transform="translate(0 8.871)">
          <path
            className="a"
            d="M146.263,336.526a16.263,16.263,0,1,1,16.263-16.263A16.281,16.281,0,0,1,146.263,336.526Zm0-31.047a14.784,14.784,0,1,0,14.784,14.784A14.8,14.8,0,0,0,146.263,305.478Z"
            transform="translate(-130 -304)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco180;
