/* eslint-disable no-unused-vars */
export enum AppFragment {
  Live = 'live',
  Login = 'login',
  Sport = 'sport',
  Casino = 'casino',
  Ippica = 'ippica',
  SlotTop = 'slot-top',
  Virtuali = 'virtuali',
  Lotterie = 'lotterie',
  Marcatori = 'marcatori',
  CasinoLive = 'casinolive',
  CardGame = 'giochi-di-carte',
}

export type AppFragmentType = `${AppFragment}`;
/* eslint-disable no-unused-vars */
export enum LeafFragment {
  Search = 'ricerca',
}
