/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-cl.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneCl = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-cl', className].join(' ');
  return (
    <svg
      id="nazione-cl"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <mask id="a" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <path d="M0 0h15.99v15.99H0V0Z" fill="#fff" />
      </mask>
      <g fillRule="evenodd" clipRule="evenodd" mask="url(#a)">
        <path d="M7.994 0h15.99v7.994H7.994V0Z" fill="#fff" />
        <path d="M0 0h7.994v7.994H0V0Z" fill="#0039A6" />
        <path
          d="m5.241 5.986-1.24-.927-1.23.936.458-1.523-1.23-.941 1.52-.014L3.995 2l.48 1.516h1.523l-1.227.952.469 1.521.002-.002Z"
          fill="#fff"
        />
        <path d="M0 7.994h23.984v7.996H0V7.994Z" fill="#D52B1E" />
      </g>
    </svg>
  );
};
export default NazioneCl;
