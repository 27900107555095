/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-381.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco381 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-381', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="35.482"
      viewBox="0 0 38.439 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M414.222,312.263h-.739v-5.175a3.7,3.7,0,0,0-3.681-3.695l-2.159-5.933a2.218,2.218,0,0,0-2.843-1.325l-5.326,1.938-1.425-1.425a2.221,2.221,0,0,0-3.136,0l-6.744,6.744h-7.212a1.478,1.478,0,1,1,0-2.957h8.131a.739.739,0,0,0,0-1.478h-8.131A2.96,2.96,0,0,0,378,301.914v25.873a3.7,3.7,0,0,0,3.7,3.7h28.09a3.7,3.7,0,0,0,3.7-3.7V324.83a.739.739,0,0,0-1.478,0v2.957A2.22,2.22,0,0,1,409.786,330H381.7a2.22,2.22,0,0,1-2.218-2.218V304.474a2.927,2.927,0,0,0,1.479.4h28.321l.006,0h.5A2.22,2.22,0,0,1,412,307.088v5.175h-5.174a5.175,5.175,0,0,0,0,10.349h7.392a2.22,2.22,0,0,0,2.218-2.218v-5.914A2.22,2.22,0,0,0,414.222,312.263Zm-23.962-8.871,5.7-5.7a.739.739,0,0,1,1.045,0l1.737,1.737,0,0,3.957,3.958Zm14.533,0-4.165-4.165,4.678-1.7a.739.739,0,0,1,.948.442l1.975,5.426Zm10.169,17a.739.739,0,0,1-.739.739h-7.392a3.7,3.7,0,1,1,0-7.392h7.392a.739.739,0,0,1,.739.739Zm-8.131-5.175a2.218,2.218,0,1,0,2.218,2.218A2.22,2.22,0,0,0,406.829,315.22Zm0,2.957a.739.739,0,1,1,.739-.739A.74.74,0,0,1,406.829,318.177Z"
        transform="translate(-378 -296)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco381;
