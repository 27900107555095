/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-sport-gaelici.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const DisciplinaSportGaelici = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-sport-gaelici', className].join(' ');
  return (
    <svg
      id="disciplina_sport-gaelici"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M12.761 0h.591c.119.036.236.094.357.105.801.068 1.47.403 1.933 1.058.312.443.56.935.814 1.417.514.975 1.2 1.301 2.292 1.122.716-.118 1.475-.323 2.146.077 2.222 1.325 2.914 4.05.678 5.873-.939.766-2.032 1.245-3.162 1.647-.16.057-.311.134-.466.202.135.102.294.168.461.193 1.33.241 2.64.555 3.815 1.26.742.444 1.347 1.013 1.61 1.873.056.185.114.37.171.553v.425c-.036.119-.094.235-.108.357-.206 1.68-1.206 2.64-2.875 2.742-.609.036-1.131.204-1.57.678-.33.355-.407.763-.458 1.188-.171 1.41-.796 2.441-2.248 2.825-.173.047-.343.102-.515.154h-.254c-.047-.028-.094-.074-.144-.082-1.535-.245-2.495-1.216-3.165-2.53-.708-1.39-.965-2.894-1.057-4.435-.028-.471-.062-.94-.094-1.422-.072.025-.107.026-.119.043a4.284 4.284 0 0 0-.208.317C9.691 18.22 7.5 19.92 4.66 20.797c-.24.073-.496.141-.74.128-.148-.009-.361-.152-.41-.283-.05-.132.034-.37.141-.47.181-.163.425-.264.652-.368.65-.298 1.344-.519 1.951-.886 1.732-1.046 2.995-2.581 4.175-4.189.026-.036.021-.094.045-.214-.205.115-.347.2-.492.276-.76.395-1.502.834-2.286 1.172-1.62.697-3.324.945-5.073.643C1.26 16.37.624 15.356.206 14.16c-.091-.26-.138-.538-.206-.808v-.424c.23-1.006.956-1.612 1.744-2.168.066-.053.129-.109.189-.168.463-.41.612-.921.504-1.522-.167-.91-.33-1.82-.497-2.73-.292-1.593.963-3.028 2.58-2.918 1.007.068 1.954.35 2.796.942 1.158.815 2.039 1.886 2.832 3.038.079.113.17.217.292.373.02-.121.033-.15.026-.174-.032-.122-.072-.242-.105-.364-.4-1.514-.688-3.037-.444-4.615.203-1.307.952-2.175 2.155-2.467C12.3.1 12.53.051 12.76 0Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default DisciplinaSportGaelici;
