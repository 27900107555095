/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-tiro-con-volo.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const DisciplinaTiroConVolo = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-tiro-con-volo', className].join(' ');
  return (
    <svg
      id="disciplina_tiro-con-volo"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M6.837 0C7.251 0 7.656.125 8 .36c.344.235.612.569.77.96.159.39.2.82.12 1.235-.081.414-.28.795-.573 1.094a2.079 2.079 0 0 1-1.071.585c-.406.082-.827.04-1.21-.122a2.103 2.103 0 0 1-.938-.787 2.17 2.17 0 0 1 .26-2.7c.194-.198.425-.355.679-.463.254-.107.526-.162.8-.162Zm16 7.294a8.154 8.154 0 0 1-1.468-.811c-.193-.14-.276-.085-.193.145l.142.399-1.679.009C19.318.796 13.771.21 13.711.206a.63.63 0 0 0-.47.15.655.655 0 0 0-.228.446.67.67 0 0 0 .147.48.646.646 0 0 0 .436.232c.188.019 4.455.474 4.754 5.531l-3.474.02a1.317 1.317 0 0 0-.463-.67 1.27 1.27 0 0 0-.762-.26H9.182a1.306 1.306 0 0 0-.474-.58 1.265 1.265 0 0 0-.71-.216H5.745L2.864 3.867a1.266 1.266 0 0 0-.983-.069 1.3 1.3 0 0 0-.744.66 1.345 1.345 0 0 0-.072 1.004c.107.331.338.605.643.763l2.675 1.364v5.19l-2.57 9.557c-.09.336-.046.695.123.998.168.303.448.526.777.62a1.27 1.27 0 0 0 1.118-.223c.223-.175.385-.42.46-.697l2.326-8.653h.4l2.151 8.63c.071.283.232.534.458.713a1.27 1.27 0 0 0 1.112.234c.33-.086.614-.303.789-.603.175-.3.226-.657.142-.996l-2.386-9.557V8.77h4.364c.256 0 .507-.078.72-.225.213-.147.377-.356.473-.6.752-.004 2.16-.014 3.505-.018-.179 4.443-4.084 5.775-4.253 5.826a.648.648 0 0 0-.354.28.671.671 0 0 0 .127.849.638.638 0 0 0 .612.132c.05-.014 4.992-1.65 5.167-7.092.706-.005 1.312-.01 1.674-.01l-.137.404c-.078.23 0 .28.192.14.46-.324.948-.601 1.46-.83.224-.103.22-.234.004-.332Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default DisciplinaTiroConVolo;
