/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-210.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco210 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-210', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="41.396"
      viewBox="0 0 35.482 41.396"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M249.133,402H225.478A1.48,1.48,0,0,0,224,403.478v12.567a.739.739,0,1,0,1.478,0V403.478H227.7v3.7a.739.739,0,0,0,.739.739h2.957a.739.739,0,0,0,.739-.739v-3.7h2.219v12.567a.739.739,0,1,0,1.478,0V403.478h13.306v16.263h-9.685a.739.739,0,0,0,0,1.478h9.685a1.48,1.48,0,0,0,1.478-1.478V403.478A1.48,1.48,0,0,0,249.133,402Zm-18.48,4.435h-1.478v-2.957h1.478Z"
          transform="translate(-215.129 -382.78)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M253.914,418.739a.739.739,0,0,0-.739-.739h-4.435a.739.739,0,0,0,0,1.478h4.435A.739.739,0,0,0,253.914,418.739Zm-.739,2.218h-2.218a.739.739,0,0,0,0,1.478h2.218a.739.739,0,0,0,0-1.478Z"
          transform="translate(-221.388 -386.953)"
          fill="currentColor"
        />
        <g transform="translate(0 16.164)">
          <path
            className="a"
            d="M232.7,423.1H218.653A6.661,6.661,0,0,1,212,416.446v-11a6.673,6.673,0,0,1,3.865-6.041l3.187-1.471a.739.739,0,0,1,.619,1.342l-3.187,1.472a5.188,5.188,0,0,0-3.006,4.7v11a5.181,5.181,0,0,0,5.175,5.174H232.7a2.957,2.957,0,0,0,0-5.914H220.131a.739.739,0,1,1,0-1.478H232.7a4.435,4.435,0,0,1,0,8.871Z"
            transform="translate(-212 -397.866)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M228.653,396.61a6.661,6.661,0,0,0,6.653-6.653V387h-1.478v2.957a5.175,5.175,0,0,1-10.349,0V387H222v2.957A6.661,6.661,0,0,0,228.653,396.61Z"
          transform="translate(-214.608 -378.869)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M239,383.392h-3.7v-.739a6.653,6.653,0,0,0-13.306,0v1.478a.739.739,0,0,0,.739.739H239a.739.739,0,0,0,0-1.478Zm-10.349-5.914a5.181,5.181,0,0,1,5.175,5.174v.739H223.478v-.739A5.181,5.181,0,0,1,228.653,377.478Z"
          transform="translate(-214.608 -376)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco210;
