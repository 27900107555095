import { detectMob, isClientSide, isTruthy } from 'utility/functions';
import { setSelectedCard, setSession } from './authSlice';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiSlice } from 'features/api/apiSlice';
import { setInjectDigitalAssistantCompleted } from 'features/dashboard/dashboardSlice';
import { ticketRemoved } from 'features/sport/components/sportTicket/sportTicketSlice';
import Persist, { StorageKind } from 'lib/persist';
import policy from 'lib/policy/types';
import { signOut as signOutNext } from 'next-auth/react';
import { usersCli } from 'types/swagger';
import { IS_LOGGED, TOKEN_AUTH_NAME, isHappybetSite } from 'utility/constant';
import { doGet } from 'lib/auth/web';
import { utcNow } from 'utility/date';

const { DIGITAL_ASSISTANT_URL } = policy;

const cookie = Persist(StorageKind.cookie);
interface signOutMWProps {
  logoutType: number;
}
const signOutMW = async ({ logoutType }: signOutMWProps) => {
  const { user, hbet } = usersCli;

  try {
    // const token = cookie.getItem(TOKEN_AUTH_NAME)
    const params = {
      headers: {
        'Frontend-Type': detectMob() ? '60' : '1',
      },
    };
    const prLogout = isHappybetSite ? hbet.userLogoutCreate({ logoutType }, params) : user.logoutCreate(params);

    const { ok, error } = await prLogout;

    if (ok) {
      console.log(`LOGOUT OK`);
    } else {
      console.log(`LOGOUT ERROR: `, JSON.stringify(error));
    }
  } catch (e) {
    console.log(`LOGOUT EXCEPTION: `, JSON.stringify(e));
  } finally {
    cookie.removeItem(IS_LOGGED);
    cookie.removeItem(TOKEN_AUTH_NAME);
    return Promise.resolve();
  }
};

export interface signOutProps {
  isInactive?: boolean;
}
export const signOut = createAsyncThunk(
  'signOut',
  async ({ isInactive }: signOutProps = {}, { dispatch }): Promise<void> => {
    try {
      const ts = utcNow().getTime();
      const logoutType = isTruthy(isInactive) ? 1 : 0;
      const { access_token, expires_in, refresh_token } = await doGet();

      try {
        await signOutMW({ logoutType });
      } finally {
      }
      try {
        await signOutNext({ redirect: false });
      } finally {
      }
      dispatch(
        setSession({
          ts,
          accessToken: access_token,
          expireDate: ts + expires_in * 0.8,
          refreshToken: refresh_token,
          isAuthenticated: false,
        })
      );

      dispatch(ticketRemoved());
      if (isClientSide()) {
        const { DA } = window as any;
        dispatch(setInjectDigitalAssistantCompleted(false));
        DA?.hide();
        DA?.disconnect();
        delete window.DA;

        var scripts = document.getElementsByTagName('script');
        for (var i = 0; i < scripts.length; i++) {
          var script = scripts[i];

          if (script.getAttribute('src')?.includes(DIGITAL_ASSISTANT_URL.replace('?view.mobileMode=detect', ''))) {
            script.parentNode?.removeChild(script);
          }
        }
        var script = document.createElement('script');
        script.src = DIGITAL_ASSISTANT_URL;
        document.head.appendChild(script);
      }
      dispatch(apiSlice.util.resetApiState());
      if (isHappybetSite) {
        dispatch(setSelectedCard(undefined));
      }
      // if (typeof callback === 'function') {
      //   callback();
      // }
      return Promise.resolve();
    } catch (e) {
      console.log(`exception: `, JSON.stringify(e));
      return Promise.reject();
    }
  }
);
