/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\pencil.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Pencil = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-pencil', className].join(' ');
  return (
    <svg
      id="pencil"
      width="100%"
      height="100%"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M0 15.2486V18.9986H3.75L14.81 7.93859L11.06 4.18859L0 15.2486ZM17.71 5.03859C18.1 4.64859 18.1 4.01859 17.71 3.62859L15.37 1.28859C14.98 0.898594 14.35 0.898594 13.96 1.28859L12.13 3.11859L15.88 6.86859L17.71 5.03859Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Pencil;
