import { setSession } from 'features/auth/authSlice';
import { useAppDispatch } from 'lib/centralStore';
import { useCallback, useMemo } from 'react';

import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import { DeepPartial } from 'types/swagger';

// do not start monitoring on components, one is enougth: it is started from RefreshTokenEngine
export const useInternalSession = () => {
  const dispatch = useAppDispatch();
  const { data: session, status, update } = useSession();
  const enableTracing = useMemo(() => process.env.NODE_ENV !== 'production', []);

  const updateSession = useCallback(
    async (payload: DeepPartial<Session>) => {
      if (enableTracing) {
        console.log(`[AUTH] - Session update with`, payload);
      }
      const isAuthenticated = payload.user ? true : false;

      const pr2 = update({ ...payload });
      const pr1 = dispatch(setSession({ ...payload, isAuthenticated }));

      await Promise.all([pr1, pr2]);
    },
    [dispatch, enableTracing]
  );

  // console.log(`useInternalSession: status="${status}", isLogged="${isTruthy(cookie.getItem(IS_LOGGED))}"`)

  return {
    status: status ?? 'unauthenticated',
    session,
    isAuthenticated: status === 'authenticated',
    updateSession,
  };
};
