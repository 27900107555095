/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-205.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco205 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-205', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="32.526"
      viewBox="0 0 38.439 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(7.392 11.827)">
        <path
          className="a"
          d="M399.328,502.7H391.7A3.7,3.7,0,0,1,388,499a.739.739,0,1,1,1.478,0,2.22,2.22,0,0,0,2.218,2.218h7.632a1.325,1.325,0,0,0,.925-.4,1.023,1.023,0,0,0,.313-.731,1.1,1.1,0,0,0-.228-.644.74.74,0,0,1-.06-.8.724.724,0,0,1,.706-.383.842.842,0,0,0,.8-.323,1.165,1.165,0,0,0-.022-1.521.74.74,0,0,1,.3-1.188,1.1,1.1,0,0,0,.208-1.97.74.74,0,0,1-.3-.889,1.019,1.019,0,0,0,.03-.693,1.236,1.236,0,0,0-1.2-.81h-3.925a1.48,1.48,0,0,1-1.478-1.478v-4.435a1.48,1.48,0,0,0-1.478-1.478V485.7a5.181,5.181,0,0,1-5.174,5.174.739.739,0,1,1,0-1.478,3.7,3.7,0,0,0,3.7-3.7v-2.957a.739.739,0,0,1,.739-.739h.739a2.96,2.96,0,0,1,2.957,2.957v4.435H400.8a2.725,2.725,0,0,1,2.614,1.847,2.454,2.454,0,0,1,.084,1.122,2.572,2.572,0,0,1-.164,3.817,2.741,2.741,0,0,1-.319,2.583,2.206,2.206,0,0,1-1.021.821,2.556,2.556,0,0,1,.055.481,2.487,2.487,0,0,1-.736,1.794A2.8,2.8,0,0,1,399.328,502.7Z"
          transform="translate(-388 -482)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(4.435 0)">
        <path
          className="a"
          d="M406.916,492.612a.731.731,0,0,1-.283-.056.739.739,0,0,1-.456-.683V486.7h-2.218a.739.739,0,1,1,0-1.478h2.957a.738.738,0,0,1,.739.739v4.129l4.652-4.652a.736.736,0,0,1,.523-.217h2.218a1.48,1.48,0,0,0,1.478-1.479V468.957a1.48,1.48,0,0,0-1.478-1.478h-28.09a1.48,1.48,0,0,0-1.478,1.478V483A.739.739,0,1,1,384,483V468.957A2.96,2.96,0,0,1,386.957,466h28.09A2.96,2.96,0,0,1,418,468.957v14.784a2.96,2.96,0,0,1-2.957,2.957h-1.912l-5.7,5.7A.739.739,0,0,1,406.916,492.612Z"
          transform="translate(-384 -466)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M416.052,477.328a.741.741,0,0,0-.589-.5l-2.162-.33-.976-2.077a.74.74,0,0,0-1.338,0l-.976,2.077-2.162.33a.739.739,0,0,0-.417,1.248l1.587,1.624-.377,2.308a.739.739,0,0,0,1.087.766l1.927-1.064,1.927,1.064a.739.739,0,0,0,1.087-.766l-.377-2.308,1.587-1.624A.741.741,0,0,0,416.052,477.328Zm-3.077,1.609a.738.738,0,0,0-.2.636l.191,1.175-.953-.526a.736.736,0,0,0-.714,0l-.954.526.192-1.175a.74.74,0,0,0-.2-.636l-.842-.861,1.129-.173a.736.736,0,0,0,.557-.415l.475-1.01.475,1.01a.737.737,0,0,0,.557.415l1.13.173Zm-7.122-2.105-2.162-.33-.977-2.077a.74.74,0,0,0-1.338,0L400.4,476.5l-2.162.33a.739.739,0,0,0-.417,1.248l1.587,1.624-.377,2.308a.739.739,0,0,0,1.087.766l1.927-1.064,1.927,1.064a.739.739,0,0,0,1.087-.766l-.377-2.308,1.587-1.624a.739.739,0,0,0-.417-1.248Zm-2.488,2.105a.738.738,0,0,0-.2.636l.191,1.175-.953-.526a.736.736,0,0,0-.714,0l-.954.526.192-1.175a.74.74,0,0,0-.2-.636l-.842-.861,1.129-.173a.736.736,0,0,0,.557-.415l.475-1.01.475,1.01a.737.737,0,0,0,.557.415l1.13.173Zm-7.122-2.105-2.162-.33-.977-2.077a.739.739,0,0,0-1.338,0l-.977,2.077-2.162.33a.74.74,0,0,0-.417,1.248L389.8,479.7l-.377,2.308a.739.739,0,0,0,.611.849.726.726,0,0,0,.12.01.739.739,0,0,0,.729-.62l.437-2.677a.74.74,0,0,0-.2-.636l-.842-.861,1.129-.173a.736.736,0,0,0,.557-.415l.475-1.01.475,1.01a.737.737,0,0,0,.557.415l2.552.39a.739.739,0,0,0,.223-1.461Z"
        transform="translate(-380.608 -468.086)"
        fill="currentColor"
      />
      <g transform="translate(0 19.22)">
        <path
          className="a"
          d="M384.653,505.306H381.7a.739.739,0,0,1,0-1.478h2.957a.74.74,0,0,0,.739-.739v-9.61h-6.653a.739.739,0,1,1,0-1.478h7.392a.739.739,0,0,1,.739.739v10.349A2.22,2.22,0,0,1,384.653,505.306Z"
          transform="translate(-378 -492)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco205;
