/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-12.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina12 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-12', className].join(' ');
  return (
    <svg
      id="disciplina_rugby"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="m12.63 8.788.9.9-1.469 1.465-.9-.896-.787.787.9.897-1.59 1.589-.899-.9-.787.787 2.587 2.587.787-.787-.9-.9 1.589-1.589.9.9.787-.787-.9-.9 1.47-1.466.9.896.787-.787-2.587-2.583-.788.787Zm8.043-5.034-.078-.348-.348-.077A14.207 14.207 0 0 0 3.33 20.25l.077.348.348.078c.999.218 2.018.327 3.04.327a14.183 14.183 0 0 0 5.347-1.04 14.112 14.112 0 0 0 4.7-3.122 14.243 14.243 0 0 0 3.623-6.162c.634-2.257.706-4.634.208-6.925Zm-1.016.59c.253 1.323.3 2.675.14 4.012L15.65 4.208a13.295 13.295 0 0 1 4.007.137ZM6.792 19.889c-.822 0-1.642-.077-2.45-.228a13.055 13.055 0 0 1-.14-4.01l4.147 4.147c-.517.06-1.037.091-1.557.091Zm9.262-3.835A12.994 12.994 0 0 1 9.7 19.568l-5.266-5.265a13.062 13.062 0 0 1 3.515-6.355 12.994 12.994 0 0 1 6.356-3.515l5.262 5.262a13.051 13.051 0 0 1-3.512 6.358Z"
      />
    </svg>
  );
};
export default Disciplina12;
