import { AnyAction, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';
import { tipoCarrelloSelected, toggleCarrello } from 'features/carrello/carrelloSlice';
import {
  scommessaAddedQuotaFissa,
  scommessaAddedTotalizzatore,
  scommessaRemoved,
  setNumEsitiTotalizzatore,
  ticketRemoved,
} from './components/ippicaTicket/ippicaTicketSlice';
import {
  initCampiMatrix,
  initIppicaQuoteNaz,
  initIppicaQuoteTot,
  initIppicaTodayMap,
  removeCampoCavalliMatrix,
  resetCampiCavalliMatrix,
  resetCampiMatrix,
  resetCampiMatrixSelected,
  resetColumnAndRowCavalloSelected,
} from './ippicaSlice';
import {
  selectBlockIppicaScommessa,
  selectCavalli,
  selectCavalliVincentiOrdineArrivoList,
  selectCavalloFromEsitoKey,
  selectCavalloFromSiglaSistema,
  selectCodiceProgrammaAndAvvenimentoTotOrNazionale,
  selectColonneByTipoScommessaFromSettings,
  selectCurrentScommesseType,
  selectDescrizioneCampi,
  selectDescrizioneTot,
  selectEsitoFromEsitoMap,
  selectIppicaRaceDetail,
  selectNumeroCavalliMatrix,
  selectQuotaCampiTotValue,
  selectQuotaTotValue,
  selectTipoScommessaFromCodiceScommessaCampi,
  selectTipoScommessaFromEsitoKey,
  selectTipoScommessaFromEsitoKeyTot,
  selectTipoScommessaKey,
  selectTipoScommessaListFromIppicaSettings,
} from './selectors';

import { appInsight } from 'components/appInsight';
import format from 'date-fns/format';
import { extendedApiIppica } from 'features/api/ippicaApiSlice';
import { CarrelloTipo } from 'features/carrello/types';
import { formatKey } from 'features/virtual/utils/functions';
import { RootState } from 'lib/centralStore';
import { CartTipoVenditaEnum, IppicaQuotaDto } from 'types/swagger';
import { breakpoint } from 'utility/constant';
import raise from 'utility/raise';
import {
  composeCoppiaCavalli,
  composeNumeroCavallo,
} from './components/ippicaScommesseRenderer/ippicaScommesseRendererTestaATesta/actions';
import { isQuotaFissa } from './functions';
import { BlockScommessa } from './settings/settings-types';
import { convertiNumeroCavalliMatrixInStringaEsadecimale } from './utils';
import { KeyManagerIppica } from './utils/keyManagerIppica';
import { removeFromCartByKey } from 'features/carrello/actions';

export type GetQuoteCombinateProps = {
  codiceTipoScommessa: number;
};
export type GetQuoteFinaliProps = {
  codiceTipoScommessa: number;
};

export const getQuoteCombinate = createAsyncThunk(
  'getQuoteCombinate',
  async ({ codiceTipoScommessa }: GetQuoteCombinateProps, { dispatch, getState }) => {
    const state = getState() as RootState;
    const race = selectIppicaRaceDetail(state);

    const {
      codiceProgramma,
      numeroAvvenimento,
      isTris,
      isTot,
      trisCodiceProgramma,
      trisNumeroAvvenimento,
      totCodiceProgramma,
      totNumeroAvvenimento,
    } = race;
    dispatch(initIppicaQuoteTot({ isLoading: true }));
    dispatch(initIppicaQuoteNaz({ isLoading: true }));

    if (isTot && !isTris) {
      const responseTotalizzatore = await dispatch(
        extendedApiIppica.endpoints.getIppicaQuoteTot.initiate(
          {
            idProgramma: codiceProgramma,
            idAvvenimento: numeroAvvenimento,
            idProgrammaTot: totCodiceProgramma,
            idAvvenimentoTot: totNumeroAvvenimento,
            codiceTipoScommessa: codiceTipoScommessa,
          },
          { forceRefetch: true }
        )
      ).unwrap();
      dispatch(initIppicaQuoteTot({ data: responseTotalizzatore, isLoading: false }));
      return;
    }
    if (!isTot && isTris) {
      const responseNazionale = await dispatch(
        extendedApiIppica.endpoints.getIppicaQuoteNaz.initiate(
          {
            idProgramma: codiceProgramma,
            idAvvenimento: numeroAvvenimento,
            idProgrammaNaz: trisCodiceProgramma,
            idAvvenimentoNaz: trisNumeroAvvenimento,
            codiceTipoScommessa: codiceTipoScommessa,
          },
          { forceRefetch: true }
        )
      ).unwrap();
      dispatch(initIppicaQuoteNaz({ data: responseNazionale, isLoading: false }));
      return;
    }
    const { data: responseTotalizzatore } = await dispatch(
      extendedApiIppica.endpoints.getIppicaQuoteTot.initiate(
        {
          idProgramma: codiceProgramma,
          idAvvenimento: numeroAvvenimento,
          codiceTipoScommessa: codiceTipoScommessa!,
          idProgrammaTot: totCodiceProgramma ?? '',
          idAvvenimentoTot: totNumeroAvvenimento ?? '',
        },
        { forceRefetch: true }
      )
    );
    const { data: responseNazionale } = await dispatch(
      extendedApiIppica.endpoints.getIppicaQuoteNaz.initiate(
        {
          idProgramma: codiceProgramma,
          idAvvenimento: numeroAvvenimento,
          codiceTipoScommessa: codiceTipoScommessa!,
          idProgrammaNaz: trisCodiceProgramma ?? '',
          idAvvenimentoNaz: trisNumeroAvvenimento ?? '',
        },
        { forceRefetch: true }
      )
    );
    dispatch(initIppicaQuoteNaz({ data: responseNazionale, isLoading: false }));
    dispatch(initIppicaQuoteTot({ data: responseTotalizzatore, isLoading: false }));
  }
);

export const addToIppicaCartByKey =
  ({ esitoKey }: { esitoKey: string }): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const state = getState();
    const {
      isAntepost,
      numeroCorsa,
      dataOraAvvenimento,
      descrizioneRiunione,
      descrizioneAvvenimento,
      codiceProgramma,
      numeroAvvenimento,
      ...others
    } = selectIppicaRaceDetail(state);
    const currentScommesseType = selectCurrentScommesseType(state);
    const blockScommessa = selectBlockIppicaScommessa(state);
    const { infoAggiuntiva } = new KeyManagerIppica(esitoKey);

    let nomeCavallo: string | undefined;
    let coppiaCavalli: string | undefined;
    if (blockScommessa === BlockScommessa.TestaTesta && infoAggiuntiva) {
      const { numeroCavallo: numeroEvento } = new KeyManagerIppica(esitoKey);

      if (numeroEvento) {
        const numeroCavallo = composeNumeroCavallo(infoAggiuntiva, numeroEvento);
        const { cavalliDict } = others;
        coppiaCavalli = composeCoppiaCavalli(infoAggiuntiva);
        nomeCavallo = cavalliDict[`${codiceProgramma}_${numeroAvvenimento}_${numeroCavallo}`].nomeCavallo;
      }
    } else if (blockScommessa === BlockScommessa.PariDispari) {
      const { numeroCavallo: numeroEvento } = new KeyManagerIppica(esitoKey);
      nomeCavallo = numeroEvento && numeroEvento === '1' ? 'Pari' : 'Dispari';
    } else {
      nomeCavallo = selectCavalloFromEsitoKey(state, esitoKey).nomeCavallo;
    }

    const date = dataOraAvvenimento ? new Date(dataOraAvvenimento) : new Date();

    if (isQuotaFissa(currentScommesseType!)) {
      const { tipoInfoAggiuntiva, descrizioneTipoScommessa } = selectTipoScommessaFromEsitoKey(state, esitoKey);
      const { numeroEvento, codiceTipoScommessa, quota } = selectEsitoFromEsitoMap(state, esitoKey) as IppicaQuotaDto;
      dispatch(
        scommessaAddedQuotaFissa({
          ticketAvvenimento: {
            isAntepost,
            numeroCorsa,
            codiceProgramma,
            numeroAvvenimento,
            dataOraAvvenimento: format(date, 'dd/MM/yy - HH:mm'),
            descrizioneRiunione,
            codiceTipoScommessa,
            descrizioneAvvenimento,
            siglaSistema: '',
          },
          ticketEsito: {
            quota: quota ?? 0,
            nomeCavallo,
            numeroEvento,
            id: esitoKey,
            tipoInfoAggiuntiva,
            codiceTipoScommessa,
            descrizioneTipoScommessa: `${descrizioneTipoScommessa} ${coppiaCavalli ?? ''} `,
            infoAggiuntiva: infoAggiuntiva!,
          },
          tipoVendita: CartTipoVenditaEnum.QUOTAFISSA,
        })
      );
      dispatch(tipoCarrelloSelected(CarrelloTipo.Ippica));
    } else {
      try {
        const { codiceTipoScommessa: codiceSiglaSistema } = new KeyManagerIppica(esitoKey);
        // console.log('esitoKey', esitoKey);
        const { codiceProgramma, numeroAvvenimento, tipoVendita } =
          selectCodiceProgrammaAndAvvenimentoTotOrNazionale(state, codiceSiglaSistema!) ?? raise('settings not found');
        const { tipoInfoAggiuntiva } =
          selectTipoScommessaFromEsitoKeyTot(state, esitoKey) ?? raise('infoTipoAggiuntiva not Fount');
        const descrizioneTipoScommessa = selectDescrizioneTot(state, esitoKey) ?? '';
        const { unita, minSco, codiceTipoScommessa, codiceSistema, siglaSistema } =
          selectQuotaTotValue(state, esitoKey) ?? raise('Quota non trovata');
        const cavallo = selectCavalloFromSiglaSistema(state, esitoKey);
        // const { numeroEvento } = selectQuotaScommessaFromEsitoKey(getState(), esitoKey);
        dispatch(
          scommessaAddedTotalizzatore({
            tipoVendita,
            ticketAvvenimento: {
              isAntepost,
              numeroCorsa,
              codiceProgramma,
              numeroAvvenimento,
              dataOraAvvenimento: format(date, 'dd/MM/yy - HH:mm'),
              descrizioneRiunione,
              descrizioneAvvenimento,
              cavalliMap: {
                [String(cavallo.numeroCavallo)]: cavallo.nomeCavallo ?? `${cavallo.numeroCavallo}`,
              },
              siglaSistema,
              mappe: [[cavallo.numeroCavallo]],
              codiceSistema: +codiceSistema,
              codiceTipoScommessa,
            },
            ticketEsito: {
              nomeCavallo: cavallo.nomeCavallo,
              codiceTipoScommessa,
              numeroEvento: cavallo.numeroCavallo,
              id: esitoKey,
              tipoInfoAggiuntiva,
              descrizioneTipoScommessa: descrizioneTipoScommessa ?? '',
              infoAggiuntiva: infoAggiuntiva!,
            },
            unita,
            minimoScommettibile: minSco,
          })
        );

        // dispatch(setNumEsitiTotalizzatore(1));
        dispatch(tipoCarrelloSelected(CarrelloTipo.Ippica));
      } catch (e) {
        appInsight.trackException({ error: e });
      }
    }
  };

export const getQuoteFinali = createAsyncThunk(
  'getQuoteFinali',
  async ({ codiceTipoScommessa }: GetQuoteFinaliProps, { dispatch, getState }) => {
    const state = getState() as RootState;
    const race = selectIppicaRaceDetail(state);
    const combinazione = selectCavalliVincentiOrdineArrivoList(state);
    const { trisCodiceProgramma, trisNumeroAvvenimento, totCodiceProgramma, totNumeroAvvenimento } = race;

    // dispatch(initIppicaQuoteFinali({ isLoading: true }));

    const response = await dispatch(
      extendedApiIppica.endpoints.getIppicaQuoteFinali.initiate({
        idProgrammaTot: totCodiceProgramma ?? '',
        idAvvenimentoTot: totNumeroAvvenimento ?? '',
        codiceTipoScommessa: codiceTipoScommessa!,
        idProgrammaNaz: trisCodiceProgramma ?? '',
        idAvvenimentoNaz: trisNumeroAvvenimento ?? '',
        ordineArrivo: combinazione,
      })
    ).unwrap();
    // dispatch(initIppicaQuoteFinali({ data: response, isLoading: false }));
    return response!;
  }
);

// Funzione per richiamare la query e aggiornare lo stato
export const fetchAndUpdateData = createAsyncThunk(
  'getFetchAndUpdateData',
  async (param: Array<{ [key: string]: string }>, { dispatch }) => {
    try {
      // Richiama la query con i parametri desiderati
      dispatch(initIppicaTodayMap({ isLoading: true }));
      let queryString;
      // Serializza l'array di parametri in una stringa di query
      if (!!param?.length) {
        queryString = param
          .map((obj) =>
            Object.entries(obj)
              .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
              .join('&')
          )
          .join('&');
      }
      const data = await dispatch(
        extendedApiIppica.endpoints.getCorseDiOggi.initiate(queryString ?? 'orderBy?=Cronologia')
      ).unwrap();
      dispatch(initIppicaTodayMap({ data, isLoading: false }));

      // Aggiorna lo stato con i nuovi dati
    } catch (error) {
      // Gestisci eventuali errori qui
      console.error('Error fetching data:', error);
    }
  }
);

type ValidationType = {
  width?: number;
  isChecked: boolean;
};

export const validation = createAsyncThunk(
  'validation',
  async ({ width, isChecked }: ValidationType, { dispatch, getState }) => {
    const state = getState() as RootState;
    const tipoScommessaListFromIppicaSettings = selectTipoScommessaListFromIppicaSettings(state);
    const tipoScommessaKey = selectTipoScommessaKey(state);
    const numeroCavalliMatrix = selectNumeroCavalliMatrix(state);
    const currentScommesseType = selectCurrentScommesseType(state);
    const blockScommessa = selectBlockIppicaScommessa(state);
    const {
      isAntepost,
      numeroCorsa,
      descrizioneRiunione,
      dataOraAvvenimento,
      descrizioneAvvenimento,
      codiceProgramma,
      numeroAvvenimento,
    } = selectIppicaRaceDetail(state);

    const isTipoScommessaInDisordine =
      blockScommessa === BlockScommessa.AccoppiataDisordine || blockScommessa === BlockScommessa.TrioDisordine;

    if (tipoScommessaListFromIppicaSettings && tipoScommessaKey && !!numeroCavalliMatrix?.length) {
      // const numeroGruppi = tipoScommessaSettings[siglaSistemaKey].n_gruppi;

      const isValid = tipoScommessaListFromIppicaSettings[tipoScommessaKey].colonne.every((colonna, index) => {
        const from = tipoScommessaListFromIppicaSettings[tipoScommessaKey].checkColonne[colonna].from;
        const to = tipoScommessaListFromIppicaSettings[tipoScommessaKey].checkColonne[colonna].to;
        return numeroCavalliMatrix[index].length >= from && numeroCavalliMatrix[index].length <= to;
      });

      if (isValid) {
        const key = new KeyManagerIppica({
          codiceProgramma: String(codiceProgramma),
          numeroAvvenimento: String(numeroAvvenimento),
          codiceTipoScommessa: tipoScommessaKey ?? '',
          infoAggiuntiva: '000000',
          codiceCavallo: '1',
        });

        const {
          codiceProgramma: codiceProgrammaTotOrTris,
          numeroAvvenimento: numeroAvvenimentoTotOrTris,
          tipoVendita,
        } = selectCodiceProgrammaAndAvvenimentoTotOrNazionale(state, tipoScommessaKey) ??
        raise(`avvenimento not found`);

        const date = dataOraAvvenimento ? new Date(dataOraAvvenimento) : new Date();

        const { tipoInfoAggiuntiva } =
          selectTipoScommessaFromCodiceScommessaCampi(state, key.codiceTipoScommessa!) ??
          raise('tipoInfoAggiuntiva notFoud');

        const descrizioneTipoScommessa =
          selectDescrizioneCampi(state, key.codiceTipoScommessa!) ?? raise('descrizione not found');
        const { unita, minSco, codiceTipoScommessa, codiceSistema, siglaSistema } =
          selectQuotaCampiTotValue(state, key.codiceTipoScommessa) ??
          raise('unita minSco codiceTipoScommessa codiceSistema not found');

        const cavalliMapTot = selectCavalli(state);
        // const cavallo = selectCavalloFromSiglaSistema(state, key.esitoKey);
        // const { numeroEvento } = selectQuotaScommessaFromEsitoKey(getState(), esitoKey);
        const objTemp = {};
        numeroCavalliMatrix.flatMap((item) => {
          // [ [1,2,3],[3,4,5]    ]
          const setItem = new Set(item);
          return [...setItem].forEach((numeroCavallo) => {
            // [1,2,3]
            objTemp[numeroCavallo] =
              cavalliMapTot[formatKey([key.codiceProgramma, key.numeroAvvenimento, String(numeroCavallo)])].nomeCavallo;
          });
        });
        if (currentScommesseType && isQuotaFissa(currentScommesseType)) {
          const infoAggiuntivaByNumeroCavalliMatrix = convertiNumeroCavalliMatrixInStringaEsadecimale(
            numeroCavalliMatrix,
            codiceTipoScommessa
          );

          const esitoKey = `${key.codiceProgramma}_${key.numeroAvvenimento}_${key.codiceTipoScommessa}_${infoAggiuntivaByNumeroCavalliMatrix}_1`;
          let esito = selectEsitoFromEsitoMap(state, esitoKey);
          let quota: number | undefined;

          if (state.ippicaTicket.esiti[esitoKey]) {
            dispatch(resetCampiMatrixSelected());
            dispatch(resetColumnAndRowCavalloSelected());
            dispatch(resetCampiCavalliMatrix());
            dispatch(removeFromCartByKey({ esitoKey, ambiente: 'ippica' }));
            return;
          }

          if (!esito) {
            try {
              const { quote } = await dispatch(
                extendedApiIppica.endpoints.ippicaRichiestaQuote.initiate({
                  esitoKey,
                })
              ).unwrap();
              quota = quote[esitoKey].quota;
            } catch (e) {
              console.error('Error fetching data:', e);
            }
          } else {
            quota = esito.quota;
          }

          dispatch(
            scommessaAddedQuotaFissa({
              ticketAvvenimento: {
                isAntepost,
                numeroCorsa,
                codiceProgramma,
                numeroAvvenimento,
                dataOraAvvenimento: format(date, 'dd/MM/yy - HH:mm'),
                descrizioneRiunione,
                codiceTipoScommessa,
                descrizioneAvvenimento,
                siglaSistema: '',
              },
              ticketEsito: {
                quota: quota ?? 0,
                nomeCavallo: isTipoScommessaInDisordine
                  ? `[${numeroCavalliMatrix.flat().sort().join(', ')}]`
                  : `[${numeroCavalliMatrix.flat().join(', ')}]`,
                numeroEvento: 1,
                id: esitoKey,
                tipoInfoAggiuntiva,
                codiceTipoScommessa,
                descrizioneTipoScommessa,
                infoAggiuntiva: infoAggiuntivaByNumeroCavalliMatrix,
              },
              tipoVendita: CartTipoVenditaEnum.QUOTAFISSA,
            })
          );
          dispatch(resetCampiMatrixSelected());
          dispatch(resetColumnAndRowCavalloSelected());
          dispatch(resetCampiCavalliMatrix());
        } else {
          dispatch(
            scommessaAddedTotalizzatore({
              tipoVendita,
              ticketAvvenimento: {
                isAntepost,
                numeroCorsa,
                codiceProgramma: codiceProgrammaTotOrTris,
                numeroAvvenimento: numeroAvvenimentoTotOrTris,
                dataOraAvvenimento: format(date, 'dd/MM/yy - HH:mm'),
                descrizioneRiunione,
                descrizioneAvvenimento,
                cavalliMap: objTemp,
                codiceTipoScommessa,
                codiceSistema: +codiceSistema,
                siglaSistema,
                mappe: numeroCavalliMatrix, //[[cavallo.numeroCavallo]], //[[2],[],[1]]
              },
              ticketEsito: {
                nomeCavallo: 'nomeCavallo', //cavallo.nomeCavallo,
                numeroEvento: 1, //cavallo.numeroCavallo,
                id: key.esitoKey,
                tipoInfoAggiuntiva,
                codiceTipoScommessa,
                descrizioneTipoScommessa: descrizioneTipoScommessa ?? '',
                infoAggiuntiva: '000000',
              },
              unita,
              minimoScommettibile: minSco,
            })
          );
        }

        // dispatch(setNumEsitiTotalizzatore(1));
        dispatch(tipoCarrelloSelected(CarrelloTipo.Ippica));
        dispatch(openCartCampi({ isOpen: isValid, width }));
      } else {
        if (!isChecked) {
          dispatch(ticketRemoved());
        }
      }
    } else {
      return;
    }
  }
);

export const removeTicketAndCampiSelected = createAsyncThunk(
  'removeTicketAndCampiSelected',
  async (esitoId: string | undefined, { dispatch }) => {
    esitoId ? dispatch(scommessaRemoved(esitoId)) : dispatch(ticketRemoved());
    dispatch(resetCampiMatrixSelected());
    dispatch(resetColumnAndRowCavalloSelected());
    dispatch(resetCampiCavalliMatrix());
    dispatch(setNumEsitiTotalizzatore(0));
  }
);

export const getMatrix = createAsyncThunk('getMatrix', async (_, { dispatch, getState }) => {
  const state = getState() as RootState;
  const { cavalliKeyList } = selectIppicaRaceDetail(state);
  const valueColumn = selectColonneByTipoScommessaFromSettings(state);

  dispatch(resetCampiMatrix());
  dispatch(resetCampiCavalliMatrix());
  dispatch(initCampiMatrix({ cavalliKeyList, valueColumn }));
});

export const openCartCampi = createAsyncThunk(
  'openCartCampi',
  async ({ isOpen, width }: { isOpen: boolean; width: number | undefined }, { dispatch }) => {
    dispatch(
      toggleCarrello({
        isOpen: isOpen,
        isFullCart: width && width < breakpoint.md ? false : true,
      })
    );
  }
);
