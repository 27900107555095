/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\plus.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Plus = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-plus', className].join(' ');
  return (
    <svg id="plus" viewBox="0 0 14 14" focusable="false" className={componentClassName} {...attribs}>
      <path d="M6 14V8H0V6H6V0H8V6H14V8H8V14H6Z" fill="currentColor" />
    </svg>
  );
};
export default Plus;
