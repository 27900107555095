/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\happybet-logo.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const HappybetLogo = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-happybet-logo', className].join(' ');
  return (
    <svg
      id="happybet-logo"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path d="M20 0H0V20H20V0Z" fill="#92C73F" />
      <path
        d="M1.38 1.37988H2.46V3.41988H4.54001V1.37988H5.60001V6.57988H4.54001V4.27988H2.46V6.57988H1.38V1.37988ZM8.12001 1.37988H9.24001L11.1 6.57988H9.98001L9.62001 5.47988H7.70001L7.36001 6.57988H6.28001L8.12001 1.37988ZM7.96001 4.65988H9.36001L8.88001 3.15988C8.80001 2.87988 8.66001 2.35988 8.66001 2.35988H8.64001C8.64001 2.35988 8.50001 2.87988 8.42001 3.15988L7.96001 4.65988ZM11.76 1.37988H14.08C14.7 1.37988 15.16 1.55988 15.46 1.85988C15.76 2.15988 15.94 2.55988 15.94 3.03988C15.94 4.01988 15.28 4.67988 14.16 4.67988H12.86V6.55988H11.74V1.37988H11.76ZM12.86 3.83988H13.98C14.54 3.83988 14.84 3.53988 14.84 3.05988C14.84 2.57988 14.52 2.29988 14 2.29988H12.88V3.83988H12.86ZM1.38 7.39988H3.70001C4.32001 7.39988 4.78001 7.57988 5.08001 7.87988C5.38001 8.17988 5.56001 8.57988 5.56001 9.05988C5.56001 10.0399 4.90001 10.6999 3.78 10.6999H2.48V12.5999H1.38V7.39988ZM2.50001 9.85988H3.60001C4.16001 9.85988 4.46001 9.55988 4.46001 9.07988C4.46001 8.59988 4.14001 8.31988 3.62001 8.31988H2.50001V9.85988ZM7.48001 10.5999L5.72001 7.39988H6.90001L7.70001 8.93988C7.86001 9.25988 8.04001 9.67988 8.04001 9.67988H8.06001C8.06001 9.67988 8.26001 9.25988 8.42001 8.93988L9.20001 7.39988H10.32L8.56001 10.5999V12.5999H7.48001V10.5999ZM1.38 13.4199H3.76C4.34001 13.4199 4.76001 13.5599 5.04001 13.7999C5.32001 14.0599 5.46001 14.3599 5.46001 14.7799C5.46001 15.3199 5.12001 15.6599 4.64001 15.8199V15.8399C5.20001 15.9999 5.60001 16.4799 5.60001 17.1199C5.60001 17.5999 5.44001 17.9399 5.14001 18.1999C4.84001 18.4599 4.36001 18.6199 3.8 18.6199H1.38V13.4199ZM3.62001 15.5199C4.08001 15.5199 4.40001 15.2999 4.40001 14.8999C4.40001 14.5199 4.12001 14.2799 3.64001 14.2799H2.46V15.5199H3.62001ZM2.46 17.7399H3.70001C4.22001 17.7399 4.52 17.4599 4.52 17.0399C4.52 16.5399 4.14001 16.2999 3.66001 16.2999H2.46V17.7399ZM6.28001 13.4199H10.16V14.3199H7.34001V15.4799H9.82001V16.3399H7.34001V17.7399H10.2V18.6199H6.28001V13.4199ZM10.86 13.4199H15.12V14.3199H13.52V18.6199H12.44V14.3199H10.86V13.4199Z"
        fill="#112F4F"
      />
    </svg>
  );
};
export default HappybetLogo;
