import { existsSync, mkdirSync, readFileSync, writeFileSync } from 'fs';

import { getLngFromCultureName } from 'hooks/useLingUI';
import { resolve } from 'path';
import { TokenResponse } from 'types/login';
import { defaultLang, SSR_ROOT_CACHE_PATH } from 'utility/constant';
import { isTruthy } from 'utility/functions';
import { emptyToken } from '.';
import { doGet as webGET } from './web';

type CachedToken = TokenResponse & {
  expiring: string;
  expired: string;
};

const parts = [...SSR_ROOT_CACHE_PATH, 'auth'];

export const doGet = async (): Promise<TokenResponse> => {
  let asyncPromise: Promise<TokenResponse> | undefined;

  const location = __dirname.substring(0, __dirname.toLowerCase().lastIndexOf('.next')) || '.';
  const CACHE_FOLDER = resolve(location, ...parts);
  const CACHE_PATH = resolve(CACHE_FOLDER, `.token`);

  let cachedData: CachedToken | null | undefined;

  try {
    if (existsSync(CACHE_PATH)) {
      const json = readFileSync(CACHE_PATH, 'utf8');
      cachedData = JSON.parse(json) as CachedToken;
    } else {
      console.log(`AUTH.ssr.get ${CACHE_PATH}: file not found`);
      let tmp = location;
      for (const part of parts) {
        tmp = resolve(tmp, part);
        if (!existsSync(tmp)) {
          try {
            mkdirSync(tmp);
          } catch (e) {
            console.log(`AUTH.ssr:`, e);
          }
        }
      }
    }
  } catch (error) {
    console.log(`AUTH.ssr: token not found -> "${CACHE_PATH}"`);
  }
  const dtNow = new Date();

  const triggerUpdate = dtNow.toISOString() >= `${cachedData?.expiring ?? ''}`;
  // console.log(`triggerUpdate:${triggerUpdate}, now:'${dtNow.toISOString()}' >= '${cachedData?.expiring}'`);

  const syncResponse = dtNow.toISOString() < `${cachedData?.expired ?? ''}`;
  // console.log(`syncResponse:${syncResponse}, now:'${dtNow.toISOString()}' < '${cachedData?.expired}'`);

  if (triggerUpdate || !syncResponse) {
    console.log(`AUTH.ssr: cached data expired: request over the wire...`);

    asyncPromise = new Promise<TokenResponse>(async (resolve) => {
      const dtTrigger = new Date();
      const data = await webGET();

      if (isTruthy(data.expires_in)) {
        dtNow.setSeconds(dtNow.getSeconds() + data.expires_in);
        Reflect.set(data, 'expired', dtNow.toISOString());

        dtTrigger.setSeconds(dtTrigger.getSeconds() + data.expires_in * 0.9);
        Reflect.set(data, 'expiring', dtTrigger.toISOString());

        console.log(`Writing access token to "${CACHE_PATH}" cache file!!!`);
        writeFileSync(CACHE_PATH, JSON.stringify(data), 'utf8');
      }

      resolve(data);
    });
  }

  if (syncResponse) {
    return Promise.resolve({ ...emptyToken, ...cachedData });
  }

  if (asyncPromise) {
    return asyncPromise;
  }

  throw new Error(`ERROR: ssr.doGet: nothing to handle`);
};

// server-side-rendering request params
export const ssrHeaders = async (lang: string | undefined | null = defaultLang) => {
  const headers = {};

  const { token_type, access_token } = await doGet();

  Reflect.set(headers, 'Accept', `*/*`);
  Reflect.set(headers, 'Country', `${process.env.NEXT_PUBLIC_COUNTRY}`);
  Reflect.set(headers, 'Language', getLngFromCultureName(`${lang ?? defaultLang}`));
  Reflect.set(headers, 'Authorization', `${token_type} ${access_token}`);
  Reflect.set(headers, 'Accept-Encoding', `gzip, deflate, br`);

  return Promise.resolve({ headers });
};
