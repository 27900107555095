import { Esito, InfoTipoScommessa, Scommessa, ScommessaResponse } from 'lib/api/sport/sportScommesseBySlugResponse';
import {
  ItemWithTranslation,
  SportsAvvenimentoEsposto,
  SportsCacheTemplateDisciplinaDto,
  SportsCacheTemplateDisciplinaLiveDto,
  SportsLiveSection,
  SportsPiuGiocateTabDto,
} from 'types/swagger';

import { AsyncThunk } from '@reduxjs/toolkit';

export const SCOMMESSA_PREMATCH_API = 'ScommessePreMatch';

/* eslint-disable no-unused-vars */
export enum ScoreBoardProvider {
  BetRadar = 1,
  BetGenius = 2,
  SNAI = 22,
}

export interface ListenerType<T> {
  eventName: string;
  actionType?: string;
  action?: AsyncThunk<T, any, any>;
}

// Define a type for the slice state
export type UpdateEventType = 'avvenimento' | 'quote' | 'infoAgg';

export type UpdateCounterType = {
  // eslint-disable-next-line no-unused-vars
  [key in UpdateEventType]: {
    counter: number;
  };
};

export interface SportFilters {
  quota: number[];
  orario: string;
  isResetted: boolean;
}

export type LiveType = Omit<SportsLiveSection, 'scoreBoardProvider'> & {
  minutes?: number;
  seconds?: number;
  idProgramma: number;
  idAvvenimento: number;
  scoreBoardProvider: ScoreBoardProvider;
};

export type Quota = {
  value: number;
  esito?: string;
  header?: string;
  esitoKey?: string;
  isIncreasing?: boolean;
  isDecreasing?: boolean;
};

export interface InfoTipoListPayload {
  headerSequence: Record<string, Array<number>>;
  infoTipoScommessaList: Array<InfoTipoScommessa>;
}

export type SportTicketInfo = {
  ticketAvvenimento: Pick<
    SportsAvvenimentoEsposto,
    | 'key'
    | 'ora'
    | 'data'
    | 'dataOra'
    | 'idProgramma'
    | 'idDisciplina'
    | 'idAvvenimento'
    | 'slugDisciplina'
    | 'idManifestazione'
    | 'descrizioneTrKey'
    | 'slugManifestazione'
    | 'descrizioneManifestazione'
    | 'descrizioneManifestazioneTrKey'
  > &
    ItemWithTranslation<{ descrizioneAvvenimento: string }>;
  ticketScommessa: Pick<
    Scommessa,
    'multipla' | 'idTipoScommessa' | 'minCombinazioni' | 'maxCombinazioni' | 'idTipoInfoAggiuntiva'
  > &
    Pick<InfoTipoScommessa, 'isAntepost'> &
    ItemWithTranslation<{ descrizione: string }>;
};

export type SportTicketInfoEsito = {
  ticketEsito: { id: string } & Pick<Esito, 'quota' | 'isActive' | 'idEsito' | 'infoAggiuntiva'> &
    ItemWithTranslation<{ descrizione?: string; descrizioneTipoScommessaWithInfoAgg?: string }>;
};

export type ScommessaAddedPayload = SportTicketInfo & SportTicketInfoEsito;

export interface RemoveAvvenimentoProps {
  avvenimentoKey: string;
  updateTemplate?: SportsCacheTemplateDisciplinaLiveDto;
  updateTemplatePrematch?: SportsCacheTemplateDisciplinaDto;
}

export type UpdateStatoInfoAgg = {
  updateStatoInfoAggMap: {
    [key: string]: {
      scommessaKey: string;
      infoAggList: Array<string>;
      isActive: boolean;
    };
  };
};

export type TipoScommessaDescrizioniType = ItemWithTranslation<{ descrizione: string }>;

export interface AvvenimentoLinkType {
  shortUrl: string;
  longUrl: string;
  found: boolean;
  first: boolean;
}

export interface getProssimiEventiProps {
  slug?: string;
}

export type ProssimiEventiStateType = {
  tabList: Array<SportsPiuGiocateTabDto>;
} & ScommessaResponse;
