/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { setHeaders } from 'common/types/swagger/base';

import type { HttpResponseType } from 'common/types/swagger/base';
import { checkResponse, handleException } from 'common/types/swagger/base';

export interface SportsAddAvvenimentoSignalREvent {
  avvenimento: SportsAvvenimentoEsposto;
  /** @minLength 1 */
  avvenimentoKey: string;
  /** @format int32 */
  idDisciplina: number;
  /** @format uuid */
  signalREventId: string;
  traduzioneMap?: Record<string, Record<string, string>>;
  updateProssimiEventi?: Array<SportsPiuGiocateTabDto> | undefined;
  updateTemplate?: SportsCacheTemplateDisciplinaLiveDto;
  updateTemplatePrematch?: SportsCacheTemplateDisciplinaDto;
}

export interface SportsAddDisciplinaSignalREvent {
  disciplinaMenuItem: SportsMenuDisciplinaItem;
  /** @format uuid */
  signalREventId: string;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsAddManifestazioneSignalREvent {
  manifestazioneMenuItem: SportsManifestazioneMenuItem;
  /** @format uuid */
  signalREventId: string;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsAddScommessaSignalREvent {
  /** @format int32 */
  betRadarMatchId?: number | undefined;
  esitoMap: Record<string, SportsEsito>;
  infoAggiuntivaMap: Record<string, SportsInfoAggiuntivaMapDto>;
  infoTipoScommessaGroupMap?: Record<string, SportsInfoTipoScommessaGroupDto>;
  infoTipoScommessaMap: Record<string, SportsInfoTipoScommessa>;
  scommessaMap: Record<string, SportsScommessaMapDto>;
  /** @format uuid */
  signalREventId: string;
  /** @minLength 1 */
  slug: string;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsAvvenimentoDto {
  /** @format date-time */
  closedTime?: string | undefined;
  /** @format date-time */
  creationTime: string;
  /** @format date-time */
  dataOra: string;
  descAvvenimento?: string | undefined;
  descAvvenimentoTrKey?: string | undefined;
  descDisciplina?: string | undefined;
  descDisciplinaTrKey?: string | undefined;
  descGruppo?: string | undefined;
  descGruppoTrKey?: string | undefined;
  descManifestazione?: string | undefined;
  descManifestazioneTrKey?: string | undefined;
  /** @format int32 */
  externalProviderMatchId: number;
  fase: SportsPhaseType;
  /** @format int32 */
  idAvvenimento: number;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idGruppo: number;
  /** @format int32 */
  idManifestazione: number;
  /** @format int32 */
  idProgramma: number;
  key?: string | undefined;
  legameMultipla?: string | undefined;
  live?: SportsLiveSection;
  loadedFromDB: boolean;
  /** @format int32 */
  prioritaDisciplina: number;
  /** @format int32 */
  prioritaGruppo: number;
  /** @format int32 */
  prioritaManifestazione: number;
  scommesseAttive: boolean;
  /** @format int32 */
  score?: number | undefined;
  slug?: string | undefined;
  stato?: string | undefined;
  statoCache: SportsStatoAvvenimentoCache;
  tipoAvvenimento?: string | undefined;
}

export interface SportsAvvenimentoEsposto {
  data?: string | undefined;
  /** @format date-time */
  dataOra: string;
  descrizione?: string | undefined;
  descrizioneManifestazione?: string | undefined;
  descrizioneManifestazioneTrKey?: string | undefined;
  descrizioneTrKey?: string | undefined;
  /** @format int32 */
  externalProviderMatchId: number;
  filtro?: SportsFiltroAvvenimento;
  firstCompetitor?: SportsCompetitor;
  /** @format int32 */
  idAvvenimento: number;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idGruppo: number;
  /** @format int32 */
  idManifestazione: number;
  /** @format int32 */
  idProgramma: number;
  isActive: boolean;
  key?: string | undefined;
  live?: SportsLiveSection;
  /** @format int32 */
  numeroScommesse: number;
  ora?: string | undefined;
  /** @format int32 */
  prioritaDisciplina: number;
  /** @format int32 */
  prioritaGruppo: number;
  /** @format int32 */
  prioritaManifestazione: number;
  scommesseAttive: boolean;
  secondCompetitor?: SportsCompetitor;
  slug?: string | undefined;
  slugAvvenimento?: string | undefined;
  slugDefaultScommessaGroup?: string | undefined;
  slugDisciplina?: string | undefined;
  slugGruppo?: string | undefined;
  slugManifestazione?: string | undefined;
  tipoAvvenimento: SportsTipoAvvenimento;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsBetRadarGiocatoreDto {
  playerId?: string | undefined;
  seasonId?: string | undefined;
  teamUid?: string | undefined;
  uniqueTournamentId?: string | undefined;
}

export interface SportsCacheFiltroTestualeDto {
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idGruppo: number;
  label?: string | undefined;
  labelTrKey?: string | undefined;
  value?: string | undefined;
}

export interface SportsCacheMenuManifestazioniLiveDto {
  sportList?: Array<SportsVocePrimoLivelloMenuLive> | undefined;
}

export interface SportsCacheMenuManifestazioniSportDto {
  descrizioneDisciplina?: string | undefined;
  descrizioneDisciplinaTrKey?: string | undefined;
  filters?: Array<any> | undefined;
  gruppi?: Array<SportsVoceMenu> | undefined;
  /** @format int32 */
  idDisciplina: number;
  preferiti?: Array<SportsFavoritesMenuManifestazioneDto> | undefined;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsCacheScommesseCoreDto {
  avvenimentoList?: Array<SportsAvvenimentoEsposto> | undefined;
  esitoMap?: Record<string, SportsEsito>;
  infoAggiuntivaMap?: Record<string, SportsInfoAggiuntivaMapDto>;
  infoTipoScommessaGroupMap?: Record<string, SportsInfoTipoScommessaGroupDto>;
  infoTipoScommessaMap?: Record<string, SportsInfoTipoScommessa>;
  pagination?: SportsPagination;
  scommessaMap?: Record<string, SportsScommessaMapDto>;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsCacheScommesseMarcatoriDto {
  avvenimentoList?: Array<SportsAvvenimentoEsposto> | undefined;
  esitoMap?: Record<string, SportsEsito>;
  infoAggiuntivaMap?: Record<string, SportsInfoAggiuntivaMapDto>;
  infoTipoScommessaGroupMap?: Record<string, SportsInfoTipoScommessaGroupDto>;
  infoTipoScommessaMap?: Record<string, SportsInfoTipoScommessa>;
  pagination?: SportsPagination;
  scommessaMap?: Record<string, SportsScommessaMapDto>;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsCacheTemplateAvvenimentoDto {
  data?: string | undefined;
  disciplina?: string | undefined;
  disciplinaTrKey?: string | undefined;
  externalProvider?: string | undefined;
  filters?: Record<string, Array<SportsFilterScomAvv>>;
  gruppoList?: Array<SportsGruppoScommessa> | undefined;
  /** @format int32 */
  idAvvenimento: number;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idManifestazione: number;
  /** @format int32 */
  idProgramma: number;
  key?: string | undefined;
  live?: SportsLiveSection;
  manifestazione?: string | undefined;
  manifestazioneTrKey?: string | undefined;
  ora?: string | undefined;
  slug?: string | undefined;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsCacheTemplateAvvenimentoMarcatoreDto {
  avvenimentoList?: Array<SportsAvvenimentoEsposto> | undefined;
  betRadarGiocatore: SportsBetRadarGiocatoreDto;
  gruppoList?: Array<SportsGruppoScommessa> | undefined;
  slugScommessaAvvenimentoMap?: Record<string, Array<string>>;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsCacheTemplateDisciplinaDto {
  disciplina?: string | undefined;
  disciplinaTrKey?: string | undefined;
  inEvidenza?: Array<string> | undefined;
  piuGiocate?: Array<SportsPiuGiocateStruttura> | undefined;
  topAntepost?: Array<SportsTopAntepostDto> | undefined;
  topManifestazioni?: Array<SportsCacheTopManifestazioneDto> | undefined;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsCacheTemplateDisciplinaLiveDto {
  filters?: Array<SportsFilterTemplateTopLive> | undefined;
  hasfilters: boolean;
  tabList?: Array<SportsPiuGiocateTabDto> | undefined;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsCacheTemplateManifestazioneCustomSectionDto {
  disciplina?: string | undefined;
  disciplinaTrKey?: string | undefined;
  externalProvider?: string | undefined;
  gruppo?: string | undefined;
  gruppoList?: Array<SportsGruppoScommessa> | undefined;
  gruppoTrKey?: string | undefined;
  iconSrc?: string | undefined;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idGruppo: number;
  /** @format int32 */
  idManifestazione: number;
  manifestazione?: string | undefined;
  manifestazioneTrKey?: string | undefined;
  slug?: string | undefined;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsCacheTemplateManifestazioneDto {
  disciplina?: string | undefined;
  disciplinaTrKey?: string | undefined;
  externalProvider?: string | undefined;
  gruppo?: string | undefined;
  gruppoList?: Array<SportsGruppoScommessa> | undefined;
  gruppoTrKey?: string | undefined;
  iconSrc?: string | undefined;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idGruppo: number;
  /** @format int32 */
  idManifestazione: number;
  manifestazione?: string | undefined;
  manifestazioneTrKey?: string | undefined;
  slug?: string | undefined;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsCacheTemplateManifestazioneMarcatoriDto {
  disciplina?: string | undefined;
  disciplinaTrKey?: string | undefined;
  externalProvider?: string | undefined;
  gruppo?: string | undefined;
  gruppoList?: Array<SportsGruppoScommessa> | undefined;
  gruppoTrKey?: string | undefined;
  iconSrc?: string | undefined;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idGruppo: number;
  /** @format int32 */
  idManifestazione: number;
  manifestazione?: string | undefined;
  manifestazioneTrKey?: string | undefined;
  slug?: string | undefined;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsCacheTopManifestazioneDto {
  disciplina?: string | undefined;
  disciplinaTrKey?: string | undefined;
  iconSrc?: string | undefined;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idManifestazione: number;
  isTop: boolean;
  manifestazione?: string | undefined;
  manifestazioneTrKey?: string | undefined;
  slug?: string | undefined;
}

export interface SportsCacheTranslationKeyDto {
  descrizione?: string | undefined;
  key?: string | undefined;
}

export interface SportsCartUpdate {
  avvenimentiSospesi?: Record<string, boolean>;
  esitoMap?: Record<string, SportsEsito>;
  infoAggiuntiveSospese?: Record<string, boolean>;
  risultatiniMap?: Record<string, SportsRisultatini>;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsCompetitor {
  descrizione?: string | undefined;
  descrizioneTrKey?: string | undefined;
  iconSrc?: string | undefined;
}

export interface SportsDirettissime {
  avvKey?: string | undefined;
  esitoKey?: string | undefined;
  scommessaKey?: string | undefined;
}

export interface SportsDizAvvDto {
  /** @format date-time */
  dataOraAvvenimento?: string | undefined;
  dataOraModifica?: string | undefined;
  descrizione?: string | undefined;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idAvvenimento: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idProgramma: number;
  key?: string | undefined;
  language?: string | undefined;
}

export interface SportsDizEsitiDto {
  dataOraModifica?: string | undefined;
  descrizione?: string | undefined;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idDisciplina: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idEvento: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idTipoSco: number;
  key?: string | undefined;
  language?: string | undefined;
}

export interface SportsDizEtichetteApplicativoDto {
  dataOraModifica?: string | undefined;
  descrizione?: string | undefined;
  etichetta?: string | undefined;
  /** @format int32 */
  idApplicativo: number;
  key?: string | undefined;
  language?: string | undefined;
}

export interface SportsDizGruppiManifDto {
  dataOraModifica?: string | undefined;
  descrizione?: string | undefined;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  groupId: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idDisciplina: number;
  key?: string | undefined;
  language?: string | undefined;
}

export interface SportsDizGruppiScommesseDto {
  dataOraModifica?: string | undefined;
  descrizione?: string | undefined;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  groupId: number;
  key?: string | undefined;
  language?: string | undefined;
}

export interface SportsDizManifDto {
  dataOraModifica?: string | undefined;
  descrizione?: string | undefined;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idDisciplina: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idManif: number;
  key?: string | undefined;
  language?: string | undefined;
}

export interface SportsDizScommesseAnagraficheDto {
  /** @format date-time */
  dataOraAvv?: string | undefined;
  dataOraModifica?: string | undefined;
  desElemento?: string | undefined;
  desScoOriginal?: string | undefined;
  descrizione?: string | undefined;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idAvvenimento: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idDisciplina: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idElemento: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idListaAnag: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idProgramma: number;
  /** @format int32 */
  idTipoSco: number;
  infoAgg?: string | undefined;
  key?: string | undefined;
  language?: string | undefined;
  tipoInfoAgg?: string | undefined;
}

export interface SportsDizScommesseDto {
  dataOraModifica?: string | undefined;
  descrizione?: string | undefined;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idDisciplina: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idTipoSco: number;
  infoAgg?: string | undefined;
  key?: string | undefined;
  language?: string | undefined;
}

export interface SportsDizScommesseModelliDto {
  dataOraModifica?: string | undefined;
  descrizione?: string | undefined;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idDisciplina: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idTipoSco: number;
  key?: string | undefined;
  language?: string | undefined;
}

export interface SportsDizSportDto {
  dataOraModifica?: string | undefined;
  descrizione?: string | undefined;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idDisciplina: number;
  key?: string | undefined;
  language?: string | undefined;
}

export enum SportsEntityType {
  Sport = 'Sport',
  ScommessaModelli = 'ScommessaModelli',
  ScommessaSottoGruppo = 'ScommessaSottoGruppo',
  ScommessaSottoGruppoTipi = 'ScommessaSottoGruppoTipi',
  Avvenimento = 'Avvenimento',
  Manifestazione = 'Manifestazione',
  GruppoManifestazione = 'GruppoManifestazione',
  Esito = 'Esito',
  Scommessa = 'Scommessa',
  GruppoScommessa = 'GruppoScommessa',
  EtichetteApplicativo = 'EtichetteApplicativo',
  ScommesseAnagrafiche = 'ScommesseAnagrafiche',
  InfoScommessa = 'InfoScommessa',
  OrdineEsiti = 'OrdineEsiti',
  FiltroOffertaCountryByDisc = 'FiltroOffertaCountryByDisc',
  FiltroOffertaCountryByAvv = 'FiltroOffertaCountryByAvv',
}

export interface SportsEsito {
  descrizione?: string | undefined;
  descrizioneTipoScommessaWithInfoAgg?: string | undefined;
  descrizioneTipoScommessaWithInfoAggTrKey?: string | undefined;
  descrizioneTrKey?: string | undefined;
  esitoDinamico: boolean;
  fase: SportsPhaseType;
  iconSrc?: string | undefined;
  idAvvenimento?: string | undefined;
  idDisciplina?: string | undefined;
  idEsito?: string | undefined;
  idProgramma?: string | undefined;
  idTipoScommessa?: string | undefined;
  infoAggWithAnag: boolean;
  infoAggiuntiva?: string | undefined;
  isActive: boolean;
  isDecreasing: boolean;
  isIncreasing: boolean;
  key?: string | undefined;
  /** @format double */
  quota: number;
  splitInfoAggiuntiva?: string | undefined;
}

export interface SportsFavoritesMenuManifestazioneDto {
  prossime12Ore: boolean;
  prossime24Ore: boolean;
  attivo: boolean;
  /** @format date-time */
  dateTimeSaved: string;
  descrizione?: string | undefined;
  descrizioneTrKey?: string | undefined;
  domani: boolean;
  id?: string | undefined;
  isCustom?: boolean | undefined;
  isPreferito: boolean;
  oggi: boolean;
  /** @format double */
  quotaMax: number;
  /** @format double */
  quotaMin: number;
  slug?: string | undefined;
}

export interface SportsFilter {
  find?: string | undefined;
  label?: string | undefined;
}

export interface SportsFilterScomAvv {
  filters?: Array<SportsFilter> | undefined;
  text?: string | undefined;
}

export interface SportsFilterTemplateTopLive {
  label?: string | undefined;
  labelTrKey?: string | undefined;
  type: SportsFilterTopLiveType;
  values?: Array<string> | undefined;
}

export enum SportsFilterTopLiveType {
  Minutaggio = 'Minutaggio',
  SommaGol = 'SommaGol',
  DiffGol = 'DiffGol',
  Fase = 'Fase',
  StatoMatch = 'StatoMatch',
  GolNoGol = 'GolNoGol',
}

export interface SportsFiltroAvvenimento {
  prossime12Ore: boolean;
  prossime24Ore: boolean;
  domani: boolean;
  oggi: boolean;
  /** @format double */
  quotaMax: number;
  /** @format double */
  quotaMin: number;
}

export interface SportsFiltroOffertaCountryByAvvDto {
  country?: string | undefined;
  /** @format date-time */
  dataCreazione?: string | undefined;
  /** @format date-time */
  dataModifica?: string | undefined;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idAvvenimento: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  idProgramma: number;
  idTemplate?: string | undefined;
  /** @format int32 */
  idTipoSco: number;
  infoAgg?: string | undefined;
  /** @format int32 */
  playPhase: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  stato: number;
}

export interface SportsFiltroOffertaCountryByDiscDto {
  country?: string | undefined;
  /** @format date-time */
  dataModifica?: string | undefined;
  idDisciplina?: string | undefined;
  idTemplate?: string | undefined;
  /** @format int32 */
  idTipoSco: number;
  infoAgg?: string | undefined;
  /** @format int32 */
  playPhase: number;
}

export interface SportsGruppoScommessa {
  descrizione?: string | undefined;
  descrizioneTrKey?: string | undefined;
  /** @format int32 */
  idGruppo: number;
  isNew: boolean;
  key?: string | undefined;
  /** @format int32 */
  priorita: number;
  slug?: string | undefined;
  textualFilters?: Array<SportsCacheFiltroTestualeDto> | undefined;
  tipoScommessaList?: Array<SportsTipoScommessa> | undefined;
}

export interface SportsGuardaSuSnaiDto {
  avvenimentoList?: Array<SportsAvvenimentoEsposto> | undefined;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsInfoAggiuntivaDto {
  betRadarGiocatore?: SportsBetRadarGiocatoreDto;
  /** @format int32 */
  defaultNextInfoAggIndex?: number | undefined;
  dipendenza?: string | undefined;
  esitoKeyList?: Array<string> | undefined;
  infoAggiuntivaHex?: string | undefined;
  isActive: boolean;
  isBilanciata: boolean;
  isLista?: boolean | undefined;
  /** @format int32 */
  maxCombinazioni: number;
  /** @format int32 */
  minCombinazioni: number;
  nextInfoAgg?: Array<SportsInfoAggiuntivaDto> | undefined;
  slug?: string | undefined;
  valore?: string | undefined;
  valoreTrKey?: string | undefined;
}

export interface SportsInfoAggiuntivaMapDto {
  /** @format int32 */
  defaultNextInfoAggIndex?: number | undefined;
  headers?: Array<string> | undefined;
  headersTrKey?: Array<string> | undefined;
  /** @format int32 */
  idAvvenimento: number;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idProgramma: number;
  /** @format int32 */
  idTipoScommessa: number;
  infoAggiuntivaList?: Array<SportsInfoAggiuntivaDto> | undefined;
  key?: string | undefined;
  /** @format int32 */
  maxCombinazioni: number;
  /** @format int32 */
  minCombinazioni: number;
  sizeInfoAggiuntive?: Array<string> | undefined;
  splitInfoAggiuntiva?: string | undefined;
}

export interface SportsInfoAggiuntivaMapUpdateDto {
  infoAggiuntivaList: Array<SportsInfoAggiuntivaDto>;
}

export interface SportsInfoScommessaPreMatch {
  descrizione?: string | undefined;
  descrizioneTrKey?: string | undefined;
  headers?: Array<string> | undefined;
  headersTrKey?: Array<string> | undefined;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idTipoScommessa: number;
  quotaList?: Array<string> | undefined;
  quotaListTrKey?: Array<string> | undefined;
}

export interface SportsInfoTipoScommessa {
  descrizione?: string | undefined;
  descrizioneTrKey?: string | undefined;
  headers?: Array<string> | undefined;
  headersInfoAggiuntive?: Array<string> | undefined;
  headersInfoAggiuntiveTrKey?: Array<string> | undefined;
  headersTrKey?: Array<string> | undefined;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idTipoScommessa: number;
  infoAggiuntiveHex?: Array<string> | undefined;
  isAntepost: boolean;
  key?: string | undefined;
  /** @format int32 */
  numeroEsiti: number;
  /** @format int32 */
  numeroInfoAggiuntive: number;
  sizeInfoAggiuntive?: Array<string> | undefined;
  splitInfoAggiuntiva?: string | undefined;
  tipoOrdinamentoEsiti: SportsTipoOrdinamentoEsiti;
  /** @format int32 */
  tipoVisualizzazione: number;
  toolTip?: string | undefined;
  toolTipTrKey?: string | undefined;
}

export interface SportsInfoTipoScommessaGroupDto {
  descrizione?: string | undefined;
  descrizioneTrKey?: string | undefined;
  key?: string | undefined;
  toolTip?: string | undefined;
  toolTipTrKey?: string | undefined;
}

export interface SportsLiveScore {
  firstCompetitor?: string | undefined;
  lastSetScore?: SportsSetScore;
  secondCompetitor?: string | undefined;
}

export interface SportsLiveSection {
  /** @format int32 */
  betGeniusMatchId?: number | undefined;
  /** @format int32 */
  betRadarMatchId?: number | undefined;
  coodsId?: string | undefined;
  /** @format int32 */
  externalProviderMatchId: number;
  hasStreaming?: boolean | undefined;
  /** @format int32 */
  idDisciplina: number;
  matchTime?: string | undefined;
  redCards?: Array<string> | undefined;
  scommessaPreMatch?: SportsInfoScommessaPreMatch;
  score?: SportsLiveScore;
  /** @format int32 */
  scoreBoardProvider: number;
  /** @format int32 */
  server?: number | undefined;
  status?: string | undefined;
  statusTrKey?: string | undefined;
}

export interface SportsManifestazioneMenuItem {
  /** @format int32 */
  counter: number;
  /** @minLength 1 */
  descrizione: string;
  descrizioneTrKey?: string | undefined;
  /** @format int32 */
  id: number;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  priorita: number;
  /** @format int32 */
  prioritaGruppo: number;
  /** @minLength 1 */
  slug: string;
}

export interface SportsMenuDisciplinaItem {
  /** @format int32 */
  counter: number;
  /** @minLength 1 */
  descrizione: string;
  descrizioneTrKey?: string | undefined;
  /** @format int32 */
  id: number;
  isLive: boolean;
  isPrematch: boolean;
  isRoot: boolean;
  isVirtual: boolean;
  /** @format int32 */
  priorita: number;
  slug?: string | undefined;
}

export interface SportsMenuDisciplineDto {
  discipline?: Array<SportsMenuDisciplinaItem> | undefined;
  /** @format int64 */
  now: number;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsPagination {
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  numberOfPages: number;
}

export enum SportsPhaseType {
  Prematch = 'Prematch',
  Live = 'Live',
}

export interface SportsPiuGiocateContentTabDto {
  avvenimentoList?: Array<SportsPointerAvvenimentoDto> | undefined;
  descrizione?: string | undefined;
  descrizioneTrKey?: string | undefined;
  /** @format int32 */
  firstPriority?: number | undefined;
  gruppo?: string | undefined;
  gruppoTrKey?: string | undefined;
  iconSrc?: string | undefined;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idGruppo: number;
  /** @format int32 */
  idManifestazione: number;
  /** @format int32 */
  secondPriority?: number | undefined;
  slug?: string | undefined;
}

export interface SportsPiuGiocateStruttura {
  fase?: string | undefined;
  faseTrKey?: string | undefined;
  tabList?: Array<SportsPiuGiocateTabDto> | undefined;
}

export interface SportsPiuGiocateTabDto {
  contentTabList?: Array<SportsPiuGiocateContentTabDto> | undefined;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idTab: number;
  infoTipoScommessaKeyList?: Array<string> | undefined;
  textTab?: string | undefined;
  textTabTrKey?: string | undefined;
}

export interface SportsPointerAvvenimentoDto {
  /** @format date-time */
  firstPriority: string;
  infoTipoScommessaKeyList?: Array<string> | undefined;
  key?: string | undefined;
  secondPriority?: string | undefined;
  secondPriorityTrKey?: string | undefined;
}

export interface SportsProblemDetails {
  detail?: string | undefined;
  instance?: string | undefined;
  /** @format int32 */
  status?: number | undefined;
  title?: string | undefined;
  type?: string | undefined;
  [key: string]: any;
}

export type SportsProtagonisti = object;

export interface SportsRemoveAvvenimentoSignalREvent {
  /** @minLength 1 */
  avvenimentoKey: string;
  /** @format uuid */
  signalREventId: string;
  /** @minLength 1 */
  slug: string;
  traduzioneMap?: Record<string, Record<string, string>>;
  updateProssimiEventi?: Array<SportsPiuGiocateTabDto> | undefined;
  updateTemplate?: SportsCacheTemplateDisciplinaLiveDto;
  updateTemplatePrematch?: SportsCacheTemplateDisciplinaDto;
}

export interface SportsRemoveDisciplinaSignalREvent {
  /** @format int32 */
  id: number;
  /** @format uuid */
  signalREventId: string;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsRemoveGruppoScommessaSignalREvent {
  /** @minLength 1 */
  key: string;
  /** @format uuid */
  signalREventId: string;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsRemoveManifestazioneSignalREvent {
  /** @format int32 */
  id: number;
  /** @format int32 */
  idDisciplina: number;
  /** @format uuid */
  signalREventId: string;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsRisultatini {
  matchTime?: string | undefined;
  redCards?: Array<string> | undefined;
  score?: SportsLiveScore;
  /** @format int32 */
  server?: number | undefined;
  status?: string | undefined;
  statusTrKey?: string | undefined;
}

export interface SportsScommessaMapDto {
  descrizione?: string | undefined;
  descrizioneAvvenimento?: string | undefined;
  descrizioneAvvenimentoTrKey?: string | undefined;
  descrizioneTrKey?: string | undefined;
  /** @format int32 */
  idAvvenimento: number;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idProgramma: number;
  /** @format int32 */
  idTipoInfoAggiuntiva: number;
  /** @format int32 */
  idTipoScommessa: number;
  infoTipoScommessaKey?: string | undefined;
  key?: string | undefined;
  keyAvvenimento?: string | undefined;
  /** @format int32 */
  maxCombinazioni: number;
  /** @format int32 */
  minCombinazioni: number;
  /** @format int32 */
  multipla: number;
  /** @format int32 */
  priorita: number;
  splitInfoAggiuntiva?: string | undefined;
  tipoAvvenimento: SportsTipoAvvenimento;
  /** @format int32 */
  tipoVisualizzazione: number;
}

export interface SportsScommesseDirettissimeDto {
  direttissime?: Array<SportsDirettissime> | undefined;
  scommesse: SportsCacheScommesseCoreDto;
}

export interface SportsScommesseInEvidenzaDto {
  scommesse?: SportsCacheScommesseCoreDto;
  template?: Array<string> | undefined;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsScommesseProssimiEventiDto {
  scommesse: SportsCacheScommesseCoreDto;
  tabList?: Array<SportsPiuGiocateTabDto> | undefined;
}

export interface SportsScommesseSnaiRunnerDto {
  scommesse: SportsCacheScommesseCoreDto;
  tabList?: Array<SportsPiuGiocateTabDto> | undefined;
}

export interface SportsScommesseTopAntepostDto {
  scommesse: SportsCacheScommesseCoreDto;
  template?: Array<SportsTopAntepostDto> | undefined;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsScommesseTopGiocateDto {
  scommesse?: SportsCacheScommesseCoreDto;
  template?: Array<SportsPiuGiocateStruttura> | undefined;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsSearchResultsAvvenimentoDto {
  dataOra?: string | undefined;
  descrizione?: string | undefined;
  descrizioneTrKey?: string | undefined;
  disciplina?: string | undefined;
  disciplinaTrKey?: string | undefined;
  firstCompetitor?: SportsCompetitor;
  gruppo?: string | undefined;
  gruppoTrKey?: string | undefined;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idGruppo: number;
  /** @format int32 */
  idManifestazione: number;
  isAntepost: boolean;
  key?: string | undefined;
  live: boolean;
  manifestazione?: string | undefined;
  manifestazioneTrKey?: string | undefined;
  secondCompetitor?: SportsCompetitor;
  slug?: string | undefined;
}

export interface SportsSearchResultsDto {
  avvenimenti?: Array<SportsSearchResultsAvvenimentoDto> | undefined;
}

export interface SportsSetScore {
  firstCompetitor?: string | undefined;
  secondCompetitor?: string | undefined;
}

export interface SportsSignalRPacketDto {
  data?: Array<SportsSignalRPacketElementDto> | undefined;
}

export interface SportsSignalRPacketElementDto {
  event?: any;
  message?: string | undefined;
}

export interface SportsSottoVoceMenu {
  prossime12Ore: boolean;
  prossime24Ore: boolean;
  avvenimentoKeyList?: Array<string> | undefined;
  descrizione?: string | undefined;
  descrizioneTrKey?: string | undefined;
  domani: boolean;
  /** @format int32 */
  id: number;
  /** @format int32 */
  idDisciplina: number;
  isCustom?: boolean | undefined;
  isPreferito: boolean;
  oggi: boolean;
  /** @format int32 */
  priorita: number;
  /** @format double */
  quotaMax: number;
  /** @format double */
  quotaMin: number;
  slug?: string | undefined;
}

export interface SportsSquadraItem {
  maglia?: string | undefined;
  name?: string | undefined;
}

export enum SportsStatoAvvenimentoCache {
  TO_LOAD = 'TO_LOAD',
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  TO_DELETE = 'TO_DELETE',
  NOT_IN_CACHE = 'NOT_IN_CACHE',
}

export enum SportsStreamingProvider {
  Img = 'img',
  Perform = 'perform',
  Betradar = 'betradar',
  Betgenius = 'betgenius',
  Perform2 = 'perform_2',
}

export interface SportsStreamingRequestDto {
  betGeniusMatchId?: string | undefined;
  betRadarMatchId?: string | undefined;
  idAvvenimento?: string | undefined;
  idProgramma?: string | undefined;
}

export interface SportsStreamingResponseDto {
  accessToken?: string | undefined;
  dataorarisposta?: string | undefined;
  descrizione?: string | undefined;
  /** @format int32 */
  esito: number;
  type: SportsStreamingProvider;
  url?: string | undefined;
}

export enum SportsTipoAvvenimento {
  Match = 'Match',
  Antepost = 'Antepost',
  Fantasy = 'Fantasy',
  BettingExchangeMatch = 'BettingExchangeMatch',
  Donazione = 'Donazione',
  BettingExchangeAntepost = 'BettingExchangeAntepost',
}

export enum SportsTipoOrdinamentoEsiti {
  QuotaAsc = 'QuotaAsc',
  NumEsito = 'NumEsito',
  Alfabetico = 'Alfabetico',
}

export interface SportsTipoScommessa {
  defaultTipoScommessa: boolean;
  descrizione?: string | undefined;
  descrizioneTrKey?: string | undefined;
  gruppoKey?: string | undefined;
  /** @format int32 */
  idDisciplina: number;
  idTipoScommessa?: string | undefined;
  /** @format int32 */
  idTipoScommessaBase: number;
  idTipoScommessaList?: Array<number> | undefined;
  infoTipoScommessaKeyStringList?: Array<string> | undefined;
  isActive: boolean;
  isNew: boolean;
  isSottoGruppo: boolean;
  key?: string | undefined;
  /** @format int32 */
  priorita: number;
  slug?: string | undefined;
  splitInfoAggiuntiva?: string | undefined;
  tipoScommessaList?: Array<SportsTipoScommessa> | undefined;
}

export interface SportsTopAntepostDto {
  avvKey?: string | undefined;
  /** @minLength 1 */
  disciplina: string;
  disciplinaTrKey?: string | undefined;
  /** @format int32 */
  idDisciplina: number;
  /** @format int32 */
  idManifestazione: number;
  /** @minLength 1 */
  idTab: string;
  /** @format int32 */
  idTipoScommessa: number;
  /** @minLength 1 */
  keyScommessa: string;
  /** @format int32 */
  score: number;
  /** @minLength 1 */
  slug: string;
  /** @minLength 1 */
  textTab: string;
  textTabTrKey?: string | undefined;
}

export interface SportsTopManifestazioniDto {
  template?: Array<SportsCacheTopManifestazioneDto> | undefined;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsTopVincitaDto {
  /** @format int32 */
  importoGiocato: number;
  /** @format int32 */
  importoVinto: number;
}

export interface SportsTopVinciteDto {
  data?: Array<SportsTopVincitaDto> | undefined;
}

export interface SportsTranslateSlugResponseDto {
  slug?: string | undefined;
}

export interface SportsUpdateAvvenimentoSignalREvent {
  /** @minLength 1 */
  avvenimentoKey: string;
  /** @format date-time */
  dataOra: string;
  descrizione?: string | undefined;
  descrizioneTrKey?: string | undefined;
  live?: SportsLiveSection;
  /** @format uuid */
  signalREventId: string;
  slug?: string | undefined;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsUpdateDisciplinaSignalREvent {
  /** @format int32 */
  counter: number;
  /** @format int32 */
  id: number;
  isLive: boolean;
  isPrematch: boolean;
  /** @format int32 */
  priorita: number;
  /** @format uuid */
  signalREventId: string;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsUpdateGruppoScommessaSignalREvent {
  gruppo: SportsGruppoScommessa;
  /** @format uuid */
  signalREventId: string;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsUpdateInfoAggSignalREvent {
  esitoMap: Record<string, SportsEsito>;
  infoAggiuntivaMap: Record<string, SportsInfoAggiuntivaMapUpdateDto>;
  /** @format uuid */
  signalREventId: string;
  slug?: string | undefined;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsUpdateQuoteSignalREvent {
  esiti: Record<string, SportsEsito>;
  /** @format uuid */
  signalREventId: string;
  slug?: string | undefined;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsUpdateRisultatiniSignalREvent {
  /** @minLength 1 */
  key: string;
  live: SportsRisultatini;
  /** @format uuid */
  signalREventId: string;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsUpdateScommesseAvvenimentoSignalREvent {
  allInfoAggAreActive: boolean;
  /** @minLength 1 */
  avvenimentoKey: string;
  /** @format uuid */
  signalREventId: string;
  slug?: string | undefined;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsUpdateStatoInfoAgg {
  infoAggList: Array<string>;
  isActive: boolean;
  /** @minLength 1 */
  scommessaKey: string;
  /** @format uuid */
  signalREventId: string;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsUpdateStatoInfoAggSignalREvent {
  /** @format uuid */
  signalREventId: string;
  /** @minLength 1 */
  slug: string;
  traduzioneMap?: Record<string, Record<string, string>>;
  updateStatoInfoAggMap: Record<string, SportsUpdateStatoInfoAgg>;
}

export interface SportsUpdateTopAntepostSignalREvent {
  avvenimentoEsposto: SportsAvvenimentoEsposto;
  esitoMap: Record<string, SportsEsito>;
  infoAggiuntivaMap: Record<string, SportsInfoAggiuntivaMapDto>;
  infoTipoScommessaMap: Record<string, SportsInfoTipoScommessa>;
  scommessaMap: Record<string, SportsScommessaMapDto>;
  /** @format uuid */
  signalREventId: string;
  topAntepost: SportsTopAntepostDto;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsUpdateTranslationSignalREvent {
  avvenimento: SportsAvvenimentoDto;
  entityType: SportsEntityType;
  /** @format int32 */
  idTipoScommessa?: number | undefined;
  isEtichetta: boolean;
  /** @format uuid */
  signalREventId: string;
  traduzioneMap?: Record<string, Record<string, string>>;
}

export interface SportsVoceMenu {
  descrizione?: string | undefined;
  descrizioneTrKey?: string | undefined;
  iconSrc?: string | undefined;
  /** @format int32 */
  id: number;
  /** @format int32 */
  idDisciplina: number;
  manifestazioni?: Array<SportsSottoVoceMenu> | undefined;
  /** @format int32 */
  priorita: number;
}

export interface SportsVocePrimoLivelloMenuLive {
  /** @format int32 */
  counter: number;
  descrizione?: string | undefined;
  /** @format int32 */
  id: number;
  manifestazioneList?: Array<SportsVoceSecondoLivelloMenuLive> | undefined;
  slug?: string | undefined;
}

export interface SportsVoceSecondoLivelloMenuLive {
  avvenimentoList?: Array<SportsPointerAvvenimentoDto> | undefined;
  /** @format int32 */
  counter: number;
  descrizione?: string | undefined;
  /** @format int32 */
  id: number;
  isPreferito: boolean;
  slug?: string | undefined;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    const result: RequestParams = setHeaders({
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    });
    return result;
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponseType<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    })
      .then(async (response) => {
        const r = response as HttpResponse<T, E>;
        r.data = null as unknown as T;
        r.error = null as unknown as E;

        const data = !responseFormat
          ? r
          : await response[responseFormat]()
              .then((data) => {
                if (r.ok) {
                  r.data = data;
                } else {
                  r.error = data;
                }
                return r;
              })
              .catch((e) => {
                r.error = e;
                return r;
              });

        if (cancelToken) {
          this.abortControllers.delete(cancelToken);
        }

        return checkResponse<T, E>(data);
      })
      .catch((exception) => {
        return handleException<T, E>(exception);
      });
  };
}

/**
 * @title SnaiWebsite.Middleware.Sports
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  live = {
    /**
     * No description
     *
     * @tags Live
     * @name MenuManifestazioniList
     * @request GET:/live/menu-manifestazioni
     */
    menuManifestazioniList: (params: RequestParams = {}) =>
      this.request<SportsCacheMenuManifestazioniLiveDto, SportsProblemDetails>({
        path: `/live/menu-manifestazioni`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Live
     * @name TemplateTopList
     * @request GET:/live/template-top
     */
    templateTopList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsCacheTemplateDisciplinaLiveDto, SportsProblemDetails>({
        path: `/live/template-top`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Live
     * @name TemplateProssimiEventiList
     * @request GET:/live/template-prossimi-eventi
     */
    templateProssimiEventiList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsCacheTemplateDisciplinaLiveDto, SportsProblemDetails>({
        path: `/live/template-prossimi-eventi`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Live
     * @name StreamingCreate
     * @request POST:/live/streaming
     */
    streamingCreate: (data: SportsStreamingRequestDto, params: RequestParams = {}) =>
      this.request<SportsStreamingResponseDto, SportsProblemDetails>({
        path: `/live/streaming`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  menumanifestazionilive = {
    /**
     * No description
     *
     * @tags MenuManifestazioniLive
     * @name GetmenuList
     * @request GET:/menumanifestazionilive/getmenu
     */
    getmenuList: (
      query?: {
        light?: boolean;
        rootSlug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsMenuDisciplineDto, SportsProblemDetails>({
        path: `/menumanifestazionilive/getmenu`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MenuManifestazioniLive
     * @name GetdisciplinadetailsList
     * @request GET:/menumanifestazionilive/getdisciplinadetails
     */
    getdisciplinadetailsList: (
      query?: {
        /** @format int32 */
        idDisciplina?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<SportsManifestazioneMenuItem>, SportsProblemDetails>({
        path: `/menumanifestazionilive/getdisciplinadetails`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MenuManifestazioniLive
     * @name GetmanifestazionedetailsList
     * @request GET:/menumanifestazionilive/getmanifestazionedetails
     */
    getmanifestazionedetailsList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsCacheScommesseCoreDto, SportsProblemDetails>({
        path: `/menumanifestazionilive/getmanifestazionedetails`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
  signalr = {
    /**
     * No description
     *
     * @tags SignalR
     * @name AddavvenimentosignalreventList
     * @request GET:/signalr/addavvenimentosignalrevent
     */
    addavvenimentosignalreventList: (params: RequestParams = {}) =>
      this.request<SportsAddAvvenimentoSignalREvent, any>({
        path: `/signalr/addavvenimentosignalrevent`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name UpdateavvenimentosignalreventList
     * @request GET:/signalr/updateavvenimentosignalrevent
     */
    updateavvenimentosignalreventList: (params: RequestParams = {}) =>
      this.request<SportsUpdateAvvenimentoSignalREvent, any>({
        path: `/signalr/updateavvenimentosignalrevent`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name RemoveavvenimentosignalreventList
     * @request GET:/signalr/removeavvenimentosignalrevent
     */
    removeavvenimentosignalreventList: (params: RequestParams = {}) =>
      this.request<SportsRemoveAvvenimentoSignalREvent, any>({
        path: `/signalr/removeavvenimentosignalrevent`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name AddscommessasignalreventList
     * @request GET:/signalr/addscommessasignalrevent
     */
    addscommessasignalreventList: (params: RequestParams = {}) =>
      this.request<SportsAddScommessaSignalREvent, any>({
        path: `/signalr/addscommessasignalrevent`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name UpdateinfoaggsignalreventList
     * @request GET:/signalr/updateinfoaggsignalrevent
     */
    updateinfoaggsignalreventList: (params: RequestParams = {}) =>
      this.request<SportsUpdateInfoAggSignalREvent, any>({
        path: `/signalr/updateinfoaggsignalrevent`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name UpdatestatoinfoaggsignalreventList
     * @request GET:/signalr/updatestatoinfoaggsignalrevent
     */
    updatestatoinfoaggsignalreventList: (params: RequestParams = {}) =>
      this.request<SportsUpdateStatoInfoAggSignalREvent, any>({
        path: `/signalr/updatestatoinfoaggsignalrevent`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name UpdatescommesseavvenimentosignalreventList
     * @request GET:/signalr/updatescommesseavvenimentosignalrevent
     */
    updatescommesseavvenimentosignalreventList: (params: RequestParams = {}) =>
      this.request<SportsUpdateScommesseAvvenimentoSignalREvent, any>({
        path: `/signalr/updatescommesseavvenimentosignalrevent`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name UpdatequotesignalreventList
     * @request GET:/signalr/updatequotesignalrevent
     */
    updatequotesignalreventList: (params: RequestParams = {}) =>
      this.request<SportsUpdateQuoteSignalREvent, any>({
        path: `/signalr/updatequotesignalrevent`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name UpdaterisultatinisignalreventList
     * @request GET:/signalr/updaterisultatinisignalrevent
     */
    updaterisultatinisignalreventList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsUpdateRisultatiniSignalREvent, any>({
        path: `/signalr/updaterisultatinisignalrevent`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name UpdatetopantepostsignalreventList
     * @request GET:/signalr/updatetopantepostsignalrevent
     */
    updatetopantepostsignalreventList: (params: RequestParams = {}) =>
      this.request<SportsUpdateTopAntepostSignalREvent, any>({
        path: `/signalr/updatetopantepostsignalrevent`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name MenumanifestazioneliveAdddisciplinasignalreventList
     * @request GET:/signalr/menumanifestazionelive-adddisciplinasignalrevent
     */
    menumanifestazioneliveAdddisciplinasignalreventList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsAddDisciplinaSignalREvent, any>({
        path: `/signalr/menumanifestazionelive-adddisciplinasignalrevent`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name MenumanifestazioneliveUpdatedisciplinasignalreventList
     * @request GET:/signalr/menumanifestazionelive-updatedisciplinasignalrevent
     */
    menumanifestazioneliveUpdatedisciplinasignalreventList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsUpdateDisciplinaSignalREvent, any>({
        path: `/signalr/menumanifestazionelive-updatedisciplinasignalrevent`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name MenumanifestazioneliveRemovedisciplinasignalreventList
     * @request GET:/signalr/menumanifestazionelive-removedisciplinasignalrevent
     */
    menumanifestazioneliveRemovedisciplinasignalreventList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsRemoveDisciplinaSignalREvent, any>({
        path: `/signalr/menumanifestazionelive-removedisciplinasignalrevent`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name MenumanifestazioneliveAddmanifestazionesignalreventList
     * @request GET:/signalr/menumanifestazionelive-addmanifestazionesignalrevent
     */
    menumanifestazioneliveAddmanifestazionesignalreventList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsAddManifestazioneSignalREvent, any>({
        path: `/signalr/menumanifestazionelive-addmanifestazionesignalrevent`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name MenumanifestazioneliveRemovemanifestazionesignalreventList
     * @request GET:/signalr/menumanifestazionelive-removemanifestazionesignalrevent
     */
    menumanifestazioneliveRemovemanifestazionesignalreventList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsRemoveManifestazioneSignalREvent, any>({
        path: `/signalr/menumanifestazionelive-removemanifestazionesignalrevent`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name UpdategrupposcommessasignalreventList
     * @request GET:/signalr/updategrupposcommessasignalrevent
     */
    updategrupposcommessasignalreventList: (params: RequestParams = {}) =>
      this.request<SportsUpdateGruppoScommessaSignalREvent, any>({
        path: `/signalr/updategrupposcommessasignalrevent`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name RemovegrupposcommessasignalreventList
     * @request GET:/signalr/removegrupposcommessasignalrevent
     */
    removegrupposcommessasignalreventList: (params: RequestParams = {}) =>
      this.request<SportsRemoveGruppoScommessaSignalREvent, any>({
        path: `/signalr/removegrupposcommessasignalrevent`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name UpdatetranslationsignalreventList
     * @request GET:/signalr/updatetranslationsignalrevent
     */
    updatetranslationsignalreventList: (params: RequestParams = {}) =>
      this.request<SportsUpdateTranslationSignalREvent, any>({
        path: `/signalr/updatetranslationsignalrevent`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SignalR
     * @name SignalrpacketdtoList
     * @request GET:/signalr/signalrpacketdto
     */
    signalrpacketdtoList: (params: RequestParams = {}) =>
      this.request<SportsSignalRPacketDto, any>({
        path: `/signalr/signalrpacketdto`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  sports = {
    /**
     * No description
     *
     * @tags Sports
     * @name GetInitExposedCacheList
     * @request GET:/sports/get-init-exposed-cache
     */
    getInitExposedCacheList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/sports/get-init-exposed-cache`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name CountLoadedAvvListList
     * @request GET:/sports/count-loaded-avv-list
     */
    countLoadedAvvListList: (params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/sports/count-loaded-avv-list`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name MenuManifestazioniList
     * @request GET:/sports/menu-manifestazioni
     */
    menuManifestazioniList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsCacheMenuManifestazioniSportDto, SportsProblemDetails>({
        path: `/sports/menu-manifestazioni`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name TemplateDisciplinaList
     * @request GET:/sports/template-disciplina
     */
    templateDisciplinaList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsCacheTemplateDisciplinaDto, SportsProblemDetails>({
        path: `/sports/template-disciplina`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name TemplateManifestazioneList
     * @request GET:/sports/template-manifestazione
     */
    templateManifestazioneList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsCacheTemplateManifestazioneDto, SportsProblemDetails>({
        path: `/sports/template-manifestazione`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name TemplateManifestazioneMarcatoriList
     * @request GET:/sports/template-manifestazione-marcatori
     */
    templateManifestazioneMarcatoriList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsCacheTemplateManifestazioneMarcatoriDto, any>({
        path: `/sports/template-manifestazione-marcatori`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name TemplateCustomSectionList
     * @request GET:/sports/template-custom-section
     */
    templateCustomSectionList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsCacheTemplateManifestazioneCustomSectionDto, any>({
        path: `/sports/template-custom-section`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name TemplateAvvenimentoList
     * @request GET:/sports/template-avvenimento
     */
    templateAvvenimentoList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsCacheTemplateAvvenimentoDto, SportsProblemDetails>({
        path: `/sports/template-avvenimento`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name TemplateAvvenimentoMarcatoriList
     * @request GET:/sports/template-avvenimento-marcatori
     */
    templateAvvenimentoMarcatoriList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsCacheTemplateAvvenimentoMarcatoreDto, any>({
        path: `/sports/template-avvenimento-marcatori`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name GetAvvenimentoList
     * @request GET:/sports/get-avvenimento
     */
    getAvvenimentoList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsAvvenimentoEsposto, SportsProblemDetails>({
        path: `/sports/get-avvenimento`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name ScommesseList
     * @request GET:/sports/scommesse
     */
    scommesseList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsCacheScommesseCoreDto, SportsProblemDetails>({
        path: `/sports/scommesse`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name TopGiocateList
     * @request GET:/sports/top-giocate
     */
    topGiocateList: (
      query?: {
        light?: boolean;
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsScommesseTopGiocateDto, SportsProblemDetails>({
        path: `/sports/top-giocate`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name ScommesseMarcatoriList
     * @request GET:/sports/scommesse-marcatori
     */
    scommesseMarcatoriList: (
      query?: {
        slug?: string;
        slugGiocatore?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsCacheScommesseMarcatoriDto, any>({
        path: `/sports/scommesse-marcatori`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name ScommesseCustomSectionList
     * @request GET:/sports/scommesse-custom-section
     */
    scommesseCustomSectionList: (
      query?: {
        /** @format int32 */
        page?: number;
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsCacheScommesseCoreDto, any>({
        path: `/sports/scommesse-custom-section`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name DirettissimeList
     * @request GET:/sports/direttissime
     */
    direttissimeList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsScommesseDirettissimeDto, SportsProblemDetails>({
        path: `/sports/direttissime`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name ProssimiEventiList
     * @request GET:/sports/prossimi-eventi
     */
    prossimiEventiList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsScommesseProssimiEventiDto, SportsProblemDetails>({
        path: `/sports/prossimi-eventi`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name InEvidenzaList
     * @request GET:/sports/in-evidenza
     */
    inEvidenzaList: (
      query?: {
        light?: boolean;
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsScommesseInEvidenzaDto, SportsProblemDetails>({
        path: `/sports/in-evidenza`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name TopAntepostList
     * @request GET:/sports/top-antepost
     */
    topAntepostList: (
      query?: {
        light?: boolean;
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsScommesseTopAntepostDto, SportsProblemDetails>({
        path: `/sports/top-antepost`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name TopManifestazioniList
     * @request GET:/sports/top-manifestazioni
     */
    topManifestazioniList: (
      query?: {
        light?: boolean;
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsTopManifestazioniDto, SportsProblemDetails>({
        path: `/sports/top-manifestazioni`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name SnaiRunnerList
     * @request GET:/sports/snai-runner
     */
    snaiRunnerList: (params: RequestParams = {}) =>
      this.request<SportsScommesseSnaiRunnerDto, SportsProblemDetails>({
        path: `/sports/snai-runner`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name SearchTipiScommessaList
     * @request GET:/sports/search-tipi-scommessa
     */
    searchTipiScommessaList: (
      query?: {
        slug?: string;
        word?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<SportsTipoScommessa>, any>({
        path: `/sports/search-tipi-scommessa`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name SearchList
     * @request GET:/sports/search
     */
    searchList: (
      query?: {
        text?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsSearchResultsDto, any>({
        path: `/sports/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name CartUpdateCreate
     * @request POST:/sports/cart-update
     */
    cartUpdateCreate: (data: Record<string, boolean>, params: RequestParams = {}) =>
      this.request<SportsCartUpdate, any>({
        path: `/sports/cart-update`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name GuardaSuSnaiList
     * @request GET:/sports/guarda-su-snai
     */
    guardaSuSnaiList: (params: RequestParams = {}) =>
      this.request<Array<SportsAvvenimentoEsposto>, any>({
        path: `/sports/guarda-su-snai`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name GuardaSuSnaiV2List
     * @request GET:/sports/guarda-su-snai-v2
     */
    guardaSuSnaiV2List: (params: RequestParams = {}) =>
      this.request<SportsGuardaSuSnaiDto, any>({
        path: `/sports/guarda-su-snai-v2`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name ProtagonistiList
     * @request GET:/sports/protagonisti
     */
    protagonistiList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<SportsProtagonisti>, any>({
        path: `/sports/protagonisti`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name NewsList
     * @request GET:/sports/news
     */
    newsList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<string, any>({
        path: `/sports/news`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name TopVinciteList
     * @request GET:/sports/top-vincite
     */
    topVinciteList: (params: RequestParams = {}) =>
      this.request<SportsTopVinciteDto, any>({
        path: `/sports/top-vincite`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name SquadreList
     * @request GET:/sports/squadre
     */
    squadreList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<SportsSquadraItem>, any>({
        path: `/sports/squadre`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name TranslateSlugList
     * @request GET:/sports/translate-slug
     */
    translateSlugList: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsTranslateSlugResponseDto, any>({
        path: `/sports/translate-slug`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sports
     * @name StatisticheMarcatoriList
     * @request GET:/sports/statistiche-marcatori
     */
    statisticheMarcatoriList: (
      query?: {
        manifKey?: string;
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SportsBetRadarGiocatoreDto, any>({
        path: `/sports/statistiche-marcatori`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
  translations = {
    /**
     * No description
     *
     * @tags Translations
     * @name UpdateSportsCreate
     * @request POST:/translations/update-sports
     */
    updateSportsCreate: (data: Array<SportsDizSportDto>, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/translations/update-sports`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translations
     * @name UpdateScommesseModelliCreate
     * @request POST:/translations/update-scommesse-modelli
     */
    updateScommesseModelliCreate: (data: Array<SportsDizScommesseModelliDto>, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/translations/update-scommesse-modelli`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translations
     * @name UpdateAvvenimentiCreate
     * @request POST:/translations/update-avvenimenti
     */
    updateAvvenimentiCreate: (data: Array<SportsDizAvvDto>, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/translations/update-avvenimenti`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translations
     * @name UpdateManifestazioniCreate
     * @request POST:/translations/update-manifestazioni
     */
    updateManifestazioniCreate: (data: Array<SportsDizManifDto>, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/translations/update-manifestazioni`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translations
     * @name UpdateGruppiScommessaCreate
     * @request POST:/translations/update-gruppi-scommessa
     */
    updateGruppiScommessaCreate: (data: Array<SportsDizGruppiScommesseDto>, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/translations/update-gruppi-scommessa`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translations
     * @name UpdateEsitiCreate
     * @request POST:/translations/update-esiti
     */
    updateEsitiCreate: (data: Array<SportsDizEsitiDto>, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/translations/update-esiti`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translations
     * @name UpdateGruppiManifestazioneCreate
     * @request POST:/translations/update-gruppi-manifestazione
     */
    updateGruppiManifestazioneCreate: (data: Array<SportsDizGruppiManifDto>, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/translations/update-gruppi-manifestazione`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translations
     * @name UpdateScommesseCreate
     * @request POST:/translations/update-scommesse
     */
    updateScommesseCreate: (data: Array<SportsDizScommesseDto>, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/translations/update-scommesse`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translations
     * @name UpdateFiltroOffertaByDiscCreate
     * @request POST:/translations/update-filtro-offerta-by-disc
     */
    updateFiltroOffertaByDiscCreate: (data: Array<SportsFiltroOffertaCountryByDiscDto>, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/translations/update-filtro-offerta-by-disc`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translations
     * @name UpdateFiltroOffertaByAvvCreate
     * @request POST:/translations/update-filtro-offerta-by-avv
     */
    updateFiltroOffertaByAvvCreate: (data: Array<SportsFiltroOffertaCountryByAvvDto>, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/translations/update-filtro-offerta-by-avv`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translations
     * @name UpdateEtichetteApplicativoCreate
     * @request POST:/translations/update-etichette-applicativo
     */
    updateEtichetteApplicativoCreate: (data: Array<SportsDizEtichetteApplicativoDto>, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/translations/update-etichette-applicativo`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translations
     * @name UpdateScommesseAnagraficheCreate
     * @request POST:/translations/update-scommesse-anagrafiche
     */
    updateScommesseAnagraficheCreate: (data: Array<SportsDizScommesseAnagraficheDto>, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/translations/update-scommesse-anagrafiche`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Translations
     * @name GetTraduzioneCreate
     * @request POST:/translations/get-traduzione
     */
    getTraduzioneCreate: (data: Array<SportsCacheTranslationKeyDto>, params: RequestParams = {}) =>
      this.request<Record<string, Record<string, string>>, any>({
        path: `/translations/get-traduzione`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
