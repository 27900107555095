/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-98.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina98 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-98', className].join(' ');
  return (
    <svg
      id="disciplina_cricket"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M20.637 5.123a1.247 1.247 0 0 0 0-1.76 1.246 1.246 0 0 0-1.76 0l-3.854 3.854a.708.708 0 0 1-1.005 0l-.028-.028a.326.326 0 0 0-.466 0l-6.06 6.06a2.164 2.164 0 0 0-1.536-.483 2.206 2.206 0 0 0-2.03 2.03 2.163 2.163 0 0 0 .484 1.535l-1.284 1.294a.326.326 0 0 0 0 .466l2.811 2.811c.13.13.345.13.466 0L16.8 10.476a.326.326 0 0 0 0-.466l-.009-.01a.678.678 0 0 1-.214-.437.729.729 0 0 1 .205-.577l3.854-3.863ZM12.426 9.34l2.243 2.243-.344.326-2.234-2.234.335-.335ZM6.086 13.5c.8 0 1.452.652 1.452 1.453 0 .8-.651 1.452-1.452 1.452-.8 0-1.452-.652-1.452-1.452 0-.801.651-1.453 1.452-1.453Zm-2.188 4.357 1.043-1.043a2.15 2.15 0 0 0 1.136.317c1.21 0 2.188-.978 2.188-2.188 0-.419-.112-.8-.317-1.136l3.621-3.621 2.244 2.244-7.671 7.67-2.244-2.243Zm12.354-9.403c-.27.27-.419.633-.419 1.024 0 .261.065.512.196.727l-.848.847-2.243-2.244.847-.847c.55.326 1.275.242 1.75-.233l3.854-3.854a.514.514 0 0 1 .727 0 .511.511 0 0 1 0 .717l-3.864 3.863Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina98;
