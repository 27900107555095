/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\snai-picto.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const SnaiPicto = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-snai-picto', className].join(' ');
  return (
    <svg
      id="snai-picto"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34 30"
      fill="none"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M13.2425 0.0580918C11.3449 0.228876 9.49854 0.784636 7.80877 1.69362C6.11899 2.6026 4.61894 3.84699 3.39431 5.3557C2.16968 6.86441 1.24447 8.60788 0.671531 10.4865C0.098595 12.3651 -0.11084 14.3421 0.0551973 16.3044C0.220328 18.267 0.757732 20.1766 1.63671 21.9243C2.5157 23.6719 3.71903 25.2234 5.17795 26.4899C6.63687 27.7564 8.32279 28.7133 10.1394 29.3057C11.956 29.8982 13.8677 30.1147 15.7652 29.9428C19.596 29.5954 23.1364 27.6885 25.6079 24.6416C28.0793 21.5947 29.2793 17.6573 28.9439 13.6954C28.779 11.7331 28.2419 9.82374 27.3633 8.07625C26.4847 6.32876 25.2817 4.77742 23.8232 3.51088C22.3647 2.24435 20.6792 1.28743 18.863 0.694793C17.0467 0.10216 15.1354 -0.114573 13.2382 0.0569819L13.2425 0.0580918ZM15.4639 26.388C12.5431 26.6515 9.64073 25.7048 7.39465 23.756C5.14857 21.8071 3.74268 19.0156 3.48603 15.9951C3.35913 14.4991 3.51845 12.992 3.95489 11.5598C4.39133 10.1275 5.09635 8.79826 6.02965 7.64789C6.96296 6.49752 8.10626 5.54859 9.39426 4.85531C10.6823 4.16203 12.0897 3.73799 13.5362 3.6074C14.9825 3.47647 16.4395 3.64147 17.8242 4.09298C19.2088 4.5445 20.4938 5.27368 21.6059 6.23888C22.718 7.20409 23.6353 8.38641 24.3055 9.71832C24.9757 11.0502 25.3857 12.5056 25.512 14.0014C25.7677 17.022 24.8527 20.0239 22.9684 22.3468C21.084 24.6698 18.3846 26.1234 15.4639 26.388Z"
        fill="currentColor"
      />
      <path
        d="M28.6945 7.71315C30.0529 8.60796 30.8774 9.70901 30.9792 10.9431C31.3105 14.8517 24.3524 18.6782 15.4397 19.481C11.9746 19.7926 8.72279 19.6041 6.02637 19.0286C9.02834 20.4213 13.5881 21.081 18.6186 20.6253C27.2504 19.8491 33.9769 16.0791 33.6564 12.2227C33.4902 10.3011 31.6096 8.71774 28.6945 7.71094"
        fill="currentColor"
      />
    </svg>
  );
};
export default SnaiPicto;
