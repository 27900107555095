/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\meteo-variabile.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MeteoVariabile = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-meteo-variabile', className].join(' ');
  return (
    <svg
      id="meteo-variabile"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M10.2077 9.48464C9.90213 9.92908 9.49935 10.2569 8.99935 10.468L9.00828 10.5036C9.22455 10.8785 9.33268 11.3 9.33268 11.768C10.1327 11.4791 10.7771 10.993 11.266 10.3096C11.7549 9.6263 11.9993 8.85686 11.9993 8.0013C11.9993 6.89019 11.6105 5.94575 10.8327 5.16797C10.0549 4.39019 9.11046 4.0013 7.99935 4.0013C7.01046 4.0013 6.13546 4.31241 5.37435 4.93464C4.61324 5.55686 4.1549 6.35686 3.99935 7.33464C4.48654 7.33464 4.94258 7.43106 5.36746 7.62391C5.45807 6.96207 5.75203 6.41564 6.24935 5.98464C6.74935 5.5513 7.33268 5.33464 7.99935 5.33464C8.73268 5.33464 9.36046 5.59575 9.88268 6.11797C10.4049 6.64019 10.666 7.26797 10.666 8.0013C10.666 8.54575 10.5132 9.04019 10.2077 9.48464Z"
        fill="#DEA410"
      />
      <path d="M4.23268 5.16797L2.34935 3.28464L3.29935 2.33464L5.16602 4.23464L4.23268 5.16797Z" fill="#DEA410" />
      <path d="M7.33268 3.33464V0.667969H8.66602V3.33464H7.33268Z" fill="#DEA410" />
      <path d="M11.766 5.16797L10.8327 4.23464L12.716 2.33464L13.6493 3.28464L11.766 5.16797Z" fill="#DEA410" />
      <path d="M12.666 8.66797V7.33464H15.3327V8.66797H12.666Z" fill="#DEA410" />
      <path d="M12.716 13.6513L10.8327 11.768L11.766 10.8346L13.666 12.7013L12.716 13.6513Z" fill="#DEA410" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.36746 7.62391C4.94258 7.43106 4.48654 7.33464 3.99935 7.33464C4.22157 7.39019 4.44935 7.44019 4.68268 7.48464C4.91602 7.52908 5.14379 7.57908 5.36602 7.63464L5.36746 7.62391ZM9.33268 11.768C9.33268 11.3 9.22455 10.8785 9.00828 10.5036C9.06086 10.7134 9.11344 10.9182 9.16602 11.118C9.22157 11.3291 9.27713 11.5457 9.33268 11.768Z"
        fill="#DEA410"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.64102 13.0263C2.29102 13.6763 3.07713 14.0013 3.99935 14.0013H6.99935C7.63268 14.0013 8.17435 13.7874 8.62435 13.3596C9.07435 12.9319 9.31046 12.4013 9.33268 11.768C9.27713 11.5457 9.22157 11.3291 9.16602 11.118C9.11344 10.9182 9.06086 10.7134 9.00828 10.5036C8.91646 10.3445 8.80515 10.1937 8.67435 10.0513C8.23546 9.57353 7.69379 9.33464 7.04935 9.33464C6.78268 8.72353 6.37435 8.23741 5.82435 7.8763C5.67628 7.77909 5.52399 7.69496 5.36746 7.62391L5.36602 7.63464C5.14379 7.57908 4.91602 7.52908 4.68268 7.48464C4.44935 7.44019 4.22157 7.39019 3.99935 7.33464C3.07713 7.33464 2.29102 7.65964 1.64102 8.30964C0.991016 8.95964 0.666016 9.74575 0.666016 10.668C0.666016 11.5902 0.991016 12.3763 1.64102 13.0263ZM6.99935 12.668H3.99935C3.44379 12.668 2.97157 12.4735 2.58268 12.0846C2.19379 11.6957 1.99935 11.2235 1.99935 10.668C1.99935 10.1124 2.19379 9.64019 2.58268 9.2513C2.97157 8.86241 3.44379 8.66797 3.99935 8.66797C4.39935 8.66797 4.76602 8.7763 5.09935 8.99297C5.43268 9.20964 5.67713 9.5013 5.83268 9.86797L6.16602 10.668H7.01602C7.29379 10.668 7.52713 10.7652 7.71602 10.9596C7.9049 11.1541 7.99935 11.3902 7.99935 11.668C7.99935 11.9457 7.90213 12.1819 7.70768 12.3763C7.51324 12.5707 7.27713 12.668 6.99935 12.668Z"
        fill="#757575"
      />
    </svg>
  );
};
export default MeteoVariabile;
