/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-1.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina1 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-1', className].join(' ');
  return (
    <svg
      id="disciplina_calcio"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M17.402 4.805c-.893-.685-2-1.23-3.226-1.536A8.997 8.997 0 0 0 3.269 9.824a8.998 8.998 0 0 0 3.276 9.335l.053.036c.893.685 2 1.23 3.226 1.535a8.997 8.997 0 0 0 10.907-6.555 8.999 8.999 0 0 0-3.276-9.335l-.053-.035Zm.007 13.859c-.02-.317-.07-.96-.12-1.462a8.322 8.322 0 0 0 1.807-2.918l.01-.035a6.54 6.54 0 0 0 .205-.678c.066-.265.118-.534.154-.805l-.007.032c.636-.454.939-.742 1.121-.995a8.619 8.619 0 0 1-1.74 5.381l.036-.05c-.322.442-.69.846-1.1 1.206l.02-.02c-.077.066-.157.126-.238.19-.035.03-.151.105-.148.154Zm1.505-6.053a6.078 6.078 0 0 1-.155.823c-.056.218-.12.432-.194.64l.011-.029a7.47 7.47 0 0 1-.858 1.709l.029-.046c-.594.907-1.195 1.416-1.203 1.42 0 0-1.465.643-2.9.499l-2.319-3.722 1.582-3.41 4.446-.477 1.56 2.593Zm-6.904 7.43a8.864 8.864 0 0 0 1.55.398 8.458 8.458 0 0 1-3.634-.116 8.81 8.81 0 0 1-1.195-.39l.042.017c-.087-.067-.193-.154-.32-.26a8.163 8.163 0 0 0 1.888.394l-.046-.004a7.54 7.54 0 0 0 1.768-.042l-.053.003ZM5.85 16.33l1.655-2.6 3.363.516 2.278 3.652c-.246.41-.745 1.131-1.406 1.61-.445.05-.893.055-1.34.017l-.038-.004a7.18 7.18 0 0 1-1.135-.193 8.154 8.154 0 0 1-.707-.21l.029.01a7.79 7.79 0 0 1-1.294-.584l.05.025c-.549-.619-1.104-1.385-1.456-2.239ZM4.7 8.749l1.771.928.608 3.665-1.673 2.63c-.32-.078-1.174-.38-1.191-.377a8.52 8.52 0 0 1-.784-4.042l.004-.056c.186-.643.681-2.218 1.254-2.745l.01-.003Zm4.506-4.861c.429.144 1.132.387 1.61.559L9.764 7.462 6.76 9.188l-1.775-.924c-.025-.01.288-.738.312-.798.327-.76.71-1.61 1.347-2.158.678-.584 1.486-1.009 2.323-1.325.077-.035.158-.063.239-.095Zm5.898.499a6.941 6.941 0 0 1 1.616 1.452l-.014-.018c.37.456.688.952.946 1.48l.007.014-.394 2.147-4.457.482-2.481-2.376 1.047-3.002c.025-.007.05-.01.07-.017.412-.148 1.565-.436 3.66-.162Zm1.223.2a8.663 8.663 0 0 1 3.497 3.884l.007.021.063.148.021.045c-.594-.882-1.262-1.318-1.701-1.528a8.175 8.175 0 0 0-1.04-1.67l-.018-.017a7.159 7.159 0 0 0-.815-.858l-.036-.028h.004c.007.003.014.007.018.003Z"
      />
    </svg>
  );
};
export default Disciplina1;
