/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-17.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina17 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-17', className].join(' ');
  return (
    <svg
      id="disciplina_pallamano"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M18.363 5.635A9 9 0 1 0 21 12a8.93 8.93 0 0 0-2.637-6.364Zm2.053 6.378-1.1-.798-.89-2.799.426-1.304a8.35 8.35 0 0 1 1.564 4.887v.014ZM19.295 16.2h-1.702l-.896-2.76 2.348-1.705 1.343.974a8.345 8.345 0 0 1-1.093 3.49Zm-4.637 2.035-2.366-1.719V15.09l2.485-1.807 1.36.323.904 2.785-2.383 1.845Zm-5.32 0-2.372-1.87.9-2.767 1.346-.327 2.496 1.814v1.428l-2.37 1.722Zm-5.726-5.523 1.343-.973 2.348 1.704-.896 2.76H4.705a8.377 8.377 0 0 1-1.093-3.49Zm2.471-4.015h2.981l.943 1.2-.91 2.801-1.365.334-2.45-1.779.801-2.556Zm4.36-3.17h3.118l.91 2.801-.931 1.203h-3.076l-.939-1.189.918-2.816Zm7.463 3.17.812 2.556-2.454 1.783-1.357-.324-.917-2.82.924-1.195h2.992ZM12 14.58l-2.348-1.705.896-2.76h2.9l.897 2.76L12 14.579Zm6.42-8.023-.507 1.558h-2.9l-.897-2.76 1.393-1.012a8.472 8.472 0 0 1 2.443 1.701c.165.169.32.338.467.513Zm-3.544-2.471-1.181.858h-3.386l-1.181-.858a8.466 8.466 0 0 1 5.748 0ZM6.048 6.047a8.427 8.427 0 0 1 2.443-1.702l1.393 1.013-.897 2.76h-2.9L5.58 6.56c.148-.18.303-.348.468-.513Zm-.9 1.065.418 1.29-.882 2.816-1.097.798v-.014a8.317 8.317 0 0 1 1.561-4.89Zm-.074 9.671h1.48l2.493 1.966.415 1.28a8.376 8.376 0 0 1-3.414-2.078 8.284 8.284 0 0 1-.974-1.168Zm5.06 3.425-.482-1.48L12 17.026l2.348 1.705-.481 1.48a8.435 8.435 0 0 1-3.734-.003Zm7.818-2.257a8.367 8.367 0 0 1-3.414 2.077l.419-1.283 2.527-1.958h1.442c-.29.416-.615.806-.974 1.164Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina17;
