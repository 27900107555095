/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-399.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco399 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-399', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="38.439"
      viewBox="0 0 41.396 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M228.7,400.177a11.088,11.088,0,1,0-11.088-11.088A11.1,11.1,0,0,0,228.7,400.177Zm0-20.7a9.61,9.61,0,1,1-9.61,9.61A9.621,9.621,0,0,1,228.7,379.479Zm3.7,8.871a.739.739,0,0,0,.739-.739v-2.218a.739.739,0,0,0-1.478,0v2.218A.739.739,0,0,0,232.394,388.349Zm-7.392,0a.739.739,0,0,0,.739-.739v-2.218a.739.739,0,0,0-1.478,0v2.218A.739.739,0,0,0,225,388.349Zm-.531,3.922a.739.739,0,0,0,.018,1.045,6.112,6.112,0,0,0,8.42,0,.74.74,0,0,0-1.028-1.065,4.619,4.619,0,0,1-6.365,0A.74.74,0,0,0,224.47,392.271Zm18.273,11.6v-2.218a.739.739,0,1,0-1.478,0v2.218a.739.739,0,0,0,1.478,0Zm-8.131.739a.739.739,0,0,0,.739-.739v-2.218a.739.739,0,1,0-1.478,0v2.218A.739.739,0,0,0,234.612,404.612Zm-11.088-2.957a.739.739,0,1,0-1.478,0v2.218a.739.739,0,0,0,1.478,0Zm-7.392,2.218v-2.218a.739.739,0,1,0-1.478,0v2.218a.739.739,0,0,0,1.478,0Zm-.225,6.446a4.619,4.619,0,0,1,6.365,0,.74.74,0,0,0,1.028-1.065,6.114,6.114,0,0,0-8.42,0,.74.74,0,0,0,1.028,1.065Zm17.967-1.271a.739.739,0,0,0,.739.739H242a.739.739,0,1,0,0-1.478h-7.392A.739.739,0,0,0,233.873,409.047Zm6.774-14.532a.739.739,0,1,0-.31,1.445,9.607,9.607,0,1,1-9.265,15.7.739.739,0,0,0-1.114.972,11.083,11.083,0,1,0,10.69-18.116Zm-10.94,7.652a.739.739,0,0,0-1.416.425,9.609,9.609,0,1,1-11.231-6.632.739.739,0,1,0-.31-1.445,11.093,11.093,0,1,0,12.958,7.652Z"
        transform="translate(-208 -378)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco399;
