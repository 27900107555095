/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\arrow-decrease-light.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const ArrowDecreaseLight = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-arrow-decrease-light', className].join(' ');
  return (
    <svg
      id="arrow-decrease-light"
      width="10"
      height="8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M4.783 7.125 5 7.5l.217-.375 3.464-6L8.897.75H1.103l.216.375 3.464 6Z"
        fill="#D61818"
        stroke="#FCFCFC"
        strokeWidth=".5"
      />
    </svg>
  );
};
export default ArrowDecreaseLight;
