/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\chewron-up.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const ChewronUp = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-chewron-up', className].join(' ');
  return (
    <svg id="chewron-up" viewBox="0 0 12 8" focusable="false" className={componentClassName} {...attribs}>
      <path
        d="M6 2.4271e-05L12 6.00002L10.6 7.40002L6 2.80002L1.4 7.40002L1.22392e-07 6.00002L6 2.4271e-05Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default ChewronUp;
