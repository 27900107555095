/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-lb.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneLb = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-lb', className].join(' ');
  return (
    <svg
      id="nazione-lb"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <mask id="a" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="-1" y="0" width="17" height="16">
        <path d="M0 0h15.997v15.998H-.001V0Z" fill="#fff" />
      </mask>
      <g mask="url(#a)">
        <g fillRule="evenodd" clipRule="evenodd">
          <path d="M-4 11.998h23.996v4H-4v-4ZM-4 0h23.996v4H-4V0Z" fill="red" />
          <path d="M-4 4h23.996v7.998H-4V4Z" fill="#fff" />
        </g>
        <path
          d="M7.879 4.06c-.245.488-.41.488-.816.813-.161.162-.407.242-.08.407-.323.161-.488.242-.652.567l.083.081s.307-.151.323-.08c.055.064-.406.312-.464.354-.058.042-.349.213-.349.213-.406.326-.651.245-.893.732l.813-.08c.16.567-.407.648-.813.893l-.652.406c.162.568.652.242 1.058.081l.08.08v.162l-.809.406s-.96.549-.977.568c-.006.032 0 .161 0 .161.326.084.813.165 1.139 0 .406-.161.49-.322.977-.322a3.141 3.141 0 0 1-1.629.651v.323c.49 0 .816 0 1.222-.08l1.058-.323c.242 0 .487.242.407.483-.245.894-1.22.733-1.548 1.465l1.303-.487c.325-.162.651-.326 1.058-.245.487.16.487.487 1.138.648l-.161-.403c.161.08.322.08.487.161.406.161.487.322.977.242-.406-.484-.49-.403-.813-.729-.325-.487-.49-1.22 0-1.3l.568.161c.57.084.57-.08 1.384.246.487.16.651.406 1.219.241-.242-.567-1.139-.974-1.71-1.135.652-.406.49.161 1.387-.08v-.162c-.651-.49-.896-.896-1.793-.896l1.387-.162v-.16s-1.37-.359-1.403-.372a.204.204 0 0 1 .132-.142c.258.168 1.042.149 1.087.145-.023-.2-.393-.364-.72-.525 0 0-1.393-.862-1.399-.936.026-.219.57.033 1.155.204-.162-.323-.487-.488-.813-.568l.487-.08c-.323-.733-1.139-.652-1.626-.978-.325-.242-.325-.406-.812-.648h.003Z"
          fill="#007900"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.998 9.47c.062-.194.139-.365-.222-.53-.361-.164.18.662.226.53h-.004Zm.43-.388c-.075.01-.11.278.032.349.164.026.032-.345-.033-.349Zm.422-.029c-.074.023-.078.4.187.333.268-.065 0-.362-.187-.33v-.003Zm.493-.96c.058-.091-.003-.468-.232-.31-.229.16.161.335.232.31Zm-.5-.314c.071-.025.078-.258-.122-.193-.2.058.07.242.122.193Zm-.451.094s-.139-.194-.249-.155c-.135.13.258.158.249.155Zm-1.548.616c.058.006.5-.07.651-.242.155-.17-.784.071-.784.074 0 .004.088.152.13.168h.003Zm4.409-1.09c.022-.042-.236-.226-.387-.152-.04.136.387.178.387.152Zm-.868-.439c.048-.067-.113-.355-.429-.193-.316.161.332.306.43.193Zm-1.003-.167s.077-.259.27-.207c.214.165-.26.216-.27.207Zm-.187-.194c-.032-.071-.23-.029-.458.123-.23.148.526.041.458-.123Zm.58-.69s.207-.094.268 0c.084.135-.268 0-.264 0h-.004Zm-.167-.09c-.042-.081-.268-.09-.261.032-.04.09.29.074.258-.033h.003Zm-.539 0c-.022-.049-.342 0-.439.193.155.074.51-.071.44-.194Zm-.687.196s-.42.258-.448.449c.013.16.526-.297.526-.297s.045-.18-.078-.152Zm-.468-.235c.013-.052.21-.174.226-.162.016.052-.161.194-.226.162Zm.136.987c.01-.074-.503-.068-.31.164.162.197.345-.129.31-.161v-.003Zm-.471.338c-.026-.051-.064-.19-.135-.203-.055 0-.365.065-.387.113-.013.042.129.297.174.303.055.02.338-.187.348-.213Zm2.76 1.723c.017-.055.54-.236.72-.065.213.29-.732.155-.72.065Zm1.44.342c.112-.194-.355-.426-.549-.204.065.265.455.362.548.204Z"
          fill="#fff"
          stroke="#fff"
          strokeWidth=".1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
export default NazioneLb;
