/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\snai-logo.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const SnaiLogo = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-snai-logo', className].join(' ');
  return (
    <svg id="snai-logo" viewBox="0 0 24 24" focusable="false" className={componentClassName} {...attribs}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2431 6.3609C17.7903 2.68661 14.205 0.0808716 10.0152 0.0808716C4.53661 0.0808716 0.0984192 4.52375 0.0984192 10.0024C0.0984192 15.481 4.53661 19.9192 10.0152 19.9192C14.8143 19.9192 18.7463 16.5027 19.6649 11.9707C19.6649 11.9707 18.4136 12.1535 16.8342 12.2191C15.9016 15.0873 13.1927 17.1635 10.0152 17.1635C6.62215 17.1635 3.77739 14.8014 3.04159 11.6333C14.505 12.0645 23.5501 11.01 23.8922 8.68544C24.1875 6.67021 19.2431 6.3609 19.2431 6.3609ZM19.6977 8.89634C19.5337 10.0914 15.1283 10.9069 3.04159 11.6286C2.91974 11.1037 2.85413 10.5601 2.85413 9.99769C2.85413 6.04221 6.05975 2.83658 10.0152 2.83658C13.1178 2.83658 15.7422 4.81432 16.7358 7.57472C16.8108 7.57472 19.8758 7.6169 19.6977 8.89634Z"
      />
    </svg>
  );
};
export default SnaiLogo;
