/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\live.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Live = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-live', className].join(' ');
  return (
    <svg
      id="live"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g id="live_tv">
        <mask
          id="mask0_18024_164892"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <rect id="Bounding box" width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_18024_164892)">
          <path
            id="live_tv_2"
            d="M6.49996 10.034L10.7 7.33398L6.49996 4.63398V10.034ZM5.66663 13.6673V12.334H2.86663C2.53329 12.334 2.24996 12.2173 2.01663 11.984C1.78329 11.7507 1.66663 11.4673 1.66663 11.134V3.53398C1.66663 3.20065 1.78329 2.91732 2.01663 2.68398C2.24996 2.45065 2.53329 2.33398 2.86663 2.33398H13.1333C13.4666 2.33398 13.75 2.45065 13.9833 2.68398C14.2166 2.91732 14.3333 3.20065 14.3333 3.53398V11.134C14.3333 11.4673 14.2166 11.7507 13.9833 11.984C13.75 12.2173 13.4666 12.334 13.1333 12.334H10.3333V13.6673H5.66663ZM2.86663 11.334H13.1333C13.1777 11.334 13.2222 11.3118 13.2666 11.2673C13.3111 11.2229 13.3333 11.1784 13.3333 11.134V3.53398C13.3333 3.48954 13.3111 3.4451 13.2666 3.40065C13.2222 3.35621 13.1777 3.33398 13.1333 3.33398H2.86663C2.82218 3.33398 2.77774 3.35621 2.73329 3.40065C2.68885 3.4451 2.66663 3.48954 2.66663 3.53398V11.134C2.66663 11.1784 2.68885 11.2229 2.73329 11.2673C2.77774 11.3118 2.82218 11.334 2.86663 11.334Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default Live;
