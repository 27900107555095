/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-69.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina69 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-69', className].join(' ');
  return (
    <svg
      id="disciplina_poker"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M22.95 3.486 11.555 1.028a1.352 1.352 0 0 0-1.58 1.02L6.376 18.73a1.33 1.33 0 0 0 1.02 1.58l11.395 2.458a1.337 1.337 0 0 0 1.58-1.022l3.598-16.68a1.332 1.332 0 0 0-1.02-1.579Zm-3.353 9.653c-.745.938-2.255 2.312-5.22 3.533l-.255.105-.187-.2c-2.202-2.335-3.01-4.21-3.302-5.371-.216-.857-.035-1.856.482-2.673.485-.764 1.184-1.27 1.97-1.424.184-.036.37-.055.558-.056 1.186 0 1.953.758 2.311 1.22.334-.177.876-.396 1.51-.396.568 0 1.123.181 1.582.516.654.465 1.083 1.215 1.21 2.11.138.96-.11 1.944-.66 2.636Zm-13.71 7.024a2.113 2.113 0 0 1-.292-1.603l.68-3.154c-.07.006-.14.014-.213.014-.5-.002-.99-.142-1.415-.406-.685-.415-1.172-1.13-1.363-2.013-.206-.944-.033-1.943.462-2.673.63-.928 1.9-2.287 4.408-3.637l.853-3.955-5.158-.713a1.334 1.334 0 0 0-1.501 1.134L.013 20.06a1.331 1.331 0 0 0 1.134 1.501l11.548 1.597a1.317 1.317 0 0 0 1.3-.61L7.227 21.09a2.113 2.113 0 0 1-1.34-.926Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina69;
