/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-53.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco53 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-53', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="35.482"
      viewBox="0 0 38.439 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M76.743,214.957H71.569v-.739a2.218,2.218,0,0,0-4.435,0v.739H55.306v-.739a2.218,2.218,0,0,0-4.435,0v.739H45.7a3.7,3.7,0,0,0-3.7,3.7v25.133a3.7,3.7,0,0,0,3.7,3.7H76.743a3.7,3.7,0,0,0,3.7-3.7V218.653A3.7,3.7,0,0,0,76.743,214.957Zm-8.131-.739a.739.739,0,1,1,1.478,0v4.435a.739.739,0,1,1-1.478,0Zm-16.263,0a.739.739,0,1,1,1.478,0v4.435a.739.739,0,1,1-1.478,0Zm26.612,29.569A2.22,2.22,0,0,1,76.743,246H45.7a2.22,2.22,0,0,1-2.218-2.218V223.827H76.743a.739.739,0,1,0,0-1.478H43.478v-3.7a2.22,2.22,0,0,1,2.218-2.218h5.175v2.218a2.218,2.218,0,0,0,4.435,0v-2.218H67.133v2.218a2.218,2.218,0,0,0,4.435,0v-2.218h5.175a2.22,2.22,0,0,1,2.218,2.218Z"
          transform="translate(-42 -212)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M53.7,234H50.739a.739.739,0,0,0,0,1.478H53.7a.739.739,0,1,0,0-1.478Zm7.392,0H58.131a.739.739,0,0,0,0,1.478h2.957a.739.739,0,1,0,0-1.478Zm7.392,0H65.524a.739.739,0,0,0,0,1.478H68.48a.739.739,0,0,0,0-1.478Zm4.435,1.478h2.957a.739.739,0,0,0,0-1.478H72.916a.739.739,0,0,0,0,1.478ZM53.7,239.914H50.739a.739.739,0,0,0,0,1.478H53.7a.739.739,0,1,0,0-1.478Zm7.392,0H58.131a.739.739,0,0,0,0,1.478h2.957a.739.739,0,1,0,0-1.478Zm7.392,0H65.524a.739.739,0,0,0,0,1.478H68.48a.739.739,0,0,0,0-1.478Zm7.392,0H72.916a.739.739,0,0,0,0,1.478h2.957a.739.739,0,0,0,0-1.478ZM53.7,245.828H50.739a.739.739,0,1,0,0,1.478H53.7a.739.739,0,1,0,0-1.478Zm7.392,0H58.131a.739.739,0,1,0,0,1.478h2.957a.739.739,0,1,0,0-1.478Zm7.392,0H65.524a.739.739,0,1,0,0,1.478H68.48a.739.739,0,1,0,0-1.478Z"
          transform="translate(-44.086 -217.737)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco53;
