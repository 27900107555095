/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-au.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneAu = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-au', className].join(' ');
  return (
    <svg
      id="nazione-au"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <path d="M0 0h16v16H0V0Z" fill="#006" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.716 11.519 2.984 12l.42-1.294L3.818 12l1.272-.481-.753 1.134 1.168.694-1.353.116.188 1.346-.938-.984-.937.984.187-1.343-1.356-.12 1.172-.693m10.156 2.234-.594.05.085.588-.407-.428-.406.428.072-.588-.588-.05.51-.3-.328-.493.553.21.181-.563.178.562.556-.21-.324.495m.506-8.45-.588.05.082.587-.407-.428-.406.428.075-.588-.588-.05.51-.3-.328-.493.553.21.181-.563.178.562.556-.21-.324.494M9.338 9.694l-.588.05.081.584-.406-.428-.406.428.078-.584-.588-.05.51-.303L7.69 8.9l.553.21.181-.563.178.562.553-.209-.325.49m6.7-.571-.587.05.081.584-.406-.428-.406.428.078-.584-.588-.05.51-.303-.329-.491.553.21.182-.563.181.562.55-.209-.325.49m-2.006 2.579-.322.2.09-.369-.29-.244.375-.028.147-.35.137.353.378.031-.287.241.09.369"
          fill="#fff"
        />
        <path d="M0 0h8v8H0V0Z" fill="#006" />
        <path
          d="M8 0v1L5.031 4 8 6.922V8H6.953L3.97 5.062 1.063 8H0V6.937l2.906-2.921L0 1.156V0h.969l3 2.938L6.875 0H8Z"
          fill="#fff"
        />
        <path
          d="m2.875 5.063.172.53L.657 8H0v-.047l2.875-2.89Zm1.938-.188L5.655 5 8 7.297V8L4.812 4.875ZM8 0 5 3.063l-.063-.688L7.281 0H8ZM0 .016l3.016 2.953-.922-.125L0 .766v-.75Z"
          fill="#C8102E"
        />
        <path d="M2.75 0v8h2.5V0h-2.5ZM0 2.75v2.5h8v-2.5H0Z" fill="#fff" />
        <path d="M0 3.25v1.5h8v-1.5H0ZM3.25 0v8h1.5V0h-1.5Z" fill="#C8102E" />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="16" height="16" rx="8" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NazioneAu;
