/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-377.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco377 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-377', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M396.48,148.177a8.131,8.131,0,1,0,8.131,8.131A8.141,8.141,0,0,0,396.48,148.177Zm0,14.784a6.653,6.653,0,1,1,6.653-6.653A6.661,6.661,0,0,1,396.48,162.961Zm3.7-7.392H397.22v-2.957a.739.739,0,1,0-1.478,0v2.957h-2.957a.739.739,0,1,0,0,1.478h2.957V160a.739.739,0,1,0,1.478,0v-2.957h2.957a.739.739,0,0,0,0-1.478ZM412,148.177a2.957,2.957,0,1,0-2.957,2.957A2.96,2.96,0,0,0,412,148.177Zm-4.435,0a1.478,1.478,0,1,1,1.479,1.478A1.48,1.48,0,0,1,407.569,148.177Zm5.174-13.306H380.957a1.476,1.476,0,0,1-.275-2.927l25.748-4.423a2.192,2.192,0,0,1,2.617,2.176v2.957a.739.739,0,1,0,1.478,0V129.7a3.7,3.7,0,0,0-3.7-3.7,3.584,3.584,0,0,0-.658.064l-25.748,4.423a2.952,2.952,0,0,0-2.424,2.9v24.394a3.7,3.7,0,0,0,3.7,3.7h5.175a.739.739,0,1,0,0-1.478H381.7a2.22,2.22,0,0,1-2.218-2.218V135.938a2.927,2.927,0,0,0,1.479.411h31.786a2.22,2.22,0,0,1,2.218,2.218v19.22A2.22,2.22,0,0,1,412.743,160H406.09a.739.739,0,0,0,0,1.478h6.653a3.7,3.7,0,0,0,3.7-3.7v-19.22A3.7,3.7,0,0,0,412.743,134.871Z"
        transform="translate(-378 -126)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco377;
