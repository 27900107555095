/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-16.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina16 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-16', className].join(' ');
  return (
    <svg
      id="disciplina_nuoto"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M4.529 9.719a1.461 1.461 0 0 1 2.065 0 3.047 3.047 0 0 0 4.308 0 1.461 1.461 0 0 1 2.065 0 3.05 3.05 0 0 0 4.308 0 1.461 1.461 0 0 1 2.065 0 3.05 3.05 0 0 0 4.308 0 .79.79 0 0 0-1.117-1.117 1.461 1.461 0 0 1-2.065 0 3.05 3.05 0 0 0-4.308 0 1.46 1.46 0 0 1-2.07 0 3.047 3.047 0 0 0-4.308 0 1.461 1.461 0 0 1-2.065 0 3.05 3.05 0 0 0-4.308 0 1.461 1.461 0 0 1-2.064 0A.79.79 0 0 0 .226 9.72a3.045 3.045 0 0 0 4.303 0Zm8.076-6.424a1.966 1.966 0 1 1 0 3.932 1.966 1.966 0 0 1 0-3.932Zm-4.547 5.12a.318.318 0 0 0 .46 0 3.27 3.27 0 0 1 1.92-1.056c.054-.31.028-.628-.076-.925-.056-.16-.68-2.262-.68-2.262-.037-.289.03-.581.187-.826.263-.385.817-.647 1.591-.765a.798.798 0 0 0-.234-1.577c-1.244.188-2.145.672-2.675 1.441a2.794 2.794 0 0 0-.427 2.093l.169.685a1.86 1.86 0 0 0-.31.075l-3.205 1.14a1.853 1.853 0 0 0-1.234 1.624 3.246 3.246 0 0 1 4.514.352Zm10.972 6.457a1.967 1.967 0 1 1 0 3.934 1.967 1.967 0 0 1 0-3.934Zm-4.548 5.12a.317.317 0 0 0 .46 0 3.27 3.27 0 0 1 1.92-1.056c.054-.31.028-.628-.075-.925-.057-.16-.68-2.262-.68-2.262-.037-.289.029-.581.187-.826.263-.384.816-.647 1.59-.765a.797.797 0 1 0-.234-1.576c-1.243.187-2.144.67-2.675 1.44a2.794 2.794 0 0 0-.427 2.093l.17.686a1.852 1.852 0 0 0-.31.075l-3.22 1.135a1.853 1.853 0 0 0-1.234 1.624 3.259 3.259 0 0 1 4.528.357ZM4.656 21.31a1.461 1.461 0 0 1 2.065 0 3.048 3.048 0 0 0 4.308 0 1.461 1.461 0 0 1 2.064 0 3.05 3.05 0 0 0 4.308 0 1.461 1.461 0 0 1 2.065 0 3.05 3.05 0 0 0 4.308 0 .79.79 0 0 0-1.117-1.117 1.461 1.461 0 0 1-2.064 0 3.05 3.05 0 0 0-4.308 0 1.462 1.462 0 0 1-2.07 0 3.047 3.047 0 0 0-4.308 0 1.461 1.461 0 0 1-2.065 0 3.05 3.05 0 0 0-4.308 0 1.461 1.461 0 0 1-2.065 0A.79.79 0 0 0 .352 21.31a3.045 3.045 0 0 0 4.304 0Z"
      />
    </svg>
  );
};
export default Disciplina16;
