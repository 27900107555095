import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CasinoLiveCatalog, Table } from 'types/casino-live';

interface CasinoLiveFilterState<T extends Array<Table>> {
  filteredItems: T;
  items: T;
  selectedLanguages: Array<string>;
}

const initialState: CasinoLiveFilterState<CasinoLiveCatalog['games']> = {
  filteredItems: [],
  items: [],
  selectedLanguages: [],
};

export const casinoLiveFilterSlice = createSlice({
  name: 'CasinoLiveFilter',
  initialState,
  reducers: {
    addLanguage: (state, { payload: language }: PayloadAction<string>) => {
      state.selectedLanguages.push(language);
      state.filteredItems = state.items.filter((item) => state.selectedLanguages.includes(item.language));
    },
    removeLanguage: (state, { payload: language }: PayloadAction<string>) => {
      const index = state.selectedLanguages.findIndex((lang) => lang === language);
      state.selectedLanguages.splice(index, 1);
      state.filteredItems = state.items.filter((item) => state.selectedLanguages.includes(item.language));

      if (!state.filteredItems.length) {
        state.filteredItems = state.items;
      }
    },
    setItems: (state, action: PayloadAction<CasinoLiveCatalog['games']>) => {
      state.items = action.payload;
      state.filteredItems = state.items;
    },
    resetFilter: (state) => {
      state.filteredItems = state.items;
      state.selectedLanguages = [];
    },
  },
});

export const { setItems, addLanguage, removeLanguage, resetFilter } = casinoLiveFilterSlice.actions;

export default casinoLiveFilterSlice.reducer;
