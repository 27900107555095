export const BANNER_UNIQUE_MODEL_API_KEY = 'banner_unique';
export const PROMO_MODEL_API_KEY = 'promo';
export const HERO_BANNER_MODEL_API_KEY = 'hero_banner';

export const CMS_SLOT_MODEL_API_KEY = 'cms_slot';
export const CONSOLE_GIOCHI_SLOT_MODEL_API_KEY = 'console_giochi_slot_category_list';

export const CMS_CASINO_MODEL_API_KEY = 'cms_casino';
export const CONSOLE_GIOCHI_CASINO_MODEL_API_KEY = 'console_giochi_casino_category_list';

export const SUPPLIER_SISAL_MODEL_API_KEY = 'sisal';
export const SUPPLIER_LOTTOMATICA_MODEL_API_KEY = 'lottomatica';
export const SUPPLIER_GRATTA_E_VINCI_MODEL_API_KEY = 'grattaevinci';

export const HERO_BANNER_CMS = 'heroBannerCms';
export const HERO_BANNER_PROMO = 'heroBannerPromoEngine';
export const BANNER_ON_PAGE_CMS = 'bannerOnPageCms';
export const BANNER_ON_PAGE_PROMO = 'bannerOnPagePromoEngine';
export const BANNER_INSIDE_LIST_CMS = 'bannerInsideListCms';
export const BANNER_INSIDE_LIST_PROMO = 'bannerInsideListPromoEngine';

export enum ModelApiKey {
  SLOT = 'slot_page',
  CASINO = 'list_casino',
  GIOCHI_CARTE = 'card_game_page',
}
