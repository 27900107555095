import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { InfoAmbiente } from 'context/Ambiente';
import { addToIppicaCartByKey } from 'features/ippica/actions';
import {
  IppicaTicketState,
  ticketImported as ippicaTicketImported,
  scommessaRemoved as removeIppicaScommessa,
} from 'features/ippica/components/ippicaTicket/ippicaTicketSlice';
import { IppicaTicket } from 'features/ippica/components/ippicaTicket/utils/calcoloCarrello/types';
import { addToSportCartByKey } from 'features/sport';
import {
  SportTicketState,
  addToSnaiRunnerEsiti,
  scommessaRemoved,
  ticketImported as sportTicketImported,
} from 'features/sport/components/sportTicket/sportTicketSlice';
import { SportTicket } from 'features/sport/components/sportTicket/utils/calcoloCarrello/types';
import { addToVirtualCartByKey } from 'features/virtual/actions';
import { VirtualTicket } from 'features/virtual/components/virtualTicket/utils/calcoloCarrello/types';
import {
  VirtualTicketState,
  scommessaRemoved as removeVirtualScommessa,
  ticketImported as virtualTicketImported,
} from 'features/virtual/components/virtualTicket/virtualTicketSlice';
import { RootState } from 'lib/centralStore';
import { tabSelected, toggleCarrello } from './carrelloSlice';
import { CarrelloCalcTicketTypes, CarrelloTipo } from './types';
import { getSliceByTipoTicket } from './utils';

export const addToCartSnaiRunnerKey =
  ({ esitoKey }: { esitoKey: string }): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch) => {
    dispatch(addToSnaiRunnerEsiti(esitoKey));
  };

export const addToCartByKey =
  ({
    esitoKey,
    ambiente,
    pathScommessa,
  }: {
    esitoKey: string;
  } & InfoAmbiente): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    switch (ambiente) {
      case 'sport':
        dispatch(addToSportCartByKey({ esitoKey, pathScommessa }));
        break;
      case 'virtual-sport':
        dispatch(addToVirtualCartByKey({ esitoKey, pathScommessa, ambiente }));
        break;
      case 'virtual-corse':
        dispatch(addToVirtualCartByKey({ esitoKey, pathScommessa, ambiente }));
        break;
      case 'ippica':
        dispatch(addToIppicaCartByKey({ esitoKey }));
        break;
      default:
        console.error(`Unhandled addToCart: ${ambiente}`);
        break;
    }
  };

export const removeFromCartByKey =
  ({
    esitoKey,
    ambiente,
  }: {
    esitoKey: string;
  } & Partial<InfoAmbiente>): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch) => {
    switch (ambiente) {
      case 'sport':
        dispatch(scommessaRemoved(esitoKey));
        break;
      case 'virtual-sport':
      case 'virtual-corse':
        dispatch(removeVirtualScommessa(esitoKey));
        break;
      case 'ippica':
        dispatch(removeIppicaScommessa(esitoKey));
        break;
      default:
        console.error(`Unhandled addToCart: ${ambiente}`);
        break;
    }
  };

export const updateTicket =
  (
    ticketState: SportTicketState | VirtualTicketState | IppicaTicketState
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const tipo = getState().carrello.tipo;
    const { actions } = getSliceByTipoTicket(tipo);
    const { sistemiDaGiocare, ticket, puntataSingolaMultipla } = ticketState;

    if (ticket) {
      if (ticket.sistema) {
        dispatch(actions.ticketUpdatedBySistemi(sistemiDaGiocare));
      } else {
        dispatch(actions.ticketUpdated({ puntata: puntataSingolaMultipla }));
      }
    }
  };

export const importTicketByType = (
  tipo: CarrelloTipo,
  ticket: CarrelloCalcTicketTypes
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    dispatch(toggleCarrello({ isFullCart: true, isOpen: true }));
    dispatch(tabSelected('tab1'));
    // dispatch(importTicketByType(tipo, ticket));
    const { actions } = getSliceByTipoTicket(tipo);
    switch (tipo) {
      case CarrelloTipo.Ippica:
        dispatch(ippicaTicketImported(ticket as IppicaTicket));
        break;
      case CarrelloTipo.Sport:
        dispatch(sportTicketImported(ticket as SportTicket));
        break;
      case CarrelloTipo.Virtual:
        dispatch(virtualTicketImported(ticket as VirtualTicket));
        break;
    }
    dispatch(actions.importTicketDialogOpenChange(false));
  };
};
