import React from 'react';
import { mutex } from '.';
import { AnyAction } from '@reduxjs/toolkit';
import { setToken } from 'features/auth/authSlice';
import { AuthTokenType } from 'features/auth/types';
import { setIsOnline } from 'features/location/locationSlice';
import { useAppDispatch } from 'lib/centralStore';
import Persist, { StorageKind } from 'lib/persist';
import { useSession } from 'next-auth/react';
import { setSession } from 'features/auth/authSlice';
import { useEffect, useRef } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import { CONNECTED_KEY_NAME, REFRESH_KEY_NAME } from 'utility/constant';
import { getSessionId, hasValue, isClientSide, isTruthy } from 'utility/functions';
import { useInternalSession } from 'hooks';

const isSS = isClientSide() ? false : true;

const cookie = Persist(StorageKind.cookie);

const DELAY = 9999;

const doPing = async (): Promise<AuthTokenType | undefined> => {
  let result: AuthTokenType | undefined;
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/ping?v=${Date.now()}`, { method: 'POST' });
    if (response.ok) {
      const data = await response.json();
      if (Object.hasOwn(data, 'accessToken')) {
        result = data as AuthTokenType;
      }
    }
  } catch (e) {
    return Promise.reject(e);
  } finally {
    return Promise.resolve(result);
  }
};

export const RefreshTokenEngine = () => {
  const dispatch = useAppDispatch();
  const resetRef = useRef<Function | undefined>();
  const messageRef = useRef<Function | undefined>();
  const releaseRef = useRef<Function | undefined>();
  const refSessionId = useRef('');

  const { data, status } = useSession();
  const { updateSession } = useInternalSession();

  const isDriver = () => {
    let owner = cookie.getItem(REFRESH_KEY_NAME);
    // push current
    if (!hasValue(owner)) {
      cookie.setItem(REFRESH_KEY_NAME, refSessionId.current, true);
      return true;
    }

    return owner === refSessionId.current;
    // save the clean unique list
  };

  const onIdle = async () => {
    let result = false;
    let payload: AuthTokenType | undefined;

    // console.log(`RefreshTokenEngine.status:${status}, data:${JSON.stringify(data)}`);

    if (typeof resetRef.current === 'function') {
      resetRef.current();
    }

    // some one else is owner of refreshing the token
    if (isDriver()) {
      console.log(`RefreshTokenEngine.provider`);
    } else {
      console.log(`RefreshTokenEngine.consumer`);
      return;
    }

    if (mutex.isLocked()) {
      console.log('RefreshTokenEngine.in esecuzione - skip');
      return;
    }

    if (typeof messageRef.current === 'function') {
      messageRef.current({ type: 'lock', payload: true }, true);
      try {
        // console.log('RefreshTokenEngine.invocazione di ping...?')
        payload = await doPing();
        // console.log('RefreshTokenEngine.invocazione di ping: ok')
        result = true;
      } finally {
        if (payload) {
          // refresh token has been executed
          // console.log('RefreshTokenEngine.propagazione token')
          messageRef.current({ type: 'token', payload }, true);
          // } else {
        }
        // console.log('RefreshTokenEngine.propagazione online')
        messageRef.current({ type: 'online', payload: result }, true);
      }
      messageRef.current({ type: 'lock', payload: false }, true);
    }
  };

  const onMessage = async ({ type, payload }: AnyAction) => {
    // console.log(`RefreshTokenEngine.message (type:${type}, payload:${JSON.stringify(payload)})`)
    switch (type) {
      case 'lock': {
        if (isTruthy(payload)) {
          // console.log('RefreshTokenEngine.mutex:acquire...?')
          releaseRef.current = await mutex.acquire();
          console.log('RefreshTokenEngine.mutex:acquire: ok');
        } else if (typeof releaseRef.current === 'function') {
          // console.log('RefreshTokenEngine.mutex:release...?')
          releaseRef.current();
          console.log('RefreshTokenEngine.mutex:release: ok');
        } else {
          console.log('RefreshTokenEngine.mutex:nulla da rilasciare');
        }
        break;
      }
      case 'session': {
        console.log(`RefreshTokenEngine.session... ${status}`);
        // console.log('RefreshTokenEngine.session...?')
        // TODO : VERIFICARE se nel multibat convenga sparare setSession da sola oppure in compinata con update della session di next-auth
        // dispatch(setSession({ ...payload }));
        updateSession({ ...payload });
        console.log('RefreshTokenEngine.session: ok');
        break;
      }
      case 'token': {
        // console.log('RefreshTokenEngine.token...?')
        await dispatch(setToken(payload));
        console.log('RefreshTokenEngine.token: ok');
        break;
      }
      case 'online': {
        // console.log('RefreshTokenEngine.online...?')
        await dispatch(setIsOnline(payload));
        console.log('RefreshTokenEngine.online: ok');
        break;
      }
    }
  };

  const { reset, message } = useIdleTimer({
    name: CONNECTED_KEY_NAME,
    events: [],
    timers: workerTimers,
    timeout: DELAY,
    crossTab: true,
    disabled: isSS,
    syncTimers: 500,
    startOnMount: true,
    leaderElection: true,
    promptBeforeIdle: 0,
    onIdle,
    onMessage,
  });

  const doUnlist = () => {
    // write updated list
    cookie.removeItem(REFRESH_KEY_NAME);
  };

  useEffect(() => {
    resetRef.current = reset;
    messageRef.current = message;
  });

  useEffect(() => {
    window.addEventListener('beforeunload', doUnlist, { passive: true });
    return () => {
      window.removeEventListener('beforeunload', doUnlist);
    };
  }, []);

  useEffect(() => {
    if (status === 'loading') return;
    refSessionId.current = getSessionId();

    const isAuthenticated = Object.hasOwn(data ?? {}, 'refreshToken');

    const sessionData = { ...data, isAuthenticated };
    dispatch(setSession(sessionData));

    if (typeof messageRef.current === 'function') {
      console.log(
        `RefreshTokenEngine.isAuthenticated: ${status === 'authenticated'} vs ${Object.hasOwn(
          data ?? {},
          'refreshToken'
        )} `
      );
      messageRef.current({ type: 'session', payload: sessionData }, false);
    }

    if (typeof resetRef.current === 'function') {
      resetRef.current();
    }
  }, [data, status]);

  return <React.Fragment key={CONNECTED_KEY_NAME} />;
};
