/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-54.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina54 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-54', className].join(' ');
  return (
    <svg
      id="disciplina_pentathlon-moderno"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M19.802 5.56c.117-1.05-.627-1.997-1.662-2.115a1.893 1.893 0 0 0-2.09 1.681c-.118 1.05.625 1.996 1.661 2.114a1.893 1.893 0 0 0 2.09-1.68ZM8.87 21.89l3.465-5.95c.103.08 2.024.923 2.773 1.431-.777.471-1.554.947-2.33 1.417-1.286.782-.114 2.816 1.176 2.034 1.2-.73 3.978-2.5 4.063-2.693.363-.419.447-1.035-.09-1.51a14.473 14.473 0 0 0-2.942-2.025 103.16 103.16 0 0 1 2.241-4.143c2.495.772 4.868-.142 6.558-2.533.876-1.238-1.144-2.41-2.01-1.187-1.323 1.879-3.098 2.095-5.004.947-.132-.08-.707-.4-.834-.476-3.07-1.84-6.134-1.12-8.191 1.794-.876 1.238 1.144 2.41 2.01 1.186 1.064-1.506 2.41-1.94 3.888-1.464-.757 1.328-5.32 9.463-6.784 11.982-.762 1.313 1.253 2.5 2.01 1.19ZM3.8 4.468A1.233 1.233 0 1 1 3.8 2a1.233 1.233 0 0 1 0 2.467Zm-.35 3.56a1.233 1.233 0 1 1-2.466 0 1.233 1.233 0 0 1 2.467 0Zm3.17.013a1.234 1.234 0 1 1-2.468 0 1.234 1.234 0 0 1 2.467 0Zm.988-2.91a1.233 1.233 0 1 1-2.467 0 1.233 1.233 0 0 1 2.467 0ZM1.233 6.365a1.234 1.234 0 1 1 0-2.467 1.234 1.234 0 0 1 0 2.467Z"
      />
    </svg>
  );
};
export default Disciplina54;
