/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-244.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco244 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-244', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.569"
      height="38.439"
      viewBox="0 0 29.569 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M487.48,496.131a2.212,2.212,0,0,0-4.3-.739h-2.354a2.22,2.22,0,0,0-2.218,2.218v8.871h1.478V497.61a.74.74,0,0,1,.739-.739h2.354a2.212,2.212,0,0,0,4.3-.739Zm-2.957,0a.739.739,0,1,1,.739.739A.739.739,0,0,1,484.523,496.131Zm2.218,3.7a2.218,2.218,0,1,0,2.082,2.957h8.267v-1.478h-8.267A2.213,2.213,0,0,0,486.741,499.828Zm0,2.957a.739.739,0,1,1,.739-.739A.739.739,0,0,1,486.741,502.784Zm-11.827-8.871a2.212,2.212,0,0,0-.739,4.3v2.354a.74.74,0,0,1-.739.739H469v1.478h4.435a2.22,2.22,0,0,0,2.218-2.218v-2.354a2.212,2.212,0,0,0-.739-4.3Zm0,2.957a.739.739,0,1,1,.739-.739A.739.739,0,0,1,474.914,496.871Zm5.914-4.435a2.218,2.218,0,1,0-2.082-2.957H469v1.478h9.746A2.214,2.214,0,0,0,480.827,492.435Zm0-2.957a.739.739,0,1,1-.739.739A.739.739,0,0,1,480.827,489.479Zm11.827,7.392a.739.739,0,0,1-.739-.739V492.3a2.218,2.218,0,1,0-1.479,0v3.832a2.22,2.22,0,0,0,2.218,2.218h4.435v-1.479Zm-1.478-5.914a.739.739,0,1,1,.739-.739A.74.74,0,0,1,491.176,490.957Z"
        transform="translate(-468.261 -468.78)"
        fill="currentColor"
      />
      <g transform="translate(4.435 0)">
        <path
          className="a"
          d="M494.7,479H493.22v-6.653a8.871,8.871,0,1,0-17.741,0V479H474v-6.653a10.349,10.349,0,1,1,20.7,0Z"
          transform="translate(-474 -462)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(0 16.263)">
        <path
          className="a"
          d="M492.394,506.177h-19.22A5.181,5.181,0,0,1,468,501V489.175A5.181,5.181,0,0,1,473.174,484h19.22a5.181,5.181,0,0,1,5.174,5.175V501A5.181,5.181,0,0,1,492.394,506.177Zm-19.22-20.7a3.7,3.7,0,0,0-3.7,3.7V501a3.7,3.7,0,0,0,3.7,3.7h19.22a3.7,3.7,0,0,0,3.7-3.7V489.175a3.7,3.7,0,0,0-3.7-3.7Z"
          transform="translate(-468 -484)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco244;
