/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\success.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Success = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-success', className].join(' ');
  return (
    <svg
      id="success"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M10 19.7743C4.477 19.7743 0 15.3968 0 9.99653C0 4.59626 4.477 0.21875 10 0.21875C15.523 0.21875 20 4.59626 20 9.99653C20 15.3968 15.523 19.7743 10 19.7743ZM9.003 13.9076L16.073 6.99377L14.659 5.61119L9.003 11.1425L6.174 8.37635L4.76 9.75893L9.003 13.9076Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Success;
