/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\archive.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Archive = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-archive', className].join(' ');
  return (
    <svg
      id="archive"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M17 18H1V7H3V16H15V7H17V18ZM0 0H18V6H0V0ZM6.5 8H11.5C11.78 8 12 8.22 12 8.5V10H6V8.5C6 8.22 6.22 8 6.5 8ZM2 2V4H16V2H2Z"
        fill="var(--color-primary)"
      />
    </svg>
  );
};
export default Archive;
