/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\chewron-left.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const ChewronLeft = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-chewron-left', className].join(' ');
  return (
    <svg id="chewron-left" viewBox="0 0 8 12" focusable="false" className={componentClassName} {...attribs}>
      <path
        d="M0.299982 6L6.29998 -6.11959e-08L7.69998 1.4L3.09998 6L7.69998 10.6L6.29998 12L0.299982 6Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default ChewronLeft;
