/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-71.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco71 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-71', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M481.22,332.439h-18.48a.739.739,0,0,1-.523-1.262l4.9-4.9a19.22,19.22,0,1,1,14.1,6.163Zm-16.7-1.478h16.7a17.741,17.741,0,1,0-12.545-5.2.738.738,0,0,1,0,1.045Z"
          transform="translate(-462 -294)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M474.739,315.478h19.22a.739.739,0,0,0,0-1.478h-19.22a.739.739,0,1,0,0,1.478Zm13.306,7.392H474.739a.739.739,0,1,0,0,1.478h13.306a.739.739,0,1,0,0-1.478Zm5.914-4.435h-19.22a.739.739,0,1,0,0,1.478h19.22a.739.739,0,1,0,0-1.478Z"
        transform="translate(-465.129 -299.216)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco71;
