/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-163.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco163 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-163', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="35.483"
      viewBox="0 0 35.482 35.483"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <g transform="translate(16.263 17.741)">
          <path
            className="a"
            d="M334.263,337.741H320.957A2.96,2.96,0,0,1,318,334.784V322.957A2.96,2.96,0,0,1,320.957,320h13.306a2.96,2.96,0,0,1,2.957,2.957v11.827A2.96,2.96,0,0,1,334.263,337.741Zm-13.306-16.263a1.48,1.48,0,0,0-1.478,1.478v11.827a1.48,1.48,0,0,0,1.478,1.478h13.306a1.48,1.48,0,0,0,1.478-1.478V322.957a1.481,1.481,0,0,0-1.478-1.478Z"
            transform="translate(-318 -320)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(0 7.392)">
          <path
            className="a"
            d="M315.22,334.09h-18.48a.738.738,0,0,1-.736-.8l2.218-26.612a.738.738,0,0,1,.736-.678h22.177a.738.738,0,0,1,.736.678l.2,2.357a.739.739,0,0,1-.675.8.729.729,0,0,1-.8-.675l-.14-1.679H299.637l-2.094,25.133H315.22a.739.739,0,0,1,0,1.478Z"
            transform="translate(-296 -306)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(8.131 0)">
          <path
            className="a"
            d="M318.828,304.131h-1.478v-2.218a4.435,4.435,0,0,0-8.871,0v2.218H307v-2.218a5.914,5.914,0,0,1,11.828,0Z"
            transform="translate(-307 -296)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M334.372,312.935a3.191,3.191,0,0,0-4.509,0,6.834,6.834,0,0,0-1.209,2.552,6.836,6.836,0,0,0-1.209-2.552,3.189,3.189,0,1,0-4.509,4.509c.887.888,3.649,1.543,4.979,1.818v15.654a.739.739,0,0,0,1.478,0V319.262c1.331-.274,4.092-.93,4.979-1.818A3.192,3.192,0,0,0,334.372,312.935ZM323.98,316.4a1.71,1.71,0,0,1,2.419-2.419,11.235,11.235,0,0,1,1.3,3.723A11.223,11.223,0,0,1,323.98,316.4Zm9.347,0a11.229,11.229,0,0,1-3.723,1.3,11.229,11.229,0,0,1,1.3-3.723,1.71,1.71,0,0,1,2.419,2.419Z"
          transform="translate(-302.781 -300.173)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco163;
