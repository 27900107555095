import { MutableRefObject, useEffect, useRef, useState } from 'react';
import Swiper from 'swiper';
import useWindowSize from './useWindowSize';

export const useHideNavigationCarousel = <T>({
  className,
  items = [],
  sliderRef,
}: {
  className: string;
  items?: Array<T>;
  sliderRef?: MutableRefObject<Swiper>;
}) => {
  const swiperSlideRef = useRef<HTMLElement | null>(null);
  const [isScrollable, setIsScrollable] = useState(true);
  const width = useWindowSize();

  const [graterSlideWidth, setGraterSlideWidth] = useState(0);

  useEffect(() => {
    const swiperSlideElementChild = sliderRef ? sliderRef.current.el.children[0] : swiperSlideRef?.current?.children[0];
    const widthSwipe = [...(swiperSlideElementChild?.children ?? [])]?.reduce(
      (previous, current) => previous + current.getBoundingClientRect().width,
      0
    );
    // IS SCROLLABLE WHEN ALL CARDS PLUS THE GRATER ONE SLIDE IS GREATER THEN THE SWIPER WIDTH
    if (swiperSlideElementChild?.children) {
      for (const child of swiperSlideElementChild?.children) {
        setGraterSlideWidth((prevState) => {
          if (prevState < child.clientWidth) {
            return child.clientWidth;
          }
          return prevState;
        });
      }
    }

    // setIsScrollable(
    //   widthSwipe + (swiperSlideElementChild?.firstElementChild?.getBoundingClientRect()?.width ?? 0) >
    //     swiperSlideElementChild?.getBoundingClientRect().width! + 24
    // );

    setIsScrollable(
      widthSwipe + (graterSlideWidth + 24 ?? 0) > swiperSlideElementChild?.getBoundingClientRect().width!
    );
    swiperSlideElementChild?.classList[!isScrollable ? 'add' : 'remove'](className);
  }, [swiperSlideRef, isScrollable, width, items, className, sliderRef]);

  return { isScrollable, swiperSlideRef };
};
