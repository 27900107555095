/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\bonus-grey.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const BonusGrey = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-bonus-grey', className].join(' ');
  return (
    <svg
      id="bonus-grey"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M12.1667 3.33203H9.42671C9.75337 3.01203 9.96004 2.57203 9.96004 2.0787C9.96004 1.11203 9.17337 0.332031 8.21337 0.332031C7.62004 0.332031 7.07337 0.638698 6.75337 1.12536C6.44671 0.785365 6.01337 0.585365 5.54671 0.585365C4.65337 0.585365 3.92671 1.31203 3.92671 2.20536C3.92671 2.64536 4.10004 3.0387 4.38671 3.33203H1.50004C0.860041 3.33203 0.333374 3.8587 0.333374 4.4987V6.4987C0.333374 7.0387 0.706707 7.49203 1.20671 7.62536V12.1654C1.20671 12.8054 1.73337 13.332 2.37337 13.332H11.2934C11.9334 13.332 12.46 12.8054 12.46 12.1654V7.62536C12.96 7.49203 13.3334 7.0387 13.3334 6.4987V4.4987C13.3334 3.8587 12.8067 3.33203 12.1667 3.33203ZM12.3334 4.4987V6.4987C12.3334 6.59203 12.26 6.66536 12.1667 6.66536H7.36004V4.33203H12.1667C12.26 4.33203 12.3334 4.40536 12.3334 4.4987ZM6.32671 2.20536C6.32671 2.6387 5.97337 2.99203 5.54004 2.99203C5.10671 2.99203 4.75337 2.6387 4.75337 2.20536C4.75337 1.77203 5.10671 1.4187 5.54004 1.4187C5.97337 1.4187 6.32671 1.77203 6.32671 2.20536ZM7.29337 2.0787C7.29337 1.57203 7.70671 1.16536 8.20671 1.16536C8.70671 1.16536 9.12004 1.5787 9.12004 2.0787C9.12004 2.5787 8.70671 2.99203 8.20671 2.99203C7.70671 2.99203 7.29337 2.5787 7.29337 2.0787ZM1.33337 4.4987C1.33337 4.40536 1.40671 4.33203 1.50004 4.33203H6.35337V6.66536H1.50004C1.40671 6.66536 1.33337 6.59203 1.33337 6.4987V4.4987ZM2.20671 12.1654V7.66536H6.36004V12.332H2.37337C2.28004 12.332 2.20671 12.2587 2.20671 12.1654ZM11.46 12.1654C11.46 12.2587 11.3867 12.332 11.2934 12.332H7.35337V7.66536H11.46V12.1654Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
    </svg>
  );
};
export default BonusGrey;
