/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-411.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco411 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-411', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.526"
      height="38.439"
      viewBox="0 0 32.526 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M326.829,490.09a.739.739,0,0,0-.739.739v8.131H299.478v-28.09h6.653a.739.739,0,0,0,.739-.739v-6.653h19.22v9.61a.739.739,0,0,0,1.478,0v-9.61A1.48,1.48,0,0,0,326.09,462H306.131a.73.73,0,0,0-.285.058.721.721,0,0,0-.239.16l-7.391,7.391a.721.721,0,0,0-.16.239.73.73,0,0,0-.058.285v28.829a1.48,1.48,0,0,0,1.478,1.478H326.09a1.48,1.48,0,0,0,1.478-1.478v-8.131A.739.739,0,0,0,326.829,490.09Zm-21.437-25.567v4.868h-4.868Zm-2.218,15.218h7.392a.739.739,0,1,0,0-1.478h-7.392a.739.739,0,1,0,0,1.478Zm8.871-5.914h-8.871a.739.739,0,1,0,0,1.478h8.871a.739.739,0,1,0,0-1.478Zm-8.871,10.349h8.131a.739.739,0,0,0,0-1.478h-8.131a.739.739,0,1,0,0,1.478ZM315,487.133H303.174a.739.739,0,1,0,0,1.478H315a.739.739,0,1,0,0-1.478Zm-12.567,5.174a.739.739,0,0,0,.739.739h19.22a.739.739,0,1,0,0-1.478h-19.22A.739.739,0,0,0,302.435,492.308Zm27.874-3.479-4.412-4.412a7.4,7.4,0,1,0-1.045,1.045l4.412,4.412a.739.739,0,1,0,1.045-1.045Zm-10.132-3.173a5.914,5.914,0,1,1,5.914-5.914A5.921,5.921,0,0,1,320.177,485.655Z"
        transform="translate(-298 -462)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco411;
