/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\account.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Account = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-account', className].join(' ');
  return (
    <svg
      id="account"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M5.99996 0.667969C6.7072 0.667969 7.38548 0.94892 7.88558 1.44902C8.38567 1.94911 8.66663 2.62739 8.66663 3.33464C8.66663 4.04188 8.38567 4.72016 7.88558 5.22025C7.38548 5.72035 6.7072 6.0013 5.99996 6.0013C5.29272 6.0013 4.61444 5.72035 4.11434 5.22025C3.61424 4.72016 3.33329 4.04188 3.33329 3.33464C3.33329 2.62739 3.61424 1.94911 4.11434 1.44902C4.61444 0.94892 5.29272 0.667969 5.99996 0.667969ZM5.99996 7.33464C8.94663 7.33464 11.3333 8.52797 11.3333 10.0013V11.3346H0.666626V10.0013C0.666626 8.52797 3.05329 7.33464 5.99996 7.33464Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
    </svg>
  );
};
export default Account;
