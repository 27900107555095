/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-49.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina49 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-49', className].join(' ');
  return (
    <svg
      id="disciplina_ginnastica"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M3.905 20.133a1.601 1.601 0 0 1 0-2.255l4.312-4.312a1.4 1.4 0 0 1 .052-.188l-.999-2.414a1.318 1.318 0 0 1-.107-.867l.38-2.475-1.716-1.945a1.195 1.195 0 0 1-.272-.544l-.572-2.799a1.182 1.182 0 0 1 2.315-.473L7.81 4.35l1.697 1.927c.014.014.019.028.033.042l1.674.468.525-.342c.099-.062.206-.111.318-.145l5.517-1.59a1.186 1.186 0 0 1 .657 2.28l-5.344 1.537-.919.595-.258 1.167a.718.718 0 0 0 .3.717l2.311 1.467c.014.005.014.02.028.024l.802.23h5.17a1.66 1.66 0 1 1 0 3.318h-5.404c-.156.002-.311-.02-.46-.065l-2.962-.853h-.492c-.065.292-.211.56-.422.773l-4.327 4.327a1.601 1.601 0 0 1-2.255 0l-.093-.094Zm7.584-14.4A2.867 2.867 0 1 0 11.484 0a2.867 2.867 0 0 0 .005 5.733ZM23.306 21.88H.694c-.38 0-.694.361-.694.802 0 .44.314.801.694.801h22.612c.38 0 .694-.36.694-.801 0-.44-.314-.802-.694-.802Z"
      />
    </svg>
  );
};
export default Disciplina49;
