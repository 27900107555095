/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\autoesclusion-24h.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Autoesclusion24h = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-autoesclusion-24h', className].join(' ');
  return (
    <svg
      width="100%"
      height="100%"
      id="autoesclusion-24h"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#clip0_8998_100428)">
        <path
          d="M16.5 0C7.668 0 0.5 7.168 0.5 16C0.5 24.832 7.668 32 16.5 32C25.332 32 32.5 24.832 32.5 16C32.5 7.168 25.332 0 16.5 0ZM3.7 16C3.7 8.928 9.428 3.2 16.5 3.2C19.46 3.2 22.18 4.208 24.34 5.904L6.404 23.84C4.708 21.68 3.7 18.96 3.7 16ZM16.5 28.8C13.54 28.8 10.82 27.792 8.66 26.096L26.596 8.16C28.292 10.32 29.3 13.04 29.3 16C29.3 23.072 23.572 28.8 16.5 28.8Z"
          fill="currentColor"
        />
        <rect x="5" y="10" width="23" height="12" rx="1" fill="#031222" />
        <path
          d="M8.72426 18.68L10.3343 17.16C11.3143 16.25 11.5043 15.63 11.5043 14.95C11.5043 13.68 10.4643 12.88 8.90426 12.88C7.64426 12.88 6.66426 13.39 6.12426 14.18L7.30426 14.94C7.64426 14.47 8.15426 14.24 8.75426 14.24C9.49426 14.24 9.87426 14.56 9.87426 15.11C9.87426 15.45 9.76426 15.81 9.14426 16.4L6.44426 18.95V20H11.7343V18.68H8.72426ZM18.715 17.21H17.575V15.9H16.045V17.21H14.165L17.225 13H15.525L12.225 17.44V18.53H15.995V20H17.575V18.53H18.715V17.21ZM24.4396 13V15.76H21.2596V13H19.6396V20H21.2596V17.13H24.4396V20H26.0596V13H24.4396Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_8998_100428">
          <rect width="32" height="32" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Autoesclusion24h;
