import { IppicaTicket } from 'features/ippica/components/ippicaTicket/utils/calcoloCarrello/types';
import { SportTicket } from 'features/sport/components/sportTicket/utils/calcoloCarrello/types';
import { VirtualTicket } from 'features/virtual/components/virtualTicket/utils/calcoloCarrello/types';
import { ippicaTicketSlice } from 'features/ippica/components/ippicaTicket/ippicaTicketSlice';
import { sportTicketSlice } from 'features/sport/components/sportTicket/sportTicketSlice';
import { virtualTicketSlice } from 'features/virtual/components/virtualTicket/virtualTicketSlice';

export enum CarrelloTipo {
  Sport = 'Sport',
  Virtual = 'Virtual',
  Ippica = 'Ippica',
}

export type CarrelloTipoType = `${CarrelloTipo}`;

export type CarrelloCalcTicketTypes = SportTicket | VirtualTicket | IppicaTicket;

export type CarrelloScommesseCount = {
  label: CarrelloTipo;
  count?: number;
};

/** Warning - OnlyLabel - Footer Carrello Bonus */
export enum TicketMessaggi {
  UnAvvenimentoMancantePerBonus = 'un-avvenimento-mancante-per-bonus',
}

/** Warning - Big - In pagina - IsDismissible */
export enum TicketErroreInserimento {
  ScommessaNonCombinabile = 'scommessa-non-combinabile',
  MassimoNumeroDiEsitiPerAvvenimento = 'massimo-numero-di-esiti-per-avvenimento',
  SportNonCombinabile = 'sport-non-combinabile',
}

export enum TicketErroreBetReferral {
  ImportoMassimoScommettibile = 'importo-massimo-scommettibile',
  MaxVincita = 'max-vincita',
}

/** Error - Small - Top Footer Carrello */
export enum TicketErrore {
  PrezzoTicketSinglaMultiplaSottoMinimo = 'prezzo-ticket-singola-multipla-sotto-minimo',
  PrezzoTicketVirtualSistemaSottoMinimo = 'prezzo-ticket-virtual-sistema-sotto-minimo',
  PrezzoTicketSistemaSottoMinimo = 'prezzo-ticket-sistema-sotto-minimo',
  VincitaOltreMassimo = 'vincita-oltre-massimo',
  MinimoNumeroDiCombinazioniSelezionabili = 'minimo-numero-di-combinazioni-selezionabili',
  MassimoNumeroDiCombinazioniSelezionabili = 'massimo-numero-di-combinazioni-selezionabili',
  MassimoCombinazioniAvvenimenti = 'massimo-combinazioni-avvenimenti',
  MinimoCombinazioniAvvenimenti = 'minimo-combinazioni-avvenimenti',
  ImportoNonSuperioreCredito = 'importo-non-superiore-credito',
  IncrementoImportoSingolaMultiplaNonCorretto = `incremento-importo-singola-multipla-non-corretto`,
  MaxAvvenimenti = 'max-avvenimenti',
}

/** Warning - Small - Top Footer Carrello */
export enum TicketErroreVendita {
  QuoteCambiate = 'quote-cambiate',
  MassimoImportoRaggiunto = 'massimo-importo-raggiunto',
}

export enum TicketErroreSistema {
  IncrementoImportoSistemaNonCorretto = 'incremento-importo-sistema-non-corretto',
}

export type TicketMessaggiErrori = {
  messaggi: TicketMessaggi[];
  erroriCarrello: Array<TicketErrorWithValue<TicketErrore>>;
  erroreInserimento?: TicketErroreInserimento | undefined;
  erroriEvento: Array<string>;
  erroriVendita: Array<TicketErrorWithValue<TicketErroreVendita>>;
  erroriBetReferral: Array<TicketErrorWithValue<TicketErroreVendita>>;
  erroriSistema: {
    [idSistema: string]: TicketErroreSistema;
  };
};

export type TicketErrorWithValue<T extends TicketErrore | TicketErroreVendita> = {
  type: T;
  value?: number;
};

export type CarrelloScommesseCountList = Array<CarrelloScommesseCount>;

type SportTicketAction = {
  [K in keyof typeof sportTicketSlice.actions]: ReturnType<(typeof sportTicketSlice.actions)[K]>;
}[keyof typeof sportTicketSlice.actions];

type VirtualTicketAction = {
  [K in keyof typeof virtualTicketSlice.actions]: ReturnType<(typeof virtualTicketSlice.actions)[K]>;
}[keyof typeof virtualTicketSlice.actions];

type IppicaTicketAction = {
  [K in keyof typeof ippicaTicketSlice.actions]: ReturnType<(typeof ippicaTicketSlice.actions)[K]>;
}[keyof typeof ippicaTicketSlice.actions];

export type CarrelloActions = SportTicketAction | VirtualTicketAction | IppicaTicketAction;

export type Sistema = {
  id: string; // è la key Sistema
  numScommesseSelezionabiliCorrente: number;
  numScommesseSelezionabiliMassimo: number;
  numCombinazioni: number;
  importo: number;
  possibileVincitaMin: number; // Min per combinazione
  possibileVincitaMax: number; // Max per combinazione
  stakePerBet?: number;
};
