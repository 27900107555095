/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-61.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina61 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-61', className].join(' ');
  return (
    <svg
      id="disciplina_tiro-a-segno"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M12 13.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3ZM24 12a.797.797 0 0 1-.797.797H21.17a9.215 9.215 0 0 1-8.367 8.367v2.04a.797.797 0 0 1-1.594 0v-2.04c-4.444-.384-7.988-3.928-8.372-8.367H.796a.797.797 0 0 1 0-1.594h2.035c.39-4.439 3.933-7.983 8.372-8.367V.796a.797.797 0 0 1 1.594 0v2.04a9.215 9.215 0 0 1 8.367 8.367h2.04A.797.797 0 0 1 24 12Zm-19.56-.797h2.624a5.012 5.012 0 0 1 4.134-4.134V4.444a7.611 7.611 0 0 0-6.759 6.76Zm6.763 5.733a5.006 5.006 0 0 1-4.134-4.134H4.444a7.625 7.625 0 0 0 6.764 6.764v-2.63h-.005Zm1.594-2.138v.507a3.418 3.418 0 0 0 2.508-2.508h-.507a.797.797 0 0 1 0-1.594h.507a3.418 3.418 0 0 0-2.508-2.508v.507a.797.797 0 0 1-1.594 0v-.507a3.418 3.418 0 0 0-2.508 2.508h.507a.797.797 0 0 1 0 1.594h-.507a3.418 3.418 0 0 0 2.508 2.508v-.507a.797.797 0 0 1 1.594 0Zm6.764-2.001h-2.625a5.012 5.012 0 0 1-4.134 4.134v2.625a7.611 7.611 0 0 0 6.759-6.76Zm0-1.594a7.625 7.625 0 0 0-6.764-6.764v2.625a5.012 5.012 0 0 1 4.134 4.134h2.63v.005Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina61;
