/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\avatar.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Avatar = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-avatar', className].join(' ');
  return (
    <svg
      id="avatar"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#clip0_8071_490407)">
        <path
          d="M16.0003 31.7702C24.7087 31.7702 31.7683 24.7107 31.7683 16.0023C31.7683 7.29391 24.7087 0.234375 16.0003 0.234375C7.29196 0.234375 0.232422 7.29391 0.232422 16.0023C0.232422 24.7107 7.29196 31.7702 16.0003 31.7702Z"
          fill="#593F5A"
        />
        <path
          d="M16 31.9983C7.17755 31.9983 0 24.8208 0 16C0 7.17925 7.17755 0 16 0C24.8224 0 32 7.17755 32 16C32 24.8224 24.8224 32 16 32V31.9983ZM16 0.462467C7.43166 0.462467 0.462467 7.43166 0.462467 16C0.462467 24.5683 7.43166 31.5375 16 31.5375C24.5683 31.5375 31.5375 24.5683 31.5375 16C31.5375 7.43166 24.5666 0.462467 16 0.462467V0.462467Z"
          fill="#F6B40E"
        />
        <path
          d="M15.9997 30.0954C8.22585 30.0954 1.90039 23.7699 1.90039 15.9961C1.90039 8.22221 8.22416 1.89844 15.9997 1.89844C23.7752 1.89844 30.099 8.2239 30.099 15.9977C30.099 23.7716 23.7736 30.0971 15.9997 30.0971V30.0954ZM15.9997 2.20505C8.39526 2.20675 2.2087 8.3933 2.2087 15.9977C2.2087 23.6022 8.39526 29.7888 15.9997 29.7888C23.6041 29.7888 29.7907 23.6022 29.7907 15.9977C29.7907 8.3933 23.6025 2.20675 15.9997 2.20675V2.20505Z"
          fill="black"
        />
        <path
          d="M15.9998 27.7653C22.4956 27.7653 27.7614 22.4995 27.7614 16.0038C27.7614 9.50802 22.4956 4.24219 15.9998 4.24219C9.50411 4.24219 4.23828 9.50802 4.23828 16.0038C4.23828 22.4995 9.50411 27.7653 15.9998 27.7653Z"
          fill="#F2EED1"
        />
        <path
          d="M15.9998 27.9934C9.38801 27.9934 4.00781 22.6132 4.00781 16.0015C4.00781 9.38971 9.38632 4.00781 15.9998 4.00781C22.6132 4.00781 27.9917 9.38801 27.9917 15.9998C27.9917 22.6115 22.6115 27.9917 15.9998 27.9917V27.9934ZM15.9998 4.47197C9.64212 4.47197 4.47028 9.64381 4.47028 16.0015C4.47028 22.3591 9.64212 27.5309 15.9998 27.5309C22.3574 27.5309 27.5293 22.3591 27.5293 16.0015C27.5293 9.64381 22.3574 4.47028 15.9998 4.47028V4.47197Z"
          fill="black"
        />
        <path
          d="M15.9991 4.23438C14.942 4.23438 13.9172 4.37498 12.9414 4.63586C13.1379 6.14862 14.4321 7.31749 15.9991 7.31749C17.5661 7.31749 18.8586 6.14862 19.0568 4.63586C18.0811 4.37328 17.0579 4.23438 15.9991 4.23438Z"
          fill="#E16251"
        />
        <path
          d="M15.9993 7.55127C14.3392 7.55127 12.9264 6.31125 12.7129 4.66806C12.6977 4.55286 12.7705 4.44445 12.8824 4.41565C14.9084 3.87187 17.0903 3.87187 19.1163 4.41565C19.2281 4.44614 19.301 4.55456 19.2857 4.66806C19.0723 6.31125 17.6578 7.55127 15.9993 7.55127V7.55127ZM13.2042 4.80866C13.4719 6.12152 14.639 7.08881 15.9993 7.08881C17.3596 7.08881 18.5268 6.12152 18.7945 4.80866C16.9717 4.35466 15.027 4.35466 13.2059 4.80866H13.2042Z"
          fill="black"
        />
        <path
          d="M7.68166 7.68335C6.9346 8.43042 6.30781 9.2554 5.80469 10.1295C7.01422 11.0595 8.75567 10.9714 9.86355 9.86356C10.9714 8.75567 11.0595 7.01422 10.1295 5.80469C9.2554 6.3095 8.43042 6.9346 7.68335 7.68166L7.68166 7.68335Z"
          fill="#E16251"
        />
        <path
          d="M7.68011 10.9953C6.97201 10.9953 6.26053 10.77 5.66423 10.3109C5.57275 10.2398 5.54734 10.1127 5.60494 10.0128C6.12839 9.10309 6.77381 8.26455 7.51918 7.51918C8.26455 6.77381 9.10478 6.13009 10.0128 5.60494C10.1127 5.54734 10.2415 5.57275 10.3109 5.66423C11.3223 6.97879 11.1986 8.85407 10.0263 10.0263C9.38429 10.6684 8.5339 10.9953 7.68011 10.9953V10.9953ZM6.11145 10.0636C7.2295 10.8022 8.73887 10.6616 9.69938 9.69938C10.6616 8.73718 10.8022 7.2295 10.0636 6.11145C9.25894 6.59594 8.51357 7.17868 7.84613 7.84613C7.17868 8.51357 6.59594 9.25894 6.11145 10.0636Z"
          fill="black"
        />
        <path
          d="M4.23828 15.9991C4.23828 17.0562 4.37888 18.0811 4.63976 19.0568C6.15252 18.8603 7.32139 17.5661 7.32139 15.9991C7.32139 14.4321 6.15252 13.1396 4.63976 12.9414C4.37719 13.9172 4.23828 14.9403 4.23828 15.9991Z"
          fill="#E16251"
        />
        <path
          d="M4.63942 19.2874C4.53609 19.2874 4.44292 19.218 4.41581 19.1163C4.14307 18.1033 4.00586 17.0547 4.00586 15.9993C4.00586 14.944 4.14307 13.8954 4.41581 12.8824C4.4463 12.7705 4.55472 12.6977 4.66822 12.7129C6.31142 12.9264 7.55144 14.3392 7.55144 15.9993C7.55144 17.6595 6.31142 19.0723 4.66822 19.2857C4.65806 19.2857 4.64789 19.2874 4.63773 19.2874H4.63942ZM4.81052 13.2042C4.58352 14.1156 4.46833 15.0541 4.46833 15.9993C4.46833 16.9446 4.58352 17.8831 4.81052 18.7945C6.12338 18.5268 7.09067 17.3596 7.09067 15.9993C7.09067 14.639 6.12338 13.4719 4.81052 13.2042V13.2042Z"
          fill="black"
        />
        <path
          d="M7.6814 24.3153C8.42846 25.0624 9.25345 25.6892 10.1276 26.1923C11.0576 24.9828 10.9695 23.2413 9.8616 22.1334C8.75371 21.0255 7.01226 20.9375 5.80273 21.8675C6.30755 22.7416 6.93264 23.5666 7.67971 24.3136L7.6814 24.3153Z"
          fill="#E16251"
        />
        <path
          d="M10.128 26.4241C10.089 26.4241 10.0484 26.4139 10.0128 26.3936C9.10309 25.8701 8.26455 25.2247 7.51918 24.4793C6.77381 23.734 6.12839 22.8937 5.60494 21.9857C5.54734 21.8858 5.57275 21.7571 5.66423 21.6876C6.97709 20.6763 8.85407 20.7999 10.0263 21.9722C11.2003 23.1462 11.3223 25.0214 10.3109 26.3343C10.2652 26.3936 10.1974 26.4241 10.128 26.4241V26.4241ZM7.84443 24.1524C8.51188 24.8198 9.25724 25.4026 10.0619 25.8871C10.8005 24.769 10.6599 23.2597 9.69769 22.2991C8.73718 21.3386 7.22781 21.1963 6.10976 21.9349C6.59425 22.7396 7.17699 23.485 7.84443 24.1524V24.1524Z"
          fill="black"
        />
        <path
          d="M15.9991 27.7589C17.0562 27.7589 18.0811 27.6183 19.0568 27.3574C18.8603 25.8447 17.5661 24.6758 15.9991 24.6758C14.4321 24.6758 13.1396 25.8447 12.9414 27.3574C13.9172 27.62 14.9403 27.7589 15.9991 27.7589Z"
          fill="#E16251"
        />
        <path
          d="M15.9993 27.9909C14.944 27.9909 13.8954 27.8537 12.8824 27.5809C12.7705 27.5504 12.6977 27.442 12.7129 27.3285C12.9264 25.6853 14.3392 24.4453 15.9993 24.4453C17.6595 24.4453 19.0723 25.6853 19.2857 27.3285C19.301 27.4437 19.2281 27.5521 19.1163 27.5809C18.1033 27.8537 17.0547 27.9909 15.9993 27.9909V27.9909ZM13.2042 27.1862C15.027 27.6402 16.9717 27.6402 18.7928 27.1862C18.5251 25.8734 17.3579 24.9061 15.9977 24.9061C14.6374 24.9061 13.4702 25.8734 13.2025 27.1862H13.2042Z"
          fill="black"
        />
        <path
          d="M24.3173 24.3175C25.0643 23.5705 25.6911 22.7455 26.1942 21.8714C24.9847 20.9414 23.2433 21.0294 22.1354 22.1373C21.0275 23.2452 20.9394 24.9867 21.8694 26.1962C22.7435 25.6914 23.5685 25.0663 24.3156 24.3192L24.3173 24.3175Z"
          fill="#E16251"
        />
        <path
          d="M21.8706 26.4246C21.8011 26.4246 21.7316 26.3925 21.6876 26.3349C20.6763 25.0203 20.7999 23.145 21.9722 21.9728C23.1462 20.7988 25.0214 20.6768 26.3343 21.6882C26.4258 21.7593 26.4512 21.8864 26.3936 21.9863C25.8701 22.896 25.2247 23.7345 24.4793 24.4799C23.734 25.2253 22.8937 25.8707 21.9857 26.3942C21.9502 26.4145 21.9095 26.4246 21.8706 26.4246V26.4246ZM24.3184 21.4646C23.5832 21.4646 22.8514 21.7458 22.2991 22.298C21.3369 23.2602 21.1963 24.7679 21.9349 25.886C22.7396 25.4015 23.485 24.8187 24.1524 24.1513C24.8198 23.4838 25.4026 22.7385 25.8871 21.9338C25.4111 21.6187 24.8639 21.4646 24.3184 21.4646V21.4646Z"
          fill="black"
        />
        <path
          d="M27.7608 15.9991C27.7608 14.942 27.6202 13.9172 27.3594 12.9414C25.8466 13.1379 24.6777 14.4321 24.6777 15.9991C24.6777 17.5661 25.8466 18.8586 27.3594 19.0568C27.6219 18.0811 27.7608 17.0579 27.7608 15.9991Z"
          fill="#E16251"
        />
        <path
          d="M27.359 19.2874C27.3489 19.2874 27.3387 19.2874 27.3285 19.2857C25.6853 19.0723 24.4453 17.6595 24.4453 15.9993C24.4453 14.3392 25.6853 12.9264 27.3285 12.7129C27.4437 12.6977 27.5521 12.7705 27.5809 12.8824C27.8537 13.8954 27.9909 14.944 27.9909 15.9993C27.9909 17.0547 27.8537 18.1033 27.5809 19.1163C27.5538 19.218 27.4607 19.2874 27.3573 19.2874H27.359ZM27.1879 13.2042C25.8751 13.4719 24.9078 14.639 24.9078 15.9993C24.9078 17.3596 25.8751 18.5268 27.1879 18.7945C27.4149 17.8831 27.5301 16.9446 27.5301 15.9993C27.5301 15.0541 27.4149 14.1156 27.1879 13.2042Z"
          fill="black"
        />
        <path
          d="M24.3156 7.68166C23.5685 6.9346 22.7435 6.30781 21.8694 5.80469C20.9394 7.01422 21.0275 8.75567 22.1354 9.86355C23.2433 10.9714 24.9847 11.0595 26.1943 10.1295C25.6894 9.2554 25.0643 8.43042 24.3173 7.68335L24.3156 7.68166Z"
          fill="#E16251"
        />
        <path
          d="M24.317 10.9953C23.4632 10.9953 22.6112 10.6684 21.9708 10.0263C20.7969 8.85237 20.6749 6.97709 21.6862 5.66423C21.7574 5.57275 21.8844 5.54734 21.9844 5.60494C22.8941 6.12839 23.7326 6.77381 24.478 7.51918C25.2233 8.26455 25.8671 9.10478 26.3922 10.0128C26.4498 10.1127 26.4244 10.2415 26.3329 10.3109C25.7366 10.77 25.0251 10.9953 24.317 10.9953ZM21.9335 6.11145C21.195 7.2295 21.3356 8.73887 22.2978 9.69938C23.2583 10.6599 24.7676 10.8022 25.8857 10.0636C25.4012 9.25894 24.8185 8.51357 24.151 7.84613C23.4836 7.17868 22.7382 6.59594 21.9335 6.11145V6.11145Z"
          fill="black"
        />
        <path
          d="M18.2908 13.6303C18.2908 13.6303 18.284 13.6303 18.2823 13.6303C18.5194 13.2322 18.6567 12.7698 18.6567 12.2751C18.6567 10.8081 17.4675 9.61719 15.9987 9.61719C14.5317 9.61719 13.3408 10.8064 13.3408 12.2751C13.3408 12.7714 13.4797 13.2339 13.7152 13.6303C13.7118 13.6303 13.7084 13.6303 13.7067 13.6303C12.2397 13.6303 11.0488 14.8195 11.0488 16.2882C11.0488 17.7553 12.238 18.9461 13.7067 18.9461C14.2116 18.9461 14.6808 18.8038 15.0823 18.5599L13.7067 22.3799H18.2908L16.9152 18.5599C17.3167 18.8038 17.7859 18.9461 18.2908 18.9461C19.7578 18.9461 20.9487 17.7569 20.9487 16.2882C20.9487 14.8212 19.7595 13.6303 18.2908 13.6303Z"
          fill="#593F5A"
        />
        <path
          d="M18.2907 22.6119H13.7067C13.6321 22.6119 13.561 22.5746 13.5169 22.5137C13.4729 22.4527 13.4627 22.3731 13.4881 22.3019L14.6739 19.0087C14.3639 19.1189 14.037 19.1764 13.7067 19.1764C12.1143 19.1764 10.8184 17.8805 10.8184 16.2881C10.8184 14.816 11.9246 13.598 13.3492 13.4219C13.1917 13.0594 13.1104 12.6697 13.1104 12.275C13.1104 10.6826 14.4063 9.38672 15.9987 9.38672C17.591 9.38672 18.887 10.6826 18.887 12.275C18.887 12.6697 18.8057 13.0594 18.6481 13.4219C20.0728 13.598 21.179 14.816 21.179 16.2881C21.179 17.8805 19.8831 19.1764 18.2907 19.1764C17.9586 19.1764 17.6317 19.1189 17.3234 19.0087L18.5092 22.3019C18.5346 22.3731 18.5244 22.451 18.4804 22.5137C18.4364 22.5763 18.3669 22.6119 18.2907 22.6119ZM14.0353 22.1495H17.9603L16.6966 18.6378C16.6627 18.5463 16.6915 18.4429 16.7678 18.3803C16.844 18.3176 16.9507 18.3108 17.0337 18.3616C17.4115 18.592 17.8451 18.714 18.289 18.714C19.6273 18.714 20.7148 17.6247 20.7148 16.2881C20.7148 14.9516 19.6289 13.864 18.2941 13.8623C18.289 13.8623 18.2839 13.8623 18.2788 13.8623C18.1958 13.8623 18.1196 13.8183 18.0772 13.7454C18.0349 13.6726 18.0366 13.5845 18.0789 13.5133C18.3025 13.1373 18.4211 12.7087 18.4211 12.275C18.4211 10.9367 17.3319 9.84919 15.9953 9.84919C14.6587 9.84919 13.5694 10.9384 13.5694 12.275C13.5694 12.7087 13.688 13.1373 13.9116 13.5133C13.954 13.5845 13.9557 13.6743 13.9133 13.7454C13.8693 13.8234 13.7778 13.8674 13.6897 13.8606C12.365 13.8606 11.2757 14.9499 11.2757 16.2881C11.2757 17.6264 12.365 18.714 13.7016 18.714C14.1437 18.714 14.5774 18.592 14.9568 18.3616C15.0399 18.3108 15.1466 18.3176 15.2228 18.3803C15.299 18.4429 15.3278 18.5446 15.294 18.6378L14.0302 22.1495H14.0353Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_8071_490407">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Avatar;
