/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\media.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Media = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-media', className].join(' ');
  return (
    <svg id="media" viewBox="0 0 24 24" focusable="false" className={componentClassName} {...attribs}>
      <path d="M9.75 11.25V17.25L15 14.25L9.75 11.25Z" fill="currentColor" />
      <path
        d="M19.5 21H4.5C4.1023 20.9996 3.721 20.8414 3.43978 20.5602C3.15856 20.279 3.0004 19.8977 3 19.5V9C3.0004 8.6023 3.15856 8.221 3.43978 7.93978C3.721 7.65856 4.1023 7.5004 4.5 7.5H19.5C19.8977 7.5004 20.279 7.65856 20.5602 7.93978C20.8414 8.221 20.9996 8.6023 21 9V19.5C20.9996 19.8977 20.8414 20.279 20.5602 20.5602C20.279 20.8414 19.8977 20.9996 19.5 21ZM4.5 9V19.5H19.5V9H4.5ZM4.5 4.5H19.5V6H4.5V4.5ZM6 1.5H18V3H6V1.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Media;
