/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-bb.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneBb = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-bb', className].join(' ');
  return (
    <svg
      id="nazione-bb"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <path d="M0-.006h16V16H0V-.006Z" fill="#00267F" />
        <path d="M5.334-.006h5.332V16H5.334V-.006Z" fill="#FFC726" />
        <path
          d="M8 5.416c-.172.472-.35.965-.728 1.343.148-.045.301-.07.456-.071v1.987l-.562.084c-.022 0-.029-.03-.029-.075a7.612 7.612 0 0 0-.365-1.675c-.013-.071-.225-.353-.063-.303.022 0 .241.094.207.05a2.125 2.125 0 0 0-1.16-.6c-.037-.01-.062.01-.028.053.563.866 1.035 1.888 1.031 3.1.22 0 .75-.128.97-.128v1.403h.274l.063-3.918L8 5.416Z"
          fill="#000"
        />
        <path
          d="M8 5.416c.172.472.35.965.728 1.343a1.616 1.616 0 0 0-.456-.071v1.987l.562.084c.022 0 .029-.03.029-.075.058-.57.18-1.132.365-1.675.013-.071.225-.353.063-.303-.022 0-.241.094-.207.05.314-.316.72-.526 1.16-.6.037-.01.062.01.028.053-.563.866-1.034 1.888-1.031 3.1-.22 0-.75-.128-.97-.128v1.403h-.274l-.063-3.918L8 5.416Z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="16" height="16" rx="8" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NazioneBb;
