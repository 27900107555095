/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-8.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco8 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-8', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="41.396"
      viewBox="0 0 35.482 41.396"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <g transform="translate(13.306 16.263)">
          <path
            className="a"
            d="M244.056,507.133a8.139,8.139,0,0,1-5.76-2.381l-8.083-8.188a.739.739,0,0,1,0-1.042l.344-.344a4.442,4.442,0,0,1,6.273,0l.56.56V484.957a2.957,2.957,0,1,1,5.914,0v5.381l5.838,1.946a4.429,4.429,0,0,1,3.033,4.207v2.283a8.276,8.276,0,0,1-7.552,8.34h0C244.435,507.127,244.245,507.133,244.056,507.133Zm.517-.757h0Zm-12.785-10.32,7.556,7.655a6.678,6.678,0,0,0,5.177,1.927,6.793,6.793,0,0,0,6.176-6.864v-2.283a2.952,2.952,0,0,0-2.022-2.8l-6.343-2.115a.737.737,0,0,1-.506-.7v-5.914a1.478,1.478,0,1,0-2.957,0v12.567a.739.739,0,0,1-1.262.523l-1.821-1.822A2.962,2.962,0,0,0,231.788,496.056Z"
            transform="translate(-230 -482)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M232.653,480.088h-2.218v-6.653a.739.739,0,0,0-.739-.739h-2.957a.739.739,0,1,0,0,1.478h2.218v5.914h-2.218a.739.739,0,1,0,0,1.478h5.914a.739.739,0,1,0,0-1.478Zm-3.326-8.871a1.109,1.109,0,1,0-1.109-1.109A1.11,1.11,0,0,0,229.326,471.218Z"
          transform="translate(-215.651 -462.347)"
          fill="currentColor"
        />
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M224.967,488.036l-.06,0a14.04,14.04,0,1,1,15.184-13.989A13.925,13.925,0,0,1,240,475.6a.739.739,0,1,1-1.469-.163,12.565,12.565,0,1,0-13.506,11.122.739.739,0,0,1-.058,1.476Z"
            transform="translate(-212 -460)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco8;
