/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-418.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco418 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-418', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.44"
      height="38.439"
      viewBox="0 0 38.44 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M164.223,391.523l-3.917-3.918a.74.74,0,0,0-1.006-.037,3.138,3.138,0,0,1-4.429-4.428.738.738,0,0,0-.037-1.005l-3.917-3.918a.739.739,0,0,0-1.045,0l-23.655,23.655a.738.738,0,0,0,0,1.045l3.917,3.918a.74.74,0,0,0,1.006.037,3.138,3.138,0,0,1,4.429,4.428.738.738,0,0,0,.037,1.005l3.917,3.918a.739.739,0,0,0,1.045,0l23.655-23.655A.738.738,0,0,0,164.223,391.523Zm-24.178,23.132-2.946-2.946a4.613,4.613,0,0,0-6.369-6.369l-2.946-2.946,22.61-22.61,2.946,2.946a4.613,4.613,0,0,0,6.369,6.369l2.946,2.946Zm10.563-23.874a.757.757,0,0,0-.214.525.771.771,0,0,0,.214.525.75.75,0,0,0,1.05,0,.771.771,0,0,0,.214-.525.757.757,0,0,0-.214-.525A.744.744,0,0,0,150.608,390.781Zm-1.693-.954a.742.742,0,0,0,.525-1.264.744.744,0,0,0-1.05,0,.739.739,0,0,0,.525,1.264ZM146.7,387.61a.742.742,0,0,0,.525-1.264.744.744,0,0,0-1.05,0,.739.739,0,0,0,.525,1.264Zm8.346,7.607a.757.757,0,0,0-.214.525.77.77,0,0,0,.214.525.75.75,0,0,0,1.05,0,.77.77,0,0,0,.214-.525.757.757,0,0,0-.214-.525A.744.744,0,0,0,155.044,395.216ZM152.826,393a.757.757,0,0,0-.214.525.771.771,0,0,0,.214.525.75.75,0,0,0,1.05,0,.771.771,0,0,0,.214-.525.757.757,0,0,0-.214-.525A.744.744,0,0,0,152.826,393Zm-7.084-2.215a.738.738,0,0,0-1.045,0l-8.871,8.871a.739.739,0,1,0,1.045,1.045l8.871-8.871A.738.738,0,0,0,145.742,390.783Zm2.957,2.957a.738.738,0,0,0-1.045,0l-8.871,8.871a.739.739,0,1,0,1.045,1.045l8.871-8.871A.738.738,0,0,0,148.7,393.74Zm1.912,2.957-8.871,8.871a.739.739,0,1,0,1.045,1.045l8.871-8.871a.739.739,0,1,0-1.045-1.045Z"
        transform="translate(-126 -378)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco418;
