/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-401.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco401 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-401', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="32.526"
      viewBox="0 0 41.396 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M246.226,227.359l-4.462-11.474A2.938,2.938,0,0,0,239.009,214H218.387a2.938,2.938,0,0,0-2.756,1.885l-4.462,11.474A3.69,3.69,0,0,0,208,231v11.827a3.7,3.7,0,0,0,3.7,3.7h34a3.7,3.7,0,0,0,3.7-3.7V231A3.69,3.69,0,0,0,246.226,227.359ZM217.01,216.421a1.47,1.47,0,0,1,1.378-.942h20.621a1.468,1.468,0,0,1,1.377.942l4.234,10.885H212.777Zm30.908,26.409a2.22,2.22,0,0,1-2.218,2.218h-34a2.22,2.22,0,0,1-2.218-2.218V231a2.22,2.22,0,0,1,2.218-2.218H245.7A2.22,2.22,0,0,1,247.918,231Zm-8.131-19.959H217.61a.739.739,0,1,0,0,1.478h22.177a.739.739,0,1,0,0-1.478Zm-20.7-2.957h19.22a.739.739,0,0,0,0-1.478h-19.22a.739.739,0,0,0,0,1.478Zm15.524,11.827H222.784a2.957,2.957,0,0,0,0,5.914h11.828a2.957,2.957,0,1,0,0-5.914Zm0,4.435H222.784a1.478,1.478,0,0,1,0-2.957h11.828a1.478,1.478,0,1,1,0,2.957Z"
        transform="translate(-208 -214)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco401;
