/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-97.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina97 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-97', className].join(' ');
  return (
    <svg
      id="disciplina_manifestazioni-di-spettacolo"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="m8.043 12.904.51-1.793c-1.4.696-2.053 1.627-2.32 1.947-.298.353.37-2.942 2.17-3.537.208-.07.424-.118.642-.145l.887-3.126a2.06 2.06 0 0 0-.137-.008c-.596-.016-1.554.005-1.554.005s.328-1.232 1.524-1.143c.363.027.677.241.922.493.548-.135 1.444-.29 2.644-.27-.724-2.023-1.574-4.144-1.574-4.144s-2.555-.72-6.46.695C1.349 3.308 0 5.518 0 5.518l2.234 6.242s1.167 3.247 5.867 4.13c-.403-1.766-.058-2.986-.058-2.986ZM4.806 8.008c-.328.499-.78 1.302-.78 1.302S3.45 8.206 3.974 7.48c.524-.724.96-.882 1.951-.551.1.035-.79.58-1.119 1.078Zm12.95-1.453c-4.464-1.25-7.092-.13-7.092-.13l-1.998 7.039s-1.202 4.242 3.533 8.34c.01.009.898.79 1.36.767.765-.037 1.926-.1 4.271-1.1.941-.4 1.883-1.1 2.998-2.143.446-.418.81-.904 1.39-2.722.806-2.535 1.781-6.53 1.781-6.53s-1.832-2.285-6.243-3.519v-.002Zm-4.5 3.78c.984.043 1.68 1.346 1.694 1.462.014.115-1.014-.375-1.673-.375s-1.672.072-1.672.072.666-1.201 1.651-1.158v-.002Zm1.406 8.006c-2.07-.622-2.867-2.722-3.055-4.184 0 0 1.26 1.954 3.345 2.432s3.518-.086 4.025-.231c.507-.145-2.246 2.608-4.316 1.985l.001-.002Zm5.925-4.299c-.021.115-.854-.664-1.482-.862-.629-.197-1.617-.433-1.617-.433s.996-.946 1.922-.61c.926.338 1.197 1.792 1.176 1.907l.001-.002Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina97;
