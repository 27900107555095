import { EventsType, useIdleTimer, workerTimers } from 'react-idle-timer';
import { FORCE_LOGOUT, IDLE_KEY_NAME, IS_LOGGED } from 'utility/constant';
import Persist, { StorageKind } from 'lib/persist';
import React, { useCallback, useEffect, useRef } from 'react';
import { setAlertMessage } from 'features/dashboard/dashboardSlice';
import { useAppDispatch } from 'lib/centralStore';

import { IconsEnum } from 'components/Icons';
import { getSessionId, isTruthy } from 'utility/functions';
import { signOut } from 'features/auth/authActions';
import { useGetLabelByKey } from 'hooks/useLingUI';
import { useSession } from 'next-auth/react';
import { utcNow } from 'utility/date';
import { AnyAction } from '@reduxjs/toolkit';

const cookie = Persist(StorageKind.cookie);
const localStorage = Persist(StorageKind.local);

// minutes
const IDLE_MAX_TIME = 20;

// milliseconds
const IDLE_INERVALL = 15000; // 15 seconds

const events: Array<EventsType> = [
  'wheel',
  'focus',
  'keydown',
  'mousemove',
  'mousedown',
  'touchmove',
  'touchstart',
  'mousewheel',
  'MSPointerDown',
  'MSPointerMove',
  'DOMMouseScroll',
];

export const WrapperInactiveLogout = () => {
  const dispatch = useAppDispatch();

  const { status } = useSession();

  const inactiveLogoutMessageCms = useGetLabelByKey('inactive-logout-message');

  const myUIDRef = useRef('');
  const resetRef = useRef<Function | null>();
  const messageRef = useRef<Function | null>();
  const debounceRef = useRef<NodeJS.Timeout | null>();
  const activatingRef = useRef(false);

  const killAll = useCallback(
    (isInactive = false, doNotify = true) => {
      try {
        dispatch(signOut({ isInactive }));
      } finally {
        if (doNotify) {
          dispatch(
            setAlertMessage({
              icon: IconsEnum.SUCCESS,
              color: 'var(--color-success)',
              message: inactiveLogoutMessageCms!,
              animation: false,
            })
          );
        }
      }
    },
    [inactiveLogoutMessageCms, dispatch]
  );

  const onIdle = useCallback(async () => {
    const now = utcNow().getTime();
    const expiration = Number(cookie.getItem(IDLE_KEY_NAME));
    if (typeof resetRef.current === 'function') {
      resetRef.current();
    }

    console.log('onIdle', expiration, now, expiration - now, IDLE_INERVALL);

    if (expiration - now > IDLE_INERVALL) {
      const duration = Math.round((expiration - now) * 0.001);
      console.log(`WrapperInactiveLogout: still ${duration}" - raised`);
      if (typeof messageRef.current === 'function') {
        messageRef.current({ type: 'notify', payload: `still ${duration}" - detect` }, false);
      }
    } else {
      if (typeof messageRef.current === 'function') {
        messageRef.current({ type: 'sign-out', payload: { isInactive: true, doNotify: true } }, true);
      } else {
        killAll(true, true);
      }
    }
  }, [killAll]);

  const doInit = useCallback(
    (doResume = false) => {
      const dt = utcNow();
      const nextExp = dt.setMinutes(dt.getMinutes() + IDLE_MAX_TIME);

      const expiration = (doResume ? cookie.getItem(IDLE_KEY_NAME) : undefined) ?? `${nextExp}`;

      cookie.setItem(IDLE_KEY_NAME, expiration, true);

      if (typeof messageRef.current === 'function') {
        messageRef.current(
          {
            type: 'notify',
            payload: `${doResume ? 'resume,' : 'reset, new'} expiration: ${new Date(
              Number(expiration)
            ).toISOString()}.`,
          },
          true
        );
      }

      onIdle();
    },
    [onIdle]
  );

  const onActive = useCallback(
    async (ev) => {
      if (!activatingRef.current) {
        activatingRef.current = true;
        await onIdle();
        activatingRef.current = false;
      }

      // keep timer's ownership and reset expiration
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }

      debounceRef.current = setTimeout(() => {
        console.log(`WrapperInactiveLogout: a ${ev?.type} event has been raised`);
        if (typeof messageRef.current === 'function') {
          messageRef.current({ type: 'notify', payload: `a ${ev?.type} event has been received` }, false);
        }

        const doReset = isTruthy(`${ev?.type ?? ''}`.length);
        doInit(!doReset);
      }, 500);
    },
    [doInit, onIdle]
  );

  const onMessage = ({ type, payload }: AnyAction) => {
    switch (type) {
      case 'notify': {
        console.log(`WrapperInactiveLogout: ${payload}`);
        break;
      }
      case 'sign-out': {
        const { isInactive, doNotify } = payload ?? {};
        killAll(isInactive ?? true, doNotify ?? true);
        break;
      }
    }
  };

  const { reset, message } = useIdleTimer({
    name: IDLE_KEY_NAME,
    events: [],
    timers: workerTimers,
    timeout: IDLE_INERVALL,
    crossTab: true,
    disabled: status !== 'authenticated', //start only on authenticated user
    syncTimers: 500,
    startOnMount: true,
    leaderElection: true,
    promptBeforeIdle: 0,
    onIdle,
    onActive,
    onMessage,
  });

  useEffect(() => {
    resetRef.current = reset;
    messageRef.current = message;
  });

  useEffect(() => {
    if (status === 'unauthenticated') {
      cookie.removeItem(IDLE_KEY_NAME);
      return;
    }

    // fix lack of activation from timer
    events.forEach(function (ev) {
      document.addEventListener(ev, onActive, { passive: true });
    });

    doInit(true);

    myUIDRef.current = getSessionId();

    return () => {
      events.forEach(function (ev) {
        document.removeEventListener(ev, onActive);
      });
    };
  }, [status, doInit, onActive]);

  useEffect(() => {
    if (isTruthy(cookie.getItem(IS_LOGGED)) && isTruthy(localStorage.getItem(FORCE_LOGOUT))) {
      localStorage.removeItem(FORCE_LOGOUT);
    } else if (!isTruthy(cookie.getItem(IS_LOGGED)) && isTruthy(localStorage.getItem(FORCE_LOGOUT))) {
      if (typeof messageRef.current === 'function') {
        messageRef.current({ type: 'sign-out', payload: { isInactive: true, doNotify: false } }, false);
      }
      killAll(true, false);
      localStorage.removeItem(FORCE_LOGOUT);
    }
  }, [killAll]);

  return <React.Fragment key={IDLE_KEY_NAME} />;
};
