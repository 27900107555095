/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-109.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco109 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-109', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="35.482"
      viewBox="0 0 41.396 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <g transform="translate(36.222 12.567)">
          <path
            className="a"
            d="M426.478,320.392H425v-1.478h1.478a2.218,2.218,0,1,0,0-4.435H425V313h1.478a3.7,3.7,0,1,1,0,7.392Z"
            transform="translate(-425 -313)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M416.523,296a2.95,2.95,0,0,0-2.923,2.622c-.962.974-6.458,6.249-12.6,6.249v1.478c5.5,0,10.346-3.668,12.567-5.654v23.136c-2.221-1.986-7.064-5.654-12.567-5.654v1.478c6.143,0,11.638,5.275,12.6,6.249a2.949,2.949,0,0,0,5.88-.336V298.957A2.96,2.96,0,0,0,416.523,296ZM418,325.569a1.478,1.478,0,1,1-2.957,0V298.957a1.478,1.478,0,1,1,2.957,0Z"
          transform="translate(-382.52 -296)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M394.48,308H383.392a7.392,7.392,0,0,0,0,14.784v6.653a5.181,5.181,0,0,0,5.175,5.174,2.22,2.22,0,0,0,2.218-2.218V327.22h1.479a2.96,2.96,0,0,0,2.957-2.957V308.739A.739.739,0,0,0,394.48,308Zm-17,7.392a5.921,5.921,0,0,1,5.914-5.914h10.349v11.828H383.392A5.921,5.921,0,0,1,377.478,315.392Zm11.828,17a.74.74,0,0,1-.739.739,3.7,3.7,0,0,1-3.7-3.7v-6.653h4.435Zm2.957-6.653h-1.479v-2.957h2.957v1.479A1.48,1.48,0,0,1,392.263,325.741Z"
          transform="translate(-376 -299.129)"
          fill="currentColor"
        />
        <g transform="translate(5.914 11.827)">
          <path
            className="a"
            d="M384.739,320.871a.739.739,0,0,1-.739-.739v-7.392a.739.739,0,0,1,1.478,0v7.392A.739.739,0,0,1,384.739,320.871Z"
            transform="translate(-384 -312)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(2.957 14.045)">
          <path
            className="a"
            d="M380.739,319.435A.739.739,0,0,1,380,318.7v-2.957a.739.739,0,0,1,1.478,0V318.7A.739.739,0,0,1,380.739,319.435Z"
            transform="translate(-380 -315)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco109;
