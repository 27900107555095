/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-42.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco42 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-42', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="38.439"
      viewBox="0 0 35.482 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(16.263 17.741)">
        <path
          className="a"
          d="M495.61,254.7a.738.738,0,0,1-.561-.258l-8.871-10.349a.739.739,0,0,1,.561-1.22h5.174v-8.131a.739.739,0,0,1,.739-.739h5.914a.739.739,0,0,1,.739.739v8.131h5.174a.739.739,0,0,1,.561,1.22l-8.871,10.349A.738.738,0,0,1,495.61,254.7Zm-7.264-10.349,7.264,8.474,7.264-8.474h-4.307a.739.739,0,0,1-.739-.739v-8.131h-4.435v8.131a.739.739,0,0,1-.739.739Z"
          transform="translate(-486 -234)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M490.612,210H472.617A3.672,3.672,0,0,0,470,211.082L465.083,216A3.67,3.67,0,0,0,464,218.617V244a2.96,2.96,0,0,0,2.957,2.957h17a.739.739,0,0,0,0-1.478h-17A1.48,1.48,0,0,1,465.478,244V219.61a.74.74,0,0,1,.739-.739h3.7a2.96,2.96,0,0,0,2.957-2.957v-3.7a.74.74,0,0,1,.739-.739h17a1.48,1.48,0,0,1,1.479,1.478v12.567a.739.739,0,1,0,1.478,0V212.957A2.96,2.96,0,0,0,490.612,210Zm-19.22,5.914a1.48,1.48,0,0,1-1.478,1.478h-3.7a2.254,2.254,0,0,0-.39.039,2.2,2.2,0,0,1,.3-.382l4.92-4.922a2.2,2.2,0,0,1,.384-.3,2.137,2.137,0,0,0-.04.392Z"
        transform="translate(-464 -210)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M487.088,234.871H476.739a.739.739,0,0,0,0,1.478h10.349a.739.739,0,0,0,0-1.478Zm0-4.435H476.739a.739.739,0,0,0,0,1.478h10.349a.739.739,0,1,0,0-1.478Zm-10.349-2.957h10.349a.739.739,0,0,0,0-1.478H476.739a.739.739,0,0,0,0,1.478Z"
        transform="translate(-467.129 -214.173)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco42;
