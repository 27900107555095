import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FetchPayload, doFetch } from 'features/api/thunkUtils';
import { SearchResponseApi } from 'lib/api/searchResponseApi';
import { AUTOCOMPLETE_GAMES_ID, MOST_SEARCHED_ID, SearchState } from './searchTypes';

const initialState: SearchState = {};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetHints: (state) => {
      if (state.autocompleteGame) {
        state.autocompleteGame.hints = [];
        state.isAutocompleteGameLoading = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doFetch.pending.type, (state, { meta }: AnyAction) => {
      const { uid } = meta.arg;
      switch (uid) {
        case MOST_SEARCHED_ID: {
          state.isMostSearchedLoading = true;
          delete state.mostSearched;
          break;
        }
        case AUTOCOMPLETE_GAMES_ID: {
          state.isAutocompleteGameLoading = true;
          delete state.autocompleteGame;
          break;
        }
      }
    });
    builder.addCase(doFetch.rejected.type, (state, { meta }: AnyAction) => {
      const { uid } = meta.arg;
      switch (uid) {
        case MOST_SEARCHED_ID: {
          delete state.isMostSearchedLoading;
          break;
        }
        case AUTOCOMPLETE_GAMES_ID: {
          delete state.isAutocompleteGameLoading;
          break;
        }
      }
    });
    builder.addCase(doFetch.fulfilled.type, (state, action: PayloadAction<FetchPayload<SearchResponseApi>>) => {
      const { uid, data } = action.payload;
      switch (uid) {
        case MOST_SEARCHED_ID: {
          state.mostSearched = data;
          delete state.isMostSearchedLoading;
          break;
        }
        case AUTOCOMPLETE_GAMES_ID: {
          state.autocompleteGame = data;
          delete state.isAutocompleteGameLoading;
          break;
        }
      }
    });
  },
});

export const { resetHints } = searchSlice.actions;

export default searchSlice.reducer;
