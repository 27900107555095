/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-380.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco380 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-380', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="35.482"
      viewBox="0 0 41.396 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M79.838,301.914H77.7a.739.739,0,0,0,0,1.478h2.138a.079.079,0,0,1,.08.079V324.09H77.7a.739.739,0,1,0,0,1.478h2.218A1.48,1.48,0,0,0,81.4,324.09V303.471A1.56,1.56,0,0,0,79.838,301.914ZM67.351,324.09h-17v-20.7H64.394a.739.739,0,0,0,0-1.478H50.29a5.171,5.171,0,0,0-10.29.739v22.177h0A5.166,5.166,0,0,0,45.175,330H61.437a1.48,1.48,0,0,0,1.478-1.478v-2.957h4.435a.739.739,0,1,0,0-1.478ZM41.478,302.653a3.7,3.7,0,0,1,7.392,0v18.554a5.383,5.383,0,0,0-.434-.4,5.206,5.206,0,0,0-4.367-1.042,5.112,5.112,0,0,0-2.591,1.434Zm19.959,25.873H45.175a3.684,3.684,0,0,1-2.87-1.367,3.73,3.73,0,0,1-.742-3.133,3.672,3.672,0,0,1,2.808-2.809,3.731,3.731,0,0,1,3.133.743,3.684,3.684,0,0,1,1.367,2.87.739.739,0,0,0,.739.739H61.437ZM58.48,313.741H55.524a.739.739,0,1,0,0,1.478H57v.739a.739.739,0,1,0,1.478,0v-.739a2.218,2.218,0,1,0,0-4.435H57a.739.739,0,1,1,0-1.478h2.957a.739.739,0,1,0,0-1.478H58.48v-.739a.739.739,0,1,0-1.478,0v.739a2.218,2.218,0,0,0,0,4.435H58.48a.739.739,0,1,1,0,1.478Zm-5.175,6.653a.739.739,0,0,0,.739.739h7.392a.739.739,0,0,0,0-1.478H54.045A.739.739,0,0,0,53.306,320.394Zm14.045-.739H64.394a.739.739,0,0,0,0,1.478h2.957a.739.739,0,0,0,0-1.478ZM72.526,296a2.96,2.96,0,0,0-2.957,2.957v1.478h-.739a2.22,2.22,0,0,0-2.218,2.218v6.885a.739.739,0,1,0,1.478,0v-6.885a.74.74,0,0,1,.739-.739h.739v22.916a8.727,8.727,0,0,0,2.218,5.462v.452a.739.739,0,1,0,1.478,0v-.452a8.727,8.727,0,0,0,2.218-5.462V298.957A2.96,2.96,0,0,0,72.526,296ZM74,324.829a7.016,7.016,0,0,1-1.478,4.021,7.013,7.013,0,0,1-1.478-4.021v-1.478H74Zm0-2.957H71.047V301.914H74Zm0-21.437H71.047v-1.478a1.478,1.478,0,1,1,2.957,0Z"
        transform="translate(-40 -296)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco380;
