/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\trophy.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Trophy = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-trophy', className].join(' ');
  return (
    <svg
      id="trophy"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M512,106.67a21.33,21.33,0,0,0-21.33-21.33h-64v-64A21.33,21.33,0,0,0,405.33,0H106.67A21.33,21.33,0,0,0,85.33,21.33v64h-64A21.33,21.33,0,0,0,0,106.67c0,47.23,18.79,132.69,86.46,147,8.72,78.13,70.39,140.38,148.2,150.15v65.48H192A21.33,21.33,0,0,0,192,512H320a21.33,21.33,0,0,0,0-42.67H277.33V403.85c77.82-9.77,139.48-72,148.2-150.15C493.21,239.35,512,153.89,512,106.67ZM44.31,128h41v80.54C57.1,194.63,47.55,153.54,44.31,128Zm382.35,80.54V128h41C464.45,153.55,454.9,194.63,426.67,208.54Z"
      />
    </svg>
  );
};
export default Trophy;
