/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-ag.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneAg = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-ag', className].join(' ');
  return (
    <svg
      id="nazione_ag"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <mask id="b" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
          <path d="M.002 0h15.997v16.002H.002V.001Z" fill="#fff" />
        </mask>
        <g mask="url(#b)" fillRule="evenodd" clipRule="evenodd">
          <path d="M-3.75 0h23.852v15.985H-3.75V0Z" fill="#fff" />
          <path d="M-3.698.021h23.78v6.753h-23.78V.021Z" fill="#000" />
          <path d="M.666 6.351h15.78V9.91H.667V6.351Z" fill="#0061FF" />
          <path d="M20.085.056v15.946H8.193L20.085.056Zm-23.794 0v15.946H8.19L-3.71.056Z" fill="#E20000" />
          <path
            d="m13.762 6.355-2.385-.606 2.03-1.528-2.495.354 1.284-2.168-2.206 1.28.389-2.53-1.494 1.997L8.28.837l-.674 2.385-1.494-2.03.427 2.602L4.332 2.44l1.28 2.169-2.42-.39 1.993 1.494-2.492.64h11.07Z"
            fill="#FFD600"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <rect width="16" height="16" rx="8" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NazioneAg;
