/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\arrow-increase-light.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const ArrowIncreaseLight = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-arrow-increase-light', className].join(' ');
  return (
    <svg
      id="arrow-increase-light"
      width="10"
      height="8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M5.217.875 5 .5l-.217.375-3.464 6-.216.375h7.794l-.216-.375-3.464-6Z"
        fill="#0C7923"
        stroke="#FCFCFC"
        strokeWidth=".5"
      />
    </svg>
  );
};
export default ArrowIncreaseLight;
