/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\round-question-32.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const RoundQuestion32 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-round-question-32', className].join(' ');
  return (
    <svg
      id="round-question-32"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#clip0_12790_113532)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9993 29.3346C8.64935 29.3346 2.66602 23.3513 2.66602 16.0013C2.66602 8.6513 8.64935 2.66797 15.9993 2.66797C23.3493 2.66797 29.3327 8.6513 29.3327 16.0013C29.3327 23.3513 23.3493 29.3346 15.9993 29.3346ZM17.3327 21.3346V24.0013H14.666V21.3346H17.3327ZM10.666 13.3346C10.666 10.388 13.0527 8.0013 15.9993 8.0013C18.946 8.0013 21.3327 10.388 21.3327 13.3346C21.3327 15.0452 20.2793 15.9657 19.2537 16.862C18.2807 17.7123 17.3327 18.5408 17.3327 20.0013H14.666C14.666 17.5729 15.9222 16.6101 17.0266 15.7635C17.893 15.0994 18.666 14.5069 18.666 13.3346C18.666 11.868 17.466 10.668 15.9993 10.668C14.5327 10.668 13.3327 11.868 13.3327 13.3346H10.666Z"
          fill="#EBFF00"
        />
      </g>
      <defs>
        <clipPath id="clip0_12790_113532">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default RoundQuestion32;
