/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-52.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco52 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-52', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <g transform="translate(4.435 4.435)">
          <path
            className="a"
            d="M384.739,478.349a.739.739,0,0,1-.739-.739,9.621,9.621,0,0,1,9.61-9.61.739.739,0,1,1,0,1.478,8.141,8.141,0,0,0-8.131,8.131A.739.739,0,0,1,384.739,478.349Z"
            transform="translate(-384 -468)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M423.6,502.854l-12.583-12.582-1.045,1.045,12.272,12.272a4.387,4.387,0,0,1-2.651,2.651l-9.252-9.252a6.421,6.421,0,0,0,1.524-1.127.739.739,0,1,0-1.045-1.045,4.715,4.715,0,0,1-1.589,1.062l-1.91-1.909-1.045,1.045L418.854,507.6a.738.738,0,0,0,.523.217.766.766,0,0,0,.177-.021,5.862,5.862,0,0,0,4.236-4.236A.739.739,0,0,0,423.6,502.854Z"
          transform="translate(-385.373 -469.373)"
          fill="currentColor"
        />
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M392.045,490.09a14.045,14.045,0,1,1,14.045-14.045A14.061,14.061,0,0,1,392.045,490.09Zm0-26.612a12.567,12.567,0,1,0,12.567,12.567A12.581,12.581,0,0,0,392.045,463.478Z"
            transform="translate(-378 -462)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco52;
