/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-173.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco173 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-173', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.569"
      height="41.396"
      viewBox="0 0 29.569 41.396"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(7.392 0)">
        <path
          className="a"
          d="M499.437,308.263a.739.739,0,0,1-.679-.448l-.874-2.039a4.432,4.432,0,0,0-4.077-2.688h-3.98v2.957a.739.739,0,0,1-1.139.622l-10.349-6.653a.739.739,0,0,1,0-1.243l10.349-6.653a.739.739,0,0,1,1.139.622V295.7h2.218a8.141,8.141,0,0,1,8.131,8.131v3.7a.74.74,0,0,1-.591.724A.778.778,0,0,1,499.437,308.263Zm-19.331-8.871,8.242,5.3v-2.342a.739.739,0,0,1,.739-.739h4.719A5.907,5.907,0,0,1,498.7,304.2v-.37a6.66,6.66,0,0,0-6.653-6.653h-2.957a.739.739,0,0,1-.739-.739v-2.342Z"
          transform="translate(-478 -292)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(17.741 32.525)">
        <path
          className="a"
          d="M492.739,337.478h5.914a.739.739,0,1,0,0-1.478h-5.914a.739.739,0,1,0,0,1.478Z"
          transform="translate(-492 -336)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(20.698 35.482)">
        <path
          className="a"
          d="M496.739,341.478H499.7a.739.739,0,1,0,0-1.478h-2.957a.739.739,0,1,0,0,1.478Z"
          transform="translate(-496 -340)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(0 16.263)">
        <path
          className="a"
          d="M468,336.177v-19.22A2.96,2.96,0,0,1,470.957,314h22.177a2.96,2.96,0,0,1,2.957,2.957v19.22a2.96,2.96,0,0,1-2.957,2.957H470.957A2.96,2.96,0,0,1,468,336.177Zm2.957-20.7a1.48,1.48,0,0,0-1.478,1.478v19.22a1.48,1.48,0,0,0,1.478,1.478h22.177a1.48,1.48,0,0,0,1.479-1.478v-19.22a1.481,1.481,0,0,0-1.479-1.478Z"
          transform="translate(-468 -314)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(10.349 17.002)">
        <path
          className="a"
          d="M482.739,323.131h5.914a.739.739,0,0,0,.739-.739V315h-1.478v6.653h-4.435V315H482v7.392A.738.738,0,0,0,482.739,323.131Z"
          transform="translate(-482 -315)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco173;
