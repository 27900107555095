/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-152.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco152 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-152', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.569"
      height="38.439"
      viewBox="0 0 29.569 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M318.779,152.137a.741.741,0,0,0-1.031.172l-7.392,10.349a.739.739,0,1,0,1.2.859l7.392-10.349A.739.739,0,0,0,318.779,152.137Zm-4.865,2.819a2.957,2.957,0,1,0-2.957,2.957A2.96,2.96,0,0,0,313.914,154.957Zm-4.435,0a1.478,1.478,0,1,1,1.478,1.478A1.48,1.48,0,0,1,309.478,154.957Zm8.871,2.957a2.957,2.957,0,1,0,2.957,2.957A2.96,2.96,0,0,0,318.349,157.914Zm0,4.435a1.478,1.478,0,1,1,1.478-1.478A1.48,1.48,0,0,1,318.349,162.349Z"
          transform="translate(-302.086 -132.78)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M314.265,129.156a.74.74,0,0,0-1.005.29,6.662,6.662,0,0,0-.824,3.207v4.435a.739.739,0,0,0,1.478,0v-4.435a5.174,5.174,0,0,1,.641-2.493A.739.739,0,0,0,314.265,129.156ZM319.088,126a6.6,6.6,0,0,0-2.215.39A6.634,6.634,0,0,0,308,132.653v4.435h1.478v-4.435a5.175,5.175,0,0,1,10.349,0v4.435h1.478v-4.435a6.642,6.642,0,0,0-2.465-5.163c.082,0,.164-.012.247-.012a5.181,5.181,0,0,1,5.174,5.175v4.435a.739.739,0,0,0,1.478,0v-4.435A6.661,6.661,0,0,0,319.088,126Z"
          transform="translate(-302.086 -126)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M328.829,140h-28.09a.739.739,0,0,0-.739.739v24.394a2.96,2.96,0,0,0,2.957,2.957h23.655a2.96,2.96,0,0,0,2.957-2.957V140.739A.739.739,0,0,0,328.829,140Zm-25.873,26.612a1.48,1.48,0,0,1-1.478-1.478V141.478h22.177v23.655a1.48,1.48,0,0,1-1.478,1.478Zm25.133-1.478a1.48,1.48,0,0,1-1.478,1.478h-1.889a2.927,2.927,0,0,0,.411-1.478V141.478h2.957Z"
          transform="translate(-300 -129.651)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco152;
