/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-73.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco73 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-73', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="41.396"
      viewBox="0 0 38.439 41.396"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <g transform="translate(8.29 2.957)">
          <path
            className="a"
            d="M326.335,470.717a.739.739,0,0,1-.6-.307,11.792,11.792,0,0,0-19.181,0,.739.739,0,0,1-1.2-.863,13.269,13.269,0,0,1,21.581,0,.74.74,0,0,1-.6,1.171Z"
            transform="translate(-305.215 -464)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M329.482,477.824v-1.561a16.263,16.263,0,1,0-32.526,0v1.561A3.7,3.7,0,0,0,294,481.437v5.914a3.7,3.7,0,0,0,3.065,3.642,2.222,2.222,0,0,0,2.11,1.532h1.478a2.22,2.22,0,0,0,2.218-2.218V478.48a2.22,2.22,0,0,0-2.218-2.218h-1.478a2.208,2.208,0,0,0-.739.129v-.129a14.784,14.784,0,1,1,29.569,0v.129a2.207,2.207,0,0,0-.739-.129h-1.478a2.22,2.22,0,0,0-2.218,2.218v11.828a2.22,2.22,0,0,0,2.218,2.218h.7a6.659,6.659,0,0,1-6.609,5.914h-2.354a2.214,2.214,0,0,0-2.082-1.478h-1.478a2.218,2.218,0,0,0,0,4.435h1.478a2.214,2.214,0,0,0,2.082-1.478h2.354a8.136,8.136,0,0,0,8.1-7.508,2.226,2.226,0,0,0,1.4-1.416,3.7,3.7,0,0,0,3.065-3.642v-5.914A3.7,3.7,0,0,0,329.482,477.824Zm-30.308-.083h1.478a.739.739,0,0,1,.739.739v11.828a.739.739,0,0,1-.739.739h-1.478a.74.74,0,0,1-.739-.739.739.739,0,0,0-.739-.739,2.22,2.22,0,0,1-2.218-2.218v-5.914a2.214,2.214,0,0,1,1.479-2.082v8a.739.739,0,1,0,1.478,0V478.48A.74.74,0,0,1,299.174,477.741Zm16.263,22.177h-1.478a.739.739,0,1,1,0-1.478h1.478a.739.739,0,1,1,0,1.478Zm15.524-12.567a2.22,2.22,0,0,1-2.218,2.218.739.739,0,0,0-.739.739.739.739,0,0,1-.739.739h-1.478a.74.74,0,0,1-.739-.739V478.48a.74.74,0,0,1,.739-.739h1.478a.739.739,0,0,1,.739.739v8.871a.739.739,0,1,0,1.478,0v-8a2.215,2.215,0,0,1,1.478,2.082Z"
          transform="translate(-294 -460)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M313.011,491.061l2.152-1.345a4.594,4.594,0,0,0,2.229-4.021,3.7,3.7,0,0,0-7.392,0,.739.739,0,1,0,1.478,0,2.218,2.218,0,0,1,4.435,0,3.161,3.161,0,0,1-1.533,2.766l-2.152,1.345A4.777,4.777,0,0,0,310,493.827v.739a.739.739,0,0,0,.739.739h5.914a.739.739,0,0,0,0-1.479h-5.175A3.287,3.287,0,0,1,313.011,491.061Zm12.512-.191h-.739v-8.131a.739.739,0,0,0-1.354-.41l-5.914,8.871a.739.739,0,0,0,.615,1.149h5.174v2.218a.739.739,0,1,0,1.478,0v-2.218h.739a.739.739,0,1,0,0-1.478Zm-2.218,0h-3.794l3.794-5.69Z"
          transform="translate(-298.172 -465.737)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco73;
