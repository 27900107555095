import { IppicaTodayFilterListType, Size } from './types';

export const quotaFissaHeadersList = [
  { label: 'vincente' },
  { label: 'piazzato 2' },
  { label: 'piazzato 3' },
  { label: 'piazzato 4' },
];
export const quotaFissaResultsHeaderList = [
  { label: 'chiusa vincente' },
  { label: 'chiusa piazzato 2' },
  { label: 'chiusa piazzato 3' },
  { label: 'chiusa piazzato 4' },
];
// TODO ADD CAMPI
export const totalizzatoreHeadersList = [
  { label: 'quota vincente', size: Size.Small },
  { label: 'piazzato Min/Max', size: Size.Small },
];
export const totalizzatoreHeadersListWithCampi = [
  { label: 'quota vincente', size: Size.Small },
  { label: 'piazzato Min/Max', size: Size.Small },
  { label: 'campi', size: Size.Large },
];

export const accoppiataTrioOrdineHeader = [{ label: 'in ordine', size: Size.Large }];
export const accoppiataTrioDisordineHeader = [{ label: 'in disordine', size: Size.Large }];
export const totalizzatoreResultsHeadersList = [{ label: 'quota vincente' }, { label: 'quota piazzato' }];

export const quotaFissaResults = { title: 'quota fissa', label: 'Risultati Quota Fissa' };
export const quotaTotalizzatoreResults = { title: 'totalizzatore', label: 'Risultati Totalizzatore' };

export const COD_SCOM_TRIO = 10;

export const IMAGE_COUNTRY = 'https://www.datocms-assets.com/76629/1690913473-scommesse.png';

export const ippicaTodayFilters: IppicaTodayFilterListType = [
  {
    label: 'Ordina per',
    typeOfItem: 'radio',
    values: [
      { label: 'Cronologico', queryGroup: 'ordina_per', queryParam: 'orderBy', queryValue: 'Cronologico' },
      { label: 'Ippodromi', queryGroup: 'ordina_per', queryParam: 'orderBy', queryValue: 'Ippodromi' },
      { label: 'Dotazione', queryGroup: 'ordina_per', queryParam: 'orderBy', queryValue: 'Dotazione' },
    ],
  },
  {
    label: 'tipo di gara',
    typeOfItem: 'radio',
    values: [
      { label: 'Tutte', queryGroup: 'tipo_di_gara', queryParam: '', queryValue: '' },
      { label: 'Galoppo', queryGroup: 'tipo_di_gara', queryParam: 'tipoGara', queryValue: 'Galoppo' },
      { label: 'Trotto', queryGroup: 'tipo_di_gara', queryParam: 'tipoGara', queryValue: 'Trotto' },
    ],
  },
  {
    label: 'Tipo Corsa',
    typeOfItem: 'radio',
    values: [
      { label: 'Tutte', queryGroup: 'tipo_corsa', queryParam: '', queryValue: '' },
      { label: 'Quota Fissa', queryGroup: 'tipo_corsa', queryParam: 'isQf', queryValue: 'true' },
      { label: 'Totalizzatore', queryGroup: 'tipo_corsa', queryParam: 'isTot', queryValue: 'true' },
      { label: 'Nazionale', queryGroup: 'tipo_corsa', queryParam: 'isTris', queryValue: 'true' },
    ],
  },
  {
    label: 'internazionali',
    typeOfItem: 'radio',
    values: [
      { label: 'Tutte', queryGroup: 'internazionali', queryParam: '', queryValue: '' },
      { label: 'Nazionale', queryGroup: 'internazionali', queryParam: 'isNazionale', queryValue: 'true' },
      { label: 'Internazionale', queryGroup: 'internazionali', queryParam: 'isNazionale', queryValue: 'false' },
    ],
  },
  {
    label: 'Stato Corsa',
    typeOfItem: 'radio',
    values: [
      { label: 'Tutte', queryGroup: 'stato_corsa', queryParam: '', queryValue: '' },
      { label: 'Aperta', queryGroup: 'stato_corsa', queryParam: 'codiceStatoAvvenimento', queryValue: '2' },
      { label: 'Chiusa', queryGroup: 'stato_corsa', queryParam: 'codiceStatoAvvenimento', queryValue: '4' },
    ],
  },
  {
    label: 'Dotazione',
    typeOfItem: 'radio',
    values: [
      { label: 'Tutte', queryGroup: 'dotazione', queryParam: '', queryValue: '' },
      { label: 'fino a 5.0000,00', queryGroup: 'dotazione', queryParam: 'importoPremio', queryValue: 'Max5000' },
      { label: '>5.000,00', queryGroup: 'dotazione', queryParam: 'importoPremio', queryValue: 'Min5000' },
      { label: '>10.000,00', queryGroup: 'dotazione', queryParam: 'importoPremio', queryValue: 'Min10000' },
      { label: '>20.000,00', queryGroup: 'dotazione', queryParam: 'importoPremio', queryValue: 'Main20000' },
    ],
  },
  {
    label: 'Numero Partenti',
    typeOfItem: 'radio',
    values: [
      { label: 'Tutti', queryGroup: 'numero_partenti', queryParam: '', queryValue: '' },
      { label: 'Fino A 6', queryGroup: 'numero_partenti', queryParam: 'numeroCavalliPartentiMin', queryValue: '6' },
      {
        label: 'Da 7 a 10',
        queryGroup: 'numero_partenti',
        queryParam: 'numeroCavalliPartentiMin_numeroCavalliPartentiMax',
        queryValue: '7_10',
      },
      { label: 'Sopra i 10', queryGroup: 'numero_partenti', queryParam: 'numeroCavalliPartentiMin', queryValue: '11' },
    ],
  },
];

export const TRIO_IN_ORDINE_SIGLA_SISTEMA_KEY = '18-TRO';

export const POLLING_INTERVAL_IPPICA = 1000 * 60;
