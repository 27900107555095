/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-225.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco225 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-225', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.526"
      height="38.439"
      viewBox="0 0 32.526 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M398.263,416.439a.711.711,0,0,1-.251-.044A24.348,24.348,0,0,1,382,393.524V383.174a.74.74,0,0,1,.329-.615,30.122,30.122,0,0,1,31.868,0,.74.74,0,0,1,.329.615v10.349A24.348,24.348,0,0,1,398.513,416.4.715.715,0,0,1,398.263,416.439Zm-14.784-32.854v9.938a22.872,22.872,0,0,0,14.784,21.389,22.873,22.873,0,0,0,14.784-21.389v-9.938a28.664,28.664,0,0,0-29.569,0Z"
          transform="translate(-382 -378)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(7.392 10.349)">
        <path
          className="a"
          d="M400.131,408.263a.737.737,0,0,1-.523-.217l-7.392-7.392a.738.738,0,0,1,0-1.045l2.957-2.957a.738.738,0,0,1,1.045,0l3.913,3.913,8.348-8.348a.738.738,0,0,1,1.045,0l2.957,2.957a.738.738,0,0,1,0,1.045l-11.828,11.827A.737.737,0,0,1,400.131,408.263Zm-6.347-8.131,6.347,6.347L410.914,395.7,409,393.784l-8.348,8.348a.738.738,0,0,1-1.045,0L395.7,398.22Z"
          transform="translate(-392 -392)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco225;
