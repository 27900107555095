type IdType = {
  codiceProgramma: string;
  numeroAvvenimento: string;
  codiceTipoScommessa?: string;
  infoAggiuntiva?: string;
  codiceCavallo?: string;
};

export class KeyManagerIppica {
  private _separator: string;

  private _codiceProgramma: string;
  private _numeroAvvenimento: string;
  private _codiceTipoScommessa?: string;
  private _infoAggiuntiva?: string;
  private _numeroCavallo?: string;

  public esitoKey: string;
  public scommessaKey: string;
  public avvenimentoKey: string;
  public infoAggiuntivaKey: string;

  get codiceProgramma(): string {
    return this._codiceProgramma;
  }

  get numeroAvvenimento(): string {
    return this._numeroAvvenimento;
  }

  get codiceTipoScommessa(): string | undefined {
    return this._codiceTipoScommessa;
  }

  set codiceTipoScommessa(tipoScommessa: string) {
    this._codiceTipoScommessa = tipoScommessa;
    this.generateKeys();
  }

  get infoAggiuntiva(): string | undefined {
    return this._infoAggiuntiva;
  }

  set infoAggiuntiva(infoAggiuntiva: string) {
    this._infoAggiuntiva = infoAggiuntiva;
    this.generateKeys();
  }

  get numeroCavallo(): string | undefined {
    return this._numeroCavallo;
  }

  set numeroCavallo(cavallo: string) {
    this._numeroCavallo = cavallo;
    this.generateKeys();
  }

  constructor(key: IdType, separator?: string);
  constructor(key: string, separator?: string);
  constructor(key: IdType | string, separator: string = '_') {
    this._separator = separator;
    if (typeof key === 'object') {
      const { codiceProgramma, numeroAvvenimento, codiceTipoScommessa, infoAggiuntiva, codiceCavallo } = key;

      this._codiceProgramma = codiceProgramma;
      this._numeroAvvenimento = numeroAvvenimento;
      this._codiceTipoScommessa = codiceTipoScommessa;
      this._infoAggiuntiva = infoAggiuntiva;
      this._numeroCavallo = codiceCavallo;
    } else if (typeof key === 'string') {
      this.generateIds(key);
    }
    this.generateKeys();
  }

  private generateIds(key: string) {
    const { codiceProgramma, numeroAvvenimento, codiceTipoScommessa, infoAggiuntiva, codiceCavallo } =
      this.retrieveInformationByKey(key);

    this._codiceProgramma = codiceProgramma;
    this._numeroAvvenimento = numeroAvvenimento;
    this._codiceTipoScommessa = codiceTipoScommessa;
    this._infoAggiuntiva = infoAggiuntiva;
    this._numeroCavallo = codiceCavallo;
  }

  private retrieveInformationByKey(key: string) {
    const [codiceProgramma, numeroAvvenimento, codiceTipoScommessa, infoAggiuntiva, codiceCavallo] = key.split(
      this._separator
    );
    return {
      codiceProgramma,
      numeroAvvenimento,
      codiceTipoScommessa,
      infoAggiuntiva,
      codiceCavallo,
    };
  }

  private generateKeys() {
    this.avvenimentoKey = this.concatId([this._codiceProgramma, this._numeroAvvenimento]);
    if (this._codiceTipoScommessa) {
      this.scommessaKey = this.concatId([this._codiceProgramma, this._numeroAvvenimento, this._codiceTipoScommessa]);

      if (this._infoAggiuntiva) {
        this.infoAggiuntivaKey = this.concatId([
          this._codiceProgramma,
          this._numeroAvvenimento,
          this._codiceTipoScommessa,
          this._infoAggiuntiva,
        ]);
        if (this._numeroCavallo) {
          this.esitoKey = this.concatId([
            this._codiceProgramma,
            this._numeroAvvenimento,
            this._codiceTipoScommessa,
            this._infoAggiuntiva,
            this._numeroCavallo,
          ]);
        }
      }
    }
  }

  private concatId(keys: Array<string>) {
    return keys.join(this._separator);
  }
}
