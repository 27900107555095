import { createAsyncThunk } from '@reduxjs/toolkit';
import { extendedApi } from 'features/api/sportApiSlice';
import { doFetch, FetchPayload } from 'features/api/thunkUtils';
import { addToCartSnaiRunnerKey, updateTicket } from 'features/carrello/actions';
import {
  venditaAlertFailedMessageErrorAdded,
  venditaAlertFailedOpenChange,
  venditaAlertSuccessOpenChange,
} from 'features/carrello/carrelloSlice';
import { TicketErroreBetReferral, TicketErroreVendita } from 'features/carrello/types';
import { KeyManagerSport } from 'features/sport/utils/keyManager';
import { EsitoMap } from 'lib/api/sport/sportScommesseBySlugResponse';
import { ScommettiResponse } from 'lib/api/sport/sportScommettiResponseApi';
import { RootState } from 'lib/centralStore';
import {
  SportsRisultatini,
  SportsUpdateRisultatiniSignalREvent,
  SportsUpdateRisultatiniSignalREventWithTranslationsDto,
} from 'types/swagger';
import { selectEsiti } from './sportTicketSelectors';
import {
  betReferralAdded,
  betReferralReset,
  erroriAdded,
  erroriRemoved,
  hasBetReferralTimer,
  puntataSingolaMultiplaPrevAdded,
  puntataSingolaMultiplaUpdated,
  quoteUpdated,
  removeToSnaiRunnerEsiti,
  risultatiniUpdated,
  ticketRemoved,
  ticketSaved,
  updateEventiSospesi,
} from './sportTicketSlice';
import { QUOTE_SPORT_TICKET_AVVENIMENTO_API, VENDITA_STATUS } from './types';

export type EsitoInErrore = {
  esitiSospesi: string[];
  esitiNonSospesi: string[];
};

export const updateQuoteFromSignalR = createAsyncThunk(
  'updateQuoteFromSignalR',
  async (payload: EsitoMap, { getState, dispatch }) => {
    const { carrello } = getState() as RootState;

    if (!carrello.isLoadingVendita) {
      dispatch(quoteUpdated({ quoteToUpdate: payload, isMultipla: false }));
      dispatch(updateSportTicket());
    }
    return Promise.resolve();
  }
);

export const updateRisultatiniFromSignalR = createAsyncThunk(
  'updateRisultatiniFromSignalR',
  async (payload: SportsUpdateRisultatiniSignalREventWithTranslationsDto, { getState, dispatch }) => {
    const { carrello } = getState() as RootState;

    if (!carrello.isLoadingVendita) {
      dispatch(risultatiniUpdated([payload]));
      dispatch(updateSportTicket());
    }
    return Promise.resolve();
  }
);

export const updateSportTicket = createAsyncThunk('updateCalcoloTicketWithBonus', async (_, { getState, dispatch }) => {
  const { sportTicket } = getState() as RootState;
  dispatch(updateTicket(sportTicket));
});

export const initializeQuoteForSignalR = createAsyncThunk(
  'initializeQuoteForSignalR',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const esiti = selectEsiti(state);

    if (Object.keys(esiti).length > 0) {
      const response = await dispatch(
        doFetch({
          query: extendedApi.endpoints.updateQuoteBySportTicketAvvenimentoList,
          uid: QUOTE_SPORT_TICKET_AVVENIMENTO_API,
          ...esiti,
          paramAsObject: true,
        })
      );

      const result = Reflect.get(response, 'payload') as FetchPayload<{
        esitoMap: EsitoMap;
        avvenimentiSospesi: {
          [key: string]: boolean;
        };
        infoAggiuntiveSospese: {
          [key: string]: boolean;
        };
        risultatiniMap?: Record<string, SportsRisultatini>;
      }>;

      const { avvenimentiSospesi, infoAggiuntiveSospese, esitoMap, risultatiniMap } = result.data;

      const objectOfRisultatiniMap =
        risultatiniMap &&
        (Object.entries(risultatiniMap).map(([key, live]) => ({ key, live })) as SportsUpdateRisultatiniSignalREvent[]);

      if (objectOfRisultatiniMap && objectOfRisultatiniMap.length > 0) {
        await dispatch(risultatiniUpdated(objectOfRisultatiniMap));
      }

      await dispatch(updateQuoteFromSignalR(esitoMap));
      await dispatch(updateEventiSospesi({ avvenimentiSospesi, infoAggiuntiveSospese }));
    }
    return Promise.resolve();
  }
);

export const venditaSport = createAsyncThunk('venditaSport', async (response: ScommettiResponse, { dispatch }) => {
  switch (response?.statusCodeResponse) {
    case VENDITA_STATUS.SUCCESS:
      dispatch(ticketSaved());
      dispatch(ticketRemoved());
      dispatch(venditaAlertSuccessOpenChange(true));
      break;
    case VENDITA_STATUS.CAMBIO_QUOTE:
      if (response?.esitoMap) {
        dispatch(updateQuoteFromSignalR(response.esitoMap));
      }
      dispatch(
        erroriAdded({
          tipoErrore: 'erroriVendita',
          errore: TicketErroreVendita.QuoteCambiate,
        })
      );
      break;
    case 799:
      if (response.esitoMap) {
        dispatch(quoteUpdated({ quoteToUpdate: response.esitoMap }));
      }
      break;
    case VENDITA_STATUS.BET_REFERRAL:
    case VENDITA_STATUS.BET_REFERRAL_PARTIAL:
      dispatch(venditaBetReferral(response));
      break;
    default:
      if (response.errore) {
        dispatch(venditaAlertFailedMessageErrorAdded(response.errore));
        dispatch(venditaAlertFailedOpenChange(true));
      }
  }
});

export const venditaBetReferral = createAsyncThunk(
  'venditaBetReferral',
  async (response: ScommettiResponse, { dispatch, getState }) => {
    const { descrizioneBetReferral, transazioneBetReferral, valore, dataOra, limite } = response;

    if (
      descrizioneBetReferral &&
      transazioneBetReferral &&
      valore !== undefined &&
      valore !== null &&
      dataOra &&
      limite
    ) {
      dispatch(puntataSingolaMultiplaPrevAdded());
      dispatch(
        betReferralAdded({
          descrizione: descrizioneBetReferral,
          transazione: transazioneBetReferral,
          valore,
          dataOra,
          limite,
        })
      );

      const { sportTicket } = getState() as RootState;
      const { ticket } = sportTicket;
      if (ticket) {
        // if non è un sistema
        const { sistema: isSistema } = ticket;
        if (!isSistema) {
          // if limite is e
          const { limite, valore } = response;

          // registra puntata precedente
          if (limite === 'e' && valore !== undefined && valore !== null) {
            // cambia campo importo singola
            dispatch(puntataSingolaMultiplaUpdated(valore / 100));
          }
          // TODO: NON PRIORITARIO LIMITE DI TIPO P
          /* else if (limite === 'p' && valore) {
            const { quotaTotale } = ticket;
            const puntata = valore / quotaTotale;
            dispatch(puntataSingolaMultiplaUpdated(roundToNearestFiveCents(puntata)));
          } */

          // mostra label con descrizioneBetReferral
          dispatch(
            erroriAdded({
              tipoErrore: 'erroriBetReferral',
              errore: TicketErroreBetReferral.ImportoMassimoScommettibile,
            })
          );
          return Promise.resolve();
        }
      }
    }
  }
);

export const venditaBetReferralReset = createAsyncThunk('venditaBetReferralReset', async (_, { dispatch }) => {
  dispatch(betReferralReset());
  dispatch(
    erroriRemoved({
      tipoErrore: 'erroriBetReferral',
      errore: {
        type: TicketErroreBetReferral.ImportoMassimoScommettibile,
      },
    })
  );
  dispatch(
    erroriRemoved({
      tipoErrore: 'erroriBetReferral',
      errore: {
        type: TicketErroreBetReferral.MaxVincita,
      },
    })
  );

  dispatch(hasBetReferralTimer(false));
});

export const snaiRunnerToCartHandler = createAsyncThunk(
  'snaiRunnerToCartHandler',
  async (keyToToggle: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const esitiSnaiRunner = state.sportTicket.snaiRunnerEsiti;
    const isInEsitiSnaiRunner = esitiSnaiRunner && esitiSnaiRunner[keyToToggle] ? true : false;

    const scommessa = new KeyManagerSport(keyToToggle).scommessaKey;
    if (isInEsitiSnaiRunner) {
      dispatch(removeToSnaiRunnerEsiti(keyToToggle));
    } else {
      const scommessaInCart =
        esitiSnaiRunner &&
        Object.keys(esitiSnaiRunner).some((esito) => {
          return esito.includes(scommessa);
        });
      if (scommessaInCart) {
        const keyEsitoInCart = Object.keys(esitiSnaiRunner).find((esito) => {
          return esito.includes(scommessa) && esito !== keyToToggle;
        })!;
        dispatch(removeToSnaiRunnerEsiti(keyEsitoInCart));
      }
      dispatch(addToCartSnaiRunnerKey({ esitoKey: keyToToggle }));
    }
  }
);
