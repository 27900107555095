/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-ie.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneIe = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-ie', className].join(' ');
  return (
    <svg
      id="nazione-ie"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path d="M0 0h16v16H0V0Z" fill="#fff" />
        <path d="M0 0h5.334v16H0V0Z" fill="#009A49" />
        <path d="M10.666 0H16v16h-5.334V0Z" fill="#FF7900" />
      </g>
    </svg>
  );
};
export default NazioneIe;
