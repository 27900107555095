/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-31.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina31 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-31', className].join(' ');
  return (
    <svg
      id="disciplina_slittino"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="m6.28 10.304 1.735 1.74a1.088 1.088 0 0 1-.12-.258l-.834-2.905 1.354 2.639a.972.972 0 0 0 .868.528h4.676a.976.976 0 0 0 0-1.95H9.88l-1.078-2.11 1.203 1.525h1.238l-2.08-2.084a1.676 1.676 0 0 0-2.368-.004l-.511.51a1.672 1.672 0 0 0-.005 2.369Zm7.674 2.329H9.017a1.14 1.14 0 0 1-.59-.176l1.312 1.315a2.154 2.154 0 0 0 2.51.386c.218-.043 3.639-1.52 3.845-1.607l3.644 2.79a1.284 1.284 0 1 0 1.56-2.042l-4.241-3.244a1.283 1.283 0 0 0-1.285-.164l-.58.25a1.551 1.551 0 0 1-1.238 2.492ZM6.19 7.32a1.945 1.945 0 1 0-2.746-2.754 1.945 1.945 0 0 0 2.746 2.754Zm16.768 9.828a1.167 1.167 0 0 0-1.44-.812c-3.517.99-7.28.6-10.52-1.091L2.709 10.94a1.17 1.17 0 1 0-1.078 2.075l8.289 4.306a16.674 16.674 0 0 0 12.226 1.268 1.168 1.168 0 0 0 .812-1.44Z"
      />
    </svg>
  );
};
export default Disciplina31;
