/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-ar.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneAr = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-ar', className].join(' ');
  return (
    <svg
      id="nazione-ar"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path d="M0 0h16v16H0V0Z" fill="#74ACDF" />
          <path d="M0 5.334h16v5.332H0V5.334Z" fill="#fff" />
          <path
            d="m7.898 8.042.912 1.984s.016.038.041.028c.026-.012.01-.048.01-.048l-.759-2.048m-.022.772c-.013.3.173.467.15.736-.025.272.122.422.16.528.032.105-.041.166-.01.182.033.016.097-.067.078-.218-.023-.147-.135-.192-.11-.521.026-.33-.134-.407-.095-.704"
            fill="#F6B40E"
          />
          <path
            d="m7.898 8.042.912 1.984s.016.038.041.028c.026-.012.01-.048.01-.048l-.759-2.048m-.022.772c-.013.3.173.467.15.736-.025.272.122.422.16.528.032.105-.041.166-.01.182.033.016.097-.067.078-.218-.023-.147-.135-.192-.11-.521.026-.33-.134-.407-.095-.704"
            stroke="#85340A"
            strokeWidth=".141"
          />
          <path
            d="m7.89 8 .083 2.181s0 .042.027.043c.029-.002.027-.04.027-.04L8.111 8m-.316.704c-.127.273-.02.497-.143.737-.128.242-.05.437-.054.55-.011.109-.102.137-.079.164.024.027.114-.025.154-.172.036-.144-.05-.228.1-.523.15-.295.03-.427.18-.687"
            fill="#F6B40E"
          />
          <path
            d="m7.89 8 .083 2.181s0 .042.027.043c.029-.002.027-.04.027-.04L8.111 8m-.316.704c-.127.273-.02.497-.143.737-.128.242-.05.437-.054.55-.011.109-.102.137-.079.164.024.027.114-.025.154-.172.036-.144-.05-.228.1-.523.15-.295.03-.427.18-.687"
            stroke="#85340A"
            strokeWidth=".141"
          />
          <path
            d="m7.898 7.957-.758 2.048s-.016.038.01.05c.026.009.04-.028.04-.028l.912-1.984m-.561.53c-.222.203-.209.452-.414.626-.21.175-.213.385-.26.487-.053.097-.148.088-.136.122.01.034.115.02.208-.1.088-.12.04-.23.292-.445.25-.215.192-.383.43-.566"
            fill="#F6B40E"
          />
          <path
            d="m7.898 7.957-.758 2.048s-.016.038.01.05c.026.009.04-.028.04-.028l.912-1.984m-.561.53c-.222.203-.209.452-.414.626-.21.175-.213.385-.26.487-.053.097-.148.088-.136.122.01.034.115.02.208-.1.088-.12.04-.23.292-.445.25-.215.192-.383.43-.566"
            stroke="#85340A"
            strokeWidth=".141"
          />
          <path
            d="M7.922 7.921 6.438 9.523s-.029.03-.01.05c.021.018.048-.01.048-.01L8.078 8.08m-.721.274c-.283.103-.366.339-.623.42-.261.081-.344.275-.426.35-.086.07-.17.026-.173.062-.002.035.1.063.23-.013.128-.077.127-.197.441-.3.314-.102.324-.28.614-.358"
            fill="#F6B40E"
          />
          <path
            d="M7.922 7.921 6.438 9.523s-.029.03-.01.05c.021.018.048-.01.048-.01L8.078 8.08m-.721.274c-.283.103-.366.339-.623.42-.261.081-.344.275-.426.35-.086.07-.17.026-.173.062-.002.035.1.063.23-.013.128-.077.127-.197.441-.3.314-.102.324-.28.614-.358"
            stroke="#85340A"
            strokeWidth=".141"
          />
          <path
            d="M8.138 8.78c.016.289.179.417.147.682.07-.208-.1-.37-.09-.678m-.246-.762.624 1.364L8.05 7.98"
            fill="#85340A"
          />
          <path
            d="M7.828 8.774c-.095.272.007.453-.125.686.145-.165.05-.38.177-.661m.064-.798L8 9.5l.056-1.497"
            fill="#85340A"
          />
          <path
            d="M7.545 8.65c-.192.214-.167.42-.378.585.197-.097.193-.332.417-.543m.364-.712-.523 1.405.625-1.362"
            fill="#85340A"
          />
          <path
            d="M7.331 8.426c-.26.125-.315.325-.573.397.219-.015.305-.234.592-.343m.61-.519L6.94 9.06l1.097-1.02"
            fill="#85340A"
          />
          <path
            d="m7.958 7.898-1.984.912s-.038.016-.028.041c.012.026.048.01.048.01l2.048-.759M7.27 8.08c-.3-.013-.467.173-.736.15-.272-.025-.422.122-.528.16-.105.032-.166-.041-.182-.01-.016.033.067.097.218.078.147-.023.192-.135.521-.11.33.026.407-.134.704-.095"
            fill="#F6B40E"
          />
          <path
            d="m7.958 7.898-1.984.912s-.038.016-.028.041c.012.026.048.01.048.01l2.048-.759M7.27 8.08c-.3-.013-.467.173-.736.15-.272-.025-.422.122-.528.16-.105.032-.166-.041-.182-.01-.016.033.067.097.218.078.147-.023.192-.135.521-.11.33.026.407-.134.704-.095"
            stroke="#85340A"
            strokeWidth=".141"
          />
          <path
            d="m8 7.89-2.181.083s-.042 0-.043.027c.002.029.04.027.04.027L8 8.111m-.704-.316c-.273-.127-.497-.02-.737-.143-.242-.128-.437-.05-.55-.054-.109-.011-.137-.102-.164-.079-.027.024.025.115.172.154.144.036.228-.05.523.1.295.15.427.03.687.18"
            fill="#F6B40E"
          />
          <path
            d="m8 7.89-2.181.083s-.042 0-.043.027c.002.029.04.027.04.027L8 8.111m-.704-.316c-.273-.127-.497-.02-.737-.143-.242-.128-.437-.05-.55-.054-.109-.011-.137-.102-.164-.079-.027.024.025.115.172.154.144.036.228-.05.523.1.295.15.427.03.687.18"
            stroke="#85340A"
            strokeWidth=".141"
          />
          <path
            d="M8.043 7.898 5.995 7.14s-.038-.016-.05.01c-.009.026.028.04.028.04l1.984.912m-.53-.561c-.203-.222-.452-.208-.626-.414-.174-.21-.385-.213-.487-.26-.097-.053-.088-.148-.122-.136-.034.01-.02.115.1.208.12.088.23.04.445.292.215.25.383.192.566.43"
            fill="#F6B40E"
          />
          <path
            d="M8.043 7.898 5.995 7.14s-.038-.016-.05.01c-.009.026.028.04.028.04l1.984.912m-.53-.561c-.203-.222-.452-.208-.626-.414-.174-.21-.385-.213-.487-.26-.097-.053-.088-.148-.122-.136-.034.01-.02.115.1.208.12.088.23.04.445.292.215.25.383.192.566.43"
            stroke="#85340A"
            strokeWidth=".141"
          />
          <path
            d="M8.079 7.922 6.477 6.438s-.03-.029-.05-.01c-.018.021.01.048.01.048L7.92 8.078m-.274-.721c-.103-.283-.339-.366-.42-.623-.081-.26-.275-.344-.35-.426-.07-.086-.026-.17-.062-.173-.035-.002-.063.1.013.23.077.128.197.127.3.441.102.314.28.324.358.614"
            fill="#F6B40E"
          />
          <path
            d="M8.079 7.922 6.477 6.438s-.03-.029-.05-.01c-.018.021.01.048.01.048L7.92 8.078m-.274-.721c-.103-.283-.339-.366-.42-.623-.081-.26-.275-.344-.35-.426-.07-.086-.026-.17-.062-.173-.035-.002-.063.1.013.23.077.128.197.127.3.441.102.314.28.324.358.614"
            stroke="#85340A"
            strokeWidth=".141"
          />
          <path
            d="M7.22 8.138c-.289.016-.417.179-.682.147.208.07.37-.1.678-.09m.762-.246-1.364.624L8.02 8.05"
            fill="#85340A"
          />
          <path
            d="M7.226 7.828c-.272-.095-.453.007-.686-.124.165.144.38.05.661.176m.798.064L6.5 8l1.497.056"
            fill="#85340A"
          />
          <path
            d="M7.35 7.545c-.214-.192-.42-.167-.585-.378.097.197.332.193.543.417m.712.364-1.405-.523 1.362.625"
            fill="#85340A"
          />
          <path
            d="M7.574 7.331c-.125-.26-.325-.315-.397-.573.015.219.234.305.343.592m.519.61L6.94 6.94 7.96 8.036"
            fill="#85340A"
          />
          <path
            d="M8.102 7.958 7.19 5.974s-.016-.038-.041-.028c-.026.012-.01.048-.01.048l.759 2.048m.022-.772c.013-.3-.173-.467-.15-.736.025-.272-.122-.422-.16-.528-.032-.105.041-.166.01-.182-.033-.016-.097.067-.078.218.023.147.135.192.11.521-.026.33.134.407.095.704"
            fill="#F6B40E"
          />
          <path
            d="M8.102 7.958 7.19 5.974s-.016-.038-.041-.028c-.026.012-.01.048-.01.048l.759 2.048m.022-.772c.013-.3-.173-.467-.15-.736.025-.272-.122-.422-.16-.528-.032-.105.041-.166.01-.182-.033-.016-.097.067-.078.218.023.147.135.192.11.521-.026.33.134.407.095.704"
            stroke="#85340A"
            strokeWidth=".141"
          />
          <path
            d="M8.11 8 8.028 5.82s0-.042-.027-.043c-.029.002-.027.04-.027.04L7.889 8m.316-.704c.127-.273.02-.497.143-.737.128-.242.05-.437.054-.55.011-.109.102-.137.079-.164-.024-.027-.114.025-.154.172-.036.144.05.228-.1.523-.15.295-.03.427-.18.687"
            fill="#F6B40E"
          />
          <path
            d="M8.11 8 8.028 5.82s0-.042-.027-.043c-.029.002-.027.04-.027.04L7.889 8m.316-.704c.127-.273.02-.497.143-.737.128-.242.05-.437.054-.55.011-.109.102-.137.079-.164-.024-.027-.114.025-.154.172-.036.144.05.228-.1.523-.15.295-.03.427-.18.687"
            stroke="#85340A"
            strokeWidth=".141"
          />
          <path
            d="m8.102 8.043.758-2.048s.016-.038-.01-.05c-.026-.009-.04.028-.04.028l-.912 1.984m.561-.53c.222-.203.209-.452.414-.626.21-.175.213-.385.26-.487.053-.097.148-.088.136-.122-.01-.034-.115-.02-.208.1-.088.12-.04.23-.292.445-.25.215-.192.383-.43.566"
            fill="#F6B40E"
          />
          <path
            d="m8.102 8.043.758-2.048s.016-.038-.01-.05c-.026-.009-.04.028-.04.028l-.912 1.984m.561-.53c.222-.203.209-.452.414-.626.21-.175.213-.385.26-.487.053-.097.148-.088.136-.122-.01-.034-.115-.02-.208.1-.088.12-.04.23-.292.445-.25.215-.192.383-.43.566"
            stroke="#85340A"
            strokeWidth=".141"
          />
          <path
            d="m8.078 8.079 1.484-1.602s.029-.03.01-.05c-.021-.018-.048.01-.048.01L7.922 7.92m.721-.274c.283-.103.366-.339.623-.42.261-.081.344-.275.426-.35.086-.07.17-.026.173-.062.002-.035-.1-.063-.23.013-.128.077-.127.197-.441.3-.314.102-.324.28-.614.358"
            fill="#F6B40E"
          />
          <path
            d="m8.078 8.079 1.484-1.602s.029-.03.01-.05c-.021-.018-.048.01-.048.01L7.922 7.92m.721-.274c.283-.103.366-.339.623-.42.261-.081.344-.275.426-.35.086-.07.17-.026.173-.062.002-.035-.1-.063-.23.013-.128.077-.127.197-.441.3-.314.102-.324.28-.614.358"
            stroke="#85340A"
            strokeWidth=".141"
          />
          <path
            d="M7.862 7.22c-.016-.289-.179-.417-.147-.682-.07.208.1.37.09.678m.246.762-.624-1.364.522 1.405"
            fill="#85340A"
          />
          <path
            d="M8.172 7.226c.095-.272-.007-.453.125-.686-.145.165-.05.38-.177.661m-.064.798L8 6.5l-.056 1.497"
            fill="#85340A"
          />
          <path
            d="M8.455 7.35c.192-.214.167-.42.378-.585-.197.097-.193.332-.417.543m-.364.712.523-1.405-.625 1.362"
            fill="#85340A"
          />
          <path
            d="M8.669 7.574c.26-.125.315-.325.573-.397-.219.015-.305.234-.592.343m-.61.519L9.06 6.94 7.964 7.96"
            fill="#85340A"
          />
          <path
            d="m8.042 8.102 1.984-.912s.038-.016.028-.041c-.012-.026-.048-.01-.048-.01l-2.048.759m.772.022c.3.013.467-.173.736-.15.272.025.422-.122.528-.16.105-.032.166.041.182.01.016-.033-.067-.097-.218-.078-.147.023-.192.135-.521.11-.33-.026-.407.134-.704.095"
            fill="#F6B40E"
          />
          <path
            d="m8.042 8.102 1.984-.912s.038-.016.028-.041c-.012-.026-.048-.01-.048-.01l-2.048.759m.772.022c.3.013.467-.173.736-.15.272.025.422-.122.528-.16.105-.032.166.041.182.01.016-.033-.067-.097-.218-.078-.147.023-.192.135-.521.11-.33-.026-.407.134-.704.095"
            stroke="#85340A"
            strokeWidth=".141"
          />
          <path
            d="m8 8.11 2.181-.083s.042 0 .043-.027c-.002-.029-.04-.027-.04-.027L8 7.889m.704.316c.273.127.497.02.737.143.242.128.437.05.55.054.11.011.137.102.164.079.027-.024-.025-.115-.172-.154-.144-.036-.228.05-.523-.1-.295-.15-.427-.03-.687-.18"
            fill="#F6B40E"
          />
          <path
            d="m8 8.11 2.181-.083s.042 0 .043-.027c-.002-.029-.04-.027-.04-.027L8 7.889m.704.316c.273.127.497.02.737.143.242.128.437.05.55.054.11.011.137.102.164.079.027-.024-.025-.115-.172-.154-.144-.036-.228.05-.523-.1-.295-.15-.427-.03-.687-.18"
            stroke="#85340A"
            strokeWidth=".141"
          />
          <path
            d="m7.957 8.102 2.048.758s.038.016.05-.01c.009-.026-.028-.04-.028-.04l-1.984-.912m.53.561c.203.222.452.208.626.414.174.21.385.213.487.26.097.053.088.148.122.136.034-.01.02-.115-.1-.208-.12-.088-.23-.04-.445-.292-.215-.25-.383-.192-.566-.43"
            fill="#F6B40E"
          />
          <path
            d="m7.957 8.102 2.048.758s.038.016.05-.01c.009-.026-.028-.04-.028-.04l-1.984-.912m.53.561c.203.222.452.208.626.414.174.21.385.213.487.26.097.053.088.148.122.136.034-.01.02-.115-.1-.208-.12-.088-.23-.04-.445-.292-.215-.25-.383-.192-.566-.43"
            stroke="#85340A"
            strokeWidth=".141"
          />
          <path
            d="m7.921 8.078 1.602 1.484s.03.029.05.01c.018-.021-.01-.048-.01-.048L8.08 7.922m.274.721c.103.283.339.366.42.623.081.26.275.344.35.426.07.086.026.17.062.173.035.002.063-.1-.013-.23-.077-.128-.197-.127-.3-.441-.102-.314-.28-.324-.358-.614"
            fill="#F6B40E"
          />
          <path
            d="m7.921 8.078 1.602 1.484s.03.029.05.01c.018-.021-.01-.048-.01-.048L8.08 7.922m.274.721c.103.283.339.366.42.623.081.26.275.344.35.426.07.086.026.17.062.173.035.002.063-.1-.013-.23-.077-.128-.197-.127-.3-.441-.102-.314-.28-.324-.358-.614"
            stroke="#85340A"
            strokeWidth=".141"
          />
          <path
            d="M8.78 7.862c.289-.016.417-.179.682-.147-.208-.07-.37.1-.678.09m-.762.246 1.364-.624-1.405.522"
            fill="#85340A"
          />
          <path
            d="M8.774 8.172c.272.095.453-.007.686.124-.165-.144-.38-.05-.661-.176m-.798-.064L9.5 8l-1.497-.056"
            fill="#85340A"
          />
          <path
            d="M8.65 8.455c.214.192.42.167.585.378-.097-.197-.332-.193-.543-.417m-.712-.364 1.405.523-1.362-.625"
            fill="#85340A"
          />
          <path
            d="M8.426 8.669c.125.26.325.315.397.573-.015-.219-.234-.305-.343-.592m-.519-.61L9.06 9.06 8.04 7.964"
            fill="#85340A"
          />
          <path
            d="M8 8.887a.888.888 0 1 0 0-1.775.888.888 0 0 0 0 1.775Z"
            fill="#F6B40E"
            stroke="#85340A"
            strokeWidth=".188"
          />
          <path
            d="M8.303 7.813a.175.175 0 0 0-.153.078c.069.062.219.068.322-.007a.234.234 0 0 0-.169-.075v.004Zm0 .012c.06 0 .113.025.122.053-.069.075-.178.069-.247.013a.144.144 0 0 1 .125-.066Z"
            fill="#843511"
          />
          <path
            d="M8.316 7.769c-.088 0-.11.018-.15.053-.041.034-.063.028-.07.034-.005.006 0 .028.017.022.012-.01.037-.022.08-.056.041-.031.082-.031.126-.031.118 0 .187.1.203.093.012-.006-.069-.115-.206-.115Z"
            fill="#85340A"
          />
          <path
            d="M8.563 7.81c-.157-.138-.357-.157-.466-.057a.269.269 0 0 0-.05.116c-.016.078.01.162.072.25a.045.045 0 0 0-.031.012.514.514 0 0 1-.054-.312.444.444 0 0 1 .022-.078c.15-.125.357-.138.507.068Z"
            fill="#85340A"
          />
          <path d="M8.3 7.94a.062.062 0 1 0 0-.124.062.062 0 0 0 0 .125Z" fill="#85340A" />
          <path
            d="M8.478 7.919c-.115.087-.231.081-.3.04-.069-.04-.069-.056-.053-.056.012 0 .028.016.081.04.056.032.138.032.275-.024h-.003ZM7.866 8.125a.063.063 0 1 0 .062.094c.022.019.053.019.072.019h.01c.025 0 .05-.006.071-.02a.062.062 0 1 0 .063-.093c.015.003.028.019.028.038a.04.04 0 1 1-.081 0 .103.103 0 0 1-.088.056.103.103 0 0 1-.084-.056.04.04 0 1 1-.081 0c0-.02.009-.032.024-.041l.004.003Zm.062.181c-.066 0-.094.063-.156.103.037-.015.062-.04.112-.068.047-.028.088.006.116.006.028 0 .069-.034.116-.006.046.028.075.053.109.068-.063-.043-.088-.103-.156-.103A.187.187 0 0 0 8 8.325a.181.181 0 0 0-.069-.019h-.003Z"
            fill="#85340A"
          />
          <path
            d="M7.906 8.388a.469.469 0 0 0-.112.021c.125-.03.15.013.206.013.056 0 .081-.04.206-.016-.137-.037-.165-.012-.206-.012-.028 0-.047-.01-.094-.01v.004Z"
            fill="#85340A"
          />
          <path
            d="M7.8 8.406h-.025c.144.016.072.097.225.097.15 0 .081-.081.225-.094-.15-.015-.103.075-.225.075-.116 0-.081-.078-.2-.078Zm.322.222A.125.125 0 0 0 8 8.503a.125.125 0 0 0-.125.125A.125.125 0 0 1 8 8.534a.125.125 0 0 1 .125.094h-.003ZM7.447 7.81c.156-.138.356-.157.465-.057a.269.269 0 0 1 .05.116c.016.078-.009.162-.071.25.009 0 .021.003.03.012a.514.514 0 0 0 .054-.312.444.444 0 0 0-.022-.078c-.15-.125-.356-.138-.506.068Z"
            fill="#85340A"
          />
          <path
            d="M7.694 7.769c.087 0 .11.018.15.053.04.034.062.028.068.034.007.006 0 .028-.015.022-.013-.01-.038-.022-.081-.056-.041-.031-.082-.031-.125-.031-.12 0-.188.1-.204.093-.012-.006.07-.115.207-.115Z"
            fill="#85340A"
          />
          <path
            d="M7.69 7.813a.175.175 0 0 0-.153.078c.07.062.22.068.322-.007a.234.234 0 0 0-.168-.075v.004Zm0 .012c.06 0 .113.025.122.053-.068.075-.178.069-.246.013a.144.144 0 0 1 .125-.066Z"
            fill="#843511"
          />
          <path d="M7.697 7.94a.063.063 0 1 0 0-.124.063.063 0 0 0 0 .125Z" fill="#85340A" />
          <path
            d="M7.531 7.919c.116.087.232.081.3.04.069-.04.069-.056.053-.056-.012 0-.028.016-.08.04-.057.032-.138.032-.276-.024h.003Z"
            fill="#85340A"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="16" height="16" rx="8" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NazioneAr;
