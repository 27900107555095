/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-41.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina41 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-41', className].join(' ');
  return (
    <svg
      id="disciplina_equitazione"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M22.019 12.99v.095a.567.567 0 0 1-.567.567H2.377A.478.478 0 0 1 2 13.18v-2.076C2 6.535 5.378 2.758 9.775 2.13 9.904 1.486 10.454 1 11.11 1c.666 0 1.216.494 1.341 1.143 4.35.666 7.68 4.423 7.68 8.957 0 .443-.032.885-.095 1.323h1.419a.566.566 0 0 1 .563.568ZM11.11 14.31h-.094a.522.522 0 0 0-.52.52v3.73c-.07.344-.323.203-.46.1l-.086-.074-4.78-4.108a.522.522 0 0 0-.73.056l-.06.073a.522.522 0 0 0 .056.73l6.064 5.209v1.934a.522.522 0 0 0 .52.52h.095a.522.522 0 0 0 .52-.52v-7.65a.528.528 0 0 0-.525-.52Z"
      />
    </svg>
  );
};
export default Disciplina41;
