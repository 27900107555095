/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\eclamation-mark-outline.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const EclamationMarkOutline = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-eclamation-mark-outline', className].join(' ');
  return (
    <svg
      id="circle-warning-red-outline"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 48 48"
      fill="none"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M25.875 14H22.125V26.5H25.875V14ZM24 30.25C23.48 30.25 23.0375 30.4325 22.6725 30.7975C22.3075 31.1625 22.125 31.605 22.125 32.125C22.125 32.645 22.3075 33.0875 22.6725 33.4525C23.0375 33.8175 23.48 34 24 34C24.52 34 24.9625 33.8175 25.3275 33.4525C25.6925 33.0875 25.875 32.645 25.875 32.125C25.875 31.605 25.6925 31.1625 25.3275 30.7975C24.9625 30.4325 24.52 30.25 24 30.25ZM24 4C26.7433 4 29.33 4.52083 31.76 5.5625C34.19 6.60417 36.3167 8.03667 38.14 9.86C39.9633 11.6833 41.3958 13.81 42.4375 16.24C43.4792 18.67 44 21.2567 44 24C44 26.7783 43.4792 29.3742 42.4375 31.7875C41.3958 34.1992 39.9633 36.3167 38.14 38.14C36.3167 39.9633 34.19 41.3958 31.76 42.4375C29.33 43.4792 26.7433 44 24 44C21.2217 44 18.6258 43.4792 16.2125 42.4375C13.8008 41.3958 11.6833 39.9633 9.86 38.14C8.03667 36.3167 6.60417 34.1992 5.5625 31.7875C4.52083 29.3742 4 26.7783 4 24C4 21.2567 4.52083 18.67 5.5625 16.24C6.60417 13.81 8.03667 11.6833 9.86 9.86C11.6833 8.03667 13.8008 6.60417 16.2125 5.5625C18.6258 4.52083 21.2217 4 24 4ZM24 7.75C19.4867 7.75 15.65 9.33 12.49 12.49C9.33 15.65 7.75 19.4867 7.75 24C7.75 28.5133 9.33 32.35 12.49 35.51C15.65 38.67 19.4867 40.25 24 40.25C28.5133 40.25 32.35 38.67 35.51 35.51C38.67 32.35 40.25 28.5133 40.25 24C40.25 19.4867 38.67 15.65 35.51 12.49C32.35 9.33 28.5133 7.75 24 7.75Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default EclamationMarkOutline;
