/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-21.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina21 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-21', className].join(' ');
  return (
    <svg
      id="disciplina_biathlon"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M14.776 2.228c.035.396.182.773.425 1.083.242.31.569.54.938.66.37.12.765.126 1.137.016a1.96 1.96 0 0 0 .955-.635 2.06 2.06 0 0 0 .249-2.22 1.993 1.993 0 0 0-.79-.84 1.922 1.922 0 0 0-1.11-.25 1.96 1.96 0 0 0-1.343.71c-.338.41-.503.94-.46 1.476Z"
      />
      <path
        fill="currentColor"
        d="M21.861 6.83c.129-.224.17-.49.116-.744a1.037 1.037 0 0 0-.406-.627.988.988 0 0 0-1.355.186l-.96 1.187L16 4.607a.99.99 0 0 0-.423-.16c-.45-.518-1.223-.782-1.902-.734a.84.84 0 0 0-.433-.067l-1.166.118L15.365-.46a.336.336 0 0 0 .03-.368.33.33 0 0 0-.193-.159.316.316 0 0 0-.343.112L13.757.541 13.22.083a.231.231 0 0 0-.325.037.246.246 0 0 0 .024.335l.543.467-2.286 2.934-2.136.219a.85.85 0 0 0-.735.176L6.82 5.488a.922.922 0 0 0-.13 1.267l.143.18c.079.1.179.18.292.235L.267 12.799l.248.32 7.147-5.87a.837.837 0 0 0 .404-.183L9.29 6.049l.18-.02-1.321 1.684-5.29 5.012 1.767 1.532 3.827-5.84 1.934-2.482.813-.083-.11.128-2.653 3.204c-.186.22-.326.478-.412.756a2.2 2.2 0 0 0-.087.863c.02.292.097.577.226.838.129.26.307.493.525.682l4.642 4.138-1.237 4.277a1.262 1.262 0 0 0 .18 1.226H3.03a1.22 1.22 0 0 0 .435-.374l3.788-5.241c.016-.02.03-.045.045-.067.118-.107.228-.223.33-.346.696-.837 1.684-2.243 1.85-2.47l-.812-.617a2.844 2.844 0 0 1-1.05-1.532l-2.028 3.254a1.2 1.2 0 0 0-.297.288l-3.788 5.242a1.273 1.273 0 0 0-.23.934 1.28 1.28 0 0 0 .483.828c.057.042.118.08.181.112H0v1.031h21.071c.904-.18.751-2.082.751-2.082h-.657v.554c0 .56-.34.505-.34.505h-3.622l4.702-15.145-.044-.008Zm-5.138 15.138h-2.579c.021-.019.041-.039.06-.06.047-.078.087-.16.12-.244l1.506-5.081s.295-.992-.378-1.67l-3.315-3.658 3.212-3.9.01.01.371-.451 2.892 1.973a.96.96 0 0 0 .2.106.994.994 0 0 0 1.419-.138l1.12-1.38-4.638 14.493Z"
      />
    </svg>
  );
};
export default Disciplina21;
