/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-29.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina29 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-29', className].join(' ');
  return (
    <svg
      id="disciplina_skeleton"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M20.041 12.18c.378-.147.703-.4.934-.729a1.995 1.995 0 0 0 .037-2.243c-.22-.336-.538-.6-.91-.758a2.09 2.09 0 0 0-2.248.401 2.01 2.01 0 0 0-.58 1.025c-.086.39-.052.797.097 1.168.202.498.596.897 1.096 1.11.5.213 1.066.223 1.574.026Zm3.007 1.795-3.05.018-3.282-2.609s-.305-.308-.947-.294l-7.31-.028-6.272-4.775a1.366 1.366 0 0 0-1.895.214 1.308 1.308 0 0 0 .218 1.86l7.789 5.94-2.965-.004c-.248 0-.576.156-.73.354l-.014.018c-.155.198-.075.354.178.354l13.184-.036c-.005 0 .782.566.782.566s.356.326 1.017.326l1.69-.014c-.571 1.307-2.075 1.426-2.075 1.426l-16.242-.069c-.249 0-.577.157-.731.355l-.052.069c-.154.198-.075.354.178.354l16.946-.018c2-.056 2.824-2.112 2.824-2.112l.82-.028a.946.946 0 0 0 .813-.563.921.921 0 0 0 .011-.716.961.961 0 0 0-.353-.424.986.986 0 0 0-.532-.164Z"
      />
    </svg>
  );
};
export default Disciplina29;
