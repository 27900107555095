/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-38.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina38 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-38', className].join(' ');
  return (
    <svg
      id="disciplina_attivita-sportiva"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M4.366 14.927c.091.129.176.257.267.38.118.161.268.247.453.114s.156-.303.047-.469c-.132-.2-.27-.396-.418-.613l.587-.902c.129.183.21.366.35.478.107.086.311.144.426.098.179-.07.175-.262.074-.43-.006-.008-.008-.02-.016-.026-.616-.558-.359-1.062.14-1.566.09.128.19.267.287.407.118.168.278.24.456.122s.16-.295.05-.467c-.145-.226-.43-.48-.395-.677.053-.313.32-.59.52-.92.11.164.198.29.284.419.125.188.278.348.51.185.21-.15.128-.34.008-.522-.463-.705-.461-.707.146-1.533.141.2.278.39.409.583.122.18.274.322.49.181.216-.14.149-.339.026-.518-.153-.225-.316-.443-.456-.674-.043-.075-.068-.2-.038-.276.207-.531.41-1.066.654-1.58.25-.523.5-1.06.837-1.526.405-.558.842-.525 1.281.013.165.201.321.41.466.627a1.136 1.136 0 0 1 .189.858c-.12.715.232 1.055.934.873a7.556 7.556 0 0 0 3.198-1.703c.522-.47.75-1.043.588-1.771-.133-.593-.057-1.195.267-1.722.106-.172.353-.386.515-.369.215.022.512.207.588.397.567 1.4.63 2.78-.336 4.06-.154.204-.34.384-.515.58.812.368 1.463.208 1.87-.565.321-.611.515-1.288.76-1.94.04-.107.056-.222.092-.371.074.066.143.108.188.168.512.682 1.068 1.339 1.512 2.063.25.41.314.935.469 1.406.033.094.08.183.14.263.124.165.258.321.403.5-2.608 1.62-4.928 3.558-7.242 5.503l.053.081 7.473-4.78a1.43 1.43 0 0 1-.288 1.263c-.217.279-.478.52-.773.717-4.865 3.08-9.736 6.149-14.612 9.207-1.177.735-2.439 1.322-3.816 1.607a4.6 4.6 0 0 1-.558.077c-1.144.09-1.826-.514-1.903-1.657-.062-.921.276-1.717.753-2.468.208-.325.435-.638.679-.937.309-.38.644-.74.962-1.113.319-.371.633-.743.965-1.135Zm4.693-3.263 5.097 1.949.154-.404-5.088-1.944c-.056.142-.099.245-.163.399Zm-.193.479-.157.403 5.093 1.947.142-.408-5.078-1.942ZM8.27 13.42l5.1 1.949.142-.408-5.083-1.941-.16.4Z"
      />
    </svg>
  );
};
export default Disciplina38;
