/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-96.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina96 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-96', className].join(' ');
  return (
    <svg
      id="disciplina_condizioni-metereologiche"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M18.785 19.022c.474.902.998 1.784 1.409 2.715.318.722 0 1.365-.606 1.803-.606.438-1.305.452-1.927.01-.613-.438-.93-1.085-.624-1.801.4-.935.928-1.815 1.402-2.719l.346-.008Zm-9.43.012c-.452.797-.95 1.577-1.341 2.404-.392.827-.188 1.583.45 2.073a1.704 1.704 0 0 0 2.007.027c.67-.49.891-1.277.482-2.133-.384-.808-.869-1.567-1.309-2.347l-.29-.024Zm4.543.008c-.47.853-.99 1.682-1.383 2.567-.321.724-.107 1.413.539 1.912a1.663 1.663 0 0 0 2 0c.638-.498.867-1.175.535-1.91-.4-.88-.906-1.713-1.367-2.567l-.324-.002ZM8.142 9.874c-2.468.164-4.24 2.062-4.157 4.453.08 2.297 1.954 4.005 4.395 4.005h12.282c1.734 0 2.978-1.003 3.287-2.639.281-1.494-.638-2.959-2.184-3.57a1.278 1.278 0 0 1-.652-.647c-.508-1.302-1.406-2.123-2.787-2.33-.586-.087-1.195-.014-1.816-.014-.823-2.044-2.167-3.09-3.955-3.04-1.932.053-3.09 1.15-3.95 3.75l-.463.032Zm-5.497 3.553c.116-.252.257-.495.346-.756.656-1.936 1.953-3.134 4.006-3.408.447-.06.612-.238.745-.653.605-1.88 2.139-3.14 3.972-3.14.926 0 1.132-.51 1.365-1.229h-1.423l.267-1.432-1.433.418-.105-1.47-1.26.772-.515-1.41-.99 1.141L6.785 1l-.732 1.318-1.107-.984-.345 1.441-1.362-.64.09 1.483-1.487-.28c.16.535.281.955.418 1.413L.77 4.87l.833 1.27-1.515.521 1.196.987L0 8.49l1.334.698-.963 1.136 1.433.345-.632 1.335h1.47l-.248 1.34.25.084Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina96;
