/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-131.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina131 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-131', className].join(' ');
  return (
    <svg
      id="disciplina_skateboarding"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M19.58 20.652a.587.587 0 0 0-.792.276c-.313.636-1.622.456-2.463.324-.024 0-12.23-1.968-12.23-1.968a.598.598 0 0 0-.685.492.598.598 0 0 0 .492.684l2.175.348c-.096.144-.156.3-.192.48a1.193 1.193 0 0 0 2.355.384 1.24 1.24 0 0 0-.036-.516l6.163.996c-.096.144-.156.3-.193.48a1.193 1.193 0 0 0 2.355.384 1.345 1.345 0 0 0-.024-.492h.012c.901.144 2.68.24 3.316-1.056a.582.582 0 0 0-.252-.816ZM11.016 4.716c.288.156.624.252.949.288a2.513 2.513 0 0 0 2.175-.9 2.535 2.535 0 0 0 .456-2.34A2.521 2.521 0 0 0 12.937.12c-.805-.24-1.694-.072-2.33.468a2.53 2.53 0 0 0-.902 2.208 2.574 2.574 0 0 0 1.31 1.92Zm10.969-1.404a1.03 1.03 0 0 0-1.19-.816l-4.493.864c-.252.06-.985.42-1.634.72-.084.156-.18.3-.288.432a2.719 2.719 0 0 1-2.355.972 2.865 2.865 0 0 1-1.033-.312 2.046 2.046 0 0 1-.469-.324c-1.514.012-3.256.048-4.385.336L2.485 6.84a.815.815 0 0 0-.445.996c.132.432.601.672 1.034.528l3.604-1.14 2.86.108-.71 4.26c-.288 2.016 1.55 3.276 1.55 3.276L8.6 18.396a1.015 1.015 0 0 0 .396 1.332c.481.288 1.118.132 1.394-.36l2.463-4.14c.132-.228.18-.504.144-.768l1.478.132-2.163 4.212c-.24.48-.072 1.08.397 1.344.492.276 1.117.12 1.393-.372l3.268-5.652c.072-.132.12-.276.144-.432a1.163 1.163 0 0 0-.997-1.308l-.06-.012-2.475-.336.721-4.356c.036-.204.048-.408.024-.612.024-.012.048-.036.072-.048l2.21-1.5 4.206-1.02c.528-.132.865-.648.769-1.188Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina131;
