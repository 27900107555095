/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\slots.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Slots = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-slots', className].join(' ');
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 4.99c0-.664-.545-1.209-1.208-1.209-.664 0-1.209.545-1.209 1.208 0 .575.413 1.062.958 1.18v1.93a.466.466 0 0 1-.472.472h-.5V7.347a.855.855 0 0 0-.855-.854h-.148v-.457a1.43 1.43 0 0 0-1.43-1.43h-.633C9.782 3.663 8.75 3 7.542 3c-1.209 0-2.255.663-2.712 1.592h-.648a1.43 1.43 0 0 0-1.43 1.43v.456h-.147a.855.855 0 0 0-.855.855v3.02c0 .472.383.855.855.855h.147v.442c0 .796.649 1.43 1.43 1.43h6.705a1.43 1.43 0 0 0 1.43-1.43v-.442h.147a.855.855 0 0 0 .854-.854V9.116h.487c.575 0 1.031-.472 1.031-1.032v-1.93a1.2 1.2 0 0 0 .914-1.165ZM9.089 7.052v3.61H5.994v-3.61H9.09Zm-6.764 3.315V7.333c0-.162.132-.295.294-.295h2.815v3.61h-2.83c-.161.015-.28-.117-.28-.28Zm10.433 0a.296.296 0 0 1-.294.295H9.649v-3.61h2.815c.162 0 .294.132.294.294v3.021ZM4.344 8.364c0 .03 0 .074-.015.103.221.03.398.221.398.457a.466.466 0 0 1-.472.472.497.497 0 0 1-.25-.074l.191.413c.015.03 0 .073-.044.073h-.575c-.03 0-.059-.044-.044-.073l.192-.413a.497.497 0 0 1-.25.074.466.466 0 0 1-.06-.929c-.014-.03-.014-.059-.014-.103 0-.265.206-.471.471-.471s.472.206.472.471Zm6.926-.457a.09.09 0 0 0-.118 0l-.692.693a.472.472 0 0 0 .338.81c.089 0 .177-.029.25-.073l-.176.398c-.015.03.015.073.044.073h.575c.044 0 .059-.044.044-.073l-.177-.398a.552.552 0 0 0 .266.074c.132 0 .25-.045.339-.148a.5.5 0 0 0-.015-.678l-.678-.678ZM7.615 9.705c-.044.06-.118.06-.162 0l-.56-.81a.071.071 0 0 1 0-.103l.56-.781c.044-.06.118-.06.162 0l.575.795c.03.03.03.074 0 .104l-.575.795Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Slots;
