/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-hockey-inliine.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const DisciplinaHockeyInliine = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-hockey-inliine', className].join(' ');
  return (
    <svg
      id="disciplina_hockey-inliine"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M5.039 19.11a2.5 2.5 0 0 1 1.376.413c.407.268.724.65.912 1.097a2.416 2.416 0 0 1-.537 2.664 2.506 2.506 0 0 1-2.699.53 2.47 2.47 0 0 1-1.111-.9 2.423 2.423 0 0 1 .308-3.087 2.492 2.492 0 0 1 1.75-.716Zm13.922 0a2.5 2.5 0 0 1 1.376.413c.407.268.724.65.912 1.097.187.447.236.938.14 1.412-.095.474-.33.91-.677 1.252a2.506 2.506 0 0 1-2.699.53 2.47 2.47 0 0 1-1.11-.9 2.422 2.422 0 0 1 .307-3.087 2.492 2.492 0 0 1 1.751-.716ZM17.82 10.46a9.537 9.537 0 0 1-2.043-.213l-.385 1.874-1.428-.287.418-2.028a7.41 7.41 0 0 1-.794-.392l-.958 1.621-1.258-.725 1.03-1.74a7.795 7.795 0 0 1-.719-.714l-1.33 1.333-1.037-1.01 1.494-1.498a9.784 9.784 0 0 1-.498-.904l-1.697.802-.628-1.298 1.783-.842C9.49 3.588 9.41 3 9.41 3H6.23v1.385a4.277 4.277 0 0 1-1.277 3.038 4.389 4.389 0 0 1-3.078 1.261h-.118C1.412 10.656 1 12.134 1 14.81v.977h22v-.977c0-2.825-2.32-4.351-5.181-4.351ZM2.222 3c0 1.767-.101 3.112-.245 4.244a2.919 2.919 0 0 0 1.984-.873 2.846 2.846 0 0 0 .815-1.987V3H2.222ZM1.58 17.224l.913 1.883a3.956 3.956 0 0 1 2.762-.92A3.945 3.945 0 0 1 7.898 19.4l.622-.526h6.96l.622.526a3.944 3.944 0 0 1 2.642-1.215 3.954 3.954 0 0 1 2.762.921l.914-1.883H1.58ZM19.135 6.03c-1.646.25-3.063-.078-3.164-.73l.298 1.917h.009c.109.7 1.527 1.063 3.168.817 1.64-.246 2.885-1.017 2.775-1.716h.009L21.932 4.4c.1.65-1.15 1.381-2.797 1.63Zm-.4-2.574c1.647-.25 3.063.078 3.164.73.102.654-1.15 1.385-2.798 1.634-1.648.25-3.063-.078-3.164-.73-.101-.654 1.152-1.385 2.798-1.634Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default DisciplinaHockeyInliine;
