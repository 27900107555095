import { CartScommessaAvvenimentoVirtual, CartScommessaEsitoVirtual, VirtualBonusEntity } from 'types/swagger';
import { CostantiCalcoloVirtualTicket, default as calcoloVirtualTicket } from './utils/calcoloCarrello/utils';
import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SviluppoSistemi, VirtualTicket as Ticket } from './utils/calcoloCarrello/types';
import {
  TicketErrore,
  TicketErroreBetReferral,
  TicketErroreSistema,
  TicketErroreVendita,
  TicketMessaggiErrori,
} from 'features/carrello/types';

import { ScommessaAddedPayload } from './types';
import { hasKey } from 'features/sport/utils/utils';

export const PUNTATA_SINGOLA_MULTIPLA = CostantiCalcoloVirtualTicket.DefaultPuntata / 100;

// Define a type for the slice state
export interface VirtualTicketState {
  ticket?: Ticket;
  esiti: Record<string, boolean>;
  isOpenCombinazioni: boolean;
  puntataSingolaMultipla: number;
  puntataPerScommessa: { [idSistema: string]: number };
  sistemiDaGiocare: string[];
  errori: TicketMessaggiErrori;
  isScommettibile: boolean;
  isOpenSettings: boolean;
  lastTicket?: Ticket;
  importTicketAlertState: { isOpen: boolean; ticket?: Ticket };
  bonusConfiguration?: Array<VirtualBonusEntity>;
}

const hasTicket = (state: Draft<VirtualTicketState>): state is Draft<VirtualTicketState & { ticket: Ticket }> =>
  !!state.ticket;

// Define the initial state using that type
const initialState: VirtualTicketState = {
  esiti: {},
  isOpenCombinazioni: false,
  puntataSingolaMultipla: PUNTATA_SINGOLA_MULTIPLA,
  puntataPerScommessa: {},
  sistemiDaGiocare: [],
  errori: {
    messaggi: [],
    erroriCarrello: [],
    erroriEvento: [],
    erroriVendita: [],
    erroriSistema: {},
    erroriBetReferral: [],
  },
  isScommettibile: true,
  isOpenSettings: false,
  importTicketAlertState: { isOpen: false },
};

export const virtualTicketSlice = createSlice({
  name: 'virtualTicket',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    bonusConfigurationAdded: (state, action: PayloadAction<Array<VirtualBonusEntity>>) => {
      state.bonusConfiguration = action.payload;
    },
    lastTicketRemoved: (state) => {
      state.lastTicket = undefined;
    },
    ticketImported: (state, action: PayloadAction<Ticket>) => {
      const newTicket = calcoloVirtualTicket(
        {
          avvenimenti: action.payload.avvenimenti,
        },
        undefined,
        state.bonusConfiguration
      );
      state.ticket = newTicket;
      action.payload.avvenimenti.map((avvenimento) => {
        if (avvenimento.esiti) {
          avvenimento.esiti
            .filter(({ id }) => !!id)
            .map(
              (esito) =>
                (state.esiti = {
                  ...state.esiti,
                  [`${esito.id}`]: true,
                })
            );
        }
      });
      state.errori.erroriCarrello = newTicket.errori;
      state.errori.erroreInserimento = newTicket.erroreInserimento;
      state.errori.messaggi = newTicket.messaggi;
      state.isScommettibile =
        state.errori.erroriCarrello.length > 0 ||
        state.errori.erroreInserimento !== undefined ||
        state.errori.erroriEvento.length > 0
          ? false
          : true;
    },
    ticketSaved: (state) => {
      state.lastTicket = state.ticket;
    },
    toggleOpenSettings: (state, action: PayloadAction<boolean>) => {
      state.isOpenSettings = action.payload;
    },
    // venditaAlertUpdated: (state, action: PayloadAction<{ response: string; value: boolean }>) => {
    //   // @ts-ignore FIXME: Questa prop non esiste
    //   state.venditaAlert[action.payload.response] = action.payload.value;
    // },
    isScommettibileToggled: (state, action: PayloadAction<boolean>) => {
      state.isScommettibile = action.payload;
    },
    erroriSistemiAdded: (
      state,
      action: PayloadAction<{
        idSistema: string;
        errore: TicketErroreSistema;
      }>
    ) => {
      state.errori['erroriSistema'][action.payload.idSistema] = action.payload.errore;
    },
    erroriSistemiRemoved: (
      state,
      action: PayloadAction<{
        idSistema: string;
        errore: string;
      }>
    ) => {
      if (hasKey(state.errori['erroriSistema'], [action.payload.idSistema])) {
        delete state.errori['erroriSistema'][action.payload.idSistema];
      }
    },
    erroriAdded: (
      state,
      action: PayloadAction<{
        tipoErrore:
          | 'messaggi'
          | 'erroriCarrello'
          | 'erroreInserimento'
          | 'erroriEvento'
          | 'erroriVendita'
          | 'erroriBetReferral';
        errore: TicketErroreVendita | TicketErrore | TicketErroreBetReferral;
      }>
    ) => {
      // TODO: rivedere con Carlo e Denis
      // @ts-ignore FIXME: Questo non potrà mai funzionare se errori di tipo diverso possono avere forme diverse
      state.errori[action.payload.tipoErrore] = [{ type: action.payload.errore }];
    },
    erroriRemoved: (
      state,
      action: PayloadAction<{
        tipoErrore:
          | 'messaggi'
          | 'erroriCarrello'
          | 'erroreInserimento'
          | 'erroriEvento'
          | 'erroriVendita'
          | 'erroriBetReferral';
        errore: { type: string; value?: string };
      }>
    ) => {
      // @ts-ignore FIXME: Vedi sopra
      state.errori[action.payload.tipoErrore] = state.errori[action.payload.tipoErrore].filter(
        (errorToRemove) => errorToRemove.type !== action.payload.errore.type
      );
    },
    sistemiDaGiocareUpdated: (state, action: PayloadAction<string[]>) => {
      state.sistemiDaGiocare = action.payload;
    },
    puntataSingolaMultiplaUpdated: (state, action: PayloadAction<number>) => {
      state.puntataSingolaMultipla = action.payload;
    },
    puntataPerScommesseSistemaUpdated: (
      state,
      action: PayloadAction<{
        idSistema: string;
        puntata: number;
      }>
    ) => {
      state.puntataPerScommessa[action.payload.idSistema] = Math.round(action.payload.puntata * 100);
    },
    ticketUpdatedBySistemi: (state, action: PayloadAction<string[]>) => {
      const puntataPerScommessa: { [idSistema: string]: number } = Object.fromEntries(
        Object.entries(state.puntataPerScommessa as { [idSistema: string]: number }).filter(([key]) =>
          action.payload.some((sistema) => sistema === key)
        )
      );
      const newTicket = calcoloVirtualTicket(
        {
          avvenimenti: state.ticket?.avvenimenti ?? [],
          sistema: true,
          puntataPerScommessa: puntataPerScommessa,
        },
        undefined,
        state.bonusConfiguration
      );
      state.ticket = newTicket;
      state.errori.erroriCarrello = newTicket.errori;
      state.errori.erroreInserimento = newTicket.erroreInserimento;
      state.errori.messaggi = newTicket.messaggi;
      state.isScommettibile =
        state.errori.erroriCarrello.length > 0 ||
        state.errori.erroreInserimento !== undefined ||
        state.errori.erroriEvento.length > 0
          ? false
          : true;
    },
    ticketUpdated: (
      state,
      action: PayloadAction<{
        sistema?: boolean;
        puntata?: number; // format centesimi x 100
        puntataPerScommessa?: { [idSistema: string]: number }; // idSistema 1/2 per esempio
        maxVincita?: number;
      }>
    ) => {
      const newTicket = calcoloVirtualTicket(
        {
          avvenimenti: state.ticket?.avvenimenti ?? [],
          sistema: action.payload.sistema,
          puntata: Math.round(action.payload.puntata! * 100),
          puntataPerScommessa: action.payload.puntataPerScommessa,
          maxVincita: action.payload.maxVincita,
        },
        undefined,
        state.bonusConfiguration
      );
      state.ticket = newTicket;
      state.puntataPerScommessa = initialState.puntataPerScommessa;
      state.sistemiDaGiocare = initialState.sistemiDaGiocare;
      state.errori.erroriCarrello = newTicket.errori;
      state.errori.erroreInserimento = newTicket.erroreInserimento;
      state.errori.messaggi = newTicket.messaggi;
      state.isScommettibile =
        state.errori.erroriCarrello.length > 0 ||
        state.errori.erroreInserimento !== undefined ||
        state.errori.erroriEvento.length > 0
          ? false
          : true;
    },
    ticketRemoved: (state) => {
      applyInitialState(state, initialState);
    },
    calcoloVirtualTicketVincite: (state, action: PayloadAction<{ sviluppoSistemi: SviluppoSistemi }>) => {
      // TODO: Aggiungere un errore via console in caso lo stato non abbia il ticket?
      // @ts-ignore
      if (hasTicket(state)) {
        const sviluppoSistemi = action.payload.sviluppoSistemi;
        let minVincita: number | undefined;
        let maxVincita = 0;
        sviluppoSistemi.dettaglioSviluppoSistemi.forEach((dettaglioSviluppoSistema) => {
          let puntata =
            state.ticket.puntataPerScommessa[
              `${dettaglioSviluppoSistema.codiceSistema}/${state.ticket.avvenimenti.length}`
            ];
          let currentMinVincita = Math.floor((puntata * dettaglioSviluppoSistema.quotaMinimaSistema) / 100);
          if (!minVincita || currentMinVincita < minVincita) minVincita = currentMinVincita;
          maxVincita += Math.floor((puntata * dettaglioSviluppoSistema.quotaMassimaSistema) / 100);
        });
        state.ticket.possibileVincitaMin = minVincita!;
        state.ticket.possibileVincitaMax = maxVincita;
      }
    },
    avvenimentoUpdatedOnFisso: (
      state,
      action: PayloadAction<{
        idAvvenimento: number;
        isFisso: boolean;
      }>
    ) => {
      const avvenimentoFound = state.ticket?.avvenimenti.find(
        (avvenimento) => avvenimento.idAvvenimento === action.payload.idAvvenimento
      );
      if (avvenimentoFound) {
        avvenimentoFound.isFisso = action.payload.isFisso;
      }
      const newTicket = calcoloVirtualTicket(
        {
          avvenimenti: state.ticket?.avvenimenti ?? [],
          sistema: state.ticket?.sistema,
        },
        undefined,
        state.bonusConfiguration
      );
      state.ticket = newTicket;
      state.sistemiDaGiocare = initialState.sistemiDaGiocare;
      state.puntataPerScommessa = initialState.puntataPerScommessa;
      state.errori.erroriCarrello = newTicket.errori;
      state.errori.erroreInserimento = newTicket.erroreInserimento;
      state.errori.messaggi = newTicket.messaggi;
      state.isScommettibile =
        state.errori.erroriCarrello.length > 0 ||
        state.errori.erroreInserimento !== undefined ||
        state.errori.erroriEvento.length > 0
          ? false
          : true;
    },
    // TODO: scommessaAdded: modificarla ad hoc per virtual
    scommessaAdded: (state, action: PayloadAction<ScommessaAddedPayload>) => {
      const { ticketAvvenimento, ticketEsito } = action.payload;
      const avvenimento: CartScommessaAvvenimentoVirtual = {
        ...ticketAvvenimento,
        id: ticketAvvenimento.key,
        isFisso: false,
        esiti: [
          {
            /* ...ticketScommessa, */
            ...ticketEsito,
            quota: Math.round(ticketEsito.quota * 100), // convenzione SNAI per calcoli ticketEsito.quota
            flagBonus: false,
            idTipoInfoAggiuntiva: 0,
            infoAggiuntiva: '',
            isActive: true,
            maxCombinazioni: 30,
            minCombinazioni: 0,
            multipla: 1,
          },
        ],
      };
      const newTicket = calcoloVirtualTicket(
        {
          avvenimenti: state.ticket?.avvenimenti ?? [],
          puntata: Math.round(state.puntataSingolaMultipla * 100),
        },
        avvenimento,
        state.bonusConfiguration
      );
      state.ticket = newTicket;
      if (newTicket.erroreInserimento === undefined) {
        state.esiti = {
          ...state.esiti,
          [ticketEsito?.id]: true,
        };
      }
      state.puntataPerScommessa = initialState.puntataPerScommessa;
      state.sistemiDaGiocare = initialState.sistemiDaGiocare;
      state.errori.erroriCarrello = newTicket.errori;
      state.errori.erroreInserimento = newTicket.erroreInserimento;
      state.errori.messaggi = newTicket.messaggi;
      state.isScommettibile =
        state.errori.erroriCarrello.length > 0 ||
        state.errori.erroreInserimento !== undefined ||
        state.errori.erroriEvento.length > 0
          ? false
          : true;
    },
    // TODO: scommessaRemoved: modificarla ad hoc per virtual
    scommessaRemoved: (state, { payload }: PayloadAction<string | undefined>) => {
      // ticketEsito.id

      if (!payload) return;

      let isEsitoActive: boolean = true;

      const filterEsitiByEsitoIdToRemove = (esiti: CartScommessaEsitoVirtual[], esitoIdToRemove: string) =>
        esiti.filter((esito) => {
          if (esito.id === esitoIdToRemove) {
            isEsitoActive = false;
          }
          return esito.id !== esitoIdToRemove;
        });

      const filteredAvvenimenti = state.ticket?.avvenimenti.filter((avvenimento) => {
        const filteredEsiti = filterEsitiByEsitoIdToRemove(avvenimento.esiti ?? [], payload);
        avvenimento.esiti = filteredEsiti;
        return filteredEsiti.length > 0;
      });
      if (filteredAvvenimenti && filteredAvvenimenti.length > 0) {
        const newTicket = calcoloVirtualTicket(
          {
            avvenimenti: filteredAvvenimenti,
            puntata: !state.ticket?.sistema ? Math.round(state.puntataSingolaMultipla * 100) : undefined,
            puntataPerScommessa: state.ticket?.sistema ? state.puntataPerScommessa : undefined,
          },
          undefined,
          state.bonusConfiguration
        );
        state.ticket = newTicket;
        const nextEsiti = state.esiti;
        delete nextEsiti[payload];
        state.esiti = nextEsiti;
        state.puntataPerScommessa = initialState.puntataPerScommessa;
        state.sistemiDaGiocare = initialState.sistemiDaGiocare;
        state.errori.erroriCarrello = newTicket.errori;
        state.errori.erroreInserimento = newTicket.erroreInserimento;
        state.errori.messaggi = newTicket.messaggi;
        if (!isEsitoActive) {
          const newErroriEvento = state.errori.erroriEvento.filter((esitoIdInErrore) => esitoIdInErrore !== payload);
          state.errori.erroriEvento = newErroriEvento;
        }
        state.isScommettibile =
          state.errori.erroriCarrello.length > 0 ||
          state.errori.erroreInserimento !== undefined ||
          state.errori.erroriEvento.length > 0
            ? false
            : true;
      } else {
        applyInitialState(state, initialState);
      }
    },
    isOpenCombinazioniUpdated: (state, action: PayloadAction<boolean>) => {
      state.isOpenCombinazioni = action.payload;
    },
    importTicketDialogChange: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        ticket: Ticket | undefined;
      }>
    ) => {
      state.importTicketAlertState.ticket = action.payload.ticket;
      state.importTicketAlertState.isOpen = action.payload.isOpen;
    },
    importTicketDialogOpenChange: (state, action: PayloadAction<boolean>) => {
      state.importTicketAlertState.isOpen = action.payload;
    },
  },
});

const applyInitialState = (state, initialState: VirtualTicketState) => {
  state.ticket = initialState.ticket;
  state.esiti = initialState.esiti;
  state.isOpenCombinazioni = initialState.isOpenCombinazioni;
  state.puntataPerScommessa = initialState.puntataPerScommessa;
  state.puntataSingolaMultipla = initialState.puntataSingolaMultipla;
  state.sistemiDaGiocare = initialState.sistemiDaGiocare;
  state.errori = initialState.errori;
  state.isScommettibile = initialState.isScommettibile;
  state.isOpenSettings = initialState.isOpenSettings;
};

export const {
  scommessaAdded,
  scommessaRemoved,
  ticketUpdated,
  ticketUpdatedBySistemi,
  ticketRemoved,
  ticketSaved,
  avvenimentoUpdatedOnFisso,
  puntataSingolaMultiplaUpdated,
  puntataPerScommesseSistemaUpdated,
  sistemiDaGiocareUpdated,
  isOpenCombinazioniUpdated,
  erroriAdded,
  erroriRemoved,
  erroriSistemiAdded,
  erroriSistemiRemoved,
  isScommettibileToggled,
  toggleOpenSettings,
  ticketImported,
  lastTicketRemoved,
  importTicketDialogChange,
  importTicketDialogOpenChange,
  bonusConfigurationAdded,
  calcoloVirtualTicketVincite,
} = virtualTicketSlice.actions;

export default virtualTicketSlice.reducer;
