/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\meteo-bello.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MeteoBello = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-meteo-bello', className].join(' ');
  return (
    <svg
      id="meteo-bello"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M7.33268 3.33464V0.667969H8.66602V3.33464H7.33268ZM11.766 5.16797L10.8493 4.2513L12.716 2.33464L13.6493 3.28464L11.766 5.16797ZM12.666 8.66797V7.33464H15.3327V8.66797H12.666ZM7.33268 15.3346V12.668H8.66602V15.3346H7.33268ZM4.23268 5.13464L2.33268 3.28464L3.28268 2.3513L5.16602 4.23464L4.23268 5.13464ZM12.6993 13.668L10.8493 11.7513L11.7493 10.8513L13.6493 12.6846L12.6993 13.668ZM0.666016 8.66797V7.33464H3.33268V8.66797H0.666016ZM3.28268 13.668L2.34935 12.718L4.21602 10.8513L4.69935 11.3013L5.18268 11.768L3.28268 13.668ZM7.99935 12.0013C6.88824 12.0013 5.94379 11.6124 5.16602 10.8346C4.38824 10.0569 3.99935 9.11241 3.99935 8.0013C3.99935 6.89019 4.38824 5.94575 5.16602 5.16797C5.94379 4.39019 6.88824 4.0013 7.99935 4.0013C9.11046 4.0013 10.0549 4.39019 10.8327 5.16797C11.6105 5.94575 11.9993 6.89019 11.9993 8.0013C11.9993 9.11241 11.6105 10.0569 10.8327 10.8346C10.0549 11.6124 9.11046 12.0013 7.99935 12.0013ZM7.99935 10.668C8.73268 10.668 9.36046 10.4069 9.88268 9.88464C10.4049 9.36241 10.666 8.73464 10.666 8.0013C10.666 7.26797 10.4049 6.64019 9.88268 6.11797C9.36046 5.59575 8.73268 5.33464 7.99935 5.33464C7.26602 5.33464 6.63824 5.59575 6.11602 6.11797C5.59379 6.64019 5.33268 7.26797 5.33268 8.0013C5.33268 8.73464 5.59379 9.36241 6.11602 9.88464C6.63824 10.4069 7.26602 10.668 7.99935 10.668Z"
        fill="#DEA410"
      />
    </svg>
  );
};
export default MeteoBello;
