/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-24.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina24 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-24', className].join(' ');
  return (
    <svg
      id="disciplina_curling"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M19.038 9.534a2.165 2.165 0 0 0-2.163-2.158h-.018v-.614A3.767 3.767 0 0 0 13.095 3H8.091a1.243 1.243 0 0 0 0 2.486H13.1a1.277 1.277 0 0 1 1.276 1.276v.614H7.129a2.165 2.165 0 0 0-2.162 2.158A5.706 5.706 0 0 0 0 15.185v.572a5.7 5.7 0 0 0 5.694 5.694h12.612A5.7 5.7 0 0 0 24 15.757v-.572a5.698 5.698 0 0 0-4.962-5.651Zm-5.943-5.02H8.091a.263.263 0 1 1 0-.524H13.1A2.784 2.784 0 0 1 15.88 6.77v.605h-.525v-.605a2.264 2.264 0 0 0-2.26-2.256ZM7.13 8.362h9.746a1.186 1.186 0 0 1 1.183 1.13H5.948a1.182 1.182 0 0 1 1.181-1.13Zm-1.435 2.106h12.612a4.722 4.722 0 0 1 4.535 3.424H1.159a4.723 4.723 0 0 1 4.536-3.424Zm17.335 4.718v.572c0 .108-.005.212-.014.315H.99c-.005-.103-.014-.211-.014-.314v-.573c0-.108.004-.21.014-.314h22.025c.01.103.014.206.014.314Zm-4.718 5.29H5.694a4.723 4.723 0 0 1-4.536-3.423h21.688a4.723 4.723 0 0 1-4.535 3.424Z"
      />
    </svg>
  );
};
export default Disciplina24;
