/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-sa.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneSa = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-sa', className].join(' ');
  return (
    <svg
      id="nazione-sa"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <mask id="a" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="-1" y="0" width="17" height="16">
        <path d="M0 0h15.997v15.998H-.001V0Z" fill="#fff" />
      </mask>
      <g fillRule="evenodd" clipRule="evenodd" mask="url(#a)">
        <path d="M-4 0h23.996v15.998H-4V0Z" fill="#199D00" />
        <path
          d="M2.047 4.535c-.025.374-.06 1.032.258 1.1.384.035.171-.652.31-.774.03-.065.078-.065.08.016v.58c-.002.194.12.245.217.287.103-.01.168-.006.21.09l.045 1.01s.235.065.245-.568c.013-.37-.074-.683-.023-.754 0-.071.09-.074.155-.042.1.07.145.161.3.122.239-.064.38-.18.387-.36a1.645 1.645 0 0 0-.11-.52c.01-.032-.048-.113-.035-.145.042.064.106.061.122 0-.042-.136-.103-.262-.206-.316-.084-.075-.206-.058-.252.096a.839.839 0 0 0 .194.558c.04.088.057.185.048.281-.067.042-.135.023-.193-.035 0 0-.19-.146-.19-.175.051-.322.012-.358-.017-.448-.019-.123-.077-.161-.125-.245-.049-.052-.11-.052-.142 0-.084.145-.045.455.016.593.045.13.11.21.077.21-.026.07-.077.055-.116-.029a1.974 1.974 0 0 1-.068-.545c-.016-.145-.032-.452-.129-.53-.058-.076-.145-.038-.174.033-.008.14-.005.28.01.42.064.225.084.432.116.667.01.316-.184.139-.174-.02.045-.202.032-.525-.007-.606-.032-.08-.067-.1-.142-.087-.064-.006-.216.162-.258.445 0 0-.038.146-.054.271-.023.145-.117.249-.184-.019-.058-.194-.094-.674-.19-.561Z"
          fill="#fff"
        />
        <path d="m3.092 6.07-1 .48c.01-.225.475-.638.79-.644.207.006.156.08.21.16v.004Z" fill="#fff" />
        <path
          d="M2.918 6.383C2.39 7.74 4.15 7.93 4.348 6.438c.018-.062.096-.123.106-.023-.042 1.355-1.362 1.445-1.587 1.02-.055-.097-.071-.323-.078-.459-.032-.264-.174-.16-.193.104-.023.145-.02.187-.02.329.071 1.064 1.774.606 2.052-.275.145-.487-.026-.848.055-.848.167.184.406.026.458-.039.022-.032.077-.051.116-.01.129.097.364.05.412-.115.026-.165.049-.333.055-.507-.106.032-.187.055-.193.097l-.023.148c-.01.046-.103.049-.106-.013-.042-.183-.21-.21-.313.078-.068.058-.194.064-.206-.016.016-.194-.062-.22-.217-.13l-.151-1.128c.064 0 .126.045.184-.03-.065-.202-.204-.612-.278-.644-.038-.045-.067-.016-.116-.007-.084.026-.161.097-.135.236l.258 1.619c.013.068-.045.161-.12.151-.125-.087-.158-.258-.37-.251-.159 0-.336.17-.355.335a.737.737 0 0 0 0 .384c.106.13.238.12.354.087.094-.038.171-.132.207-.11.022.03.003.34-.448.581-.271.123-.49.148-.607-.074-.07-.139.007-.668-.17-.548v.003Z"
          fill="#fff"
        />
        <path
          d="M5.153 5c.107-.036.607-.614.607-.614l-.074-.058c-.03-.025-.026-.048 0-.074.122-.07.083-.232.019-.303a.303.303 0 0 0-.271 0c-.087.087-.106.22-.039.303.065.032.136.097.09.136-.206.219-.767.596-.702.612.012.017.36.017.37 0V5ZM2.125 7.03c-.187.3-.203.748-.097.88.052.065.142.091.21.072.12-.052.17-.29.142-.378-.039-.064-.071-.07-.11-.02-.084.169-.12.053-.126-.041a2.17 2.17 0 0 1 .023-.474c.022-.132 0-.094-.042-.039Zm8.034-.484c-.18-.39-.432-.777-.513-.925-.243-.385-.501-.76-.774-1.126-.193-.232.32.097-.064-.364-.145-.13-.155-.133-.278-.236-.06-.045-.21-.122-.235.007a.758.758 0 0 0 .013.277c.013.065.106.171.155.235a17.404 17.404 0 0 1 1.68 2.703c.084-.038.064-.503.016-.57Z"
          fill="#fff"
        />
        <path
          d="M9.362 7.86c-.035.042.088.213.249.213.27-.032.506-.184.725-.58.059-.097.162-.29.165-.449.023-.903-.045-1.606-.18-2.258a.445.445 0 0 1 .01-.16c.015-.02.073 0 .106-.05.045-.048-.123-.438-.217-.586-.035-.068-.048-.113-.103.006-.061.097-.097.268-.097.426.13.89.171 1.67.255 2.558.007.087-.006.212-.064.26a2.509 2.509 0 0 1-.849.62Zm3.639-.003c-.194.11-.194.239-.036.245.268-.032.587-.055.806-.387.058-.094.13-.345.133-.497.02-.903-.013-1.58-.149-2.232-.006-.064-.035-.21-.026-.229.02-.045.107.004.14-.048.044-.045-.226-.397-.323-.548-.036-.065-.049-.11-.103.01-.062.096-.081.27-.059.422.146.967.252 1.693.271 2.548-.01.084-.012.129-.051.229-.084.11-.178.245-.268.31-.087.064-.274.128-.335.177Z"
          fill="#fff"
        />
        <path
          d="M13.146 6.99v-.591a1.065 1.065 0 0 0-.097-.426c-.058-.129-.023-.229-.048-.367-.026-.136-.02-.342-.058-.504-.013-.064-.046-.264-.033-.283.013-.046.075 0 .104-.052.045-.048-.155-.564-.255-.71-.036-.064-.1-.045-.184.065-.074.068-.045.229-.016.38.193 1.01.335 1.926.306 2.884-.01.084.284-.242.28-.397Zm-1.43-1.252c-.118 0-.373-.239-.45-.374a.25.25 0 0 1 .015-.2c.046-.033.117-.065.168-.033 0 0 .052.078.042.088.065.032.097.012.103-.017.004-.045-.022-.074-.022-.129.032-.138.193-.16.251-.07.045.054.062.17.068.251 0 .039-.064-.01-.103 0-.036.013-.045.055-.049.094-.006.103-.019.267-.022.387v.003ZM9.473 7.24c.032-.31-.01-.855-.013-1.036-.013-.428-.084-1.257-.116-1.393-.039-.261.107.03.087-.122-.048-.258-.193-.44-.361-.678-.055-.074-.052-.09-.139.02-.09.213-.01.358.013.522.123.539.194 1.032.226 1.522.036.51.04 1.022.013 1.533.093.006.242-.149.29-.368Z"
          fill="#fff"
        />
        <path
          d="M13.562 6.747c-.216-.358-.539-.748-.626-.893-.289-.405-.592-.8-.91-1.184-.267-.28.123-.045-.051-.261-.148-.161-.194-.213-.316-.31-.065-.042-.103-.119-.123.013-.01.116-.016.252-.01.352 0 .055.059.155.107.216.645.797 1.355 1.613 1.926 2.635.08-.045.054-.503 0-.568h.003Z"
          fill="#fff"
        />
        <path
          d="M3.831 6.083c-.016.029-.052.064-.039.097.026.032.046.042.084.045.032 0 .084.006.094-.013a.103.103 0 0 0 .016-.103c-.032-.09-.136-.058-.155-.026Z"
          fill="#1BA400"
        />
        <path
          d="M11.069 11.33c.287.01.474.013.729.043l.296-.033c.333-.032.349.474.349.474-.004.297-.12.31-.265.342-.084.013-.129-.048-.17-.113a.44.44 0 0 1-.223.013c-.12-.006-.239-.006-.355-.016-.13-.01-.197.016-.323.003-.029.042-.064.097-.138.081-.065-.007-.142-.19-.12-.326.046-.1.033-.068.03-.113-1.175-.029-2.355-.08-3.507-.064-.903 0-1.79.038-2.677.077-.474-.006-.838-.084-1.086-.451.022 0 1.209.07 1.554.048.645-.01 1.229-.061 1.884-.08 1.29.022 2.564.022 3.854.112-.123-.084-.13-.284.065-.332.012-.01.022.097.051.097.148-.01.084.193.052.239ZM5.895 4.226c-.193.558.11 1.168.323 1.11.155.064.251-.23.316-.549.042-.09.074-.1.097-.054-.007.425.032.519.142.648a.355.355 0 0 0 .46.01l.191-.194c.042-.042.097-.045.158-.006.058.054.048.145.174.21.103.041.33.009.38-.081.069-.12.088-.162.12-.207.049-.064.132-.039.132-.016-.006.035-.054.074-.022.139.058.045.074.016.11.006.122-.058.216-.329.216-.329.003-.097-.052-.09-.087-.07l-.097.067c-.065.01-.18.048-.239-.042-.058-.106-.061-.258-.106-.368 0-.006-.078-.17-.004-.18.036.006.117.026.13-.039.038-.065-.084-.248-.168-.342-.071-.077-.174-.087-.271-.006-.065.064-.058.129-.071.196a.31.31 0 0 0 .064.271c.068.133.194.304.152.549 0 0-.074.113-.2.096-.052-.013-.135-.032-.18-.367-.033-.255.006-.61-.1-.774-.04-.104-.065-.2-.162-.026-.026.064-.135.168-.058.38.074.188.096.392.065.59-.046.068-.055.094-.117.162-.08.087-.17.064-.238.032-.065-.042-.116-.064-.145-.203.006-.22.016-.577-.023-.655-.058-.116-.155-.074-.197-.038a1.493 1.493 0 0 0-.36.738c-.056.178-.114.13-.156.055-.1-.097-.106-.835-.229-.713Z"
          fill="#fff"
        />
        <path
          d="M6.482 5.441c.09-.064.049-.106.18.026.171.3.27.634.291.977-.01.08.049.13.074.113.013-.187.474-.451.894-.49.064-.013.032-.136.042-.2-.026-.232.129-.445.351-.461.297.045.397.203.403.445-.032.467-.519.548-.793.58-.042.02-.058.039 0 .062l1.145.003.058.032c.006.032-.016.007-.064.08a.882.882 0 0 0-.113.362c-.344.1-.696.167-1.052.2-.122.064-.184.148-.161.242.042.106.32.21.32.216.05.032.112.11-.017.264-.558-.022-.99-.26-1.138-.596-.046-.033-.097 0-.126.045a1.764 1.764 0 0 1-.803.667c-.223.055-.449-.032-.555-.177-.071-.084-.068-.174-.097-.193-.12.051-1.148.49-1.016.287.249-.271.684-.468 1.065-.733.032-.087.08-.387.232-.483.006 0-.026.174-.023.248 0 .061-.003.084.01.068.026-.016.49-.38.526-.494.045-.064.012-.225.012-.232-.083-.226-.206-.242-.251-.355-.042-.148-.023-.316.061-.364.074-.068.161-.061.245.013a.753.753 0 0 1 .204.37c-.017.049-.123-.032-.162-.006a.477.477 0 0 1 .12.242c.064.258.042.355-.017.523-.206.432-.47.564-.703.722-.006.003-.01.113.078.171.032.032.151.045.29 0 .302-.167.546-.42.7-.729.042-.232-.016-.477-.078-.69a8.152 8.152 0 0 1-.193-.516c-.006-.13.003-.174.064-.239h-.003ZM3.49 4.235c.13.061.378.032.368-.178l-.007-.1c-.025-.064-.1-.048-.116.02-.006.02.01.055-.01.064-.012.01-.054.004-.051-.054 0-.02-.013-.04-.023-.052-.01-.003-.012-.007-.029-.007-.019 0-.016.007-.029.023l-.01.048c0 .023-.009.03-.025.033-.016 0-.013 0-.026-.007-.01-.01-.02-.013-.02-.029l-.006-.051a.065.065 0 0 0-.032-.02c-.074 0-.077.084-.074.116-.007.004-.01.152.09.194Z"
          fill="#fff"
        />
        <path
          d="M7.347 5.867c.129.064.445.026.367-.178l-.006-.096c-.026-.065-.097-.049-.116.016-.003.02.01.055-.01.064-.01.013-.052.007-.052-.051 0-.02-.012-.039-.022-.052-.007-.006-.013-.006-.03-.006-.015 0-.015.003-.025.019l-.01.048c-.003.023-.012.033-.025.033-.02 0-.013 0-.03-.007-.006-.01-.019-.013-.019-.029s0-.042-.006-.051c-.007-.01-.02-.013-.032-.017-.071 0-.078.081-.075.113-.003.007-.006.152.09.194Zm2.25-.677c.13.064.378.035.369-.175l-.007-.1c-.026-.064-.1-.048-.116.017-.006.022.01.054-.01.064-.013.013-.054.006-.051-.052 0-.019-.016-.038-.023-.051-.01-.003-.013-.007-.032-.007-.016 0-.016.007-.026.023l-.01.048c0 .02-.01.03-.025.033-.016 0-.013 0-.026-.01-.01-.007-.02-.013-.02-.026a.15.15 0 0 0-.01-.055c-.006-.01-.015-.013-.031-.016-.071 0-.075.084-.071.113-.007.006-.01.155.09.194Zm1.162 1.7c-.226.257-.126.683-.074.773.077.155.135.252.284.326.135.097.238.036.296-.032.136-.139.136-.497.2-.568.046-.129.155-.106.21-.048.05.07.116.126.193.164.126.11.275.13.423.033a.5.5 0 0 0 .226-.278c.064-.177.032-.99.016-1.47-.003-.04-.13-.665-.13-.671 0-.007-.018-.32-.031-.394 0-.029-.01-.039.022-.032.033.026.039.029.058.039.033.006.065-.052.046-.107l-.317-.58c-.022-.023-.054-.049-.096.01a.226.226 0 0 0-.078.17c.01.139.033.278.042.416l.13.703c.038.503.047.913.086 1.42-.006.21-.07.396-.135.419 0 0-.094.058-.155 0-.048-.023-.232-.31-.232-.31-.097-.087-.155-.064-.226 0-.18.178-.264.513-.39.742-.033.052-.126.097-.226-.003-.258-.355-.107-.852-.139-.723h-.003ZM9.656 3.956c.12.049.2.29.174.407-.022.142-.087.3-.129.277-.051-.016.032-.142-.016-.274-.026-.084-.187-.242-.17-.287-.033-.097.07-.139.141-.123Z"
          fill="#fff"
        />
        <path
          d="M11.143 7.031c.022-.29-.02-.464-.026-.632a27.393 27.393 0 0 0-.226-1.58c-.048-.242.178-.033.152-.175-.078-.177-.268-.435-.33-.587-.035-.064-.022-.125-.102-.019-.07.192-.094.397-.071.6.193 1.01.39 1.848.358 2.81.093 0 .2-.21.245-.42v.003Zm2.012-2.667c.11.055.171.355.162.439-.023.158-.08.322-.123.303-.042-.02.01-.233-.013-.297-.022-.094-.167-.261-.155-.313-.029-.103.065-.148.13-.129v-.003ZM5.166 6.486c.104.042.162.258.152.323-.02.116-.074.238-.113.225-.042-.016.006-.17-.013-.219-.01-.12-.151-.18-.148-.229-.026-.097.061-.113.122-.097v-.003Z"
          fill="#fff"
        />
        <path
          d="M7.653 6.818c.13.007.197.113.074.155-.122.042-.242.074-.242.252.045.248-.064.161-.129.129-.074-.055-.287-.184-.316-.468-.003-.064.049-.123.132-.123.13.033.317.04.478.055h.003Z"
          fill="#1B9D00"
        />
        <path
          d="M2.418 3.886c.152.046.162.268.149.336-.02.12-.074.245-.113.229-.042-.013 0-.174-.02-.226-.022-.07-.151-.2-.135-.239-.032-.077.058-.112.12-.096v-.004Zm2.997 1.052c-.116.064-.162.252-.09.361.067.097.174.061.19.061.113.013.18-.216.18-.216s.004-.064-.129.058c-.058.01-.064-.01-.08-.042a.28.28 0 0 1 .019-.18c.02-.055-.026-.08-.09-.042Zm.87-1.139c-.054.042-.174.162-.177.3 0 .078-.016.078.032.13.04.051.078.048.152.009a.161.161 0 0 0 .07-.106c.02-.09-.093.042-.106-.058-.025-.09.046-.13.113-.22 0-.061 0-.103-.08-.055h-.003Zm.707.13a1.835 1.835 0 0 0-.052.345c-.02.087.09.122.142.01.074-.204.074-.291.08-.378-.022-.13-.112-.13-.17.02v.002Zm4.435 2.254c.013-.016.625-.452.625-.452.062-.019.049.226.02.226-.19.184-.407.335-.645.448-.033.02-.062-.167 0-.225v.003Zm.554-.003c.107.051.152.367.14.451.002.168-.104.3-.146.278-.045-.02.003-.207-.016-.271-.023-.094-.116-.268-.1-.316-.032-.107.058-.162.122-.142ZM8.356 7.534a.728.728 0 0 1 .174-.151c.062-.033.12.022.116.02.01.06-.038.115-.022.196.013.032.022.068.084.055a.465.465 0 0 1 .284-.087c.074.006.074.129.029.132-.181.038-.258.087-.387.135-.062.036-.113-.01-.113-.013 0-.003-.033-.035-.01-.116.003-.064-.023-.097-.074-.09-.042.02-.078.032-.097-.013-.01-.032-.01-.048.016-.068Zm4.267.168c.026.032.045.065 0 .12l-.116.096c-.022.039-.032.09.03.107.112.032.377-.14.377-.142.042-.033.029-.094.022-.094-.022-.029-.08-.013-.116-.016-.02 0-.077-.01-.052-.061.026-.033.033-.052.049-.094.02-.039.003-.064-.062-.087-.064-.01-.09-.003-.16 0-.043.01-.056.026-.065.074.006.071.048.068.097.097h-.004Z"
          fill="#fff"
        />
        <path
          d="M8.379 5.928c-.016.032-.074.03-.13 0-.054-.029-.08-.08-.064-.11.016-.029.074-.029.13 0 .054.03.08.081.064.11Zm-2.784-1.67c-.032.01-.074-.02-.093-.065-.02-.045-.01-.08.022-.09.033-.01.075.022.094.064.02.042.01.084-.023.09Z"
          fill="#259F00"
        />
        <path
          d="M11.1 11.717c.291.013.569.004.859.02.055.042.016.155-.02.145l-.245-.006c0-.094-.242-.078-.232.003-.129.016-.245-.003-.374-.01-.039-.048-.032-.132.013-.152Z"
          fill="#209000"
        />
      </g>
    </svg>
  );
};
export default NazioneSa;
