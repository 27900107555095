import { SignupState } from './types';
import { UsersSignUpDataDto } from 'types/swagger';
import { initialSignupState } from './signup';
import { useRef, useState } from 'react';
import { CSVCountryCodeType, CSVNationalitieType, CSVTownshipType } from 'lib/datoCms/types';
import { isTruthy } from 'utility/functions';
import { CsvFiles } from 'lib/datoCms/queries/getCsvFile';

export type CSVTownshipsType = Array<CSVTownshipType>;
export type CSVCountryCodesType = Array<CSVCountryCodeType>;
export type CSVNationalitiesType = Array<CSVNationalitieType>;

export type GeoInfoHookType = {
  getTownships: () => Promise<CSVTownshipsType>;
  getCountryCodes: () => Promise<CSVCountryCodesType>;
  getNationalities: () => Promise<CSVNationalitiesType>;
};

export const useGeoInfo = (): GeoInfoHookType => {
  const townshipsFlag = useRef(false);
  const [townships, setTownships] = useState<CSVTownshipsType>([]);
  const getTownships = async () => {
    if (!isTruthy(townships?.length) && !townshipsFlag.current) {
      townshipsFlag.current = true;
      const response = await fetch(`/api/geo?basename=${encodeURIComponent(CsvFiles.Townships)}`);

      if (response.ok) {
        const data = (await response.json()) as CSVTownshipsType;
        setTownships(data);
        return data;
      }
    }

    return townships;
  };

  const nationalitiesFlag = useRef(false);
  const [nationalities, setNationalities] = useState<CSVNationalitiesType>([]);
  const getNationalities = async () => {
    if (!isTruthy(nationalities?.length) && !nationalitiesFlag.current) {
      nationalitiesFlag.current = true;
      const response = await fetch(`/api/geo?basename=${encodeURIComponent(CsvFiles.Nationalities)}`);

      if (response.ok) {
        const data = (await response.json()) as CSVNationalitiesType;
        setNationalities(data);
        return data;
      }
    }

    return nationalities;
  };

  const countryCodesFlag = useRef(false);
  const [countryCodes, setCountryCodes] = useState<CSVCountryCodesType>([]);
  const getCountryCodes = async () => {
    if (!isTruthy(countryCodes?.length) && !countryCodesFlag.current) {
      countryCodesFlag.current = true;
      const response = await fetch(`/api/geo?basename=${encodeURIComponent(CsvFiles.CountryCodes)}`);

      if (response.ok) {
        const data = (await response.json()) as CSVCountryCodesType;
        setCountryCodes(data);
        return data;
      }
    }

    return countryCodes;
  };

  return {
    getTownships,
    getCountryCodes,
    getNationalities,
  };
};

export type SignupConverterHookType = {
  // eslint-disable-next-line no-unused-vars
  merge: (ori: SignupState, payload: Partial<SignupState | UsersSignUpDataDto>, loadFallback?: boolean) => void;
};

export const mergeSignupState = (
  ori: SignupState,
  payload: Partial<SignupState | UsersSignUpDataDto>,
  loadFallback?: boolean
): void => {
  const allKeys = Object.keys(initialSignupState);
  allKeys.push('gender');
  allKeys.push('spidCode');
  Object.entries(payload).forEach(([key, value]) => {
    let loaded = false;
    const t = RegExp(`^${key}$`, 'gim');
    const field = allKeys.find((x) => x.match(t));

    if (field) {
      if (!!value) {
        ori = ori ?? {};
        Reflect.set(ori, field, value);
        loaded = true;
      }

      if (loadFallback && !loaded && !ori[field]) {
        ori = ori ?? {};
        Reflect.set(ori, field, initialSignupState[field]);
      }
    }
  });
};

export const useSignupDataConverter = (): SignupConverterHookType => {
  return {
    merge: mergeSignupState,
  };
};
