/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\info-solid.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const InfoSolid = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-info-solid', className].join(' ');
  return (
    <svg
      id="info-solid"
      width="100%"
      height="100%"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M7.00008 0.334635C10.6821 0.334635 13.6667 3.3193 13.6667 7.0013C13.6667 10.6833 10.6821 13.668 7.00008 13.668C3.31808 13.668 0.333414 10.6833 0.333414 7.0013C0.333414 3.3193 3.31808 0.334635 7.00008 0.334635ZM7.66675 5.0013V3.66797L6.33341 3.66797V5.0013L7.66675 5.0013ZM7.66675 10.3346L7.66675 6.33464H6.33341L6.33341 10.3346H7.66675Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
    </svg>
  );
};
export default InfoSolid;
