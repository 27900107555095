import { CostantiCalcoloIppicaTicket, erroriRemoved } from '../../ippicaTicketSlice';
import { IppicaContenutoCarrello, IppicaTicket, IppicaTicketAvvenimento, SviluppoSistemi } from './types';
import { TicketErrore, TicketErroreInserimento, TicketErroreVendita } from 'features/carrello/types';

import { AllCarrelloErrors } from 'lib/datoCms/types';
import { BonusIppica } from '../../types';
import { CartTipoVenditaEnum } from 'types/swagger';
import { Dispatch } from '@reduxjs/toolkit';
import { TipoIppicaTicket } from 'features/ippica/components/ippicaTicket/utils/calcoloCarrello/types';

type QuoteMinMax = {
  quotaMin: number;
  quotaMax: number;
};

export default function calcoloIppicaTicket(
  contenutoCarrello: IppicaContenutoCarrello,
  nuovoAvvenimento?: IppicaTicketAvvenimento,
  configBonus?: BonusIppica,
  tipoVendita: CartTipoVenditaEnum = CartTipoVenditaEnum.QUOTAFISSA
): IppicaTicket {
  let ticket: IppicaTicket = {
    avvenimenti: contenutoCarrello.avvenimenti as unknown as Array<IppicaTicketAvvenimento>,
    puntata: contenutoCarrello.puntata ?? CostantiCalcoloIppicaTicket.DefaultPuntata,
    puntataPerScommessa: contenutoCarrello.puntataPerScommessa ?? {},
    sistema: contenutoCarrello.sistema ?? false,
    tipo: TipoIppicaTicket.Singola,
    numEsiti: 0,
    sistemi: [],
    moltBonus: 0,
    percBonus: 0,
    quotaTotale: 0,
    prezzo: 0,
    bonus: 0,
    minCombinazioni: 1,
    maxCombinazioni: CostantiCalcoloIppicaTicket.MaxNumCombinazioniSistema,
    possibileVincitaMin: 0,
    possibileVincitaMax: 0,
    messaggi: [],
    errori: [],
    erroreInserimento: undefined,
    tipoVendita: tipoVendita,
    moltiplicatore: contenutoCarrello.moltiplicatore ?? CostantiCalcoloIppicaTicket.DefaultPuntataTotalizzatore,
    unita: contenutoCarrello.unita,
    minimoScommettibile: contenutoCarrello.minimoScommettibile,
    numeroCombinazioniTotalizzatore: 1,
  };
  if (isTipoVenditaQuotaFissa(tipoVendita)) {
    aggiungiNuovoAvvenimento(ticket, nuovoAvvenimento);
    calcoloCampiBaseTicket(ticket);
    if (configBonus) {
      calcoloBonus(ticket, configBonus);
    }
    if (ticket.sistema) {
      calcoloSistema(ticket);
    } else {
      calcoloSingolaMultipla(ticket, contenutoCarrello.maxVincita);
    }
    checkErroriQuotaFissa(ticket);
    return ticket;
  }
  aggiungiNuovoAvvenimento(ticket, nuovoAvvenimento);
  calcoloNumeroEsiti(ticket);
  calcoloCombinazioniTotalizzatore(ticket);
  calcoloTotalizzatore(ticket);
  checkErroriTotalizatore(ticket);
  // reset flag bonus for each avvenimento
  for (let avvenimento of ticket.avvenimenti) {
    if (!avvenimento.esiti) {
      avvenimento.esiti = [];
    }
    for (let esito of avvenimento.esiti) {
      esito.flagBonus = false;
    }
  }
  return ticket;
}

function aggiungiNuovoAvvenimento(ticket: IppicaTicket, nuovoAvvenimento?: IppicaTicketAvvenimento) {
  if (!nuovoAvvenimento) return;

  if (hasMaxAvvenimenti(ticket)) return;

  const isDifferentTipoEsiti = ticket.avvenimenti.some((avvenimento) => {
    return avvenimento.esiti?.some((esito) => {
      return esito.tipo !== nuovoAvvenimento.esiti?.[0]?.tipo;
    });
  });

  if (isDifferentTipoEsiti) {
    ticket.erroreInserimento = TicketErroreInserimento.ScommessaNonCombinabile;
    return;
  }

  const found = ticket.avvenimenti.find(
    (avvenimento) => avvenimento.numeroAvvenimento === nuovoAvvenimento.numeroAvvenimento
  );
  if (!found) {
    // Avvenimento non presente, aggiunto in coda
    ticket.avvenimenti.push(nuovoAvvenimento);
    return;
  }

  // TOTALIZZATORE SEGUE UNA SUA VALIDATION - QUESTO CALCOLO VALE SOLO PER QUOTA FISSA
  if (isTipoVenditaQuotaFissa(ticket.tipoVendita)) {
    if (found.esiti!.length >= CostantiCalcoloIppicaTicket.MaxEventiPerAvvenimento) {
      ticket.erroreInserimento = TicketErroreInserimento.MassimoNumeroDiEsitiPerAvvenimento;
      return;
    }
    // Avvenimento presente con multipla = 1, esito aggiunto in coda all'avvenimento
    found.esiti!.push(nuovoAvvenimento.esiti![0]);
  }
}

function calcoloCampiBaseTicket(ticket: IppicaTicket) {
  let tipoTicket: TipoIppicaTicket | undefined;
  let numEsiti: number = 0;
  let sistema: boolean | undefined = undefined;
  let minCombinazioni = 1;
  let maxCombinazioni = CostantiCalcoloIppicaTicket.MaxNumCombinazioniSistema;
  // TODO: da capire la logica per elementi non combinabili
  /* const firstIdDisciplina = ticket.avvenimenti[0]?.idDisciplina;
  const isDifferentDiscipline = ticket.avvenimenti?.some((item) => item.idDisciplina !== firstIdDisciplina); */

  for (let avvenimento of ticket.avvenimenti) {
    if (!avvenimento.esiti) {
      avvenimento.esiti = [];
    }
    numEsiti += avvenimento.esiti.length;
    if (avvenimento.esiti.length > 1) {
      //se ci sono più eventi in un avvenimento sono tutti con valore multipla = 1
      tipoTicket = TipoIppicaTicket.Sistema;
      sistema = true;
    }
  }
  if (numEsiti === 1) {
    //non si può mettere a sistema se c'è solo un esito
    tipoTicket = TipoIppicaTicket.Singola;
    sistema = false;
  } else if (ticket.avvenimenti.length > 1 && numEsiti < 3 /* TODO: da eliminare||  isDifferentDiscipline */) {
    //non si può mettere a sistema se ci sono più avvenimenti e meno di tre esiti
    // oppuere se c'è una disciplina diversa
    tipoTicket = TipoIppicaTicket.Multipla;
    sistema = false;
  } else {
    tipoTicket = tipoTicket ?? TipoIppicaTicket.MultiplaSistema;
    ticket.sistema = sistema ?? ticket.sistema ?? false;
  }
  ticket.numEsiti = numEsiti;
  ticket.tipo = tipoTicket;
  ticket.minCombinazioni = minCombinazioni;
  ticket.maxCombinazioni = maxCombinazioni;
}

function calcoloNumeroEsiti(ticket: IppicaTicket) {
  let numEsiti: number = 0;
  for (let avvenimento of ticket.avvenimenti) {
    if (!avvenimento.esiti) {
      avvenimento.esiti = [];
    }
    numEsiti += avvenimento.esiti.length;
    if (avvenimento.esiti.length > 1) {
    }
  }
  ticket.numEsiti = numEsiti;
}

function calcoloTotalizzatore(ticket: IppicaTicket) {
  ticket.prezzo = ticket.numeroCombinazioniTotalizzatore
    ? ticket.moltiplicatore! * ticket.unita! * ticket.numeroCombinazioniTotalizzatore
    : ticket.moltiplicatore! * ticket.unita!;
}

function calcoloBonus(ticket: IppicaTicket, configBonus: BonusIppica) {
  const quotaMinima = getQuotaMinima(ticket);
  const quotaMinBonus = Math.min(...Object.values(+configBonus.quoteDec));
  const idSports = configBonus.id_sport.split('-').map((element) => Number(element)) ?? [];

  for (let avvenimento of ticket.avvenimenti) {
    if (!avvenimento.esiti) {
      avvenimento.esiti = [];
    }
    for (let esito of avvenimento.esiti) {
      esito.flagBonus = false;
    }
  }

  if (configBonus.tipo_bonus === 9 && ticket.avvenimenti.find((avvenimento) => avvenimento.isAntepost)) return;
  if (!(ticket.numEsiti >= configBonus.num_avv_bonus && quotaMinima >= quotaMinBonus)) return;

  if (
    (idSports.length === 1 && idSports[0] !== 0) ||
    ticket.avvenimenti.find((avv) => !idSports.includes(avv.codiceTipoScommessa))
  )
    return;

  const sogliaPercentuale =
    ticket.avvenimenti.reduce((acc, avvenimento) => {
      if (!avvenimento.esiti) {
        avvenimento.esiti = [];
      }
      return acc + avvenimento.esiti.filter((esito) => esito.quota! < quotaMinBonus).length;
    }, 0) + configBonus.num_avv_bonus;

  const maxKey = Object.keys(configBonus.quoteDec).reduce((a, b) =>
    configBonus.quoteDec[a] > configBonus.quoteDec[b] ? a : b
  );
  ticket.moltBonus = sogliaPercentuale;
  ticket.percBonus = configBonus.bonusDec[`fascia_${maxKey}`];
  ticket.bonus = 1;
}

function calcoloSistema(ticket: IppicaTicket) {
  //calcolo sistemi
  let numScommesseMassimo: number = ticket.avvenimenti.length;
  let numAvvenimentiFissi = 0;
  let avvenimentiFissi: IppicaTicketAvvenimento[] = [];
  let avvenimentiNonFissi: IppicaTicketAvvenimento[] = [];
  let quotaMinFissa: number | undefined = undefined;
  let quotaMaxFissa: number | undefined = undefined;
  let quoteMinNonFisse: number[] = [];
  let quoteMaxNonFisse: number[] = [];
  let numCombinazioniFisse: number = 1;
  let numCombinazioniNonFisse: number[] = [];
  //calcolo avvenimenti fissi, avvenimenti non fissi, combinazioni, quote
  for (let avvenimento of ticket.avvenimenti) {
    if (!avvenimento.esiti) {
      avvenimento.esiti = [];
    }

    if (avvenimento.isFisso) {
      numAvvenimentiFissi++;
      avvenimentiFissi.push(avvenimento);
      let quoteMinMax: QuoteMinMax = calcoloQuoteMinMaxAvvenimento(avvenimento);
      if (quotaMinFissa) quotaMinFissa = (quotaMinFissa * quoteMinMax.quotaMin) / CostantiCalcoloIppicaTicket.QuotaBase;
      else quotaMinFissa = quoteMinMax.quotaMin;
      if (quotaMaxFissa) quotaMaxFissa = (quotaMaxFissa * quoteMinMax.quotaMax) / CostantiCalcoloIppicaTicket.QuotaBase;
      else quotaMaxFissa = quoteMinMax.quotaMax;
      numCombinazioniFisse *= avvenimento.esiti.length;
      //console.log('*** Avvenimento Fisso:', avvenimento.id);
      //console.log('quoteMinMax:', quoteMinMax);
      //console.log('quotaMinFissa:', quotaMinFissa);
      //console.log('quotaMaxFissa:', quotaMaxFissa);
    } else {
      avvenimentiNonFissi.push(avvenimento);
      let quoteMinMax: QuoteMinMax = calcoloQuoteMinMaxAvvenimento(avvenimento);
      quoteMinNonFisse.push(quoteMinMax.quotaMin);
      quoteMaxNonFisse.push(quoteMinMax.quotaMax);
      numCombinazioniNonFisse.push(avvenimento.esiti.length);
      //console.log('*** Avvenimento NonFisso:', avvenimento.id);
      //console.log('quoteMinMax:', quoteMinMax);
      //console.log('quotaMinFissa:', quoteMinNonFisse);
      //console.log('quotaMaxFissa:', quoteMaxNonFisse);
    }
  }
  let numAvvenimentiNonFissi = ticket.avvenimenti.length - numAvvenimentiFissi;
  quotaMinFissa = quotaMinFissa ?? CostantiCalcoloIppicaTicket.QuotaBase;
  quotaMaxFissa = quotaMaxFissa ?? CostantiCalcoloIppicaTicket.QuotaBase;
  quoteMinNonFisse = quoteMinNonFisse.sort((n1, n2) => n1 - n2);
  quoteMaxNonFisse = quoteMaxNonFisse.sort((n1, n2) => n2 - n1);
  //console.log('*** Calcoli Generali:', numScommesseMassimo);
  //console.log('numScommesseMassimo:', numScommesseMassimo);
  //console.log('numAvvenimentiFissi:', numAvvenimentiFissi);
  //console.log('numAvvenimentiNonFissi:', numAvvenimentiNonFissi);
  //console.log('avvenimentiFissi:', avvenimentiFissi);
  //console.log('avvenimentiNonFissi:', avvenimentiNonFissi);
  //console.log('quotaMinFissa:', quotaMinFissa);
  //console.log('quotaMaxFissa:', quotaMaxFissa);
  //console.log('quoteMinNonFisse:', quoteMinNonFisse);
  //console.log('quoteMaxNonFisse:', quoteMaxNonFisse);
  //console.log('numCombinazioniFisse:', numCombinazioniFisse);
  //console.log('numCombinazioniNonFisse:', numCombinazioniNonFisse);
  //creazione dei sistemi
  for (
    let numAvvenimentiVariabili: number = numAvvenimentiFissi > 0 ? 0 : 1;
    numAvvenimentiVariabili <= numAvvenimentiNonFissi;
    numAvvenimentiVariabili++
  ) {
    //calcolo numero combinazioni
    let numScommesseSistema = numAvvenimentiFissi + numAvvenimentiVariabili;
    let numCombinazioni = 0;
    if (numScommesseSistema > numAvvenimentiFissi) {
      //includi le combinazioni degli altri avvenimenti, se non è un sistema di soli fissi
      //console.log('numAvvenimentiVariabili:', numAvvenimentiVariabili);
      let combinazioniDiCombinazioni = calcolaCombinazioniK(numCombinazioniNonFisse, numAvvenimentiVariabili);
      //console.log('combinazioniDiCombinazioni:', combinazioniDiCombinazioni);
      for (let combinazione of combinazioniDiCombinazioni) {
        numCombinazioni += numCombinazioniFisse * moltiplica(combinazione);
      }
    } else numCombinazioni = numCombinazioniFisse;
    if (numCombinazioni <= CostantiCalcoloIppicaTicket.MaxNumCombinazioniSistema) {
      //calcolo quote min-max
      let quotaMinSistema: number | undefined = quotaMinFissa;
      let quotaMaxSistema: number | undefined = quotaMaxFissa;
      for (let i = 0; i < numAvvenimentiVariabili; i++) {
        quotaMinSistema = quotaMinSistema
          ? (quotaMinSistema * quoteMinNonFisse[i]) / CostantiCalcoloIppicaTicket.QuotaBase
          : quoteMinNonFisse[i];
        quotaMaxSistema = quotaMaxSistema
          ? (quotaMaxSistema * quoteMaxNonFisse[i]) / CostantiCalcoloIppicaTicket.QuotaBase
          : quoteMaxNonFisse[i];
      }
      //recupero puntata
      let idSistema = `${numScommesseSistema}/${numScommesseMassimo}`;
      let puntataSistema = ticket.puntataPerScommessa[idSistema] ?? 0;
      //inserimento sistema
      //console.log('*** Sistema:', idSistema);
      //console.log('numCombinazioni:', numCombinazioni);
      //console.log('numAvvenimentiNonFissi:', numAvvenimentiNonFissi);
      //console.log('numAvvenimentiVariabili:', numAvvenimentiVariabili);
      //console.log('quotaMinFissa:', quotaMinFissa);
      //console.log('quotaMaxFissa:', quotaMaxFissa);
      //console.log('quoteMinNonFisse:', quoteMinNonFisse);
      //console.log('quoteMaxNonFisse:', quoteMaxNonFisse);
      //console.log('quotaMinSistema:', quotaMinSistema);
      //console.log('quotaMaxSistema:', quotaMaxSistema);
      ticket.sistemi.push({
        id: idSistema,
        numScommesseSelezionabiliCorrente: numScommesseSistema,
        numScommesseSelezionabiliMassimo: numScommesseMassimo,
        numCombinazioni: numCombinazioni,
        importo: puntataSistema * numCombinazioni,
        possibileVincitaMin: Math.floor(
          (puntataSistema * (quotaMinSistema ?? CostantiCalcoloIppicaTicket.QuotaBase)) /
            CostantiCalcoloIppicaTicket.QuotaBase
        ),
        possibileVincitaMax: Math.floor(
          (puntataSistema * (quotaMaxSistema ?? CostantiCalcoloIppicaTicket.QuotaBase)) /
            CostantiCalcoloIppicaTicket.QuotaBase
        ),
      });
    }
  }
  let prezzo: number = 0;
  let possibileVincitaMin: number = 0;
  let possibileVincitaMax: number = 0;
  //Calcoli globali ticket
  for (let sistema of ticket.sistemi) {
    if (sistema.importo > 0) {
      prezzo += sistema.importo;
      if (possibileVincitaMin === 0) possibileVincitaMin = sistema.possibileVincitaMin;
      else possibileVincitaMin = Math.min(possibileVincitaMin, sistema.possibileVincitaMin);
      if (possibileVincitaMax === 0) possibileVincitaMax = sistema.possibileVincitaMax;
      else possibileVincitaMax = Math.max(possibileVincitaMax, sistema.possibileVincitaMax);
    }
  }
  ticket.prezzo = prezzo;
  ticket.possibileVincitaMin = possibileVincitaMin;
  ticket.possibileVincitaMax = possibileVincitaMax;
}

function calcoloQuoteMinMaxAvvenimento(avvenimento: IppicaTicketAvvenimento): QuoteMinMax {
  let quotaMinAvvenimento: number | undefined = undefined;
  let quotaMaxAvvenimento: number | undefined = undefined;
  if (!avvenimento.esiti) {
    avvenimento.esiti = [];
  }
  for (let esito of avvenimento.esiti) {
    if (quotaMinAvvenimento && quotaMaxAvvenimento) {
      quotaMinAvvenimento = Math.min(quotaMinAvvenimento, esito.quota!);
      quotaMaxAvvenimento = Math.max(quotaMaxAvvenimento, esito.quota!);
    } else quotaMinAvvenimento = quotaMaxAvvenimento = esito.quota;
  }
  return {
    quotaMin: quotaMinAvvenimento ?? CostantiCalcoloIppicaTicket.QuotaBase,
    quotaMax: quotaMaxAvvenimento ?? CostantiCalcoloIppicaTicket.QuotaBase,
  };
}

export function calcolaCombinazioni<T>(set: T[], min: number, max: number): T[][] {
  max = max < set.length ? max : set.length;
  min = min > 0 ? (min > max ? max : min) : 1;
  let all: T[][] = [];
  for (let k = min; k <= max; k++) {
    let combinazioniK = calcolaCombinazioniK(set, k);
    combinazioniK.forEach((e) => all.push(e));
  }
  return all;
}

function calcolaCombinazioniK<T>(set: T[], k: number): T[][] {
  let i, j, combs, head, tailcombs;

  // There is no way to take e.g. sets of 5 elements from a set of 4.
  if (k > set.length || k <= 0) {
    return [];
  }

  // K-sized set has only one K-sized subset.
  if (k === set.length) {
    return [set];
  }

  // There is N 1-sized subsets in a N-sized set.
  if (k === 1) {
    combs = [];
    for (i = 0; i < set.length; i++) {
      combs.push([set[i]]);
    }
    return combs;
  }

  combs = [];
  for (i = 0; i < set.length - k + 1; i++) {
    // head is a list that includes only our current element.
    head = set.slice(i, i + 1);
    // We take smaller combinations from the subsequent elements
    tailcombs = calcolaCombinazioniK(set.slice(i + 1), k - 1);
    // For each (k-1)-combination we join it with the current
    // and store it to the set of k-combinations.
    for (j = 0; j < tailcombs.length; j++) {
      combs.push(head.concat(tailcombs[j]));
    }
  }
  return combs;
}

function moltiplica(array: number[]) {
  let prodotto = 1;
  for (let i = 0; i < array.length; i++) {
    prodotto = prodotto * array[i];
  }
  return prodotto;
}

/*
function combinazioni(n: number, k: number): number {
  return moltiplicazioneRange(n, n - k + 1) / moltiplicazioneRange(k, 2);
}

function moltiplicazioneRange(n: number, r: number): number {
  let totale = 1;
  while (n >= r) {
    totale *= n;
    n--;
  }
  return totale;
}
*/

function calcoloSingolaMultipla(ticket: IppicaTicket, maxVincita?: number) {
  let quota: number = 1;
  for (let avvenimento of ticket.avvenimenti) {
    if (!avvenimento.esiti) {
      avvenimento.esiti = [];
    }
    for (let esito of avvenimento.esiti) {
      quota = parseFloat((quota * (esito.quota! / CostantiCalcoloIppicaTicket.QuotaBase)).toFixed(6));
    }
  }
  ticket.quotaTotale = Math.floor(quota * 100);
  if (maxVincita) {
    ticket.puntata = Math.floor(maxVincita / quota);
  } else ticket.puntata ??= CostantiCalcoloIppicaTicket.DefaultPuntata;
  ticket.prezzo = ticket.puntata;
  ticket.possibileVincitaMin = ticket.possibileVincitaMax = Math.floor(quota * ticket.puntata);
  if (ticket.bonus > 0) {
    //applicazione bonus multipla
    const percentualeBonus = 1 + ticket.percBonus / CostantiCalcoloIppicaTicket.QuotaBase / 100;
    const quotaBonus: number = parseFloat((quota * percentualeBonus).toFixed(6));
    ticket.possibileVincitaMinWithBonus = ticket.possibileVincitaMaxWithBonus = Math.floor(quotaBonus * ticket.puntata);
  }
}

function checkErroriQuotaFissa(ticket: IppicaTicket) {
  if (ticket.possibileVincitaMax > CostantiCalcoloIppicaTicket.MaxVincita)
    ticket.errori.push({ type: TicketErrore.VincitaOltreMassimo });
  if (ticket.sistema) {
    if (ticket.prezzo < CostantiCalcoloIppicaTicket.MinImportoSistema) {
      ticket.errori.push({
        type: TicketErrore.PrezzoTicketVirtualSistemaSottoMinimo,
        value: CostantiCalcoloIppicaTicket.MinImportoSistema / 100,
      });
      let numeroTotaleCombinazioni = ticket.sistemi.reduce(
        (accumulatoreCombinazioni, sistema) => accumulatoreCombinazioni + sistema.numCombinazioni,
        0
      );
      if (numeroTotaleCombinazioni > CostantiCalcoloIppicaTicket.MaxNumCombinazioniSistema) {
        ticket.errori.push({
          type: TicketErrore.MassimoNumeroDiCombinazioniSelezionabili,
        });
      } else if (numeroTotaleCombinazioni < CostantiCalcoloIppicaTicket.MinNumCombinazioniSistema) {
        ticket.errori.push({
          type: TicketErrore.MinimoNumeroDiCombinazioniSelezionabili,
        });
      }
    }
    for (let sistema of ticket.sistemi) {
      if (sistema.importo > 0) {
        if (sistema.numScommesseSelezionabiliCorrente > ticket.maxCombinazioni) {
          ticket.errori.push({
            type: TicketErrore.MassimoCombinazioniAvvenimenti,
            value: ticket.maxCombinazioni,
          });
          break;
        }
        if (sistema.numScommesseSelezionabiliCorrente < ticket.minCombinazioni) {
          ticket.errori.push({
            type: TicketErrore.MinimoCombinazioniAvvenimenti,
            value: ticket.minCombinazioni,
          });
          break;
        }
      }
    }
  } else {
    if (ticket.prezzo < CostantiCalcoloIppicaTicket.MinImportoSingolaMultipla)
      ticket.errori.push({
        type: TicketErrore.PrezzoTicketSinglaMultiplaSottoMinimo,
        value: CostantiCalcoloIppicaTicket.MinImportoSingolaMultipla / 100,
      });
    if (ticket.avvenimenti.length > ticket.maxCombinazioni)
      ticket.errori.push({
        type: TicketErrore.MassimoCombinazioniAvvenimenti,
        value: ticket.maxCombinazioni,
      });
    if (ticket.avvenimenti.length < ticket.minCombinazioni)
      ticket.errori.push({
        type: TicketErrore.MinimoCombinazioniAvvenimenti,
        value: ticket.minCombinazioni,
      });
  }
}

function checkErroriTotalizatore(ticket: IppicaTicket) {
  if (ticket.prezzo < ticket.minimoScommettibile!) {
    ticket.errori.push({
      type: TicketErrore.PrezzoTicketSinglaMultiplaSottoMinimo,
      value: ticket.minimoScommettibile! / 100,
    });
  }
}

function hasMaxAvvenimenti(ticket: IppicaTicket) {
  if (ticket.sistema && ticket.avvenimenti.length >= CostantiCalcoloIppicaTicket.MaxAvvenimentiSistema) {
    ticket.errori.push({ type: TicketErrore.MaxAvvenimenti, value: CostantiCalcoloIppicaTicket.MaxAvvenimentiSistema });
    return true;
  } else if (
    !ticket.sistema &&
    ticket.avvenimenti.length >= CostantiCalcoloIppicaTicket.MaxAvvenimentiSingolaMultipla
  ) {
    ticket.errori.push({
      type: TicketErrore.MaxAvvenimenti,
      value: CostantiCalcoloIppicaTicket.MaxAvvenimentiSingolaMultipla,
    });
    return true;
  }
  return false;
}

export function calcoloSportTicketVincite(sportTicket: IppicaTicket, sviluppoSistemi: SviluppoSistemi) {
  let minVincita: number | undefined;
  let maxVincita = 0;
  sviluppoSistemi.dettaglioSviluppoSistemi.forEach((dettaglioSviluppoSistema) => {
    let puntata =
      sportTicket.puntataPerScommessa[`${dettaglioSviluppoSistema.codiceSistema}/${sportTicket.avvenimenti.length}`];
    let currentMinVincita = Math.floor((puntata * dettaglioSviluppoSistema.quotaMinimaSistema) / 100);
    if (!minVincita || currentMinVincita < minVincita) minVincita = currentMinVincita;
    maxVincita += Math.floor((puntata * dettaglioSviluppoSistema.quotaMassimaSistema) / 100);
  });
  sportTicket.possibileVincitaMin = minVincita!;
  sportTicket.possibileVincitaMax = maxVincita;
}

export const dismissChangedQuoteWarning = ({
  dispatcher,
  error,
}: {
  dispatcher: Dispatch;
  error: AllCarrelloErrors;
}): (() => void) | undefined => {
  if (error?.key === TicketErroreVendita.QuoteCambiate) {
    return () => {
      dispatcher(
        erroriRemoved({
          tipoErrore: 'erroriVendita',
          errore: {
            type: TicketErroreVendita.QuoteCambiate,
          },
        })
      );
    };
  }
  return undefined;
};

function getQuotaMinima(ticket: IppicaTicket): number {
  let quotaMinima = 0;
  for (const avvenimento of ticket.avvenimenti) {
    if (!avvenimento.esiti) {
      avvenimento.esiti = [];
    }
    for (const esito of avvenimento.esiti) {
      if (esito.quota && esito.quota < quotaMinima) {
        quotaMinima = esito.quota;
      }
    }
  }
  return quotaMinima;
}

export const isTipoVenditaQuotaFissa = (tipoVendita: CartTipoVenditaEnum): boolean =>
  tipoVendita === CartTipoVenditaEnum.QUOTAFISSA;

export const isTipoVenditaTotalizzatore = (tipoVendita: CartTipoVenditaEnum): boolean =>
  tipoVendita === CartTipoVenditaEnum.TOTALIZZATORE;

export const isTipoVenditaNazionale = (tipoVendita: CartTipoVenditaEnum): boolean =>
  tipoVendita === CartTipoVenditaEnum.NAZIONALE;

// Calcola il fattoriale di number
function mathFattoriale(number: number): number {
  var fattoriale: number = 1;
  while (number > 0) {
    fattoriale *= number;
    number--;
  }
  return fattoriale;
}

// Calcola il binomio di Newton --> N!/(K! * (N - K)!)
function mathBinomiale(n: number, k: number): number {
  var binomiale: number = 1;
  for (var i: number = 0; i < k; i++) {
    binomiale *= n;
    n--;
  }
  binomiale = binomiale / mathFattoriale(k);
  return binomiale;
}

function calcoloCombinazioniTotalizzatore(ticket: IppicaTicket) {
  const { mappe: mappa, siglaSistema } = ticket.avvenimenti?.[0] ?? {};

  if (!mappa) throw new Error('missing "mappe" required field for calcoloCombinazioniTotalizzatore function');

  let numeroCombinazioni = 0;
  // TOOD: CAPIRE
  // var mappa: number[][] = [],
  //   i: number = 0,
  //   n_comb: number = 0;
  // formData.forEach((value: number[], key: number) => {
  //   mappa.push([]);
  //   angular.forEach(formData[key], function (presente: boolean, cavallo: string) {
  //     if (presente) {
  //       mappa[i].push(parseInt(cavallo));
  //     }
  //   });
  //   i++;
  // });

  // Nuove funzioni di calcolo delle combinazioni passate da SNAI utilizzandole a seconda della tipologia// Declare the variable sigla_sistema
  switch (siglaSistema) {
    // TOTALIZZATORE
    case 'AO':
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0; // cavalli in comune tra prima e seconda mappa
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombPx(2, 1, n1, n2, k21, false) / 2;
      break;
    case 'AOV1':
      // Accoppiata all'ordine T2
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0; // cavalli in comune tra prima e seconda mappa
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombVx(2, 1, n1, n2, k21);
      break;
    case 'AOP1':
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0; // cavalli in comune tra prima e seconda mappa
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      //console.log(2, 1, n1, n2, k21, false);
      numeroCombinazioni = calcolaCombPx(2, 1, n1, n2, k21, false) * 2;
      break;
    case 'AONX':
      var N = mappa[0].length;
      numeroCombinazioni = N * (N - 1);
      break;
    case 'AP1':
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0; // cavalli in comune tra prima e seconda mappa
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombPx(2, 1, n1, n2, k21, true) / 2;
      break;
    case 'ACPP1':
      //ACCOPPIATA PIAZZATA IN ORDINE
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0; // cavalli in comune tra prima e seconda mappa
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombPx(2, 1, n1, n2, k21, true) / 2;
      break;
    case 'ANX':
      // Accoppiata in disordine NX
      var N = mappa[0].length;
      numeroCombinazioni = (N * (N - 1)) / 2;
      break;
    case 'ACPNX':
      // Accoppiata Piazzata NX
      var N = mappa[0].length;
      numeroCombinazioni = (N * (N - 1)) / 2;
      break;
    case 'TRO':
      // Trio in Ordine
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var n3 = mappa[2].length;
      var k21 = 0,
        k31 = 0,
        k32 = 0,
        k123 = 0;
      for (var i = 0; i < n2; i++) {
        for (var j = 0; j < n1; j++) {
          if (mappa[1][i] == mappa[0][j]) {
            k21++;
            for (var w = 0; w < n3; w++) {
              if (mappa[1][i] == mappa[2][w]) {
                k123++;
                break;
              }
            }
            break;
          }
        }
      }

      for (var i = 0; i < n3; i++) {
        for (var j = 0; j < n1; j++) {
          if (mappa[2][i] == mappa[0][j]) {
            k31++;
            for (var w = 0; w < n2; w++) {
              if (mappa[2][i] == mappa[1][w]) {
                k123++;
                break;
              }
            }
            break;
          }
        }
      }

      for (var i = 0; i < n3; i++) {
        for (var j = 0; j < n2; j++) {
          if (mappa[2][i] == mappa[1][j]) {
            k32++;
            for (var w = 0; w < n1; w++) {
              if (mappa[2][i] == mappa[0][w]) {
                k123++;
                break;
              }
            }
            break;
          }
        }
      }
      k123 = k123 / 3;
      numeroCombinazioni = n1 * n2 * n3 - k21 * n3 - k31 * n2 - k32 * n1 + 2 * k123;
      break;
    case 'T3':
      // Trio T3
      var c1 = mappa[0];
      var c2 = mappa[1];
      var c3 = mappa[2];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var n3 = mappa[2].length;
      var k21 = 0,
        k31 = 0,
        k32 = 0,
        k123 = 0;
      for (var i = 0; i < n2; i++) {
        for (var j = 0; j < n1; j++) {
          if (c2[i] == c1[j]) {
            k21++;
            for (var w = 0; w < n3; w++) {
              if (c2[i] == c3[w]) {
                k123++;
                break;
              }
            }
            break;
          }
        }
      }

      for (var i = 0; i < n3; i++) {
        for (var j = 0; j < n1; j++) {
          if (c3[i] == c1[j]) {
            for (var w = 0; w < n2; w++) {
              if (c3[i] == c2[w]) {
                k123++;
                break;
              }
            }
            k31++;
            break;
          }
        }
      }

      for (var i = 0; i < n3; i++) {
        for (var j = 0; j < n2; j++) {
          if (c3[i] == c2[j]) {
            k32++;
            for (var w = 0; w < n1; w++) {
              if (c3[i] == c1[w]) {
                k123++;
                break;
              }
            }
            break;
          }
        }
      }
      k123 = k123 / 3;
      numeroCombinazioni = n1 * n2 * n3 - k21 * n3 - k31 * n2 - k32 * n1 + 2 * k123;
      break;
    case 'TNX':
      // TRIO A GIRARE
      var N = mappa[0].length;
      numeroCombinazioni = N * (N - 1) * (N - 2);
      break;
    case 'TVX':
      // TRIO con Vincente
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      var m = n1 - k21;
      var n = n2 - k21;
      var c = k21;
      numeroCombinazioni =
        m * n * (n - 1) +
        2 * m * n * c +
        m * c * (c - 1) +
        c * n * (n - 1) +
        2 * n * c * (c - 1) +
        c * (c - 1) * (c - 2);
      break;
    case 'TP1':
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      var m = n1 - k21;
      var n = n2 - k21;
      var c = k21;
      numeroCombinazioni =
        3 * m * n * (n - 1) +
        6 * m * n * c +
        3 * m * c * (c - 1) +
        3 * c * n * (n - 1) +
        3 * n * c * (c - 1) +
        c * (c - 1) * (c - 2);
      break;
    case 'TP2':
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      var m = n1 - k21;
      var n = n2 - k21;
      var c = k21;
      numeroCombinazioni =
        3 * n * m * (m - 1) +
        3 * c * m * (m - 1) +
        6 * m * n * c +
        3 * m * c * (c - 1) +
        3 * n * c * (c - 1) +
        c * (c - 1) * (c - 2);
      break;
    case 'TA2':
      // Trio con Accoppiata
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      var m = n1 - k21;
      var n = n2 - k21;
      var c = k21;
      numeroCombinazioni =
        n * m * (m - 1) +
        c * m * (m - 1) +
        2 * m * n * c +
        2 * m * c * (c - 1) +
        n * c * (c - 1) +
        c * (c - 1) * (c - 2);
      break;
    // NAZIONALE
    case 'VIN':
      // n_comb = nr di cavalli della colonna G1
      var N = mappa[0].length;
      numeroCombinazioni = N;
      break;
    case 'DUO-NX':
      // Accoppiata a girare
      var N = mappa[0].length;
      numeroCombinazioni = N * (N - 1);
      break;
    case 'DUO-NXR':
      // Accoppiata a girare
      var N = mappa[0].length;
      numeroCombinazioni = (N * (N - 1)) / 2;
      break;
    case 'DUO-T2':
      // Accoppiata in ordine (T2)
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = n1 * n2 - k21;
      break;
    case 'DUO-V1':
      // Accoppiata Con 1 Vincente
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = n1 * n2 - k21;
      break;
    case 'DUO-P1':
      // Accoppiata con 1 Piazzato
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      var m = n1 - k21;
      var n = n2 - k21;
      var c = k21;
      numeroCombinazioni = 2 * m * n2 + 2 * c * n + c * (c - 1);
      break;
    case 'TRO-NX':
      // Tris a girare
      var N = mappa[0].length;
      numeroCombinazioni = N * (N - 1) * (N - 2);
      break;
    case 'TRO-NXR':
      // Tris a girare
      var N = mappa[0].length;
      numeroCombinazioni = (N * (N - 1) * (N - 2)) / 6;
      break;
    case 'TRO-P1':
      // TRIS con 1 piazzato
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      var m = n1 - k21;
      var n = n2 - k21;
      var c = k21;
      numeroCombinazioni =
        3 * m * n * (n - 1) +
        6 * m * n * c +
        3 * m * c * (c - 1) +
        3 * c * n * (n - 1) +
        3 * n * c * (c - 1) +
        c * (c - 1) * (c - 2);
      break;
    case 'TRO-P2':
      // TRIS con 2 piazzati
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      var m = n1 - k21;
      var n = n2 - k21;
      var c = k21;
      numeroCombinazioni =
        3 * n * m * (m - 1) +
        3 * c * m * (m - 1) +
        6 * m * n * c +
        3 * m * c * (c - 1) +
        3 * n * c * (c - 1) +
        c * (c - 1) * (c - 2);
      break;
    case 'TRO-V1':
      // Tris con 1 Vincente (V1)
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      var m = n1 - k21;
      var n = n2 - k21;
      var c = k21;
      numeroCombinazioni =
        m * n * (n - 1) +
        2 * m * n * c +
        m * c * (c - 1) +
        c * n * (n - 1) +
        2 * n * c * (c - 1) +
        c * (c - 1) * (c - 2);
      break;
    case 'TRO-V2':
      // Tris con V2
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      var m = n1 - k21;
      var n = n2 - k21;
      var c = k21;
      numeroCombinazioni =
        n * m * (m - 1) +
        c * m * (m - 1) +
        2 * m * n * c +
        2 * m * c * (c - 1) +
        n * c * (c - 1) +
        c * (c - 1) * (c - 2);
      break;
    case 'TRO-T3':
      // Tris T3 (In ordine)
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var n3 = mappa[2].length;
      var k21 = 0,
        k31 = 0,
        k32 = 0,
        k123 = 0;
      for (var i = 0; i < n2; i++) {
        for (var j = 0; j < n1; j++) {
          if (mappa[1][i] == mappa[0][j]) {
            k21++;
            for (var w = 0; w < n3; w++) {
              if (mappa[1][i] == mappa[2][w]) {
                k123++;
                break;
              }
            }
            break;
          }
        }
      }

      for (var i = 0; i < n3; i++) {
        for (var j = 0; j < n1; j++) {
          if (mappa[2][i] == mappa[0][j]) {
            k31++;
            for (var w = 0; w < n2; w++) {
              if (mappa[2][i] == mappa[1][w]) {
                k123++;
                break;
              }
            }
            break;
          }
        }
      }

      for (var i = 0; i < n3; i++) {
        for (var j = 0; j < n2; j++) {
          if (mappa[2][i] == mappa[1][j]) {
            k32++;
            for (var w = 0; w < n1; w++) {
              if (mappa[2][i] == mappa[0][w]) {
                k123++;
                break;
              }
            }
            break;
          }
        }
      }
      k123 = k123 / 3;
      numeroCombinazioni = n1 * n2 * n3 - k21 * n3 - k31 * n2 - k32 * n1 + 2 * k123;
      break;
    case 'QUO-NX':
      // Quarté a girare
      var N = mappa[0].length;
      numeroCombinazioni = N * (N - 1) * (N - 2) * (N - 3);
      break;
    case 'QUO-P1':
      // Quartè con 1 Piazzato (P1)
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombPx(4, 1, n1, n2, k21, true);
      break;
    case 'QUO-P2':
      // Quartè con 2 Piazzati (P2)
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombPx(4, 2, n1, n2, k21, true);
      break;
    case 'QUO-P3':
      // Quartè con 2 Piazzati (P2)
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombPx(4, 3, n1, n2, k21, true);
      break;
    case 'QUO-V1':
      // Quartè con 1 Vincente (V1)
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombVx(4, 1, n1, n2, k21);
      break;
    case 'QUO-V2':
      // Quartè con V2
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombVx(4, 2, n1, n2, k21);
      break;
    case 'QUO-V3':
      // Quartè con V3
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombVx(4, 3, n1, n2, k21);
      break;
    case 'QUO-T4':
      // Quartè T4
      numeroCombinazioni = calcolaCombTx(mappa, 4, null);
      break;
    case 'CIO-NX':
      // Quintè a girare
      var N = mappa[0].length;
      numeroCombinazioni = N * (N - 1) * (N - 2) * (N - 3) * (N - 4);
      break;
    case 'CIO-P1':
      // Quintè con 1 Piazzato (P1)
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombPx(5, 1, n1, n2, k21, true);
      break;
    case 'CIO-P2':
      // Quintè con 2 Piazzati (P2)
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombPx(5, 2, n1, n2, k21, true);
      break;
    case 'CIO-P3':
      // Quintè con 3 Piazzati (P3)
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombPx(5, 3, n1, n2, k21, true);
      break;
    case 'CIO-P4':
      // Quintè con 4 Piazzati (P4)
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombPx(5, 4, n1, n2, k21, true);
      break;
    case 'CIO-V1':
      // Quintè con 1 Vincente (V1)
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombVx(5, 1, n1, n2, k21);
      break;
    case 'CIO-V2':
      // Quintè V2
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombVx(5, 2, n1, n2, k21);
      break;
    case 'CIO-V3':
      // Quintè V3
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      for (var x = 0; x < n2; x++) {
        for (var j = 0; j < n1; j++) {
          if (c2[x] == c1[j]) {
            k21++;
            break;
          }
        }
      }
      numeroCombinazioni = calcolaCombVx(5, 3, n1, n2, k21);
      break;
    case 'CIO-V4':
      // Quintè V4
      var c1 = mappa[0];
      var c2 = mappa[1];
      var n1 = mappa[0].length;
      var n2 = mappa[1].length;
      var k21 = 0;
      numeroCombinazioni = calcolaCombVx(5, 4, n1, n2, k21);
      break;
    case 'CIO-T5':
      // Quintè T5
      numeroCombinazioni = calcolaCombTx(mappa, 5, null);
      break;
    default:
      numeroCombinazioni = 1;
      break;
  }
  ticket.numeroCombinazioniTotalizzatore = numeroCombinazioni;
}

// Combinazioni NX
// nrPos = numero di posizioni da pronosticare (Es: in TRIS NX => nrPos = 3)
// inOrdine = flag che definisce se la scommessa è in ordine o meno (TRIS NX ha inOrdine = 0)
function calcolaCombNX(nrPos: number, nrEsiti: number, inOrdine: boolean): number {
  // inOrdine = true
  var nrComb: number = mathBinomiale(nrEsiti, nrPos);
  if (inOrdine) {
    nrComb *= mathFattoriale(nrPos);
  }
  return nrComb;
}

/*
  Combinazioni per scommessa con N piazzati e M dietro
  nrPos = numero di posizioni da pronosticare (Es: in TRIS P1 => nrPos = 3)
  xDiPx = numero di cavalli piazzati
  nrEsMappa2 = numero di cavalli dietro
  nrEsDuplicati = numero di cavalli ripetuti in entrambe le mappe
  inOrdine = flag che definisce se la scommessa è in ordine o meno (TRIS P1 ha inOrdine = 0)
*/
function calcolaCombPx(
  nrPos: number,
  xDiPx: number,
  nrEsMappa1: number,
  nrEsMappa2: number,
  nrEsDuplicati: number,
  inOrdine: boolean
): number {
  // inOrdine = true;
  var nrComb: number = mathBinomiale(nrEsMappa1 + nrEsMappa2 - nrEsDuplicati, nrPos);
  for (var x = 0; x < xDiPx; x++) {
    nrComb -= mathBinomiale(nrEsMappa1, x) * mathBinomiale(nrEsMappa2 - nrEsDuplicati, nrPos - x);
  }
  for (var x = 0; x < nrPos - xDiPx; x++) {
    nrComb -= mathBinomiale(nrEsMappa2, x) * mathBinomiale(nrEsMappa1 - nrEsDuplicati, nrPos - x);
  }
  if (inOrdine) {
    nrComb *= mathFattoriale(nrPos);
  }
  return nrComb;
}

/*
  Combinazioni Vx
  $nrPos = numero di posizioni da pronosticare (Es: in TRIS V1 => $nrPos=3)
  $xDiVx =numero di cavalli vincenti
  $nrEsMappa2 = numero di cavalli dietro
  $nrEsDuplicati = numero cavalli ripetuti in entrambe le mappe
*/

function calcolaCombVx(
  nrPos: number,
  xDiVx: number,
  nrEsMappa1: number,
  nrEsMappa2: number,
  nrEsDuplicati: number
): number {
  var maxDup1: number = xDiVx < nrEsDuplicati ? xDiVx : nrEsDuplicati;
  var nrComb: number = 0;

  for (var x = 0; x <= maxDup1; x++) {
    var n: number = mathBinomiale(nrEsDuplicati, x) * mathBinomiale(nrEsMappa1 - nrEsDuplicati, xDiVx - x);
    var s: number = 0;
    var maxDup2: number = nrEsDuplicati - x < nrPos - xDiVx ? nrEsDuplicati - x : nrPos - xDiVx;

    for (var y = 0; y <= maxDup2; y++) {
      s += mathBinomiale(nrEsDuplicati - x, y) * mathBinomiale(nrEsMappa2 - nrEsDuplicati, nrPos - xDiVx - y);
    }

    nrComb += n * s;
  }

  return nrComb * mathFattoriale(xDiVx) * mathFattoriale(nrPos - xDiVx);
}

/*
  Combinazioni TX fino al T5
  $mappa= array contenente i cavalli per ogni mappa
  $xDiTx = in T2 2, in T3 3, in T4 4, in T5 5
*/
interface Mappa {
  [key: number]: number[];
}

function calcolaCombTx(mappa: Mappa, xDiTx: number, esGruppo: number | null): number {
  const combMax: number = 100000;
  let es1: number = 0;
  let es2: number = 0;
  let es3: number = 0;
  let es4: number = 0;
  let es5: number = 0;
  let max1: number = 0;
  let max2: number = 0;
  let max3: number = 0;
  let max4: number = 0;
  let max5: number = 0;
  let nrComb: number = 0;
  let nrCombTmp: number = 0;

  max1 = mappa[0] != undefined ? mappa[0].length : 0;
  max2 = mappa[1] != undefined ? mappa[1].length : 0;
  max3 = mappa[2] != undefined ? mappa[2].length : 0;
  max4 = mappa[3] != undefined ? mappa[3].length : 0;
  max5 = mappa[4] != undefined ? mappa[4].length : 0;

  for (let i1 = 0; i1 < max1; i1++) {
    if (mappa[0][i1] == 0) {
      continue;
    }
    for (let i2 = 0; i2 < max2; i2++) {
      if (mappa[1][i2] == 0) {
        continue;
      }
      es1 = mappa[0][i1];
      es2 = mappa[1][i2];
      if (es1 == es2 && es2 != esGruppo) {
        continue;
      }
      if (xDiTx == 2) {
        nrCombTmp = 1;
        nrComb += nrCombTmp;
        continue;
      } // T2
      for (let i3 = 0; i3 < max3; i3++) {
        if (mappa[2][i3] == 0) {
          continue;
        }
        es1 = mappa[0][i1];
        es2 = mappa[1][i2];
        es3 = mappa[2][i3];
        if ((es1 == es3 || es2 == es3) && es3 != esGruppo) {
          continue;
        }
        if (xDiTx == 3) {
          nrCombTmp = 1;
          nrComb += nrCombTmp;
          continue;
        } // T3
        for (let i4 = 0; i4 < max4; i4++) {
          if (mappa[3][i4] == 0) {
            continue;
          }
          es1 = mappa[0][i1];
          es2 = mappa[1][i2];
          es3 = mappa[2][i3];
          es4 = mappa[3][i4];
          if ((es1 == es4 || es2 == es4 || es3 == es4) && es4 != esGruppo) {
            continue;
          }
          if (xDiTx == 4) {
            nrCombTmp = 1;
            nrComb += nrCombTmp;
            continue;
          } // T4
          for (let i5 = 0; i5 < max5; i5++) {
            if (mappa[4][i5] == 0) {
              continue;
            }
            es1 = mappa[0][i1];
            es2 = mappa[1][i2];
            es3 = mappa[2][i3];
            es4 = mappa[3][i4];
            es5 = mappa[4][i5];
            if ((es1 == es5 || es2 == es5 || es3 == es5 || es4 == es5) && es5 != esGruppo) {
              continue;
            }
            if (xDiTx == 5) {
              nrCombTmp = 1;
              nrComb += nrCombTmp;
              continue;
            } // T5
          } // ciclo i5
          if (nrComb > combMax) {
            break;
          }
        } // ciclo i4
        if (nrComb > combMax) {
          break;
        }
      } // ciclo i3
      if (nrComb > combMax) {
        break;
      }
    } // ciclo i2
    if (nrComb > combMax) {
      break;
    }
  } // ciclo i1
  return nrComb;
}
// ----------------------------------------------------
