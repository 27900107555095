/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-28.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina28 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-28', className].join(' ');
  return (
    <svg
      id="disciplina_short-track"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="m3.94 18.39-.718.427c-.018.01-.135.005-.318-.107-.24-.155-.488-.427-.685-.75L.776 15.54a.417.417 0 0 0-.717.427l1.443 2.418c.263.436.6.802.952 1.027.258.164.51.248.74.248.16 0 .318-.043.455-.126l.717-.427a.417.417 0 1 0-.426-.717Zm12.486.976a.418.418 0 0 0-.417.417v.834c0 .103-.445.418-1.252.418h-2.816a.417.417 0 0 0 0 .834h2.816c1.191 0 2.086-.54 2.086-1.252v-.834a.418.418 0 0 0-.417-.417Zm-.075-7.237-1.889-.731 4.406-2.264a1.152 1.152 0 0 0 .29-1.683l-1.237-1.448a1.043 1.043 0 0 0-.932-.581H6.982a1.045 1.045 0 1 0 0 2.09h6.97L9.296 9.781a.036.036 0 0 1-.023.009l-.033.014c-.005 0-.01.005-.014.01l-.099.056a.828.828 0 0 0-.103.075.685.685 0 0 0-.089.075.848.848 0 0 0-.089.093.732.732 0 0 0-.075.094.822.822 0 0 0-.066.108.265.265 0 0 0-.037.06l-1.167 2.373-4.626 2.109a1.253 1.253 0 0 0 1.04 2.278l5.03-2.297c.261-.122.474-.328.604-.586l.858-1.743 3.951 1.532-1.373 4.547a1.252 1.252 0 0 0 2.395.726l1.715-5.652a1.254 1.254 0 0 0-.745-1.533Zm2.058-7.673a2.015 2.015 0 1 0 4.03.01 2.015 2.015 0 0 0-4.03-.01ZM15.75 2.417A.418.418 0 0 0 15.334 2H8.969a.417.417 0 0 0 0 .834h6.365a.418.418 0 0 0 .417-.417ZM6.363 3.462a.417.417 0 1 0 0 .835h6.365a.417.417 0 0 0 0-.835H6.363Zm17.22 6.567h-3.998a.417.417 0 1 0 0 .834h3.998a.417.417 0 0 0 0-.834Zm-1.64 1.462h-3.994a.418.418 0 0 0 0 .835h3.994a.417.417 0 0 0 0-.835Z"
      />
    </svg>
  );
};
export default Disciplina28;
