/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-62.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina62 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-62', className].join(' ');
  return (
    <svg
      id="disciplina_tiro-a-volo"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M14.966 3.52h-2.425l.745-.814a.97.97 0 0 1 1.14-.227.977.977 0 0 1 .441.43.993.993 0 0 1 .1.612Zm6.259.91h-.537v-.357a.476.476 0 0 0-.137-.334.465.465 0 0 0-.798.334v.356h-4.088c.062.058.121.119.176.183.32.388.478.887.44 1.391h4.939a.773.773 0 0 0 .55-.229.791.791 0 0 0 .004-1.113.773.773 0 0 0-.55-.232Zm-13.448.73v1.082l3.07.098 2.203-1.869H8.462a.676.676 0 0 0-.483.201.692.692 0 0 0-.202.488Zm7.254-.038a.97.97 0 0 0-1.273-.023l-2.583 2.192c-.815-.244-4.333-1.246-4.333-1.246v-.468H6.56a1.351 1.351 0 0 0-.966.404 1.382 1.382 0 0 0-.4.977v7.068l-.143 3.527-1.954 4.79a1.196 1.196 0 0 0 .242 1.307 1.17 1.17 0 0 0 .837.35 1.158 1.158 0 0 0 .833-.36c.108-.112.193-.246.25-.392l2.028-4.979c.052-.128.082-.264.088-.403l.148-3.592a.242.242 0 0 1 .073-.162.237.237 0 0 1 .163-.067.244.244 0 0 1 .227.159l1.157 3.19-1.079 5.151a1.199 1.199 0 0 0 .483 1.223 1.166 1.166 0 0 0 1.301.017 1.176 1.176 0 0 0 .508-.748l1.148-5.484a1.2 1.2 0 0 0-.047-.651l-1.097-3.03V9.983C9.712 9.609 7.837 8.532 7.2 8.167l3.925 1.17a.965.965 0 0 0 .903-.191l2.986-2.534a.987.987 0 0 0 .344-.74.997.997 0 0 0-.326-.75Zm-8.138-.365c.088-.352.29-.665.573-.889.284-.224.632-.346.992-.347h1.259a2.06 2.06 0 0 0-.227-1.695H6.078a2.06 2.06 0 0 0 .815 2.931Zm2.81-3.269c.593 0 1.042-.019 1.042-.267 0-.272-.542-.234-1.227-.234A1.871 1.871 0 0 0 8.716.2 1.842 1.842 0 0 0 6.608.51c-.278.263-.47.607-.548.984h3.643v-.005Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina62;
