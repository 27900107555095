/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-36.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina36 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-36', className].join(' ');
  return (
    <svg
      id="disciplina_manifestazione-musicale"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="m21.447 7.978-7.012 2.148c-.85.241-1.538 1.13-1.538 1.977v6.269s-.507-.344-1.642-.185c-1.663.236-3.012 1.513-3.012 2.849 0 1.336 1.35 2.157 3.012 1.925 1.663-.232 2.883-1.47 2.883-2.81v-5.186c0-.593.714-.847.714-.847l6.2-1.946s.688-.232.688.404v4.567s-.632-.36-1.762-.228c-1.663.202-3.012 1.453-3.012 2.789s1.349 2.187 3.012 1.985c1.663-.202 3.012-1.452 3.012-2.789V9.078c-.005-.85-.692-1.345-1.543-1.1Zm-9.019 2.505a1.38 1.38 0 1 1-2.759 0 1.38 1.38 0 0 1 2.76 0Zm-1.379 2.793a1.379 1.379 0 1 0 0 2.757 1.379 1.379 0 0 0 0-2.757Zm4.52-8.185h.52a.612.612 0 0 0 .61-.61V1.61A.607.607 0 0 0 16.093 1h-.52a.612.612 0 0 0-.61.61v2.87a.61.61 0 0 0 .606.61ZM6 5.09h.52a.612.612 0 0 0 .61-.61V1.61A.612.612 0 0 0 6.52 1H6a.612.612 0 0 0-.61.61v2.87a.615.615 0 0 0 .61.61Zm.903 8.185a1.379 1.379 0 1 0 0 2.758 1.379 1.379 0 0 0 0-2.758Zm0-4.177a1.38 1.38 0 1 0 0 2.759 1.38 1.38 0 0 0 0-2.759Zm3.012 8.474c.289-.074.57-.176.838-.305H4.608a1.045 1.045 0 0 1-1.044-1.044V7.87h16.53V4.394a1.392 1.392 0 0 0-1.392-1.392h-1.13V4.48a1.478 1.478 0 0 1-1.479 1.478h-.52a1.478 1.478 0 0 1-1.478-1.478V3.002H8.003V4.48a1.478 1.478 0 0 1-1.478 1.478h-.52A1.478 1.478 0 0 1 4.527 4.48V3.002H3.392A1.392 1.392 0 0 0 2 4.394v13.222a1.391 1.391 0 0 0 1.392 1.392h4.026c.507-.636 1.375-1.126 2.497-1.435Z"
      />
    </svg>
  );
};
export default Disciplina36;
