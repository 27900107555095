/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-1003.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina1003 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-1003', className].join(' ');
  return (
    <svg
      id="disciplina_calcio-ev"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.22829 4.60346s2.29757-1.26 4.68521-1.602c.4595-.063 2.2525 1.935 3.3428 2.043.4144.045 3.2076.207 3.2076.207s.1351-.108.6577.576c.5226.684 1.7479 2.502 1.7479 2.502s.1712.711.0541.99c-.1171.279-1.0362 2.50204-1.0362 2.50204s.5316 1.638.7839 2.196c.2523.567.3514.864.3244 1.377-.027.522-1.1083 2.025-1.6218 2.646-.5046.621-.7569.63-1.1083.657-.3423.027-3.4418.297-3.4418.297s-.3965.189-1.0902.756c-.6938.567-1.2344 1.251-1.6218 1.251-.3875 0-4.41499-.963-4.64024-1.206-.22525-.243-.4505-.945-.52258-1.224-.06307-.252-.6217-2.205-.92804-2.394-.30634-.189-2.64896-1.638-2.80214-1.989-.15317-.351-.3604-4.158-.08109-4.34704.27932-.198 2.54085-1.503 3.04541-2.097.50456-.594.60368-2.781 1.04517-3.141ZM8.6699 10.0845l-.05406 3.798s-.05406.297.3604.603c.41447.297 4.46896 1.629 4.46896 1.629s.4145.027.7299-.18c.3153-.207 2.748-3.789 2.748-3.789s-.1531-.324-.4054-.738c-.2523-.414-2.667-3.43804-2.667-3.43804s-.2072-.234-.7478-.018c-.2794.117-4.17171 1.62-4.17171 1.62s-.26129.36-.26129.51304Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina1003;
