/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-48.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina48 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-48', className].join(' ');
  return (
    <svg
      id="disciplina_beach-volley"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="m3.767 18.592 4.51.248a.984.984 0 0 0 .84-.398l.074-.104 1.294-1.771-.183 2.001L6.3 22.614a.816.816 0 0 0 1.101 1.2l4.735-3.92a.961.961 0 0 0 .333-.571l.019-.103 1.068-5.841c.005-.038.014-.08.02-.122a1.31 1.31 0 0 0 0-.234l.05-.005-.304-4.974 6.77-2.54a.83.83 0 1 0-.587-1.552l-6.492 2.437-1.904.244a1.792 1.792 0 1 0-1.96.253l-.323.042.024.127-2.508-.577L7.794 2.56a.828.828 0 0 0-1.551-.577L4.46 6.788a.824.824 0 0 0 .59 1.092l4.154.956.75 3.783c-.023.033-.051.066-.07.099l-2.283 3.97-3.849.277a.815.815 0 0 0 .014 1.627ZM14.202 0a1.641 1.641 0 1 1 0 3.282 1.641 1.641 0 0 1 0-3.282Z"
      />
    </svg>
  );
};
export default Disciplina48;
