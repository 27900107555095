/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\preferred-on.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const PreferredOn = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-preferred-on', className].join(' ');
  return (
    <svg id="preferred-on" viewBox="0 0 48 48" focusable="false" className={componentClassName} {...attribs}>
      <path
        d="M24 41.95L21.95 40.1C18.4167 36.8667 15.5 34.075 13.2 31.725C10.9 29.375 9.06667 27.275 7.7 25.425C6.33333 23.575 5.375 21.9 4.825 20.4C4.275 18.9 4 17.3833 4 15.85C4 12.85 5.00833 10.3417 7.025 8.32499C9.04167 6.30832 11.5333 5.29999 14.5 5.29999C16.4 5.29999 18.1583 5.74999 19.775 6.64999C21.3917 7.54999 22.8 8.84999 24 10.55C25.4 8.74999 26.8833 7.42499 28.45 6.57499C30.0167 5.72499 31.7 5.29999 33.5 5.29999C36.4667 5.29999 38.9583 6.30832 40.975 8.32499C42.9917 10.3417 44 12.85 44 15.85C44 17.3833 43.725 18.9 43.175 20.4C42.625 21.9 41.6667 23.575 40.3 25.425C38.9333 27.275 37.1 29.375 34.8 31.725C32.5 34.075 29.5833 36.8667 26.05 40.1L24 41.95Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default PreferredOn;
