/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-294.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco294 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-294', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.569"
      height="41.396"
      viewBox="0 0 29.569 41.396"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M328.567,170.044l-4.475-12.3a.739.739,0,0,0-1.389.506l3.865,10.62-4.067-1.9a.74.74,0,0,0-.982.358l-1.9,4.067-3.535-9.71a.734.734,0,0,0-1.4-.452l-3.7,10.162-1.9-4.067a.74.74,0,0,0-.982-.358l-4.067,1.9,3.865-10.619a.739.739,0,1,0-1.389-.506l-4.476,12.3a.739.739,0,0,0,1.008.923l5.015-2.338,2.338,5.014a.739.739,0,0,0,.67.427h.033a.74.74,0,0,0,.662-.486l3.536-9.717,3.536,9.717a.74.74,0,0,0,.662.486h.033a.739.739,0,0,0,.67-.427l2.338-5.014,5.015,2.338a.739.739,0,0,0,1.007-.923Z"
        transform="translate(-300.521 -132.674)"
        fill="currentColor"
      />
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M314.784,153.569a3.864,3.864,0,0,1-2.327-1.122,3.822,3.822,0,0,0-1.137-.735,3.915,3.915,0,0,0-1.416.063,3.786,3.786,0,0,1-2.515-.186,3.832,3.832,0,0,1-1.43-2.108,2.28,2.28,0,0,0-1.872-1.872,2.91,2.91,0,0,1-2.293-3.945,4,4,0,0,0,.064-1.415,3.876,3.876,0,0,0-.735-1.137,2.973,2.973,0,0,1,0-4.653,3.878,3.878,0,0,0,.735-1.138,4,4,0,0,0-.064-1.415,2.91,2.91,0,0,1,2.293-3.945,2.281,2.281,0,0,0,1.873-1.873,2.909,2.909,0,0,1,3.945-2.293,3.963,3.963,0,0,0,1.415.063,3.823,3.823,0,0,0,1.137-.735,2.973,2.973,0,0,1,4.653,0,3.842,3.842,0,0,0,1.137.735,3.97,3.97,0,0,0,1.416-.063,2.91,2.91,0,0,1,3.944,2.294,2.282,2.282,0,0,0,1.873,1.872,2.91,2.91,0,0,1,2.293,3.945,4,4,0,0,0-.064,1.415,3.889,3.889,0,0,0,.735,1.138,2.972,2.972,0,0,1,0,4.653,3.871,3.871,0,0,0-.736,1.138,4,4,0,0,0,.064,1.415,2.91,2.91,0,0,1-2.293,3.945,2.281,2.281,0,0,0-1.874,1.872,2.908,2.908,0,0,1-3.944,2.293,3.983,3.983,0,0,0-1.416-.063,3.84,3.84,0,0,0-1.137.735A3.86,3.86,0,0,1,314.784,153.569Zm-3.867-3.38a3,3,0,0,1,.785.1,4.775,4.775,0,0,1,1.681,1.01,1.63,1.63,0,0,0,2.8,0,4.775,4.775,0,0,1,1.682-1.01,4.949,4.949,0,0,1,2.017.03,1.571,1.571,0,0,0,2.346-1.368,3.633,3.633,0,0,1,2.715-2.714,1.572,1.572,0,0,0,1.368-2.346,4.941,4.941,0,0,1-.03-2.018,4.772,4.772,0,0,1,1.011-1.682,1.63,1.63,0,0,0,0-2.8,4.778,4.778,0,0,1-1.011-1.682,4.941,4.941,0,0,1,.03-2.018,1.572,1.572,0,0,0-1.368-2.346,3.63,3.63,0,0,1-2.714-2.714,1.573,1.573,0,0,0-2.346-1.368,4.931,4.931,0,0,1-2.019.03,4.779,4.779,0,0,1-1.682-1.011,1.63,1.63,0,0,0-2.8,0,4.772,4.772,0,0,1-1.682,1.011,4.911,4.911,0,0,1-2.017-.03,1.571,1.571,0,0,0-2.346,1.368,3.633,3.633,0,0,1-2.715,2.714,1.572,1.572,0,0,0-1.368,2.346,4.94,4.94,0,0,1,.03,2.018,4.781,4.781,0,0,1-1.01,1.682,1.63,1.63,0,0,0,0,2.8,4.775,4.775,0,0,1,1.01,1.681,4.94,4.94,0,0,1-.03,2.018,1.572,1.572,0,0,0,1.368,2.346,3.631,3.631,0,0,1,2.714,2.714,1.573,1.573,0,0,0,2.346,1.368A8.243,8.243,0,0,1,310.918,150.188Z"
          transform="translate(-300 -124)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(7.392 8.87)">
        <path
          className="a"
          d="M315.914,147.827a.737.737,0,0,1-.523-.217l-5.174-5.174a.738.738,0,0,1,0-1.045l2.218-2.218a.738.738,0,0,1,1.045,0l2.434,2.434,5.391-5.391a.738.738,0,0,1,1.045,0l2.218,2.218a.738.738,0,0,1,0,1.045l-8.131,8.131A.737.737,0,0,1,315.914,147.827Zm-4.129-5.914,4.129,4.129L323,138.957l-1.172-1.172-5.391,5.391a.738.738,0,0,1-1.045,0l-2.434-2.434Z"
          transform="translate(-310 -136)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco294;
