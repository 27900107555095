/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\star-outline.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const StarOutline = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-star-outline', className].join(' ');
  return (
    <svg
      id="star-outline"
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M27.3337 10.3174L17.747 9.49073L14.0003 0.664062L10.2537 9.50406L0.666992 10.3174L7.94699 16.6241L5.76033 25.9974L14.0003 21.0241L22.2403 25.9974L20.067 16.6241L27.3337 10.3174ZM14.0003 18.5307L8.98699 21.5574L10.3203 15.8507L5.89366 12.0107L11.7337 11.5041L14.0003 6.13073L16.2803 11.5174L22.1203 12.0241L17.6937 15.8641L19.027 21.5707L14.0003 18.5307Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default StarOutline;
