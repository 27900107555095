/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-372.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco372 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-372', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="38.439"
      viewBox="0 0 35.482 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M76.45,233.59l-5.838-1.946v-5.381a2.957,2.957,0,1,0-5.914,0v10.782l-.56-.56a4.44,4.44,0,0,0-6.274,0,1.229,1.229,0,0,0-.005,1.729l7.743,7.843a8.14,8.14,0,0,0,5.759,2.381c.189,0,.378-.007.569-.02a8.274,8.274,0,0,0,7.552-8.339V237.8A4.431,4.431,0,0,0,76.45,233.59ZM78,240.08a6.792,6.792,0,0,1-6.176,6.864,6.662,6.662,0,0,1-5.177-1.927l-7.556-7.655a2.963,2.963,0,0,1,4,.168l1.821,1.821a.739.739,0,0,0,1.262-.523V226.263a1.478,1.478,0,1,1,2.957,0v5.914a.739.739,0,0,0,.506.7l6.343,2.114A2.953,2.953,0,0,1,78,237.8ZM58.045,229.959a.739.739,0,1,0,1.478,0V228.48a2.218,2.218,0,1,0,0-4.435H58.045a.739.739,0,0,1,0-1.478H61a.739.739,0,0,0,0-1.478H59.524V219.61a.739.739,0,1,0-1.478,0v1.478a2.218,2.218,0,1,0,0,4.435h1.478a.739.739,0,1,1,0,1.478H56.567a.739.739,0,1,0,0,1.478h1.478Zm-3.066,7.572A13.3,13.3,0,1,1,72.09,224.784c0,.193-.013.382-.027.572l-.015.23a.739.739,0,1,0,1.475.088l.015-.217c.015-.223.03-.446.03-.673a14.784,14.784,0,1,0-19.013,14.163.755.755,0,0,0,.211.031.74.74,0,0,0,.211-1.448Z"
        transform="translate(-44 -210)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco372;
