import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state
export interface SnaiPlusSlice {
  snaiPlusOpen: boolean;
  contentLoaded: boolean;
}

// Define the initial state using that type
const initialState: SnaiPlusSlice = {
  snaiPlusOpen: false,
  contentLoaded: false,
};

export const snaiPlusSlice = createSlice({
  name: 'snaiPlus',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSnaiPlusOpen: (state, action: PayloadAction<boolean>) => {
      state.snaiPlusOpen = action.payload;
    },
    setContentLoaded: (state, action: PayloadAction<boolean>) => {
      state.contentLoaded = action.payload;
    },
  },
});

export const { setSnaiPlusOpen, setContentLoaded } = snaiPlusSlice.actions;

export default snaiPlusSlice.reducer;
