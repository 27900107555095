/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-35.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina35 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-35', className].join(' ');
  return (
    <svg
      id="disciplina_freestyle"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M19.383.067a1.276 1.276 0 0 0-1.617.802l-.01.028a12.328 12.328 0 0 1-2.877 4.672l-.005.005-2.41-.005a12.45 12.45 0 0 1-3.95-.656 1.275 1.275 0 1 0-.82 2.414c1.535.52 3.145.788 4.766.792l1.21.005.215 2.493a6.187 6.187 0 0 0-4.462.056l-3.051 1.228c-.292.119-.531.34-.67.624L3.452 9.9a1.298 1.298 0 0 0-.14 1.828l10.128 11.82a1.298 1.298 0 0 0 1.828.14l-2.878-3.355a1.273 1.273 0 0 0 .665-.75l1.036-3.121a3.623 3.623 0 0 1 2.062-2.213l.146-.06a1.91 1.91 0 0 0 1.55-2.044l-.416-4.893a2.05 2.05 0 0 0-.127-.544 14.94 14.94 0 0 0 2.868-4.986l.01-.029a1.283 1.283 0 0 0-.802-1.626Zm-7.71 15.584-.919 2.765L7.22 14.29c.033-.009.066-.023.099-.032l3.05-1.228a3.61 3.61 0 0 1 2.902.089 6.224 6.224 0 0 0-1.598 2.53Zm.126-13.405a1.897 1.897 0 1 1 3.595 1.215A1.897 1.897 0 0 1 11.8 2.246Z"
      />
    </svg>
  );
};
export default Disciplina35;
