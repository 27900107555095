/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-146.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco146 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-146', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="32.526"
      viewBox="0 0 38.439 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M414.222,130h-34A2.22,2.22,0,0,0,378,132.218v23.655a2.22,2.22,0,0,0,2.218,2.218h12.434l-.493,2.957h-1.592a.739.739,0,0,0,0,1.478h13.306a.739.739,0,0,0,0-1.478h-1.592l-.493-2.957h12.434a2.22,2.22,0,0,0,2.218-2.218V132.218A2.22,2.22,0,0,0,414.222,130Zm-34,1.478h34a.74.74,0,0,1,.739.739v19.959H379.478V132.218A.74.74,0,0,1,380.218,131.478Zm20.565,29.569h-7.126l.493-2.957h6.14Zm13.439-4.435h-34a.74.74,0,0,1-.739-.739v-2.218h35.482v2.218A.74.74,0,0,1,414.222,156.612Z"
        transform="translate(-378 -130)"
        fill="currentColor"
      />
      <g transform="translate(13.306 17.741)">
        <path
          className="a"
          d="M397.478,156.957a1.478,1.478,0,1,1,1.478-1.478A1.48,1.48,0,0,1,397.478,156.957Zm0-1.478h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Z"
          transform="translate(-396 -154)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(20.698 17.741)">
        <path
          className="a"
          d="M407.478,156.957a1.478,1.478,0,1,1,1.478-1.478A1.48,1.48,0,0,1,407.478,156.957Zm0-1.478h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Zm0,0h0Z"
          transform="translate(-406 -154)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(10.349 7.392)">
        <path
          className="a"
          d="M404.567,150.349H395.7a.739.739,0,1,1,0-1.478h8.871a.739.739,0,1,0,0-1.478h-9.61a.741.741,0,0,1-.693-.48L392.046,141a.739.739,0,0,1,.693-1h10.349a.739.739,0,0,1,0,1.478h-9.282l1.663,4.435h9.1a2.218,2.218,0,1,1,0,4.435Z"
          transform="translate(-392 -140)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(22.176 4.435)">
        <path
          className="a"
          d="M408.739,146.35a.747.747,0,0,1-.207-.03.739.739,0,0,1-.5-.917l2.587-8.871a.74.74,0,0,1,.71-.532h1.848a.739.739,0,0,1,0,1.478h-1.294l-2.432,8.338A.739.739,0,0,1,408.739,146.35Z"
          transform="translate(-408 -136)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco146;
