/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-364.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco364 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-364', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.526"
      height="38.439"
      viewBox="0 0 32.526 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M404.916,409.047a.739.739,0,0,0-.739.739v.739h-20.7V383.914h20.7v.739a.739.739,0,0,0,1.478,0V381.7a3.7,3.7,0,0,0-3.7-3.7H385.7a3.7,3.7,0,0,0-3.7,3.7v31.047a3.7,3.7,0,0,0,3.7,3.7h16.263a3.7,3.7,0,0,0,3.7-3.7v-2.957A.739.739,0,0,0,404.916,409.047ZM383.478,381.7a2.22,2.22,0,0,1,2.218-2.218h16.263a2.22,2.22,0,0,1,2.218,2.218v.739h-20.7Zm20.7,31.047a2.22,2.22,0,0,1-2.218,2.218H385.7a2.22,2.22,0,0,1-2.218-2.218V412h20.7Zm-15.524-12.567h2.957a.739.739,0,0,0,0-1.478h-2.957a.739.739,0,1,0,0,1.478Zm20.7,0a.739.739,0,0,0,0-1.478h-2.957a.739.739,0,0,0,0,1.478Zm-5.914,0a.739.739,0,0,0,0-1.478H400.48a.739.739,0,0,0,0,1.478Zm-8.871,0h2.957a.739.739,0,0,0,0-1.478h-2.957a.739.739,0,0,0,0,1.478Zm-5.914,2.957h8.871a.739.739,0,0,0,0-1.478h-8.871a.739.739,0,1,0,0,1.478Zm22.916-16.263H387.175a.739.739,0,0,0,0,1.478h24.394a1.481,1.481,0,0,1,1.478,1.478v14.784a1.48,1.48,0,0,1-1.478,1.478H387.175a.739.739,0,1,0,0,1.478h24.394a2.96,2.96,0,0,0,2.957-2.957V389.828A2.96,2.96,0,0,0,411.569,386.871Zm-18.48,4.435h-4.435a.739.739,0,0,0-.739.739v4.435a.739.739,0,0,0,.739.739h4.435a.739.739,0,0,0,.739-.739v-4.435A.739.739,0,0,0,393.088,391.306Zm-.739,4.435h-2.957v-2.957h2.957Z"
        transform="translate(-382 -378)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco364;
