/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-371.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco371 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-371', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="29.569"
      viewBox="0 0 41.396 29.569"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M236.829,313.306H213.175a.739.739,0,1,0,0,1.478h23.655a.739.739,0,1,0,0-1.478Zm0,2.957H226.48a.739.739,0,1,0,0,1.478h10.349a.739.739,0,1,0,0-1.478Zm5.175,3.7V303.7a3.7,3.7,0,0,0-3.7-3.7H211.7a3.7,3.7,0,0,0-3.7,3.7v16.263a3.7,3.7,0,0,0,3.7,3.7h26.612A3.7,3.7,0,0,0,242,319.959ZM209.478,303.7a2.22,2.22,0,0,1,2.218-2.218h26.612a2.22,2.22,0,0,1,2.218,2.218v.739H209.478Zm0,2.218h31.047v2.957H209.478Zm0,14.045v-9.61h31.047v9.61a2.22,2.22,0,0,1-2.218,2.218H211.7A2.22,2.22,0,0,1,209.478,319.959ZM245.7,305.914h-1.478a.739.739,0,1,0,0,1.478H245.7a2.22,2.22,0,0,1,2.218,2.218v16.263a2.22,2.22,0,0,1-2.218,2.218H219.088a2.22,2.22,0,0,1-2.218-2.218.739.739,0,0,0-1.478,0,3.7,3.7,0,0,0,3.7,3.7H245.7a3.7,3.7,0,0,0,3.7-3.7V309.61A3.7,3.7,0,0,0,245.7,305.914Z"
        transform="translate(-208 -300)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco371;
