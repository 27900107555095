/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-387.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco387 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-387', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M244.743,464.957h-5.174v-.739a2.218,2.218,0,0,0-4.435,0v.739H223.306v-.739a2.218,2.218,0,0,0-4.435,0v.739H213.7a3.7,3.7,0,0,0-3.7,3.7v25.133a3.7,3.7,0,0,0,3.7,3.7H229.22a.739.739,0,1,0,0-1.478H213.7a2.22,2.22,0,0,1-2.218-2.218V473.828h33.265a.739.739,0,1,0,0-1.478H211.478v-3.7a2.22,2.22,0,0,1,2.218-2.218h5.174v2.218a2.218,2.218,0,0,0,4.435,0v-2.218h11.827v2.218a2.218,2.218,0,1,0,4.435,0v-2.218h5.174a2.22,2.22,0,0,1,2.218,2.218v14.784a.739.739,0,0,0,1.478,0V468.653A3.7,3.7,0,0,0,244.743,464.957Zm-22.916,3.7a.739.739,0,0,1-1.478,0v-4.435a.739.739,0,0,1,1.478,0Zm16.263,0a.739.739,0,0,1-1.478,0v-4.435a.739.739,0,0,1,1.478,0Zm-18.48,9.61h-2.957a.739.739,0,1,0,0,1.478h2.957a.739.739,0,0,0,0-1.478Zm7.392,0h-2.957a.739.739,0,0,0,0,1.478H227a.739.739,0,1,0,0-1.478Zm7.392,1.478a.739.739,0,0,0,0-1.478h-2.957a.739.739,0,1,0,0,1.478Zm7.392,0a.739.739,0,0,0,0-1.478h-2.957a.739.739,0,0,0,0,1.478Zm-22.177,4.435h-2.957a.739.739,0,1,0,0,1.478h2.957a.739.739,0,0,0,0-1.478Zm7.392,0h-2.957a.739.739,0,0,0,0,1.478H227a.739.739,0,1,0,0-1.478Zm-7.392,5.914h-2.957a.739.739,0,1,0,0,1.478h2.957a.739.739,0,1,0,0-1.478Zm7.392,0h-2.957a.739.739,0,1,0,0,1.478H227a.739.739,0,1,0,0-1.478Zm12.567-7.392a8.871,8.871,0,1,0,8.871,8.871A8.88,8.88,0,0,0,239.569,482.7Zm0,16.263a7.392,7.392,0,1,1,7.392-7.392A7.4,7.4,0,0,1,239.569,498.961Zm-.739-9.61h2.957a.739.739,0,1,0,0-1.478h-1.478v-.739a.739.739,0,1,0-1.478,0v.739a2.218,2.218,0,1,0,0,4.435h1.478a.739.739,0,0,1,0,1.478h-2.957a.739.739,0,0,0,0,1.478h1.478V496a.739.739,0,1,0,1.478,0v-.739a2.218,2.218,0,0,0,0-4.435h-1.478a.739.739,0,1,1,0-1.478Z"
        transform="translate(-210 -462)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco387;
