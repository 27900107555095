/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\plus-circle.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const PlusCircle = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-plus-circle', className].join(' ');
  return (
    <svg
      id="plus-circle"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path fill="currentColor" d="M17 13h-4v4h-2v-4H7v-2h4V7h2v4h4m-5-9a10 10 0 1 0 0 20 10 10 0 0 0 0-20Z" />
    </svg>
  );
};
export default PlusCircle;
