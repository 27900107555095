/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-264.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco264 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-264', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="35.482"
      viewBox="0 0 41.396 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M236.957,165.478a1.478,1.478,0,1,0-2.218,1.273v.945a.739.739,0,1,0,1.478,0v-.945A1.474,1.474,0,0,0,236.957,165.478Z"
        transform="translate(-214.78 -137.388)"
        fill="currentColor"
      />
      <g transform="translate(11.828 22.177)">
        <path
          className="a"
          d="M238.784,171.306H226.957A2.96,2.96,0,0,1,224,168.349v-7.392A2.96,2.96,0,0,1,226.957,158h11.827a2.96,2.96,0,0,1,2.957,2.957v7.392A2.96,2.96,0,0,1,238.784,171.306Zm-11.827-11.827a1.48,1.48,0,0,0-1.478,1.478v7.392a1.48,1.48,0,0,0,1.478,1.478h11.827a1.48,1.48,0,0,0,1.478-1.478v-7.392a1.48,1.48,0,0,0-1.478-1.478Z"
          transform="translate(-224 -158)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(14.785 13.306)">
        <path
          className="a"
          d="M239.088,156.349a.739.739,0,0,1-.739-.739v-3.7a4.435,4.435,0,1,0-8.871,0v3.7a.739.739,0,0,1-1.478,0v-3.7a5.914,5.914,0,1,1,11.827,0v3.7A.739.739,0,0,1,239.088,156.349Z"
          transform="translate(-228 -146)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(10.349 8.871)">
        <path
          className="a"
          d="M241.727,153.169a.738.738,0,0,1-.723-.9,8.87,8.87,0,1,0-17.312,0,.739.739,0,1,1-1.444.318,10.349,10.349,0,1,1,20.2,0A.74.74,0,0,1,241.727,153.169Z"
          transform="translate(-222 -140)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M236.194,128.982a.738.738,0,0,0-.906.523l-.765,2.856a.739.739,0,0,0,.523.906.749.749,0,0,0,.191.025.74.74,0,0,0,.714-.548l.765-2.856A.739.739,0,0,0,236.194,128.982Zm6.953,2.88a.738.738,0,0,0-1.01.271l-1.478,2.561a.739.739,0,0,0,1.28.739l1.478-2.561A.739.739,0,0,0,243.147,131.862Zm5.971,4.582a.738.738,0,0,0-1.045,0l-2.091,2.091a.739.739,0,1,0,1.045,1.045l2.091-2.091A.738.738,0,0,0,249.117,136.444Zm-39.726,0a.739.739,0,1,0-1.045,1.045l2.09,2.091a.739.739,0,1,0,1.045-1.045Zm5.935-4.311a.739.739,0,0,0-1.28.739l1.478,2.561a.739.739,0,1,0,1.28-.739Zm6.849-2.628a.739.739,0,1,0-1.428.383l.765,2.856a.74.74,0,0,0,.714.548.749.749,0,0,0,.191-.025.739.739,0,0,0,.523-.906ZM228.732,128a.739.739,0,0,0-.739.739V131.7a.739.739,0,1,0,1.478,0v-2.957A.739.739,0,0,0,228.732,128Z"
        transform="translate(-208.033 -128)"
        fill="currentColor"
      />
      <g transform="translate(0 5.914)">
        <path
          className="a"
          d="M239.787,159.441a.739.739,0,0,1-.385-1.371,24.407,24.407,0,0,0,8.406-8.764c-4.242-7.414-11.358-11.827-19.11-11.827s-14.869,4.413-19.111,11.827a24.419,24.419,0,0,0,8.406,8.764.739.739,0,0,1-.768,1.263,25.96,25.96,0,0,1-9.134-9.67.741.741,0,0,1,0-.716C212.573,140.84,220.275,136,228.7,136s16.125,4.84,20.606,12.948a.741.741,0,0,1,0,.716,25.948,25.948,0,0,1-9.134,9.67A.738.738,0,0,1,239.787,159.441Z"
          transform="translate(-207.999 -136)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco264;
