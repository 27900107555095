/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\full-pinned.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const FullPinned = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-full-pinned', className].join(' ');
  return (
    <svg
      id="full-pinned"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M9.144 4.144v2.857c0 .64.212 1.235.572 1.715H6.287A2.825 2.825 0 0 0 6.858 7V4.144h2.286Zm1.714-1.143H5.144a.573.573 0 0 0-.571.572c0 .314.257.571.571.571h.572v2.857c0 .949-.766 1.715-1.715 1.715v1.142h3.412v4l.571.572.572-.572v-4H12V8.716A1.712 1.712 0 0 1 10.287 7V4.144h.571a.573.573 0 0 0 .572-.571.573.573 0 0 0-.572-.572Z"
      />
      <path
        fill="currentColor"
        d="M9.144 4.144v2.857c0 .64.212 1.235.572 1.715H6.287A2.825 2.825 0 0 0 6.858 7V4.144h2.286Z"
      />
    </svg>
  );
};
export default FullPinned;
