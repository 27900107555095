/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-143.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina143 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-143', className].join(' ');
  return (
    <svg
      id="disciplina_calcio-ultra"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M20.8458 14.2211c-.6279-1.557-.6654-3.015-.0563-4.58996.731-1.899-1.34-4.644-3.4671-4.644-1.5462 0-2.8955-.306-4.1418-1.305-1.921-1.539-5.58495-.297-6.48454 1.962-.41231 1.062-1.06825 2.223-1.97721 2.871-1.32126.954-1.8835 1.99796-1.67735 3.50096.02812-.018.05623-.018.03749.045.01874 1.737.21552 2.268.94643 2.781 1.52742 1.08 2.51134 2.34 3.05484 4.068.51539 1.629 4.55414 2.754 5.88474 1.647 1.3588-1.125 2.8112-1.665 4.6104-1.674 2.0053-.009 4.0013-2.862 3.2704-4.662Zm-4.5167-1.719-2.3708 2.952s-.2717.423-.9277.279c-.6559-.144-3.5983-1.161-3.5983-1.161s-.33735-.189-.39357-.495c-.04685-.261-.05623-3.114-.0656-3.942.36546.441.79651.846 1.01204 1.062.33733.324 1.40563.702 1.75233.918.3373.216 1.3025.81 1.3025.486 0-.324.8527-1.233.8996-1.629.0562-.486 0-.756 0-.756l-.2343-1.92596c.3186-.018.581.243.581.243s1.7711 2.47496 2.0897 3.04196c.3092.558-.0469.927-.0469.927Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina143;
