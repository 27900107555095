import Persist, { StorageKind } from 'lib/persist';
import { STORAGE_PREFIX_KEY } from 'utility/constant';
import { isClientSide, isMatch, isTruthy } from 'utility/functions';

export const useSecondaryKey = 'use-secondary';
const key = `${STORAGE_PREFIX_KEY}${useSecondaryKey}`;

const persist = Persist(StorageKind.session);

/* eslint-disable no-unused-vars */
export enum UseSecondary {
  never = 'never',
  always = 'always',
}
/* eslint-enable no-unused-vars */
export type UseSecondaryType = keyof typeof UseSecondary;

export const UseSecondaryOptions: Array<string> = Object.values(UseSecondary);

const setSecondary = (option?: UseSecondary | UseSecondaryType | string): void => {
  if (option) {
    persist.setItem(key, option);
  } else {
    persist.removeItem(key);
  }
};

export const fromSearchSetSecondary = (search: string): string | null => {
  const params = (search ?? '').replace(/^\?/gim, '').split('&');
  const [_, value] = (params.find((x) => isMatch(x, `^${useSecondaryKey}=`)) ?? '=').toLowerCase().split('=');

  if (!!value) {
    const result = decodeURIComponent(value);

    if (UseSecondaryOptions.includes(result)) {
      setSecondary(result);
      return result;
    }
  }

  return null;
};

export interface UseSecondaryHead {
  name: string;
  value: UseSecondary | UseSecondaryType;
}
export const getUseSecondary = (): UseSecondaryHead | null => {
  const search = isClientSide() ? Reflect.get(document?.location ?? {}, 'search') : '?';
  const qsValue = fromSearchSetSecondary(search);

  const value = (persist.getItem(key) ?? qsValue) as UseSecondaryType;

  if (isTruthy(value?.length)) {
    return { name: useSecondaryKey, value };
  }

  return null;
};
