/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\legenda-annullata-light.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const LegendaAnnullataLight = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-legenda-annullata-light', className].join(' ');
  return (
    <svg
      id="legenda-annullata-light"
      width="100%"
      height="100%"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M0 4a4 4 0 0 1 4-4h8L0 12V4Z" fill="#0f1315" />
    </svg>
  );
};
export default LegendaAnnullataLight;
