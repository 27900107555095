/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\radio.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Radio = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-radio', className].join(' ');
  return (
    <svg
      id="radio"
      width="100%"
      height="100%"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M19.7 6.5c.4774 0 .9352.18964 1.2728.52721.3376.33756.5272.7954.5272 1.27279v10.8c0 .4774-.1896.9352-.5272 1.2728-.3376.3376-.7954.5272-1.2728.5272H5.3c-.47739 0-.93523-.1896-1.27279-.5272C3.68964 20.0352 3.5 19.5774 3.5 19.1V8.3c0-.765.477-1.422 1.152-1.683L15.839 2l.684 1.647L9.647 6.5H19.7Zm0 1.8H5.3v3.6h10.8v-1.8h1.8v1.8h1.8V8.3ZM8 13.7c-.71608 0-1.40284.2845-1.90919.7908C5.58446 14.9972 5.3 15.6839 5.3 16.4s.28446 1.4028.79081 1.9092C6.59716 18.8155 7.28392 19.1 8 19.1s1.40284-.2845 1.90919-.7908c.50631-.5064.79081-1.1931.79081-1.9092s-.2845-1.4028-.79081-1.9092C9.40284 13.9845 8.71608 13.7 8 13.7Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Radio;
