/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-55.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina55 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-55', className].join(' ');
  return (
    <svg
      id="disciplina_sollevamento-pesi"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M10.05 6.736a1.795 1.795 0 1 1 3.59 0 1.795 1.795 0 0 1-3.59 0Zm10.19-3.253a.544.544 0 0 1-.543.544h-.267v2.512a.35.35 0 0 1-.347.347h-1.285a.35.35 0 0 1-.346-.347V4.027h-.666l-.342 3.239a.796.796 0 0 1-.16.403l-2.38 3.093c0 .98.013 4.59.018 6.07l2.344 1.885c.053.042.101.09.145.14.164.115.292.274.365.46l1.322 3.263a1.036 1.036 0 0 1-.567 1.345 1.036 1.036 0 0 1-1.345-.567l-1.233-3.042-2.723-2.19a1.036 1.036 0 0 1-.38-.895h-.005v-.351h-.45v.351h-.004a1.03 1.03 0 0 1-.38.895l-2.724 2.185-1.232 3.042a1.036 1.036 0 0 1-1.346.567 1.036 1.036 0 0 1-.567-1.345l1.322-3.262c.076-.185.202-.345.366-.46a.827.827 0 0 1 .145-.14l2.344-1.88v-6.099L6.96 7.67a.797.797 0 0 1-.16-.403l-.342-3.24H5.7V6.54a.35.35 0 0 1-.347.347H4.07a.35.35 0 0 1-.347-.347V4.027h-.178a.544.544 0 0 1 0-1.088h.178V.347A.35.35 0 0 1 4.069 0h1.284A.35.35 0 0 1 5.7.347v2.597h.694a.797.797 0 0 1 1.547 0h7.364a.796.796 0 0 1 1.547 0h.604V.347A.35.35 0 0 1 17.803 0h1.284a.35.35 0 0 1 .347.347v2.597h.267a.537.537 0 0 1 .54.539Zm-5.062.544H8.062l.3 2.85 1.979 2.568H12.9l1.978-2.568.3-2.85Z"
      />
    </svg>
  );
};
export default Disciplina55;
