/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-esports-motociclismo.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const DisciplinaEsportsMotociclismo = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-esports-motociclismo', className].join(' ');
  return (
    <svg
      id="disciplina_esports_motociclismo"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M23.16 11.7c1.112-3.372 1.065-6.087-.112-8.127C20.882-.178 14.438 0 14.438 0c-5.81 0-9.481 4.066-9.481 4.066C3.236 5.89 1.496 9.21.577 11.7H23.16ZM7.785 3.68h6.673c.549 0 .91.23.915.68 0 1.088-.798 2.12-2.373 3.053-.779.464-2.584.938-4.178 1.355-.737.192-1.43.375-2.04.558-.99.296-2.068.577-3.208.835 1.107-2.697 2.72-5.187 4.211-6.48Zm10.973 9.037v.009H13.07v-.019h-2.452v.019H5.215v-.01a5.588 5.588 0 0 0-5.192 5.595c0 3.095 2.523 5.599 5.614 5.599a5.58 5.58 0 0 0 4.792-2.697h3.17a5.595 5.595 0 0 0 7.69 1.867 5.595 5.595 0 0 0 1.867-7.69 5.573 5.573 0 0 0-4.399-2.674ZM8.314 19.09h-1.96v1.876H4.886V19.09H2.931v-1.547h1.96v-1.876h1.468v1.876h1.96l-.005 1.547Zm7.56-.014a.953.953 0 1 1 0-1.905.953.953 0 0 1 0 1.905Zm2.508 2.514a.953.953 0 1 1 .002-1.906.953.953 0 0 1-.002 1.906Zm0-5.027a.953.953 0 1 1 .002-1.906.953.953 0 0 1-.002 1.906Zm2.514 2.513a.953.953 0 1 1 0-1.905.953.953 0 0 1 0 1.905Zm-6.8-15.053a.608.608 0 1 0 0 1.22c.338 0 .61-.272.61-.61a.608.608 0 0 0-.61-.61Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default DisciplinaEsportsMotociclismo;
