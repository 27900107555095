import Persist, { StorageKind } from 'lib/persist';
import { isClientSide, isTruthy } from 'utility/functions';

import { getCurrentLocale } from 'hooks/useLingUI';
import { getUseSecondary } from 'lib/mwNodeSwitch';
import { TOKEN_BASE_NAME } from 'utility/constant';

export const TKPersist = Persist(StorageKind.cookie);
export interface iRequest {
  headers?: HeadersInit;
}

export const setHeaders = <T>(r: T & iRequest): T => {
  const headers = r?.headers ?? {};

  if (!Object.hasOwnProperty.call(headers, 'Country')) {
    Reflect.set(headers, 'Country', `${process.env.NEXT_PUBLIC_COUNTRY}`);
  }

  if (isClientSide()) {
    const head = getUseSecondary();
    if (head) {
      Reflect.set(headers, head.name, head.value);
    }

    if (!Object.hasOwnProperty.call(headers, 'Authorization')) {
      const token = TKPersist.getItem(TOKEN_BASE_NAME);
      if (token) {
        Reflect.set(headers, 'Authorization', `Bearer ${token}`);
      }
    }

    if (!Object.hasOwnProperty.call(headers, 'Language')) {
      const lang = getCurrentLocale();
      Reflect.set(headers, 'Language', lang);
    }
  }

  Reflect.set(headers, 'Accept-Encoding', `gzip, deflate, br`);
  Reflect.set(headers, 'Accept', `*/*`);

  Reflect.set(r, 'headers', headers);

  return r;
};

export interface HttpResponseType<D extends unknown, E extends unknown = unknown> extends Partial<Response> {
  ok: boolean;
  data: D;
  error?: E;
}

export const checkResponse = <T, E>(response?: any): HttpResponseType<T, E> => {
  if (!isTruthy(response?.ok)) {
    const error = Reflect.get(response ?? {}, 'error') ?? {};
    error.type = error.type ?? 'generic-error';

    if (response?.status > 499) {
      error.type = 'generic-error';
    }

    Reflect.set(response, 'error', error);
    Reflect.set(response, 'ok', false);
  }

  return response;
};

export const handleException = <T, E>(exc: any): HttpResponseType<T, E> => {
  const error: E = { ...exc, type: 'generic-error' } as unknown as E;

  return { data: undefined, ok: false, error } as HttpResponseType<T, E>;
};
