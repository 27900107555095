/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-383.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco383 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-383', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.526"
      height="38.439"
      viewBox="0 0 32.526 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M68.916,408.308a.739.739,0,0,0-.739.739v1.478h-20.7V383.914h20.7v2.218a.739.739,0,1,0,1.478,0V381.7a3.7,3.7,0,0,0-3.7-3.7H49.7a3.7,3.7,0,0,0-3.7,3.7v31.047a3.7,3.7,0,0,0,3.7,3.7H65.959a3.7,3.7,0,0,0,3.7-3.7v-3.7A.739.739,0,0,0,68.916,408.308ZM47.478,381.7a2.22,2.22,0,0,1,2.218-2.218H65.959a2.22,2.22,0,0,1,2.218,2.218v.739h-20.7Zm20.7,31.047a2.22,2.22,0,0,1-2.218,2.218H49.7a2.22,2.22,0,0,1-2.218-2.218V412h20.7ZM57.088,395h2.957a.739.739,0,0,0,0-1.478H58.567v-.739a.739.739,0,1,0-1.478,0v.739a2.218,2.218,0,0,0,0,4.435h1.478a.739.739,0,1,1,0,1.478H55.61a.739.739,0,1,0,0,1.478h1.478v.739a.739.739,0,0,0,1.478,0v-.739a2.218,2.218,0,0,0,0-4.435H57.088a.739.739,0,1,1,0-1.478Zm7.094,7.342a.738.738,0,0,0,1.025-.2,8.864,8.864,0,0,0,0-9.84.739.739,0,0,0-1.229.821,7.385,7.385,0,0,1,0,8.2A.739.739,0,0,0,64.182,402.344Zm4.92-13.529a.739.739,0,0,0-.2,1.026,13.3,13.3,0,0,1,0,14.758.739.739,0,0,0,1.229.822,14.777,14.777,0,0,0,0-16.4A.741.741,0,0,0,69.1,388.815Zm-6.011,2.17a.738.738,0,0,0-.18-1.029,8.87,8.87,0,1,0,0,14.529.739.739,0,0,0-.849-1.211,7.393,7.393,0,1,1,0-12.108A.738.738,0,0,0,63.091,390.985Zm11.958-5.247a.739.739,0,1,0-1.229.821,19.219,19.219,0,0,1,0,21.321.739.739,0,1,0,1.229.821,20.7,20.7,0,0,0,0-22.963Z"
        transform="translate(-46 -378)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco383;
