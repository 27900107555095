/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-01.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina01 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-01', className].join(' ');
  return (
    <svg
      id="disciplina_live"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M6 14c0 1 .242 1.938.725 2.812A5.791 5.791 0 0 0 8.7 18.925a2.471 2.471 0 0 1-.15-.438 1.897 1.897 0 0 1-.05-.437c0-.466.087-.904.262-1.312.175-.408.43-.78.763-1.113L12 13.2l2.475 2.425c.333.334.588.705.763 1.113.175.408.262.846.262 1.312 0 .15-.017.296-.05.437a2.466 2.466 0 0 1-.15.438 5.791 5.791 0 0 0 1.975-2.113A5.724 5.724 0 0 0 18 14c0-.833-.154-1.62-.462-2.363A6.112 6.112 0 0 0 16.2 9.65a3.985 3.985 0 0 1-2.175.65c-1.033 0-1.929-.341-2.687-1.025a3.926 3.926 0 0 1-1.313-2.55c-.65.55-1.225 1.121-1.725 1.713-.5.592-.92 1.196-1.262 1.812a8.55 8.55 0 0 0-.775 1.875A7.005 7.005 0 0 0 6 14Zm6 1.3-1.425 1.4a1.981 1.981 0 0 0-.425.625c-.1.234-.15.475-.15.725 0 .534.196.992.588 1.375.391.384.862.575 1.412.575.55 0 1.021-.191 1.413-.575.391-.383.587-.841.587-1.375 0-.266-.05-.512-.15-.738a2.028 2.028 0 0 0-.425-.612L12 15.3Zm-.5-11.4v2.4c0 .7.246 1.292.738 1.775a2.454 2.454 0 0 0 1.787.725c.3 0 .592-.058.875-.175.283-.116.542-.283.775-.5l.425-.45a7.15 7.15 0 0 1 2.487 2.687A7.528 7.528 0 0 1 19.5 14c0 2.1-.725 3.875-2.175 5.325C15.875 20.775 14.1 21.5 12 21.5s-3.875-.725-5.325-2.175C5.225 17.875 4.5 16.1 4.5 14c0-1.933.63-3.791 1.888-5.575C7.646 6.642 9.35 5.134 11.5 3.9Z"
      />
    </svg>
  );
};
export default Disciplina01;
