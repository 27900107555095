/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-45.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina45 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-45', className].join(' ');
  return (
    <svg
      id="disciplina_baseball"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M12 3c-4.964 0-9 4.036-9 9s4.036 9 9 9 9-4.036 9-9-4.036-9-9-9Zm-8.28 9a8.24 8.24 0 0 1 1.994-5.379c.255.294.49.606.703.932l-1.167.671.359.622 1.163-.671c.223.428.406.875.549 1.336l-1.294.348.186.696 1.294-.345c.103.471.165.95.186 1.431H6.357v.721h1.336c-.019.482-.081.96-.186 1.431l-1.294-.348-.186.696 1.294.348c-.144.46-.328.907-.549 1.336l-1.163-.671-.359.622 1.164.671a8.091 8.091 0 0 1-.703.932A8.255 8.255 0 0 1 3.72 12ZM12 20.28a8.252 8.252 0 0 1-5.794-2.374c.306-.345.584-.712.833-1.1l1.178.678.359-.622-1.181-.678c.252-.483.46-.988.622-1.509l1.315.352.186-.696-1.315-.352c.12-.531.19-1.072.211-1.617h1.368v-.72H8.414a8.854 8.854 0 0 0-.21-1.618L9.52 9.67l-.186-.696-1.315.352a8.958 8.958 0 0 0-.622-1.509l1.178-.682-.359-.622-1.178.679a9.233 9.233 0 0 0-.833-1.1 8.263 8.263 0 0 1 11.588.003 8.968 8.968 0 0 0-.834 1.1l-1.177-.678-.359.622 1.181.678a8.954 8.954 0 0 0-.622 1.509l-1.315-.352-.186.696 1.318.355a8.854 8.854 0 0 0-.21 1.617h-1.368v.721h1.364c.02.544.091 1.086.21 1.617l-1.317.355.186.697 1.315-.352c.16.52.37 1.025.622 1.508l-1.178.679.359.622 1.178-.679c.25.387.528.755.833 1.1A8.264 8.264 0 0 1 12 20.28Zm6.286-2.901a8.64 8.64 0 0 1-.703-.932l1.164-.671-.36-.623-1.163.672a8.023 8.023 0 0 1-.548-1.336l1.294-.348-.187-.696-1.294.348a8.43 8.43 0 0 1-.186-1.43h1.336v-.722h-1.336c.02-.481.082-.96.186-1.43l1.294.344.186-.696-1.293-.348c.144-.46.327-.907.548-1.336l1.164.671.358-.622-1.163-.671c.211-.327.446-.639.703-.932a8.254 8.254 0 0 1 0 10.758Z"
      />
    </svg>
  );
};
export default Disciplina45;
