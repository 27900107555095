/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-89.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina89 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-89', className].join(' ');
  return (
    <svg
      id="disciplina_bowling"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M12 1C5.925 1 1 5.924 1 12s4.924 11 11 11c6.077 0 11-4.925 11-11 0-6.076-4.924-11-11-11ZM9.276 11.178a1.652 1.652 0 1 1 .004-3.304 1.652 1.652 0 0 1-.004 3.304Zm1.653-5.191a1.936 1.936 0 1 1 3.872 0 1.936 1.936 0 0 1-3.872 0Zm3.591 7.565a1.99 1.99 0 1 1 .001-3.98 1.99 1.99 0 0 1 0 3.98Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina89;
