/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-120.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco120 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-120', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="29.569"
      viewBox="0 0 41.396 29.569"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(4.435 5.914)">
        <path
          className="a"
          d="M51.914,315.392H47.478A1.48,1.48,0,0,1,46,313.914v-4.435A1.48,1.48,0,0,1,47.478,308h4.435a1.48,1.48,0,0,1,1.478,1.478v4.435A1.48,1.48,0,0,1,51.914,315.392Zm-4.435-5.914v4.435h4.437v-4.435Z"
          transform="translate(-46 -308)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(4.435 14.784)">
        <path
          className="a"
          d="M51.175,321.478H46.739a.739.739,0,1,1,0-1.478h4.435a.739.739,0,1,1,0,1.478Z"
          transform="translate(-46 -320)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(11.827 14.784)">
        <path
          className="a"
          d="M61.175,321.478H56.739a.739.739,0,1,1,0-1.478h4.435a.739.739,0,1,1,0,1.478Z"
          transform="translate(-56 -320)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(19.22 14.784)">
        <path
          className="a"
          d="M71.175,321.478H66.739a.739.739,0,1,1,0-1.478h4.435a.739.739,0,1,1,0,1.478Z"
          transform="translate(-66 -320)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(26.612 14.784)">
        <path
          className="a"
          d="M81.175,321.478H76.739a.739.739,0,1,1,0-1.478h4.435a.739.739,0,1,1,0,1.478Z"
          transform="translate(-76 -320)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(4.435 19.22)">
        <path
          className="a"
          d="M52.653,327.479H46.739a.739.739,0,0,1,0-1.478h5.914a.739.739,0,0,1,0,1.478Z"
          transform="translate(-46 -326)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(13.306 19.22)">
        <path
          className="a"
          d="M61.7,327.479H58.739a.739.739,0,0,1,0-1.478H61.7a.739.739,0,0,1,0,1.478Z"
          transform="translate(-58 -326)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M71.786,325.133H43.7a3.7,3.7,0,0,1-3.7-3.7V303.7a3.7,3.7,0,0,1,3.7-3.7h28.09a3.7,3.7,0,0,1,3.7,3.7v17.741A3.7,3.7,0,0,1,71.786,325.133ZM43.7,301.478a2.22,2.22,0,0,0-2.218,2.218v17.741a2.22,2.22,0,0,0,2.218,2.218h28.09A2.22,2.22,0,0,0,74,321.437V303.7a2.22,2.22,0,0,0-2.218-2.218Z"
          transform="translate(-40 -300)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(5.914 4.435)">
        <path
          className="a"
          d="M79.786,331.133H51.7a3.7,3.7,0,0,1-3.7-3.7v-1.478a.739.739,0,0,1,1.478,0v1.478a2.22,2.22,0,0,0,2.218,2.218h28.09A2.22,2.22,0,0,0,82,327.437V309.7a2.22,2.22,0,0,0-2.218-2.218.739.739,0,0,1,0-1.478,3.7,3.7,0,0,1,3.7,3.7v17.741A3.7,3.7,0,0,1,79.786,331.133Z"
          transform="translate(-48 -306)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(23.655 4.435)">
        <path
          className="a"
          d="M75.7,313.392a3.7,3.7,0,1,1,3.7-3.7A3.7,3.7,0,0,1,75.7,313.392Zm0-5.914a2.218,2.218,0,1,0,2.218,2.218A2.22,2.22,0,0,0,75.7,307.478Z"
          transform="translate(-72 -306)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco120;
