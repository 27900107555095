/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\user.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const User = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-user', className].join(' ');
  return (
    <svg id="user" viewBox="0 0 24 24" focusable="false" className={componentClassName} {...attribs}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM4.8 16.8C6.348 19.104 9 20.64 12 20.64C15 20.64 17.652 19.104 19.2 16.8C19.164 14.4 14.4 13.08 12 13.08C9.6 13.08 4.836 14.4 4.8 16.8ZM14.5456 4.65442C13.8705 3.97928 12.9548 3.6 12 3.6C11.0452 3.6 10.1295 3.97928 9.45442 4.65442C8.77928 5.32955 8.4 6.24522 8.4 7.2C8.4 8.15478 8.77928 9.07045 9.45442 9.74558C10.1295 10.4207 11.0452 10.8 12 10.8C12.9548 10.8 13.8705 10.4207 14.5456 9.74558C15.2207 9.07045 15.6 8.15478 15.6 7.2C15.6 6.24522 15.2207 5.32955 14.5456 4.65442Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default User;
