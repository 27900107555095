/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-32.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina32 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-32', className].join(' ');
  return (
    <svg
      id="disciplina_snowboard"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M20.08 4.34a1.944 1.944 0 1 0-2.707-2.79 1.944 1.944 0 0 0 2.708 2.79Zm2.177 16.763a.837.837 0 0 0-1.116-.412c-.841.386-1.734.888-3.06.403l-2.134-.79 1.258-4.649a1.264 1.264 0 0 0-.284-1.176l-2.674-2.958 1.781-1.734.27.875a1.053 1.053 0 0 0 .718.704l3.36.962a1.051 1.051 0 1 0 .58-2.022L18.145 9.5l-.425-1.374a1.671 1.671 0 0 0-.056-2.262l-.498-.51a1.995 1.995 0 0 0-.79-.469l-4.121-1.056a1.049 1.049 0 0 0-1.039.31L8.735 6.841a1.054 1.054 0 1 0 1.554 1.425l2.056-2.236 1.374.352-2.932 2.859c-.49.437-.859.995-.601 1.614l1.159 2.76-5.624 2.92-.06-.022c-1.322-.49-1.674-1.451-2.06-2.297a.842.842 0 0 0-1.529.704c.387.841.979 2.421 3.005 3.172l12.424 4.598c2.026.756 3.507-.064 4.348-.45a.877.877 0 0 0 .408-1.138Zm-8.719-5.8c.161-.085.3-.205.408-.352l.64.708-1.018 3.765-4.653-1.726 4.623-2.395Z"
      />
    </svg>
  );
};
export default Disciplina32;
