/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-co.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneCo = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-co', className].join(' ');
  return (
    <svg
      id="nazione-co"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path d="M0 0h16v16H0V0Z" fill="#FFE800" />
        <path d="M0 8h16v8H0V8Z" fill="#00148E" />
        <path d="M0 12h16v4H0v-4Z" fill="#DA0010" />
      </g>
    </svg>
  );
};
export default NazioneCo;
