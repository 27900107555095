/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-88.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina88 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-88', className].join(' ');
  return (
    <svg
      id="disciplina_pelota"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M1.007 19.96c-.19.487 3.668 1.367 4.858 1.769 1.23.418 1.718 1.225 5.011 1.983 3.294.759 6.735.118 9.715-3.127 3.004-3.185 1.234-6.143.769-6.72-.47-.677-.436-2.717-.379-3.016.01-.243.647-.73.647-.73s.568-2.35.765-3.18c.217-.771 1.761-5.317 1.594-5.836-.153-.488-.492-1.182-1.311-1.073-.82.108-.386-1.26-2.66 6.556-2.917 9.266-4.641 11.306-7.22 13.364-2.58 2.059-11.077-.47-11.077-.47s-.532-.033-.712.48Zm3.28-5.977a2.012 2.012 0 0 1 2.173.484 1.994 1.994 0 0 1-1.497 3.375 2.01 2.01 0 0 1-1.822-1.276 1.99 1.99 0 0 1 1.145-2.583Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina88;
