import { AnyAction, Dispatch, MiddlewareAPI, ThunkAction } from '@reduxjs/toolkit';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { MAX_CONNECTION_ATTEMPTS, decodePathname, nextDelay, selectCurrentPath } from 'features/sport';
import { setSignalRConnection, signalRs } from 'features/signalR/signalRSlice';

import { CustomHttpClient } from '../CustomHttpClient';
import { LocationEventHandler } from './types';
import { RootState } from 'lib/centralStore';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsight } from 'components/appInsight/AppInsight';
import { feedLingUI } from 'hooks/useLingUI';
import { isTruthy } from 'utility/functions';
import { liveNavSignalRListeners } from '../../live/liveNav/liveNavSlice';
import { selectIsOnline } from 'features/location/selectors';
import { selectSignalRConnection } from '../selectors';
import { selectSlugs } from '../../live/liveNav/selectors';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { SportsSignalRPacketDto } from 'types/swagger';
import { selectSportPacketMessage } from 'features/configuration/selectors';

export interface LiveNavEventHandler extends LocationEventHandler {
  // eslint-disable-next-line no-unused-vars
  onSubscribeHandler: (groupName?: string) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  onUnsubscribeHandler: (groupName?: string) => Promise<void>;
}

export const liveNavConnectionHubManager = (store: MiddlewareAPI<Dispatch<AnyAction>, any>): LiveNavEventHandler => {
  const key = signalRs.liveNav;

  const registerListeners = (connection: HubConnection) => {
    const isPacketMessageOn = selectSportPacketMessage(store.getState() as RootState);

    const dispatchMessage = (event, action?: AnyAction | Function, type?: string) => {
      const { traduzioneMap, ...payload } = event ?? {};
      feedLingUI(traduzioneMap);

      if (typeof action === 'function') {
        store.dispatch(action(payload));
      } else if (type) {
        store.dispatch({ type, payload });
      }
    };

    if (isPacketMessageOn) {
      connection.on('PacketMessage', (_a: boolean, _b: string, { data }: SportsSignalRPacketDto) => {
        if (isTruthy(data?.length)) {
          data?.forEach(({ message, event }) => {
            const { action, actionType } = liveNavSignalRListeners.find((x) => x.eventName === message) ?? {};

            if (action || actionType) {
              dispatchMessage(event, action, actionType);
            } else {
              const msg = `[liveNavConnectionHubManager] - PacketMessage: no action defined for "${message}" event`;
              appInsight.trackTrace({
                message: msg,
                severityLevel: SeverityLevel.Information,
              });
              console.log(msg);
            }
          });
        } else {
          const msg = `[liveNavConnectionHubManager] - PacketMessage: empty payload data`;
          appInsight.trackTrace({
            message: msg,
            severityLevel: SeverityLevel.Information,
          });
          console.log(msg);
        }
      });
    } else {
      (liveNavSignalRListeners ?? []).forEach(({ eventName, action, actionType }) => {
        connection.on(eventName, (_a: boolean, _b: string, data: any) => {
          if (action || actionType) {
            dispatchMessage(data, action, actionType);
          } else {
            const msg = `[liveNavConnectionHubManager]: no action defined for "${eventName}" event`;
            appInsight.trackTrace({
              message: msg,
              severityLevel: SeverityLevel.Information,
            });
            console.log(msg);
          }
        });
      });
    }
  };

  const doSubscribe = async (hub: HubConnection, groups?: string[]): Promise<void> => {
    for (let groupName of groups ?? []) {
      if (!isTruthy(groupName?.length)) continue;

      let success = false;

      for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && !success; x++) {
        try {
          await hub.invoke('SubscribeToGroup', groupName);
          success = true;
          appInsight.trackTrace({
            message: `[liveNavConnectionHubManager] Successfully Subscribed To ${groupName}`,
            severityLevel: SeverityLevel.Information,
          });
        } catch (exception) {
          appInsight.trackTrace({
            message: `[liveNavConnectionHubManager] Failure Subscribing To ${groupName} - attempt n. ${x + 1}`,
            severityLevel: SeverityLevel.Error,
          });
        }

        await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
      }

      if (!success) {
        appInsight.trackTrace({
          message: `[liveNavConnectionHubManager] unable to Subscribe to ${groupName}`,
          severityLevel: SeverityLevel.Critical,
        });
      }
    }

    return Promise.resolve();
  };

  const doUnsubscribe = async (hub: HubConnection, groupName?: string): Promise<void> => {
    if (!groupName) return Promise.resolve();
    if ((hub?.state || HubConnectionState.Disconnected) !== HubConnectionState.Connected) {
      appInsight.trackTrace({
        message: `[liveNavConnectionHubManager] unable to Unsubscribe From ${groupName} since state is ${hub?.state}`,
        severityLevel: SeverityLevel.Information,
      });

      return Promise.resolve();
    }

    let success = false;

    for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && !success; x++) {
      try {
        await hub.invoke('UnsubscribeFromGroup', groupName);
        success = true;
        appInsight.trackTrace({
          message: `[liveNavConnectionHubManager] Successfully Unsubscribed From ${groupName}`,
          severityLevel: SeverityLevel.Information,
        });
      } catch (exception) {
        appInsight.trackTrace({
          message: `[liveNavConnectionHubManager] Failure Unsubscribing from ${groupName} - attempt n. ${x + 1}`,
          severityLevel: SeverityLevel.Error,
        });
      }

      await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
    }

    if (!success) {
      appInsight.trackTrace({
        message: `[liveNavConnectionHubManager] unable to Unsubscribing from ${groupName}`,
        severityLevel: SeverityLevel.Critical,
      });
    }

    return Promise.resolve();
  };

  const buildConnection = (): HubConnection => {
    // TODO: change the url with signalR for side menu (when available)
    const isPacketMessageOn = selectSportPacketMessage(store.getState() as RootState);

    const connectionBuilder = new HubConnectionBuilder().withUrl(
      `${process.env.NEXT_PUBLIC_API_BASE_URL}/signalr-sports-${process.env.NEXT_PUBLIC_COUNTRY}/sigr-hubs-${process.env.NEXT_PUBLIC_COUNTRY}/eventi-sport`,
      { httpClient: new CustomHttpClient() }
    );

    if (isPacketMessageOn) {
      connectionBuilder.withHubProtocol(new MessagePackHubProtocol());
    }

    const connection = connectionBuilder.build();

    connection.onclose(() => {
      appInsight.trackTrace({
        message: `[liveNavConnectionHubManager] signalR closed`,
        severityLevel: SeverityLevel.Information,
      });

      store.dispatch(setSignalRConnection({ key }));
      const slugs: string[] = selectSlugs(store.getState());
      ensureConnected(undefined, slugs);
    });

    connection.onreconnected(async () => {
      // just in case of restoring withAutomaticReconnect option
      appInsight.trackTrace({
        message: `[liveNavConnectionHubManager] signalR reconnected`,
        severityLevel: SeverityLevel.Information,
      });

      const slugs: string[] = selectSlugs(store.getState());
      doSubscribe(connection, slugs);
    });

    registerListeners(connection);

    store.dispatch(setSignalRConnection({ key, connection }));

    return connection;
  };

  const ensureConnected = async (hub?: HubConnection, groups?: string[]): Promise<HubConnection> => {
    const connection = hub ?? buildConnection();

    let success = true;

    for (let x = 0; x < MAX_CONNECTION_ATTEMPTS && connection.state !== HubConnectionState.Connected; x++) {
      success = false;
      if (connection.state === HubConnectionState.Disconnected) {
        try {
          await connection.start();
          doSubscribe(connection, groups);
          success = true;
        } catch (e) {
          appInsight.trackTrace({
            message: `[liveNavConnectionHubManager] unable to Start signalR, state is ${
              connection.state
            } - attempt n. ${x + 1}`,
            severityLevel: SeverityLevel.Error,
          });
        }
      }
      await new Promise((resolve) => setTimeout(resolve, nextDelay(x)));
    }

    if (!success) {
      appInsight.trackTrace({
        message: `[liveNavConnectionHubManager] unable to Start signalR`,
        severityLevel: SeverityLevel.Critical,
      });
    }

    return Promise.resolve(connection);
  };

  const onSubscribeHandler = async (groupName?: string): Promise<void> => {
    if (!groupName) return Promise.reject();

    appInsight.trackTrace({
      message: `[liveNavConnectionHubManager] expanding menu ${groupName}`,
      severityLevel: SeverityLevel.Information,
    });

    const state = store.getState() as RootState;
    const connectionSignalR = selectSignalRConnection(state)(key);
    doSubscribe(connectionSignalR, [groupName]);

    return Promise.resolve();
  };

  const onUnsubscribeHandler = async (groupName?: string): Promise<void> => {
    if (!groupName) return Promise.reject();
    const state = store.getState();

    appInsight.trackTrace({
      message: `[liveNavConnectionHubManager] collapsing menu ${groupName}`,
      severityLevel: SeverityLevel.Information,
    });

    const connectionSignalR = selectSignalRConnection(state)(key);
    doUnsubscribe(connectionSignalR, groupName);

    return Promise.resolve();
  };

  const onLocationEventHandler = async (nextPath: string): Promise<void> => {
    const state = store.getState();

    const path = selectCurrentPath(state);
    const currentPathname = decodePathname(path) ?? '';
    const nextPathname = decodePathname(nextPath) ?? '';

    const currentHasSideMenu = (currentPathname.match(/^live/gim) ?? []).length > 0;
    const currentHasTopMenu = currentHasSideMenu || (currentPathname.match(/^sport/gim) ?? []).length > 0;

    const nextHasSideMenu = (nextPathname.match(/^live/gim) ?? []).length > 0;
    const nextHasTopMenu = nextHasSideMenu || (nextPathname.match(/^sport/gim) ?? []).length > 0;

    const groups = selectSlugs(state);
    const connectionSignalR = selectSignalRConnection(state)(key);

    if (nextHasTopMenu && currentHasTopMenu) {
      // console.log('onLocationEventHandler', `'${path}'`, `'${nextPath}'`, 'ensure' )
      // when navigating between sport pages (/sport and /live) - ensure subsbribed
      await ensureConnected(connectionSignalR, groups);
    } else if (nextHasTopMenu) {
      // console.log('onLocationEventHandler', `'${path}'`, `'${nextPath}'`, 'subscribe' )
      // entering on sport page (/sport and /live) from other paths - do register
      const hub = await ensureConnected(connectionSignalR, []);
      doSubscribe(hub, groups);
    } else if (currentHasTopMenu) {
      // console.log('onLocationEventHandler', `'${path}'`, `'${nextPath}'`, 'unsubscribe' )
      // when navigating out of sport pages (/sport and /live) - unsubscribe from all
      for (let groupName of groups) {
        doUnsubscribe(connectionSignalR, groupName);
      }
      // } else {
      //  console.log('onLocationEventHandler', `'${path}'`, `'${nextPath}'`, 'nothing' )
    }

    return Promise.resolve();
  };

  const onIsOnlineEventHandler = async (isOnline: boolean): Promise<void> => {
    const state = store.getState();
    const prevOnline = selectIsOnline(state);
    if (isOnline === true && prevOnline === false) {
      const slugs = selectSlugs(state);

      const connectionSignalR = selectSignalRConnection(state)(key);
      await ensureConnected(connectionSignalR, slugs);
    }
    return Promise.resolve();
  };

  return {
    onSubscribeHandler,
    onUnsubscribeHandler,
    onLocationEventHandler,
    onIsOnlineEventHandler,
  };
};
