import { AnyAction, ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createReducer, localEnhancer } from 'lib/centralStore';

import { apiSlice } from 'features/api/apiSlice';
import cardsGameArchiveReducer from 'features/cardsGamesArchive/cardsGamesArchiveSlice';
import casinoLiveReducer from 'features/casinoLiveArchive/casinoLiveArchiveSlice';
import { externalApi } from 'features/api/externalApiSlice';
import { ippicaMiddleware } from 'features/ippica/ippicaMiddleware';
import ippicaReducer from 'features/ippica/ippicaSlice';
import { liveNavMiddleware } from 'features/live/liveNav/liveNavMiddleware';
import { locationMiddleware } from 'features/location/locationMiddleware';
import { promoEngineApiSlice } from 'features/api/promoEngineApiSlice';
import { rememberReducer } from 'redux-remember';
import { serverSideApi } from 'features/api/serverSideApiSlice';
import signupReducer from 'features/signup/signup';
import geoComplyReducer from 'features/geoComply/geoComplySlice';
import slotArchiveReducer from 'features/slotArchive/slotArchiveSlice';
import snaiPlusReducer from 'features/dashboard/snaiPlus/snaiPlusSlice';
import { sportTicketMiddleware } from 'features/sport/components/sportTicket/sportTicketMiddleware';
import virtualReducer from 'features/virtual/virtualSlice';

export const rootReducer = createReducer({
  ippica: ippicaReducer,
  signup: signupReducer,
  virtual: virtualReducer,
  snaiPlus: snaiPlusReducer,
  geoComply: geoComplyReducer,
  casinoLive: casinoLiveReducer,
  archiveSlot: slotArchiveReducer,
  archiveCardGame: cardsGameArchiveReducer,
  [promoEngineApiSlice.reducerPath]: promoEngineApiSlice.reducer,
});

const createStore = () =>
  configureStore({
    reducer: rememberReducer(rootReducer), //as typeof rootReducer,
    // reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [localEnhancer],
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        // { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] },
      })
        .concat(apiSlice.middleware)
        .concat(externalApi.middleware)
        .concat(serverSideApi.middleware)
        .concat(promoEngineApiSlice.middleware)
        .concat(sportTicketMiddleware)
        .concat(locationMiddleware)
        .concat(liveNavMiddleware)
        .concat(ippicaMiddleware),
  });

export const store = createStore();
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
