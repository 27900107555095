/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\arrow-up.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const ArrowUp = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-arrow-up', className].join(' ');
  return (
    <svg
      id="arrow-up"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g id="icon-24 / generic / arrow-up-thin">
        <path
          id="Vector"
          d="M4.66602 6.66536H7.33268V12.612L8.67268 12.632V6.66536H11.3327L7.99935 3.33203L4.66602 6.66536Z"
          fill="#55D470"
        />
      </g>
    </svg>
  );
};
export default ArrowUp;
