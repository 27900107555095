/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-59.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco59 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-59', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="38.439"
      viewBox="0 0 38.439 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M483.012,401.061l2.152-1.345a4.593,4.593,0,0,0,2.229-4.021,3.7,3.7,0,0,0-7.392,0,.739.739,0,1,0,1.478,0,2.218,2.218,0,0,1,4.435,0,3.161,3.161,0,0,1-1.533,2.766l-2.153,1.346A4.774,4.774,0,0,0,480,403.827v.739a.738.738,0,0,0,.739.739h5.914a.739.739,0,1,0,0-1.478h-5.175A3.287,3.287,0,0,1,483.012,401.061Zm12.512-.191h-.739v-8.131a.739.739,0,0,0-1.354-.41l-5.914,8.871a.739.739,0,0,0,.615,1.149h5.175v2.218a.739.739,0,1,0,1.478,0v-2.218h.739a.739.739,0,1,0,0-1.478Zm-2.218,0h-3.794l3.794-5.69Z"
        transform="translate(-466.694 -381.651)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M490.171,378.58a.741.741,0,0,0-.906.523l-.381,1.428a.74.74,0,0,0,.523.906.752.752,0,0,0,.192.025.74.74,0,0,0,.713-.548l.381-1.427A.739.739,0,0,0,490.171,378.58Zm4.1,1.7a.738.738,0,0,0-1.01.271l-1.478,2.561a.739.739,0,0,0,1.28.739l1.478-2.561A.739.739,0,0,0,494.272,380.278Zm1.954,5.009a.739.739,0,0,0,.523-.216l1.045-1.046a.739.739,0,0,0-1.045-1.045l-1.046,1.045a.739.739,0,0,0,.523,1.262Zm.429,3.431a.739.739,0,0,0,1.01.271l2.56-1.478a.739.739,0,0,0-.739-1.28l-2.56,1.478A.738.738,0,0,0,496.655,388.718Zm2.683,2.649a.74.74,0,0,0,.713.548.718.718,0,0,0,.192-.025l1.427-.382a.739.739,0,0,0-.383-1.428l-1.427.382A.74.74,0,0,0,499.338,391.367Zm2.7,2.9h-2.956a.739.739,0,1,0,0,1.478h2.956a.739.739,0,1,0,0-1.478Zm-.363,4.235-1.427-.383a.739.739,0,0,0-.383,1.428l1.427.382a.718.718,0,0,0,.192.025.739.739,0,0,0,.191-1.453Zm-27.054-10.79-2.56-1.478a.739.739,0,0,0-.739,1.28l2.56,1.478a.739.739,0,1,0,.739-1.28Zm25.608,14.785-2.56-1.478a.739.739,0,1,0-.739,1.28l2.56,1.478a.733.733,0,0,0,.369.1.739.739,0,0,0,.37-1.379Zm-25.431-19.514a.739.739,0,0,0-1.045,1.045l1.045,1.046a.739.739,0,0,0,1.045-1.046Zm21.954,21.955a.739.739,0,0,0-1.046,1.045l1.046,1.045a.739.739,0,0,0,1.045-1.045ZM478.28,380.548a.739.739,0,0,0-1.28.739l1.478,2.561a.739.739,0,1,0,1.28-.739Zm14.784,25.608a.739.739,0,0,0-1.28.739l1.478,2.561a.739.739,0,0,0,1.28-.739ZM482.276,379.1a.739.739,0,1,0-1.428.383l.382,1.427a.739.739,0,0,0,.713.548.752.752,0,0,0,.192-.025.74.74,0,0,0,.523-.906Zm3.495-1.1a.738.738,0,0,0-.739.739V381.7a.739.739,0,1,0,1.478,0v-2.957A.739.739,0,0,0,485.771,378Z"
        transform="translate(-464.333 -378)"
        fill="currentColor"
      />
      <g transform="translate(0 11.827)">
        <path
          className="a"
          d="M481.833,420.612c-3.776,0-8.723-2.436-13.06-6.773-6.316-6.316-8.6-13.927-5.2-17.327a23.65,23.65,0,0,1,2.7-2.153,1.953,1.953,0,0,1,2.781.554L472.7,400.7a1.955,1.955,0,0,1-.648,2.719l-2.137,1.282a.488.488,0,0,0-.227.317.46.46,0,0,0,.073.362,36.874,36.874,0,0,0,7.477,7.477.462.462,0,0,0,.361.073.489.489,0,0,0,.317-.226l1.282-2.138a1.955,1.955,0,0,1,2.719-.648l5.784,3.646a1.953,1.953,0,0,1,.553,2.782,23.716,23.716,0,0,1-2.153,2.7A5.84,5.84,0,0,1,481.833,420.612ZM467.4,395.478a.473.473,0,0,0-.273.087,21.652,21.652,0,0,0-2.507,1.991c-2.72,2.721-.339,9.7,5.2,15.238s12.518,7.921,15.238,5.2a22.4,22.4,0,0,0,1.991-2.507.466.466,0,0,0,.079-.366.473.473,0,0,0-.214-.311l-5.784-3.646a.463.463,0,0,0-.364-.06.469.469,0,0,0-.3.219l-1.283,2.137a1.972,1.972,0,0,1-1.279.912,1.942,1.942,0,0,1-1.52-.312,38.3,38.3,0,0,1-7.831-7.831,1.947,1.947,0,0,1-.312-1.52,1.97,1.97,0,0,1,.913-1.279l2.136-1.283a.478.478,0,0,0,.159-.663L467.8,395.7a.473.473,0,0,0-.311-.214A.5.5,0,0,0,467.4,395.478Z"
          transform="translate(-462 -394)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco59;
