/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-87.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina87 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-87', className].join(' ');
  return (
    <svg
      id="disciplina_pesapallo"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M23.979 3.188c-.024-.099-.268-.988-1.223-1.943C21.801.29 20.912.047 20.812.021a.685.685 0 0 0-.65.18l-8.94 8.94a.69.69 0 0 0-.063.072l-5.23 6.941a.685.685 0 0 0-.133.338L1.277 21.01a1.12 1.12 0 0 0-.95 1.899l.763.763a1.117 1.117 0 0 0 1.9-.95l4.477-4.478h.003c.15 0 .296-.05.416-.14l6.904-5.266a.65.65 0 0 0 .07-.06l8.939-8.94a.685.685 0 0 0 .18-.65ZM2.109 23.107a.32.32 0 0 1-.452 0l-.764-.763a.32.32 0 0 1 .452-.453l.764.764a.319.319 0 0 1 0 .452Zm.618-1.11-.725-.723.648-.649.725.724-.648.649Zm1.142-1.142-.724-.724.766-.766.724.724-.766.766Zm1.26-1.26-.723-.724.67-.67.723.725-.67.668Zm1.163-1.164-.724-.724.54-.54.724.724-.54.54ZM5.668 7.054a2.535 2.535 0 0 0 0-3.75.245.245 0 0 1-.026-.028 2.775 2.775 0 0 0 0 3.808.3.3 0 0 1 .026-.03ZM6.984 5.18a3.03 3.03 0 0 1-.981 2.227 2.778 2.778 0 0 0 3.32 0 3.02 3.02 0 0 1 0-4.453 2.78 2.78 0 0 0-3.32 0 3.03 3.03 0 0 1 .981 2.226Zm2.672 1.875a.25.25 0 0 1 .026.029 2.775 2.775 0 0 0 0-3.809.297.297 0 0 1-.026.029 2.535 2.535 0 0 0 0 3.75v.001Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina87;
