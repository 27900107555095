import { FIRTS_LOGIN_COOKIE } from 'utility/constant';
import { isClientSide } from 'utility/functions';

export const getCookie = (name: string): string | null => {
  const nameLenPlus = name.length + 1;
  return typeof document !== 'undefined'
    ? document.cookie
        .split(';')
        .map((c) => c.trim())
        .filter((cookie) => cookie.substring(0, nameLenPlus) === `${name}=`)
        .map((cookie) => decodeURIComponent(cookie.substring(nameLenPlus)))[0] || null
    : null;
};

export function setCookie(name: string, value: string, expireInDays: number, isSecure = false, saveExpireDate = true) {
  if (typeof document === 'undefined') return;
  const options: Array<string> = [`${name}=${value}`];
  if (saveExpireDate) {
    const d = new Date();
    d.setTime(d.getTime() + expireInDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()};`;
    options.push(expires);
    // } else {
    //   options.push('expires=Session')
  }
  options.push('path=/');
  if (isSecure) {
    options.push('Secure');
  }
  document.cookie = options.join(';');
}

export const deleteCookie = (name: string, isSecure = false) => {
  document.cookie = `${name}=;Max-Age=-99999999;path=/${isSecure ? ';Secure' : ''}`;
};

export function eraseCookie(name) {
  setCookie(name, '', -1);
}

export const cookieReset = () => {
  if (!isClientSide()) return;

  const list = document.cookie
    .split(';')
    .map((x) => `${x}`.split('=')[0].trim())
    .filter((x) => x !== FIRTS_LOGIN_COOKIE);
  const isDirthy = list.some((x) => x.startsWith('session_token_'));

  if (isDirthy) {
    list.forEach((key) => eraseCookie(key));
    document.location.reload();
  }
};
