import {
  SportsAvvenimentoEsposto,
  SportsCacheScommesseCoreDto,
  SportsEsito,
  SportsInfoAggiuntivaDto,
  SportsInfoAggiuntivaMapDto,
  SportsInfoTipoScommessa,
  SportsInfoTipoScommessaGroupDto,
  SportsScommessaMapDto,
} from 'types/swagger';

import { ApiStatusType } from 'features/api/thunkUtils';

/* eslint-disable no-unused-vars */
export enum SizeInfoAggiuntiva {
  Large = 'large',
  Small = 'small',
}

export enum VisualizationEnum {
  EsitiUnderThree = 1,
  EsitiOverThree = 2,
  Antepost = 3,
  SingleInfoAggEsitiUnderThree = 4,
  SingleInfoAggEsitiOverThree = 5,
  MultipleInfoAggEsitiUnderThree = 6,
  RecursiveInfoAgg = 7,
}
/* eslint-enable no-unused-vars */

/**
 * Opaque/Tagged types utilities
 */
declare const tag: unique symbol;

export type TagContainer<Token> = {
  readonly [tag]: Token;
};

export type Opaque<Type, Token = unknown> = Type & TagContainer<Token>;

export type UnwrapOpaque<OpaqueType extends TagContainer<unknown>> = OpaqueType extends Opaque<
  infer Type,
  OpaqueType[typeof tag]
>
  ? Type
  : OpaqueType;

export type KeyAvvenimento = Opaque<string, 'KeyAvvenimento'>;

export type KeyScommessa = Opaque<string, 'KeyScommessa'>;

export type KeyTipo = Opaque<string, 'KeyTipo'>;

export type KeyEsito = Opaque<`${string}-${string}-${string}-${string}-${string}`, 'KeyEsito'>;

export type KeyInfoAgg = Opaque<string, 'KeyInfoAgg'>;

export type Scommessa = SportsScommessaMapDto & {
  isClosed: boolean;
};

export type ScommessaMap = {
  [key: string]: Scommessa;
};

// TODO : MW : aggiornare tipi
export interface Esito extends Omit<SportsEsito, 'idEsito' | 'idAvvenimento' | 'idTipoScommessa'> {
  idEsito: number;
  idAvvenimento: number;
  idTipoScommessa: number;
}

export type EsitoMap = {
  [key: string]: Esito;
};

export type InfoTipoScommessaMap = {
  [key: string]: InfoTipoScommessa;
};

export type InfoTipoScommessaGroupMap = {
  [key: string]: SportsInfoTipoScommessaGroupDto;
};

export type AvvenimentoList = Array<SportsAvvenimentoEsposto>;

export type ScommessaList = {
  key: string;
  filtro: ScommessaListFiltro;
  priorita: number;
  descrizione: string;
  idProgramma: number;
  idAvvenimento: number;
  idTipoScommessa: number;
};

export type ScommessaListFiltro = {
  quotaMin: number;
  quotaMax: number;
};

export type InfoAggiuntivaList = Array<SportsInfoAggiuntivaDto>;

export interface InfoAggiuntiva extends Omit<SportsInfoAggiuntivaMapDto, 'sizeInfoAggiuntive'> {
  sizeInfoAggiuntive: Array<SizeInfoAggiuntiva>;
}

export type InfoAggiuntivaMap = Record<string, InfoAggiuntiva>;

export interface InfoTipoScommessa extends Omit<SportsInfoTipoScommessa, 'sizeInfoAggiuntive' | 'tipoVisualizzazione'> {
  sizeInfoAggiuntive: Array<SizeInfoAggiuntiva>;
  tipoVisualizzazione: VisualizationEnum;
  defaultNextInfoAggIndex?: number;
}

export interface ScommessaResponse
  extends Omit<SportsCacheScommesseCoreDto, 'esitoMap' | 'scommessaMap' | 'avvenimentoList' | 'infoAggiuntivaMap'> {
  esitoMap: EsitoMap;
  scommessaMap: ScommessaMap;
  avvenimentoList: AvvenimentoList;
  infoAggiuntivaMap: InfoAggiuntivaMap;
  infoTipoScommessaMap: InfoTipoScommessaMap;
  infoTipoScommessaGroupMap: InfoTipoScommessaGroupMap;
}

export type ScommessaResponsePayload = {
  data?: ScommessaResponse;
  status?: ApiStatusType;
};
