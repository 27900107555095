/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\link-external.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const LinkExternal = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-link-external', className].join(' ');
  return (
    <svg
      width="24"
      id="link-external"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M5 5V19H19V12L21 14V19C21 20.103 20.103 21 19 21H5C3.897 21 3 20.103 3 19V5C3 3.897 3.897 3 5 3H10L12 5H5Z"
        fill="currentColor"
      />
      <path d="M16.293 6.293L13 3H21V11L17.707 7.707L10.707 14.707L9.29297 13.293L16.293 6.293Z" fill="currentColor" />
    </svg>
  );
};
export default LinkExternal;
