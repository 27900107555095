/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\quadri.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Quadri = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-quadri', className].join(' ');
  return (
    <svg
      id="quadri"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M11.9919 2.39846c.0023-.00004.0046-.00003.0069.00002.0803.00198.1436.0619.1414.13384-.0285.88692.1595 1.86989.5591 2.87775.3859.97345.9686 1.96827 1.7436 2.92059.6885.84598 1.4655 1.58066 2.2832 2.18174.8372.6154 1.7162 1.09 2.5859 1.3999.0746.0265.1111.1022.0815.169-.0157.0355-.0469.0614-.0839.0739l.0001.0002c-.8168.276-1.6634.7245-2.5081 1.3595-.7955.5981-1.588 1.3602-2.3509 2.2976-.7405.9099-1.3054 1.8586-1.691 2.7906-.3994.9656-.6056 1.9108-.6144 2.7738-.0006.0719-.0662.1298-.1465.1292-.0023 0-.0046-.0001-.0069-.0002-.0022.0001-.0045.0002-.0069.0002-.0802.0006-.1458-.0573-.1465-.1292-.0087-.863-.2149-1.8082-.6144-2.7738-.3855-.932-.9504-1.8807-1.69088-2.7906-.76296-.9374-1.55541-1.6995-2.35097-2.2976-.84469-.635-1.69125-1.0835-2.50811-1.3595l.00009-.0002c-.03697-.0125-.06816-.0384-.08388-.0739-.02959-.0668.00691-.1425.08151-.169.86967-.3099 1.74871-.7845 2.58587-1.3999.81777-.60108 1.59471-1.33576 2.28323-2.18174.77504-.95232 1.35774-1.94715 1.74364-2.92059.3996-1.00787.5875-1.99083.5591-2.87775-.0022-.07194.0611-.13186.1414-.13384.0023-.00005.0046-.00006.0068-.00002Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Quadri;
