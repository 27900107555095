import { KeyManager } from 'utility/keyManager';

export class KeyManagerSport extends KeyManager {
  private _infoAggiuntivaId: string;

  get infoAggiuntivaId(): string {
    return this._infoAggiuntivaId;
  }

  public infoAggiuntivaKey: string;

  constructor(key: string) {
    super();
    this.generateKeys(key);
    this.generateIds();
  }

  private generateKeys(key: string) {
    const { programmaId, avvenimentoId, tipoScommessaId, infoAggiuntivaId, esitoId } =
      this.retrieveInformationByKey(key);

    this._programmaId = programmaId;
    this._avvenimentoId = avvenimentoId;
    this._tipoScommessaId = tipoScommessaId;
    this._infoAggiuntivaId = infoAggiuntivaId;
    this._esitoId = esitoId;
  }

  private retrieveInformationByKey(key: string) {
    const [programmaId, avvenimentoId, tipoScommessaId, infoAggiuntivaId, esitoId] = key?.split(this._separator) ?? [];
    return {
      programmaId,
      avvenimentoId,
      tipoScommessaId,
      infoAggiuntivaId,
      esitoId,
    };
  }

  private generateIds() {
    this.avvenimentoKey = this.concatId([this.programmaId, this._avvenimentoId]);
    this.scommessaKey = this.concatId([this.programmaId, this._avvenimentoId, this._tipoScommessaId]);
    this.infoAggiuntivaKey = this.concatId([
      this.programmaId,
      this._avvenimentoId,
      this._tipoScommessaId,
      this._infoAggiuntivaId,
    ]);
    this.esitoKey = this.concatId([
      this.programmaId,
      this._avvenimentoId,
      this._tipoScommessaId,
      this._infoAggiuntivaId,
      this._esitoId,
    ]);
  }
}
