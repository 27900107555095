/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-esports-starcraft-2.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const DisciplinaEsportsStarcraft2 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-esports-starcraft-2', className].join(' ');
  return (
    <svg
      id="disciplina_esports-starcraft-2"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M1 18.516C2.044 17.203 3.004 16 3.978 14.779c.289.255.523.626.824.692.511.11 1.064.03 1.721.03L2.038 9.496c.952 0 1.789-.003 2.626 0 .615.004 1.324-.167 1.82.081.498.248.792.916 1.164 1.406.942 1.245 1.877 2.496 2.818 3.741.42.555.657 1.165.547 1.867-.19 1.205-1.033 1.91-2.318 1.919-2.047.013-4.094.004-6.14.004L1 18.516ZM16.088 9.5c-1.04-.004-2.079-.015-3.118.01-.14.003-.348.17-.4.308-.525 1.396-.72 2.835-.439 4.312.568 2.988 3.338 4.872 6.192 4.223 1.31-.298 2.351-1.066 3.476-1.95-.6.065-1.067.111-1.534.166-2.109.247-3.506-.707-4.009-2.748-.07-.282-.14-.577-.14-.866 0-1.131.028-2.263.047-3.44.045.013-.016-.015-.075-.015Zm3.969-6.987c.2-.09.42-.415.411-.623-.009-.209-.245-.52-.453-.6-1.119-.43-2.26-.803-3.393-1.194a1.613 1.613 0 0 0-1.319.098c-.791.412-1.586.818-2.356 1.266-.187.108-.391.366-.4.562-.04.97-.018 1.942-.018 2.94h5.512c.27-1.182.939-1.972 2.016-2.45Zm-8.55-.444c-.009-.202-.191-.478-.371-.58-.845-.48-1.71-.923-2.584-1.35-.206-.1-.495-.174-.7-.11-1.313.41-2.616.849-3.91 1.317-.176.064-.362.362-.368.557-.006.196.16.516.33.583 1.14.466 1.836 1.29 2.112 2.475h5.508c0-.983.021-1.939-.018-2.892Zm-.344 3.938c-2.338 0-4.675-.008-7.016.002-1.056.005-1.889.613-2.042 1.529-.075.454-.013.93-.013 1.456h.526c1.608 0 3.216.004 4.824 0 .785 0 1.62-.172 2.087.808l3.028-3.794-1.394-.001Zm11.527 0c-2.466 0-4.931-.014-7.396.011a1.54 1.54 0 0 0-.883.323c-.836.672-1.225 1.635-1.632 2.652 1.855 0 3.64.028 5.42-.013.718-.017 1.346.04 1.784.684.014.02.058.02.114.036L23 6.054c-.16-.026-.235-.048-.31-.048v.002ZM12.55 17.245V24c1.68-.686 3.213-1.507 4.479-2.758.32-.316.59-.691.833-1.07.117-.182.119-.438.187-.73-2.305.295-4.051-.505-5.5-2.197Zm-6.347 2.953c.278.398.57.797.917 1.13 1.163 1.12 2.551 1.889 4.025 2.512.114.042.23.078.348.108V18.1c-.816 1.125-1.878 1.47-3.123 1.425-.76-.028-1.52-.005-2.378-.005.08.274.094.512.21.678Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default DisciplinaEsportsStarcraft2;
