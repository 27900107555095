/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-63.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco63 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-63', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.439"
      height="32.526"
      viewBox="0 0 38.439 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M410.612,402a2.937,2.937,0,0,0-2.218,1.022,2.907,2.907,0,0,0-3.758-.577.739.739,0,0,0-1.416.294v5.914a.739.739,0,1,0,1.478,0v-3.7a1.478,1.478,0,1,1,2.957,0v3.7a.739.739,0,1,0,1.478,0v-3.7a1.478,1.478,0,1,1,2.957,0v3.7a.739.739,0,1,0,1.478,0v-3.7A2.96,2.96,0,0,0,410.612,402Zm-12.567,0a3.7,3.7,0,1,0,3.7,3.7A3.7,3.7,0,0,0,398.045,402Zm0,5.914a2.218,2.218,0,1,1,2.218-2.218A2.22,2.22,0,0,1,398.045,407.914Zm-5.824-3.785a.739.739,0,0,0,1.045-1.045,3.7,3.7,0,1,0,0,5.226.739.739,0,1,0-1.045-1.045,2.271,2.271,0,0,1-3.136,0,2.217,2.217,0,0,1,0-3.136A2.271,2.271,0,0,1,392.221,404.128Zm-7.112,3.046a1.109,1.109,0,1,0,1.109,1.109A1.11,1.11,0,0,0,385.109,407.174Z"
          transform="translate(-379.565 -387.216)"
          fill="currentColor"
        />
        <g transform="translate(2.957 2.956)">
          <path
            className="a"
            d="M382.739,387.478a.737.737,0,0,1-.281-.059.854.854,0,0,1-.244-.155,1.124,1.124,0,0,1-.163-.244.728.728,0,0,1-.051-.281.774.774,0,0,1,.214-.525.736.736,0,0,1,.665-.2.451.451,0,0,1,.141.044.421.421,0,0,1,.126.067.741.741,0,0,1,.118.089.778.778,0,0,1,.214.525.736.736,0,0,1-.059.281.666.666,0,0,1-.4.4A.737.737,0,0,1,382.739,387.478Z"
            transform="translate(-382 -385.999)"
            fill="currentColor"
          />
          <path
            className="a"
            d="M386.739,387.482a.736.736,0,0,1-.281-.059.854.854,0,0,1-.244-.155,1.122,1.122,0,0,1-.163-.244.727.727,0,0,1-.051-.281.739.739,0,1,1,1.478,0,.735.735,0,0,1-.059.281.667.667,0,0,1-.4.4A.736.736,0,0,1,386.739,387.482Z"
            transform="translate(-383.043 -386.003)"
            fill="currentColor"
          />
          <path
            className="a"
            d="M390.739,387.482a.729.729,0,0,1-.281-.052,1.082,1.082,0,0,1-.244-.163.863.863,0,0,1-.155-.244.736.736,0,0,1-.059-.281.739.739,0,1,1,1.478,0,.735.735,0,0,1-.059.281.863.863,0,0,1-.155.244,1.1,1.1,0,0,1-.244.163A.729.729,0,0,1,390.739,387.482Z"
            transform="translate(-384.086 -386.003)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M412.743,382H381.7a3.7,3.7,0,0,0-3.7,3.7v25.133a3.7,3.7,0,0,0,3.7,3.7h31.047a3.7,3.7,0,0,0,3.7-3.7V385.7A3.7,3.7,0,0,0,412.743,382Zm2.218,28.829a2.22,2.22,0,0,1-2.218,2.218H381.7a2.221,2.221,0,0,1-2.218-2.218V389.392h33.265a.739.739,0,0,0,0-1.478H379.478V385.7a2.22,2.22,0,0,1,2.218-2.218h31.047a2.22,2.22,0,0,1,2.218,2.218Z"
          transform="translate(-378 -382)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco63;
