/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-301.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco301 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-301', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="35.482"
      viewBox="0 0 35.482 35.482"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M404.957,483.478a1.478,1.478,0,1,0-2.218,1.273v.945a.739.739,0,1,0,1.478,0v-.945A1.473,1.473,0,0,0,404.957,483.478Z"
        transform="translate(-385.737 -468.694)"
        fill="currentColor"
      />
      <g transform="translate(11.827 0)">
        <path
          className="a"
          d="M407.088,474.349a.738.738,0,0,1-.739-.739v-3.7a4.435,4.435,0,1,0-8.871,0v3.7a.739.739,0,0,1-1.478,0v-3.7a5.914,5.914,0,1,1,11.827,0v3.7A.738.738,0,0,1,407.088,474.349Z"
          transform="translate(-396 -464)"
          fill="currentColor"
        />
      </g>
      <g transform="translate(8.871 8.871)">
        <path
          className="a"
          d="M409,486.626a.739.739,0,0,1-.739-.739v-6.93a1.48,1.48,0,0,0-1.478-1.478H394.957a1.48,1.48,0,0,0-1.478,1.478v6.93a.739.739,0,0,1-1.479,0v-6.93A2.96,2.96,0,0,1,394.957,476h11.827a2.96,2.96,0,0,1,2.957,2.957v6.93A.739.739,0,0,1,409,486.626Z"
          transform="translate(-392 -476)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M415.482,479.721a2.953,2.953,0,0,0-.386-1.451h0a2.956,2.956,0,0,0-.882-.975l-7.919-5.509a.74.74,0,0,0-.844,1.215l7.919,5.508c.016.012.028.028.044.04l-13.689,9.625h-3.968l-13.689-9.625c.016-.013.028-.029.044-.04L390.032,473a.74.74,0,0,0-.844-1.215l-7.919,5.509a2.956,2.956,0,0,0-.882.974h0a2.955,2.955,0,0,0-.385,1.45v19.54a2.2,2.2,0,0,0,.586,1.49.129.129,0,0,0,.007.015c0,.005.01.007.015.013s.025.034.041.049a2.2,2.2,0,0,0,1.569.651h31.047a2.206,2.206,0,0,0,1.609-.7c0-.005.011-.007.015-.013s.005-.012.009-.017a2.2,2.2,0,0,0,.585-1.488Zm-14.289,9.227L414,479.94v19.178Zm-1.492.7L412.736,500H382.747l13.035-10.349Zm-18.222-9.71,12.811,9.007-12.811,10.171Z"
        transform="translate(-380 -465.996)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco301;
