import { ConfigurationType } from './types';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getConfig = createAsyncThunk('getConfig', async (): Promise<ConfigurationType> => {
  try {
    const response = await fetch(`/api/configuration`);
    // console.log(response, 'response');
    return Promise.resolve(response.json());
  } catch (e) {
    return Promise.reject(e);
  }
});
