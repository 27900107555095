/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\nazione-ca.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const NazioneCa = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-nazione-ca', className].join(' ');
  return (
    <svg
      id="nazione-ca"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <path d="M2.534 0h11.322v16H2.534V0Z" fill="#fff" />
        <path
          d="M-3.125 0h5.66v16h-5.66V0Zm16.981 0h5.66v16h-5.66V0ZM4.228 7.731l-.437.15 2.043 1.797c.157.463-.053.597-.187.84l2.219-.28-.057 2.234.463-.016-.103-2.215 2.225.262c-.138-.29-.26-.444-.135-.906l2.044-1.703-.356-.128c-.294-.229.125-1.088.187-1.632 0 0-1.19.41-1.268.194l-.31-.578-1.081 1.188c-.119.03-.169-.02-.197-.12l.5-2.49-.794.447c-.065.028-.13 0-.175-.075l-.765-1.531-.788 1.59c-.06.057-.119.063-.168.025L6.33 4.36l.453 2.475c-.034.094-.122.125-.221.072L5.521 5.725c-.135.219-.228.575-.406.656-.178.072-.782-.153-1.185-.24.138.497.569 1.322.297 1.593v-.003Z"
          fill="#D52B1E"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="16" height="16" rx="8" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NazioneCa;
