export type ItemWithLabel<T> = T & {
  label?: string;
};

/* eslint-disable no-unused-vars */
export enum enFiltersKind {
  Minutaggio = 'Minutaggio',
  SommaGol = 'SommaGol',
  DiffGol = 'DiffGol',
  Fase = 'Fase',
  StatoMatch = 'StatoMatch',
  GolNoGol = 'GolNoGol',
}
export type FiltersKindType = keyof typeof enFiltersKind;
export const FiltersKindOptions: Array<enFiltersKind | FiltersKindType> = Object.values(enFiltersKind);

export interface FilterType<T>
  extends ItemWithLabel<{
    type: enFiltersKind | FiltersKindType;
  }> {
  values: Array<T>;
}

/*****************************/
export enum enStatoMatch {
  Home = 'Home',
  Balance = 'Balance',
  Guest = 'Guest',
}
export type StatoMatchType = keyof typeof enStatoMatch;
export const StatoMatchOptions: Array<enStatoMatch | StatoMatchType> = Object.values(enStatoMatch);

/*****************************/
export enum enFase {
  FirstHalf = '^1(.*)tempo$',
  Breack = 'intervallo',
  SecondHalf = '^2(.*)tempo$',
  Other = 'other',
  End = 'end',
}

export type FaseType = keyof typeof enFase;
export const FaseOptions: Array<enFase | FaseType> = Object.values(enFase);

/*****************************/
export enum enGoals {
  Zero = 0,
  One = 1,
  Two = 2,
  Tree = 3,
  More = 4,
}

export type GoalsType = keyof typeof enGoals;
export const GoalsOptions: Array<number> = Object.values([0, 1, 2, 3, 4]);

/*****************************/
export enum enGolNoGol {
  Goal = 'Goal',
  NoGoal = 'NoGoal',
}
export type GolNoGolType = keyof typeof enGolNoGol;
export const GolNoGolOptions: Array<enGolNoGol | GolNoGolType> = Object.values(enGolNoGol);

/*****************************/
/* eslint-enable no-unused-vars */

/* type definition for state */
export interface MinutaggioStatus {
  to: number | enFase | FaseType;
  from: number | enFase | FaseType;
}

export type LiveFiltersStatus = {
  fase?: Array<enFase | FaseType>;
  diffGol?: Array<number>;
  golNoGol?: Array<enGolNoGol | GolNoGolType>;
  sommaGol?: Array<number>;
  minutaggio?: Array<MinutaggioStatus>;
  statoMatch?: Array<enStatoMatch | StatoMatchType>;
};

/* type definition for hook */
export interface MinutaggioITemType {
  to: ItemWithLabel<{ value: number | enFase | FaseType }>;
  from: ItemWithLabel<{ value: number | enFase | FaseType }>;
}

export type LiveFiltersType = {
  fase: FilterType<ItemWithLabel<{ value: enFase | FaseType }>>;
  diffGol: FilterType<ItemWithLabel<{ value: number }>>;
  golNoGol: FilterType<ItemWithLabel<{ value: enGolNoGol | GolNoGolType }>>;
  sommaGol: FilterType<ItemWithLabel<{ value: number }>>;
  minutaggio: FilterType<MinutaggioITemType>;
  statoMatch: FilterType<ItemWithLabel<{ value: enStatoMatch | StatoMatchType }>>;
};

export const sequence = ['minutaggio', 'sommaGol', 'diffGol', 'fase', 'statoMatch', 'golNoGol'];
