/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-366.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco366 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-366', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.44"
      height="41.396"
      viewBox="0 0 38.44 41.396"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        className="a"
        d="M321.351,155.786h-2.957a.739.739,0,1,0,0,1.478h1.478v1.478a.739.739,0,0,0,1.478,0v-1.478a2.218,2.218,0,1,0,0-4.435h-1.478a.739.739,0,0,1,0-1.478h2.957a.739.739,0,0,0,0-1.478h-1.478v-1.478a.739.739,0,0,0-1.478,0v1.478a2.218,2.218,0,1,0,0,4.435h1.478a.739.739,0,0,1,0,1.478Zm-.739-14.045H320.6l.016-8.44a3.688,3.688,0,0,0-2.811-3.595l-9.84-2.428a.732.732,0,0,0-.133-.845l-2.218-2.218a.739.739,0,1,0-1.045,1.045l1.7,1.7-9.3,9.3-1.7-1.7a.739.739,0,0,0-1.045,1.045l2.218,2.218a.739.739,0,0,0,1.045,0l1.182-1.182c.992,3.4,5.059,5.717,7.749,6.448a2.2,2.2,0,0,1,.977.566l5.168,5.168a3.329,3.329,0,1,0,4.707-4.709l-5.276-5.269a.845.845,0,0,1-.078-.092.542.542,0,0,1-.064-.5.761.761,0,0,1,.475-.483l.949-.316a.739.739,0,1,0-.467-1.4l-.95.316a2.239,2.239,0,0,0-1.414,1.43,2.016,2.016,0,0,0,.271,1.814,2.2,2.2,0,0,0,.234.274l5.277,5.271a1.847,1.847,0,0,1,0,2.617,1.891,1.891,0,0,1-2.613,0l-5.169-5.168a3.689,3.689,0,0,0-1.636-.948c-2.773-.753-6.635-3.183-6.862-6.294l6.859-6.859,10.654,2.629a2.213,2.213,0,0,1,1.685,2.157v8.553c-.115.015-.238.023-.355.042a.739.739,0,1,0,.237,1.459c.283-.046.556-.08.823-.1.006,0,.01,0,.016,0h0c.009,0,.016,0,.025,0,.248-.02.491-.032.731-.032a10.385,10.385,0,1,1-9.673,6.7.739.739,0,0,0-1.383-.523,11.821,11.821,0,1,0,11.056-7.654Z"
        transform="translate(-294 -124)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco366;
