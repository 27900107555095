/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-155.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina155 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-155', className].join(' ');
  return (
    <svg
      id="disciplina_quarter-ev"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="m19.7506 10.2796.2607.1272c.2788.109.6114.0272.8182-.218.2608-.3089.2158-.78127-.0899-1.04471l-1.8432-1.78046-.7372-1.33535-.1349 1.07191-3.4167 2.55261-.0809.00908-.5934-.57229.3057-.8539.009-.00908c.7373-.41787.5125-1.68963-.5035-1.67146l-3.0301.25435c-.3686.16351-.6383.55413-.5304 1.05375l.4585 2.19835-3.29975.3452c-.86316 0-4.34275.4178-4.34275.4178v1.0629l4.47761-.7722v.645l-.9171 1.2354-2.40964 3.1885c-.17083.2089-.15285.5269.05395.6995.2068.1816.5125.1544.69232-.0545l2.39166-2.5163.53947-.1635.4046-.0727-.0989.2453.57544 4.0333c.05395.2725.31469.4451.58443.3815.26074-.0545.43157-.3179.37763-.5905l-.09891-3.361 1.28574-1.6442.2158-.0909 3.012 1.0265.9801-.0545 2.4456 3.8698c.1349.2362.4316.3179.6653.1817.2338-.1363.3147-.4451.1799-.6813l-1.5106-2.9523 1.2498.4814 1.7713 1.7442c.1528.2271.4585.2816.6833.1271.2248-.1635.2787-.4633.1259-.6904l-1.9331-2.5435-1.7083-.8993.1348-1.4353c.1619.0455 2.5805-.9447 2.5805-.9447Zm-1.3397-.49054.9351.36334s-1.8882.7449-1.9961.7177l1.061-1.08104Zm-6.2938.12717-.3507-1.5897 1.4746.02725h.018l-.2518.70856c-.027.08175-.027.17259-.018.25435v.00908c.018.08176.063.16352.1259.2271l.2517.24527-1.2497.11809Zm2.6793-3.1794c.072-.47237.5036-.79939.9711-.72672.4675.06359.7912.5087.7193.98107-.0629.47237-.5035.7994-.9711.73581-.4585-.07267-.7912-.50871-.7193-.99016Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina155;
