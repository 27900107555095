/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-6.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina6 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-6', className].join(' ');
  return (
    <svg
      id="disciplina_hockey-ghiaccio"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M15.024 21.507H8.977a.395.395 0 0 0-.389.389v1.715a.394.394 0 0 0 .39.389h6.046a.391.391 0 0 0 .39-.389v-1.715a.39.39 0 0 0-.39-.39Zm-.39 1.715H9.368v-.937h5.268v.937Zm9.335-3.163-.412-1.032c-.005-.004-.005-.014-.01-.018a3.735 3.735 0 0 0-3.377-2.147h-2.75a2.825 2.825 0 0 1-2.542-1.574l-1.44-2.812L18.823.553a.41.41 0 0 0-.028-.375.375.375 0 0 0-.328-.178h-1.309a.396.396 0 0 0-.347.21l-4.808 9.412L7.199.216a.387.387 0 0 0-.347-.211H5.544a.394.394 0 0 0-.39.357.375.375 0 0 0 .033.186l5.352 11.918-1.426 2.793a2.82 2.82 0 0 1-2.542 1.57H3.827a3.755 3.755 0 0 0-3.378 2.147c-.004.005-.004.014-.01.019l-.412 1.03a.384.384 0 0 0 .352.526c.033 0 2.069.033 4.095.033 1.023 0 2.045-.005 2.796-.033h.01c1.402-.08 2.955-1.144 3.625-2.484 0-.005.005-.01.005-.014l1.07-2.367 1.092 2.4c.005.004.005.014.01.018.722 1.364 2.247 2.4 3.626 2.47h6.89a.387.387 0 0 0 .325-.169c.084-.084.098-.225.046-.346ZM6.144.787h.47l4.953 9.697-.568 1.101L6.144.787ZM.965 19.791l.197-.492a2.974 2.974 0 0 1 2.191-1.65l-.962 2.156c-.572-.004-1.07-.009-1.426-.014Zm9.246-2.048c-.544 1.083-1.848 1.978-2.965 2.048-1.06.033-2.636.038-4.015.029l.98-2.203h2.36a3.6 3.6 0 0 0 3.232-1.997l1.51-2.943c.005-.005.005-.014.01-.019L17.398.792h.469L10.21 17.743Zm6.54 2.077c-1.103-.066-2.374-.947-2.966-2.053l-1.374-3.004.624-1.378 1.159 2.259a3.604 3.604 0 0 0 3.236 2.001h2.458l.92 2.175H16.75Zm4.896-.005-.895-2.119a2.991 2.991 0 0 1 2.092 1.631l.197.488h-1.394Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Disciplina6;
