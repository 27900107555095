/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-43.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco43 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-43', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="38.439"
      viewBox="0 0 41.396 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M149,494.824a.739.739,0,0,0-.739.739v2.957a.739.739,0,0,0,1.478,0v-2.957A.739.739,0,0,0,149,494.824Zm15.524-12.567h-2.957a.739.739,0,0,0,0,1.479h2.957a.739.739,0,0,0,0-1.479Zm-8.6,11.253a.739.739,0,1,0-1.28.739l1.478,2.56a.739.739,0,1,0,1.28-.739Zm-12.837-.271a.738.738,0,0,0-1.01.271l-1.478,2.56a.739.739,0,1,0,1.28.739l1.478-2.56A.74.74,0,0,0,143.083,493.239Zm14.045-24.327a.738.738,0,0,0-1.01.271l-1.478,2.56a.739.739,0,1,0,1.28.739l1.478-2.56A.74.74,0,0,0,157.128,468.912ZM137.743,488.64l-2.561,1.478a.739.739,0,1,0,.739,1.28l2.561-1.478a.739.739,0,1,0-.739-1.28Zm22.137-11.187a.735.735,0,0,0,.369-.1l2.561-1.478a.739.739,0,1,0-.739-1.28l-2.561,1.479a.739.739,0,0,0,.37,1.379Zm2.93,12.666-2.561-1.478a.739.739,0,0,0-.739,1.28l2.561,1.478a.739.739,0,1,0,.739-1.28Z"
          transform="translate(-126.82 -463.777)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M160.458,485.89l-5.585-3.224a2.183,2.183,0,0,0-1.438-2.485V466.739a.739.739,0,1,0-1.478,0v13.442a2.216,2.216,0,0,0-.692.4l-1.156-.668a.74.74,0,0,0-.739,1.281l1.149.663a2.182,2.182,0,0,0,1.438,2.485v1.614a.739.739,0,1,0,1.478,0v-1.614a2.22,2.22,0,0,0,.692-.4l5.591,3.228a.74.74,0,0,0,.739-1.281Zm-8.5-3.627a.739.739,0,1,1,.739.739A.74.74,0,0,1,151.957,482.263Z"
          transform="translate(-130.519 -463.043)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M129.012,473.061l2.153-1.345a4.593,4.593,0,0,0,2.228-4.02,3.7,3.7,0,0,0-7.392,0,.739.739,0,0,0,1.478,0,2.218,2.218,0,0,1,4.435,0,3.161,3.161,0,0,1-1.533,2.766l-2.153,1.346A4.774,4.774,0,0,0,126,475.827v.739a.739.739,0,0,0,.739.739h5.914a.739.739,0,1,0,0-1.478h-5.175A3.285,3.285,0,0,1,129.012,473.061Zm12.512-.191h-.739v-8.131a.739.739,0,0,0-1.354-.41l-5.914,8.871a.739.739,0,0,0,.615,1.15h5.175v2.218a.739.739,0,0,0,1.478,0v-2.218h.739a.739.739,0,0,0,0-1.479Zm-2.218,0h-3.793l3.793-5.69Z"
          transform="translate(-124.521 -462.522)"
          fill="currentColor"
        />
        <path
          className="a"
          d="M146.176,462a.739.739,0,1,0,0,1.478,17.741,17.741,0,1,1-17.733,18.055l1.687,1.687a.739.739,0,0,0,1.045-1.045l-2.957-2.957a.738.738,0,0,0-1.045,0l-2.957,2.957a.739.739,0,0,0,1.045,1.045l1.7-1.7A19.217,19.217,0,1,0,146.176,462Z"
          transform="translate(-124 -462)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco43;
