/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-10.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina10 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-10', className].join(' ');
  return (
    <svg
      id="disciplina_football-americano"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        d="M6.698 9.279a.41.41 0 0 1-.383-.538c.854-2.489 3.228-4.159 5.91-4.159a6.277 6.277 0 0 1 4.616 2 .405.405 0 1 1-.594.552 5.41 5.41 0 0 0-1.793-1.276 5.498 5.498 0 0 0-2.23-.467c-2.333 0-4.4 1.452-5.142 3.61a.402.402 0 0 1-.384.278Zm7.158 6.36a1.65 1.65 0 1 1 0-3.299 1.65 1.65 0 0 1 0 3.299Zm0-2.482a.837.837 0 1 0 0 1.674.837.837 0 0 0 0-1.674ZM21 11.402C21 6.77 17.15 3 12.415 3c-4.736 0-8.582 3.769-8.582 8.402a.405.405 0 0 0 .313.394c.142.03.287.045.432.042a2.716 2.716 0 0 0 1.593-.523c.246-.183.531-.303.833-.352l.116 3.052-3.81.92a.406.406 0 0 0-.307.447l.62 4.781a.403.403 0 0 0 .382.352c.01 0 1.85 0 2.964-1.006.675-.608 1.03-1.459 1.076-2.513l-.01-.327 1.195-.12c.245.455.563.866.942 1.217.626.587 1.55.928 2.531.928.946 0 1.828-.299 2.486-.844.724-.601 1.5-1.195 2.341-1.297l1.463.604a.403.403 0 0 0 .492-.148l1.35-2.024a.399.399 0 0 0 .067-.253l-.102-1.53c.133-.59.2-1.194.2-1.8ZM3.935 16.265l-.08-.63 3.293-.794.043 1.097-3.256.327Zm2.49 2.643c-.648.608-1.562.753-2.043.788L4.04 17.07l3.181-.32s.039 1.374-.798 2.158ZM7.82 11.09c.147.073.28.17.394.289.323.334.495.819.474 1.364-.003.08-.007.162-.007.246-.007.214-.014.432-.017.654l-.739.18-.105-2.732Zm.183 4.768-.046-1.21.724-.175c.024.438.1.872.225 1.293l-.903.092Zm6.665 1.364c-.502.418-1.22.657-1.965.657-.777 0-1.498-.256-1.98-.706-1.335-1.256-1.282-2.732-1.23-4.156.004-.084.007-.165.007-.25.025-.766-.225-1.459-.703-1.954a2.2 2.2 0 0 0-1.589-.679c-.517 0-1.04.18-1.512.52a1.915 1.915 0 0 1-1.044.37c.204-4.012 3.607-7.214 7.763-7.214 4.285 0 7.773 3.406 7.773 7.59 0 .528-.056 1.055-.169 1.571l-2.622 2.774c-1.045.14-1.955.833-2.729 1.477Zm4.328-.942-.707-.292 1.762-1.864.035.52-1.09 1.636Z"
      />
    </svg>
  );
};
export default Disciplina10;
