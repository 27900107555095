/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\tv.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Tv = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-tv', className].join(' ');
  return (
    <svg
      id="tv"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 5.3a2 2 0 0 0-2 2v9.333a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V7.3a2 2 0 0 0-2-2H4ZM9.85 16H8.47v-5.72H7V8.95h4.32v1.33H9.85V16Zm5.36 0h-1.45l-1.89-7.05h1.41l1.21 4.98 1.21-4.98h1.41L15.21 16Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default Tv;
