/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-59.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina59 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-59', className].join(' ');
  return (
    <svg
      id="disciplina_taekwondo"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M21.623.395a1.127 1.127 0 0 0-1.602.089l-6.775 7.647L9.509 5.09c-.005-.005-.01-.005-.014-.01l2.102-3.635a.97.97 0 0 0-.276-1.272.94.94 0 0 0-1.275.175s-3.242 3.796-3.409 4.17c-.407.928-1.092 1.425-1.231 1.692-.14.267-2.413 4.512-2.413 4.512l-.074.14c-.03.06-.055.12-.074.184a1.143 1.143 0 0 0 .336 1.213c.112.096.241.17.381.216l2.848.946c.226.076.471.063.688-.035a.955.955 0 0 0 .482-.497.97.97 0 0 0 .024-.696.957.957 0 0 0-.448-.529l-1.385-.754.913-1.42 3.825 3.111c.104.087.216.164.333.23l.51 10.074c.014.295.14.574.352.777a1.131 1.131 0 0 0 1.57 0c.21-.203.337-.482.351-.777l.533-10.679 7.618-10.285a1.166 1.166 0 0 0-.153-1.546ZM6.378 4.242a2.798 2.798 0 0 0-.057-1.606 2.766 2.766 0 0 0-.93-1.303 2.715 2.715 0 0 0-3.033-.19 2.754 2.754 0 0 0-1.08 1.177 2.796 2.796 0 0 0 .409 3.048 2.73 2.73 0 0 0 2.416.908A2.711 2.711 0 0 0 5.91 5.211c.218-.289.377-.618.468-.97Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina59;
