/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-87.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco87 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-87', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="38.439"
      viewBox="0 0 35.482 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <ellipse className="a" cx="2" cy="1" rx="2" ry="1" transform="translate(0.855 30.811)" />
        <path
          className="a"
          d="M250.09,316.916a3.688,3.688,0,0,0-1.563-3.008,3.408,3.408,0,0,0,1.546-2.545,3.7,3.7,0,0,0-3.679-4.057H240.48l-5.237-.02a12.152,12.152,0,0,0,.879-9.873A5.137,5.137,0,0,0,231.252,294a1.892,1.892,0,0,0-1.891,1.773c-.5,8.5-7.086,15.385-7.153,15.454l1.061,1.029c.287-.3,7.031-7.337,7.568-16.394a.412.412,0,0,1,.415-.383,3.657,3.657,0,0,1,3.474,2.419,10.641,10.641,0,0,1-.758,8.642,1.509,1.509,0,0,0-.011,1.509,1.45,1.45,0,0,0,1.274.735h2.318a3.7,3.7,0,0,0-.747,2.578,3.562,3.562,0,0,0,.965,2.085,3.669,3.669,0,0,0-1.28,6.161,3.661,3.661,0,0,0-.739,5.914,3.647,3.647,0,0,0-1.162,5.438h-8.151A2.96,2.96,0,0,1,223.478,328H222a4.44,4.44,0,0,0,4.435,4.435h15.524a3.7,3.7,0,0,0,3.7-3.7,3.658,3.658,0,0,0-.758-2.218h.019a3.68,3.68,0,0,0,2.743-6.151A3.691,3.691,0,0,0,250.09,316.916Zm-3.7-8.131a2.217,2.217,0,0,1,2.207,2.439c-.115,1.213-1.243,2-2.873,2h-5.12a2.3,2.3,0,0,1-2.334-2,2.217,2.217,0,0,1,2.207-2.439Zm-2.218,19.959a2.22,2.22,0,0,1-2.218,2.218h-4.435a2.218,2.218,0,0,1,0-4.435h4.435A2.22,2.22,0,0,1,244.177,328.743Zm.739-3.7h-6.653a2.218,2.218,0,1,1,0-4.435h6.653a2.218,2.218,0,0,1,0,4.435Zm1.478-5.914H239a2.218,2.218,0,1,1,0-4.435h1.478v-.012c.043,0,.084.012.127.012h5.12a5.9,5.9,0,0,0,.667-.047v.047a2.218,2.218,0,0,1,0,4.435Z"
          transform="translate(-214.608 -294)"
          fill="currentColor"
        />
        <g transform="translate(0 14.784)">
          <path
            className="a"
            d="M217.174,337.655H215.7a.739.739,0,0,1,0-1.478h1.478a2.22,2.22,0,0,0,2.218-2.218v-17a1.48,1.48,0,0,0-1.478-1.478h-5.175a.739.739,0,0,1,0-1.478h5.175a2.96,2.96,0,0,1,2.957,2.957v17A3.7,3.7,0,0,1,217.174,337.655Z"
            transform="translate(-212 -314)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco87;
