/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-83.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina83 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-83', className].join(' ');
  return (
    <svg
      id="disciplina_calcio-gaelico"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M1 12c.004.56.052 1.12.141 1.673C3.377 9.688 7.666 7.488 12 7.488c.149 0 .293.015.44.02a6.746 6.746 0 0 0-.647-2.585C7.818 4.975 4.09 6.53 1.378 9.193A10.91 10.91 0 0 0 1 12Zm11.405-3.205c-.136-.004-.269-.018-.405-.018-4.54 0-8.781 2.675-10.364 6.866.447 1.263 1.155 2.422 2.005 3.424.012-4.014 3.313-7.47 7.95-7.685.423-.807.698-1.683.814-2.587Zm9.89 6.918c-3.824 2.159-8.4.494-10.651-3.058a7.646 7.646 0 0 0-2.621.598c2.335 4.394 6.912 6.574 11.288 5.868a10.933 10.933 0 0 0 1.985-3.408ZM7.889 13.861a6.727 6.727 0 0 0-1.917 1.851c2.008 3.375 5.245 5.865 8.886 6.898 1.478-.4 2.833-1.138 3.994-2.072-4.557.069-8.73-2.483-10.963-6.677Zm4.251 9.133c-2.745-1.273-5.129-3.38-6.846-5.977-.415 1.101-.486 2.282-.212 3.471C6.97 22.028 9.38 23 12 23c.047 0 .092-.006.14-.006ZM2.43 6.615c2.456-1.739 5.45-2.75 8.583-2.934a6.082 6.082 0 0 0-2.896-1.957 11.18 11.18 0 0 0-5.69 4.89l.002.001ZM12 1c-.651.003-1.3.064-1.94.183 3.566 2.066 4.744 6.794 2.684 10.766a7.6 7.6 0 0 0 1.837 1.992c1.66-2.664 2.116-5.65 1.502-8.413-.36-1.624-1.078-3.118-2.083-4.336-.66-.126-1.329-.19-2-.192Zm7.73 3.243c-1.058-1.055-2.334-1.929-3.748-2.483a11.955 11.955 0 0 1 1.36 3.489c.673 3.035.204 6.373-1.673 9.382.8.409 1.675.654 2.571.72 1.91-3.42 2.43-7.402 1.49-11.108Zm-.014 11.047c1.201-.203 2.225-.69 3.129-1.53.1-.581.151-1.17.155-1.76 0-2.034-.61-3.934-1.58-5.568.272 3.016-.318 6.084-1.704 8.858Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina83;
