/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\dashboard-updated.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const DashboardUpdated = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-dashboard-updated', className].join(' ');
  return (
    <svg
      id="dashboard-updated"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 32"
      fill="none"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M17.833 13V4H28.833V13H17.833ZM4.83301 17V4H15.833V17H4.83301ZM17.833 28V15H28.833V28H17.833ZM4.83301 28V19H15.833V28H4.83301ZM6.83301 15H13.833V6H6.83301V15ZM19.833 26H26.833V17H19.833V26ZM19.833 11H26.833V6H19.833V11ZM6.83301 26H13.833V21H6.83301V26Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default DashboardUpdated;
