/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-76.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco76 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-76', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="32.526"
      viewBox="0 0 41.396 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M318.263,142H302.739a.739.739,0,0,0,0,1.478h15.524a.739.739,0,0,0,0-1.478Zm-3.7,8.871H302.739a.739.739,0,0,0,0,1.478h11.828a.739.739,0,1,0,0-1.478Zm0-4.435H302.739a.739.739,0,0,0,0,1.478h11.828a.739.739,0,1,0,0-1.478Z"
          transform="translate(-294.608 -133.129)"
          fill="currentColor"
        />
        <g transform="translate(0 1.479)">
          <path
            className="a"
            d="M304.567,163.047a.74.74,0,0,1-.523-.217l-5.7-5.7H295.7a3.7,3.7,0,0,1-3.7-3.7V135.7a3.7,3.7,0,0,1,3.7-3.7h26.612a.739.739,0,1,1,0,1.478H295.7a2.22,2.22,0,0,0-2.218,2.218v17.741a2.22,2.22,0,0,0,2.218,2.218h2.957a.74.74,0,0,1,.523.217l4.652,4.652v-4.129a.739.739,0,0,1,.739-.739h19.22A2.22,2.22,0,0,0,326,153.437v-7.392a.739.739,0,1,1,1.478,0v7.392a3.7,3.7,0,0,1-3.7,3.7h-18.48v5.175a.739.739,0,0,1-.739.739Z"
            transform="translate(-292 -132)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M341.2,135a7.377,7.377,0,0,0-4.976-4.976.738.738,0,0,0-.7.2l-13.306,13.306a.739.739,0,0,0-.217.523v4.435a.739.739,0,0,0,.739.739h4.435A.737.737,0,0,0,327.7,149L341,135.7A.739.739,0,0,0,341.2,135Zm-14.33,12.744h-3.39v-3.39L333.827,134l3.39,3.39Zm11.394-11.394-3.39-3.39,1.382-1.382a6.156,6.156,0,0,1,3.39,3.39Z"
          transform="translate(-299.823 -130)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco76;
