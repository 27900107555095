/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\disciplina-71.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Disciplina71 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-disciplina-71', className].join(' ');
  return (
    <svg
      id="disciplina_superliga"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <path
        d="M12.469 7.134V3.416L15.806 0l.723 5.271 5.112-.891-1.743 3.358-3.669 2.158-3.76-2.762Zm-.6 1.056L8.25 10.848l1.38 4.299h4.477l1.383-4.301-3.621-2.656Zm-2.415 8.168-1.85 1.978L8.28 23l3.71-3.853L15.655 23l.67-4.457-2.043-2.185h-4.83Zm9.755-2.454L24 11.554l-4.41-2.236-2.898 1.706-1.472 4.575 2.104 2.248 4.332.748-2.447-4.69ZM7.046 11.023 4.254 9.381 0 11.508l4.76 2.368-2.454 4.642 4.125-.686 2.089-2.234-1.474-4.575Zm4.224-3.889V3.117L8.258 0l-.746 5.225-5.113-.921 1.895 3.701 3.214 1.89 3.762-2.761Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Disciplina71;
