/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-25.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco25 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-25', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.396"
      height="32.526"
      viewBox="0 0 41.396 32.526"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M498.439,130H462.957A2.96,2.96,0,0,0,460,132.957v22.177a2.96,2.96,0,0,0,2.957,2.957h14.784v2.957h-3.7a.739.739,0,0,0,0,1.478h13.306a.739.739,0,0,0,0-1.478h-3.7V158.09h14.784a2.96,2.96,0,0,0,2.957-2.957V132.957A2.96,2.96,0,0,0,498.439,130Zm-16.263,31.047H479.22V158.09h2.957Zm17.741-5.914a1.48,1.48,0,0,1-1.478,1.478H462.957a1.48,1.48,0,0,1-1.478-1.478V132.957a1.48,1.48,0,0,1,1.478-1.478h35.482a1.48,1.48,0,0,1,1.478,1.478Z"
          transform="translate(-460 -130)"
          fill="currentColor"
        />
        <g transform="translate(14.784 10.35)">
          <path
            className="a"
            d="M489.206,155.828h0a.737.737,0,0,1-.523-.217L486.069,153l-.887,2.071a.725.725,0,0,1-.7.447.739.739,0,0,1-.675-.479L480.046,145a.74.74,0,0,1,.953-.951l10.036,3.763a.74.74,0,0,1,.031,1.372l-2.07.887,2.615,2.615a.739.739,0,0,1,0,1.045l-1.882,1.881A.736.736,0,0,1,489.206,155.828Zm-3.386-4.866a.739.739,0,0,1,.523.217l2.864,2.864.837-.836-2.865-2.864a.738.738,0,0,1,.231-1.2l1.382-.592L482,146l2.545,6.789.593-1.382a.739.739,0,0,1,.679-.448Z"
            transform="translate(-480 -144.001)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(0 5.914)">
          <path
            className="a"
            d="M497.7,139.478H460.739a.739.739,0,0,1,0-1.478H497.7a.739.739,0,1,1,0,1.478Z"
            transform="translate(-460 -138)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(2.957 2.957)">
          <path
            className="a"
            d="M464.739,135.478a.736.736,0,0,1-.281-.059.763.763,0,0,1-.244-.155.853.853,0,0,1-.155-.244.737.737,0,0,1-.059-.281.774.774,0,0,1,.214-.525.761.761,0,0,1,.244-.155.687.687,0,0,1,.421-.044.449.449,0,0,1,.141.044.419.419,0,0,1,.126.066c.044.03.081.059.118.089a.777.777,0,0,1,.214.525.737.737,0,0,1-.059.281.852.852,0,0,1-.155.244c-.037.03-.075.059-.118.089a.421.421,0,0,1-.126.067.45.45,0,0,1-.141.044A.888.888,0,0,1,464.739,135.478Z"
            transform="translate(-464 -134)"
            fill="currentColor"
          />
          <path
            className="a"
            d="M468.739,135.479a.9.9,0,0,1-.14-.015.447.447,0,0,1-.14-.044.58.58,0,0,1-.134-.067,1.286,1.286,0,0,1-.11-.089.764.764,0,0,1-.155-.244.7.7,0,0,1,0-.562.763.763,0,0,1,.155-.244.752.752,0,0,1,.806-.155.736.736,0,0,1-.281,1.419Z"
            transform="translate(-465.043 -134.001)"
            fill="currentColor"
          />
          <path
            className="a"
            d="M472.739,135.478a1,1,0,0,1-.148-.015.47.47,0,0,1-.133-.044.583.583,0,0,1-.134-.067l-.11-.089a1.176,1.176,0,0,1-.089-.111.558.558,0,0,1-.066-.133.5.5,0,0,1-.045-.133,1.253,1.253,0,0,1-.014-.148.774.774,0,0,1,.214-.525,1.254,1.254,0,0,1,.11-.089.58.58,0,0,1,.134-.066.469.469,0,0,1,.133-.044.707.707,0,0,1,.429.044.861.861,0,0,1,.244.155.777.777,0,0,1,.214.525,1.017,1.017,0,0,1-.015.148.45.45,0,0,1-.044.133.593.593,0,0,1-.067.133l-.089.111A.778.778,0,0,1,472.739,135.478Z"
            transform="translate(-466.086 -134)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
export default MessageCenterIco25;
