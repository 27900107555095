/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\pig.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const Pig = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-pig', className].join(' ');
  return (
    <svg
      id="pig"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g id="Frame 5307">
        <path
          id="Vector"
          d="M32.9474 22.8947C33.5737 22.8947 34.0987 22.6829 34.5224 22.2592C34.9461 21.8355 35.1579 21.3105 35.1579 20.6842C35.1579 20.0579 34.9461 19.5329 34.5224 19.1092C34.0987 18.6855 33.5737 18.4737 32.9474 18.4737C32.3211 18.4737 31.7961 18.6855 31.3724 19.1092C30.9487 19.5329 30.7368 20.0579 30.7368 20.6842C30.7368 21.3105 30.9487 21.8355 31.3724 22.2592C31.7961 22.6829 32.3211 22.8947 32.9474 22.8947ZM15.2632 17.3684H26.3158V14.0526H15.2632V17.3684ZM7.52632 45C6.27368 40.8 5.03947 36.6092 3.82368 32.4276C2.60789 28.2461 2 23.9632 2 19.5789C2 16.1895 3.17895 13.3158 5.53684 10.9579C7.89474 8.6 10.7684 7.42105 14.1579 7.42105H25.2105C26.2789 6.02105 27.5776 4.93421 29.1066 4.16053C30.6355 3.38684 32.2842 3 34.0526 3C34.9737 3 35.7566 3.32237 36.4013 3.96711C37.0461 4.61184 37.3684 5.39474 37.3684 6.31579C37.3684 6.53684 37.3408 6.75789 37.2855 6.97895C37.2303 7.2 37.1658 7.40263 37.0921 7.58684C36.9447 7.99211 36.8066 8.40658 36.6776 8.83026C36.5487 9.25395 36.4474 9.68684 36.3737 10.1289L41.4026 15.1579H46.2105V30.5763L39.9658 32.6211L36.2632 45H24.1053V40.5789H19.6842V45H7.52632ZM10.0132 41.6842H16.3684V37.2632H27.4211V41.6842H33.7763L37.2579 30.0789L42.8947 28.1447V18.4737H40.0211L32.9474 11.4C32.9842 10.4789 33.1039 9.58553 33.3066 8.71974C33.5092 7.85395 33.7211 6.97895 33.9421 6.09474C32.5421 6.46316 31.2158 7.00658 29.9632 7.725C28.7105 8.44342 27.7526 9.44737 27.0895 10.7368H14.1579C11.7158 10.7368 9.63157 11.6 7.90525 13.3263C6.17894 15.0526 5.31579 17.1368 5.31579 19.5789C5.31579 23.3795 5.85 27.0928 6.91842 30.7188C7.98684 34.3449 9.01842 38 10.0132 41.6842Z"
          fill="#509DD4"
        />
      </g>
    </svg>
  );
};
export default Pig;
