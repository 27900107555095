/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-91.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco91 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-91', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.526"
      height="38.439"
      viewBox="0 0 32.526 38.439"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(0 0)">
        <g transform="translate(0 0)">
          <path
            className="a"
            d="M157.351,142.263h-5.914a.739.739,0,0,1,0-1.478h5.914a3.7,3.7,0,0,0,3.7-3.7v-5.914a3.7,3.7,0,0,0-3.7-3.7H135.175a3.7,3.7,0,0,0-3.7,3.7v5.914a3.7,3.7,0,0,0,3.7,3.7h5.914a.739.739,0,0,1,0,1.478h-5.914A5.18,5.18,0,0,1,130,137.088v-5.914A5.18,5.18,0,0,1,135.175,126h22.177a5.18,5.18,0,0,1,5.175,5.175v5.914A5.18,5.18,0,0,1,157.351,142.263Z"
            transform="translate(-130 -126)"
            fill="currentColor"
          />
        </g>
        <g transform="translate(5.914 13.306)">
          <path
            className="a"
            d="M152.055,169.133a8.141,8.141,0,0,1-5.759-2.381l-8.083-8.188a.739.739,0,0,1,0-1.042l.343-.343a4.44,4.44,0,0,1,6.273,0l.56.56V146.957a2.957,2.957,0,0,1,5.914,0v5.381l5.838,1.946a4.43,4.43,0,0,1,3.033,4.208v2.283a8.274,8.274,0,0,1-7.552,8.339h0Q152.339,169.133,152.055,169.133Zm-12.267-11.077,7.556,7.655a6.663,6.663,0,0,0,5.177,1.927h0a6.792,6.792,0,0,0,6.176-6.864v-2.283a2.953,2.953,0,0,0-2.022-2.805l-6.343-2.114a.739.739,0,0,1-.506-.7v-5.914a1.478,1.478,0,1,0-2.957,0v12.567a.739.739,0,0,1-1.262.523l-1.821-1.821A2.962,2.962,0,0,0,139.788,158.056Z"
            transform="translate(-138 -144)"
            fill="currentColor"
          />
        </g>
        <path
          className="a"
          d="M150.567,132a.739.739,0,0,0-.739.739v3.7a1.478,1.478,0,0,1-2.957,0v-3.7a.739.739,0,0,0-1.478,0v3.7a2.957,2.957,0,0,0,5.914,0v-3.7A.74.74,0,0,0,150.567,132Zm7.392,0a.739.739,0,0,0-.739.739v.739a1.478,1.478,0,1,1-2.957,0v-.739a.739.739,0,0,0-1.478,0v.739A2.954,2.954,0,0,0,155,136.33v2.323a.739.739,0,1,0,1.478,0V136.33a2.954,2.954,0,0,0,2.218-2.852v-.739A.739.739,0,0,0,157.959,132Zm-15.081,3.305a2.193,2.193,0,0,0,.3-1.087A2.22,2.22,0,0,0,140.957,132h-2.218a.739.739,0,0,0-.739.739v5.914a.739.739,0,0,0,.739.739H141.7a2.213,2.213,0,0,0,1.182-4.087Zm-3.4-1.827h1.478a.739.739,0,1,1,0,1.478h-1.478Zm2.218,4.435h-2.218v-1.478H141.7a.739.739,0,0,1,0,1.478Z"
          transform="translate(-132.086 -127.565)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export default MessageCenterIco91;
