/************************************************************************************************************
    DO NOT EDIT THIS FILE
    APPLY YOUR CHANGES TO \common\tasks\image-lib\src\message-center-ico-188.svg FILE, INSTEAD
    THEN RUN npm run image-lib COMMAND
************************************************************************************************************/
import React from 'react';

export const MessageCenterIco188 = ({ className, ...attribs }: React.SVGProps<SVGSVGElement>) => {
  const componentClassName = ['snai-image', 'snai-image-message-center-ico-188', className].join(' ');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.482"
      height="35.483"
      viewBox="0 0 35.482 35.483"
      focusable="false"
      className={componentClassName}
      {...attribs}
    >
      <g transform="translate(23.655 19.22)">
        <path
          className="a"
          d="M162.218,410.435a2.218,2.218,0,1,1,2.218-2.218A2.22,2.22,0,0,1,162.218,410.435Zm0-2.957a.739.739,0,1,0,.739.739A.74.74,0,0,0,162.218,407.478Z"
          transform="translate(-160 -406)"
          fill="currentColor"
        />
      </g>
      <path
        className="a"
        d="M157.816,387.761l-2.987-5.175a.739.739,0,0,0-1.01-.271l-2.835,1.637-2.069-3.584a.739.739,0,0,0-1.01-.271l-12.8,7.392a.74.74,0,0,0,.739,1.281L148,381.749l1.7,2.943-4.847,2.8a.74.74,0,0,0,.739,1.281l8.322-4.8,2.618,4.534a.739.739,0,1,0,1.28-.739Z"
        transform="translate(-129.756 -380)"
        fill="currentColor"
      />
      <path
        className="a"
        d="M161.265,398.871h-.739v-5.914A2.96,2.96,0,0,0,157.569,390H130.957A2.96,2.96,0,0,0,128,392.957v22.177a2.96,2.96,0,0,0,2.957,2.957h26.612a2.96,2.96,0,0,0,2.957-2.957v-2.957a.739.739,0,1,0-1.478,0v2.957a1.48,1.48,0,0,1-1.478,1.478H130.957a1.48,1.48,0,0,1-1.478-1.478V392.957a1.48,1.48,0,0,1,1.478-1.478h26.612a1.48,1.48,0,0,1,1.478,1.478v5.914h-5.174a5.175,5.175,0,1,0,0,10.349h7.392A2.22,2.22,0,0,0,163.482,407v-5.914A2.22,2.22,0,0,0,161.265,398.871ZM162,407a.74.74,0,0,1-.739.739h-7.392a3.7,3.7,0,1,1,0-7.392h7.392a.74.74,0,0,1,.739.739Z"
        transform="translate(-128 -382.608)"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageCenterIco188;
